import React, {useEffect} from "react";
import {Avatar, Dropdown, Menu} from "antd";
import {LogoutOutlined, UserOutlined} from "@ant-design/icons";
import {MemberContext} from "../../auth/member.context";
import {AuthService} from "../../auth/auth.service";
import {useLayoutDispatch, useLayoutState} from "./AppLayoutContext";
import intl from 'react-intl-universal';
import { CaretDownOutlined } from '@ant-design/icons';
import axios from "axios";
import {adminConfig} from "../../config/admin.config";
import {useHistory} from "react-router-dom";

const API_URL = adminConfig.apiServer();
function LoginInfo({props}) {
  const history = useHistory();
  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();

  const logout = () => {
    // remove all tab
    layoutDispatch({type:'REMOVE_ALL_TAB'});

    AuthService.logout().then(() => {
      document.location.href = "/login"
    }).catch((err) => {
      console.log(">>>>Logout err:", err);
      document.location.href = "/login";
    });
  };

  const handleApplicationClick = ({key}) => {
    if (key === "logout") {
      logout();
      return;
    }
    AuthService.changeApplication(key).then(res => {
      layoutDispatch({type:'CHANGE_APPLICATION', key});
      history.push("/");
    });
  }

  const getApplicationTitle = (app) => {
    if (app.adminWebSetting?.title) {
      return app.adminWebSetting.title;
    } else {
      return app.name;
    }
  };

  const getAppSelectionMenuItems = () => {
    const hasMultipleApps = MemberContext.memberInformation.applicationUserRoles && MemberContext.memberInformation.applicationUserRoles.length > 1;
    let menuItems = [];
    if (hasMultipleApps) {
      menuItems = MemberContext.memberInformation.applicationUserRoles.map((ar, index) => {
        return {key: ar.application.id, label: getApplicationTitle(ar.application)};
      });
      menuItems.push({type: "divider"})
    }
    menuItems.push({key: "logout", label: <div><LogoutOutlined/>&nbsp;{intl.get("login.loginOut")}</div>});
    return menuItems;
  };

  return (
      <>
        <Dropdown menu={{
          items: getAppSelectionMenuItems(),
          selectable: true,
          selectedKeys: [layoutState.application.id ? "" + layoutState.application.id : ""],
          onClick: handleApplicationClick,
        }}>
          <span style={{cursor: "pointer", fontSize: 14}}>
            <span style={{marginRight: "2px"}}>{MemberContext.memberInformation && MemberContext.memberInformation.name}</span>
            <CaretDownOutlined />
          </span>
        </Dropdown>
      </>
  )
};

export default LoginInfo;

