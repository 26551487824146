import React, {useState, useEffect} from "react";
import ProductApi from "./ProductApi";
import {Button, Input, Modal, Table, message, Divider, Checkbox, Select, Layout, Spin, Tag} from "antd";
import intl from "react-intl-universal";
import CategoryApi from "../category/CategoryApi";
import BrandApi from "../brand/BrandApi";
import {SettingOutlined} from "@ant-design/icons";
import NoImage from "../../asset/images/no_image.png";
import Util from "../../util/Util";
import {useLayoutState} from "../../components/layout/AppLayoutContext";
const {Content} = Layout;

const SkuSearchPopup = (props) => {
  const layoutState = useLayoutState();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [filter, setFilter] = useState({
    name: '',
    idType: '',
    idTarget: '',
    published: true,
    eventProduct: props.eventProduct,
    hideToUser: "",
    brandId: '',
    categoryId: '',
    subCategoryId: '',
    sort: "latest",
  });

  const [products, setProducts] = useState([]);
  const [application, setApplication] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [selectedSkuIds, setSelectedSkuIds] = useState([]);
  const [numSkus, setNumSkus] = useState(0);

  useEffect(() => {
    searchProducts(1, filter);
    loadBrands();
    loadCategories();
    setLoading(false);
  }, []);

  useEffect(() => {
    setApplication(layoutState.application);
  }, [layoutState.application]);

  const searchProducts = (page, filter) => {
    const params = getSearchParam(page, filter);
    ProductApi.getProducts(params).then(res => {
      const pagingResult = res.data;
      const pagination = {
        current: pagingResult.page,
        pageSize: pagingResult.pageSize,
        total: pagingResult.count,
      }
      const products = pagingResult.data;
      setProducts(products);
      setPagination(pagination);
      setSelectedSkuIds([]);
      setCheckAll(false);
      setIndeterminate(false);
      setLoading(false);

      let numSkus = 0;
      products.forEach(p => {
        numSkus += p.skus.length;
      });
      setNumSkus(numSkus);
    });
  };

  const getSearchParam = (page, filter) => {
    const params = {
      pageSize: pagination.pageSize,
      page: page,
      ...filter
    };
    return params;
  };

  const loadCategories = () => {
    CategoryApi.getCategoriesByApp({}).then(res => {
      setCategories(res.data);
    });
  };

  const loadBrands = () => {
    BrandApi.getBrands({}).then(res => {
      setBrands(res.data);
    });
  };

  const productTableChanged = (e) => {
    const paginationState = {...pagination};
    paginationState.pageSize = e.pageSize;
    setPagination(paginationState);
    searchProducts(e.current, filter);
  };

  const handleOk = () => {
    if (selectedSkuIds.length === 0) {
      message.error(intl.get("products.selectSku"));
      return;
    }

    const selectedSkus = [];
    const skuMap = {};
    products.forEach(p => {
      p.skus.forEach(s => {
        s.productName = p.name;
        skuMap[s.id] = s;
      });
    });

    selectedSkuIds.forEach(sid => {
      const sku = skuMap[sid];
      if (sku) {
        selectedSkus.push(sku);
      }
    });
    if (props.handleOk) {
      props.handleOk(JSON.parse(JSON.stringify(selectedSkus)));
    }
  }

  const onCategorySelect = (value) => {
    const categoriesState = [...categories];
    const filterState = {...filter};

    let selectedCategory = categoriesState.find(category => category.id === value);
    if (selectedCategory) {
      setSelectedCategory(selectedCategory);
      filterState.categoryId = value;
      setFilter(filterState);
    }
    searchProducts(1, filterState);
  };

  const onFilterChanged = (field, value, runSearch = false) => {
    const filterState = {...filter};
    filterState[field] = value;
    setFilter(filterState);
    if (runSearch) {
      searchProducts(1, filterState);
    }
  };

  const onCheckAllChange = (e) => {
    let allSkuIds = [];
    if (e.target.checked) {
      products.forEach(p => {
        allSkuIds = allSkuIds.concat(p.skus.map(sku => sku.id));
      });
    }
    setSelectedSkuIds(allSkuIds);
    setIndeterminate(false)
    setCheckAll(e.target.checked)
  };

  const onCheckChange = (skuId, e) => {
    const checked = e.target.checked;
    let selectedIds = [...selectedSkuIds];

    if (checked) {
      selectedIds.push(skuId);
    } else {
      selectedIds = selectedIds.filter(id => id !== skuId);
    }
    setSelectedSkuIds(selectedIds);
    setIndeterminate(!!selectedIds.length && selectedIds.length < numSkus)
    setCheckAll(selectedIds.length === numSkus)
  };

  let columns = [{
    title: () => {
      return (
        <div style={{width: "100%", display: "flex", "justifyContent": "flex-start", fontWeight: "bold"}}>
          <div style={{ marginRight: 10 }}>
            <Checkbox
              indeterminate={indeterminate}
              onChange={onCheckAllChange}
              checked={checkAll}
            />
          </div>
          <div style={{width: "5%"}}>{intl.get("products.images")}</div>
          <div style={{width: "40%", textAlign: "left"}}>{intl.get("products.name")}</div>
          <div style={{width: "15%", textAlign: "left"}}>{intl.get("products.sku.optionText")}</div>
          <div style={{width: "10%"}}>{intl.get("products.sku.barCode")}</div>
          <div style={{width: "10%"}}>{intl.get("products.sku.salesPrice")}</div>
          <div style={{width: "10%"}}>{intl.get("products.sku.minSalesPrice")}</div>
          <div style={{width: "10%"}}>{intl.get("products.sku.maxSalesPrice")}</div>
        </div>
      );
    },
      dataIndex: 'images',
      key: 'images',
      align: 'center',
      render: (text, record) => {
        let attrs = [];
        if (record.eventProduct) {
          attrs.push({color: "geekblue", value: intl.get("products.button.eventProduct")});
        }
        if (record.tags?.length > 0) {
          const tagAttr = record.tags.map((t) => {
            return {color: "geekblue", value: t.value};
          });
          attrs = attrs.concat(...tagAttr);
        }
        let etcAttrs = Util.getProductAttrs(record);
        attrs = attrs.concat(...etcAttrs);

        return (
          <div style={{width: "100%"}}>
            {record.skus.length > 1 && (
              <div style={{textAlign: "left", padding: "5px 10px", backgroundColor: "#fafafa", display: "flex", gap: 20, alignItems: "center"}}>
                <div>
                  <img style={{width: 26, height: 26}} src={record.images.length > 0 ? record.images[0] + "?x-oss-process=image/resize,h_48,w_48" : NoImage}/>
                </div>
                <div>
                  <div>
                    <div>{record.name}</div>
                    {record.name !== record.nameKr && (
                      <div style={{color: "gray", fontSize: 11}}>{record.nameKr}</div>
                    )}
                  </div>
                </div>
                <div>
                  {attrs.map((attr, index) => (
                    <Tag key={index} color={attr.color}>{attr.value}</Tag>
                  ))}
                </div>
              </div>
            )}
            {record.skus.map((sku, index) => {
              const image = sku.skuImage ? sku.skuImage + "?x-oss-process=image/resize,h_48,w_48" : NoImage;
              return (
                <div key={sku.id} style={{width: "100%", display: "flex", alignItems: "center"}}>
                  <div className="table_col" style={{ marginRight: 10}}>
                    <Checkbox checked={selectedSkuIds.find(id => sku.id === id)}
                              onChange={(e) => onCheckChange(sku.id, e)} />
                  </div>
                  <div className="table_col" style={{width: "5%"}}><img src={image} style={{width: "48px", height: "48px"}}/></div>
                  <div className="table_col" style={{width: "40%", textAlign: "left", matginleft: 10}}>
                    {record.skus.length === 1 ? (
                      <>
                        <div>{record.name}</div>
                        {record.name !== record.nameKr && (
                          <div style={{color: "gray", fontSize: 11}}>{record.nameKr}</div>
                        )}
                        <div>
                          {attrs.map((attr, index) => (
                            <Tag key={index} color={attr.color}>{attr.value}</Tag>
                          ))}
                        </div>
                      </>
                    ) : (
                      <div>{sku.optionText}</div>
                    )}
                  </div>
                  <div className="table_col" style={{width: "15%", textAlign: "left"}}>{record.skus.length === 1 ? sku.optionText : ""}</div>
                  <div className="table_col" style={{width: "15%"}}>{sku.barCode}</div>
                  <div className="table_col" style={{width: "10%", textAlign: "right"}}>{Util.currencyFormat(sku.salesPrice, application.productCurrency)}</div>
                  <div className="table_col" style={{width: "10%", textAlign: "right"}}>{Util.currencyFormat(sku.minSalesPrice, application.productCurrency)}</div>
                  <div className="table_col" style={{width: "10%", textAlign: "right"}}>{Util.currencyFormat(sku.maxSalesPrice, application.productCurrency)}</div>
                </div>
              )})}
          </div>
        )
      }
    }];

  const idTypeName = (idType) => {
    if (idType === "id") {
      return intl.get("products.id");
    } else if (idType === "barCode" || idType === "barcode") {
      return intl.get("products.sku.barCode");
    } else if (idType === "hsCode") {
      return intl.get("products.sku.hsCode");
    } else if (idType === "skuCode") {
      return intl.get("products.sku.skuCode");
    } else {
      return intl.get("products.id");
    }
  };

  const subCategories = selectedCategory.id ? selectedCategory.subCategories : [];

  return (
    <Modal
        title={[<div key={1}><SettingOutlined/> {intl.get("products.searchModal.title")}</div>]}
        visible={true}
        onCancel={props.handleCancel}
        onOk={handleOk}
        // footer={[<Button key="submit" type="primary" onClick={handleOk}>{intl.get("common.button.ok")}</Button>,]}
        width={"80%"}
        bodyStyle={{margin: 0, padding: 0}}>
      <Layout>
        <Content className="modal-content">
          <Spin spinning={loading}>
            <div className="page-op-box">
              <div className="filter-box">
              <div>
                <div>
                  <Input style={{ width: 200 }} value={filter.name}
                         onChange={e => onFilterChanged('name', e.target.value)}
                         placeholder={intl.get("products.name")}
                         onPressEnter={() => searchProducts(1, filter)}/>
                  <Divider type="vertical" />
                  <Select allowClear={true}
                          value={filter.idType ? filter.idType : undefined}
                          placeholder={intl.get("products.id.kind")}
                          onClear={() => {onFilterChanged('idType', ''); onFilterChanged('idTarget', '')}}
                          onSelect={(value) => onFilterChanged('idType', value)} style={{width: 100}}>
                    <Select.Option value={"id"}>{intl.get("products.id")}</Select.Option>
                    <Select.Option value={"barcode"}>{intl.get("products.sku.barCode")}</Select.Option>
                    <Select.Option value={"hsCode"}>{intl.get("products.sku.hsCode")}</Select.Option>
                    <Select.Option value={"skuCode"}>{intl.get("products.sku.skuCode")}</Select.Option>
                  </Select>
                  <Divider type="vertical" />
                  <Input style={{ width: 200 }}
                         value={filter.idTarget}
                         onChange={e => onFilterChanged('idTarget', e.target.value)}
                         placeholder={idTypeName(filter.idType)}
                         onPressEnter={() => searchProducts(1, filter)}/>
                  <Divider type="vertical" />
                  <Checkbox checked={filter.published}
                            onChange={e => onFilterChanged('published', e.target.checked, true)}>
                    {intl.get("products.published")}
                  </Checkbox>
                  <Divider type="vertical" />
                  <Checkbox checked={filter.eventProduct || false}
                            disabled={props.disableEventProductFilter || false}
                            onChange={(e) => onFilterChanged('eventProduct', e.target.checked ? true : "", true)}>
                    {intl.get("products.eventProduct")}
                  </Checkbox>
                </div>
                <div style={{marginTop: 10}}>
                  <Select allowClear={true}
                          value={filter.brandId ? filter.brandId : undefined}
                          onSelect={value => onFilterChanged('brandId', value, true)}
                          placeholder={intl.get("products.brand")}
                          onClear={() => onFilterChanged('brandId', '', true)}
                          style={{width: 150}}>
                    {
                      brands.map(eachBrand => {
                        return (<Select.Option key={eachBrand.id} value={eachBrand.id}>{eachBrand.name}</Select.Option>)
                      })
                    }
                  </Select>
                  <Divider type="vertical" />
                  <Select value={filter.categoryId ? filter.categoryId : undefined}
                          placeholder={intl.get("products.category") + "1"}
                          allowClear={true}
                          onClear={() => onFilterChanged('categoryId', '', true)}
                          onSelect={onCategorySelect}
                          style={{width: 150}}>
                    { categories.map(eachCategory => {
                        return (<Select.Option key={eachCategory.id} value={eachCategory.id}>{eachCategory.name}</Select.Option>)
                      })
                    }
                  </Select>
                  <Divider type="vertical" />
                  <Select value={filter.subCategoryId ? filter.subCategoryId : undefined}
                          placeholder={intl.get("products.category") + "2"}
                          allowClear={true}
                          onClear={() => onFilterChanged('subCategoryId', '', true)}
                          onSelect={(value) => onFilterChanged('subCategoryId', value, true)}
                          style={{width: 150}}>
                    { subCategories.map(eachCategory => {
                        return (<Select.Option key={eachCategory.id} value={eachCategory.id}>{eachCategory.name}</Select.Option>)
                      })
                    }
                  </Select>
                  <Divider type="vertical" />
                  <Select value={filter.sort}
                          placeholder={intl.get("products.sort")}
                          onSelect={(value) => onFilterChanged('sort', value, true)}
                          style={{width: 150}}>
                    <Select.Option key={"latest"} value={"latest"}>{intl.get("products.sort.latest")}</Select.Option>
                    <Select.Option key={"lowest"} value={"lowest"}>{intl.get("products.sort.lowest")}</Select.Option>
                    <Select.Option key={"highest"} value={"highest"}>{intl.get("products.sort.highest")}</Select.Option>
                    <Select.Option key={"name_cn"} value={"name_cn"}>{intl.get("products.sort.name_cn")}</Select.Option>
                    <Select.Option key={"name_kr"} value={"name_kr"}>{intl.get("products.sort.name_kr")}</Select.Option>
                    <Select.Option key={"total_sales_desc"} value={"total_sales_desc"}>{intl.get("products.sort.total_sales_desc")}</Select.Option>
                  </Select>
                </div>
              </div>
              <div style={{marginLeft: "auto"}}>
                <div style={{width: 100}}>
                  <Button style={{width: 100}} type="primary" block size="default"
                          onClick={() => searchProducts(1, filter)}>
                    {intl.get("common.button.search")}
                  </Button>
                </div>
              </div>
            </div>
            </div>
            <div className="sku_search_pop" style={{marginTop: "10px", minHeight: 500}}>
              <Table size={'small'}
                     dataSource={products}
                     columns={columns}
                     rowKey={'id'}
                     title={() => intl.get("common.label.listTotal", {total: pagination.total})}
                     pagination={pagination}
                     onChange={productTableChanged}
              />
            </div>
          </Spin>
        </Content>
      </Layout>
    </Modal>
  )
}

export default SkuSearchPopup;