import {EventBroadcaster, SETTING_CONTEXT_AVAILABLE_EVENT_TOPIC} from "../event/event.broadcaster";

class SettingContext {
  constructor() {
    this._setting = {
      company: {
        id: 0,
        companyCode: "",
        name: "",
        adminDomain: "",
      },
      systemSettings: [],
      loaded: false,
    };
    this._available = false;
  }

  set setting(setting) {
    this._setting = setting;
    EventBroadcaster.broadcast(SETTING_CONTEXT_AVAILABLE_EVENT_TOPIC, setting);
  }

  get available() {
    return this._available;
  }

  get setting() {
    return this._setting;
  }
}

const instance = new SettingContext()
export {instance as SettingContext}
