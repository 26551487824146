import React, {useState} from "react";
import {Button, Select, Table, Upload, Space, Divider, message, Spin} from "antd";
import intl from "react-intl-universal";
import OrderApi from "../OrderApi";
import {UploadOutlined} from "@ant-design/icons";
import {adminConfig} from "../../../config/admin.config";
import Util from "../../../util/Util";
import {SettingContext} from "../../../auth/setting.context";
import axios from "axios";

const MAX_NUM_ORDERS = 200;

const UploadOrder = (props) => {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [fileSource, setFileSource] = useState("youma");
  const [fileList, setFileList] = useState([]);
  const [selectedOrderNos, setSelectedOrderNos] = useState([]);
  const [uploadResult, setUploadResult] = useState([]);

  const handleUploadClick = (e) => {
    if (fileList.length === 0) {
      message.error(intl.get("order.upload.selectFile"));
      return;
    }
    const formData = new FormData();
    formData.append('files', fileList[0]);
    formData.append("fileSource", fileSource);

    setLoading(true);

    let apiPath = adminConfig.apiServer() + "/orders/upload-preview";
    axios.post(apiPath, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      setLoading(false);
      let previewOrders = res.data;
      if (previewOrders.length > MAX_NUM_ORDERS) {
        message.warn(intl.get("order.upload.maxOrders"));
        previewOrders = previewOrders.slice(0, MAX_NUM_ORDERS);
      }
      setOrders(previewOrders);
    }).catch(err => {
      console.log("Error:", err);
    }).finally(() => {
      setLoading(false);
    });
  }

  const handleSendSelected = () => {
    const selectedOrders = [];
    orders.forEach(o => {
      if (selectedOrderNos.includes(o.externalOrderNo)) {
        selectedOrders.push(o);
      }
    });
    submitOrders(selectedOrders);
  };

  const handleSendAll = () => {
    submitOrders(orders);
  };

  const submitOrders = (targetOrders) => {
    if (targetOrders.length === 0) {
      message.error(intl.get("common.selectField", {name: intl.get("order.order")}));
      return;
    }
    if (props.match.path === "/upload-logistics-order") {
      targetOrders = targetOrders.map(o => {
        o.logisticsOrder = true;
        return o;
      });
    }
    setLoading(true);
    OrderApi.uploadOrders(targetOrders).then(res => {
      setUploadResult(res.data);
      setLoading(false);
    }).finally(() => {
      setLoading(false);
    });
  }

  const columns = [{
      title: intl.get("order.orderNo"),
      dataIndex: 'externalOrderNo',
      key: 'externalOrderNo',
      align: 'left',
    }, {
      title: intl.get("order.orderDate"),
      dataIndex: 'orderDate',
      key: 'orderDate',
      align: 'left',
      render: (text, value) => {
        return Util.getChinaLocalTime(text).substring(0, 10);
      }
    }, {
      title: intl.get("order.recipient"),
      dataIndex: 'recipient',
      key: 'recipient',
      align: 'left',
      render: (text, value) => {
        return (
          <div>
            {value.shippingAddress.recipient}
            <br/>
            {value.shippingAddress.addressProvince}{value.shippingAddress.addressCity}{value.shippingAddress.addressDistrict}
          </div>
        )
      }
    }, {
      title: intl.get("products.product"),
      dataIndex: 'orderTitle',
      key: 'orderTitle',
      align: 'left',
      width: "400px",
      render: (text, value) => {
        return value.orderSkus.map((orderSku, index) => {
          return (<div key={index}>{orderSku.productName}{orderSku.optionText ? " " + orderSku.optionText : ""}（¥{orderSku.salesAmount}) * {orderSku.shipQuantity}</div>)
        })
      }
    }, {
      title: intl.get("order.paymentAmountUser"),
      dataIndex: 'paymentAmountUser',
      key: 'paymentAmountUser',
      align: 'right',
    }];

  const rowSelection = {
    selectedRowKeys: selectedOrderNos,
    onChange: (selectedOrderNos) => {
      setSelectedOrderNos(selectedOrderNos);
    },
  };

  const uploadProps = {
    onRemove: file => {
      setFileList([]);
    },
    beforeUpload: file => {
      setFileList([file]);
      return false;
    },
    itemRender: (originNode, file, currFileList) => {
      // Upload 컴포넌트가 기본적으로 파일이름을 아래로 나타내기 때문에 자체적으로 표시하는 로직에서는 보여주지 않음
      return null
    },
    fileList,
  };

  const onChangeFileSource = (value) => {
    if (value === "taobao") {
      message.error("타오바오 주문 업로드는 준비중입니다.");
      return;
    }
    setFileSource(value)
  };

  const fileName = fileList.length > 0 ? fileList[0].name : "";
  const numErrors = uploadResult.filter(r => !r.success).length;
  const numSucceeded = uploadResult.length - numErrors;

  return (
    <Spin spinning={loading}>
      <div className="page-op-box">
        <div className="filter-box">
          <Space>
            <div>{intl.get("order.upload.fileType")}</div>
            <Select value={fileSource} style={{ width: 200, marginRight: 10 }} onChange={onChangeFileSource}>
              <Select.Option key={"youma"} value={"youma"}>{SettingContext.setting.company.serviceName}{intl.get("order.upload.fileType.postfix")}</Select.Option>
              <Select.Option key={"taobao"} value={"taobao"}>淘宝{intl.get("order.upload.fileType.postfix")}（正在准备）</Select.Option>
            </Select>
            <Upload {...uploadProps}>
              <Button>
                <UploadOutlined /> {intl.get("products.upload.help3")}
              </Button>
            </Upload>
            <div style={{fontSize: 14}}>{fileName}</div>
            <div style={{marginLeft: 20, display: "flex"}}>
              <div style={{width: 150}}>
                <Button style={{width: 100}} type="primary" block size="default" onClick={handleUploadClick}>{intl.get("order.upload.uploadFile")}</Button>
              </div>
            </div>
          </Space>
        </div>
        <div className="extra-op-box">
          <a href={Util.getOssBucket() + "/downloads/order_upload_sample.xlsx"} download="order-sample" target='_blank'>
            <Button type="default" size="small" disabled={fileSource !== "youma"}>
              {SettingContext.setting.company.serviceName}{intl.get("order.upload.fileType.postfix")}{intl.get("common.label.download")}
            </Button>
          </a>
          <Divider type={"vertical"}/>
          <Button type="default" size="small" disabled={orders.length === 0} onClick={handleSendSelected}>{intl.get("order.upload.uploadSelected")}</Button>
          <Divider type={"vertical"}/>
          <Button type="default" size="small" disabled={orders.length === 0} onClick={handleSendAll}>{intl.get("order.upload.uploadAll")}</Button>
        </div>
      </div>
      <div style={{marginTop: "10px"}}>
        {uploadResult.length > 0 && (
          <div style={{marginTop: "10px"}}>
            <div style={{fontWeight: 700}}>{intl.get("order.order")}: {uploadResult.length} 건</div>
            <ul>
              <li>{intl.get("common.label.success")}: {intl.get("common.label.count", {count: numSucceeded})}</li>
              <li>
                <div style={{color: "red"}}>{intl.get("common.label.fail")}: {intl.get("common.label.count", {count: numErrors})}</div>
                <div>
                  <ul>
                    {uploadResult.filter(r => !r.success).map((res, index) => {
                      return (<li key={index}>{res.orderNo}: {res.errorMessage}</li>);
                    })}
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        )}
        <Table size={'small'}
               dataSource={orders}
               columns={columns}
               rowKey={'externalOrderNo'}
               title={() => intl.get("common.label.listTotal", {total: orders.length})}
               pagination={false}
               rowSelection={rowSelection}
        />
      </div>
    </Spin>
  )
};

export default UploadOrder;