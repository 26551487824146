import React, {useState} from 'react';

import {message, Modal, Spin, Input, Layout, Collapse} from "antd";
import OrderApi from "./OrderApi";
import {SettingOutlined} from "@ant-design/icons";

const {Content, Sider} = Layout;
const { Panel } = Collapse;

const DeliveryUploadModal = (props) => {
  const [deliveryText, setDeliveryText] = useState("");
  const [uploading, setUploading] = useState(false);

  const handleOk = () => {
    const orderDeliverys = [];
    const orderNoMap = new Map();

    let errMsg = "";
    deliveryText.split("\n").forEach((line, index) => {
      if (errMsg !== "") {
        return;
      }
      if (line.trim().length === 0) {
        return;
      }

      // 주문번호, 배송회사, 배송번호, 제품순중량, 총중량
      const lineTokens = line.split(",");
      if (lineTokens.length < 3) {
        errMsg = (index + 1) + " 번째의 데이터를 확인하세요. 양식에 맞지 않습니다.";
        return;
      }

      const orderNo = lineTokens[0].trim();
      if (orderNoMap.has(orderNo)) {
        return;
      }

      const orderDelivery = {
        orderNo: orderNo,
        deliveryCompany: lineTokens[1].trim(),
        deliveryNo: lineTokens[2].trim(),
      };

      if (lineTokens.length > 3 && lineTokens[3].trim().length > 0) {
        if (isNaN(lineTokens[3].trim())) {
          errMsg = (index + 1) + " 번째의 제품 순중량(Net Weight) 데이터를 확인하세요. 숫자가 아닙니다.";
          return;
        }
        orderDelivery.netWeight = parseFloat(lineTokens[3].trim());
      }

      if (lineTokens.length > 4 && lineTokens[4].trim().length > 0) {
        if (isNaN(lineTokens[4].trim())) {
          errMsg = (index + 1) + " 번째의 제품 총중량(Gross Weight) 데이터를 확인하세요. 숫자가 아닙니다.";
          return;
        }
        orderDelivery.grossWeight = parseFloat(lineTokens[4].trim());
      }

      orderDeliverys.push(orderDelivery);
    });
    if (errMsg !== "") {
      message.error(errMsg);
      return;
    }
    if (orderDeliverys.length === 0) {
      message.error("주문을 입력하세요.");
      return;
    }
    setUploading(true);

    OrderApi.uploadOrderDeliveries(orderDeliverys).then(res => {
      message.success("정상적으로 업로드 되었습니다.");
      props.onOk();
    }).finally(() => {
      setUploading(true);
    })
  };

  const handleCancel = () => {
    props.handleCancel();
  }

  return (
      <Modal  visible={true}
              title={[<div key={1}><SettingOutlined/> 배송정보 업로드</div>]}
              onCancel={() => props.onCancel()}
              onOk={handleOk}
              bodyStyle={{margin: 0, padding: 0}}
              width={"900px"}>
        <Spin spinning={uploading}>
          <Layout>
            <Content className="modal-content">
              <div>
                <Collapse defaultActiveKey={['1']}>
                  <Panel header="배송정보 입력 가이드" key="1">
                    <div>아래 입력한에 다음과 같은 형태로 컬럼이 ","로 분리된 형태로 하나의 라인에 하나의 주문정보 입력합니다.</div>
                    <div>제품순중량과 전체중량은 옵션값으로 입력하지 않아됩니다/</div>
                    <div>회사코드는 顺丰速运: SF, 圆通: YTO, 通达: YTG, 韵达: YUNDA 입니다.(여기 없는 코드는 관리자에게 문의)</div>
                    <div style={{marginTop: 10, fontWeight: "bold"}}>주문번호,물류회사코드,택배번호,제품순중량(NetWeight),전체중량(GrossWeight)</div>
                  </Panel>
                  <Panel header="배송정보 입력 예제" key="2">
                    20211006234567,SF,SF124567890<br/>
                    20211006234568,SF,SF124567870<br/>
                    20211006234569,SF,SF124567450,2.3,3.1<br/>
                  </Panel>
                </Collapse>
                <div style={{marginTop: 10}}>
                  <Input.TextArea rows={20} value={deliveryText} onChange={(e) => setDeliveryText(e.target.value)}/>
                </div>
              </div>
            </Content>
          </Layout>
        </Spin>
      </Modal>
  )
}

export default DeliveryUploadModal;