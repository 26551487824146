import React, {useEffect, useState} from "react";
import {Descriptions, Input, InputNumber} from "antd";
import FileListPopup from "../file_manager/FileListPopup";
import intl from "react-intl-universal";

const { Search } = Input;

const ImageSetting = (props) => {
  const [settingValues, setSettingValues] = useState({});
  const [fileModalVisible, setFileModalVisible] = useState(false);
  useEffect(() => {
    setSettingValues({...props.settingValues});
  }, [props.settingValues]);

  const okFileModal = (modalName, filePathList) => {
    if (filePathList.length === 0) {
      return;
    }
    const settingValuesState = {...settingValues};
    settingValuesState.image = filePathList[0];
    setSettingValues(settingValuesState);
    setFileModalVisible(false);
    props.applySetting(props.id, settingValuesState);
  };

  const onImageChange = (e) => {
    const settingValuesState = {...settingValues};
    settingValuesState.image = e.target.value;
    setSettingValues(settingValuesState);
    props.applySetting(props.id, settingValuesState);
  };

  const onHeightChange = (value) => {
    const settingValuesState = {...settingValues};
    settingValuesState.height = value;
    setSettingValues(settingValuesState);
    props.applySetting(props.id, settingValuesState);
  };

  return (
      <div>
        <Descriptions title={intl.get("page_setting.edit.image.setting")}
                      bordered layout="vertical" size={"small"} column={1}>
          <Descriptions.Item label={intl.get("page_setting.edit.media.image")}>
            <Search
                allowClear
                enterButton={intl.get("page_setting.edit.media.button.contents")}
                value={settingValues.image}
                onChange={onImageChange}
                onSearch={() => setFileModalVisible(true)}
            />

          </Descriptions.Item>
          <Descriptions.Item label={intl.get("page_setting.edit.banner.height")}>
            <InputNumber value={settingValues.height} min={1} max={50000} defaultValue={130} onChange={onHeightChange}></InputNumber>
            <div>{intl.getHTML("page_setting.edit.image.size.help")}</div>
          </Descriptions.Item>
        </Descriptions>
        <FileListPopup id={'imageSelectModal'}
                       fileKind={"index"}
                       visible={fileModalVisible}
                       multipleFile={false}
                       canSelect={true}
                       handleCancel={() => setFileModalVisible(false)}
                       handleOk={okFileModal}
        />
      </div>
  )
};

export default ImageSetting;