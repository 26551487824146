import React from 'react';

const RequiredLabel = ({label}) => {
  return (
    <div>
      <span style={{marginRight: 4, color: "#ff4d4f"}}>*</span>
      <span>{label}</span>
    </div>
  )
};

export default RequiredLabel;