import React, {useEffect, useState} from "react";
import SupplierApi from "./SupplierApi";
import intl from "react-intl-universal";
import {Button, Input, Table, Divider, Spin, DatePicker} from "antd";
import Util from "../../util/Util";
import NoImg from "../../asset/images/no_image.png";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const initialFilter = {
  startDate: dayjs().subtract(31, "day").format("YYYY-MM-DD"),
  endDate: dayjs().format("YYYY-MM-DD"),
  productId: "",
}

const SupplierOrderList = (props) => {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [filter, setFilter] = useState(initialFilter);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  useEffect(() => {
    searchOrders(pagination);
  }, []);

  const onDateOk = (value, dateString) => {
    if (value[1]) {
      const {filter} = props;
      filter.startDate =  value[0].format("YYYY-MM-DD HH:mm:ss");
      filter.endDate = value[1].format("YYYY-MM-DD HH:mm:ss");
      props.setFilter(filter);
    }
  }

  const searchOrders = (paginationState) => {
    setLoading(true);
    SupplierApi.getOrders(getSearchParam(paginationState)).then(res => {
      const pagingResult = res.data;
      const pagination = {
        current: pagingResult.page,
        pageSize: pagingResult.pageSize,
        total: pagingResult.count,
      }
      setOrders(pagingResult.data);
      setPagination(pagination);
    }).finally(() => {
      setLoading(false);
    });
  }

  const getSearchParam = (pagination) => {
    const params = {
      pageSize: pagination.pageSize,
      page: pagination.current,
    };
    if (filter.productId) {
      params.productId = filter.productId
    } else {
      params.startDate = filter.startDate;
      params.endDate = filter.endDate;
    }
    return params;
  };

  const pageChanged = (pagination) => {
    searchOrders(pagination);
  };

  const onSearchClick = () => {
    const paginationState = {...pagination};
    paginationState.current = 1;
    searchOrders(paginationState);
  }

  const filterChanged = (fieldName, value) => {
    const filterState = {...filter}
    filterState[fieldName] = value;
    setFilter(filterState);
  };

  const columns = [{
    title: "ID",
    dataIndex: 'id',
    key: 'id',
    align: 'center',
  }, {
    title: intl.get("order.orderNo"),
    dataIndex: 'orderNo',
    key: 'orderNo',
    align: 'center',
    width: 200,
  }, {
    title: intl.get("order.orderDate"),
    dataIndex: 'orderDate',
    key: 'orderDate',
    width: 100,
    render: (text, record) => {
      return Util.getLocalTime(text).substring(0, 10);
    }
  }, {
    title: () => (<div>{intl.get("order.orderProduct")}<br/>({intl.get("order.sku.salesPrice")} * {intl.get("order.sku.shipQuantity")} = {intl.get("order.sku.salesAmount")})</div>),
    key: 'orderSkus',
    dataIndex: 'orderSkus',
    align: 'left',
    render: (text, record) => {
      const options = record.orderSkus.map((sku, index) => {
        return (
          <div key={index} style={{display: "flex", padding: 10, color: "#595961", fontSize: 12}}>
            <div style={{marginTop: 2}}>
              <img style={{width: "48px", height: "48px"}} src={sku.skuImage ? sku.skuImage + "?x-oss-process=image/resize,h_48,w_48" : NoImg}/>
            </div>
            <div style={{paddingLeft: 10}}>
              <div>{sku.productName}{sku.optionText ? ": " + sku.optionText : ""}</div>
              <div>{intl.get("products.taxRate")}: {sku.taxRate}%, {intl.get("products.sku.barCode")}: {sku.barCode}</div>
              <div>
                <span style={{display: "inline-block", width: 100}}>
                  {Util.currencyFormat(sku.salesPrice.originPrice, record.productCurrency)}
                  {record.productCurrency !== record.userCurrency && (
                    <span style={{color: "gray", fontSize: 11, marginLeft: 5}}>{Util.currencyFormat(sku.salesPrice.secondaryPrice, record.userCurrency)}</span>
                  )}
                </span>
                <span style={{display: "inline-block", width: 50}}>x {sku.shipQuantity}</span>
                <span style={{display: "inline-block", width: 100}}>
                  {Util.currencyFormat(sku.salesAmount.originPrice, record.productCurrency)}
                  {record.productCurrency !== record.userCurrency && (
                    <span style={{color: "gray", fontSize: 11, marginLeft: 5}}>{Util.currencyFormat(sku.salesAmount.secondaryPrice, record.userCurrency)}</span>
                  )}
                </span>
              </div>
            </div>
          </div>
        )
      })
      return (<div>{options}</div>)
    }
  }, {
    title: intl.get("order.status"),
    dataIndex: 'currentStatus',
    key: 'currentStatus',
    width: 100,
    render: (text, record) => {
      return (
        <div>
          {record.currentStatus === "finished" ? intl.get("order.status." + record.finishedStatus) : intl.get("order.status." + record.currentStatus)}
        </div>
      )
    }
  }];

  const dateRange = [dayjs(filter.startDate), dayjs(filter.endDate)];

  return (
    <div className="page-content">
      <Spin spinning={loading}>
        <div className="page-op-box">
          <div className="filter-box">
            <Input style={{width: 100}} value={filter.productId} placeholder={intl.get("products.id")} onChange={(e) => filterChanged('productId', e.target.value)}/>
            <Divider type={"vertical"}/>
            <RangePicker
              format="YYYY-MM-DD"
              placeholder={[intl.get("order.searchOrderDateStart"), intl.get("order.searchOrderDateEnd")]}
              onOk={onDateOk}
              value={dateRange}
              allowClear={false}
              style={{ marginRight: 10 }}
            />
            <div style={{marginLeft: "auto"}}>
              <div style={{width: 100}}>
                <Button style={{width: 100}} type="primary" block size="default" onClick={onSearchClick}>{intl.get("common.button.search")}</Button>
              </div>
            </div>
          </div>
        </div>
        <div style={{marginTop: "10px"}}>
          <Table size={'small'}
                 dataSource={orders}
                 columns={columns}
                 rowKey={'id'}
                 title={() => intl.get("common.label.listTotal", {total: pagination.total})}
                 pagination={pagination}
                 onChange={pageChanged}
          />
        </div>
      </Spin>
    </div>
  )
}

export default SupplierOrderList;