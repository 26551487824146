import Carousel from "react-multi-carousel";
import {PriceView} from "./PriceView";
import React from "react";
import ProductOneColumn from "./ProductOneColumn";

const ProductSlider = (props) => {
  const productWidth = props.productWidth ? props.productWidth : 100;
  const imageSize = productWidth - 10;
  const titleWidth = productWidth - 10;
  const numSlideItems = Math.trunc(375 / productWidth);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: numSlideItems,
    },
  };
  const {settingValues} = props;
  const backgroundColor = settingValues.backgroundColor || "#ffffff";
  let padding = "16,16,16,16";
  if (settingValues.padding) {
    padding = settingValues.padding;
  }
  const paddingStyle = padding.split(",").map(p => p.trim() + "px").join(" ");

  const style = {
    backgroundColor: backgroundColor,
    padding: paddingStyle,
  };

  if (settingValues.backgroundImage) {
    style.backgroundImage = "url('" + settingValues.backgroundImage + "')";
    style.backgroundSize = "cover";
    style.backgroundRepeat = "no-repeat";
    style.backgroundPosition = "center center";
  }

  return (
    <div className="slider-box" style={style}>
      <div style={{backgroundColor: backgroundColor}}>
        {props.title && (<div className="header">{props.title}</div>)}
        <Carousel responsive={responsive}
                  autoPlay={false}
                  showDots={false}
                  deviceType={"desktop"}
                  removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
        >
          {
            props.products.map((product, index) => {
              const marginLeft = (index === 0) ? 10 : 0;
              return (
                <div key={product.id} className="item-box" style={{width: productWidth, marginLeft: marginLeft}} onDragStart={props.handleDragStart}>
                  <img style={{width: imageSize, height: imageSize}} src={product.images[0] + "?x-oss-process=image/resize,w_256"}/>
                  <div style={{width: titleWidth, marginTop: 16}}>
                    <div className="u-line-2 title" style={{width: titleWidth}}>{product.name}</div>
                    <PriceView listPrice={product.listPrice}
                               salesPrice={product.salesPrice}
                               width={titleWidth}
                               {...props}
                    />
                  </div>
                </div>
              )
            })
          }
        </Carousel>
      </div>
    </div>
  )
};

export default ProductSlider;