import {adminConfig} from "../../config/admin.config";
import axios from "axios";

const API_BASE_URL = adminConfig.apiServer();

export default class QuestionApi {
  static getQuestion = (id) => {
    let apiPath = `${API_BASE_URL}/questions/${id}?projection=webAdmin&`;
    return axios.get(apiPath);
  };
  static getQuestions = (options) => {
    let apiPath = `${API_BASE_URL}/questions?projection=webAdmin&`;

    if (options) {
      apiPath += Object.keys(options).map(key => {
        return key + "=" + options[key]
      }).join("&");
    }
    return axios.get(apiPath);
  };

  static submitQuestion = (question) => {
    let apiPath = `${API_BASE_URL}/questions`;
    if (question.id) {
      apiPath = `${API_BASE_URL}/questions/${question.id}?projection=webAdmin`;
      return axios.put(apiPath, question);
    } else {
      const apiPath = `${API_BASE_URL}/questions?projection=webAdmin`;
      return axios.post(apiPath, question);
    }
  };

  static deleteQuestion = (id) => {
    let apiPath = `${API_BASE_URL}/questions/${id}`;
    return axios.delete(apiPath);
  };
}