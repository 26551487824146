import React from "react";
import Util from "../../../util/Util";
import intl from "react-intl-universal";

const ImageSlot = (props) => {
  const {id, settingValues} = props;

  const height = settingValues.height ? settingValues.height : "150";
  if (!settingValues || !settingValues.image) {
    return (
        <div className={"empty-slot"} style={{height: height + "px", marginBottom: 0, backgroundColor: "white"}}>
          {intl.get("page_setting.edit.media.image")} / {intl.get("page_setting.edit.media.video")}
        </div>
    )
  }

  const imagePath = settingValues.image?.endsWith(".svg") ? Util.encodeImagePath(settingValues.image) :
    Util.encodeImagePath(settingValues.image) + "?x-oss-process=image/resize,w_375,h_" + height;

  return (
      <div style={{height: height + "px", marginBottom: 0, backgroundColor: "white"}}>
        <section className="">
          <img width="375px" height={height + "px"} src={imagePath}/>
        </section>
      </div>
  )
}

export default ImageSlot;