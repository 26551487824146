import React from 'react';
import "./ApplicationPageEidt.less";
import PageDesigner from "./PageDesigner";

const ApplicationPageEdit = (props) => {
  const pageSaved = () => {
    props.loadAppPages();
  };

  return (
    <PageDesigner
      pageType={props.pageType}
      pageSaved={pageSaved}
      {...props}
    />
  )
}

export default ApplicationPageEdit;