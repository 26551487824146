import React, {useEffect, useState} from "react";
import {Spin, Table, Button, Space, Divider, message, Descriptions, Modal} from "antd";
import ClearanceApi from "./ClearanceApi";
import Util from "../../../util/Util";
import intl from "react-intl-universal";
import PrettyData from "pretty-data";
import JSONPretty from "react-json-pretty";
import {SettingOutlined} from "@ant-design/icons";

const SendOrderToDeclareCompany = (props) => {
  const [updating, setUpdating] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [declareCompanyInterfaces, setDeclareCompanyInterfaces] = useState([]);
  const [selectedDeclareCompanyInterface, setSelectedDeclareCompanyInterface] = useState({});
  const [visibleModal, setVisibleModal] = useState(false);

  useEffect(() => {
    loadDeclareCompanyInterfaces(props.order.id);
  }, [props.order.id]);

  const loadDeclareCompanyInterfaces = (orderId) => {
    ClearanceApi.getDeclareCompanyInterfaces(orderId).then(res => {
      const declareCompanyInterfaces = res.data;
      const succeedItems = declareCompanyInterfaces.find(pd => pd.status === "success");
      setDisabled(succeedItems ? true : false);
      setDeclareCompanyInterfaces(declareCompanyInterfaces);
    })
  };

  const showDetail = (declareCompanyInterface, event) => {
    event.stopPropagation();
    setSelectedDeclareCompanyInterface(declareCompanyInterface);
    setVisibleModal(true);
  };

  const deleteDeclareCompanyInterface = (id, event) => {
    event.stopPropagation();
    if(!window.confirm(intl.get("common.confirm.delete", {name: intl.get("order.clearance.declare_company_interface_type.send_order")}))) {
      return;
    }
    ClearanceApi.deleteDeclareCompanyInterface(id).then(res => {
      message.info(intl.get("common.message.deleted"));
      loadDeclareCompanyInterfaces(props.order.id);
    }).catch(e => {
      console.log("deleteDeclareCompanyInterface err:", e);
    }).finally(() => {
      setUpdating(false);
    });
  };

  const sendToDeclareCompany = () => {
    if(!window.confirm(intl.get("order.clearance.sendToLogistics.confirm"))) {
      return;
    }
    setUpdating(true);
    ClearanceApi.sendOrderToDeclareCompany(props.order.id).then(res => {
      message.info(intl.get("common.message.success"));
      loadDeclareCompanyInterfaces(props.order.id);
    }).catch(e => {
      console.log("sendToDeclareCompanyI err:", e);
    }).finally(() => {
      setUpdating(false);
    });
  };

  const testReceiveFromDeclareCompany = (testType) => {
    const {order} = props;
    if(!window.confirm("주문 정보 전송 후 신고회사로 부터 회신을 받는 테스트입니다. 개발 환경에서만 사용하세요.")) {
      return;
    }
    let notifyType = "";
    let data = "";
    if (testType === "delivery") {
      notifyType = "03";
      data = "{\"LOGISTICS_NO\":\"SF1346582724145\",\"LOGISTICS_MARK\":\"\",\"TRANSPORT_SERVICE_CODE\":\"SF\",\"ORDER_NO\":\"" + order.declareOrderNo + "\"}";
    } else if (testType === "order_1_1") {
      notifyType = "04";
      data = "{\"COMMIT_TIME\":\"20220117102118\",\"STATUS_MSG\":\"[Code:1800;Desc:逻辑校验通过]\",\"STATUS\":\"1\",\"ORDER_NO\":\"" + order.declareOrderNo + "\"}";
    } else if (testType === "order_1_2") {
      notifyType = "04";
      data = "{\"COMMIT_TIME\":\"20220117102516\",\"STATUS_MSG\":\"清单新增申报成功[0F593D1B-FEE6-49D7-B955-FDC745DE5CF1][电商企业编码：37109608P0订单编号：601020220116000000238713]\",\"STATUS\":\"1\",\"ORDER_NO\":\"" + order.declareOrderNo + "\"}";
    } else if (testType === "order_3") {
      notifyType = "04";
      data = "{\"COMMIT_TIME\":\"20220117102119\",\"STATUS_MSG\":\"放行\",\"STATUS\":\"3\",\"ORDER_NO\":\"" + order.declareOrderNo + "\"}";
    }
    setUpdating(true);
    ClearanceApi.testReceiveFromDeclareCompany(props.order.id, notifyType, data).then(res => {
      if (res.data.success) {
        message.info(intl.get("common.message.success"));
        loadDeclareCompanyInterfaces(props.order.id);
      } else {
        message.error(res.data.error_msg);
      }
    }).catch(e => {
      console.log("testReceiveFromDeclareCompany err:", e);
    }).finally(() => {
      setUpdating(false);
    });
  };

  const columns = [{
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  }, {
    title: intl.get("order.clearance.table.date"),
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text, record) => {
      return (<div>{Util.getChinaLocalTime(record.createdAt)}</div>);
    }
  }, {
    title: intl.get("order.clearance.table.type"),
    dataIndex: 'type',
    key: 'type',
    render: (text, record) => {
      return (
        <div>{intl.get("order.clearance.declare_company_interface_type." + record.type)}</div>
      )
    }
  }, {
    title: intl.get("order.clearance.table.status"),
    dataIndex: 'status',
    key: 'status',
    render: (text, record) => {
      return (<div>{text === "success" ? intl.get("order.clearance.table.success") : intl.get("order.clearance.table.failed")}</div>)
    }
  }, {
    title: intl.get("order.clearance.table.errorMessage"),
    dataIndex: 'errorMessage',
    key: 'errorMessage',
    render: text => {
      return (<div style={{display: "flex", alignItems: "center", fontSize: 11, width: 200, height: 40, overflow: "auto"}}>{text}</div>)
    }
  }, {
    title: '',
    dataIndex: 'id',
    key: 'button',
    render: (text, record) => {
      return record.status === "fail"
        ? (<Button size="small" onClick={(event) => deleteDeclareCompanyInterface(record.id, event)}>{intl.get("common.button.delete")}</Button>)
        : (<div></div>)
    }
  }];

  const isAdmin = Util.isAdmin();

  return (
    <Spin spinning={updating}>
      <div>
        <div style={{ display: "flex", marginBottom: 10}}>
          <div>
            <div>{intl.get("order.clearance.sendToLogistics.help1")}</div>
            <div>{intl.getHTML("order.clearance.sendToLogistics.help2")}</div>
          </div>
          <div style={{marginLeft: "auto"}}>
            <Button type="primary" size="small" disabled={disabled} onClick={sendToDeclareCompany}>{intl.get("order.clearance.declare_company_interface_type.send_order")}</Button>
          </div>
        </div>
        { isAdmin && process.env.REACT_APP_ENV !== "production" && (
          <div style={{marginBottom: 10}}>
            <Button type="primary" size="small" onClick={() => testReceiveFromDeclareCompany('delivery')}>{"배송회신테스트"}</Button>
            <Divider type={"vertical"}/>
            <Button type="primary" size="small" onClick={() => testReceiveFromDeclareCompany('order_1_1')}>{"주문회신테스트(逻辑校验通过)"}</Button>
            <Divider type={"vertical"}/>
            <Button type="primary" size="small" onClick={() => testReceiveFromDeclareCompany('order_1_2')}>{"주문회신테스트(清单新增申报成功)"}</Button>
            <Divider type={"vertical"}/>
            <Button type="primary" size="small" onClick={() => testReceiveFromDeclareCompany('order_3')}>{"주문회신테스트(放行)"}</Button>
            <Divider type={"vertical"}/>
          </div>
        )}
        <div style={{cursor: "pointer"}}>
          <Table size={'small'}
                 dataSource={declareCompanyInterfaces}
                 columns={columns}
                 rowKey={'id'}
                 pagination={false}
                 onRow={(record, rowIndex) => {
                   return {
                     onClick: event => {
                       showDetail(record, event);
                     },
                   }
                 }}
          />
        </div>
      </div>
      {visibleModal && (
        <DeclareCompanyInterfaceDetailModal
          declareCompanyInterface={selectedDeclareCompanyInterface}
          handleOk={() => setVisibleModal(false)}/>
      )}
    </Spin>
  );
};

const DeclareCompanyInterfaceDetailModal = (props) => {
  const decodeFormEncodedData = (text) => {
    return text.split("&").map((eachKeyVal) => {
      const keyValue = eachKeyVal.split("=");
      const key = keyValue[0];
      const value = decodeURIComponent(keyValue[1]);

      return [key, value];
    })
  };

  const getRequestView = () => {
    const {declareCompanyInterface} = props;
    if (!declareCompanyInterface.type || declareCompanyInterface.type.indexOf("callback") >= 0) {
      return (<div></div>)
    }
    const value = declareCompanyInterface.requestBody;
    let tradeList = "";
    const keyValues = decodeFormEncodedData(value).map((eachKeyVal, index) => {
      if (eachKeyVal[0] === "trade_list") {
        tradeList = eachKeyVal[1];
      } else {
        return (<div key={"param-" + declareCompanyInterface.id + "-" + index}>{eachKeyVal[0]} : {eachKeyVal[1]}</div>)
      }
    })

    return (
      <div className={"request_message"}>
        <div>
          <div>{keyValues}</div>
          <div><JSONPretty id={"json-pretty-" + declareCompanyInterface.id} data={tradeList}></JSONPretty></div>
        </div>
        <hr/>
        <div style={{display: "block", width: "100%"}}>{value}</div>
      </div>
    )
  };

  const getResponseView = () => {
    const {declareCompanyInterface} = props;
    if (!declareCompanyInterface.type) {
      return (<div></div>)
    }
    const value = declareCompanyInterface.responseBody;

    return (
      <div className={"request_message"}>
        <JSONPretty id={"json-pretty-res-" + declareCompanyInterface.id} data={value}></JSONPretty>
      </div>
    )
  };

  return (
    <Modal title={[<div key={1}><SettingOutlined/> {intl.get("order.clearance.declare_company_interface_type.send_order")}</div>]}
           visible={true}
           onOk={() => props.handleOk()}
           onCancel={() => props.handleOk()}
           width="80%"
           cancelButtonProps={{ style: { display: 'none' } }}>
      <div>
        <Descriptions  bordered
                       layout={"vertical"}
                       size={"small"}
                       column={2}
        >
          <Descriptions.Item label={intl.get("order.clearance.sendToLogistics.request")}>
            { getRequestView() }
          </Descriptions.Item>
          <Descriptions.Item label={intl.get("order.clearance.sendToLogistics.response")}>
            { getResponseView() }
          </Descriptions.Item>
        </Descriptions>
      </div>
    </Modal>
  )
};

export default SendOrderToDeclareCompany;