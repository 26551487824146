import React, {useEffect, useState} from 'react';
import { Switch, Route } from 'react-router-dom';

import SystemSettingList from './SystemSettingList';
import SystemSettingApi from "./SystemSettingApi";

const SystemSetting = (props) => {
  const [systemSettings, setSystemSettings] = useState([]);
  const [parentPath, setParentPath] = useState("");

  useEffect(() => {
    loadSystemSettings();
  }, []);

  useEffect(() => {
    setParentPath(props.match.path);
  }, [props.match.path]);

  const loadSystemSettings = () => {
    SystemSettingApi.getSystemSettings().then(res => {
      setSystemSettings(res.data);
    })
  };

  const setUpdatedSystemSettings = (updatesValues) => {
    setSystemSettings(updatesValues);
  }

  // NOTICE: path argument를 사용하기 위해서는 Route의 순서가 중요. new -> edit -> show 순서로 작성
  return (
      <div>
        <div className="page-content">
          <Switch>
            <Route path={props.match.path} exact
                   render={(props) => (
                       <SystemSettingList systemSettings={systemSettings}
                                          loadSystemSettings={loadSystemSettings}
                                          setSystemSettings={setUpdatedSystemSettings}
                                          parentPath={parentPath} {...props}/>) } />
            />
          </Switch>
        </div>
      </div>
  );
}

export default SystemSetting;