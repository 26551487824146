import React, {useState, useEffect} from 'react';
import { Switch, Route } from 'react-router-dom';
import {useHistory} from "react-router-dom";

import UserApi from './UserApi';
import UserList from './UserList';
import UserEdit from "./UserEdit";
import UserListPopUp from "./UserListPopup";

const initialtPagination = {
  current: 1,
  pageSize: 20,
  total: 0,
}

const initialFilter = {
  name: "",
  id: "",
};

const User = (props) => {
  const history = useHistory();

  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState(initialtPagination);
  const [filter, setFilter] = useState(initialFilter);
  const [loading, setLoading] = useState(false);
  const [parentPath, setParentPath] = useState("");

  useEffect(() => {
    if (props.match) {
      setParentPath(props.match.path);
    }
  }, [props.match?.path]);

  useEffect(() => {
    searchUsers(pagination);
  }, []);

  const searchUsers = (pagination) => {
    setLoading(true);
    UserApi.getUsers(getSearchParam(pagination)).then(res => {
      const pagingResult = res.data;
      const pagination = {
        current: pagingResult.page,
        pageSize: pagingResult.pageSize,
        total: pagingResult.count,
      }
      setUsers(pagingResult.data);
      setPagination(pagination);
      setLoading(false);
    });
  };

  const getSearchParam = (pagination) => {
    const params = {
      pageSize: pagination.pageSize,
      page: pagination.current,
      ...filter
    };
    return params;
  };

  const setFilterState = (filter) => {
    setFilter(filter);
  };

  const setUser = (mUser) => {
    const usersState = [...users];
    const changedUsers = usersState.map((user) => {
      if (user.id === mUser.id) {
        return mUser;
      } else {
        return user;
      }
    });

    setUsers(changedUsers);
  };

  const showDetail = (user) => {
    history.push(`/users/${user.id}`);
  };

  if (props.isPopup) {
    return (
      <UserList users={users}
                filter={filter}
                loading={loading}
                pagination={pagination}
                setFilter={setFilterState}
                searchUsers={searchUsers}
                onClickUser={props.onClickUser}
                {...props}/>
    )
  };

  // NOTICE: path argument를 사용하기 위해서는 Route의 순서가 중요. new -> edit -> show 순서로 작성
  return (
      <div>
        {props.match?.path && (
          <div className="page-content">
            <Switch>
              <Route path={props.match.path} exact
                     render={(props) =>
                         (<UserList users={users}
                                    filter={filter}
                                    loading={loading}
                                    pagination={pagination}
                                    setFilter={setFilterState}
                                    searchUsers={searchUsers}
                                    parentPath={parentPath}
                                    onClickUser={showDetail}
                                    {...props}/>) } />

              <Route path={`${props.match.path}/:userId`}
                     render={(props) =>
                         ( <UserEdit setUser={setUser}
                                     parentPath={parentPath}
                                     {...props}/>)} />
              />

            </Switch>
          </div>
        )}
      </div>
  )
};

export default User;