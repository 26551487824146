import React, {useEffect, useState} from "react";
import {Button, Form, Input, message, Checkbox, Divider, Modal} from "antd";
import CompanyUserApi from "../../pages/company/CompanyUserApi";
import "./Login.less";
import LogoImg from "../../asset/images/youma_logo.png";
import {Link, useHistory, useLocation} from "react-router-dom";
import {adminConfig} from "../../config/admin.config";
import Cookies from "universal-cookie";
import urlParse from "url-parse";
import ChinaFlag from "../../asset/images/flag-china.svg";
import KoreaFlag from "../../asset/images/flag-korea.svg";
import intl from 'react-intl-universal';
import {AuthService} from "../../auth/auth.service";
import {MemberContext} from "../../auth/member.context";

const cookies = new Cookies();

const RegisterUser = (props) => {
  const [loading, setLoading] = useState(false);
  const [authChecked, setAuthChecked] = useState(false);

  const [form] = Form.useForm();
  const history = useHistory();
  const searchParam = useLocation().search;

  const checkAuth = () => {
    setAuthChecked(false);
    AuthService.checkAuth().then(() => {
      document.location.href = "/";
    }).catch((err) => {
    }).finally(() => setAuthChecked(true));
  };

  useEffect(() => {
    if (MemberContext.memberInformation?.id) {
      document.location.href = "/";
      return;
    }
    checkAuth();
  }, []);


  const isExistsLoginId = (loginId) => {
    return new Promise((resolve, reject) => {
      CompanyUserApi.existsLoginId(loginId).then(res => {
        if (res.data) {
          message.error(intl.get("register.message.alreadyUsedId"));
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  };

  const registerUser = async (values) => {
    // TODO 향후 Application 정보 이용
    if (!window.location.hostname.includes("youma-admin")) {
      if (!values.agreeTerms) {
        message.error(intl.get("register.message.agree1"));
        return;
      }
      if (!values.agreePrivacy) {
        message.error(intl.get("register.message.agree2"));
        return;
      }
    } else {
      values.agreeTerms = false;
      values.agreePrivacy = false;
    }

    const exists = await isExistsLoginId(values.loginId);
    if (exists) {
      return;
    }

    if (values.loginId.length < 6) {
      message.error(intl.get("register.shortLoginId"));
      return;
    }

    if (values.password.length < 8) {
      message.error(intl.get("register.shortPassword"));
      return;
    }

    const companyUser = JSON.parse(JSON.stringify(values));
    const codeParam = new URLSearchParams(searchParam).get('code');
    if (codeParam) {
      companyUser.code = codeParam
    }
    if (props.serviceSetting.company?.id) {
      companyUser.companyId = props.serviceSetting.company.id;
    }

    setLoading(true);
    CompanyUserApi.createUser(companyUser).then(() => {
      setLoading(false);
      Modal.info({
        content: (
          <div>
            {intl.get("register.registered")}
          </div>
        ),
        onOk() {history.push("/login")},
      });
    }).finally(() => {
      setLoading(false);
    });
  };

  const checkAll = (e) => {
    const user = form.getFieldsValue();
    user.agreeTerms = e.target.checked;
    user.agreePrivacy = e.target.checked;
    user.agreeReceiveMail = e.target.checked;
    form.setFieldsValue(user);
  }

  const handleLanguageClick = (locale) => {
    cookies.set("locale", locale, {path: '/'});
    window.location.reload();
  }

  const {serviceSetting} = props;

  if (!authChecked) {
    return (<div></div>)
  }

  return (
    <div className="login-page">
      <div className="header">
        {serviceSetting.loaded && !serviceSetting.company?.id  && (
          <div><Link to={adminConfig.portalServer()}><img className="logo-img" src={LogoImg} alt={"img"}/></Link></div>
        )}
      </div>
      <div className={"contents"}>
        <div className={"form-wrapper"}>
          <div className={"title"}>{intl.get("register.register")}</div>
          <div className={"title-desc"}>{intl.get("login.welcome", {name: props.serviceSetting?.company?.serviceName})}</div>
          <div className={"form-box"}>
            <Form form={form} onFinish={registerUser}>
              <div className={"form-item"}>
                <div className={"form-label"}><span style={{color: "#ec5c57", marginRight: 4}}>*</span>{intl.get("company.user.name")}</div>
                <Form.Item name="name"
                           rules={[{required: true, message: intl.get("common.inputField", {name: intl.get("company.user.name")})}]}>
                  <Input className={"input"}/>
                </Form.Item>
              </div>
              <div className={"form-item"}>
                <div className={"form-label"}><span style={{color: "#ec5c57", marginRight: 4}}>*</span>{intl.get("company.user.loginId")}</div>
                <Form.Item name="loginId"
                           rules={[{required: true, message: intl.get("common.inputField", {name: intl.get("company.user.loginId")})}]}>
                  <Input className={"input"}/>
                </Form.Item>
              </div>
              <div className={"form-item"}>
                <div className={"form-label"}><span style={{color: "#ec5c57", marginRight: 4}}>*</span>{intl.get("company.user.password")}</div>
                <Form.Item name="password"
                           rules={[{required: true, message: intl.get("common.inputField", {name: intl.get("company.user.password")})}]}>
                  <Input.Password className={"input"}/>
                </Form.Item>
              </div>
              <div className={"form-item"}>
                <div className={"form-label"}><span style={{color: "#ec5c57", marginRight: 4}}>*</span>{intl.get("register.confirmingPassword")}</div>
                <Form.Item name="confirm" dependencies={['password']} hasFeedback
                           rules={[{required: true, message: intl.get("common.inputField", {name: intl.get("register.confirmingPassword")})},
                             ({getFieldValue}) => ({
                               validator(rule, value) {
                                 if (!value || getFieldValue('password') === value) {
                                   return Promise.resolve();
                                 }

                                 return Promise.reject(intl.get("register.diffPassword"));
                               },
                             }),
                           ]}
                >
                  <Input.Password className={"input"}/>
                </Form.Item>
              </div>
              <div className={"form-item"}>
                <div className={"form-label"}><span style={{color: "#ec5c57", marginRight: 4}}>*</span>{intl.get("company.user.phone")}</div>
                <Form.Item name="phone"
                           rules={[{required: true, message: intl.get("common.inputField", {name: intl.get("company.user.phone")})}]}>
                  <Input className={"input"}/>
                </Form.Item>
              </div>
              <div className={"form-item"}>
                <div className={"form-label"}><span style={{color: "#ec5c57", marginRight: 4}}>*</span>{intl.get("company.user.email")}</div>
                <Form.Item name="email"
                           rules={[{required: true, message: intl.get("common.inputField", {name: intl.get("company.user.email")})}]}>
                  <Input className={"input"}/>
                </Form.Item>
              </div>
              {!window.location.hostname.includes("youma-admin") && (
                <div className={"form-item"}>
                  <div className={"checkbox-item-all"}>
                    <Checkbox className={"checkbox"} onChange={checkAll}><span className={"checkbox-text bold"} style={{marginLeft: 6}}>{intl.get("terms.selectAll")}</span></Checkbox>
                  </div>
                  <div className={"line"}></div>
                  <div className={"checkbox-item"}>
                    <Form.Item name={"agreeTerms"} valuePropName="checked" noStyle>
                      <Checkbox className={"checkbox"}/>
                    </Form.Item>
                    <span className={"checkbox-text gray"}>{intl.get("register.must")}&nbsp;</span>
                    <span className={"checkbox-text"}><a href={adminConfig.portalServer() + "/terms_supplier.html"} target={"_blank"}>{intl.get("terms.termsType.user")}</a></span>
                    <span className={"checkbox-text gray"}>&nbsp;{intl.get("register.agree")}</span>
                  </div>
                  <div className={"checkbox-item"}>
                    <Form.Item name={"agreePrivacy"} valuePropName="checked" noStyle>
                      <Checkbox className={"checkbox"}/>
                    </Form.Item>
                    <span className={"checkbox-text gray"}>{intl.get("register.must")}&nbsp;</span>
                    <span className={"checkbox-text"}><a href={adminConfig.portalServer() + "/privacy.html"} target={"_blank"}>{intl.get("terms.termsType.privacy")}</a></span>
                    <span className={"checkbox-text gray"}>&nbsp;{intl.get("register.agree")}</span>
                  </div>
                  {/*<div className={"checkbox-item"}>*/}
                  {/*  <Form.Item name={"agreeReceiveMail"} noStyle*/}
                  {/*             valuePropName="checked">*/}
                  {/*    <Checkbox className={"checkbox"}/>*/}
                  {/*  </Form.Item>*/}
                  {/*  <span className={"checkbox-text gray"}>(선택)&nbsp;</span>*/}
                  {/*  <span className={"checkbox-text"}>이벤트/쇼핑정보 이메일 수신</span>*/}
                  {/*  <span className={"checkbox-text gray"}>&nbsp;동의</span>*/}
                  {/*</div>*/}
                </div>
              )}
              <div className={"btn-wrapper"}>
                <Form.Item>
                  <Button block type="primary" htmlType="submit" className="login-form-button" loading={loading}>
                    {intl.get("register.register")}
                  </Button>
                </Form.Item>
              </div>
              <div className={"footer"}>
                <span>{intl.get("register.hasLoginId")}</span>
                <span className={"login-link"}><Link to={"/login"}>{intl.get("login.login")}</Link></span>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <div style={{marginTop:24, display: "flex", justifyContent: "center", alignItems: "center"}}>
        <div><img style={{width: 20, height: 20, marginRight: 8}} src={ChinaFlag}/></div>
        <div style={{fontSize: 14, color: "#616161", marginRight: 17, cursor: "pointer"}}
             onClick={() => handleLanguageClick('zh-CN')}>
          简体中文
        </div>
        <Divider type={"vertical"}/>
        <div style={{marginLeft: 17, marginRight: 8}}><img style={{width: 20, height: 20}} src={KoreaFlag}/></div>
        <div style={{fontSize: 14, color: "#616161", cursor: "pointer"}}
             onClick={() => handleLanguageClick('ko-KR')}>
          한국어
        </div>
      </div>
      <div style={{paddingTop: 100}}>
      </div>
    </div>
  )
}
export default RegisterUser;
