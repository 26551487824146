import React, {useEffect, useState} from "react";
import {Descriptions, Form, Input, Radio, Select} from "antd";
import intl from "react-intl-universal";

const OrderEditAdditional = (props) => {
  const [additionalInfo, setAdditionalInfo] = useState({});

  const [form] = Form.useForm();

  useEffect(() => {
    const {order} = props;
    if (order.additionalInfo) {
      setAdditionalInfo(order.additionalInfo);
      form.setFieldsValue(order.additionalInfo);
    }
  }, [props.order.id]);

  const onValuesChange = (values) => {
    const additionalInfoState = {...additionalInfo};
    Object.assign(additionalInfoState, values);
    setAdditionalInfo(additionalInfoState);
    props.setOrderAssociatedValue("additionalInfo", additionalInfoState);
  }

  return (
      <div>
        <Form form={form} onValuesChange={onValuesChange}>
          <Descriptions bordered size={"small"} column={2}>
            <Descriptions.Item label={intl.get("order.requireIdCard")}>
              <Form.Item name={"idCardRequired"} rules={[{ required: true }]}>
                <Radio.Group>
                  <Radio value={true}>{intl.get("common.true")}</Radio>
                  <Radio value={false}>{intl.get("common.false")}</Radio>
                </Radio.Group>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("order.idCardStatus")}>
              <Form.Item name={"idCardStatus"}>
                <Select>
                  <Select.Option key={"none"} value={"none"}>{intl.get("order.idCardStatus.none")}</Select.Option>
                  <Select.Option key={"uploaded"} value={"uploaded"}>{intl.get("order.idCardStatus.uploaded")}</Select.Option>
                  <Select.Option key={"submitted"} value={"submitted"}>{intl.get("order.idCardStatus.submitted")}</Select.Option>
                </Select>
              </Form.Item>
            </Descriptions.Item>
          </Descriptions>
        </Form>
      </div>
  )
};

export default OrderEditAdditional;