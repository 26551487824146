import React, {useEffect, useState} from "react";
import {Spin, Button, message, Table, Modal, Layout, Descriptions} from "antd";
import ClearanceApi from "./ClearanceApi";
import Util from "../../../util/Util";
import intl from "react-intl-universal";
import PrettyData from "pretty-data";
import JSONPretty from "react-json-pretty";
import {SettingOutlined} from "@ant-design/icons";

const {Content} = Layout;

const SendOrderToKoreaLogistics = (props) => {
  const [updating, setUpdating] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [localLogisticsInterfaces, setLocalLogisticsInterfaces] = useState([]);
  const [selectedInterface, setSelectedInterface] = useState({});
  const [visibleDetailModal, setVisibleDetailModal] = useState(false);

  useEffect(() => {
    loadLocalLogisticsInterfaces(props.order.id);
  }, [props.order.id]);

  const loadLocalLogisticsInterfaces = (orderId) => {
    ClearanceApi.getLocalLogisticsInterfaces(orderId).then(res => {
      const interfaces = res.data;
      const succeedItems = interfaces.find(i => i.status === "success");
      setDisabled(succeedItems ? true : false);
      setLocalLogisticsInterfaces(interfaces);
    })
  };

  const sendToLocalLogistics = () => {
//    if (!props.application.localLogisticsCompany) {
//      message.error(intl.get("order.clearance.sendToLocalLogistics.noCompany"));
//      return;
//    }
    if(!window.confirm(intl.get("order.clearance.sendToLocalLogistics.confirm"))) {
      return;
    }
    setUpdating(true);
    ClearanceApi.sendToLocalLogistics(props.order.id).then(res => {
      message.info(intl.get("common.message.success"));
    }).catch(e => {
      console.log("sendToLocalLogistics err:", e);
    }).finally(() => {
      setUpdating(false);
      loadLocalLogisticsInterfaces(props.order.id);
    });
  };

  const deleteInterface = (id, event) => {
    event.stopPropagation();
    if(!window.confirm(intl.get("common.confirm.delete", {name: intl.get("order.clearance.send_info")}))) {
      return;
    }
    setUpdating(true);
    ClearanceApi.deleteLocalLogisticsInterface(id).then(res => {
      message.info(intl.get("common.message.deleted"));
      loadLocalLogisticsInterfaces(props.order.id);
    }).catch(e => {
      console.log("deleteLocalLogisticsInterface err:", e);
    }).finally(() => {
      setUpdating(false);
    });
  };

   const showDetail = (logisticsInterface, event) => {
     event.stopPropagation();
     setSelectedInterface(logisticsInterface);
     setVisibleDetailModal(true);
   };

  const columns = [{
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  }, {
    title: intl.get("order.clearance.table.date"),
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text, record) => {
      return (<div>{Util.getChinaLocalTime(record.createdAt)}</div>);
    }
  }, {
    title: intl.get("order.clearance.table.type"),
    dataIndex: 'type',
    key: 'type',
    render: (text, record) => {
      return (
        <div>{intl.get("order.clearance.declare_company_interface_type." + record.type)}</div>
      )
    }
  }, {
    title: intl.get("order.clearance.table.status"),
    dataIndex: 'status',
    key: 'status',
    render: (text, record) => {
      return (<div>{text === "success" ? intl.get("order.clearance.table.success") : intl.get("order.clearance.table.failed")}</div>)
    }
  }, {
    title: intl.get("order.clearance.table.errorMessage"),
    dataIndex: 'errorMessage',
    key: 'errorMessage',
    render: text => {
      return (<div style={{display: "flex", alignItems: "center", fontSize: 11, width: 200, height: 40, overflow: "auto"}}>{text}</div>)
    }
  }, {
    title: '',
    dataIndex: 'id',
    key: 'button',
    render: (text, record) => {
      return record.status === "fail"
        ? (<Button size="small" onClick={(event) => deleteInterface(record.id, event)}>
            {intl.get("common.button.delete")}
            </Button>)
        : (<div></div>)
    }
  }];

  return (
    <Spin spinning={updating}>
      <div>
        <div style={{display: "flex", marginBottom: 10}}>
          <div>
            <div>{intl.get("order.clearance.help.local_logistics")}</div>
            <div>{intl.get("order.clearance.sendToLocalLogistics.company")}: Interlogis</div>
            <div>{intl.getHTML("order.clearance.sendToLocalLogistics.help")}</div>
          </div>
          <div style={{marginLeft: "auto"}}>
            <Button type="primary" size="small" disabled={disabled} onClick={sendToLocalLogistics}>{intl.get("order.clearance.declare_company_interface_type.send_order")}</Button>
          </div>
        </div>
        <div>
          <Table size={'small'}
                 dataSource={localLogisticsInterfaces}
                 columns={columns}
                 rowKey={'id'}
                 pagination={false}
                 onRow={(record, rowIndex) => {
                   return {
                     onClick: event => {
                       showDetail(record, event);
                     },
                   }
                 }}
          />
        </div>
      </div>
      {visibleDetailModal && (
        <RequestDetailModal
          localLogisticsInterface={selectedInterface}
          handleOk={() => setVisibleDetailModal(false)}/>
      )}
    </Spin>
  );
}

const RequestDetailModal = (props) => {
  const decodeFormEncodedData = (text) => {
    return text.split("&").map((eachKeyVal) => {
      const keyValue = eachKeyVal.split("=");
      const key = keyValue[0];
      const value = decodeURIComponent(keyValue[1]);

      return [key, value];
    })
  };

  const getRequestView = () => {
    const {localLogisticsInterface} = props;
    const value = localLogisticsInterface.requestBody;
    let tradeList = "";
    const keyValues = decodeFormEncodedData(value).map((eachKeyVal, index) => {
      return (<div key={"param-" + localLogisticsInterface.id + "-" + index}>{eachKeyVal[0]} : {eachKeyVal[1]}</div>)
    })

    return (
      <div className={"request_message"}>
        <div>
          <div>{keyValues}</div>
        </div>
        <hr/>
        <h3>전송원문</h3>
        <div style={{display: "block", width: "100%"}}>{value}</div>
      </div>
    )
  };

  return (
    <Modal
      title={[<div key={1}><SettingOutlined/> 전송내역</div>]}
      visible={true}
      onOk={() => props.handleOk()}
      onCancel={() => props.handleOk()}
      width="80%"
      cancelButtonProps={{ style: { display: 'none' } }}
      bodyStyle={{margin: 0, padding: 0}}>
      <Layout>
        <Content className="modal-content">
          <Descriptions  bordered
                         layout={"vertical"}
                         size={"small"}
                         column={2}
          >
            <Descriptions.Item label={intl.get("order.clearance.sendToLogistics.request")}>
              { getRequestView() }
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("order.clearance.sendToLogistics.response")}>
              { props.localLogisticsInterface.responseBody }
            </Descriptions.Item>
          </Descriptions>
        </Content>
      </Layout>
    </Modal>
  )
}

export default SendOrderToKoreaLogistics;