// import { Config } from './Config';
import axios from "axios";
import Cookies from 'universal-cookie';
import HttpStatusError from './HttpStatusError';
import Util from "./Util";
import {message} from "antd";

export class HttpUtil {
  static makeParams = (targetKeys, obj) => {
    return Object.keys(obj).filter((k) => targetKeys.includes(k)).map((k) => {
      return encodeURIComponent(k) + '=' + encodeURIComponent(obj[k]);
    }).join('&')
  };

  static getHeader = () => {
    const cookies = new Cookies();
    const user = cookies.get("user") || {};
    const merchandiserId = cookies.get("merchandiserId") || "";

    return {
      // 'Authorization': `Bearer ${user.jwtToken}`,
      'Authorization': axios.defaults.headers['Authorization'],
      'X-MerchandiserId': merchandiserId,
      'Content-Type':'application/json'
    };
  };

  static handleHttpStatus = (response) => {
    if (response.ok) {
      return response;
    } else {
      throw new HttpStatusError(response.statusMessage, response);
    }
  };

  static handleHttpStatus = (response) => {
    if (response.ok) {
      return response;
    } else {
      throw response;
    }
  };

  static handleHttpStatusError = async(response) => {
    if (response.status == 401) {
      alert("Unauthorized request. Please login!");
      Util.deleteAllCookies();
      document.location.href = "/";
    } else if (response.status == 404) {
      throw response;
    } else {
      let errorMessage = "";
      if (!response.json) {
        message.error("에러: " + response);
        throw new Error(response);
      }
      await response.json().then(res => {
        if (res.message) {
          errorMessage += res.message;
        }
        if (res.error) {
          errorMessage += res.error.message;
        }
        if (res.details) {
          errorMessage += `[${res.details}]`
        }
        if (res.error && res.error.details) {
          errorMessage += `[${res.error.details}]`
        }
      });
      message.error("에러: " + errorMessage);
      throw new Error(errorMessage);
    }
  };

  static ignoreNotFoundError = (response) => {
    console.log("fetch result is not found, but ignored");
    return response;
  };

  static handleResponseError = (error) => {
    throw error;
  };
};