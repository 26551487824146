import React, {useEffect, useState} from 'react';
import ApplicationPageApi from "./ApplicationPageApi";
import {Button, Divider, message, Radio, Tooltip, Descriptions,
  Space, Input, Select, List} from "antd";
import {Link} from "react-router-dom";
import {MenuOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import ProductDetailPreview from "./detail_preview/ProductDetailPreview";
import HtmlEditor from "../../components/editor/HtmlEditor";
import dayjs from "dayjs";
import intl from "react-intl-universal";
import DraggableTable from "../DraggableTable";
import NoImage from "../../asset/images/no_image.png";
import {sortableHandle} from "react-sortable-hoc";

const attrNames = {
  "product_detail_page.text": "自定义",
  "product_detail_page.tag": "标签",
  "product_detail_page.brand": "品牌",
  "product_detail_page.optionText": "规格",
  "product_detail_page.originCountry": "原产地",
  "product_detail_page.delivery": "运费",
}

const defaultPageItems = [{
  id: 1,
  type: "banner",
  settingValues: {},
  selected: false,
}, {
  id: 2,
  type: "price",
  settingValues: {},
  selected: true,
}, {
  id: 3,
  type: "info",
  settingValues: {
    attrs: [{
      id:1, field: "tag", name: attrNames["products.tag"], value: ""
    }, {
      id:2, field: "brand", name: attrNames["products.brand"], value: ""
    }, {
      id:3, field: "optionText", name: attrNames["products.sku.optionText"], value: ""
    }, {
      id:4, field: "originCountry", name: attrNames["products.originCountry"], value: ""
    }, {
      id:5, field: "delivery", name: attrNames["products.delivery"], value: ""
    }]
  },
  selected: false,
}, {
  id: 4,
  type: "desc",
  settingValues: {},
  selected: false,
}];

const ProductDetailPageEdit = (props) => {
  const [] = useState({});
  const [appPage, setAppPage] = useState({});
  const [goBottom, setGoBottom] = useState("");
  const [pageItems, setPageItems] = useState(defaultPageItems);
  const [maxItemId, setMaxItemId] = useState(defaultPageItems[defaultPageItems.length - 1].id);
  const [infoAttr, setInfoAttr] = useState({
    field: "text", name: "", value: ""
  });
  // Editor가 있느면 스크롤 시 editor에 스크롤이 되어 실제 미리보기에는 스크롤이 되지 않는 문제 해결하기 위해 이 값을 사용
  const [scrollFinished, setScrollFinished] = useState(false);
  const [appPageComponents, setAppPageComponents] = useState([]);

  const [product, setProduct] = useState({
    name: "테스트 상품",
    brandName: "BETTERCODE",
    tags: [{id: 1, value: "春节活动"}, {id:1, value: "商家推荐"}],
    salesPrice: 100,
    listPrice: 200,
    skus: [{optionText: "黄色"}, {optionText: "红色"}],
    originCountry: "韩国",
  });

  useEffect(() => {
    if (props.match.params.pageId) {
      loadAppPage(props.match.params.pageId);
    }
  }, []);

  useEffect(() => {
    loadAppPageComponents();
  }, []);

  const loadAppPageComponents = () => {
    ApplicationPageApi.getAppPages({componentType: true, published: true, pageSize: 100}).then(res => {
      setAppPageComponents(res.data.data);
    })
  };

  const loadAppPage = (pageId) => {
    ApplicationPageApi.getAppPage(pageId).then(res => {
      const appPage = res.data;
      let pageItemsRes = appPage.editingSettings ? appPage.editingSettings : [];

      if (pageItemsRes.length === 0) {
        pageItemsRes = JSON.parse(JSON.stringify(defaultPageItems));
      }
      let maxItemId = 0;
      pageItemsRes.forEach(item => {
        if (item.id > maxItemId) {
          maxItemId = item.id;
        }

        // info의 경우 attr에 id가 없는 경우 id를 추가한다.
        if (item.type === "info") {
          if (item.settingValues?.attrs) {
            item.settingValues.attrs = item.settingValues.attrs.map((attr, index) => {
              attr.id = (index + 1);
              return attr;
            });
          }
        }
      });

      if (pageItemsRes.length > 0 && !pageItemsRes.find(pi => pi.selected)) {
        pageItemsRes[0].selected = true;
      }
      setPageItems(pageItemsRes);
      setMaxItemId(maxItemId);
      setAppPage(appPage);
    });
  };

  const itemScrollFinished = () => {
    setScrollFinished(true);
  };

  const submitPublish = () => {
    if (!(window.confirm(intl.get("product_detail_page.confirmDeploy")))) {
      return;
    }
    saveAppPage(true);
  };

  const saveAppPage = (publish) => {
    const appPageState = {...appPage};
    let pageItemsState = JSON.parse(JSON.stringify(pageItems));

    let hasError = false;
    pageItemsState = pageItemsState.map((pi, index) => {
      if (pi.type === "component") {
        if (!pi.componentId) {
          hasError = true;
          message.error((index + 1) + "번째 항목에서 UI 컴포넌트가 설정되지 않았습니다.");
        }
        pi.settingValues = [];
      }
      delete pi.selected;
      return pi;
    });

    if (hasError) {
      return;
    }
    appPageState.editingSettings = pageItemsState;
    if (publish) {
      appPageState.published = true;
      appPageState.publishedSettings = pageItemsState;
    }
    ApplicationPageApi.submitAppPage(appPageState).then(res => {
      message.success(intl.get("common.message.saved"));
      setAppPage(res.data);
      props.loadAppPages();
    }).
    catch(e => {
      console.log("saveAppPage error:", e);
      // message.error('저장 중 오류가 발생했습니다.');
    });
  };

  const onPageItemClick = (index) => {
    const pageItemsState = pageItems.map((pageItem, i) => {
      if (i === index) {
        pageItem.selected = true;
      } else {
        pageItem.selected = false;
      }
      return pageItem;
    });
    setPageItems(pageItemsState);
  };

  const addPageItem = () => {
    const pageItemsState = pageItems.map(pi => {
      pi.selected = false;
      return pi;
    })
    pageItemsState.push({
      id: maxItemId + 1,
      type: "component",
      settingValues: [],
      selected: true,
    });
    setPageItems(pageItemsState);
    setMaxItemId(maxItemId + 1);
    setGoBottom("" + dayjs().unix());    // scroll bottom 이벤트를 주기 위해 임의의 값 생성
  };

  const onPageItemTypeChanged = (e) => {
    const type = e.target.value;
    const pageItemsState = [...pageItems].map(pi => {
      if (pi.selected) {
        pi.type = type;
        if (type === "contents") {
          pi.settingValues = {htmlText: intl.get("product_detail_page.image.help")}
        } else if (type === "component") {
          pi.settingValues = [];
        }
      }
      return pi;
    });
    setPageItems(pageItemsState);
  };

  const onSelectComponent = (componentId) => {
    const selectedComponent = appPageComponents.find(sc => sc.id === componentId);
    if (!selectedComponent) {
      message.error(intl.get("common.message.contactAdmin"));
      return;
    }
    const pageItemsState = pageItems.map(pi => {
      if (pi.selected) {
        pi.componentId = selectedComponent.id;
        pi.settingValues = selectedComponent.publishedSettings;
      }
      return pi
    });
    setPageItems(pageItemsState);
  };

  const onHtmlChanged = (htmlText) => {
    const selectedPageItem = JSON.parse(JSON.stringify(pageItems.find(p => p.selected)));
    selectedPageItem.settingValues.htmlText = htmlText;
    const changedPageItems = pageItems.map(pi => {
      if (pi.selected) {
        return selectedPageItem;
      } else {
        return pi;
      }
    });
    setPageItems(changedPageItems);
  };

  const getAttrDisplayName = (attr) => {
    if (attr.field === "text") {
      return attr.name;
    } else {
      return intl.get("product_detail_page." + attr.field);
    }
  };

  const onInfoAttrChanged = (fieldName, value) => {
    const infoAttrStat = {...infoAttr};
    infoAttrStat[fieldName] = value;
    setInfoAttr(infoAttrStat);
  };

  const addInfoAttr = () => {
    const selectedPageItem = JSON.parse(JSON.stringify(pageItems.find(p => p.selected)));
    const infoAttrState = {...infoAttr};

    if (infoAttrState.field !== "text" && selectedPageItem.settingValues.attrs.find(a => a.field === infoAttrState.field)) {
      message.error(intl.get("product_detail_page.alreadyAdded"));
      return;
    }
    if (infoAttrState.field !== "text") {
      infoAttrState.name = attrNames["product_detail_page." + infoAttrState.field];
    }
    if (selectedPageItem.settingValues.attrs) {
      selectedPageItem.settingValues.attrs.push(infoAttrState)
    } else {
      selectedPageItem.settingValues.attrs = [infoAttrState];
    }

    selectedPageItem.settingValues.attrs = selectedPageItem.settingValues.attrs.map((attr, index) => {
      attr.id = (index + 1);
      return attr;
    });

    setPageItems(pageItems.map(pi => {
      if (pi.selected) {
        return selectedPageItem;
      } else {
        return pi;
      }
    }));

    setInfoAttr({field: infoAttrState.field, name: "", value: ""});
  };

  const deleteInfoAttr = (id) => {
    const selectedPageItem = JSON.parse(JSON.stringify(pageItems.find(p => p.selected)));

    selectedPageItem.settingValues.attrs = selectedPageItem.settingValues.attrs.filter(att => att.id !== id);
    selectedPageItem.settingValues.attrs = selectedPageItem.settingValues.attrs.map((attr, index) => {
      attr.id = (index + 1);
      return attr;
    });

    setPageItems(pageItems.map(pi => {
      if (pi.selected) {
        return selectedPageItem;
      } else {
        return pi;
      }
    }));
  };

  const moveUp = () => {
    const pageItemsState = [...pageItems];
    const selectedPageItem = pageItemsState.find(p => p.selected);
    let itemIndex = -1;
    pageItemsState.forEach((item, index) => {
      if (item.id === selectedPageItem.id) {
        itemIndex = index;
      }
    });

    if (itemIndex < 0) {
      message.error("Error");
      return;
    }

    if (itemIndex === 0) {
      message.error("처음입니다.");
      return;
    }

    const targetPageItem = pageItemsState[itemIndex];
    pageItemsState[itemIndex] = pageItemsState[itemIndex - 1];
    pageItemsState[itemIndex - 1] = targetPageItem;

    setPageItems(pageItemsState);
  };

  const moveDown = () => {
    const pageItemsState = [...pageItems];
    const selectedPageItem = pageItemsState.find(p => p.selected);
    let itemIndex = -1;
    pageItemsState.forEach((item, index) => {
      if (item.id === selectedPageItem.id) {
        itemIndex = index;
      }
    });

    if (itemIndex < 0) {
      message.error("Error");
      return;
    }

    if (itemIndex === (pageItems.length -1)) {
      message.error("마지막입니다.");
      return;
    }

    const targetPageItem = pageItemsState[itemIndex];
    pageItemsState[itemIndex] = pageItemsState[itemIndex + 1];
    pageItemsState[itemIndex + 1] = targetPageItem;

    setPageItems(pageItemsState);
  };

  const deletePageItem = () => {
    const pageItemsState = [...pageItems];
    let deletedItemIndex = -1;
    pageItemsState.forEach((pi, index) => {
      if (pi.selected) {
        deletedItemIndex = index;
      }
    });

    if (deletedItemIndex < 0) {
      message.error("삭제할 항목을 선택하세요.");
      return;
    }

    const pageItemsStateAfterDeleting = pageItemsState.filter(pi => !pi.selected);
    if (deletedItemIndex > 0) {
      pageItemsState[deletedItemIndex - 1].selected = true;
    }
    setPageItems(pageItemsStateAfterDeleting);
  };

  const onAttrDragEnd = (sortedAttrs) => {
    const selectedPageItem = JSON.parse(JSON.stringify(pageItems.find(p => p.selected)));

    selectedPageItem.settingValues.attrs = sortedAttrs;
    selectedPageItem.settingValues.attrs = selectedPageItem.settingValues.attrs.map((attr, index) => {
      attr.id = (index + 1);
      return attr;
    });

    setPageItems(pageItems.map(pi => {
      if (pi.selected) {
        return selectedPageItem;
      } else {
        return pi;
      }
    }));
  }

  const selectedPageItem = pageItems.find(p => p.selected);
  const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

  const columns = [{
      title: intl.get("product_detail_page.sort"),
      dataIndex: 'field',
      key: 'sort',
      width: 50,
      className: 'drag-visible',
      render: () => <DragHandle/>
    }, {
      title: "",
      dataIndex: 'field',
      key: 'field',
      align: 'left',
      render: (text, record) => {
        return (<span>{getAttrDisplayName(record)}</span>)
      }
    }, {
      title: "",
      dataIndex: 'field',
      key: 'value',
      align: 'left',
      render: (text, record) => {
        return (<span>{record.field === "text" ? record.value : ""}</span>)
      }
  }, {
    title: "",
    dataIndex: 'field',
    key: 'value',
    align: 'center',
    render: (text, record) => (
      <Button size={"small"} onClick={() => deleteInfoAttr(record.id)}>{intl.get("common.button.delete")}</Button>
    )
  }];

  return (
      <div>
        <div style={{width: "100%", marginLeft: "auto"}}>
          <div style={{display: "flex", alignItems: "center"}}>
            <div style={{fontSize: 16, fontWeight: "bold", marginRight: 10}}>
              {intl.get("product_detail_page.page_setting")}
            </div>
            <div style={{marginRight: 10, marginLeft: "auto"}}>
              <Button type="primary" onClick={addPageItem}>{intl.get("product_detail_page.addItem")}</Button>
              <Divider type="vertical"/>
              <Button type="primary"><Link to={`${props.parentPath}`}>{intl.get("common.button.list")}</Link></Button>
              <Divider type="vertical"/>
              <Button type="primary" onClick={() => saveAppPage(false)}>{intl.get("common.button.save")}</Button>
              <Divider type="vertical"/>
              <Button type="primary" onClick={submitPublish}>{intl.get("page_setting.edit.publish")}</Button>
              <Tooltip title={intl.get("page_setting.edit.publish.help")}>
                <span style={{marginLeft: 20, fontSize: 18}}><QuestionCircleOutlined /></span>
              </Tooltip>
            </div>
          </div>
        </div>
        <div style={{marginTop: 20}}>
          <div style={{display: "flex", xAlignItems: "stretch"}}>
            <div style={{width: "375px"}}>
              <ProductDetailPreview
                  application={props.application}
                  pageItems={pageItems}
                  product={product}
                  goBottom={goBottom}
                  onPageItemClick={onPageItemClick}
                  itemScrollFinished={itemScrollFinished}
              />
            </div>
            <div style={{flex: 1, overflow: "auto", marginLeft: 20}}>
              <Descriptions bordered size={"small"} column={1} layout={"vertical"}>
                {selectedPageItem.type === "banner" && (
                    <Descriptions.Item label={intl.get("product_detail_page.setting")}>
                      {intl.get("product_detail_page.productBanner")}: {intl.get("product_detail_page.noSettingItem")}
                    </Descriptions.Item>
                )}
                {selectedPageItem.type === "price" && (
                    <Descriptions.Item label={intl.get("product_detail_page.setting")}>
                      {intl.get("product_detail_page.priceInfo")}: {intl.get("product_detail_page.noSettingItem")}
                    </Descriptions.Item>
                )}
                {selectedPageItem.type === "desc" && (
                    <Descriptions.Item label={intl.get("product_detail_page.setting")}>
                      {intl.get("product_detail_page.detailInfo")}: {intl.get("product_detail_page.noSettingItem")}
                    </Descriptions.Item>
                )}
                {selectedPageItem.type === "info" && (
                    <Descriptions.Item label={intl.get("product_detail_page.setting")}>
                      <Space>
                        <Select value={infoAttr.field ? infoAttr.field : undefined} style={{width: 150}} placeholder={intl.get("product_detail_page.item")}
                                onChange={(value) => onInfoAttrChanged('field', value)}>
                          <Select.Option value={"text"}>{intl.get("product_detail_page.text")}</Select.Option>
                          <Select.Option value={"tag"}>{intl.get("product_detail_page.tag")}</Select.Option>
                          <Select.Option value={"brand"}>{intl.get("product_detail_page.brand")}</Select.Option>
                          <Select.Option value={"optionText"}>{intl.get("product_detail_page.optionText")}</Select.Option>
                          <Select.Option value={"originCountry"}>{intl.get("product_detail_page.originCountry")}</Select.Option>
                          <Select.Option value={"delivery"}>{intl.get("product_detail_page.delivery")}</Select.Option>
                        </Select>
                        {infoAttr.field === "text" && (
                            <>
                              <Input value={infoAttr.name} style={{width: 100}} placeholder={intl.get("product_detail_page.title")}
                                     onChange={(e) => onInfoAttrChanged('name', e.target.value)}/>
                              <Input value={infoAttr.value} style={{width: 200}} placeholder={intl.get("product_detail_page.value")}
                                     onChange={(e) => onInfoAttrChanged('value', e.target.value)}/>
                            </>
                          )}
                         <Button size={"small"} onClick={addInfoAttr}>{intl.get("common.button.add")}</Button>
                      </Space>
                      <div style={{marginTop: 10, width: 525}}>
                        <DraggableTable dataSource={selectedPageItem.settingValues.attrs}
                                        columns={columns}
                                        rowKey={"id"}
                                        onSortEnd={onAttrDragEnd}
                        />
                        {/*<List*/}
                        {/*    dataSource={selectedPageItem.settingValues.attrs}*/}
                        {/*    renderItem={(attr, index) => (*/}
                        {/*        <List.Item key={index}*/}
                        {/*                   actions={[<Button size={"small"} onClick={() => deleteInfoAttr(index)}>{intl.get("common.button.delete")}</Button>]}>*/}
                        {/*          <List.Item.Meta*/}
                        {/*              title={getAttrDisplayName(attr)}*/}
                        {/*          />*/}
                        {/*          <div>{attr.field === "text" ? attr.value : ""}</div>*/}
                        {/*        </List.Item>*/}
                        {/*    )}*/}
                        {/*/>*/}
                      </div>
                    </Descriptions.Item>
                )}
                {(selectedPageItem && (selectedPageItem.type === "contents" || selectedPageItem.type === "component")) && (
                    <>
                      <Descriptions.Item label={intl.get("product_detail_page.position")}>
                        <Space>
                          <Button onClick={moveUp}>{intl.get("product_detail_page.up")}</Button>
                          <Button onClick={moveDown}>{intl.get("product_detail_page.down")}</Button>
                          <Button onClick={deletePageItem}>{intl.get("common.button.delete")}</Button>
                        </Space>
                      </Descriptions.Item>
                      <Descriptions.Item label={intl.get("product_detail_page.pageItem.type")}>
                        <Radio.Group value={selectedPageItem.type} onChange={onPageItemTypeChanged}>
                          <Radio key={"component"} value={"component"}>UI Component</Radio>
                          <Radio key={"contents"} value={"contents"}>HTML</Radio>
                        </Radio.Group>
                      </Descriptions.Item>
                      <Descriptions.Item label={intl.get("product_detail_page.contents")}>
                        {selectedPageItem.type === "contents" && (
                          <HtmlEditor
                              fileKind="product"
                              htmlText={selectedPageItem.settingValues.htmlText}
                              setText={onHtmlChanged} height={500}/>
                        )}
                        {selectedPageItem.type === "component" && (
                          <Select value={selectedPageItem ? selectedPageItem.componentId : ""} style={{width: 400}} onChange={onSelectComponent}>
                            {appPageComponents.map((sc, index) => {
                              return (
                                <Select.Option key={sc.id} value={sc.id}>{sc.title}</Select.Option>
                              )
                            })}
                          </Select>
                        )}
                      </Descriptions.Item>
                    </>
                )}
              </Descriptions>
            </div>
          </div>
        </div>
      </div>
  );
}

export default ProductDetailPageEdit;