import React, { useState, useEffect } from "react";
import ReplyApi from "./ReplyApi";
import { Modal, Layout, Descriptions, Button, Image, Input, Spin, message, Upload, Rate } from "antd";
import intl from "react-intl-universal";
import { UserOutlined, UploadOutlined } from "@ant-design/icons";
import ProductSearchPopup from "../product/ProductSearchPopup";

const { TextArea } = Input;
const { Content } = Layout;

const AddReplyPopup = (props) => {
  const [loading, setLoading] = useState(false);
  const [productSearchVisible, setProductSearchVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [formData, setFormData] = useState({
    comment: '',
    nickName: '',
    productScore: 5,
  })
  const [fileList, setFileList] = useState([]);

  // useEffect(() => {
  // }, []);

  const okProductSearch = (products) => {
    // console.log('okProductSearch.products :>> ', products);
    setSelectedProduct(products[0])
    setProductSearchVisible(false);
  };

  const onFilterChanged = (key, e) => {
    // console.log('key :>> ', key)
    // console.log('e :>> ', e)
    // console.log(`${key}: ${e.target.value}`)
    const obj = { ...formData }
    if (typeof (e) == 'object') {
      obj[key] = e.target.value
    } else if (typeof (e) == 'number') {
      obj[key] = e
    }
    setFormData(obj)
    // console.log('obj :>> ', obj)
  }
  const uploadConfig = {
    name: 'replyImage',
    accept: 'image/png, image/jpeg',
    multiple: false,
    maxCount: 1,
    listType: 'picture-card',
    fileList: fileList,
    beforeUpload(file) {
      if (file.size > 3 * 1024 * 1024) {
        message.error('파일 사이즈가 너무 큽니다.')
        return
      }
      return false
    },
    onChange(info) {
      console.log('onChange.info :>> ', info)
      if (info.file.status === 'removed') return
      if (info.file.status !== 'uploading') {
        // console.log('info.file :>> ', info.file)
        // console.log('info.fileList :>> ', info.fileList)
        setFileList(info.fileList)
        // setFile(info.file)
      }

      if (info.file.status === 'done') {
        // message.success(`${info.file.name} 파일 업로드 성공`)
        console.log('done.info :>> ', info)
        setFileList(info.fileList)
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} 파일 업로드 실패`)
      }
    },
    onRemove(info) {
      // console.log('onRemove.info :>> ', info)
      const uploadFileList = fileList.filter((item) => item.uid != info.uid)
      // console.log('uploadFileList :>> ', uploadFileList);
      setFileList(uploadFileList)
      // setFileList([])
      return true
    }
  }
  const handelSave = () => {
    // console.log('selectedProduct :>> ', selectedProduct);
    // console.log('formData :>> ', formData);
    if (!selectedProduct) {
      message.error("상품을 선택하세요.");
      return
    }
    if (!formData.comment) {
      message.error("평가글을 입력하세요.");
      return
    }
    if (!formData.nickName) {
      message.error("이름을 입력하세요.");
      return
    }
    const param = new FormData();
    fileList.forEach((file) => {
      console.log('handelSave.file.new :>> ', file)
      // formData.append('files', file)
      param.append('replyImage', file.originFileObj)
    })
    param.append("userId", 0);
    param.append("productId", selectedProduct.id);
    param.append("orderId", 0);
    if (fileList.length > 0) {
      param.append("replyType", 'image');
    } else {
      param.append("replyType", 'text');
    }
    param.append("comment", formData.comment);
    param.append("nickName", formData.nickName);
    param.append("avatar", '');
    param.append("productScore", formData.productScore + '');
    param.append("bestReply", 0);
    setLoading(true)
    ReplyApi.createReply(param).then(res => {
      // console.log('createReply.res :>> ', res);
      props.refreshReply()
    }).finally(_ => {
      setLoading(false)
      props.onCancel()
    })
  }
  return (
    <div>
      <Modal
        title={[<div key={1}><UserOutlined /> {intl.get("reply.comment.add")}</div>]}
        open={true}
        // onOk={() => props.onOk()}
        onCancel={() => props.onCancel()}
        footer={[
          <Button key="1" onClick={() => props.onCancel()}>
            {intl.get("common.button.close")}
          </Button>,
          <Button key="2" type="primary" onClick={handelSave} loading={loading}>
            {intl.get("common.button.save")}
          </Button>,
        ]}
        width={1200}
        bodyStyle={{ margin: 0, padding: 0 }}>
        <Layout>
          <Content className="modal-content">
            <Descriptions bordered size={"small"} column={1}>
              <Descriptions.Item label={intl.get("reply.product")}>
                <Button onClick={() => setProductSearchVisible(true)}>{intl.get("coupon.button.select.product")}</Button>
              </Descriptions.Item>
              <Descriptions.Item label={intl.get("live.goodsName")}>
                {selectedProduct && (<div><Image src={selectedProduct?.images[0] + "?x-oss-process=image/resize,w_100"} style={{ width: 100, height: 100 }}></Image></div>)}
                <div style={{ marginTop: 10 }}>{selectedProduct?.name}</div>
              </Descriptions.Item>
              <Descriptions.Item label={intl.get("reply.comment.score")}>
                <Rate style={{ fontSize: 16 }} value={formData?.productScore} onChange={e => onFilterChanged('productScore', e)} />
              </Descriptions.Item>
              <Descriptions.Item label={intl.get("reply.comment")}>
                <TextArea rows={7} value={formData.comment} onChange={e => onFilterChanged('comment', e)} />
              </Descriptions.Item>
              <Descriptions.Item label={intl.get("reply.nickName")}>
                <Input value={formData.nickName} onChange={e => onFilterChanged('nickName', e)} />
              </Descriptions.Item>
              <Descriptions.Item label={intl.get("live.room.image")}>
                <Upload {...uploadConfig}>
                  {intl.get("products.upload.help3")}
                </Upload>
              </Descriptions.Item>
            </Descriptions>
          </Content>
        </Layout>
      </Modal>
      {productSearchVisible && (
        <ProductSearchPopup
          selectionType='radio'
          selectMultiple={true}
          showSku={false}
          projection={"app"}
          handleCancel={() => setProductSearchVisible(false)}
          handleOk={okProductSearch}
        />
      )}
    </div>

  )
}

export default AddReplyPopup;