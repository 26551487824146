import React, {useState, useEffect} from 'react';
import {message, Table} from "antd";
import NoImg from "../../asset/images/no_image.png";
import Util from "../../util/Util";
import intl from 'react-intl-universal';
import { Link } from 'react-router-dom';
import UserShowPopup from "../user/UserShowPopup";
import {EditableCell, EditableRow} from "../../components/editable_table/EditableTable";
import ProductApi from "../product/ProductApi";

const OrderEditSku = (props) => {
  const [editingOrderSkus, setEditingOrderSkus] = useState([]);
  const [selectedSellerUserId, setSelectedSellerUserId] = useState(0);
  const [visibleUserPopup, setVisibleUserPopup] = useState(false);
  const [columns, setColumns] = useState([]);
  const [skuEditable, setSkuEditable] = useState(false);

  useEffect(() => {
    const {order} = props;
    if (order.orderSkus) {
      const orderSkus = JSON.parse(JSON.stringify(order.orderSkus));
      getEventOriginProducts(orderSkus);
    }
    initColumns(order);

  }, [props.order.id]);

  useEffect(() => {
    const {order} = props;
    const currentStatus = order.currentStatus;
    if (currentStatus == "pay_declared" ||
      currentStatus == "order_declaring" ||
      currentStatus == "order_declared" ||
      currentStatus == "delivering" ||
      currentStatus == "delivered" ||
      currentStatus == "finished"
    ) {
      setSkuEditable(false);
    } else {
      setSkuEditable(true);
    }
  }, [props.order.currentStatus]);

  useEffect(() => {
    initColumns(props.order);
  }, [skuEditable]);

  const getEventOriginProducts = (orderSkus) => {
    let eventOriginProductIds = orderSkus
        .filter(sku => sku.eventProduct)
        .map(sku => sku.eventOriginProductId);

    if (eventOriginProductIds.length === 0) {
      setEditingOrderSkus(orderSkus);
      return;
    }
    const params = {
      id: eventOriginProductIds,
      pageSize: eventOriginProductIds.length
    };

    ProductApi.getProducts(params).then(res => {
      const eventOriginProductMap = {};
      res.data.data.forEach(p => {
        eventOriginProductMap[p.id] = p;
      });

      const ordersSkusWithEventOrigin = orderSkus.map(sku => {
        if (sku.eventProduct) {
          const op = eventOriginProductMap[sku.eventOriginProductId];
          if (op) {
            sku.eventOriginProductName = op.name;
          }
          return sku;
        }
        return sku;
      });
      setEditingOrderSkus(ordersSkusWithEventOrigin);
    });
  };

  const initColumns = (order) => {
    const originColumns = [{
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    }, {
      title: intl.get("products.id"),
      dataIndex: 'productId',
      key: 'productId',
      render: (text, record) => {
        if (order.clientApp === "weimob") {
          return record.externalProductId;
        } else {
          return text;
        }
      }
    }, {
      title: intl.get("order.sku.imageUrl"),
      dataIndex: 'skuImage',
      key: 'skuImage',
      render: (text, record) => {
        return (<img src={record.skuImage ? record.skuImage + "?x-oss-process=image/resize,h_48,w_48" : NoImg}
                     style={{width: "48px", height: "48px"}}/>)
      }
    }, {
      title: intl.get("products.name"),
      dataIndex: 'productName',
      key: 'productName',
      render: (text, record) => {
        return (
          <>
            <div>{record.productName}</div>
            {record.eventOriginProductName && (
                <div style={{color: "#8c8c8c"}}>{record.eventOriginProductName}</div>
            )}
            <div>{record.optionText}</div>
            {record.supplier && (<div>{intl.get("menu.supplier")}: {record.supplier}</div>)}
          </>
        )
      }
    }, {
      title: intl.get("order.sku.barcode"),
      dataIndex: 'barCode',
      key: 'barCode',
      editable: skuEditable,
      width: 150,
    }, {
      title: intl.get("order.sku.salesPrice"),
      dataIndex: 'salesPrice',
      key: 'salesPrice',
      align: "right",
      render: (text, record) => {
        const priceAndCurrency = getSecondaryPriceAndCurrency(record, "salesPrice");
        return (
          <>
            <div>{Util.currencyFormat(record.salesPrice, order.productCurrency)}</div>
            {priceAndCurrency[0] !== 0 && (
              <div style={{color: "gray"}}>{Util.currencyFormat(priceAndCurrency[0], priceAndCurrency[1])}</div>
            )}
          </>
        )
      }
    }, {
      title: intl.get("order.sku.shipQuantity"),
      dataIndex: 'shipQuantity',
      key: 'shipQuantity',
      align: "right",
      render: (text, record) => {
        if (record.salesQuantity !== 1) {
          return record.salesQuantity + "*" + record.quantity + "=" + record.shipQuantity;
        } else {
          return record.shipQuantity;
        }
      }
    }, {
      title: intl.get("order.sku.salesAmount"),
      dataIndex: 'salesAmount',
      key: 'salesAmount',
      align: "right",
      render: (text, record) => {
        const priceAndCurrency = getSecondaryPriceAndCurrency(record, "salesAmount");

        return (
          <>
            <div>{Util.currencyFormat(record.salesAmount, order.productCurrency)}</div>
            {priceAndCurrency[0] !== 0 && (
              <div style={{color: "gray"}}>{Util.currencyFormat(priceAndCurrency[0], priceAndCurrency[1])}</div>
            )}
          </>
        )
      }
    }, {
      title: intl.get("order.sku.discountAmount"),
      dataIndex: 'discountAmount',
      key: 'discountAmount',
      align: "right",
      render: (text, record) => {
        const priceAndCurrency = getSecondaryPriceAndCurrency(record, "discountAmount");
        return (
          <>
            <div>{Util.currencyFormat(record.discountAmount, order.productCurrency)}</div>
            {priceAndCurrency[0] !== 0 && (
              <div style={{color: "gray"}}>{Util.currencyFormat(priceAndCurrency[0], priceAndCurrency[1])}</div>
            )}
          </>
        )
      }
    }, {
      title: intl.get("order.taxRate"),
      dataIndex: 'taxRate',
      key: 'taxRate',
      align: "right",
      editable: skuEditable,
      width: 80,
      suffix: "%",
    }, {
      title: intl.get("order.sellerUserId"),
      dataIndex: 'sellerUserId',
      key: 'sellerUserId',
      align: "center",
      render: (text, record) => {
        if (record.sellerUserId === 0) {
          return (<span></span>)
        }
        return (<div style={{cursor: "pointer"}} onClick={() => onClickSellerId(record)}>{record.sellerUserId}</div>)
      }
    }];

    setColumns(originColumns.map(col => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          suffix: col.suffix,
          handleSave: setTableEditingValue,
        }),
      };
    }));
  };

  const onClickSellerId = (orderSku) => {
    setSelectedSellerUserId(orderSku.sellerUserId);
    setVisibleUserPopup(true);
  };

  const getSecondaryPriceAndCurrency = (sku, priceName) => {
    let secondaryPrice = 0;
    let secondaryPriceCurrency = "";
    if (props.order.productCurrency !== props.order.paymentCurrency || props.order.productCurrency !== props.order.userCurrency) {
      if (sku[priceName + "User"] !== 0) {
        secondaryPrice = sku[priceName + "User"] ;
        secondaryPriceCurrency = props.order.userCurrency;
      } else if (sku[priceName + "Pay"]  !== 0) {
        secondaryPrice = sku[priceName + "Pay"];
        secondaryPriceCurrency = props.order.paymentCurrency;
      }
    }
    return [secondaryPrice, secondaryPriceCurrency];
  };

  const setTableEditingValue = (record, dataIndex, value) => {
    if (dataIndex === "taxRate") {
      const taxRateValue = Number(value);
      if (isNaN(taxRateValue)) {
        message.error(intl.get("common.message.inputNumber"));
        return;
      }
      record.taxRate = taxRateValue;
    }

    const orderSkus = props.order.orderSkus.map(sku => {
      if (sku.id === record.id) {
        return record;
      } else {
        return sku;
      }
    });
    setEditingOrderSkus(orderSkus);
    props.setOrderAssociatedValue("orderSkus", orderSkus);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  return (
      <div>
        <Table size={'small'}
               components={components}
               dataSource={editingOrderSkus}
               columns={columns}
               rowKey={'id'}
               rowClassName={() => 'editable-row'}
               title={() => intl.get("common.label.listTotal", {total: editingOrderSkus.length})}
               pagination={false}
        />
        {visibleUserPopup && (
          <UserShowPopup userId={selectedSellerUserId} handleCancel={() => setVisibleUserPopup(false)}/>
        )}
      </div>
  )
}

export default OrderEditSku;