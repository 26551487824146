import React, {useState} from "react";
import {Input, List, Button, message, Empty, Popconfirm} from "antd";
import intl from "react-intl-universal";
import QuestionApi from "./QuestionApi";
import Util from "../../util/Util";

const ReplyList = ({question, afterSave}) => {
  const [contents, setContents] = useState("");
  const saveReply = () => {
    if (!contents || contents === "") {
      message.error(intl.get("common.inputField", {name: intl.get("questions.reply")}));
      return;
    }

    const reply = {
      title: "",
      contents: contents,
      parentId: question.id,
    };
    QuestionApi.submitQuestion(reply).then(res => {
      message.info(intl.get("common.message.saved"));
      setContents("");
      if (afterSave) {
        afterSave();
      }
    });
  };

  const canEdit = (reply) => {
    if (reply.companyUserId === Util.getUser().id) {
      return true;
    } else if (Util.isAdmin()) {
      return true;
    }
    return false;
  }

  const deleteReply = (reply) => {
    QuestionApi.deleteQuestion(reply.id).then(res => {
      message.info(intl.get("common.message.deleted"));
      setContents("");
      if (afterSave) {
        afterSave();
      }
    });
  };

  return (
    <div>
      <div style={{padding: 20}}>
        <pre>{question.contents}</pre>
      </div>
      <List
        locale={{ emptyText: <Empty description={"댓글이 없습니다."} imageStyle={{display: 'none'}}/> }}
        bordered
        dataSource={question.replies}
        renderItem={reply => (
          <List.Item
            actions={ canEdit(reply) ? [
              <Popconfirm
                title={intl.get("common.confirm.delete", {name: intl.get("questions.reply")})}
                onConfirm={(e) => deleteReply(reply)}
              >
                <a>{intl.get("common.button.delete")}</a>
              </Popconfirm>
            ] : [] }
          >
            <List.Item.Meta
              title={reply.userName === "admin" ? intl.get("common.label.call-center") : reply.userName}
              description={(<div><pre>{reply.contents}</pre></div>)}
            />
          </List.Item>
        )}
      >
        <div style={{padding: "0 20px 20px 20px"}}>
          {question.replies.length > 0 && (<hr style={{border: "1px solid #ededed"}}/>)}
          <div style={{marginTop: 10, display: 'flex', gap: 10, alignItems: 'flex-end'}}>
            <Input.TextArea value={contents} rows={5} onChange={e => setContents(e.target.value)}/>
            <div style={{marginTop: 10, display: 'flex', justifyContent: 'center'}}>
              <Button onClick={saveReply}>{intl.get("common.button.save")}</Button>
            </div>
          </div>
        </div>
      </List>
    </div>
  )
};

export default ReplyList;