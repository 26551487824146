import {adminConfig} from "../../config/admin.config";
import axios from "axios";
const API_BASE_URL = adminConfig.apiServer();

export default class TermsApi {
  static getTermsList = (options) => {
    let apiPath = `${API_BASE_URL}/terms?projection=webAdmin&`;

    apiPath += Object.keys(options).map(key => {
      return key + "=" + options[key]
    }).join("&");

    return axios.get(apiPath);
  };

  static getTerms = (termsId) => {
    let apiPath = `${API_BASE_URL}/terms/${termsId}?projection=webAdmin&`;
    return axios.get(apiPath);
  };

  static getTermsByType = (termsType, language) => {
    let apiPath = `${API_BASE_URL}/terms/byType?termsType=${termsType}&language=${language}&projection=webAdmin&`;
    return axios.get(apiPath);
  };

  static saveTerms = (terms) => {
    if (terms.id) {
      let apiPath = `${API_BASE_URL}/terms/${terms.id}?projection=webAdmin`;
      return axios.put(apiPath, terms);
    } else {
      let apiPath = `${API_BASE_URL}/terms?projection=webAdmin`;
      return axios.post(apiPath, terms);
    }
  }
}