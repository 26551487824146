import axios from "axios";
import {adminConfig} from "../../config/admin.config";

const API_BASE_URL = adminConfig.apiServer();

export default class ProductApi {
  static getProducts = (params) => {
    let apiPath = `${API_BASE_URL}/products?`;

    let paramKeyValues = []
    Object.keys(params).forEach(key => {
      const paramValue = params[key];
      if (Array.isArray(paramValue)) {
        paramKeyValues = paramKeyValues.concat(paramValue.map(v => key + "=" + v));
      } else {
        paramKeyValues.push(key + "=" + paramValue)
      }
    });
    apiPath += paramKeyValues.join("&");
    if (!params.projection) {
      apiPath += "&projection=webAdmin";
    }
    return axios.get(apiPath);
  };

  static getHasEventProducts = (productIds) => {
    let apiPath = `${API_BASE_URL}/products/has-event-products?projection=webAdmin&`;
    apiPath += productIds.map(id => "id=" + id).join("&");
    return axios.get(apiPath);
  };

  static getProduct = (productId) => {
    const apiPath = `${API_BASE_URL}/products/${productId}?projection=webAdmin`;
    return axios.get(apiPath);
  }

  static getProductShares = (params) => {
    let apiPath = `${API_BASE_URL}/products/share?`;

    let paramKeyValues = []
    Object.keys(params).forEach(key => {
      if (params[key]) {
        const paramValue = params[key];
        paramKeyValues.push(key + "=" + paramValue)
      }
    });
    apiPath += paramKeyValues.join("&");
    apiPath += "&projection=webAdmin";

    return axios.get(apiPath);
  };

  static getProductCoupons = (productId) => {
    const apiPath = `${API_BASE_URL}/products/${productId}/coupons?projection=webAdmin`;
    return axios.get(apiPath);
  };

  static saveProduct = (product) => {
    if (product.id ) {
      let apiPath = `${API_BASE_URL}/products/${product.id}?projection=webAdmin`;
      return axios.put(apiPath, product);
    } else {
      let apiPath = `${API_BASE_URL}/products`;
      return axios.post(apiPath, product);
    }
  }

  static saveProducts = (data) => {
    let apiPath = `${API_BASE_URL}/products/batch?projection=webAdmin`;
    return axios.put(apiPath, data);
  }

  static deleteProduct = (productId) => {
    const apiPath = `${API_BASE_URL}/products/${productId}?projection=webAdmin`;
    return axios.delete(apiPath);
  };

  static download = (params) => {
    let apiPath = `${API_BASE_URL}/products/download?projection=webAdmin&`;

    const paramKeyValues = []
    Object.keys(params).forEach(key => {
      if (params[key]) {
        paramKeyValues.push(key + "=" + params[key])
      }
    });
    apiPath += paramKeyValues.join("&")

    return axios.get(apiPath, {
      responseType: 'arraybuffer'
    });
  };

  static getProductsByPageItem = () => {
  }

  static getAllTags = () => {
    let apiPath = `${API_BASE_URL}/products/tags?projection=webAdmin&`;
    return axios.get(apiPath);
  }

  static getTags = (params) => {
    let apiPath = `${API_BASE_URL}/products/tags?projection=webAdmin&`;

    apiPath += Object.keys(params).map(key => {
      return key + "=" + params[key]
    }).join("&");

    return axios.get(apiPath);
  }

  static countDisabledMarketProducts = () => {
    const apiPath = `${API_BASE_URL}/products/market-products/disabled?projection=webAdmin`;
    return axios.get(apiPath);
  }

  static getMarketProductIds = () => {
    let apiPath = `${API_BASE_URL}/products/market-products?projection=webAdmin&`;
    return axios.get(apiPath);
  }

  static marketToMyProduct = (products) => {
    let apiPath = `${API_BASE_URL}/products/market-products?projection=webAdmin&`;
    return axios.post(apiPath, products);
  }

  static deleteMarketProducts = (productIds) => {
    const products = productIds.map(pid => { return {id: pid}});
    let apiPath = `${API_BASE_URL}/products/market-products?projection=webAdmin&`;
    return axios.delete(apiPath, {data: products});
  }

  static getInterlogisProducts = (params) => {
    let apiPath = `${API_BASE_URL}/products/interlogis?projection=webAdmin&`;

    let paramKeyValues = []
    Object.keys(params).forEach(key => {
      if (params[key]) {
        const paramValue = params[key];
        paramKeyValues.push(key + "=" + paramValue)
      }
    });
    apiPath += paramKeyValues.join("&");
    return axios.get(apiPath);
  }

  static getInterlogisStocks = (params) => {
    let apiPath = `${API_BASE_URL}/products/interlogis-stock?projection=webAdmin&`;

    let paramKeyValues = []
    Object.keys(params).forEach(key => {
      if (params[key]) {
        const paramValue = params[key];
        paramKeyValues.push(key + "=" + paramValue)
      }
    });
    apiPath += paramKeyValues.join("&");
    return axios.get(apiPath);
  }

  static getSuppliers = () => {
    const apiPath = `${API_BASE_URL}/products/suppliers?projection=webAdmin`;
    return axios.get(apiPath);
  }
}