import React, {useState, useEffect} from "react";
import {
  Checkbox,
  Descriptions,
  InputNumber,
} from "antd";
import MediaLinkSetting from "./MediaLinkSetting";

import intl from "react-intl-universal";

const PopupSetting = (props) => {
  const [settingValues, setSettingValues] = useState({});

  useEffect(() => {
    // if (!props.settingValues.slot) {
    //   setSettingValues({
    //     height: 300,
    //     slot: {
    //       action: "product",
    //       bannerMediaType: "image",
    //     },
    //   });
    // }
    setSettingValues({...props.settingValues});
  }, [props.settingValues]);

  const slotChanged = (editingSlot) => {
    const settingValuesState = {...settingValues};
    settingValuesState.slot = editingSlot;
    setSettingValues(settingValuesState)
    props.applySetting(props.id, settingValuesState);
  };


  const onChangeSettingValue = (fieldName, value) => {
    const settingValuesState = {...settingValues};
    settingValuesState[fieldName] = value;
    setSettingValues(settingValuesState)
    props.applySetting(props.id, settingValuesState);
  }

  // const onHeightChange = (value) => {
  //   const settingValuesState = {...settingValues};
  //   settingValuesState.height = value;
  //   setSettingValues(settingValuesState)
  //   props.applySetting(props.id, settingValuesState);
  // };

  return (
      <div>
        <Descriptions title={intl.get("page_setting.edit.popup.setting")}
                      bordered layout="vertical" size={"small"} column={1}>
          <Descriptions.Item label={intl.get("page_setting.edit.line.height")}>
            <InputNumber value={settingValues.height} min={1} max={2000} value={settingValues.height} onChange={(value) => onChangeSettingValue('height', value)}></InputNumber>
          </Descriptions.Item>
          <Descriptions.Item label={intl.get("page_setting.edit.popup.showEveryTime")}>
            <Checkbox checked={settingValues.showEveryTime} onChange={(e) => onChangeSettingValue('showEveryTime', e.target.checked)}/>
          </Descriptions.Item>
          <Descriptions.Item labelStyle={{height:0, paddingBottom: 0}}>
            <MediaLinkSetting settingValues={settingValues}
                          id={props.id}
                          slot={settingValues.slot}
                          slotChanged={slotChanged}
                          brands={props.brands}
                          categories={props.categories}
                          imageOnly={true}
            >
            </MediaLinkSetting>
          </Descriptions.Item>
        </Descriptions>
      </div>
  )
};

export default PopupSetting;