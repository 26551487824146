import React, {useEffect, useState} from "react";
import {sortableHandle} from "react-sortable-hoc";
import {MenuOutlined, SettingOutlined} from "@ant-design/icons";
import intl from "react-intl-universal";
import {Layout, Modal} from "antd";
import DraggableTable from "../../DraggableTable";
const {Content, Header} = Layout;

const IconOrderingModal = (props) => {
  const [selectedCvs, setSelectedCvs] = useState({
    editingSettings: [],
  });

  useEffect(() => {
    setSelectedCvs(JSON.parse(JSON.stringify(props.cvs)));
  }, [props.cvs]);

  const onDragEnd = (sortedList, oldIndex, newIndex) => {
    const selectedCvsState = {...selectedCvs};
    selectedCvsState.editingSettings = sortedList;
    setSelectedCvs(selectedCvsState);
  }

  const handleOk = () => {
    props.handleOk(selectedCvs.editingSettings);
  }

  const DragHandle = sortableHandle(() => <MenuOutlined style={{cursor: 'grab', color: '#999'}}/>);
  const columns = [{
    title: intl.get("page_setting.edit.toolbar.sort"),
    dataIndex: 'sort',
    width: 50,
    className: 'drag-visible',
    render: () => <DragHandle/>
  }, {
    title: "Label",
    dataIndex: "title",
    key: "title",
    render: (text, record) => {
      return (<div>{record.id}, {text}</div>)
    }
  }, {
    title: "Image",
    dataIndex: "image",
    key: "image",
    render: (text, record) => {
      return (<img src={text} style={{width: 32, height: 32}}/>);
    }
  }];

  return (
    <Modal
      title={[<div key={1}><SettingOutlined/>아이콘 설정</div>]}
      visible={true}
      onCancel={props.handleCancel}
      onOk={handleOk}
      width={700}
      bodyStyle={{margin: 0, padding: 0}}
    >
      <Layout>
        <Content className="modal-content">
          <DraggableTable
            showHeader={false}
            dataSource={selectedCvs.editingSettings}
            columns={columns}
            rowKey={"id"}
            onSortEnd={onDragEnd}
          />
        </Content>
      </Layout>
    </Modal>
  )
};

export default IconOrderingModal;