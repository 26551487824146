import React, {useEffect, useState} from "react";
import {
  Button, Descriptions, Divider, Form, Input, message,
  Tooltip, Select, Radio, Space, Modal, Layout, Switch
} from "antd";

import {Link} from "react-router-dom";
import ApplicationApi from "./ApplicationApi";
import {QuestionCircleOutlined, SettingOutlined} from '@ant-design/icons';
import ApplicationKeyEditModal from "./ApplicationKeyEditModal";
import Util from "../../util/Util";
import intl from "react-intl-universal";
import FileListPopup from "../file_manager/FileListPopup";

const {Content} = Layout;
const FormItem = Form.Item;
const { TextArea } = Input;
const { Search } = Input;

const ApplicationBasicEdit = (props) => {
  const [form] = Form.useForm();
  const [application, setApplication] = useState({
    defaultCbtType: "none",
    paymentCompany: "smatro",
  });
  const [applicationKey, setApplicationKey] = useState({});
  const [visiblePasswordModal, setVisiblePasswordModal] = useState(false);
  const [password, setPassword] = useState('');
  const [visibleKeyModal, setVisibleKeyModal] = useState(false);
  const [passwordConfirmed, setPasswordConfirmed] = useState(false);
  const [visibleFileModal, setVisibleFileModal] = useState(false);

  useEffect(() => {
    if (props.applicationId) {
      // 목록에 있는 경우 dirty되었을 수도 있기 때문에 edit view에서 다시 가져온다.
      loadApplication(props.applicationId);
    } else {
      form.setFieldsValue(application);
    }
    setPasswordConfirmed(false);
  }, [props.applicationId]);

  const loadApplication = (applicationId) => {
    ApplicationApi.getApplication(applicationId).then(res => {
      const app = res.data;
      app.wholesaleSetting = JSON.stringify(app.wholesaleSetting, undefined, 4);
      app.hongxinSetting = JSON.stringify(app.hongxinSetting, undefined, 4);
      app.adminWebSetting = JSON.stringify(app.adminWebSetting, undefined, 4);
      setApplication(app);
      form.setFieldsValue(app);
      if (props.setApplication) {
        props.setApplication(app);
      }
    });
  };

  const submitApplication = async (values) => {
    const app = {...application};
    const appKey = {...applicationKey}
    Object.assign(app, values);

    if (!app.paymentCompany) {
      message.error(intl.get("common.inputField", {name: intl.get("application.paymentCompany")}));
      return;
    }
    if (app.wholesaleSetting) {
      app.wholesaleSetting = JSON.parse(app.wholesaleSetting);
    }
    if (app.hongxinSetting) {
      app.hongxinSetting = JSON.parse(app.hongxinSetting);
    }
    if (app.adminWebSetting) {
      app.adminWebSetting = JSON.parse(app.adminWebSetting);
    }
    const params = {
      application: app,
      applicationKey: appKey,
    };
    ApplicationApi.submitApplication(params).then(res => {
      message.success(intl.get("common.message.saved"));
      loadApplication(res.data.id);
      if (!values.id) {
        //신규 추가인 경우 List를 재로딩한다.
        props.searchApplications(props.pagination.current);
      }
    });
  };

  const checkAndAppShowKeyEditModal = () => {
    if (application.id && !passwordConfirmed) {
      // 수정인 경우 패스워드 입력
      setVisiblePasswordModal(true);
    } else {
      // 신규인 경우 그냥 입력
      setVisibleKeyModal(true);
    }
  }
  const showAppKeyEditModal = () => {
    if (!password) {
        message.error(intl.get("common.inputField", {name: intl.get("common.label.password")}));
        return;
    }
    // 패스위드 확인 및 데이터 조회를 동시에 한다.
    ApplicationApi.getApplicationKey(application.id, {password: password}).then(res => {
      setPasswordConfirmed(true);
      setPassword("");
      setVisiblePasswordModal(false);
      setApplicationKey(res.data);
      setVisibleKeyModal(true);
    });
  };

  const setApplicationKeyState = (values) => {
    // appKey 데이터 갱신
    const applicationKeyCopy = {...applicationKey};
    applicationKeyCopy.keyData = values;
    setApplicationKey(applicationKeyCopy);
    setVisibleKeyModal(false);
  };

  const okQrFileModal = (fileType, filePathList) => {
    if (filePathList.length === 0) {
      message.error(intl.get("common.message.selectFile"));
      return;
    }
    const formValues = form.getFieldsValue();
    formValues.qrCode = filePathList[0];
    form.setFieldsValue(formValues);
    setVisibleFileModal(false);
  };

  const tooltip = {
    "name": "위챗 미니프로그램 관리 페이지에 등록된 미니프로그램 공식 명칭, https://mp.weixin.qq.com, 设置 -> 小程序名称",
    "appTitle": "미니프로그램 상단 타이틀 바에 노출되는 명칭",
    "wxAppId": "위챗 미니프로그램 관리 페이지에 있는 APP ID, https://mp.weixin.qq.com, 设置 -> AppID(小程序ID)",
    "wxOriginId": "위챗 미니프로그램 관리 페이지에 있는 Origin ID, https://mp.weixin.qq.com, 设置 -> 原始ID",
    "defaultCbtType": "상품 등록시 자동으로 설정되는 통관 방식, 상품 등록 후 각 상품별로 직접 수정 가능, 주문 후 수정 각 주문에서도 수정 가능",
    "productCurrency": "관리도구에서 상품을 관리하는 가격의 통화. 소비자에게 노출되지 않으며 소비자에게 노출되는 통화는 '지븙통화'로 환율 적용하여 노출",
    "paymentRequestCurrency": "지불회사로 지불을 요청하는 통화 단위, 한국 회사인 경우 USD 또는 CNY을 선택할 수 있음. CNY이라도 경우 지불 요청 금액과 소비자가 지불하는 금액은 달라짐." +
        "한국 지불 회사에서 CNY을 USD로 변환하여 위챗으로 요청, 위챗은 USD를 CNY으로 변환하여 소비자에게 지불 요청하기 때문",
    "smsPrefix": "모바일 웹 등 전화번호 기반 로그인 및 개인 정보 수정 처리 시 SMS 전송 메세지의 Prefix, 발송 시에는 '【Prefix】 로그인 인증번호' 형태로 발송",
    "mchCustomsNo": "해관에 등록된 회사 ID",
    "customsEbpCode": "온라인 쇼핑몰 플랫폼 회사 해관 ID",
    "customsEbpName": "온라인 쇼핑몰 플랫폼 회사명(사업자 등록증에 있는 회사명)",
    "customsEbcCode": "온라인 쇼핑몰 운영 회사 해관 ID",
    "customsEbcName": "온라인 쇼핑몰 운영 회사명(사업자 등록증에 있는 회사명)",
    "customsCopCode": "신고 데이터 전송 회사 해관 ID",
    "customsCopName": "신고 데이터 전송 회사명(사업자 등록증에 있는 회사명)",
    "customsDxpId": "해관 시스템에 등록된 데이터 전송 ID",
    "customsCertNo": "해관에 실시간 주문지불 정보 전송시 사용하는 CertNo(해관 웹화면에서 확인)",
    "webMallDomain": "PC웹 쇼핑몰을 사용하는 경우 도메인 입력(http 제외하고 입력)",
  }

  const isAdmin = Util.isAdmin();

  return (
      <div style={{width: "100%"}}>
        <Form onFinish={submitApplication} form={form}>
          <div>
            <h3>기본정보</h3>
            <Descriptions bordered size={"small"} column={2}>
              <Descriptions.Item label={"ID"} span={2}>
                <FormItem name={"id"}>
                  <Input disabled={true}/>
                </FormItem>
              </Descriptions.Item>
              <Descriptions.Item label={Util.getLabelWithHelp(intl.get("application.name"), tooltip["name"], true)}>
                <FormItem name={"name"}
                          rules={[{ required: true, message: intl.get("common.inputField", {name: intl.get("application.name")}) }]}>
                  <Input/>
                </FormItem>
              </Descriptions.Item>
              <Descriptions.Item label={Util.getLabelWithHelp(intl.get("application.appTitle"), tooltip["appTitle"], true)}>
                <FormItem name={"appTitle"}
                          rules={[{ required: true, message: intl.get("common.inputField", {name: intl.get("application.appTitle")}) }]}>
                  <Input/>
                </FormItem>
              </Descriptions.Item>
              <Descriptions.Item label={Util.getLabelWithHelp(intl.get("application.qrCode"), tooltip["qrCode"])} span={2}>
                <FormItem name={"qrCode"}>
                  <Search
                    allowClear
                    placeholder={intl.get("common.label.upload.help")}
                    onSearch={() => setVisibleFileModal(true)}
                  />
                </FormItem>
                {form.getFieldValue("qrCode") && (
                  <div>
                    <img src={form.getFieldValue("qrCode")} style={{width: "100px", height: "100px"}}/>
                  </div>
                )}
              </Descriptions.Item>
              <Descriptions.Item label={Util.getLabelWithHelp(intl.get("application.wxAppId"), tooltip["wxAppId"], true)}>
                <FormItem name={"wxAppId"} rules={[{ required: true, message: intl.get("common.inputField", {name: intl.get("application.wxAppId")}) }]}>
                  <Input/>
                </FormItem>
              </Descriptions.Item>
              <Descriptions.Item label={Util.getLabelWithHelp(intl.get("application.wxOriginId"), tooltip["wxOriginId"])}>
                <FormItem name={"wxOriginId"}>
                  <Input/>
                </FormItem>
              </Descriptions.Item>
              <Descriptions.Item label={Util.getLabelWithHelp(intl.get("application.webMallDomain"), tooltip["webMallDomain"])}>
                <FormItem name={"webMallDomain"}>
                  <Input/>
                </FormItem>
                <div>{intl.getHTML("application.webMallDomain.help")}</div>
              </Descriptions.Item>
              <Descriptions.Item label={Util.getLabelWithHelp(intl.get("application.smsPrefix"), tooltip["smsPrefix"])}>
                <Form.Item name={"smsPrefix"}>
                  <Input disabled={!isAdmin}/>
                </Form.Item>
              </Descriptions.Item>
              {isAdmin && (
                <>
                  <Descriptions.Item label={"DefaultApp"}>
                    <FormItem name={"isDefaultApp"} valuePropName="checked">
                      <Switch/>
                    </FormItem>
                    <div>
                      1210, 9610 통관하는 경우 통관 신고하는 회사의<br/>
                      웹 쇼핑몰에 신고하는 모든 상품이 표시되어야 함<br/>
                      신고하는 회사의 앱인 경우 활성화
                    </div>
                  </Descriptions.Item>
                  <Descriptions.Item label={intl.get("application.useLive")}>
                    <Form.Item name={"useLive"}>
                      <Radio.Group>
                        <Radio value={true}>{intl.get("common.true")}</Radio>
                        <Radio value={false}>{intl.get("common.false")}</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Descriptions.Item>
                </>
              )}
              <Descriptions.Item label={"관리웹 설정"} span={2}>
                <Form.Item name={"adminWebSetting"}>
                  <TextArea rows={4}/>
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label={"웹몰 하단 연락처"}>
                <FormItem name={"footerContact"}>
                  <TextArea rows={3}/>
                </FormItem>
              </Descriptions.Item>
              <Descriptions.Item label={"웹몰 하단 Copyright"}>
                <FormItem name={"footerCopyright"}>
                  <Input/>
                </FormItem>
              </Descriptions.Item>
              <Descriptions.Item label={"웹몰 하단 연락처"}>
                <FormItem name={"footerContact"}>
                  <TextArea rows={3}/>
                </FormItem>
              </Descriptions.Item>
              <Descriptions.Item label={"웹몰 하단 통신사업자번호"}>
                <FormItem name={"footerRegisterNo"}>
                  <TextArea rows={3}/>
                </FormItem>
              </Descriptions.Item>
              <Descriptions.Item label={"알람 메일 수신자"}>
                <FormItem name={"notificationEmails"}>
                  <Input/>
                </FormItem>
                <div>여러개 매일을 등록하는 경우 콤마(,)로 구분하여 입력</div>
              </Descriptions.Item>
            </Descriptions>
          </div>
          <div style={{marginTop: 20}}>
            <h3>{intl.get("application.group.price")}</h3>
            <Descriptions bordered size={"small"} column={2}>
              <Descriptions.Item label={Util.getLabelWithHelp(intl.get("application.productCurrency"), tooltip["productCurrency"], true)} span={2}>
                <Form.Item name={"productCurrency"}
                           rules={[{ required: true, message: intl.get("common.inputField", {name: intl.get("application.productCurrency")}) }]}
                           >
                  <Select style={{width: 200}}>
                    <Select.Option key={"USD"} value={"USD"}>USD</Select.Option>
                    <Select.Option key={"CNY"} value={"CNY"}>CNY</Select.Option>
                    <Select.Option key={"KRW"} value={"KRW"}>KRW</Select.Option>
                  </Select>
                </Form.Item>
                <div style={{marginTop: 10}}>
                  {intl.getHTML("application.productCurrency.help")}
                </div>
              </Descriptions.Item>
              <Descriptions.Item label={Util.getLabelWithHelp(intl.get("application.paymentCurrency"), tooltip["paymentRequestCurrency"], true)} span={2}>
                <Form.Item name={"paymentRequestCurrency"}
                           rules={[{ required: true, message: intl.get("common.inputField", {name: intl.get("application.paymentCurrency")}) }]}
                           >
                  <Select style={{width: 200}}>
                    <Select.Option key={"USD"} value={"USD"}>USD</Select.Option>
                    <Select.Option key={"KRW"} value={"KRW"}>KRW</Select.Option>
                    <Select.Option key={"CNY"} value={"CNY"}>CNY</Select.Option>
                  </Select>
                </Form.Item>
                <div style={{marginTop: 10}}>
                  {intl.getHTML("application.paymentCurrency.help")}
                </div>
              </Descriptions.Item>
              <Descriptions.Item label={Util.getLabelWithHelp(intl.get("application.paymentCompany"), tooltip["paymentCompany"], true)} span={2}>
                <Space>
                  <Form.Item name={"paymentCompany"}
                             rules={[{ required: true, message: intl.get("common.inputField", {name: intl.get("application.paymentCompany")}) }]}
                  >
                    <Select style={{width: 300}}>
                      <Select.Option key={"smatro"} value={"smatro"}>{intl.get("application.paymentCompany.smatro")}</Select.Option>
                      <Select.Option key={"eximbay"} value={"eximbay"}>{intl.get("application.paymentCompany.eximbay")}</Select.Option>
                      <Select.Option key={"nicepay"} value={"nicepay"}>{intl.get("application.paymentCompany.nicepay")}</Select.Option>
                      <Select.Option key={"wechatpay"} value={"wechatpay"}>{intl.get("application.paymentCompany.wechatpay")}</Select.Option>
                    </Select>
                  </Form.Item>
                  <div style={{marginBottom: 5}}>
                    <Button onClick={checkAndAppShowKeyEditModal}>{intl.get("application.setting.key")}</Button>
                  </div>
                </Space>
              </Descriptions.Item>
              <Descriptions.Item label={Util.getLabelWithHelp(intl.get("application.defaultCbtType"), tooltip["defaultCbtType"], true)} span={2}>
                <Form.Item name={"defaultCbtType"}
                           rules={[{ required: true, message: intl.get("common.inputField", {name: intl.get("application.defaultCbtType")}) }]}>
                  <Select style={{width: 200}}>
                    <Select.Option key={"none"} value={"none"}>{intl.get("order.cbtType.none")}</Select.Option>
                    <Select.Option key={"mail"} value={"mail"}>{intl.get("order.cbtType.mail")}</Select.Option>
                    <Select.Option key={"1210"} value={"1210"}>{intl.get("order.cbtType.1210")}</Select.Option>
                    <Select.Option key={"9610"} value={"9610"}>{intl.get("order.cbtType.9610")}</Select.Option>
                  </Select>
                </Form.Item>
                <div style={{marginTop: 10}}>
                  {intl.getHTML("application.mchCustomsNo.help")}
                </div>
              </Descriptions.Item>
            </Descriptions>
          </div>
          <div style={{marginTop: 20}}>
            <h3>{intl.get("application.group.local.logistics")}</h3>
            <div>{intl.get("application.local.logistics.help")}</div>
            <Descriptions bordered size={"small"} column={2}>
              <Descriptions.Item label={intl.get("application.localLogisticsCompany")}>
                <Form.Item name={"localLogisticsCompany"}>
                  <Select style={{width: 300}}>
                    <Select.Option key={"none"} value={""}>{intl.get("application.localLogisticsCompany.none")}</Select.Option>
                    <Select.Option key={"interlogis"} value={"interlogis"}>인터로지스(Interlogis)</Select.Option>
                  </Select>
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label={intl.get("application.localLogisticsUserId")}>
                <Form.Item name={"localLogisticsUserId"}>
                  <Input/>
                </Form.Item>
              </Descriptions.Item>
            </Descriptions>
          </div>
          {(isAdmin || application.mchCustomsNo) && (
            <div style={{marginTop: 20}}>
              <h3>{intl.get("application.group.customs")}</h3>
              <Descriptions bordered size={"small"} column={2}>
                <Descriptions.Item label={Util.getLabelWithHelp(intl.get("application.mchCustomsNo"), tooltip["mchCustomsNo"])} span={2}>
                  <Form.Item name={"mchCustomsNo"}>
                    <Input disabled={!isAdmin}/>
                  </Form.Item>
                  <div>{intl.get("application.mchCustomsNo.help")}</div>
                </Descriptions.Item>
                <Descriptions.Item label={Util.getLabelWithHelp(intl.get("application.declareDirectly"), tooltip["declareDirectly"])} span={2}>
                  <Form.Item name={"declareDirectly"}>
                    <Radio.Group>
                      <Radio value={true}>{intl.get("application.declareDirectly.true")}</Radio>
                      <Radio value={false}>{intl.get("application.declareDirectly.false")}</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Descriptions.Item>
                { !application.declareDirectly && (
                  <>
                  <Descriptions.Item label={"Hongxin 설정"} span={2}>
                    <Form.Item name={"hongxinSetting"}>
                      <TextArea rows={6}/>
                    </Form.Item>
                  </Descriptions.Item>
                  </>
                )}
                <Descriptions.Item label={"할인 0원으로 신고"} span={2}>
                  <FormItem name={"resetDiscountAmount"} valuePropName="checked">
                    <Switch/>
                  </FormItem>
                </Descriptions.Item>
                <Descriptions.Item label={Util.getLabelWithHelp(intl.get("application.customsEbpCode"), tooltip["customsEbpCode"])}>
                  <Form.Item name={"customsEbpCode"}>
                    <Input/>
                  </Form.Item>
                  <div>{intl.get("application.customsEbpCode.help")}</div>
                </Descriptions.Item>
                <Descriptions.Item label={Util.getLabelWithHelp(intl.get("application.customsEbpName"), tooltip["customsEbpName"])}>
                  <Form.Item name={"customsEbpName"}>
                    <Input/>
                  </Form.Item>
                  <div>{intl.get("application.customsEbpName.help")}</div>
                </Descriptions.Item>
                <Descriptions.Item label={Util.getLabelWithHelp(intl.get("application.customsEbcCode"), tooltip["customsEbpCode"])}>
                  <Form.Item name={"customsEbcCode"}>
                    <Input/>
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label={Util.getLabelWithHelp(intl.get("application.customsEbcName"), tooltip["customsEbpName"])}>
                  <Form.Item name={"customsEbcName"}>
                    <Input/>
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label={Util.getLabelWithHelp(intl.get("application.customsCopCode"), tooltip["customsCopCode"])}>
                  <Form.Item name={"customsCopCode"}>
                    <Input/>
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label={Util.getLabelWithHelp(intl.get("application.customsCopName"), tooltip["customsCopName"])}>
                  <Form.Item name={"customsCopName"}>
                    <Input/>
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label={Util.getLabelWithHelp(intl.get("application.customsDxpId"), tooltip["customsDxpId"])}>
                  <Form.Item name={"customsDxpId"}>
                    <Input/>
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label={Util.getLabelWithHelp(intl.get("application.customsCertNo"), tooltip["customsCertNo"])}>
                  <Form.Item name={"customsCertNo"}>
                    <Input/>
                  </Form.Item>
                </Descriptions.Item>
              </Descriptions>
            </div>
          )}
          {(isAdmin || application.mchCustomsNo) && (
            <div style={{marginTop: 20}}>
              <h3>{intl.get("application.group.bankAccount")}</h3>
              <Descriptions bordered size={"small"} column={2}>
                <Descriptions.Item label={Util.getLabelWithHelp(intl.get("application.bankRecpAccount"), tooltip["bankRecpAccount"])} span={2}>
                  <Form.Item name={"bankRecpAccount"}>
                    <Input/>
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label={Util.getLabelWithHelp(intl.get("application.bankRecpCode"), tooltip["bankRecpCode"])} span={2}>
                  <Form.Item name={"bankRecpCode"}>
                    <Input/>
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label={Util.getLabelWithHelp(intl.get("application.bankRecpName"), tooltip["bankRecpName"])} span={2}>
                  <Form.Item name={"bankRecpName"}>
                    <Input/>
                  </Form.Item>
                </Descriptions.Item>
              </Descriptions>
            </div>
          )}

          <div style={{marginTop: 20}}>
            <h3>도매몰 설정</h3>
            <Descriptions bordered size={"small"} column={2}>
              <Descriptions.Item label={"도매몰 사용"} span={2}>
                <FormItem name={"isWholesaleApp"} valuePropName="checked">
                  <Switch/>
                </FormItem>
              </Descriptions.Item>
              <Descriptions.Item label={"설정"} span={2}>
                <Form.Item name={"wholesaleSetting"}>
                 <TextArea rows={6}/>
                </Form.Item>
              </Descriptions.Item>
            </Descriptions>
          </div>
        </Form>
        <div style={{marginTop: 10, width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
          <Button type="primary" onClick={() => form.submit()}>{intl.get("common.button.save")}</Button>
          <Divider type="vertical"/>
          <Button type="primary">
            <Link to={`${props.parentPath}`}>{intl.get("common.button.list")}</Link>
          </Button>
        </div>
        {visibleKeyModal && (
            <ApplicationKeyEditModal
                applicationId={application.id}
                applicationKey={applicationKey}
                password={password}
                paymentCompany={form.getFieldValue("paymentCompany")}
                onCancel={() => setVisibleKeyModal(false)}
                onOk={setApplicationKeyState}
            />
        )}
        {visibleFileModal && (
            <FileListPopup id={"appQrCode"}
                           fileKind={"appQrCode"}
                           visible={true}
                           multipleFile={false}
                           canSelect={true}
                           handleCancel={() => setVisibleFileModal(false)}
                           handleOk={okQrFileModal}
            />
        )}
        <Modal  title={[<div key={1}><SettingOutlined/> {intl.get("application.input.password")}</div>]}
                visible={visiblePasswordModal}
                onCancel={() => {setPassword(''); setVisiblePasswordModal(false);} }
                onOk={showAppKeyEditModal}
                width={400}
                bodyStyle={{margin: 0, padding: 0}}
        >
          <Layout>
            <Content className="modal-content">
              <Input.Password value={password} onChange={(e) => setPassword(e.target.value) }/>
            </Content>
          </Layout>
        </Modal>
      </div>
  );
}

export default ApplicationBasicEdit;