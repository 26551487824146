import React, {useEffect, useState} from "react";
import {Button, Steps, message, Spin} from "antd";
import DeclarePayment from "./DeclarePayment";
import intl from "react-intl-universal";
import SendOrderToDeclareCompany from "./SendOrderToDeclareCompany";
import SendOrderToKoreaLogistics from "./SendOrderToKoreaLogistics"
import CreateOrderDeclare from "./CreateOrderDeclare";
import OrderDelivery from "./OrderDelivery";
import DeliveryStatus from "./DeliveryStatus";
import SendOrderToPCAgent from "./SendOrderToPCAgent";

const { Step } = Steps;

const Clearance9610 = (props) => {
  const [currentStep, setCurrentStep] = useState(0);

  const onChangeStep = (step) => {
    setCurrentStep(step);
  };

  const steps = [{
      title: intl.get("order.clearance.createDeclareInfo"),
    }, {
      title: intl.get("order.clearance.payment"),
    }, {
      title: intl.get("order.clearance.localDelivery"),
    }, {
      title: intl.get("order.clearance.delivery"),
    }, {
      title: intl.get("order.clearance.order.bonded"),
    }, {
      title: intl.get("order.clearance.order"),
    }, {
      title: intl.get("order.clearance.deliveryStatus"),
    }];

  return (
    <div>
      <Steps size="small"
             current={currentStep}
             onChange={onChangeStep}>
        { steps.map((step, index) => (<Step key={index} title={step.title}/>)) }
      </Steps>
      <div className="steps-item">
        <div className="steps-item-body">
          <div>
            { currentStep === 0 && (
              <CreateOrderDeclare order={props.order} application={props.application}/>
            )}
            { currentStep === 1 && (
              <DeclarePayment order={props.order} application={props.application}/>
            )}
            { currentStep === 2 && (
              <SendOrderToKoreaLogistics order={props.order} application={props.application}/>
            )}
            { currentStep === 3 && (
              <OrderDelivery order={props.order} application={props.application} loadOrder={props.loadOrder}/>
            )}
            { currentStep === 4 && (
              <div>
                중국 현지 신고업체의 요청으로 엑셀로 다운로드 받아 제출합니다. <p/>
                엑셀 다운로드 방법은 다음 두가지 방법 사용: 주문 목록에서 주문을 검색한 다음<p/>
                <ul>
                  <li>1. 전송할 주문을 선택 후 다운로드 버튼 클릭</li>
                  <li>2. 검색 결과 전체를 다운받을 경우 검색 결과 천체 다운받기 버튼 클릭</li>
                </ul>
              </div>
            )}
            { currentStep === 5 && (
              <SendOrderToPCAgent order={props.order} application={props.application}/>
            )}
            { currentStep === 6 && (
              <DeliveryStatus order={props.order} application={props.application}/>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Clearance9610;