import React, {useEffect, useState} from "react";
import {Descriptions, Input, Layout, Modal, Radio} from "antd";
import {SettingOutlined} from "@ant-design/icons";
import intl from "react-intl-universal";
const {Content, Header} = Layout;

const SettingEditModal = (props) => {
  const [selectedCvs, setSelectedCvs] = useState({});

  useEffect(() => {
    setSelectedCvs(props.cvs);
  }, [props.cvs]);

  const onCvsFieldChanged = (fieldName, value) => {
    const selectedCvsState = JSON.parse(JSON.stringify(selectedCvs));
    selectedCvsState[fieldName] = value;
    selectedCvsState.saved = false;
    setSelectedCvs(selectedCvsState);
  }

  const handleOk = () => {
    props.saveCvs(selectedCvs, false);
    props.handleOk();
  };

  return (
    <Modal
      title={[<div key={1}><SettingOutlined/>카테고리 항목 설정</div>]}
      visible={true}
      onCancel={props.handleCancel}
      onOk={handleOk}
      okText={intl.get("common.button.save")}
      width={600}
      bodyStyle={{margin: 0, padding: 0}}>
      <Layout>
        <Content className="modal-content">
          <Descriptions bordered size={"small"} column={1}>
            <Descriptions.Item label={"라벨"}>
              <Input disabled={selectedCvs ? false : true}
                     value={selectedCvs ? selectedCvs.title : ""}
                     onChange={(e) => onCvsFieldChanged("title", e.target.value)}/>
            </Descriptions.Item>
            <Descriptions.Item label={"사용"}>
              <Radio.Group disabled={selectedCvs ? false : true}
                           value={selectedCvs ? selectedCvs.active : false}
                           onChange={(e) => onCvsFieldChanged("active", e.target.value)}>
                <Radio value={true}>사용</Radio>
                <Radio value={false}>미사용</Radio>
              </Radio.Group>
            </Descriptions.Item>
          </Descriptions>
        </Content>
      </Layout>
    </Modal>
  )
};

export default SettingEditModal;