import React, {useEffect, useState} from "react";

import NoImage from "../../../asset/images/no_image.png";

const BannerTemplateOneTwoPreview = (props) => {
  const {settingValues} = props;
  const slots = settingValues.slots;

  return (
    <div style={{margin: "0 16px", display: "flex", justifyContent: "center", gap: 6}}>
      <div style={{width: 170, height: 200}}>
        <img style={{width: 170, height: 200}} src={slots.length > 0 && slots[0].bannerPath ? slots[0].bannerPath : NoImage}/>
      </div>
      <div style={{display: "flex", flexDirection: "column", gap: 6}}>
        <div>
          <img style={{width: 170, height: 87}} src={slots.length > 1 && slots[1].bannerPath ? slots[1].bannerPath : NoImage}/>
        </div>
        <div>
          <img style={{width: 170, height: 108}} src={slots.length > 2 && slots[2].bannerPath ? slots[2].bannerPath : NoImage}/>
        </div>
      </div>
    </div>
  )
};

export default BannerTemplateOneTwoPreview;