import React, {useState, useEffect} from "react";
import ProductSlot from "./ProductSlot";
import BannerSlot from "./BannerSlot";
import ImageSlot from "./ImageSlot";
import LineSlot from "./LineSlot";
import PopupSlot from "./PopupSlot";
import SearchBarSlot from "./SearchBarSlot";
import TextSlot from "./TextSlot";
import ToolbarSlot from "./ToolbarSlot";
import { DeleteFilled } from '@ant-design/icons';
import intl from "react-intl-universal";

import "./Preview.less";

const Preview = (props) => {
  const [selectedPageItemId, setSelectedPageItemId] = useState(-1);
  const {pageItems, popupVisible} = props;

  useEffect(() => {
    if (props.selectedPageItemId) {
      setSelectedPageItemId(props.selectedPageItemId);
    }
  }, [props.selectedPageItemId]);

  const onItemSelected = (id) => {
    setSelectedPageItemId(id);
    props.showSetting(id);
  };

  const getItemComponent = (pageItem) => {
    const passingProps = {
      application: props.application,
      applicationDesign: props.applicationDesign,
      exchangeRate: props.exchangeRate,
      closePopup: props.closePopup,
    }
    if (pageItem.type === "banner") {
      return (<BannerSlot {...pageItem} {...passingProps}/>)
    } else if (pageItem.type === "popup") {
      if (popupVisible) {
        return (<PopupSlot {...pageItem} {...passingProps}/>)
      } else {
        return (<div/>)
      }
    } else if (pageItem.type === "product") {
      return (<ProductSlot {...pageItem} {...passingProps}/>)
    } else if (pageItem.type === "image") {
      return (<ImageSlot {...pageItem} {...passingProps}/>)
    } else if (pageItem.type === "search") {
      return (<SearchBarSlot {...pageItem} {...passingProps}/>)
    } else if (pageItem.type === "text") {
      return (<TextSlot {...pageItem} {...passingProps}/>)
    } else if (pageItem.type === "line") {
      return (<LineSlot {...pageItem} {...passingProps}/>)
    } else if (pageItem.type === "toolbar") {
      return (<ToolbarSlot {...pageItem} {...passingProps}/>)
    }
  };

  const getPageItemPreview = (item, className, isDefaultSelected, marginTop) => {
    const selected = selectedPageItemId === -1 ? isDefaultSelected : item.id === selectedPageItemId;
    return (
      <div key={item.id} className={className} style={{marginTop: marginTop}}>
        <div style={{position: "relative"}}>
          {selected && (
            <div className="floating-button">
              <DeleteFilled onClick={() => props.removeItem(item.id)}/>
            </div>
          )}
          <div
            className={"preview-slot" + (selected ? " selected" : "")}
            // style={{width: selected ? 384 : 375}}
            onClick={() => onItemSelected(item.id)}>
            {getItemComponent(item)}
          </div>
        </div>
      </div>
    )
  };

  const pageItemsInBody = pageItems.filter(item => {
    if (item.type === "popup") {
      return false;
    }
    if (item.type === "toolbar" && (item.settingValues.fixed === "top" || item.settingValues.fixed === "bottom")) {
      return false;
    }
    return true;
  });

  const pageItemPopup = pageItems.find(item => item.type === "popup");
  const bottomFixed = pageItems.find(item => item.type === "toolbar" && item.settingValues.fixed === "bottom");
  const topFixed = pageItems.find(item => item.type === "toolbar" && item.settingValues.fixed === "top");
  const marginTop = topFixed ? topFixed.settingValues.height + "px" : "0px";
  return (
      <div>
        { pageItemPopup && popupVisible && (
            <>
              <div style={{
                backgroundColor: "rgba(0,0,0,.45)",
                bottom: 0,
                height: "100%",
                left: 0,
                position: "absolute",
                right: 0,
                top: 0,
                zIndex: 100,
              }}/>
              <div onClick={() => onItemSelected(pageItemPopup.id)}>
                { getItemComponent(pageItemPopup)}
              </div>
            </>
        )}

        <div className="preview-body-wrapper">
          { topFixed && (getPageItemPreview(topFixed, 'preview-top', false, "0px"))}
          <div className={"preview-body"}>
            {pageItemsInBody.map((eachItem, index) => {
              return getPageItemPreview(eachItem, '', index === 0, index === 0 ? marginTop : "0px");
            })}
          </div>
          { bottomFixed && (getPageItemPreview(bottomFixed, 'preview-bottom', false, "0px"))}
        </div>
      </div>
  )
}

export default Preview;