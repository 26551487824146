import React from 'react';

import {
  Table,
  Button,
  Input,
  Space, Spin, Popconfirm,
  message,
} from 'antd';

import intl from "react-intl-universal";
import {Link} from "react-router-dom";
import Util from "../../util/Util";
import {useHistory} from "react-router-dom";
import ReplyList from "./ReplyList";
import QuestionApi from "./QuestionApi";

const QuestionList = (props) => {
  const history = useHistory();

  const tableChanged = (e) => {
    const paginationState = {...props.pagination};
    paginationState.current = e.current;
    paginationState.pageSize = e.pageSize;
    props.setPagination(paginationState);
    props.searchQuestions(paginationState);
  };

  const onClickSearch = () => {
    const paginationState = {...props.pagination};
    paginationState.current = 1;
    props.setPagination(paginationState);
    props.searchQuestions(paginationState);
  };

  const onFilterChanged = (fieldName, value) => {
    const filter = {...props.filter};
    filter[fieldName] = value;
    props.setFilter(filter);
  };

  const replySaved = (id) => {
    const question = props.questions.find(q => q.id === id);
    if (!question) {
      message.error("no question data");
      return;
    }
    QuestionApi.getQuestion(id).then(res => {
      props.setQuestion(res.data);
    });
  };

  const { questions, filter } = props;

  const columns = [{
    title: "ID",
    dataIndex: 'id',
    key: 'id',
  }, {
    title: intl.get("questions.title"),
    dataIndex: 'title',
    key: 'title',
  }, {
    title: intl.get("company.user.name"),
    dataIndex: 'userName',
    key: 'userName',
    render: (text, record) => {
      return (<span>{text === "admin" ? intl.get("common.label.call-center") : text}</span>)
    }
  }, {
    title: intl.get("questions.reply"),
    dataIndex: 'id',
    key: 'reply',
    align: 'right',
    render: (text, record) => (
      <span>{record.replies.length}</span>
    )
  }, {
    title: intl.get("common.createdAt"),
    dataIndex: 'createdAt',
    key: 'createdAt',
    align: 'center',
    render: (text, record) => (
        <span>{Util.getLocalTime(text)}</span>
    )
  }, Table.EXPAND_COLUMN, {
    title: '',
    dataIndex: '',
    key: 'action',
    render: (text, record) => {
      return(
          <Space>
            <Popconfirm
              title={intl.get("common.confirm.delete", {name: intl.get("questions.question")})}
              onConfirm={(e) => { e.stopPropagation(); props.deleteQuestion(record) }}
            >
              <Button block type="default"
                      size="small"
                      onClick={(e) => e.stopPropagation()}>
                {intl.get("common.button.delete")}
              </Button>
            </Popconfirm>
          </Space>
      )
    }
  }];

  return (
      <div>
        <Spin spinning={props.loading}>
          <div className="page-op-box">
            <div className="filter-box">
              <div style={{width: "100%", display: "flex", gap: 10, alignItems: "center"}}>
                {/*<Input style={{ width: 200, marginRight: 10}}*/}
                {/*       value={filter.keyword}*/}
                {/*       onChange={e => onFilterChanged('keyword', e.target.value)}*/}
                {/*       placeholder={"Keyword"}/>*/}
                {/*<Checkbox checked={notReplied} style={{ marginRight: 10 }} onChange={e => this.setState({notReplied: e.target.checked})}>답변안함</Checkbox>*/}
                {/*<Checkbox checked={notFinished} style={{ marginRight: 10 }} onChange={e => this.setState({notFinished: e.target.checked})}>미완료</Checkbox>*/}
                <div style={{marginLeft: "auto"}}>
                  <div style={{width: 100}}>
                    <Button style={{width: 100}} type="primary" block size="default" onClick={onClickSearch}>{intl.get("common.button.search")}</Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="extra-op-box">
              <Link to={`${props.match.path}/new`}>
                <Button type="default" size="small">{intl.get("common.button.add")}</Button>
              </Link>
            </div>
          </div>
          <div style={{marginTop: "10px"}}>
            <Table size={'small'}
                   rowKey={'id'}
                   dataSource={questions}
                   columns={columns}
                   title={() => 'Total: ' + props.pagination.total}
                   pagination={props.pagination}
                   onChange={tableChanged}
                   onRow={(record, rowIndex) => {
                     return {
                       onClick: event => {
                         if (Util.isAdmin()) {
                           // history.push(props.parentPath + "/" + record.id);
                         }
                       },
                     }
                   }}
                   expandable={{
                     expandedRowRender: record => (<ReplyList question={record} afterSave={() => replySaved(record.id)}/>),
                     rowExpandable: record => true,
                   }}
            />
          </div>
        </Spin>
      </div>

  )
};

export default QuestionList;
