import React, {useEffect, useState} from "react";
import {Spin, Button, message, Descriptions, Select, Input} from "antd";
import ClearanceApi from "./ClearanceApi";
import ApplicationLogisticsCompanyApi from "../../application_logistics_company/ApplicationLogisticsCompanyApi";
import Util from "../../../util/Util";
import intl from "react-intl-universal";

const OrderDelivery = (props) => {
  const [updating, setUpdating] = useState(false);
  const [orderDelivery, setOrderDelivery] = useState({});
  const [logisticsCompanyOptions, setLogisticsCompanyOptions] = useState([]);

  useEffect(() => {
    const {orderDelivery} = props.order;
    setOrderDelivery(orderDelivery);
    loadApplicationLogisticsCompanies()
  }, [props.order.id]);

  const loadApplicationLogisticsCompanies = () => {
    ApplicationLogisticsCompanyApi.getApplicationLogistics().then( res => {
      // console.log('getApplicationLogistics.res :>> ', res.data);
      const options = res.data.map(item => {
        return {
          code: item.logisticsCompany.code,
          name: item.logisticsCompany.name,
        }
      })
      setLogisticsCompanyOptions(options)
    })
  }
  const onChangeDeliveryCompany = (value) => {
    const orderDeliveryState = {...orderDelivery};
    orderDeliveryState.deliveryCompany = value;
    setOrderDelivery(orderDeliveryState);
  };

  const onChangeDeliveryNo = (e) => {
    const orderDeliveryState = {...orderDelivery};
    orderDeliveryState.deliveryNo = e.target.value;
    setOrderDelivery(orderDeliveryState);
  }

  const saveOrderDelivery = () => {
    const orderDeliveryState = {...orderDelivery};
    if (!orderDeliveryState.deliveryCompany) {
      alert(intl.get("common.inputField", {name: intl.get("order.delivery.logisticsCompany")}));
      return;
    }
    if (!orderDeliveryState.deliveryNo) {
      alert(intl.get("common.inputField", {name: intl.get("order.delivery.logisticsNo")}));
      return;
    }

    if (!window.confirm(intl.get("common.confirm.save", {name: intl.get("order.clearance.deliveryInfo")}))) {
      return;
    }

    setUpdating(true);
    ClearanceApi.saveOrderDelivery(props.order.id, orderDeliveryState).then(res => {
      message.success(intl.get("common.message.saved"));
      if (props.loadOrder) {
        props.loadOrder();
      }
    }).catch(res => {
      console.log("배송정보 저장 오류:", res);
    }).finally(() => {
      setUpdating(false);
    });
  };

  return (
    <Spin spinning={updating}>
      <div>
        <div style={{ display: "flex", marginBottom: 10}}>
          <div>
            {intl.getHTML("order.deliveryInfo.help")}
          </div>
          <div style={{marginLeft: "auto"}}>
            <Button type="primary" size="small" onClick={saveOrderDelivery}>{intl.get("common.button.save")}</Button>
          </div>
        </div>
        <div>
          <Descriptions bordered
                        size={"small"}
                        column={2}>
            <Descriptions.Item label={intl.get("order.delivery.logisticsCompany")}>
              <Select style={{width: "100%"}} value={orderDelivery.deliveryCompany} onChange={onChangeDeliveryCompany}>
                {/* <Select.Option key="YTO" value="YTO">圆通（1210）</Select.Option>
                <Select.Option key="YTG" value="YTG">通达（9610）</Select.Option>
                <Select.Option key="YUNDA" value="YUNDA">韵达</Select.Option>
                <Select.Option key="SF" value="SF">顺丰速运</Select.Option>
                <Select.Option key="ZTO" value="ZTO">中通快递</Select.Option> */}
                {logisticsCompanyOptions.map(o => <Select.Option key={o.code} value={o.code}>{o.name}</Select.Option>)}
              </Select>
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("order.delivery.logisticsNo")}>
              <Input value={orderDelivery.deliveryNo} onChange={onChangeDeliveryNo}/>
            </Descriptions.Item>
          </Descriptions>
        </div>
      </div>
    </Spin>
  );
};

export default OrderDelivery;