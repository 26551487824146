import {PriceView} from "./PriceView";
import {ShoppingCartOutlined} from "@ant-design/icons";
import React from "react";
import ProductColumns from "./ProductColumns";

const ProductOneColumn = (props) => {
  const {applicationDesign, settingValues} = props;
  const { themeColor } = applicationDesign.themeSetting;

  const backgroundColor = settingValues.backgroundColor || "#ffffff";
  let padding = "16,16,16,16";
  if (settingValues.padding) {
    padding = settingValues.padding;
  }
  const paddingStyle = padding.split(",").map(p => p.trim() + "px").join(" ");

  const style = {
    backgroundColor: backgroundColor,
    padding: paddingStyle,
  };

  if (settingValues.backgroundImage) {
    style.backgroundImage = "url('" + settingValues.backgroundImage + "')";
    style.backgroundSize = "cover";
    style.backgroundRepeat = "no-repeat";
    style.backgroundPosition = "center center";
  }

  return (
    <div className="one-column-box" style={style}>
      {settingValues.title && (<div className="header">{settingValues.title}</div>)}
      {props.products.map(product => (
        <div key={product.id} className="item-box" style={{backgroundColor: "#ffffff"}}>
          <img style={{width: 104, height: 104}} src={product.images[0] + "?x-oss-process=image/resize,w_256"}/>
          <div className="item-r">
            <div className="u-line-2 title">{product.name}</div>
            <div style={{display: "flex", alignItems: "flex-end"}}>
              <PriceView listPrice={product.listPrice}
                         salesPrice={product.salesPrice}
                         width={"80%"}
                         {...props}
              />
              {settingValues.showCart && (
                <div style={{marginLeft: "auto"}}>
                  <ShoppingCartOutlined style={{ fontSize: '20px', color: themeColor.colorTable.price }}/>
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default ProductOneColumn;