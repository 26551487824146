import React, {useEffect, useState} from "react";
import {
  Button, Descriptions, Divider, Form, Input, message,
  Tooltip, Select, Radio, Space, DatePicker, InputNumber, List, Switch
} from "antd";
import intl from "react-intl-universal";
import {Link, useHistory} from "react-router-dom";
import CouponEventApi from "./CouponEventApi";
import ProductSearchPopup from "../product/ProductSearchPopup";
import TagSettingPopup from "../product/TagSettingPopup";
import AppPageSearchPopup from "../app_page/AppPageSearchPopup";
import dayjs from "dayjs";
import ProductApi from "../product/ProductApi";
import ApplicationPageApi from "../app_page/ApplicationPageApi";
import Util from "../../util/Util";

const { RangePicker } = DatePicker;

const CouponEventEdit = (props) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [visibleTagModal, setVisibleTagModal] = useState(false);
  const [visibleDiscountProductModal, setVisibleDiscountProductModal] = useState(false);
  const [visibleShareProductModal, setVisibleShareProductModal] = useState(false);
  const [visibleEventPageModal, setVisibleEventPageModal] = useState(false);

  const [couponEvent, setCouponEvent] = useState({
    type: "discount",
    active: false,
    discountType: "price",
  });
  const [discountEventObject, setDiscountEventObject] = useState({
    objectType: "all_products",
    objects: [],
  });
  const [shareEventObject, setShareEventObject] = useState({
    objectType: "product",
    objects: [],
  });

  useEffect(() => {
    if (props.match.params.couponEventId) {
      // 목록에 있는 경우 dirty되었을 수도 있기 때문에 edit view에서 다시 가져온다.
      loadCouponEvent(props.match.params.couponEventId);
    } else {
      form.setFieldsValue(couponEvent);
    }
  }, [props.match.params.couponEventId]);

  const loadCouponEvent = (couponEventId) => {
    CouponEventApi.getCouponEvent(couponEventId).then(res => {
      const ce = res.data;
      if (ce.startDate) {
        ce.range = [dayjs(ce.startDate, "YYYY-MM-DD"), dayjs(ce.endDate, "YYYY-MM-DD")];
      }
      setCouponEvent(ce);
      loadEventObject(ce.type, JSON.parse(JSON.stringify(ce.eventObject)));
      form.setFieldsValue(ce);
      if (props.setCouponEvent) {
        props.setCouponEvent(ce);
      }
    });
  };

  const loadEventObject = (type, eventObject) => {
    if (type === "welcome") {
      return;
    }
    if (eventObject.objects.length === 0) {
      if (type === "share") {
        setShareEventObject(eventObject);
      } else if (type === "discount") {
        setDiscountEventObject(eventObject);
      }
      return;
    }

    if (type === "discount") {
      if (eventObject.objectType === "product") {
        ProductApi.getProducts({id: eventObject.objects}).then(res => {
          eventObject.objects = res.data.data;
          setDiscountEventObject(eventObject);
        });
      } else if (eventObject.objectType === "brand") {
        const found = props.brands.find(b => b.id === eventObject.objects[0]);
        if (found) {
          eventObject.objects[0] = found;
        }
        setDiscountEventObject(eventObject);
      } else if (eventObject.objectType === "tag") {
        setDiscountEventObject(eventObject);
      }
    } else if (type === "share") {
      if (eventObject.objectType === "eventPage") {
        ApplicationPageApi.getAppPage(eventObject.objects[0]).then(res => {
          eventObject.objects[0] = res.data;
        }).catch(err => {
          if (err.response?.status === 404) {
            message.error("행사 대상 페이지 정보를 찾을 수 없습니다.")
          }
        }).finally(() => {
          setShareEventObject(eventObject);
        });
      } else if (eventObject.objectType === "product") {
        ProductApi.getProduct(eventObject.objects[0]).then(res => {
          eventObject.objects[0] = res.data;
        }).catch(err => {
          if (err.response?.status === 404) {
            message.error("행사 대상 상품정보를 찾을 수 없습니다.")
          }
        }).finally(() => {
          setShareEventObject(eventObject);
        });
      }
    }
  };

  const submitCouponEvent = (values) => {
    const couponEventState = {...couponEvent};
    Object.assign(couponEventState, values);

    couponEventState.issueCondition = "" + couponEventState.issueCondition;
    // objects에서 화면 표시를 위해 저장된 정보는 모두 제거
    if (couponEventState.type === "discount") {
      const discountEventObjectCopy = JSON.parse(JSON.stringify(discountEventObject));
      if (discountEventObjectCopy.objectType !== "all_products") {
        if (discountEventObjectCopy.objects.length === 0) {
          message.error("할인대상 선택하세요.");
          return;
        }
      }
      if (discountEventObjectCopy.objectType === "product" || discountEventObjectCopy.objectType === "brand") {
        discountEventObjectCopy.objects = discountEventObject.objects.map(o => o.id);
      }
      couponEventState.eventObject = discountEventObjectCopy;
    } else if (couponEventState.type === "share") {
      const shareEventObjectCopy = JSON.parse(JSON.stringify(shareEventObject));
      shareEventObjectCopy.issueCondition = "" + values.issueCondition;
      if (shareEventObjectCopy.objectType !== "app") {
        if (shareEventObjectCopy.objects.length === 0) {
          message.error("공유대상 선택하세요.");
          return;
        }
        shareEventObjectCopy.objects = shareEventObjectCopy.objects.map(o => o.id);
      }
      couponEventState.eventObject = shareEventObjectCopy;
    }

    // couponEventState.discountAmount = parseFloat(couponEventState.discountAmount);
    CouponEventApi.submitCouponEvent(couponEventState).then(res => {
      message.success(intl.get("common.message.saved"));
      if (values.id) {
        props.setCouponEvent(res.data);
      } else {
        //신규 추가인 경우 List를 재로딩한다.
        props.searchCouponEvents(props.pagination);
        history.push(props.parentPath + "/" + res.data.id);
      }
    });
  };

  const onFormValueChange = (changedValues, allValues) => {
    const couponEventState = {...couponEvent};
    Object.assign(couponEventState, allValues);
    if (allValues.range && allValues.range.length >= 2) {
      couponEventState.startDate = allValues.range[0].format('YYYY-MM-DD');
      couponEventState.endDate = allValues.range[1].format('YYYY-MM-DD');
    }
    setCouponEvent(couponEventState);
  };

  ///////////////////////////////////////////////
  // discount 이벤트 대상 설정
  const onDiscountEventObjectChanged = (value) => {
    const discountEventObjectState = {...discountEventObject};
    discountEventObjectState.objectType = value;
    discountEventObjectState.objects = [];
    setDiscountEventObject(discountEventObjectState);
  };

  const okDiscountProductSearch = (products) => {
    const discountEventObjectState = {...discountEventObject};
    discountEventObjectState.objects = products;
    setDiscountEventObject(discountEventObjectState);
    setVisibleDiscountProductModal(false);
  };

  const okTag = (tags) => {
    const discountEventObjectState = {...discountEventObject};
    discountEventObjectState.objects = tags;
    setDiscountEventObject(discountEventObjectState);
    setVisibleTagModal(false);
  };

  const onBrandChanged = (brandId) => {
    const brand = props.brands.find(b => b.id === brandId);
    const discountEventObjectState = {...discountEventObject};
    discountEventObjectState.objects = [brand];
    setDiscountEventObject(discountEventObjectState);
  };

  const deleteDiscountObject = (index) => {
    const discountEventObjectState = {...discountEventObject};
    discountEventObjectState.objects.splice(index, 1);
    setDiscountEventObject(discountEventObjectState);
  };
  // discount 이벤트 대상 설정
  ///////////////////////////////////////////////

  ///////////////////////////////////////////////
  // share 이벤트 대상 설정
  const onShareEventObjectChanged = (value) => {
    const shareEventObjectState = {...shareEventObject};
    shareEventObjectState.objectType = value;
    shareEventObjectState.objects = [];
    setShareEventObject(shareEventObjectState);
  };

  const okShareProductSearch = (products) => {
    const shareEventObjectState = {...shareEventObject};
    shareEventObjectState.objects = products;
    setShareEventObject(shareEventObjectState);
    setVisibleShareProductModal(false);
  };

  const okAppPageSearch = (eventPage) => {
    const shareEventObjectState = {...shareEventObject};
    shareEventObjectState.objects = [eventPage];
    setShareEventObject(shareEventObjectState);
    setVisibleEventPageModal(false);
  }
  // share 이벤트 대상 설정
  ///////////////////////////////////////////////

  let couponTypeName = intl.get("coupon.type." + couponEvent.type);
  return (
      <div style={{width: "100%"}}>
        <Form onFinish={submitCouponEvent} onValuesChange={onFormValueChange} form={form}>
          <Descriptions bordered size={"small"} column={2}>
            <Descriptions.Item label={"ID"}>
              <Form.Item name={"id"}>
                <Input disabled={true}/>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("coupon.active.true")}>
              <Form.Item name={"active"} valuePropName="checked">
                <Switch/>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("coupon.name")}>
              <Form.Item name={"name"} rules={[{ required: true, message: intl.get("common.inputField", {name: intl.get("coupon.name")}) }]}>
                <Input/>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("coupon.type")}>
              <Form.Item name={"type"}
                         rules={[{ required: true, message: intl.get("common.inputField", {name: intl.get("coupon.type")}) }]}>
                <Select>
                  <Select.Option key={"discount"} value={"discount"}>{intl.get("coupon.type.discount")}</Select.Option>
                  <Select.Option key={"welcome"} value={"welcome"}>{intl.get("coupon.type.welcome")}</Select.Option>
                  <Select.Option key={"share"} value={"share"}>{intl.get("coupon.type.share")}</Select.Option>
                </Select>
              </Form.Item>
            </Descriptions.Item>
            {/*<Descriptions.Item label={"할인유형"}>*/}
            {/*  <Form.Item name={"discountType"}>*/}
            {/*    <Radio.Group>*/}
            {/*      <Radio value={"price"}>금액</Radio>*/}
            {/*      <Radio value={"rate"}>비율</Radio>*/}
            {/*    </Radio.Group>*/}
            {/*  </Form.Item>*/}
            {/*</Descriptions.Item>*/}
            <Descriptions.Item label={intl.get("coupon.amountAndRate")}>
              <Space>
                <span>{couponEvent.discountType === "price" ? Util.getSymbol(props.application.productCurrency) : ""}</span>
                <Form.Item name={"discountAmount"} rules={[{ required: true, message: intl.get("common.inputField", {name: intl.get("coupon.amountAndRate")}) }]}>
                  <InputNumber step={0.01}/>
                </Form.Item>
                <span>{couponEvent.discountType === "rate" ? "%" : ""}</span>
              </Space>
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("coupon.summary")} span={2}>
              <Form.Item name={"summary"}>
                <Input/>
              </Form.Item>
            </Descriptions.Item>
          </Descriptions>
          <div style={{marginTop: 20}}></div>
          <Descriptions bordered size={"small"} column={1} title={couponTypeName + " " + intl.get("coupon.setting")}>
            {couponEvent.type === "welcome" && (
                <>
                  <Descriptions.Item label={intl.get("coupon.duration")}>
                    <Space>
                      <span>{intl.get("coupon.from.register")}</span>
                        <Form.Item name={"duration"}>
                            <InputNumber/>
                        </Form.Item>
                      <span>{intl.get("coupon.from.to")}</span>
                    </Space>
                  </Descriptions.Item>
                </>
            )}
            {(couponEvent.type === "discount" || couponEvent.type === "share") && (
                <Descriptions.Item label={intl.get("coupon.duration")}>
                  <Form.Item name={"range"}  rules={[{ required: true,  message: intl.get("common.inputField", {name: intl.get("coupon.duration")}) }]}>
                    <RangePicker
                        format="YYYY-MM-DD"
                        placeholder={[intl.get("coupon.dateStart"),intl.get("coupon.dateEnd")]}
                    />
                  </Form.Item>
                </Descriptions.Item>
            )}
            {couponEvent.type === "share" && (
              <>
                <Descriptions.Item label={intl.get("coupon.issue.condition")}>
                  <Space>
                    <span>{intl.get("coupon.friends")} </span>
                      <Form.Item name={"issueCondition"} rules={[{ required: true, message: intl.get("common.inputField", {name: intl.get("coupon.issue.condition")}) }]}>
                        <InputNumber/>
                      </Form.Item>
                    <span>{intl.get("coupon.receive.like")}</span>
                  </Space>
                </Descriptions.Item>
                <Descriptions.Item label={intl.get("coupon.share.object")}>
                  <Space>
                    <Select style={{width: 300}} value={shareEventObject.objectType} onChange={onShareEventObjectChanged}>
                      <Select.Option key={"product"} value={"product"}>{intl.get("coupon.products.product")}</Select.Option>
                      <Select.Option key={"app"} value={"app"}>{intl.get("coupon.app")}</Select.Option>
                      <Select.Option key={"eventPage"} value={"eventPage"}>{intl.get("coupon.page")}</Select.Option>
                    </Select>
                    {shareEventObject.objectType === "product" && (
                        <Button onClick={() => setVisibleShareProductModal(true)}>{intl.get("coupon.button.select.product")}</Button>
                    )}
                    {shareEventObject.objectType === "eventPage" && (
                        <Button onClick={() => setVisibleEventPageModal(true)}>{intl.get("coupon.button.select.page")}</Button>
                    )}
                  </Space>
                  <div style={{marginTop: 10}}>
                    {shareEventObject.objectType === "product" && shareEventObject.objects.length > 0 && (
                        <div>{intl.get("products.product")}: {shareEventObject.objects[0].name}</div>
                    )}
                    {shareEventObject.objectType === "eventPage" && shareEventObject.objects.length > 0 && (
                        <div>{intl.get("coupon.page")}: {shareEventObject.objects[0].title}</div>
                    )}
                  </div>
                </Descriptions.Item>
              </>
            )}
            {couponEvent.type === "discount" && (
                <>
                  <Descriptions.Item label={intl.get("coupon.products")}>
                    <Space>
                      <Select style={{width: 300}} value={discountEventObject.objectType} onChange={onDiscountEventObjectChanged}>
                        <Select.Option key={"all_products"} value={"all_products"}>{intl.get("coupon.products.all_products")}</Select.Option>
                        <Select.Option key={"product"} value={"product"}>{intl.get("coupon.products.product")}</Select.Option>
                        <Select.Option key={"brand"} value={"brand"}>{intl.get("coupon.products.brand")}</Select.Option>
                        <Select.Option key={"tag"} value={"tag"}>{intl.get("coupon.products.tag")}</Select.Option>
                      </Select>
                      {discountEventObject.objectType === "brand" && (
                          <Select style={{width: 300}} value={discountEventObject.objects.length > 0 ? discountEventObject.objects[0].id : ""} onChange={onBrandChanged}>
                            {props.brands.map(b => (
                              <Select.Option key={b.id} value={b.id}>{b.name}</Select.Option>
                            ))}
                          </Select>
                      )}
                      {discountEventObject.objectType === "tag" && (
                          <Button onClick={() => setVisibleTagModal(true)}>{intl.get("coupon.button.select.tag")}</Button>
                      )}
                      {discountEventObject.objectType === "product" && (
                          <Button onClick={() => setVisibleDiscountProductModal(true)}>{intl.get("coupon.button.select.product")}</Button>
                      )}
                    </Space>
                    {(discountEventObject.objectType === "product" || discountEventObject.objectType === "tag") && (discountEventObject.objects.length > 0) && (
                      <div style={{marginTop: 10, width: 600}}>
                          <List
                              size="small"
                              bordered
                              dataSource={discountEventObject.objects}
                              renderItem={(item, index) => (
                                  <List.Item>
                                    <div style={{display: "flex", alignItems: "center", width: "100%"}}>
                                      { discountEventObject.objectType === "product" && (<div>{item.name}</div>)}
                                      { discountEventObject.objectType === "tag" && (<div>{item}</div>)}
                                      <div style={{marginLeft: "auto"}}><Button size={"small"} onClick={() => deleteDiscountObject(index)}>{intl.get("common.button.delete")}</Button></div>
                                    </div>
                                  </List.Item>
                              )}
                          />
                      </div>
                    )}
                  </Descriptions.Item>
                </>
            )}
          </Descriptions>
        </Form>
        <div style={{marginTop: 10, width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
          <Button type="primary" onClick={() => form.submit()}>{intl.get("common.button.save")}</Button>
          <Divider type="vertical"/>
          <Button type="primary">
            <Link to={`${props.parentPath}`}>{intl.get("common.button.list")}</Link>
          </Button>
        </div>
        {visibleDiscountProductModal && (
            <ProductSearchPopup
                showSku={false}
                selectMultiple={true}
                handleCancel={() => setVisibleDiscountProductModal(false)}
                handleOk={okDiscountProductSearch}
            />
        )}

        {visibleShareProductModal && (
            <ProductSearchPopup
                showSku={false}
                selectMultiple={false}
                handleCancel={() => setVisibleShareProductModal(false)}
                handleOk={okShareProductSearch}
            />
        )}
        {visibleTagModal && (
          <TagSettingPopup
              selectMultiple={true}
              handleOk={okTag}
              handleCancel={() => setVisibleTagModal(false)}
          />
        )}
        {visibleEventPageModal && (
            <AppPageSearchPopup
                handleCancel={() => setVisibleEventPageModal(false)}
                handleOk={okAppPageSearch}
            />
        )}
      </div>
  );
}

export default CouponEventEdit;