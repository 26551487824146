import React, {useEffect, useState} from "react";

import {
  Button,
  Descriptions,
  Table,
  Divider, message, InputNumber
} from "antd";
import {Link} from "react-router-dom";
import Util from "../../util/Util";
import intl from "react-intl-universal";
import SkuSearchPopup from "./SkuSearchPopup";

const EventProductSkuEdit = (props) => {
  ///////////////////////////////////////////////////////////////////////
  // state and variable
  const [maxSkuId, setMaxSkuId] = useState(1);
  const [productCurrencySymbol, setProductCurrencySymbol] = useState("USD");
  const [visibleSkuSearch, setVisibleSkuSearch] = useState(false);
  // state and variable
  ///////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////
  // react hook
  useEffect(() => {
    props.setVisibleSkuPreview(true);
  }, []);

  // SKU 입력 화면 구성을 초기에 하나 입력으로 할지, N개 입력으로 할지 설정
  useEffect(() => {
    // maxId 설정
    let skuMaxId = 0;
    if (props.product.skus) {
      props.product.skus.forEach(sku => {
        if (sku.id > skuMaxId) {
          skuMaxId = sku.id;
        }
      });
    }
    setMaxSkuId(skuMaxId);
  }, [props.product]);

  useEffect(() => {
    const currencySymbol = Util.getSymbol(props.application.productCurrency);
    setProductCurrencySymbol(currencySymbol);
  }, [props.application]);
  // react hook
  ///////////////////////////////////////////////////////////////////////

  const onProductPriceChanged = (fieldName, value) => {
    const product = JSON.parse(JSON.stringify(props.product));
    product[fieldName] = value;
    props.setProduct(product);
  }

  const removeSku = (event, skuId) => {
    event.stopPropagation();
    const product = JSON.parse(JSON.stringify(props.product));
    product.skus = product.skus.filter(s => s.id !== skuId);
    props.setProduct(product);
  };

  const getSkuMinMaxSalesPrice = () => {
    let minValue = 0;
    let maxValue = Number.MAX_SAFE_INTEGER;
    if (props.product?.skus) {
      minValue = Math.min(...props.product.skus.map(s => s.minSalesPrice));
      maxValue = Math.max(...props.product.skus.map(s => s.maxSalesPrice));
      if (maxValue === 0) {
        maxValue = Number.MAX_SAFE_INTEGER;
      }
    }
    return [minValue, maxValue]
  }

  // SKU 검색 모달에서 선택한 경우
  const okSkuSearch = (selectedSkus) => {
    if (selectedSkus.length === 0) {
      message.error(intl.get("products.sku.skuNotSelected"));
      return;
    }

    let maxSkuIdState = maxSkuId;
    let errMessages = [];

    // 기존 상품을 이용해서 새로운 상품을 만드는 것이기 때문에 새로 추가되는 SKU에는 기존 상품에 대한 정보를 설정
    // selectedValues는 {product, sku} 구조체
    const eventOriginProductSet = new Set();
    const addedSkus = selectedSkus.map(selectedSku => {
      if (props.product.skus.find(s => s.eventOriginSkuId === selectedSku.id)) {
        errMessages.push(selectedSku.optionText);
      }
      // 원본 제품, SKU 정보를 설정한다.
      if (selectedSku.productId) {
        eventOriginProductSet.add(selectedSku.productId);
      }
      selectedSku.salesQuantity = 1;
      selectedSku.eventOriginProductId = selectedSku.productId;
      selectedSku.eventOriginSkuId = selectedSku.id;

      selectedSku.id = maxSkuIdState + 1;
      selectedSku.productId = props.product.id;

      maxSkuIdState++;
      return selectedSku;
    });

    if (errMessages.length > 0) {
      message.error(intl.get("products.sku.exist.optionText") + " [" + errMessages.join(", ") + "]");
      return;
    }
    setMaxSkuId(maxSkuIdState);
    setVisibleSkuSearch(false);
    const product = JSON.parse(JSON.stringify(props.product));
    product.skus = product.skus ? product.skus.concat(addedSkus) : addedSkus;
    props.setProduct(product);
  };

  const skuDataChanged = (fieldName, value, skuId) => {
    const product = JSON.parse(JSON.stringify(props.product));

    product.skus = product.skus.map(sku => {
      if (sku.id == skuId) {
        sku[fieldName] = value;
      }
      return sku;
    });
    props.setProduct(product);
  };

  //ID	이미지	옵션	바코드	소매판매가격 범위	할인전표시가격	개당판매가격	수량	판매가격
  const currencySymbol = Util.getSymbol(props.application.productCurrency);
  const skuColumns = [{
    title: "",
    dataIndex: 'skuImage',
    key: 'skuImage',
    align: 'center',
    width: 80,
    render: (text, record) => (
      <span><img src={text + "?x-oss-process=image/resize,h_64,w_64"} style={{width: "64px", height: "64px"}}/></span>
    )
  }, {
    title: intl.get("products.name"),
    dataIndex: 'optionText',
    key: 'optionText',
    align: 'left',
    textWrap: 'word-break',
    render: (text, record) => {
      return (
        <div>
          {record.productName && (<div>{record.productName}</div> )}
          {record.optionText && (<div style={{fontSize: 12, color: "gray"}}>{record.optionText}</div>)}
          <div style={{fontSize: 12, color: "gray"}}>{record.barCode}</div>
        </div>
      )
    }
  }, {
    title: intl.get("products.minMaxSalesPrice"),
    dataIndex: 'id',
    key: 'minSalesPrice',
    align: 'left',
    width: 120,
    textWrap: 'word-break',
    render: (text, record) => (
      <span>{Util.currencyFormat(record.minSalesPrice, props.application.productCurrency)} ~ {Util.currencyFormat(record.maxSalesPrice, props.application.productCurrency)}</span>
    )
  }, {
    title: intl.get("products.sku.listPrice"),
    dataIndex: 'listPrice',
    key: 'listPrice',
    align: 'right',
    width: 130,
    render: (text, record) => {
      return (
        <InputNumber
          prefix={currencySymbol}
          style={{ width: '100px' }}
          value={text}
          min={0} step={0.1}
          onChange={value => skuDataChanged('listPrice', value, record.id)}/>
      )
    }
  }, {
    title: intl.get("products.sku.salesPrice"),
    dataIndex: 'salesPrice',
    key: 'salesPrice',
    align: 'right',
    width: 130,
    render: (text, record) => {
      return (
        <InputNumber
          prefix={currencySymbol}
          style={{ width: '100px' }}
          value={text}
          min={0}
          step={0.1}
          onChange={value => skuDataChanged('salesPrice', value, record.id)}/>
      )
    }
  }, {
    title: intl.get("products.sku.salesQuantity"),
    dataIndex: 'salesQuantity',
    key: 'salesQuantity',
    align: 'right',
    width: 100,
    render: (text, record) => {
      return (
        <InputNumber
          style={{ width: '70px'}}
          value={text}
          min={1}
          step={1}
          onChange={value => skuDataChanged('salesQuantity', value, record.id)}/>
      )
    }
  }, {
    title: '',
    dataIndex: '',
    key: 'action',
    align: 'center',
    width: 60,
    render: (text, record) => {
      return (
        <span>
            <a onClick={(e) => removeSku(e, record.id)}>{intl.get("common.button.delete")}</a>
          </span>
      )
    }
  }];

  const minMaxPrice = getSkuMinMaxSalesPrice();
  return (
      <>
        <Descriptions bordered size={"small"} column={1}>
          <Descriptions.Item label={intl.get("products.price.rep")}>
            <>
              <div style={{display: "flex", alignItems: "center", gap: 10}}>
                <div>
                  <span style={{color: "red", marginRight: 2}}>*</span>
                  <span>{intl.get("products.salesPrice")}:</span>
                </div>
                <span style={{marginRight: 0}}>{productCurrencySymbol}</span>
                <InputNumber value={props.product.salesPrice}
                             style={{width: 200, marginRight: 10}} min={minMaxPrice[0]} max={minMaxPrice[1]} step={0.01}
                             onChange={(value) => onProductPriceChanged('salesPrice', value)}/>
                <span>{intl.get("products.listPrice")}:</span>
                <span style={{marginRight:0}}>{productCurrencySymbol}</span>
                <InputNumber value={props.product.listPrice}
                             style={{width: 200}} min={0} step={0.01}
                             onChange={(value) => onProductPriceChanged('listPrice', value)}/>
              </div>
              <div>{intl.getHTML("products.price.help")}</div>
            </>
          </Descriptions.Item>
        </Descriptions>

        <div style={{marginTop: 20}}>
          <div style={{textAlign: "right",  marginLeft: "auto"}}>
            <Button type={"primary"} onClick={() => setVisibleSkuSearch(true)}>
              {intl.get("common.button.add")}
            </Button>
          </div>
          <div style={{marginTop: 10}}>
            <Table size={'small'}
                   dataSource={props.product.skus}
                   columns={skuColumns}
                   rowKey={'id'}
                   pagination={false}
            />
          </div>
        </div>

        {visibleSkuSearch && (
            <SkuSearchPopup eventProduct={false}
                            disableEventProductFilter={true}
                            handleCancel={() => setVisibleSkuSearch(false)}
                            handleOk={okSkuSearch}
            />
        )}
      </>
  )
}

export default EventProductSkuEdit;