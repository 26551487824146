import React from "react";
import {Descriptions, Modal} from "antd";
import intl from "react-intl-universal";
import {getCustomsCodeText} from "../../util/Code";
import Util from "../../util/Util";
import {Link} from "react-router-dom";

const OrderSkuShowModal = (props) => {
  const {order, orderSku} = props;

  if (!order.id) {
    return <div></div>
  }

  const orderSkuDeclare = order.orderDeclare ? order.orderDeclare.orderSkuDeclares.find(osd => osd.orderSkuId === orderSku.id) : {};

  return (
      <Modal
          title="주문 제품"
          visible={true}
          onOk={() => props.onOk()}
          onCancel={() => props.onCancel()}
          width={"80%"}
          cancelButtonProps={{style: {display: 'none'}}}
      >
        <div>
          <div>
            <h3>
              {intl.get("order.orderNo")}: {order.orderNo}, {intl.get("products.id")}: {orderSku.productId}, {intl.get("order.sku.skuId")}: {orderSku.skuId}
            </h3>
          </div>
          <hr/>

          <Descriptions bordered size={"small"} column={3}>
            <Descriptions.Item label={intl.get("order.sku.productName")} span={3}><Link to={`/products/${orderSku.productId}`}>{orderSku.productName}</Link></Descriptions.Item>
            <Descriptions.Item label={intl.get("order.sku.imageUrl")}><img src={orderSku.skuImage + "?x-oss-process=image/resize,h_48,w_48"} style={{width: "48px", height: "48px"}}/></Descriptions.Item>
            <Descriptions.Item label={intl.get("order.sku.optionText")}>{orderSku.optionText}</Descriptions.Item>
            <Descriptions.Item label={intl.get("order.sku.barcode")}>{orderSku.barCode}</Descriptions.Item>
            <Descriptions.Item label={intl.get("order.sku.salesPrice")}>
              <span>{Util.currencyFormat(orderSku.salesPrice, order.productCurrency)}</span>
              { order.userExchangeRate !== 1.0 && (
                  <span style={{marginLeft: 10, fontSize: 12, color: "gray"}}>{Util.currencyFormat(orderSku.salesPriceUser, order.userCurrency)}</span>
              )}
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("order.sku.shipQuantity")}>{orderSku.shipQuantity}</Descriptions.Item>
            <Descriptions.Item label={intl.get("order.sku.salesAmount")}>
              {Util.currencyFormat(order.salesAmount, order.productCurrency)}
              { order.userExchangeRate !== 1.0 && (
                  <span style={{marginLeft: 10, fontSize: 12, color: "gray"}}>{Util.currencyFormat(order.salesAmountUser, order.userCurrency)}</span>
              )}
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("order.sku.discountAmount")}>
              {Util.currencyFormat(orderSku.discountAmount, order.productCurrency)}
              { order.userExchangeRate !== 1.0 && (
                  <span style={{marginLeft: 10, fontSize: 12, color: "gray"}}>{Util.currencyFormat(orderSku.discountAmountUser, order.userCurrency)}</span>
              )}
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("order.sku.taxAmount")}>
              {orderSkuDeclare ? Util.currencyFormat(orderSkuDeclare.taxAmount, order.productCurrency) : ""}
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("products.sku.taxRate")}>
              {orderSku.taxRate}%
            </Descriptions.Item>
            <Descriptions.Item label={"해관 신고용 정보"} span={3}>
              <div style={{marginBottom: 5}}>한국에서 출발하는 수출(직구, 9610) 방식에서만 사용</div>
              <Descriptions bordered size={"small"} column={2}>
                <Descriptions.Item label={intl.get("order.sku.hsCode")}>{orderSku.hsCode}</Descriptions.Item>
                <Descriptions.Item label={intl.get("order.sku.skuName")}>{orderSku.skuName}</Descriptions.Item>
                <Descriptions.Item label={intl.get("order.sku.customsOptionText")}>{orderSku.customsOptionText}</Descriptions.Item>
                <Descriptions.Item label={intl.get("order.sku.weight")}>{orderSku.weight}</Descriptions.Item>
                <Descriptions.Item label={intl.get("order.sku.volume")}>{orderSku.volume}</Descriptions.Item>
                <Descriptions.Item label={intl.get("order.sku.unit")}>{getCustomsCodeText(orderSku.unit)}({orderSku.unit})</Descriptions.Item>
                <Descriptions.Item label={intl.get("order.sku.unit1")}>{getCustomsCodeText(orderSku.unit1)}({orderSku.unit1})</Descriptions.Item>
                <Descriptions.Item label={intl.get("order.sku.qty")}>{orderSku.qty1}</Descriptions.Item>
                <Descriptions.Item label={intl.get("order.sku.unit2")}>{getCustomsCodeText(orderSku.unit2)}({orderSku.unit2})</Descriptions.Item>
                <Descriptions.Item label={intl.get("order.sku.qty2")}>{orderSku.qty2}</Descriptions.Item>
              </Descriptions>
            </Descriptions.Item>
            {/*<Descriptions.Item label={"오퍼정보"}>*/}
            {/*  <Descriptions bordered size={"small"} column={2}>*/}
            {/*    <Descriptions.Item label={intl.get("order.sku.offerTitle")}><Link to={`/offers/${orderSku.offeerId}`}>{orderSku.offerTitle}</Link></Descriptions.Item>*/}
            {/*    <Descriptions.Item label={intl.get("order.sku.offerSkuQuantity")}>{orderSku.offerSkuQuantity}</Descriptions.Item>*/}
            {/*    <Descriptions.Item label={intl.get("order.sku.salesManagerUserId")}>{orderSku.sellerUserId}</Descriptions.Item>*/}
            {/*    <Descriptions.Item label={intl.get("order.sku.campaignId")}>{orderSku.campaignId}</Descriptions.Item>*/}
            {/*  </Descriptions>*/}
            {/*</Descriptions.Item>*/}
          </Descriptions>
        </div>
      </Modal>
  )
};

export default OrderSkuShowModal;