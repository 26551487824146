import React, {useEffect, useState} from "react";
import {Button, Table, Modal, Layout} from "antd";
import ProductApi from "./../product/ProductApi";
import {RedEnvelopeOutlined} from "@ant-design/icons";

const {Content} = Layout;

const ProductCouponPopup = (props) => {
  const [couponEvents, setCouponEvents] = useState();

  useEffect(() => {
    loadCouponEvents();
  }, [props.product.id]);

  const loadCouponEvents = () => {
    ProductApi.getProductCoupons(props.product.id).then(res => {
      setCouponEvents(res.data);
    });
  };

  const columns = [{
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
  }, {
    title: "이벤트명",
    dataIndex: 'name',
    key: 'name',
  }, {
    title: "이벤트 종류",
    dataIndex: 'type',
    key: 'type',
    render: (text, record) => (
        <span>{text === "welcome" ? "신규 가입자 쿠폰" : (text === "discount" ? "일반 할인(기간)" : "공유 이벤트")}</span>
    )
  }, {
    title: "상태",
    dataIndex: 'active',
    key: 'active',
    align: 'left',
    render: (text, record) => (
        <span>{record.active ? "진행중" : "미진행"}</span>
    )
  }, {
    title: "할인금액(할인율)",
    dataIndex: 'discountAmount',
    key: 'discountAmount',
    align: 'right',
    render: (text, record) => (
        <span>{record.discountType === "price" ? "¥" + record.discountAmount : record.discountAmount + "%"}</span>
    )
  }, {
    title: "기간",
    dataIndex: ' startDate',
    key: 'startDate',
    align: 'center',
    render: (text, record) => (
        <span>{record.startDate} ~ {record.endDate}</span>
    )
  }];

  return (
      <Modal
          title={[<div key={1}><RedEnvelopeOutlined style={{color: "red"}}/> 상품 [{props.product.name}]에 사용 가능한 쿠폰</div>]}
          visible={true}
          onCancel={props.handleCancel}
          footer={null}
          width={800}
          bodyStyle={{margin: 0, padding: 0}}
      >
        <Layout>
          <Content className="modal-content">
              <div style={{marginTop: "10px"}}>
                <Table size={'small'}
                       dataSource={couponEvents}
                       columns={columns}
                       rowKey={'id'}
                       pagination={false}
                />
              </div>
          </Content>
        </Layout>
      </Modal>
  );
}

export default ProductCouponPopup;