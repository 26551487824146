import React, {useEffect, useState} from "react";

import {
  Button,
  Input,
  Radio,
  Descriptions,
  message, Popover,
} from "antd";
import intl from "react-intl-universal";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import FileListPopup from "../file_manager/FileListPopup";
import Util from "../../util/Util";

//https://github.com/zenoamaro/react-quill
//https://jpuri.github.io/react-draft-wysiwyg/#/demo
const ProductDescEdit = (props) => {
  const product = {...props.product};
  const [visibleFileModal, setVisibleFileModal] = useState(false);
  const [productImage, setProductImage]= useState({
    addingMode : "upload",
    currentImagePath: "",
    maxId: 0,
    addedImages: [],
  });

  useEffect(() => {
    const productImageState = {...productImage};
    let maxId = productImageState.maxId;
    productImageState.addedImages = product.descImages?.map((image, index) => {
      maxId++;
      return {
        id: "" + maxId,
        image: image,
      }
    });
    productImageState.maxId = maxId;
    setProductImage(productImageState);
  }, [product.id]);

  useEffect(() => {
    props.setVisibleSkuPreview(false);
  }, [props.visibleSkuPreview]);

  const onDescModeChanged = (e) => {
    product.descMode = e.target.value;
    props.setProduct(product);
  };

  /////////////////////////////////////////////////////////////////////////////
  // product file drag and drop
  const grid = 8;
  const getDragItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // padding: grid * 2,
    margin: `0 ${grid}px 0 0`,
    // background: "grey",

    // styles we need to apply on draggables
    ...draggableStyle
  });

  const getDragListStyle = isDraggingOver => ({
    background: 'white',
    display: 'flex',
    flexWrap: "wrap",
    // padding: grid,
    // overflow: 'auto',
  });

  const onDragEnd = (result) => {
    if (product.marketProduct) {
      return;
    }
    const productImageState = {...productImage};
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    productImageState.addedImages = reorderImages (
        productImageState.addedImages,
        result.source.index,
        result.destination.index
    );
    setProductImage(productImageState);
    setEditingProductImages(productImageState);
  };

  const reorderImages = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  /////////////////////////////////////////////////////////////////////////////

  const addProductImage = () => {
    const productImageState = {...productImage};
    if (!productImageState.currentImagePath) {
      message.error(intl.get("common.inputField", {name: intl.get("products.imageLink")}));
      return;
    }
    if (productImageState.currentImagePath.includes("base64")) {
      message.error(intl.get("products.base64.error"));
      setProductImage((prev) => {
        return { ...prev,
          currentImagePath: "" };
      });
      return;
    }
    let maxId = productImageState.maxId + 1;
    productImageState.addedImages.push({
      id: "" + maxId,
      image: productImageState.currentImagePath,
    });
    productImageState.maxId = maxId;
    productImageState.currentImagePath = "";
    setProductImage(productImageState);
    setEditingProductImages(productImageState);
  };

  const deleteProductImage = (index) => {
    const productImageState = {...productImage};
    productImageState.addedImages.splice(index, 1);
    setProductImage(productImageState);
    setEditingProductImages(productImageState);
  };

  const onProductImageChanged = (fieldName, value) => {
    const productImageState = {...productImage};
    productImageState[fieldName] = value;
    setProductImage(productImageState);
  };

  // 옵션이미지 선택 시
  const okFileModal = (fileType, filePathList) => {
    if (filePathList.length === 0) {
      message.error(intl.get("common.message.selectFile"));
      return;
    }
    const productImageState = { ...productImage };
    let maxId = productImageState.maxId + 1;
    productImageState.addedImages.push({
      id: "" + maxId,
      image: filePathList[0]
    });
    productImageState.maxId = maxId;
    setProductImage(productImageState);
    setEditingProductImages(productImageState);

    setVisibleFileModal(false);
  };

  const setEditingProductImages = (productImageState) => {
    product.descImages = productImageState.addedImages.map(item => item.image);
    props.setProduct(product);
  };

  const disabled = product?.marketProduct ? true : false;
  return (
      <>
        <Descriptions bordered size={"small"} column={1}>
          {/*<Descriptions.Item label={intl.get("products.desc.mode")}>*/}
          {/*  <Radio.Group disabled={disabled} value={product.descMode} onChange={onDescModeChanged}>*/}
          {/*    <Radio value={'image'}>{intl.get("products.desc.mode.images")}</Radio>*/}
          {/*    <Radio disabled value={'html'}>{intl.get("products.desc.mode.html")}</Radio>*/}
          {/*  </Radio.Group>*/}
          {/*</Descriptions.Item>*/}
          <Descriptions.Item label={Util.getRequiredLabel(intl.get("products.edit.desc") + intl.get("products.desc.mode.images"))}>
            {product.descMode === 'image' && (
                <>
                  <div>
                    <div style={{marginBottom: 5}}>
                      <div>
                        <Radio.Group disabled={disabled} value={productImage.addingMode} onChange={(e) => onProductImageChanged('addingMode', e.target.value)}>
                          <Radio value={"upload"}>{intl.get("products.edit.selectImage")}</Radio>
                          <Radio value={"link"}>{intl.get("products.edit.inputImage")}</Radio>
                        </Radio.Group>
                      </div>
                      {productImage.addingMode === "link" && (
                          <div style={{display: "flex"}}>
                            <Input value={productImage.currentImagePath}
                                   onChange={(e) => onProductImageChanged('currentImagePath', e.target.value)}
                                   onPressEnter={addProductImage}
                                   style={{width: 500, marginRight: 10}}/>
                            <div>
                              <Button onClick={addProductImage}>{intl.get("common.button.add")}</Button>
                            </div>
                          </div>
                      )}
                      {productImage.addingMode === "upload" && (
                          <div><Button disabled={disabled} onClick={() => setVisibleFileModal(true)}>{intl.get("products.button.selectFileOrUpload")}</Button></div>
                      )}
                    </div>
                  </div>
                  <div>
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="droppable" direction="horizontal">
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                style={getDragListStyle(snapshot.isDraggingOver)}
                                {...provided.droppableProps}
                            >
                              {productImage.addedImages.map((item, index) => (
                                  <Draggable key={item.id} draggableId={item.id} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getDragItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                        >
                                          <div style={{marginRight: 10, width: 128, position: "relative"}}>
                                            <Popover key={index} content={item.image} title={""}>
                                              <img src={item.image} style={{width: 128, height:128}}/>
                                            </Popover>
                                            <div style={{position: "absolute", bottom: 0, left: "50%", transform: "translate(-50%, -50%)"}}>
                                              <Button disabled={disabled} size={"small"} type="default" onClick={() => deleteProductImage(index)}>{intl.get("common.button.delete")}</Button>
                                            </div>
                                          </div>
                                        </div>
                                    )}
                                  </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                </>
            )}
          </Descriptions.Item>
        </Descriptions>
        {visibleFileModal && (
            <FileListPopup id={"descImage"}
                           fileKind={"product"}
                           visible={true}
                           multipleFile={false}
                           canSelect={true}
                           handleCancel={() => setVisibleFileModal(false)}
                           handleOk={okFileModal}
            />
        )}
      </>
  )
}

export default ProductDescEdit;