import React from "react";

const TextSlot = (props) => {
  const {id, settingValues} = props;

  let padding = "";
  try {
    padding = settingValues.padding.split(",").join("px ");
  } catch (err) {
    padding = "10";
  }
  padding += "px";

  const fontWeight = settingValues.fontBold ? 'bold' : 'normal';
  return (
      <div style={{marginBottom: 0, backgroundColor: settingValues.backgroundColor}}>
        <div style={{padding: padding, textAlign: settingValues.textAlign ? settingValues.textAlign : "left"}}>
          <span style={{fontWeight: fontWeight, color: settingValues.color, fontSize: settingValues.fontSize + "px"}}>{settingValues.text ? settingValues.text : "Text"}</span>
        </div>
      </div>
  )
}

export default TextSlot;