import React, {useEffect, useState} from 'react';

import OrderSummary from "./OrderSummary";
import OrderDailySummary from "./OrderDailySummary";
import {useLayoutState} from "../../components/layout/AppLayoutContext";
import {Alert} from "antd";

const Dashboard = (props) => {
  const layoutState = useLayoutState();
  const [application, setApplication] = useState({});

  useEffect(() => {
    if (layoutState.application) {
      setApplication(layoutState.application);
    }
  }, [layoutState.application]);

  if (!application || !application.id) {
    return (
      <div className="page-content">
        <div style={{backgroundColor: "#EDF0F4"}}>
          <Alert
            message="등록된 미니프로그램이 없습니다."
            description={(
              <>
                <div>회사관리에서 미니프로그램을 추가하거나</div>
                <div>회사 관리자에게 미니프로그램 추가 요청을 하세요.</div>
              </>
            )}
            type="warning"
            showIcon
          />
        </div>
      </div>
    )
  }
  return (
    <div>
      <div className="page-content">
        <div style={{backgroundColor: "#EDF0F4"}}>
          <div>
            <OrderSummary application={application}/>
          </div>
          <div style={{display: "flex", marginTop: "5px"}}>
            <div style={{width: "100%"}}>
              <OrderDailySummary/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Dashboard;