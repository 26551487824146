import axios from "axios";
import {adminConfig} from "../../../config/admin.config";

const API_BASE_URL = adminConfig.apiServer();

export default class ClearanceApi {
  static getPaymentDeclares = (orderId) => {
    const apiPath = `${API_BASE_URL}/payment-declares?orderId=${orderId}&projection=webAdmin`;
    return axios.get(apiPath);
  };

  static deletePaymentDeclare = (id) => {
    const apiPath = `${API_BASE_URL}/payment-declares/${id}?projection=webAdmin`;
    return axios.delete(apiPath);
  }

  static sendPaymentDeclare = (orderId) => {
    const apiPath = `${API_BASE_URL}/orders/${orderId}/declare-payment?projection=webAdmin`;
    return axios.put(apiPath);
  }

  static getOrderDeclares = (orderId) => {
    const apiPath = `${API_BASE_URL}/order-declares?orderId=${orderId}&projection=webAdmin`;
    return axios.get(apiPath);
  };

  static getOrdersDeclares = (orderIds) => {
    let apiPath = `${API_BASE_URL}/order-declares?projection=webAdmin&`;
    const paramKeyValues = orderIds.map(oid => {
      return "oid=" + oid;
    });
    apiPath += paramKeyValues.join("&");
    return axios.get(apiPath);
  };

  static deleteOrderDeclare = (id) => {
    const apiPath = `${API_BASE_URL}/order-declares/${id}?projection=webAdmin`;
    return axios.delete(apiPath);
  }

  static createOrderDeclare = (orderId, params) => {
    const apiPath = `${API_BASE_URL}/orders/${orderId}/declare?projection=webAdmin`;
    return axios.post(apiPath, params);
  }

  static getCurrentOrderDeclare = (orderId) => {
    const apiPath = `${API_BASE_URL}/orders/${orderId}/declare?projection=webAdmin`;
    return axios.get(apiPath);
  }

  static sendOrderDeclareToAgent = (orderId) => {
    const apiPath = `${API_BASE_URL}/orders/${orderId}/send-to-agent?projection=webAdmin`;
    return axios.put(apiPath);
  }

  static getDeclareCompanyInterfaces = (orderId) => {
    const apiPath = `${API_BASE_URL}/declare-company-interfaces?orderId=${orderId}&projection=webAdmin`;
    return axios.get(apiPath);
  };

  static deleteDeclareCompanyInterface = (id) => {
    const apiPath = `${API_BASE_URL}/declare-company-interfaces/${id}?projection=webAdmin`;
    return axios.delete(apiPath);
  }

  static sendOrderToDeclareCompany = (orderId) => {
    const apiPath = `${API_BASE_URL}/orders/${orderId}/send-declare-company?projection=webAdmin`;
    return axios.put(apiPath);
  }

  static testReceiveFromDeclareCompany = (orderId, notifyType, data) => {
    const bodyFormData = new FormData();
    bodyFormData.append('notify_type', notifyType);
    bodyFormData.append('data', data);
    const apiPath = `${API_BASE_URL}/declare-company-interfaces/callback`;
    return axios.post(apiPath, bodyFormData);
  };

  static saveOrderDelivery = (orderId, orderDelivery) => {
    const apiPath = `${API_BASE_URL}/orders/${orderId}/delivery?projection=webAdmin`;
    return axios.put(apiPath, orderDelivery);
  }

  static sendToLocalLogistics = (orderId) => {
    const apiPath = `${API_BASE_URL}/orders/${orderId}/local-logistics?projection=webAdmin`;
    return axios.put(apiPath);
  }

  static getLocalLogisticsInterfaces = (orderId) => {
    const apiPath = `${API_BASE_URL}/local-logistics-interfaces?orderId=${orderId}&projection=webAdmin`;
    return axios.get(apiPath);
  };

  static deleteLocalLogisticsInterface = (id) => {
    const apiPath = `${API_BASE_URL}/local-logistics-interfaces/${id}?projection=webAdmin`;
    return axios.delete(apiPath);
  }
}