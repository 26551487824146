import React, {useState} from "react";
import ProductEditor from "./ProductEditor";

const ProductEditExisted = (props) => {
  return (
    <ProductEditor id={props.match.params.productId}
                   editMode={"modify"}
                   {...props}
    />
  );
};

export default ProductEditExisted;