import React, {useRef, useEffect, useState} from "react";
import ApplicationPreviewFrame from "../../application/ApplicationPreviewFrame";
import ApplicationApi from "../../application/ApplicationApi";
import NoImage from "../../../asset/images/no_image.png";
import Util from "../../../util/Util";
import CommonApi from "../../common/CommonApi";
import {Carousel} from "antd";
import ProductDetailInfoView from "./ProductDetailInfoView";
import "./ProductPreview.less";
import ComponentPreview from "../etc_preview/ComponentPreview";

const ProductDetailPreview = (props) => {
  const [appDesign, setAppDesign] = useState({});
  const viewEndRef = useRef(null);

  useEffect(() => {
    if (props.application.id) {
      loadApplicationDesign();
    }
  }, [props.application.id]);

  const scrollToBottom = () => {
    if (props.goBottom) {
      viewEndRef.current.scrollIntoView({behavior: "smooth"});
      if (props.itemScrollFinished) {
        props.itemScrollFinished(true);
      }
    }
  }

  useEffect(scrollToBottom, [props.goBottom]);

  const loadApplicationDesign = () => {
    ApplicationApi.getApplicationDesign().then(res => {
      setAppDesign(res.data);
    });
  }

  const getPageItemComponent = (pageItem) => {
    if (pageItem.type === "banner") {
      return (<ProductDetailBannerView appDesign={appDesign} pageItem={pageItem} {...props}/>);
    } else if (pageItem.type === "price") {
      return (<ProductDetailPriceView appDesign={appDesign} pageItem={pageItem} {...props}/>);
    } else if (pageItem.type === "info") {
      return (<ProductDetailInfoView appDesign={appDesign} pageItem={pageItem} {...props}/>);
    } else if (pageItem.type === "desc") {
      return (<ProductDetailDescView appDesign={appDesign} pageItem={pageItem} {...props}/>);
    } else if (pageItem.type === "contents") {
      return (<ProductDetailContentsView appDesign={appDesign} pageItem={pageItem} {...props}/>);
    } else if (pageItem.type === "component") {
      if (pageItem.componentId) {
        return (<ComponentPreview
          pageItems={pageItem.settingValues}
          application={props.application}
          applicationDesign={appDesign}
        />)
      } else {
        return (
          <div style={{height: 200, display: "flex", alignItems: "center", justifyContent: "center"}}>
            <div style={{textAlign: "center"}}>표시할 컨포넌트를 선택하세요.</div>
          </div>
        )
      }
    }
  };
  return (
      <div>
        <ApplicationPreviewFrame applicationDesign={appDesign} title={props.product.name}>
          <div className="product-detail-box">
            {props.pageItems.map((pageItem, index) => {
              const pageItemComponent = getPageItemComponent(pageItem);
              return (
                <div key={index} onClick={() => props.onPageItemClick(index)}
                     className={pageItem.selected ? "selected-page-item" : ""}>
                  {pageItemComponent}
                </div>
              );
            })}
            <div ref={viewEndRef}/>
          </div>
        </ApplicationPreviewFrame>
      </div>
  )
};

const ProductDetailBannerView = (props) => {
  const {product} = props;
  const productImages = product.images ? product.images : [NoImage];
  return (
    <Carousel autoplay={true}>
      { productImages.map((image, index) => (
              <div key={index}>
                <img style={{width:373, height: 275}} src={Util.encodeImagePath(image)}/>
              </div>
          )
      )}
    </Carousel>
  )
};

const ProductDetailPriceView = (props) => {
  const p1Size = '10px';
  const p2Size = '14px';
  const p3Size = '16px';
  const p4Size = '10px';

  const [listPriceUser, setListPriceUser] = useState(0);
  const [salesPriceUser, setSalesPriceUser] = useState(0);
  const [exchangeRate, setExchangeRate] = useState(1);

  useEffect(() => {
    if (props.application.id) {
      loadExchangeRate();
    }
  }, [props.application.id]);

  useEffect(() => {
    if (props.product.listPrice) {
      setListPriceUser(Util.toFixed2(props.product.listPrice * exchangeRate));
    }
    if (props.product.salesPrice) {
      setSalesPriceUser(Util.toFixed2(props.product.salesPrice * exchangeRate));
    }
  }, [exchangeRate, props.product]);

  const loadExchangeRate = () => {
    const params = {
      baseCurrency: props.application.productCurrency,
      exchangeCurrency: props.application.userPayCurrency,
    }
    CommonApi.getExchangeRate(params).then(res => {
      setExchangeRate(res.data.rate);
    });
  }

  const {product, application, appDesign} = props;

  const mainCurrency = appDesign.priceDisplaySetting.mainCurrency === "product" ? application.productCurrency : application.userPayCurrency;
  const secondaryCurrency = appDesign.priceDisplaySetting.secondaryCurrency === "product" ? application.productCurrency : application.userPayCurrency;

  const listPriceMain = appDesign.priceDisplaySetting.mainCurrency === "product" ? product.listPrice : listPriceUser;
  const listPriceSecondary = appDesign.priceDisplaySetting.secondaryCurrency === "product" ? product.listPrice : listPriceUser;
  const salesPriceMain = appDesign.priceDisplaySetting.mainCurrency === "product" ? product.salesPrice : salesPriceUser;
  const salesPriceSecondary = appDesign.priceDisplaySetting.secondaryCurrency === "product" ? product.salesPrice : salesPriceUser;

  return (
    <div className="detail-info-box">
      <div className="price-box" style={{width: props.width}}>
        <div className="price-1" style={{fontSize: p1Size}}>
          {Util.currencyFormat(listPriceMain, mainCurrency)}
        </div>
        <div style={{display: "flex", alignItems: "flex-end"}}>
          <div style={{display: "flex", alignItems: "flex-end", letterSpacing: -1}}>
            <div className="price-2" style={{fontSize: p2Size}}>{Util.getSymbol(mainCurrency)}</div>
            <div className="price-3" style={{fontSize: p3Size}}>{Util.numberFormat(salesPriceMain)}</div>
          </div>
          <div className="price-4" style={{fontSize: p4Size, letterSpacing: -1}}>{Util.currencyFormat(salesPriceSecondary, secondaryCurrency)}</div>
        </div>
      </div>
      <div className="product-name u-line-2">
        {product.name}
      </div>
    </div>
  )
};

const ProductDetailDescView = (props) => {
  const {product} = props;
  const descImages = product.descImages ? product.descImages : [NoImage];
  return (
    <div className="detail-img-box u-m-b-16">
      {descImages.map((image, index) => (
          <img key={index} src={image} style={{width: 373, height: 273}}/>
      ))}
    </div>
  )
}

const ProductDetailContentsView = (props) => {
  let htmlText = props.pageItem.settingValues.htmlText;
  htmlText = htmlText ? htmlText.replaceAll("<img", "<img style='width:350px;' ") : "";
  return (
      <div className="html-viewer" style={{padding:10}}>
        <div dangerouslySetInnerHTML={{ __html: htmlText }}></div>
        {/*<Viewer ref={ref}*/}
        {/*        usageStatistics={false}*/}
        {/*        initialValue={html} />*/}
      </div>
  )
}

export default ProductDetailPreview;