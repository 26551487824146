import React, {useEffect, useState} from 'react';
import NoImage from "../../asset/images/no_image.png";
import {Table, Button, Input, Space, Spin} from 'antd';
import intl from "react-intl-universal";

const UserList = (props) => {
  const [filter, setFilter] = useState({});

  useEffect(() => {
    setFilter(filter);
  }, [props.filter]);

  const searchUsers = () => {
    const {pagination} = props;
    pagination.current = 1
    props.searchUsers(pagination);
  }

  const userTableChanged = (pagination) => {
    props.searchUsers(pagination);
  }

  const onChangeName = (e) => {
    const {filter} = props;
    filter.name = e.target.value;
    props.setFilter(filter);
  };

  const onChangeUserId = (e) => {
    const {filter} = props;
    filter.id = e.target.value;
    props.setFilter(filter);
  };

  const columns = [{
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  }, {
    title: intl.get("user.name"),
    dataIndex: 'name',
    key: 'name',
  }, {
    title: intl.get("user.avatarUrl"),
    dataIndex: 'avatarUrl',
    key: 'avatarUrl',
    render: (text, record) => (
      <span><img src={text ? text : NoImage} style={{width: "48px", height: "48px"}}/></span>
    )
  }, {
    title: intl.get("user.loginId"),
    dataIndex: 'loginId',
    key: 'loginId',
  }, {
    title: intl.get("user.gender"),
    dataIndex: 'gender',
    key: 'gender',
  }, {
    title: intl.get("user.city"),
    dataIndex: 'city',
    key: 'city',
    render: (text, record) => (
      <span>{record.city} {record.province}</span>
    )
  }, {
    title: "Role",
    dataIndex: 'role',
    key: 'role',
    render: (text, record) => (
      <span>{text === "manager" ? "앱관리자" : ""}</span>
    )
  }, {
    title: intl.get("common.createdAt"),
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text, record) => (
      <span>{text.substring(0, 10)}</span>
    )
  }];

  const {pagination} = props;
  return (
    <Spin spinning={props.loading}>
      <div className="page-op-box">
        <div className="filter-box">
          <Space>
            <Input value={filter.id} onChange={onChangeUserId} style={{ width: 150, marginRight: 10 }} placeholder="ID"/>
            <Input value={filter.name} onChange={onChangeName} style={{ width: 150, marginRight: 10 }} placeholder={intl.get("user.name")}/>
          </Space>
          <div style={{marginLeft: "auto"}}>
            <div style={{width: 100}}>
              <Button style={{width: 100}} type="primary" block size="default" onClick={searchUsers}>{intl.get("common.button.search")}</Button>
            </div>
          </div>
        </div>
      </div>
      <div style={{marginTop: "10px", cursor: "pointer"}}>
        <Table size={'small'}
               dataSource={props.users}
               columns={columns}
               rowKey={'id'}
               title={() => intl.get("common.label.listTotal", {total: pagination.total})}
               pagination={pagination}
               onChange={userTableChanged}
               onRow={(record, rowIndex) => {
                 return {
                   onClick: event => props.onClickUser(record),
                 };
               }}
        />
      </div>
    </Spin>
  )
};

export default UserList;