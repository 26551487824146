export const themeColorLabel = {
  primary200: "강조색(버튼배경 등)",
  primary000: "강조색 배경 위의 텍스트",
  primary100: "선택된 항목표시",

  price: "가격숫자색",
  cart: "카트아이콘",
  empty1: "",

  titleBarBg: "타이틀바 배경",
  titleBarText: "타이틀바 글",
  empty2: "",

  mypageBg: "마이페이지 배경",
  mypageText: "마이페이지 글",
  empty3: "",
}

export const themeColorSet = {
  theme1: {
    primary000: "#F2F2F2",
    primary100: "#EDEDED",
    primary200: "#000000",

    price: "#FF4D00",
    cart: "#000000",

    titleBarBg: "#FFFFFF",
    titleBarText: "#000000",
    mypageBg:  "#FFFFFF",
    mypageText:  "#000000",
  },

  theme2: {
    primary000: "#383838",
    primary100: "#FFF3D5",
    primary200: "#FFB422",

    price: "#FF6321",
    cart: "#FF6321",

    titleBarBg: "#FFFFFF",
    titleBarText: "#000000",
    mypageBg:  "#FFF3D5",
    mypageText:  "#000000",
  },

  theme3: {
    primary000: "#FFF3F5",
    primary100: "#FFD8DD",
    primary200: "#FC7183",

    price: "#F24147",
    cart: "#FC7183",

    titleBarBg: "#FFFFFF",
    titleBarText: "#000000",
    mypageBg:  "#FFF3F5",
    mypageText:  "#000000",
  },

  theme4: {
    primary000: "#F3F7FF",
    primary100: "#CFD5FF",
    primary200: "#4F66FF",

    price: "#D03126",
    cart: "#4F66FF",

    titleBarBg: "#FFFFFF",
    titleBarText: "#000000",
    mypageBg:  "#FFFFFF",
    mypageText:  "#000000",
  },

  custom: {
    primary000: "#F2F2F2",
    primary100: "#EDEDED",
    primary200: "#000000",

    price: "#FF4D00",
    cart: "#000000",

    titleBarBg: "#000000",
    titleBarText: "#FFFFFF",
    mypageBg:  "#000000",
    mypageText:  "#FFFFFF",
  }
};

export const defaultAppDesign = {
  themeSetting: {
    navigationBar: {
      type: "text",
      fontSize: "12",
      fontWeight: "500",
      titleAlign: "left",
      imageWidth: "200",
    },
    themeColor: {
      name: "theme1",
      colorTable: {...themeColorSet.theme1},
      customColorTable: {...themeColorSet.custom},
    },
    priceDisplaySetting: {
      mainCurrency: "product",
      subCurrency: "CNY",
      showAbout: false,
      usdFormat: "symbol",
      showDiscount: "ratio"
    },
  }
}