import React, {useState} from 'react';

import {
  Button,
  message,
  Spin
} from 'antd';

import BatchJobApi from "./BatchJobApi";
import InterlogisProductModal from "../product/InterlogisProductModal";
import InterlogisStockModal from "../product/InterlogisStockModal";

const BatchJob = (props) => {
  const [loading, setLoading] = useState(false);
  const [visibleInterlogisProductModal, setVisibleInterlogisProductModal] = useState(false);
  const [visibleInterlogisSotckModal, setVisibleInterlogisStockModal] = useState(false);

  const runProductStatistics = () => {
    setLoading(true);
    BatchJobApi.updateProductStatistics().then(res => {
      message.info("정상 처리 완료");
    }).catch(err => {
      console.log("Error: ", err);
      message.error("에러 발생")
    }).finally(() => {
      setLoading(false);
    })
  };

  return (
    <Spin spinning={loading}>
      <div>
        <Button type="primary" style={{width: 200, height: 50, fontSize: 20}} onClick={runProductStatistics}>상품통계</Button>
      </div>
      <div style={{marginTop: 20}}>
        <Button type="primary" style={{width: 200, height: 50, fontSize: 20}} onClick={() => setVisibleInterlogisProductModal(true)}>인터로지스 상품 정보</Button>
      </div>
      <div style={{marginTop: 20}}>
        <Button type="primary" style={{width: 200, height: 50, fontSize: 20}} onClick={() => setVisibleInterlogisStockModal(true)}>인터로지스 재고 정보</Button>
      </div>

      {visibleInterlogisProductModal && (
        <InterlogisProductModal handleCancel={() => setVisibleInterlogisProductModal(false)}/>
      )}

      {visibleInterlogisSotckModal && (
        <InterlogisStockModal handleCancel={() => setVisibleInterlogisStockModal(false)}/>
      )}
    </Spin>
  )
};

export default BatchJob;