import React, {useState, useEffect} from "react";
import {Button, Input, Modal, Table, message, Divider, Descriptions, Layout, Spin} from "antd";
import intl from "react-intl-universal";
import OrderApi from "../OrderApi";
import Util from "../../../util/Util";

import {SettingOutlined} from "@ant-design/icons";
import Clearance1210 from "./Clearance1210";
import Clearance9610 from "./Clearance9610";
import ClearanceMail from "./ClearanceMail";
import ClearanceNone from "./ClearanceNone";
import "./Clearance.less";

const {Content} = Layout;

const ClearancePopup = (props) => {
  const [order, setOrder] = useState({
    orderSkus: [],
    shippingAddress: {},
  });

  useEffect(() => {
    //주문의 orderDeclare 정보가 없으면 생성한다.
    //createOrderDeclare는 없으면 생성하면 있으면 처리하지 않는다.
    OrderApi.getOrder(props.orderId).then(res  => {
      const order = res.data;
      setOrder(order);
    });
  }, [props.orderId]);

  const handleOk = () => {
    if (props.handleOk) {
      props.handleOk();
    }
  }

  const loadOrderAndSet = () => {
    OrderApi.getOrder(props.orderId).then(res  => {
      const order = res.data;
      setOrder(order);
      props.setOrder(order);
    });
  };

  const callTest179 = () => {
    if(!window.confirm("해관으로 179 테스트 데이터를 보내는 기능입니다. 계속하시겠습니까?")) {
      return;
    }
    var unixTime = Math.round(+new Date()/1000);
    const messageData = {
      orderNo: order.declareOrderNo,
      sessionID: Util.uuidv4(),
      serviceTime: unixTime,
    };

    const param = `openReq=${encodeURIComponent(JSON.stringify(messageData))}`;
    OrderApi.callTest179(param).then(res => {
      message.info("전송 요청했습니다.");
    }).catch(err => {
      console.log("callTest179 error:", err);
      message.error("문제가 발생했습니다.");
    });
  };


  const modalTitle = (order.cbtType === "1210" || order.cbtType === "9610") ? intl.get("order.button.deliveryAndClearance") : intl.get("order.button.delivery");

  return (
    <Modal
      title={[<div key={1}><SettingOutlined/> {modalTitle}</div>]}
      visible={true}
      cancelButtonProps={{ style: { display: 'none' } }}
      onOk={handleOk}
      onCancel={props.handleCancel}
      width={"80%"}
      bodyStyle={{margin: 0, padding: 0}}>
      <Layout>
        <Content className="modal-content">
          {Util.isAdmin() && (order.cbtType === "1210" || order.cbtType === "9610") && (
            <div style={{width: "100%", display: "flex"}}>
              <div style={{marginLeft: "auto", marginRight: 30}}>
                <Button size="small" onClick={callTest179}>해관179테스트</Button>
              </div>
            </div>
          )}
          <div className={"clearance"}>
            {order.cbtType === "1210" && (<Clearance1210 order={order} application={props.application} loadOrder={loadOrderAndSet}/>)}
            {order.cbtType === "9610" && (<Clearance9610 order={order} application={props.application} loadOrder={loadOrderAndSet}/>)}
            {order.cbtType === "mail" && (<ClearanceMail order={order} application={props.application} loadOrder={loadOrderAndSet}/>)}
            {order.cbtType === "none" && (<ClearanceNone order={order} application={props.application} loadOrder={loadOrderAndSet}/>)}
          </div>
        </Content>
      </Layout>
    </Modal>
  )
}

export default ClearancePopup;