import React, {useEffect, useState} from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import './App.less';
import AppLayout from "./components/layout/AppLayout";
import Login from "./components/login/Login";
import FindPassword from "./components/login/FindPassword";
import ResetPassword from "./components/login/ResetPassword";
import RegisterUser from "./components/login/RegisterUser";
import AuthRoute from "./components/router/AuthRoute";
import {Modal, ConfigProvider} from "antd";
import Cookies from "universal-cookie";
import {
  EventBroadcaster,
  SHOW_ERROR_MESSAGE_EVENT_TOPIC,
  CHANGE_LANGUAGE_EVENT_TOPIC,
} from "./event/event.broadcaster";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {LayoutProvider, useLayoutDispatch} from "./components/layout/AppLayoutContext";

import zh_CN from 'antd/lib/locale/zh_CN';
import ko_KR from 'antd/lib/locale/ko_KR';

import messageKoKR from './locales/ko-KR';
import messageZhCN from "./locales/zh-CN";

import dayjs from 'dayjs';
import intl from 'react-intl-universal';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/ko';
import SystemSettingApi from "./pages/system_setting/SystemSettingApi";
import {SettingContext} from "./auth/setting.context";
import CommonApi from "./pages/common/CommonApi";

dayjs.locale('ko');

const localeMessages = {
  "zh-CN": messageZhCN,
  "ko-KR": messageKoKR,
};

const cookies = new Cookies();

// init default locale
intl.init({
  currentLocale: "ko-KR",
  locales: localeMessages,
});

const App = () => {
  const [antLocale, setAntLocale] = useState(ko_KR);
  const [versionCheckTimer, setVersionCheckTimer] = useState(null);
  const [needUpdate, setNeedUpdate] = useState(false);
  const [serviceSetting, setServiceSetting] = useState({loaded: false});

  useEffect(() => {
    EventBroadcaster.on(SHOW_ERROR_MESSAGE_EVENT_TOPIC, (msg) => {
      Modal.confirm({
        icon: <ExclamationCircleOutlined/>,
        content: msg,
        okText: intl.get("common.button.ok"),
        cancelText: null,
        cancelButtonProps: {style: {display: 'none'}},
      });
    });

    EventBroadcaster.on(CHANGE_LANGUAGE_EVENT_TOPIC, (data) => {
      changeLocale(data, true);
    });
  },[]);

  useEffect(() => {
    let locale = cookies.get('locale');
    if (locale !== "zh-CN" && locale !== "ko-KR") {
      const lang = navigator.language || navigator.userLanguage;
      // en-US, zh-CN, zh, ko
      if (lang.startsWith("zh")) {
        locale = "zh-CN";
      } else {
        locale = "ko-KR";
      }
    }
    changeLocale(locale, false);
    checkVersion();

    // clear timer
    return () => {
      if (versionCheckTimer != null) {
        clearInterval(versionCheckTimer);
      }
    }
  }, []);

  useEffect(() => {
    CommonApi.getSystemSetting(window.location.host).then(res => {
      const setting = res.data;
      if (!setting.company.id) {
        setting.company = {
          id: 0,
          name: intl.get("common.label.youma"),
          serviceName: "YOUMA",
          footer: "",
        };
      }
      setting.loaded = true;
      SettingContext.setting = setting;
      document.title = setting.company?.serviceName;
      setServiceSetting(setting);
      SettingContext.setting = setting;
    }).catch(err => {
    });
  }, []);

  const changeLocale = (locale, reloadPage=false) => {
    // const url = urlParse(adminConfig.adminWebServer());
    cookies.set("locale", locale, {path: '/'});
    if (locale === "zh-CN") {
      dayjs.locale('zh-cn');
      setAntLocale(zh_CN);
    } else {
      dayjs.locale('ko');
      setAntLocale(ko_KR);
    }
    intl.init({
      currentLocale: locale,
      locales: localeMessages,
    });

    if (reloadPage) {
      window.location = "/";
      window.location.reload();
    }
  };

  const checkVersion = () => {
    let version = "";
    const timer = setInterval(() => {
      SystemSettingApi.getPublicSystemSettings().then(res => {
        const versionSc = res.data.find(sc => sc.name === "admin.version");
        if (versionSc) {
          if (version !== "" && version !== versionSc.value) {
            setNeedUpdate(true);
          }
          version = versionSc.value;
        }
      });
    }, 60 * 1000);
    setVersionCheckTimer(timer);
  };

  return (<>
    <ConfigProvider locale={antLocale}>
    <LayoutProvider>
        <BrowserRouter>
          <Switch>
            <Route path={"/register"} render={(props) => <RegisterUser {...props} serviceSetting={serviceSetting}/>}/>
            <Route path={"/find-password"} render={(props) => <FindPassword {...props} serviceSetting={serviceSetting}/>}/>
            <Route path={"/reset-password"} render={(props) => <ResetPassword {...props} serviceSetting={serviceSetting}/>}/>
            <Route path={"/login"} render={(props) => <Login {...props} serviceSetting={serviceSetting}/>}/>
            <AuthRoute path={'/'} component={AppLayout} serviceSetting={serviceSetting} needUpdate={needUpdate} />} />
          </Switch>
        </BrowserRouter>
      </LayoutProvider>
    </ConfigProvider>
  </>)
};

export default App;
