import React, {useEffect, useState} from "react";
import {
  Button, Checkbox, Divider, Input, Select
} from "antd";
import intl from "react-intl-universal";
import Util from "../../util/Util";

const ProductFilter = (props) => {
  const [selectedCategory, setSelectedCategory] = useState({});

  useEffect(() => {
    if (props.filter.categoryId) {
      const found = props.categories.find(c => c.id === props.filter.categoryId);
      setSelectedCategory(found);
    }
  }, [props.filter]);

  const idTypeName = (idType) => {
    if (idType === "id") {
      return intl.get("products.id");
    } else if (idType === "barcode" || idType === "barCode") {
      return intl.get("products.sku.barCode");
    } else if (idType === "hsCode") {
      return intl.get("products.sku.hsCode");
    } else if (idType === "skuCode") {
      return intl.get("products.sku.skuCode");
    } else {
      return intl.get("products.id");
    }
  };

  const onFilterChanged = (field, value, runSearch=false) => {
    const {filter} = props;
    filter[field] = value;
    props.setFilter(filter);
    if (runSearch) {
      props.handleSearch(filter);
    }
  };

  const onCategorySelect = (value) => {
    const {filter, categories} = props;

    let selectedCategory = categories.find(c => c.id === value);
    if (selectedCategory) {
      setSelectedCategory(selectedCategory);
      filter.categoryId = value;
      filter["subCategoryId"] = undefined;
      props.setFilter(filter);
    }
    props.handleSearch(filter);
  };

  const handleSearch = () => {
    props.handleSearch(props.filter);
  }

  const subCategories = selectedCategory?.id ? selectedCategory.subCategories : [];
  const {filter} = props;

  return (
    <div className="filter-box">
      <div>
        <Input style={{ width: 200 }} value={filter.name}
               onChange={e => onFilterChanged('name', e.target.value)}
               placeholder={intl.get("products.name")}
               onPressEnter={handleSearch}/>
        <Divider type="vertical" />
        <Select allowClear={true}
                value={filter.idType ? filter.idType : undefined}
                placeholder={intl.get("products.id.kind")}
                onClear={() => {onFilterChanged('idType', ''); onFilterChanged('idTarget', '')}}
                onSelect={(value) => onFilterChanged('idType', value)} style={{width: 150}}>
          <Select.Option value={"id"}>{intl.get("products.id")}</Select.Option>
          <Select.Option value={"barcode"}>{intl.get("products.sku.barCode")}</Select.Option>
          <Select.Option value={"hsCode"}>{intl.get("products.sku.hsCode")}</Select.Option>
          <Select.Option value={"skuCode"}>{intl.get("products.sku.skuCode")}</Select.Option>
        </Select>
        <Divider type="vertical" />
        <Input style={{ width: 200 }} value={filter.idTarget} onChange={e => onFilterChanged('idTarget', e.target.value)}
               placeholder={idTypeName(filter.idType) + intl.get("products.ignore.others")} onPressEnter={handleSearch}/>

        {!props.openToMarket && (
          <>
            <Divider type="vertical" />
            <Select value={filter.published ? filter.published : undefined}
                    allowClear={true}
                    placeholder={intl.get("products.published")}
                    onClear={() => onFilterChanged('published', '')}
                    onSelect={(value) => onFilterChanged('published', value)}
                    style={{width: 100}}>
              <Select.Option key={"true"} value={"true"}>{intl.get("products.published.true")}</Select.Option>
              <Select.Option key={"false"} value={"false"}>{intl.get("products.published.false")}</Select.Option>
            </Select>
          </>
        )}

        <div style={{marginTop: 10}}>
          <Select allowClear={true}
                  value={filter.brandId ? filter.brandId : undefined}
                  onSelect={value => onFilterChanged('brandId', value, true)}
                  placeholder={intl.get("products.brand")}
                  onClear={() => onFilterChanged('brandId', '', true)}
                  style={{width: 150}}>
            {
              props.brands.map(eachBrand => {
                return (<Select.Option key={eachBrand.id} value={eachBrand.id}>{eachBrand.name}</Select.Option>)
              })
            }
          </Select>
          <Divider type="vertical" />
          <Select value={filter.categoryId ? filter.categoryId : undefined}
                  placeholder={intl.get("products.category") + "1"}
                  allowClear={true}
                  onClear={() => onFilterChanged('categoryId', '', true)}
                  onSelect={onCategorySelect}
                  style={{width: 150}}>
            {
              props.categories.map(eachCategory => {
                return (<Select.Option key={eachCategory.id} value={eachCategory.id}>{eachCategory.name}</Select.Option>)
              })
            }
          </Select>
          <Divider type="vertical" />
          <Select value={filter.subCategoryId ? filter.subCategoryId : undefined}
                  placeholder={intl.get("products.category") + "2"}
                  allowClear={true}
                  onClear={() => onFilterChanged('subCategoryId', '', true)}
                  onSelect={(value) => onFilterChanged('subCategoryId', value, true)}
                  style={{width: 150}}>
            { subCategories.map(eachCategory => {
              return (<Select.Option key={eachCategory.id} value={eachCategory.id}>{eachCategory.name}</Select.Option>)
            })}
          </Select>
          <Divider type="vertical" />
          <Select value={filter.sort}
                  placeholder={intl.get("products.sort")}
                  onSelect={(value) => onFilterChanged('sort', value, true)}
                  style={{width: 150}}>
            <Select.Option key={"latest"} value={"latest"}>{intl.get("products.sort.latest")}</Select.Option>
            <Select.Option key={"lowest"} value={"lowest"}>{intl.get("products.sort.lowest")}</Select.Option>
            <Select.Option key={"highest"} value={"highest"}>{intl.get("products.sort.highest")}</Select.Option>
            <Select.Option key={"name_cn"} value={"name_cn"}>{intl.get("products.sort.name_cn")}</Select.Option>
            <Select.Option key={"name_kr"} value={"name_kr"}>{intl.get("products.sort.name_kr")}</Select.Option>
            <Select.Option key={"total_sales_desc"} value={"total_sales_desc"}>{intl.get("products.sort.total_sales_desc")}</Select.Option>
          </Select>
        </div>
        <div style={{marginTop: 10}}>
          <Checkbox checked={filter.eventProduct || false}
                    onChange={(e) => onFilterChanged('eventProduct', e.target.checked ? true : "", true)}>
            {intl.get("products.eventProduct")}
          </Checkbox>
          <Divider type={"vertical"}/>
          <Checkbox checked={filter.hideToUser || false}
                    onChange={(e) => onFilterChanged('hideToUser', e.target.checked ? true : "", true)}>
            {intl.get("products.hideToUser")}
          </Checkbox>
          {!props.hideMarketSelection && (
            <>
              <Divider type={"vertical"}/>
              <Checkbox checked={filter.myOpenToMarket || false}
                        onChange={(e) => onFilterChanged('myOpenToMarket', e.target.checked, true)}>
                {intl.get("products.openToMarketOnly")}
              </Checkbox>
            </>
          )}
          {!props.hideMarketSelection && (
            <>
              <Divider type={"vertical"}/>
              <Checkbox checked={filter.marketProduct || false}
                        onChange={(e) => onFilterChanged('marketProduct', e.target.checked, true)}>
                {intl.get("products.source") + " " + intl.get("products.source.productMarket")}
              </Checkbox>
            </>
          )}
        </div>
      </div>
      <div style={{marginLeft: "auto", width: 100}}>
        <Button type="primary" block size="default" onClick={handleSearch}>
          {intl.get("common.button.search")}
        </Button>
      </div>
    </div>
  )
};

export default ProductFilter;