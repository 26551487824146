import React from "react";
import PageDesigner from "../app_page/PageDesigner";

const ShopComponentEdit = (props) => {
  return (
    <PageDesigner
      pageType={"component"}
      loadAppPages={props.loadAppPageComponents}
      {...props}
    />
  )
};

export default ShopComponentEdit;