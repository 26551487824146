import React, {useEffect, useState} from 'react';
import {Button, Modal, Descriptions, Layout} from "antd";
import CompanyUserApi from "./CompanyUserApi";
import intl from "react-intl-universal";
import {UserOutlined} from "@ant-design/icons";

const {Content} = Layout;

const CompanyUserDetailPopup = (props) => {
  const [companyUser, setCompanyUser] = useState({});

  useEffect(() => {
    loadCompanyUser();
  }, [props.companyUserId]);

  const loadCompanyUser = () => {
    if (props.companyUserId === 0) {
      return;
    }
    CompanyUserApi.getCompanyUser(props.companyUserId).then(res => {
      setCompanyUser(res.data);
    });
  };

  return (
    <Modal
      title={[<div key={1}><UserOutlined/> {intl.get("common.label.detail")}</div>]}
      visible={true}
      onCancel={props.onClose}
      footer={null}
      width={800}
      bodyStyle={{margin: 0, padding: 0}}>
      <Layout>
        <Content className="modal-content">
          <Descriptions bordered size={"small"} column={2}>
            <Descriptions.Item label={"ID"} span={2}>
              {companyUser.id}
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("company.user.name")}>
              {companyUser.name}
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("company.user.loginId")} span={2}>
              {companyUser.loginId}
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("company.user.email")} span={2}>
              {companyUser.email}
            </Descriptions.Item>
          </Descriptions>
        </Content>
      </Layout>
    </Modal>
  )
};

export default CompanyUserDetailPopup;