import React, {useState, useEffect} from "react";
import PrettyData from "pretty-data";
import JSONPretty from "react-json-pretty";
import { Descriptions } from 'antd';

const WechatPayView = (props) => {
  const {payment} = props;

  return (
      <div>
        <Descriptions layout="vertical"
                      bordered
                      size={"small"}
                      column={1}>
          <Descriptions.Item label="지불준비요청">
            <div style={{fontSize: "11px", overflow: "scroll", wordBreak: "break-all", wordWrap: "break-word", width: "400px", height: "150px", marginRight: 20}}>
              <pre>{payment.wxPrepayReq ? PrettyData.pd.xml(payment.wxPrepayReq): ""}</pre>
            </div>
          </Descriptions.Item>
          <Descriptions.Item label="지불준비요청 회신">
            <div style={{fontSize: "11px", overflow: "scroll", wordBreak: "break-all", wordWrap: "break-word", width: "400px", height: "150px", marginRight: 20}}>
              <pre>{payment.wxPrepayRes ? PrettyData.pd.xml(payment.wxPrepayRes) : ""}</pre>
            </div>
          </Descriptions.Item>
          <Descriptions.Item label="지불처리완료 회신">
            <div style={{fontSize: "11px", overflow: "scroll", wordBreak: "break-all", wordWrap: "break-word", width: "400px", height: "150px", marginRight: 20}}>
              <pre>{payment.wxCallbackRes ? PrettyData.pd.xml(payment.wxCallbackRes) : ""}</pre>
            </div>
          </Descriptions.Item>
        </Descriptions>
      </div>
  )
}

export default WechatPayView;