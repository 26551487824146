import {adminConfig} from "../../config/admin.config";
import axios from "axios";

const API_BASE_URL = adminConfig.apiServer();

export default class ApplicationApi {
  static getApplications = (options) => {
    let apiPath = `${API_BASE_URL}/applications?projection=webAdmin&`;

    apiPath += Object.keys(options).map(key => {
      return key + "=" + options[key]
    }).join("&")

    return axios.get(apiPath);
  };

  static getApplication = (id) => {
    let apiPath = `${API_BASE_URL}/applications/${id}?projection=webAdmin`;
    return axios.get(apiPath);
  }

  static deleteApplication = (id) => {
    let apiPath = `${API_BASE_URL}/applications/${id}?projection=webAdmin`;
    return axios.delete(apiPath);
  }

  static submitApplication = (params) => {
    if (params.application.id) {
      const apiPath = `${API_BASE_URL}/applications/${params.application.id}?projection=webAdmin`;
      return axios.put(apiPath, params);
    } else {
      const apiPath = `${API_BASE_URL}/applications?projection=webAdmin`;
      return axios.post(apiPath, params);
    }
  };

  static getApplicationKey = (appId, password) => {
    let apiPath = `${API_BASE_URL}/applications/${appId}/key?projection=webAdmin`;
    return axios.post(apiPath, password);
  }

  static getApplicationDesign = () => {
    let apiPath = `${API_BASE_URL}/applications/design?projection=webAdmin`;
    return axios.get(apiPath);
  }

  static submitAppDesign = (appDesign) => {
    if (appDesign.id) {
      const apiPath = `${API_BASE_URL}/applications/design/${appDesign.id}`;
      return axios.put(apiPath, appDesign);
    } else {
      const apiPath = `${API_BASE_URL}/applications/design`;
      return axios.post(apiPath, appDesign);
    }
  };

  static publishAppDesign = (appDesignID) => {
    const apiPath = `${API_BASE_URL}/applications/design/${appDesignID}/publish`;
    return axios.put(apiPath);
  };

  static getWeimobApp = (appId) => {
    const apiPath = `${API_BASE_URL}/applications/${appId}/weimob`;
    return axios.get(apiPath);
  }

  static submitWeimobApp = (appId, weimobApp) => {
    if (weimobApp.id) {
      const apiPath = `${API_BASE_URL}/applications/${appId}/weimob/${weimobApp.id}`;
      return axios.put(apiPath, weimobApp);
    } else {
      const apiPath = `${API_BASE_URL}/applications/${appId}/weimob`;
      return axios.post(apiPath, weimobApp);
    }
  }

  static createAppLink = (appId) => {
    let apiPath = `${API_BASE_URL}/applications/${appId}/link?projection=webAdmin&`;
    return axios.get(apiPath);
  }
}