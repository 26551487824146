import React, {useEffect, useState} from 'react';
import { Switch, Route } from 'react-router-dom';

import CouponEventList from './CouponEventList';
import CouponEventEdit from './CouponEventEdit';
import {useLayoutState} from "../../components/layout/AppLayoutContext";

import dayjs from "dayjs";
import CouponEventApi from "./CouponEventApi";
import BrandApi from "../brand/BrandApi";

const initialtPagination = {
  current: 1,
  pageSize: 20,
  total: 0,
}

const initialFilter = {
  active: "",
  type: "",
  name: "",
  startDate: dayjs().subtract(30, "day").format("YYYY-MM-DD"),
  endDate: dayjs().format("YYYY-MM-DD"),
};

const CouponEvent = (props) =>  {
  const layoutState = useLayoutState();
  const [application, setApplication] = useState([]);

  const [parentPath, setParentPath] = useState("");
  const [pagination, setPagination] = useState(initialtPagination);
  const [filter, setFilter] = useState(initialFilter);
  const [loading, setLoading] = useState(false);
  const [couponEvents, setCouponEvents] = useState([]);
  const [brands, setBrands] = useState([]);

  useEffect(() => {
    setParentPath(props.match.path);
  }, [props.match.path]);

  useEffect(() => {
    setApplication(layoutState.application);
  }, [layoutState.application]);

  useEffect(() => {
    searchCouponEvents(pagination);
    loadBrands();
  }, []);

  const loadBrands = () => {
    BrandApi.getBrands({}).then(res => {
      setBrands(res.data);
    });
  };

  const searchCouponEvents = (pagination) => {
    setLoading(true);
    const params = getSearchParam(pagination);

    CouponEventApi.getCouponEvents(params).then(res => {
      const pagingResult = res.data;
      const pagination = {
        current: pagingResult.page,
        pageSize: pagingResult.pageSize,
        total: pagingResult.count,
      }
      setCouponEvents(pagingResult.data);
      setPagination(pagination);
      setLoading(false);
    });
  };

  const getSearchParam = (pagination) => {
    const params = {
      pageSize: pagination.pageSize,
      page: pagination.current,
      ...filter
    };
    return params;
  };

  const setCouponEvent = (changedCouponEvent) => {
    const couponEventsState = [...couponEvents];
    const changedCouponEventsState = couponEventsState.map(ce => {
      if (ce.id === changedCouponEvent.id) {
        return JSON.parse(JSON.stringify(changedCouponEvent));
      } else {
        return ce;
      }
    });
    setCouponEvents(changedCouponEventsState);
  };

  const setFilterState = (filter) => {
    setFilter(JSON.parse(JSON.stringify(filter)));
  };

  const setPaginationState = (pagination) => {
    setPagination(JSON.parse(JSON.stringify(pagination)));
  };

  // NOTICE: path argument를 사용하기 위해서는 Route의 순서가 중요. new -> edit -> show 순서로 작성
  return (
      <div>
        <div className="page-content">
          <Switch>
            <Route path={props.match.path} exact
                   render={(props) =>
                       (<CouponEventList
                           couponEvents={couponEvents}
                           application={application}
                           filter={filter}
                           loading={loading}
                           pagination={pagination}
                           parentPath={parentPath}
                           setFilter={setFilterState}
                           setPagination={setPaginationState}
                           searchCouponEvents={searchCouponEvents}
                           setCouponEvent={setCouponEvent}
                           {...props}/>)
                   }
            />
            <Route path={`${props.match.path}/new`}
                   render={(props) =>
                       ( <CouponEventEdit parentPath={parentPath}
                                          pagination={pagination}
                                          application={application}
                                          brands={brands}
                                          searchCouponEvents={searchCouponEvents}
                                          setCouponEvent={setCouponEvent}
                                          {...props}/>)
                   }
            />
            <Route path={`${props.match.path}/:couponEventId`}
                   render={(props) =>
                       ( <CouponEventEdit parentPath={parentPath}
                                          pagination={pagination}
                                          application={application}
                                          brands={brands}
                                          searchCouponEvents={searchCouponEvents}
                                          setCouponEvent={setCouponEvent}
                                          {...props}/>)
                   }
            />
          </Switch>
        </div>
      </div>
  )
};

export default CouponEvent;