import {adminConfig} from "../../config/admin.config";
import { HttpUtil } from '../../util/HttpUtil';
import axios from "axios";

const API_BASE_URL = adminConfig.apiServer();

export default class BrandApi {
  static getBrands = (options) => {
    let apiPath = `${API_BASE_URL}/brands?projection=webAdmin&`;

    if (options) {
      apiPath += Object.keys(options).map(key => {
        return key + "=" + options[key]
      }).join("&");
    }

    return axios.get(apiPath);
  };

  static submitBrand = (brands) => {
    if (brands.length === 1 && brands[0].id) {
      const apiPath = `${API_BASE_URL}/brands/${brands[0].id}`;
      return axios.put(apiPath, brands[0]);
    } else {
      const apiPath = `${API_BASE_URL}/brands`;
      return axios.post(apiPath, brands);
    }
  };

  static deleteBrand = (id) => {
    let apiPath = `${API_BASE_URL}/brands/${id}`;

    return fetch(apiPath, { method: 'delete', headers: HttpUtil.getHeader() })
        .then(HttpUtil.handleHttpStatus)
        .then(res => res.json())
        .catch(HttpUtil.handleHttpStatusError)
  }
}