import React from "react";
import Util from "../../util/Util";
import CompanyApi from "./CompanyApi";
import intl from "react-intl-universal";
import {Route, Switch} from "react-router-dom";

import CompanyEdit from "./CompanyEdit";
import CompanyList from "./CompanyList";

export default class Company extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      companies: [],
      filter: {},
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
    };
  };

  componentDidMount() {
    this.loadCompanies(this.state.pagination);
  };

  getSearchParams = (pagination) => {
    const {filter} = this.state;
    const params = {
      ...filter,
      page: pagination.current,
      pageSize: pagination.pageSize,
    };
    return params;
  };

  loadCompanies = (pagination) => {
    CompanyApi.getCompanies(this.getSearchParams(pagination)).then(res => {
      const pagingResult = res.data;
      const pagination = {
        current: pagingResult.page,
        pageSize: pagingResult.pageSize,
        total: pagingResult.count,
      };
      this.setState({
        companies: pagingResult.data,
        pagination: pagination,
      });
    });
  };

  setFilter = (filter) => {
    this.setState({
      filter: filter,
    });
  };

  setCompany = (savedCompany) => {
    const companies = [];
    let found = false;

    this.state.companies.forEach(company => {
      if (company.id === savedCompany.id) {
        companies.push(savedCompany);
        found = true;
      } else {
        companies.push(company);
      }
    });

    if (!found) {
      companies.push(savedCompany);
    }
    this.setState({
      companies: companies,
    })
  }

  render() {
    // NOTICE: path argument를 사용하기 위해서는 Route의 순서가 중요. new -> edit -> show 순서로 작성
    return (
        <div>
          <div className="page-content">
            <Switch>
              <Route path={this.props.match.path} exact
                     render={(props) => (<CompanyList
                         companies={this.state.companies}
                         pagination={this.state.pagination}
                         filter={this.state.filter}
                         setFilter={this.setFilter}
                         loadCompanies={this.loadCompanies}
                         {...props}/>) } />
              <Route path={`${this.props.match.path}/new`}
                     render={(props) => ( <CompanyEdit
                  companies={this.state.companies}
                  setCompany={this.setCompany}
                  parentPath={this.props.match.path}
                  {...props}/>)} />
              <Route path={`${this.props.match.path}/:companyId/edit`}
                     render={(props) => ( <CompanyEdit
                         companies={this.state.companies}
                         setCompany={this.setCompany}
                         parentPath={this.props.match.path}
                         {...props}/>)} />
            </Switch>
          </div>
        </div>
    )
  };
}