import React, {useState, useEffect} from "react";
import Util from "../../util/Util";
import ApplicationPageApi from "./ApplicationPageApi";
import intl from "react-intl-universal";
import {Button, Input, Modal, Table, Layout} from "antd";
import {SettingOutlined} from "@ant-design/icons";

const {Content, Sider} = Layout;

const AppPageSearchPopup = (props) => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const [appPages, setAppPages] = useState([]);

  useEffect(() => {
    loadAppPages(1);
  }, []);

  const loadAppPages = (page) => {
    let params = {
      pageType: "sub",
      pageSize: pagination.pageSize,
      page: page,
    };

    ApplicationPageApi.getAppPages(params).then(res => {
      const pageResult = res.data;
      const pagination = {
        current: pageResult.page,
        pageSize: pageResult.pageSize,
        total: pageResult.count,
      }
      setAppPages(pageResult.data);
      setPagination(pagination);
    });
  };

  const tableChanged = (e) => {
    this.loadAppPages(e.current);
  };

  const handleSearchClick = (e) => {
    this.loadAppPages(1);
  };

  const handleOk = (appPage) => {
    props.handleOk(appPage);
  };

  const columns = [{
    title: 'Id',
    dataIndex: 'id',
    key: 'id',
  }, {
    title: intl.get("page_setting.pageType"),
    dataIndex: 'pageType',
    key: 'pageType',
    render: (text, record) => {
      return (<span>{intl.get("page_setting.page." + text)}</span>)
    }
  }, {
    title: intl.get("page_setting.title"),
    dataIndex: 'title',
    key: 'title',
    render: (text, record) => {
      if (record.pageType === "sub") {
        return (<span>{text}</span>)
      } else {
        return (<span>{intl.get("page_setting.page." + record.pageType)}</span>)
      }
    }
  }, {
    title: intl.get("page_setting.published"),
    dataIndex: 'published',
    key: 'published',
    render: (text, record) => {
      const textLabel = record.published ? "true" : "false";
      return (<span>{textLabel}</span>)
    }
  }];

  return (
      <Modal
          title={[<div key={1}><SettingOutlined/> 페이지 검색</div>]}
          visible={true}
          onCancel={props.handleCancel}
          footer={null}
          // onOk={handleOk}
          width={800}
          bodyStyle={{margin: 0, padding: 0}}
      >
        <Layout>
          <Content className="modal-content">
            <div>
              <div className="filter-box">
                <div style={{textAlign: "right", width: "100%"}}>
                  <Button type="primary" onClick={handleSearchClick}>검색</Button>
                </div>
              </div>
              <div style={{marginTop: "10px"}}>
                <Table size={'small'}
                       dataSource={appPages}
                       columns={columns}
                       rowKey={'id'}
                       title={() => '전체: ' + pagination.total}
                       pagination={pagination}
                       onChange={tableChanged}
                       onRow={(record, rowIndex) => {
                         return {
                           onClick: event => {
                             handleOk(record)
                           },
                         }
                       }}
                />
              </div>
            </div>
          </Content>
        </Layout>
      </Modal>
  )
}

export default AppPageSearchPopup;