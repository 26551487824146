import React, {useEffect, useState} from 'react';
import ApplicationPageApi from "./ApplicationPageApi";
import {Button, Divider, message, Radio, Tooltip, Descriptions,
  Space, Input, Select, List} from "antd";
import {Link} from "react-router-dom";
import {QuestionCircleOutlined} from "@ant-design/icons";
import intl from "react-intl-universal";
import ApplicationPreviewFrame from "../application/ApplicationPreviewFrame";
import ApplicationApi from "../application/ApplicationApi";
import EditPagePreview from "./etc_preview/EtcPagePreview";
import { UpCircleOutlined, DownCircleOutlined, DeleteOutlined } from '@ant-design/icons';

const EtcPageEdit = (props) => {
  const [appPage, setAppPage] = useState({});
  const [pageItems, setPageItems] = useState([]);
  const [maxItemId, setMaxItemId] = useState(0);
  const [appPageComponents, setAppPageComponents] = useState([]);
  const [applicationDesign, setAapplicationDesign] = useState({});

  useEffect(() => {
    loadAppPageComponents();
  }, []);

  useEffect(() => {
    if (props.match.params.pageId) {
      loadAppPage(props.match.params.pageId);
    }
  }, [props.match.params.pageId]);

  useEffect(() => {
    if (props.application.id) {
      loadApplicationDesign();
    }
  }, [props.application.id]);

  const loadApplicationDesign = () => {
    ApplicationApi.getApplicationDesign().then(res => {
      setAapplicationDesign(res.data);
    });
  };

  const loadAppPageComponents = () => {
    ApplicationPageApi.getAppPages({componentType: true, published: true, pageSize: 100}).then(res => {
      setAppPageComponents(res.data.data);
    })
  };

  const loadAppPage = (pageId) => {
    ApplicationPageApi.getAppPage(pageId).then(res => {
      const appPage = res.data;
      setAppPage(appPage);
      loadPreviewAppPage(appPage.pageType);
    });
  };

  const loadPreviewAppPage = (pageType) => {
    ApplicationPageApi.getAppPageByType(pageType).then(res => {
      const previewAppPage = res.data;
      let pageItemsRes = previewAppPage.settings ? previewAppPage.settings : [];
      let maxItemId = 0;
      pageItemsRes = pageItemsRes.map(item => {
        item.selected = false;
        if (item.id > maxItemId) {
          maxItemId = item.id;
        }
        return item;
      });
      if (pageItemsRes.length > 0) {
        pageItemsRes[0].selected = true;
      }

      setMaxItemId(maxItemId);
      setPageItems(pageItemsRes);
    });
  };

  const submitPublish = () => {
    if (!(window.confirm(intl.get("product_detail_page.confirmDeploy")))) {
      return;
    }
    saveAppPage(true);
  };

  const saveAppPage = (publish) => {
    const appPageState = {...appPage};
    const pageItemsState = JSON.parse(JSON.stringify(pageItems)).map(pi => {
      // 저장할때는 settingValues 값은 삭제한다.
      // 매번 조회 시 componentId를 이용해서 해당 정보를 가져온다.
      return {
        id: pi.id,
        componentId: pi.componentId,
        settingValues: {},
        type: pi.type,
      };
    });

    appPageState.editingSettings = pageItemsState;
    if (publish) {
      appPageState.published = true;
      appPageState.publishedSettings = pageItemsState;
    }
    ApplicationPageApi.submitAppPage(appPageState).then(res => {
      message.success(intl.get("common.message.saved"));
      setAppPage(res.data);
      props.loadAppPages();
    }).
    catch(e => {
      console.log("saveAppPage error:", e);
      // message.error('저장 중 오류가 발생했습니다.');
    });
  };

  const onItemSelected = (id) => {
    const pageItemsState = pageItems.map((pageItem) => {
      if (pageItem.id === id) {
        pageItem.selected = true;
      } else {
        pageItem.selected = false;
      }
      return pageItem;
    });
    setPageItems(pageItemsState);
  };

  const addPageItem = () => {
    const pageItemsState = pageItems.map(pi => {
      pi.selected = false;
      return pi
    })
    const addedPageItem = {
      id: maxItemId + 1,
      type: "component",
      settingValues: {items: []},
      selected: true,
    };

    pageItemsState.push(addedPageItem);
    setPageItems(pageItemsState);
    setMaxItemId(maxItemId + 1);
  };

  const onSelectComponent = (componentId) => {
    const selectedComponent = appPageComponents.find(sc => sc.id === componentId);
    if (!selectedComponent) {
      message.error(intl.get("common.message.contactAdmin"));
      return;
    }
    const pageItemsState = pageItems.map(pi => {
      if (pi.selected) {
        pi.componentId = selectedComponent.id;
        pi.settingValues = selectedComponent.publishedSettings;
      }
      return pi
    });
    setPageItems(pageItemsState);
  };

  const removeItem = (id) => {
    const pageItemsState = [...pageItems];
    const remainedPageItems = pageItemsState.filter(p => p.id !== id);
    if (remainedPageItems.length > 0) {
      remainedPageItems[0].selected = true;
    }
    setPageItems(remainedPageItems);
  };

  const moveUp = () => {
    const pageItemsState = [...pageItems];
    const selectedPageItem = pageItemsState.find(p => p.selected);
    let itemIndex = -1;
    pageItemsState.forEach((item, index) => {
      if (item.id === selectedPageItem.id) {
        itemIndex = index;
      }
    });

    if (itemIndex < 0) {
      message.error("Error");
      return;
    }

    if (itemIndex === 0) {
      message.error("처음입니다.");
      return;
    }

    const targetPageItem = pageItemsState[itemIndex];
    pageItemsState[itemIndex] = pageItemsState[itemIndex - 1];
    pageItemsState[itemIndex - 1] = targetPageItem;

    setPageItems(pageItemsState);
  };

  const moveDown = () => {
    const pageItemsState = [...pageItems];
    const selectedPageItem = pageItemsState.find(p => p.selected);
    let itemIndex = -1;
    pageItemsState.forEach((item, index) => {
      if (item.id === selectedPageItem.id) {
        itemIndex = index;
      }
    });

    if (itemIndex < 0) {
      message.error("Error");
      return;
    }

    if (itemIndex === (pageItems.length -1)) {
      message.error("마지막입니다.");
      return;
    }

    const targetPageItem = pageItemsState[itemIndex];
    pageItemsState[itemIndex] = pageItemsState[itemIndex + 1];
    pageItemsState[itemIndex + 1] = targetPageItem;

    setPageItems(pageItemsState);
  };

  let selectedItemIndex = 0;
  let selectedPageItem;
  pageItems.forEach((pi, index) => {
    if (pi.selected) {
      selectedItemIndex = index;
      selectedPageItem = pi;
    }
  });
  const disableUp = selectedItemIndex === 0;
  const disableDown = pageItems.length <= 1 || selectedItemIndex == pageItems.length - 1;

  return (
      <div>
        <div style={{width: "100%", marginLeft: "auto"}}>
          <div style={{display: "flex", alignItems: "center"}}>
            <div style={{fontSize: 16, fontWeight: "bold", marginRight: 10}}>
              {intl.get("product_detail_page.page_setting")}
            </div>
            <div style={{marginRight: 10, marginLeft: "auto"}}>
              <Button type="primary" onClick={addPageItem}>{intl.get("product_detail_page.addItem")}</Button>
              <Divider type="vertical"/>
              <Button type="primary"><Link to={`${props.parentPath}`}>{intl.get("common.button.list")}</Link></Button>
              <Divider type="vertical"/>
              <Button type="primary" onClick={() => saveAppPage(false)}>{intl.get("common.button.save")}</Button>
              <Divider type="vertical"/>
              <Button type="primary" onClick={submitPublish}>{intl.get("page_setting.edit.publish")}</Button>
              <Tooltip title={intl.get("page_setting.edit.publish.help")}>
                <span style={{marginLeft: 20, fontSize: 18}}><QuestionCircleOutlined /></span>
              </Tooltip>
            </div>
          </div>
        </div>
        <div style={{marginTop: 20}}>
          <div style={{display: "flex", xAlignItems: "stretch"}}>
            <div style={{width: "375px"}}>
              <ApplicationPreviewFrame applicationDesign={applicationDesign}
                                       title={appPage.pageType ? intl.get("page_setting.page." + appPage.pageType) : ""}>
                <EditPagePreview
                          title={appPage.pageType ? intl.get("page_setting.page." + appPage.pageType) : ""}
                          pageItems={pageItems}
                          selectedPageItemId={selectedPageItem ? selectedPageItem.id : 0}
                          removePageItem={removeItem}
                          application={props.application}
                          applicationDesign={applicationDesign}
                          onPageItemSelected={onItemSelected}/>
              </ApplicationPreviewFrame>
            </div>
            <div style={{flex: 1, overflow: "auto", marginLeft: 20}}>
              {selectedPageItem && (
                <div>
                  <div style={{marginBottom: 5, fontSize: 16}}>선택된 영역 설정</div>
                  <Descriptions bordered size={"small"} column={1}>
                    <Descriptions.Item label={"상품 목록 컴포넌트"}>
                      <div style={{display: "flex", gap: 14, alignItems: "center"}}>
                        <Select value={selectedPageItem ? selectedPageItem.componentId : ""} style={{width: 400}} onChange={onSelectComponent}>
                          {appPageComponents.map((sc, index) => {
                            return (
                              <Select.Option key={sc.id} value={sc.id}>{sc.title}</Select.Option>
                            )
                          })}
                        </Select>
                        <Divider type={"vertical"}/>
                        <UpCircleOutlined onClick={disableUp ? null : moveUp} style={{ fontSize: '20px', color: disableUp ? "#e9e9e9" : "black"}} />
                        <DownCircleOutlined onClick={disableDown ? null : moveDown }  style={{ fontSize: '20px',  color: disableDown ? "#e9e9e9" : "black"}}/>
                        <DeleteOutlined onClick={() => removeItem(selectedPageItem.id)} style={{ fontSize: '20px'}}/>
                      </div>
                    </Descriptions.Item>
                  </Descriptions>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
  );
}

export default EtcPageEdit;