import React, {useEffect, useState} from "react";
import SupplierApi from "./SupplierApi";
import intl from "react-intl-universal";
import NoImage from "../../asset/images/no_image.png";
import {Button, Popconfirm, Table, Tag, Spin, DatePicker} from "antd";
import Util from "../../util/Util";
import dayjs from "dayjs";
import {useLayoutState} from "../../components/layout/AppLayoutContext";

const { RangePicker } = DatePicker;

const initialFilter = {
  startDate: dayjs().subtract(31, "day").format("YYYY-MM-DD"),
  endDate: dayjs().format("YYYY-MM-DD"),
}

const SupplierProductList = (props) => {
  const layoutState = useLayoutState();

  const [application, setApplication] = useState({});
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [filter, setFilter] = useState(initialFilter);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  useEffect(() => {
    searchProducts(pagination);
  }, []);

  useEffect(() => {
    setApplication(layoutState.application);
  }, [layoutState.application]);

  const onDateOk = (value, dateString) => {
    if (value[1]) {
      const {filter} = props;
      filter.startDate =  value[0].format("YYYY-MM-DD HH:mm:ss");
      filter.endDate = value[1].format("YYYY-MM-DD HH:mm:ss");
      props.setFilter(filter);
    }
  }

  const searchProducts = (paginationState) => {
    setLoading(true);
    SupplierApi.getProducts(getSearchParam(paginationState)).then(res => {
      const pagingResult = res.data;
      const pagination = {
        current: pagingResult.page,
        pageSize: pagingResult.pageSize,
        total: pagingResult.count,
      }
      setProducts(pagingResult.data);
      setPagination(pagination);
    }).finally(() => {
      setLoading(false);
    });
  }

  const getSearchParam = (pagination) => {
    const params = {
      pageSize: pagination.pageSize,
      page: pagination.current,
      ...filter
    };
    return params;
  };

  const pageChanged = (pagination) => {
    searchProducts(pagination);
  };

  const onSearchClick = () => {
    const paginationState = {...pagination};
    paginationState.current = 1;
    searchProducts(paginationState);
  }

  const columns = [{
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    render: (text, record) => {
      if (record.supplierProductId) {
        return (<><div>{record.id}</div><div>({record.supplierProductId})</div></>)
      } else {
        return text;
      }
    }
  }, {
    title: intl.get("products.featuredImage"),
    dataIndex: 'images',
    key: 'images',
    align: 'center',
    render: (text, record) => {
      const image = record.images.length > 0 ? record.images[0] + "?x-oss-process=image/resize,h_48,w_48" : NoImage;
      return  (<span><img src={image} style={{width: "48px", height: "48px"}}/></span>);
    }
  }, {
    title: intl.get("products.name"),
    dataIndex: 'name',
    key: 'name',
    render: (text, record) => {
      return (
        <div>
          <div>{record.name}</div>
          { (record.name !== record.nameKr) && (<div>{record.nameKr}</div>) }
          { record.offerProduct && (<Tag color="geekblue">{intl.get("products.button.eventProduct")}</Tag>)}
          { record.tags && (
            <div>
              { record.tags.map((t, index) => (<Tag key={index} color="purple">{t.value}</Tag>)) }
            </div>
          )}
        </div>
      )
    }
  }, {
    title: intl.get("products.options") + " / " + intl.get("products.sku.stocks"),
    key: 'skus',
    dataIndex: 'skus',
    align: 'left',
    render: (text, record) => {
      const options = [];
      record.skus.forEach((sku, index) => {
        options.push((
          <div key={sku.id}>
            <span style={{marginRight: 10}}>{sku.optionText}</span>
            <span>{intl.get("products.sku.stocks")}: {sku.realStocks}</span>
          </div>
        ));
      })
      return (<div style={{fontSize: 13}}>{options}</div>)
    }
  }, {
    title: intl.get("products.taxRate"),
    key: 'taxRate',
    dataIndex: 'skus',
    align: 'left',
    render: (text, record) => {
      const taxRates = [];
      record.skus.forEach((sku, index) => {
        if (!taxRates.includes(sku.taxRate)) {
          taxRates.push(sku.taxRate);
        }
      });
      return taxRates.map((t, index) => {
        return (<div key={index}>{t}%</div>)
      })
    }
  }, {
    title: intl.get("products.salesPrice"),
    dataIndex: 'salesPrice',
    key: 'salesPrice',
    align: 'right',
    render: (text, record) => (
      <div>
        <div>{Util.currencyFormat(record.salesPrice.originPrice, application.productCurrency)}</div>
        <div style={{color: "gray", fontSize: 13}}>{Util.currencyFormat(record.salesPrice.secondaryPrice, application.userPayCurrency)}</div>
      </div>
    )
  }, {
    title: intl.get("products.totalSales"),
    dataIndex: 'totalSales',
    key: 'totalSales',
    align: "right"
  }, {
    title: intl.get("common.updatedAt"),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    align: 'center',
    render: (text, record) => (
      <span>{text ? text.substring(0, 10) : ""}</span>
    )
  }];

  const dateRange = [dayjs(filter.startDate), dayjs(filter.endDate)];

  return (
    <div className="page-content">
      <Spin spinning={loading}>
        <div className="page-op-box">
          <div className="filter-box">
            <RangePicker
              format="YYYY-MM-DD"
              placeholder={[intl.get("order.searchOrderDateStart"), intl.get("order.searchOrderDateEnd")]}
              onOk={onDateOk}
              value={dateRange}
              allowClear={false}
              style={{ marginRight: 10 }}
            />
            <div style={{marginLeft: "auto"}}>
              <div style={{width: 100}}>
                <Button style={{width: 100}} type="primary" block size="default" onClick={onSearchClick}>{intl.get("common.button.search")}</Button>
              </div>
            </div>
          </div>
        </div>
        <div style={{marginTop: "10px"}}>
          <Table size={'small'}
                 dataSource={products}
                 columns={columns}
                 rowKey={'id'}
                 title={() => intl.get("common.label.listTotal", {total: pagination.total})}
                 pagination={pagination}
                 onChange={pageChanged}
          />
        </div>
      </Spin>
    </div>
  )
}

export default SupplierProductList;