import {PriceView} from "./PriceView";
import {ShoppingCartOutlined} from "@ant-design/icons";
import React from "react";

const ProductColumns = (props) => {
  const {applicationDesign, settingValues} = props;
  const { themeColor } = applicationDesign.themeSetting;

  let productWidth = props.columns === 2 ? 150 : 100;
  if (settingValues.productWidth) {
    productWidth = parseInt(settingValues.productWidth);
  }

  let padding = "0,15,0,15";
  if (settingValues.padding) {
    padding = settingValues.padding;
  }

  const paddingStyle = padding.split(",").map(p => p.trim() + "px").join(" ");
  const descPadding = props.columns === 2 ? "0 10px" : "0 6px";
  const priceWidth = Math.ceil(productWidth * 0.8);
  const titleHeight = props.columns === 2 ? 40 : 36 ;
  const titleFontSize = props.columns === 2 ? 14 : 12;

  let columnGap = (settingValues.productGap ? settingValues.productGap : 8) + "px";

  const backgroundColor = settingValues.backgroundColor || "#ffffff";
  const style = {
    backgroundColor: backgroundColor,
    padding: paddingStyle,
  };
  if (settingValues.backgroundImage) {
    style.backgroundImage = "url('" + settingValues.backgroundImage + "')";
    style.backgroundSize = "cover";
    style.backgroundRepeat = "no-repeat";
    style.backgroundPosition = "center center";
  }

  return (
    <div className={props.columns === 2 ? "two-column-box" : "three-column-box"}
         style={style}>
      {settingValues.title && (<div className="header">{settingValues.title}</div>)}
      <div style={{
        backgroundColor: settingValues.showGapBgColor ? backgroundColor : "#ffffff",
        borderRadius: settingValues.showGapBgColor ? 0 : 8
      }}
      >
        <div className="content-box"
             style={{
               columnGap: columnGap,
               rowGap: 16,
               borderRadius: settingValues.showGapBgColor ? 4 : 0,
               padding: settingValues.showGapBgColor ? 0 : 15,
             }}
        >
          {props.products.map(product => (
            <div key={product.id} className="item-box"
                 style={{width: productWidth + "px", borderRadius: settingValues.round ? 4 : 0}}>
              <img
                style={{
                  width: productWidth, height: productWidth,
                  borderTopRightRadius: "inherit",
                  borderTopLeftRadius: "inherit"
                }}
                src={product.images[0] + "?x-oss-process=image/resize,w_256"}/>
              <div style={{width: "100%", padding: descPadding, marginTop: 8}}>
                <div className="u-line-2 title" style={{width: "100%", height: titleHeight, fontSize: titleFontSize}}>
                  {product.name}
                </div>
                <div style={{display: "flex", alignItems: "flex-end"}}>
                  <PriceView listPrice={product.listPrice}
                             salesPrice={product.salesPrice}
                             width={priceWidth}
                             {...props}
                  />
                  {(props.columns === 2 && settingValues.showCart) && (
                    <div style={{marginLeft: "auto"}}>
                      <ShoppingCartOutlined style={{ fontSize: '20px', color: themeColor.colorTable.price }}/>
                    </div>
                  )}
                </div>
              </div>

            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductColumns;