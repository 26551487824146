import { adminConfig } from "../../config/admin.config";
import axios from "axios";
const API_BASE_URL = adminConfig.apiServer();

export default class LiveApi {
  static getLiveRooms = (params) => {
    let apiPath = `${API_BASE_URL}/wx-live-rooms?projection=webAdmin&`;

    if (params) {
      apiPath += Object.keys(params).map(key => {
        return key + "=" + params[key]
      }).join("&");
    }

    return axios.get(apiPath);
  };

  static getGoods = (options) => {
    let apiPath = `${API_BASE_URL}/wx-live-goods?projection=webAdmin&`;

    if (options) {
      apiPath += Object.keys(options).map(key => {
        return key + "=" + options[key]
      }).join("&");
    }

    return axios.get(apiPath);
  };
  static getGoodsStatus = (options) => {
    let apiPath = `${API_BASE_URL}/wx-live-goods/status?projection=webAdmin&`;

    if (options) {
      apiPath += Object.keys(options).map(key => {
        return key + "=" + options[key]
      }).join("&");
    }

    return axios.get(apiPath);
  };
  static submitGoods = (param) => {
    if (param.goodsId) {
      let apiPath = `${API_BASE_URL}/wx-live-goods?projection=webAdmin`;
      return axios.put(apiPath, param);
    } else {
      let apiPath = `${API_BASE_URL}/wx-live-goods?projection=webAdmin`;
      return axios.post(apiPath, param);
    }
  };

  static deleteGoogs = (id) => {
    let apiPath = `${API_BASE_URL}/wx-live-goods/${id}?projection=webAdmin`;
    return axios.delete(apiPath);
  };

  static createQrcode = (options, param) => {
    let apiPath = `${API_BASE_URL}/qrcodes/nomal?projection=webAdmin&`;

    if (options) {
      apiPath += Object.keys(options).map(key => {
        return key + "=" + encodeURIComponent(options[key])
      }).join("&");
    }
    return axios.post(apiPath, param);
  };

}