import React, {useState, useEffect} from 'react';
import {Button, DatePicker, Divider,
  Input, message, Radio, Select, Form,
  Descriptions, Tabs, Spin, Steps, Empty} from "antd";
import OrderApi from "./OrderApi";
import AddressSelect from "../../components/address/AddressSelect";
import Util from "../../util/Util";
import {Link} from "react-router-dom";
import intl from 'react-intl-universal';
import OrderEditSku from "./OrderEditSku";
import OrderEditDelivery from "./OrderEditDelivery";
import OrderEditAdditional from "./OrderEditAdditional";
import dayjs from "dayjs";
import PaymentModal from "./payment/PaymentModal";
import UserShowPopup from "../user/UserShowPopup";
import ReplyApi from "../reply/ReplyApi";
import ReplyItem from "../reply/ReplyItem";
import ProductDetailViewPopup from "../product/ProductDetailViewPopup";
import ClearancePopup from "./clearance/ClearancePopup";

const { Step } = Steps;

const OrderEdit = (props) => {
  const [form] = Form.useForm();
  const [uploading, setUploading] = useState(false);
  const [order, setOrder] = useState({});
  const [visiblePayment, setVisiblePayment] = useState(false);
  const [visibleUserInfo, setVisibleUserInfo] = useState(false);
  const [reply, setReply] = useState({});
  const [selectedProductId, setSelectedProductId] = useState(0);
  const [visibleProductPopup, setVisibleProductPopup] = useState(false);
  const [visibleClearance, setVisibleClearance] = useState(false);

  useEffect(() => {
    if (props.match.params.orderId) {
      // 목록에 있는 경우 dirty되었을 수도 있기 때문에 edit view에서 다시 가져온다.
      loadOrder(props.match.params.orderId);
      loadReply(props.match.params.orderId);
    }
  }, [props.match.params.orderId]);

  const loadOrder = (id) => {
    OrderApi.getOrder(id).then(res => {
      const order = res.data;
      orderReloaded(order);
    }).catch(err => {
      console.log("getOrder error: ", err);
      if (err.response.status === 404) {
        message.error(intl.get("order.noOrder"));
      } else {
        message.error(intl.get("order.error"));
      }
    });
  };

  const orderReloaded = (order) => {
    if (order?.shippingAddress) {
      // form 설정을 위해 배송지 정보를 order 필드로 추가한다.
      order.phone = order.shippingAddress.phone;
      order.recipient = order.shippingAddress.recipient;
      order.addressDetail = order.shippingAddress.addressDetail;
    }
    if (order) {
      if (order.deliveredAt.Valid) {
        order.deliveredAtTime = dayjs(Util.getLocalTime(order.deliveredAt.Time));
      }
      if (order.finishedAt.Valid) {
        order.finishedAtTime = dayjs(Util.getLocalTime(order.finishedAt.Time));
      }
      if (order.refundAt.Valid) {
        order.refundAtTime = dayjs(Util.getLocalTime(order.refundAt.Time));
      }
    }
    setOrder(order);
    form.setFieldsValue(order);
  };

  const loadReply = (orderId) => {
    ReplyApi.getReplies({orderId: orderId}).then(res => {
      if (res.data.count > 0) {
        setReply(res.data.data[0]);
      }
    });
  };

  const saveOrder = (order) => {
    setUploading(true);
    OrderApi.saveOrder(order).then(res => {
      const savedOrder = res.data;
      setOrder(savedOrder);
      props.setOrder(savedOrder);
      message.success(intl.get("common.message.saved"));
    }).catch(err => {
      message.error(intl.get("common.message.contactAdmin"));
      console.log("saveOrder:", err);
    }).finally(() => {
      setUploading(false);
    })
  };

  const handleSubmit = (values) => {
    const orderState = JSON.parse(JSON.stringify(order));

    // 수정할 정보만 form values => orderState로 복사
    Object.assign(orderState, values);

    // 주소정보는 form 처리를 위해 주문에 있었음
    const shippingAddressId = orderState.shippingAddress.id;
    Object.assign(orderState.shippingAddress, values);
    orderState.shippingAddress.id = shippingAddressId;

    if (!orderState.shippingAddress.addressProvince) {
      message.error(intl.get("common.inputField", {name: intl.get("address.province")}));
      return;
    }
    if (!orderState.shippingAddress.addressCity) {
      message.error(intl.get("common.inputField", {name: intl.get("address.city")}));
      return;
    }
    if (!orderState.shippingAddress.addressDistrict) {
      message.error(intl.get("common.inputField", {name: intl.get("address.country")}));
      return;
    }
    if (!orderState.shippingAddress.addressCode) {
      message.error(intl.get("common.inputField", {name: intl.get("order.address")}));
      return;
    }

    if (orderState.refund && !orderState.refundAtTime) {
      message.error(intl.get("common.inputField", {name: intl.get("order.refundAt")}));
      return;
    }

    if (orderState.refundAtTime) {
      orderState.refundAt = {
        Valid: true,
        Time: orderState.refundAtTime,
      };
    } else {
      orderState.refundAt = {
        Valid: false,
      };
    }

    if (orderState.currentStatus === "delivered" || (
      orderState.currentStatus === "finished" && orderState.finishedStatus === "success")) {
      if (!orderState.deliveredAtTime) {
        message.error(intl.get("common.inputField", {name: intl.get("order.deliveredAt")}));
        return;
      }
    }
    if (orderState.deliveredAtTime) {
      orderState.deliveredAt.Time = orderState.deliveredAtTime;
      orderState.deliveredAt.Valid = true;
    } else {
      orderState.deliveredAt.Valid = false;
    }

    if (orderState.finishedAtTime) {
      orderState.finishedAt.Time = orderState.finishedAtTime;
      orderState.finishedAt.Valid = true;
    } else {
      orderState.finishedAt.Valid = false;
    }
    saveOrder(orderState);
  };

  const selectCounty = (address) => {
    const orderState = JSON.parse(JSON.stringify(order));
    if (!orderState.id) {
      return;
    }
    orderState.shippingAddress.addressProvince = address.province.name;
    orderState.shippingAddress.addressCity = address.city.name;
    orderState.shippingAddress.addressDistrict = address.country.name;
    orderState.shippingAddress.addressCode = address.country.code;
    orderState.shippingAddress.postCode = address.country.zip_code;
    setOrder(orderState);
  };

  const setOrderAssociatedValue = (name, value) => {
    const orderState = JSON.parse(JSON.stringify(order));
    const orderAssociatedValue = orderState[name];
    Object.assign(orderAssociatedValue, JSON.parse(JSON.stringify(value)));
    orderState[name] = orderAssociatedValue;
    console.log(">>>>AAAAA>", name, ">>>", orderState[name]);
    setOrder(orderState);
  };

  const sendNoti = () => {
    if (!window.confirm("관리자에게 주문 현황을 전송합니다. 전송하시겠습니까?")) {
      return;
    }
    OrderApi.sentNotification(order.id).then(res => {
      message.info("메일 발송 성공");
    })
  };

  const onCbtTypeChanged = (value) => {
    const orderState = {...order};
    orderState.cbtType = value;
    setOrder(orderState);
  };

  const orderStatusToStepKey = (status) => {
    if (status === "pay_prepared") {
      return "wait_pay";
    } else {
      return status;
    }
  }

  const showProductDetail = (productId) => {
    setSelectedProductId(productId);
    setVisibleProductPopup(true);
  };

  const submitRefund = () => {
    if(!window.confirm("환불 처리 하시겠습니까?")) {
      return;
    }
    setUploading(true);
    OrderApi.refund(order.id).then(res => {
      message.success(intl.get("common.message.saved"));
      loadOrder(order.id);
    }).catch(err => {
      console.log("submitRefund:", err);
      // message.error(intl.get("common.message.contactAdmin"));
    }).finally(() => {
      setUploading(false);
    })
  };

  const getRefundStatus = () => {
    setUploading(true);
    OrderApi.getRefundStatus(order.id).then(res => {
      message.success(intl.get("common.message.saved"));
      loadOrder(order.id);
    }).catch(err => {
      console.log("submitRefund:", err);
    }).finally(() => {
      setUploading(false);
    })
  };

  const stepItems = (order.cbtType === "9610") || (order.cbtType === "1210") ? [
    {key: 'wait_pay', title: intl.get("order.status.pay_prepared")},
    {key: 'paid', title: intl.get("order.status.paid")},
    {key: 'pay_declared', title: intl.get("order.status.pay_declared1")},
    {key: 'order_declared', title: intl.get("order.status.order_declared1")},
    {key: 'prepare_delivery', title: intl.get("order.status.declared")},
    {key: 'delivering', title: intl.get("order.status.delivering")},
    {key: 'delivered', title: intl.get("order.status.delivered")},
    {key: 'finished', title: intl.get("order.status.finished")},
  ] : [
    {key: 'wait_pay', title: intl.get("order.status.pay_prepared")},
    {key: 'paid', title: intl.get("order.status.paid")},
    {key: 'delivering', title:  intl.get("order.status.delivering")},
    {key: 'delivered', title: intl.get("order.status.delivered")},
    {key: 'finished', title: intl.get("order.status.finished")},
  ];

  const orderStepKey = orderStatusToStepKey(order.currentStatus);
  const currentStep = order.currentStatus ? stepItems.map(s => s.key).indexOf(orderStepKey) : 0;
  const canAddressEdit = Util.isAdmin() || ["pay_prepared","wait_pay", "paid", "pay_declared"].includes(order.currentStatus);

  const tabItems = [{
    key: "sku",
    label: intl.get("products.product"),
    children: <OrderEditSku order={order} setOrderAssociatedValue={setOrderAssociatedValue}/>
  }, {
    key: "delivery",
    label: intl.get("order.orderDelivery"),
    children: <OrderEditDelivery order={order} setOrderAssociatedValue={setOrderAssociatedValue}/>
  }, {
    key: "additional",
    label: intl.get("order.additional"),
    children: <OrderEditAdditional order={order} setOrderAssociatedValue={setOrderAssociatedValue}/>
  }, {
    key: "reply",
    label: intl.get("menu.reply"),
    children: reply.id ? (
        <ReplyItem reply={reply}
                   enableOrderLink={false}
                   showProductDetail={showProductDetail}
        />) : (<Empty/>)
  }];

  if (order.cbtType !== "none") {
    tabItems.push({
      key: "declareInfo",
      label: intl.get("order.declareInfo"),
      children: <Button type="default" size="small" onClick={() => setVisibleClearance(true)}>{intl.get("order.button.deliveryAndClearance")}</Button>
    });
  }

  return (
    <div style={{width: "100%"}}>
      <div style={{width: "100%", display:"flex", justifyContent: "center", marginBottom: 20}}>
        <Steps type="navigation"
               current={currentStep}
               size={"small"}
               items={stepItems}
        />
      </div>
      <Spin spinning={uploading}>
        <Form onFinish={handleSubmit} form={form}>
          <Descriptions bordered size={"small"} column={2}>
            <Descriptions.Item label={intl.get("order.orderNo")}>
              {order.orderNo}
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("order.orderDate")}>
              {order.orderDate ? Util.getLocalTime(order.orderDate).substring(0, 10) : ""}
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("order.orderProduct")}>
              <div style={{display:"flex", alignItems: "center"}}>
                <img src={order.featuredImage} style={{width: 64, height: 64, marginRight: 20}}/>
                <div style={{fontSize: 16, fontWeight: "bold"}}>{order.orderTitle}</div>
              </div>
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("order.userId")}>
              <a onClick={() => setVisibleUserInfo(true)}>{order.userId}</a>
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("order.paymentInfo")}>
              <div style={{width: 300}}>
                <div style={{display: "flex", alignItems: "center", gap: 10}}>
                  <div style={{width: 80, textAlign: "right"}}>{intl.get("order.declareOrder.salesAmount")}:</div>
                  {order.productCurrency !== order.paymentCurrency && (
                      <div style={{fontSize: 12, color: "gray", textAlign: "right", width: 100}}>{Util.currencyFormat(order.salesAmountPay, order.paymentCurrency)}</div>
                  )}
                  <div style={{width: 100, textAlign: "right"}}>{Util.currencyFormat(order.salesAmount, order.productCurrency)}</div>
                </div>
                <div style={{display: "flex", alignItems: "center", gap: 10}}>
                  <div style={{width: 80, textAlign: "right"}}>{intl.get("order.discountAmount")}:</div>
                  {order.productCurrency !== order.paymentCurrency && (
                      <div style={{fontSize: 12, color: "gray", textAlign: "right", width: 100}}>{Util.currencyFormat(order.discountAmountPay, order.paymentCurrency)}</div>
                  )}
                  <div style={{width: 100, textAlign: "right"}}>{Util.currencyFormat(order.discountAmount, order.productCurrency)}</div>
                </div>
                <div style={{display: "flex", alignItems: "center", gap: 10}}>
                  <div style={{width: 80, textAlign: "right"}}>{intl.get("order.deliveryFee")}:</div>
                  {order.productCurrency !== order.paymentCurrency && (
                      <div style={{fontSize: 12, color: "gray", textAlign: "right", width: 100}}>{Util.currencyFormat(order.deliveryFeePay, order.paymentCurrency)}</div>
                  )}
                  <div style={{width: 100, textAlign: "right"}}>{Util.currencyFormat(order.deliveryFee, order.productCurrency)}</div>
                </div>
                <div style={{display: "flex", alignItems: "center", gap: 10}}>
                  <div style={{width: 80, textAlign: "right", fontSize: 14, fontWeight: "bold"}}>{intl.get("order.total.paymentAmount")}:</div>
                  {order.productCurrency !== order.paymentCurrency && (
                      <div style={{fontSize: 12, color: "gray",  textAlign: "right", width: 100}}>{Util.currencyFormat(order.paymentAmountPay, order.paymentCurrency)}</div>
                  )}
                  <div style={{width: 100, textAlign: "right", fontSize: 14, fontWeight: "bold"}}>{Util.currencyFormat(order.paymentAmount, order.productCurrency)}</div>
                </div>
              </div>
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("order.paymentCompany")}>
              <div style={{display: "flex", gap: 8}}>
                <div style={{fontSize: 14, fontWeight: "bold", marginBottom: 5}}>{order.paymentCompany ? intl.get("common.paymentCompany." + order.paymentCompany) : ""}</div>
                <Button size={"small"} onClick={() => setVisiblePayment(true)}>{intl.get("order.paymentInfo")}</Button>
              </div>
            </Descriptions.Item>
            {order.cbtType !== "none" && (
                <>
                  <Descriptions.Item label={intl.get("order.userName")}>
                    <Form.Item name={"userName"}
                               extra={intl.get("order.userId.help")}
                               rules={[{ required: true, message: intl.get("common.inputField", {name: intl.get("order.userName")}) }]}>
                      <Input />
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={intl.get("order.idCardNo")}>
                    <Form.Item name={"idCardNo"} rules={[{ required: true, message: intl.get("common.inputField", {name: intl.get("order.idCardNo")}) }]}>
                      <Input />
                    </Form.Item>
                  </Descriptions.Item>
                </>
            )}
            <Descriptions.Item label={intl.get("order.recipient")}>
              <Form.Item name={"recipient"} rules={[{ required: true, message: intl.get("common.inputField", {name: intl.get("order.recipient")}) }]}>
                <Input disabled={!canAddressEdit}/>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("order.phone")}>
              <Form.Item name={"phone"} rules={[{ required: true, message: intl.get("common.inputField", {name: intl.get("order.phone")}) }]}>
                <Input disabled={!canAddressEdit}/>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("order.address")} span={2}>
              <div>
                <AddressSelect disabled={!canAddressEdit} address={order.shippingAddress} selectCounty={selectCounty}/>
              </div>
              <div>
                <Form.Item name={"addressDetail"} rules={[{ required: true }]}>
                  <Input disabled={!canAddressEdit}/>
                </Form.Item>
              </div>
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("order.status")}>
              <Form.Item name={"currentStatus"} rules={[{ required: true, message: intl.get("common.inputField", {name: intl.get("order.status")}) }]}>
                <Select placeholder="Select status">
                  {Util.ORDER_STATUSES.map(status =>
                    <Select.Option key={status.key} value={status.key}>
                      {intl.get("order.status." + status.key) ? intl.get("order.status." + status.key) : status.text}
                    </Select.Option>)
                  }
                </Select>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("order.cbtType")}>
              <Form.Item name={"cbtType"} rules={[{ required: true, message: intl.get("common.inputField", {name: intl.get("order.cbtType")}) }]}>
                <Select onChange={onCbtTypeChanged}>
                  <Select.Option key={"1210"} value={"1210"}>{intl.get("order.cbtType.1210")}</Select.Option>
                  <Select.Option key={"9610"} value={"9610"}>{intl.get("order.cbtType.9610")}</Select.Option>
                  <Select.Option key={"mail"} value={"mail"}>{intl.get("order.cbtType.mail")}</Select.Option>
                  <Select.Option key={"none"} value={"none"}>{intl.get("order.cbtType.none")}</Select.Option>
                </Select>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("order.finish.status")}>
              <Form.Item name={"finishedStatus"}>
                <Select placeholder="Select status">
                  {Util.ORDER_FINISH_STATUS.map(status =>
                    <Select.Option key={status.key} value={status.key}>
                      {intl.get("order.status." + status.key) ? intl.get("order.status." + status.key) : status.text}
                    </Select.Option>)
                  }
                </Select>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("order.failedReason")}>
              <Form.Item name={"failedReason"}>
                <Select placeholder={intl.get("order.failedReason")}>
                  {Util.ORDER_FAILED_REASONS.map(reason =>
                    <Select.Option key={reason.key} value={reason.key}>
                      {intl.get("order.reason." + reason.key) ? intl.get("order.reason." + reason.key) : reason.text}
                    </Select.Option>)}
                </Select>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("order.failedMessage")} span={2}>
              <Form.Item name={"failedMessage"}>
                <Input/>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("order.deliveredAt")}>
              <Form.Item name={"deliveredAtTime"}>
                <DatePicker />
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("order.finishedAt")}>
              <Form.Item name={"finishedAtTime"}>
                <DatePicker/>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("order.refund")}>
              <Form.Item name={"refund"} rules={[{ required: true }]} extra={intl.get("order.refund.help")}>
                <Radio.Group>
                  <Radio value={true}>{intl.get("common.true")}</Radio>
                  <Radio value={false}>{intl.get("common.false")}</Radio>
                </Radio.Group>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("order.refundAt")}>
              <Form.Item name={"refundAtTime"}>
                <DatePicker format={"YYYY-MM-DD"}/>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={"환불상태"}>
              <Form.Item name={"refundStatus"}>
                <Select>
                  <Select.Option value={"none"}>none</Select.Option>
                  <Select.Option value={"preparing"}>preparing</Select.Option>
                  <Select.Option value={"SUCCESS"}>SUCCESS</Select.Option>
                  <Select.Option value={"CLOSED"}>CLOSED</Select.Option>
                  <Select.Option value={"PROCESSING"}>PROCESSING</Select.Option>
                  <Select.Option value={"ABNORMAL"}>ABNORMAL</Select.Option>
                </Select>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={"환불에러"}>
              <Form.Item name={"refundErrorMessage"}>
                <Input/>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("order.managerComment")}>
              <Form.Item name={"managerComment"}>
                <Input/>
              </Form.Item>
              <div>{intl.get("order.managerComment.help")}</div>
            </Descriptions.Item>
          </Descriptions>
        </Form>
      </Spin>
      <div style={{marginTop: 10, padding: 10}}>
        <Tabs defaultActiveKey="sku"
              type={"card"}
              items={tabItems}
        />
      </div>
      <div style={{width: "100%", textAlign: "center", marginTop: 20}}>
        <Button type="primary" onClick={() => form.submit()}>{intl.get("common.button.save")}</Button>
        {order.currentStatus === "cancel_req" && (order.refundStatus === "" || order.refundStatus === "none") && (
          <>
            <Divider type="vertical"/>
            <Button type="primary" onClick={submitRefund}>{intl.get("order.refund.button")}</Button>
          </>
        )}
        {(order.refundStatus === "PROCESSING") && (
          <>
            <Divider type="vertical"/>
            <Button type="primary" onClick={getRefundStatus}>환불처리상태</Button>
          </>
        )}
        <Divider type="vertical"/>
        <Button type="primary">
          <Link to={`${props.parentPath}`}>{intl.get("common.button.list")}</Link>
        </Button>
        {Util.isAdmin() && (
          <>
            <Divider type="vertical"/>
            <Button type="primary" onClick={sendNoti}>관리자 메일전송</Button>
          </>
        )}
      </div>
      {visiblePayment && (
          <PaymentModal
              order={order}
              onOk={() => setVisiblePayment(false)}
              onCancel={() => setVisiblePayment(false)}
          />
      )}
      {visibleUserInfo && (
        <UserShowPopup
          userId={order.userId}
          handleCancel={() => setVisibleUserInfo(false)}
        />
      )}
      { visibleProductPopup && (
        <ProductDetailViewPopup productId={selectedProductId}
                                onOk={() => setVisibleProductPopup(false)}
                                onCancel={() => setVisibleProductPopup(false)}
        />
      )}
      {visibleClearance && (
        <ClearancePopup orderId={order.id}
                        application={props.application}
                        setOrder={orderReloaded}
                        handleOk={() => setVisibleClearance(false)}
                        handleCancel={() => setVisibleClearance(false)}
        />
      )}
    </div>
  );
};

export default OrderEdit;