import React, {useEffect, useState} from "react";
import {Link, Route, Switch} from "react-router-dom";
import ShopComponentList from "./ShopComponentList";
import ShopComponentEdit from "./ShopComponentEdit";
import {useLayoutState} from "../../components/layout/AppLayoutContext";
import ApplicationPageApi from "../app_page/ApplicationPageApi";

const ShopComponent = (props) => {
  const layoutState = useLayoutState();

  const [loading, setLoading] = useState(false);
  const [appPages, setAppPages] = useState([]);
  const [filter, setFilter] = useState({
    componentType: true,
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [parentPath, setParentPath] = useState("");
  const [application, setApplication] = useState([]);

  useEffect(() => {
    if (props.match) {
      setParentPath(props.match.path);
    }
  }, [props.match?.path]);

  useEffect(() => {
    searchAppPageComponents(pagination);
  }, []);

  useEffect(() => {
    setApplication(layoutState.application);
  }, [layoutState.application]);

  const setFilterState = (filterState) => {
    setFilter(filterState);
  };

  const loadAppPageComponents = () => {
    searchAppPageComponents(pagination);
  };

  const searchAppPageComponents = (paginationState) => {
    setLoading(true);
    ApplicationPageApi.getAppPages(getSearchParam(paginationState)).then(res => {
      const pagingResult = res.data;
      const pagination = {
        current: pagingResult.page,
        pageSize: pagingResult.pageSize,
        total: pagingResult.count,
      }
      setAppPages(pagingResult.data);
      setPagination(pagination);
    }).finally(() => {
      setLoading(false);
    });
  };

  const getSearchParam = (pagination) => {
    const params = {
      pageSize: pagination.pageSize,
      page: pagination.current,
      ...filter
    };
    return params;
  };

  const setAppPage = (appPage) => {

  };

  return (
    <div className="page-content">
      <Switch>
        <Route path={props.match.path} exact
               render={(props) =>
                 (<ShopComponentList
                            appPages={appPages}
                            filter={filter}
                            loading={loading}
                            pagination={pagination}
                            setFilter={setFilterState}
                            loadAppPageComponents={loadAppPageComponents}
                            searchAppPageComponents={searchAppPageComponents}
                            parentPath={parentPath}
                            application={application}
                            {...props}/>) } />
        <Route path={`${props.match.path}/new`}
               render={(props) =>
                 ( <ShopComponentEdit setAppPage={setAppPage}
                                      parentPath={parentPath}
                                      application={application}
                                      loadAppPageComponents={loadAppPageComponents}
                                      {...props}/>)} />
        />

        <Route path={`${props.match.path}/:pageId`}
               render={(props) =>
                 ( <ShopComponentEdit setAppPage={setAppPage}
                             parentPath={parentPath}
                             application={application}
                             loadAppPageComponents={loadAppPageComponents}
                             {...props}/>)} />
        />

      </Switch>
    </div>
  )
};

export default ShopComponent;