import React, {useState, useEffect} from 'react';

import {
  Table,
  Divider,
  Button,
  Input,
  Select,
  Upload,
  Modal,
  message,
  Spin,
  Typography,
  Tooltip,
  Col, Row
} from 'antd';
import intl from 'react-intl-universal';

import FileManagerApi from "./FileManagerApi";
import {UploadOutlined} from "@ant-design/icons";
import {HttpUtil} from "../../util/HttpUtil";
import Util from "../../util/Util";
import FolderImg from "../../asset/images/icons8-folder-96.png";
import FileImg from "../../asset/images/icons8-image-file-100.png";
import FolderOpenImg from "../../asset/images/icons8-opened-folder-96.png";
import axios from "axios";
import {adminConfig} from "../../config/admin.config";
import "./FileManager.less";

const { Option } = Select;

const initValue = {
  files: [],
  noMoreData: false,
  marker: "",
  prevMarker: "",
  nextMarker: "",
  selectedFiles: [],
  pagePrevMarker: {},  // page -> prev marker
  page: 1,
};

const FileList = (props) => {
  const [loading, setLoading] = useState(false);
  const [firstLevelFileInfo, setFirstLevelFileInfo] = useState(JSON.parse(JSON.stringify(initValue)));
  const [secondLevelFileInfo, setSecondLevelFileInfo] = useState(JSON.parse(JSON.stringify(initValue)));
  const [filter, setFilter] = useState({
    fileKind: "",
    fileName: "",
    directory: "",
  });
  const [uploadInfo, setUploadInfo] = useState({
    uploadFileList: [],
    uploadResult: [],
    uploadParentPath: "",
    uploadVisible: false,
  });
  const [imageDetailVisible, setImageDetailVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [newFolderVisible, setNewFolderVisible] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");

  useEffect(() => {
    const filterState = {...filter};
    filterState.fileKind = props.fileKind;
    setFilter(filterState);
  }, [props.id, props.fileKind]);

  const searchSubDir = (fileObj) => {
    const filterState = {...filter};
    filterState.directory = fileObj.name;
    setFilter(filterState);

    loadFiles(filterState, 2, 1);
  };

  const onFileKindChanged = (fileKind) => {
    // 이전 파일 목록 reset
    setFirstLevelFileInfo(JSON.parse(JSON.stringify(initValue)));
    setSecondLevelFileInfo(JSON.parse(JSON.stringify(initValue)));

    const filterState = {...filter};
    filterState.fileKind = fileKind;
    filterState.directory = "";
    setFilter(filterState);
    loadFiles(filterState, 1, 1);
};

  const searchFileName = () => {
    const filterState = {...filter};
    filterState.directory = "";
    setFilter(filterState);
    loadFiles(filterState, 1, 1);
  };

  const loadFiles = (filter, level, page, marker = "") => {
    setLoading(true);

    const options = {
      fileKind: filter.fileKind,
      fileName: level === 1 ? filter.fileName : "",
      path: level === 2 ? filter.directory : "",
      marker: marker,
      pageSize: 100,
    };

    FileManagerApi.getFiles(options).then(res => {
      if (props.fileChanged) {
        props.fileChanged([]);
      }
      const fileList = res.data;
      const currentFileInfo = (level === 0 || level === 1) ? firstLevelFileInfo : secondLevelFileInfo;
      const prevFileInfo = JSON.parse(JSON.stringify(currentFileInfo));
      currentFileInfo.page = page;
      currentFileInfo.selectedFiles = [];

      // ==== prev, next에 대한 prevMarker에 대한 처리 로직 ====
      // 앞으로 이동하는 경우는 이전 파일 목록의 marker를 사용하면 되지만
      // 뒤로 이동하는 경우에는 앞으로 이동하면서 처리되었던 marker의 정보를 이용해야 한다.
      // 따라서 pagePrevMarker 변수에 앞으로 이동시 각 페이지별로 marker 값의 정보를 저장시키고 이 정보를 활용한다.

      // prevMarker 설정
      if (page > prevFileInfo.page) {
        // 앞으로 가기 버튼
        currentFileInfo.prevMarker = prevFileInfo.marker;
      } else {
        // 뒤로 가기 버튼
        currentFileInfo.prevMarker = prevFileInfo.pagePrevMarker[page];
      }

      // nextMarker 설정
      if (fileList.isTruncated) {
        // 폴더를 포함하여 이름 순서로 정렬한다.
        const copiedFiles = JSON.parse(JSON.stringify(fileList.fileObjects));
        copiedFiles.sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        currentFileInfo.nextMarker = copiedFiles[copiedFiles.length - 1].key;
      } else {
        currentFileInfo.nextMarker = "";
      }

      // page별 prevMarker history 설정
      currentFileInfo.pagePrevMarker[page] = currentFileInfo.prevMarker;

      // 파일 목록 설정
      if (fileList.fileObjects.length > 0) {
        currentFileInfo.files = fileList.fileObjects;
      } else {
        if (level === 0 || !fileList.isTruncated) {
          currentFileInfo.files = fileList.fileObjects;
        }
      }
      currentFileInfo.marker = fileList.marker;
      currentFileInfo.path = filter.directory;
      currentFileInfo.selectedFiles = [];

      if (level === 0 || level === 1) {   // 0 -> fileKind 변경, 1 -> 디렉토리 선택
        setFirstLevelFileInfo(currentFileInfo);
        setSecondLevelFileInfo(JSON.parse(JSON.stringify(initValue)));
      } else {
        setSecondLevelFileInfo(currentFileInfo);
      }
    }).catch(err => {
      console.log('failed:' + err);
    }).finally(() => {
      setLoading(false);
    })
  };

  const showUploadFile = (level) => {
    let uploadParentPath = level === 1 ? "" : filter.directory ;
    setUploadInfo({
      uploadFileList: [],
      uploadResult: [],
      uploadParentPath: uploadParentPath,
      uploadVisible: true
    });
  };

  const handleUploadOk = () => {
    setLoading(true);

    const formData = new FormData();
    uploadInfo.uploadFileList.forEach(file => {
      formData.append('files', file);
    });
    formData.append("fileKind", filter.fileKind);
    formData.append("parentPath", uploadInfo.uploadParentPath);

    let apiPath = adminConfig.apiServer() + "/file-managers";
    let headers = HttpUtil.getHeader();
    axios.post(apiPath, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      const uploadInfoState = {...uploadInfo};
      uploadInfoState.uploadResult = res.data;
      setUploadInfo(uploadInfoState);
      const level = uploadInfo.uploadParentPath ? 2 : 1;
      loadFiles(filter, level, 1, "");
    }).catch(err => {
      console.log("Error:", err);
      message.error("오류가 발생했습니다.");
    }).finally(() => {
      setLoading(false);
    });
  };

  const handleUploadCancel = () => {
    setUploadInfo({
      uploadVisible: false,
      uploadParentPath: "",
      uploadFileList: [],
      uploadResult: [],
    })
  };

  const createFolder = () => {
    if (!filter.fileKind) {
      message(intl.get("common.inputField", {name: "file_manager.fileKind"}));
      return;
    }

    if (!newFolderName || newFolderName.trim().length === 0) {
      message.error(intl.get("common.inputField", {name: intl.get("file_manager.folderName")}))
      return;
    }

   setLoading(true);
    FileManagerApi.createFolder(filter.fileKind, newFolderName).then(json => {
      setNewFolderVisible(false);
      message.info("folder [" + newFolderName + "] created");
      loadFiles(filter, 1, 1, "");
    }).catch(err => {
      // message.error('creating folder failed:' + JSON.parse(err.responseText).error.message);
    }).finally(() => {
      setLoading(false);
    })
  };

  const deleteFiles = (level) => {
    const fileInfo = level === 1 ? firstLevelFileInfo : secondLevelFileInfo;
    const selectedFiles = fileInfo.selectedFiles;

    if (selectedFiles.length === 0) {
      message.error(intl.get("file_manager.message.selectFile"));
      return;
    }

    if(!window.confirm(intl.get("file_manager.message.confirmDeleteFile", {count: selectedFiles.length}))) {
      return;
    }

    setLoading(true);
    FileManagerApi.deleteFiles(selectedFiles).then(json => {
        loadFiles(filter, level, 1, "");
    }).catch(err => {
      console.log("delete failed:", err);
      // message.error('delete failed:' + JSON.parse(err.responseText).error.message);
    }).finally(() => {
      setLoading(false);
    })
  };

  const handleFileRemoved = (file) => {
    const uploadInfoState = {...uploadInfo};
    uploadInfoState.uploadFileList = uploadInfoState.uploadFileList.filter(eachFile => eachFile.uid !== file.uid)
    setUploadInfo(uploadInfoState);
  };

  const handleFileBeforeUpload = (file) => {
    const uploadInfoState = {...uploadInfo};
    if (uploadInfoState.uploadFileList.length >= 5) {
      message.error(intl.get("file_manager.message.max_upload"));
      return;
    }

    if (file.size > 3 * 1024 * 1024) {
      message.error(intl.get("file_manager.message.exceed_file_size", {name: file.name}));
      return;
    }
    uploadInfoState.uploadFileList.push(file);
    setUploadInfo(uploadInfoState);
    // false 반환시 실제 upload 처리를 하지 않는다.
    // Modal OK click시 일괄 업로드
    return false;
  };

  const onChangeSearchFileName = (e) => {
    const filterState = {...filter};
    filterState.fileName = e.target.value;
    setFilter(filterState);
  };

  const showImageDetailModal = (path) => {
    setImageDetailVisible(true);
    setSelectedImage(path);
  };

  const handleImageDetailOk = () => {
    setImageDetailVisible(false);
  };

  const handleImageDetailCancel = () => {
    setImageDetailVisible(false);
  };

  const onFileSelectChange1 = (selectedFiles) => {
    const firstLevelFileInfoState = {...firstLevelFileInfo};
    firstLevelFileInfoState.selectedFiles = selectedFiles;
    setFirstLevelFileInfo(firstLevelFileInfoState);
    mergeAndFireFileChanged(firstLevelFileInfoState, secondLevelFileInfo);
  };

  const onFileSelectChange2 = (selectedFiles) => {
    const secondLevelFileInfoState = {...secondLevelFileInfo};
    secondLevelFileInfoState.selectedFiles = selectedFiles;
    setSecondLevelFileInfo(secondLevelFileInfoState);
    mergeAndFireFileChanged(firstLevelFileInfo, secondLevelFileInfoState);
  };

  const mergeAndFireFileChanged = (firstLevelFileInfo, secondLevelFileInfo) => {
    const selectedFiles = [];

    firstLevelFileInfo.selectedFiles.forEach(sFile => {
      const selected = firstLevelFileInfo.files.find(file => sFile === file.key);
      if (selected && !selected.isDir) {
        selectedFiles.push(selected.path);
      }
    });
    secondLevelFileInfo.selectedFiles.forEach(sFile => {
      const selected = secondLevelFileInfo.files.find(file => sFile === file.key);
      if (selected) {
        selectedFiles.push(selected.path);
      }
    });
    if (props.fileChanged) {
      props.fileChanged(selectedFiles);
    }
  };

  const fileColumns = [{
    title: intl.get("file_manager.fileName"),
    dataIndex: 'name',
    key: 'name',
    render: (text, record) => {
      if (record.isDir) {
        return (
            <div style={{display: "flex", cursor: "pointer"}} onClick={() => {
              if (record.isDir) searchSubDir(record)
            }}>
              <div style={{marginRight: 5}}>{record.isDir ? (
                  <img src={filter.directory === text ? FolderOpenImg : FolderImg} style={{width: "20px", height: "20px"}}/>) : (
                  <img src={FileImg} style={{width: "20px", height: "20px"}}/>)}</div>
              <div>{record.name}</div>
            </div>
        )
      } else {
        return (
            <Tooltip title={record.isDir ? "" : Util.getLocalTime(record.lastModified)}>
              <div style={{display: "flex", cursor: "pointer"}} onClick={() => showImageDetailModal(record.path)}>
                <div style={{marginRight: 5}}><img src={FileImg} style={{width: "20px", height: "20px"}}/></div>
                <div>{record.name}</div>
              </div>
            </Tooltip>
        )
      }
    }
  }, {
    title: intl.get("file_manager.fileSize"),
    dataIndex: 'size',
    key: 'size',
    // width: 100,
    render: (text, record) => {
      if (record.isDir) {
        return (<div/>)
      }
      return (
          <span>{Util.humanFileSize(text, true, 2)}</span>
      )
    }
  }, {
    title: intl.get("file_manager.image"),
    dataIndex: 'path',
    key: 'path',
    width: 80,
    render: (text, record) => {
      if (record.isDir) {
        return (<div></div>)
      } else {
        const imagePath = text && text.endsWith(".svg") ?
          Util.encodeImagePath(text) : Util.encodeImagePath(text) + "?x-oss-process=image/resize,h_64,w_64";

        return (
            <Tooltip title={text}>
              <span style={{cursor: "pointer"}} onClick={() => showImageDetailModal(record.path)}>
                <img src={imagePath}
                     style={{width: "42px", height: "42px"}}/>
              </span>
            </Tooltip>
        )
      }
    }
  }];

  return (
      <div className={"file-manager"}>
        <Spin spinning={loading}>
          <div>
            <Input value={filter.fileName} onChange={onChangeSearchFileName}
                   style={{ width: 300, marginRight: 10 }}
                   placeholder={intl.get("file.name.placeholder")}/>
            <Button type="primary" onClick={searchFileName}>{intl.get("common.button.search")}</Button>
          </div>
          <div style={{marginTop: "10px"}}>
            <div style={{display: "flex", gap: 5}}>
              {/* 파일 종류 폴더 */}
              <div style={{flexShrink: 0, width: 100}}>
                <div className={"table-header"} style={{marginTop: 28}}>{intl.get("file_manager.fileKind")}</div>
                <div className={"folder-wrapper"} onClick={() => onFileKindChanged('index')}>
                  <img className="folder-image" src={filter.fileKind === "index" ? FolderOpenImg : FolderImg}/>
                  <span>{intl.get("file_manager.fileKind.index")}</span>
                </div>
                <div className={"folder-wrapper"} onClick={() => onFileKindChanged('product')}>
                  <img className="folder-image" src={filter.fileKind === "product" ? FolderOpenImg : FolderImg}/>
                  <span>{intl.get("file_manager.fileKind.product")}</span>
                </div>
                <div className={"folder-wrapper"} onClick={() => onFileKindChanged('etc')}>
                  <img className="folder-image" src={filter.fileKind === "etc" ? FolderOpenImg : FolderImg}/>
                  <span>{intl.get("file_manager.fileKind.etc")}</span>
                </div>
              </div>
              {/* 1레벨 디렉토리 또는 파일 */}
              <div style={{flexGrow: 1}}>
                <div style={{textAlign: "left", width: "100%", marginBottom: "5px"}}>
                  <Button type="default" size="small" disabled={!filter.fileKind} onClick={() => setNewFolderVisible(true)}>{intl.get("common.button.newFolder")}</Button>
                  <Divider type="vertical" />
                  <Button type="default" size="small" disabled={firstLevelFileInfo.selectedFiles.length === 0} onClick={() => deleteFiles(1)}>{intl.get("common.button.delete")}</Button>
                  <Divider type="vertical" />
                  <Button type="default" size="small" disabled={!filter.fileKind} onClick={() => showUploadFile(1)}>{intl.get("common.button.upload")}</Button>
                </div>
                <Table size={'small'}
                       dataSource={firstLevelFileInfo.files}
                       columns={fileColumns}
                       rowKey={'key'}
                       pagination={false}
                       scroll={{ y: 400 }}
                       rowSelection={{
                         selectedRowKeys: firstLevelFileInfo.selectedFiles,
                         onChange: onFileSelectChange1
                       }}
                />
                <div style={{textAlign: "center", marginTop: "10px"}}>
                  <Button type="default" size="small" disabled={firstLevelFileInfo.page === 1}
                          onClick={() => loadFiles(1, firstLevelFileInfo.page - 1, firstLevelFileInfo.prevMarker)}>
                    Prev
                  </Button>
                  <Divider type="vertical"/>
                  <Button type="default" size="small" disabled={firstLevelFileInfo.nextMarker === ""}
                          onClick={() => loadFiles(1, firstLevelFileInfo.page + 1, firstLevelFileInfo.nextMarker)}>
                    Next
                  </Button>
                </div>
              </div>
              {/* 2레벨 디렉토리 또는 파일 */}
              <div style={{flexGrow: 1}}>
                <div style={{textAlign: "right", width: "100%", marginBottom: "5px"}}>
                  <Button type="default" size="small" disabled={secondLevelFileInfo.selectedFiles.length === 0} onClick={() => deleteFiles(2)}>{intl.get("common.button.delete")}</Button>
                  <Divider type="vertical" />
                  <Button type="default" size="small" disabled={!filter.directory} onClick={() => showUploadFile(2)}>{intl.get("common.button.upload")}</Button>
                </div>
                <Table size={'small'}
                       dataSource={secondLevelFileInfo.files}
                       columns={fileColumns}
                       rowKey={'key'}
                       pagination={false}
                       scroll={{ y: 400 }}
                       rowSelection={{
                         selectedRowKeys: secondLevelFileInfo.selectedFiles,
                         onChange: onFileSelectChange2
                       }}
                />
                <div style={{textAlign: "center", marginTop: "10px"}}>
                  <Button type="default" size="small" disabled={secondLevelFileInfo.page === 1}
                          onClick={() => loadFiles(2, secondLevelFileInfo.page - 1, secondLevelFileInfo.prevMarker)}>
                    Prev
                  </Button>
                  <Divider type="vertical"/>
                  <Button type="default" size="small" disabled={secondLevelFileInfo.nextMarker === ""}
                          onClick={() => loadFiles(2, secondLevelFileInfo.page + 1, secondLevelFileInfo.nextMarker)}>
                    Next
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <Modal
              title={intl.get("file_manager.uploadModal.title")}
              visible={uploadInfo.uploadVisible}
              onOk={handleUploadOk}
              onCancel={handleUploadCancel}
              confirmLoading={loading}
              footer={
                uploadInfo.uploadResult.length === 0 ? [
                  <Button key="cancel" onClick={handleUploadCancel}>Cancel</Button>,
                  <Button key="ok" type="primary" loading={loading} onClick={handleUploadOk}>OK</Button>
                ] : [
                  <Button key="cancel" type="primary" onClick={handleUploadCancel}>OK</Button>
                ]
              }
          >
            <div>
              <h2>{filter.fileKind + (uploadInfo.uploadParentPath ? "/" + uploadInfo.uploadParentPath : "")} {intl.get("file_manager.uploadModal.title")}</h2>
              <p style={{color: "red"}}>업로드 된 파일은 공개된 스토리지에 업로드 됩니다. 보안에 민감한 정보나 공개되어서는 안되는 파일은 업로드 하지 마세요.</p>
              <p>상점당 전체 용량 제한은  1GB 입니다. 용량 증설이 필요하면 서비스 관리자에게 문의하세요.</p>
              <p>한 파일의 최대 크기는 3MB 입니다.</p>
              <p>한국, 중국 사이의 네트워크 상황이 좋지 않은 경우 타임아웃이 발생할 수 있습니다. 운영자에게 메일로 파일을 보내주시면 올려드리겠습니다.</p>
            </div>
            <Divider dashed />
            {uploadInfo.uploadResult.length > 0 && (
                <div style={{marginTop: 20, marginBottom: 20}}>
                  {uploadInfo.uploadResult.map((result, index) => (
                      <div key={index}>
                        <Typography.Text type={result.success ? "success" : "danger"}>
                            {result.fileName}{result.success ? " success" : " error => " + result.message}
                        </Typography.Text>
                      </div>
                  ))}
                  <Divider dashed />
                </div>
            )}
            <div>
              <Upload beforeUpload={handleFileBeforeUpload}
                      onRemove={handleFileRemoved}
                      multiple={true}
                      fileList={uploadInfo.uploadFileList}
              >
                <Button>
                  <UploadOutlined /> Select File
                </Button>
              </Upload>
            </div>
          </Modal>

          <Modal title=""
                 width={800}
                 visible={imageDetailVisible}
                 onOk={handleImageDetailOk}
                 onCancel={handleImageDetailCancel}
                 footer={[(<Button key="ok" type="primary" onClick={handleImageDetailOk}>Ok</Button>)]}
          >
            <div>
              <div style={{marginTop: "20px"}}>{selectedImage}</div>
              <div style={{marginTop: 10}}>
                <img src={selectedImage} width={700}/>
              </div>
            </div>
          </Modal>
          <Modal title={intl.get("common.button.newFolder")}
                 width={500}
                 visible={newFolderVisible}
                 onOk={createFolder}
                 onCancel={() => {setNewFolderVisible(false)}}
          >
            <div>
              <Input value={newFolderName}
                     onChange={(e) => {setNewFolderName(e.target.value)}}/>
            </div>
          </Modal>
        </Spin>
      </div>
  )
}

export default FileList;