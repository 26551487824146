import {Layout, Modal, Descriptions, Typography} from "antd";
import {NotificationOutlined} from "@ant-design/icons";
import React from "react";
import intl from "react-intl-universal";
import NoticeApi from "./NoticeApi";
import Util from "../../util/Util";

const {Content} = Layout;

const NoticeModal = (props) => {
  const downloadFile = (fileIndex) => {
    NoticeApi.getFilePath(props.notice.id, fileIndex).then(res => {
      console.log("decoded:", decodeURIComponent(res.data));
      let aTag = document.createElement('a');
      aTag.href = decodeURIComponent(res.data);
      aTag.download = props.notice.fileNames[fileIndex];
      aTag.target = "_blank";
      aTag.click();
    }).catch(e => {
    });
  }

  const {notice} = props;
  const contents = notice.contents.split("\n");

  return (
    <Modal
      title={[<div key={1}><NotificationOutlined/> {intl.get("notice")}</div>]}
      visible={true}
      onCancel={props.handleCancel}
      footer={null}
      width={900}
      bodyStyle={{margin: 0, padding: 0}}
    >
      <Layout>
        <Content className="modal-content">
          <div style={{marginTop: "10px"}}>
            <Descriptions bordered size={"small"} column={1}>
              <Descriptions.Item label={intl.get("notice.title")}>
                {notice.title}
              </Descriptions.Item>
              <Descriptions.Item label={intl.get("common.createdAt")}>
                {Util.getLocalTime(notice.updatedAt).substring(0, 10)}
              </Descriptions.Item>
              <Descriptions.Item label={intl.get("notice.contents")}>
                <div style={{height: 400, overflowY: "scroll"}}>
                  <Typography.Paragraph>
                    {contents.map((str, index) => {
                      return (<div key={index}>{str}</div>)
                    })}
                  </Typography.Paragraph>
                </div>
              </Descriptions.Item>
              <Descriptions.Item label={intl.get("notice.files")}>
                {
                  notice.files && notice.files.map((file, index) => {
                    return (<div key={index}><a onClick={e => {downloadFile(index);}}>{notice.fileNames[index]}</a></div>)
                  })
                }
              </Descriptions.Item>
            </Descriptions>
          </div>
        </Content>
      </Layout>
    </Modal>
  )
};

export default NoticeModal;