import React, {useEffect, useState} from "react";
import {Spin, Button, Steps, message} from "antd";
import OrderApi from "../OrderApi";
import intl from "react-intl-universal";

const { Step } = Steps;

const DeliveryStatus = (props) => {
  const [updating, setUpdating] = useState(false);
  const [deliverySteps, setDeliverySteps] = useState([]);
  const [showDeliverySteps, setShowDeliverySteps] = useState(false);

  const showDeliveryStatus = () => {
    setUpdating(true);
    OrderApi.getDeliveryStatus(props.order.id).then(res => {
      const deliveryStatus = res.data;
      if (deliveryStatus.currentState === "错误") {
        message.error("배송정보 조회 중 오류가 발생했습니다. 관리자에게 문의하세요.");
        return;
      }
      let steps = []
      if (deliveryStatus.data) {
        steps = deliveryStatus.data.map((item) => {
          return {
            text: item.time,
            desc: item.context,
          }
        });
        setDeliverySteps(steps);
      }
      setShowDeliverySteps(true);
    }).catch(e => {
    }).finally(() => {
      setUpdating(false);
    });
  };

  const {order} = props;
  return (
    <Spin spinning={updating}>
      <div>
        <div style={{display: "flex", marginBottom: 10}}>
          <div>
            { order.orderDelivery.deliveryNo
              ? (<div><h3>{intl.get("order.deliveryNo")}: {order.orderDelivery.deliveryCompany}, {order.orderDelivery.deliveryNo}</h3></div>)
              : (<div>{intl.get("order.noDeliveryNo")}</div>)
            }
          </div>
          <div style={{marginLeft: "auto"}}>
            <Button type="primary" disabled={!order.orderDelivery.deliveryNo} size="small" onClick={showDeliveryStatus}>
              {intl.get("order.delivery.tracking")}
            </Button>
          </div>
        </div>
        <div>
          {showDeliverySteps && (
            <div>
              <div style={{height: 400, overflowY: "scroll"}}>
                {deliverySteps.length > 0 && (
                  <Steps size={"small"} progressDot current={0} direction="vertical">
                    {
                      deliverySteps.map((eachStep, index) => {
                        return (<Step key={index} title={eachStep.text} description={eachStep.desc}/>)
                      })
                    }
                  </Steps>
                )}

                {deliverySteps.length === 0 && (<div>{intl.get("order.delivery.noTracking")}</div>)}
              </div>
            </div>
          )}
        </div>
      </div>
    </Spin>
  )
};

export default DeliveryStatus;