import axios from "axios";
import {adminConfig} from "../../config/admin.config";

const API_BASE_URL = adminConfig.apiServer();

export default class OrderApi {
  static getOrder = (orderId) => {
    const apiPath = `${API_BASE_URL}/orders/${orderId}?projection=webAdmin`;
    return axios.get(apiPath);
  };

  static getOrders = (params) => {
    let apiPath = `${API_BASE_URL}/orders?`;

    let paramKeyValues = []
    Object.keys(params).forEach(key => {
      const paramValue = params[key];
      if (Array.isArray(paramValue)) {
        paramKeyValues = paramKeyValues.concat(paramValue.map(v => key + "=" + v));
      } else {
        paramKeyValues.push(key + "=" + paramValue)
      }
    });
    apiPath += paramKeyValues.join("&");
    if (!params.projection) {
      apiPath += "&projection=webAdmin";
    }
    return axios.get(apiPath);
  };

  static download = (options) => {
    let apiPath = `${API_BASE_URL}/orders/download?projection=webAdmin&`;

    apiPath += Object.keys(options).map(key => {
      return key + "=" + options[key]
    }).join("&")

    return axios.get(apiPath, {
      responseType: 'arraybuffer'
    });
  }

  static prepareOrder = (params) => {
    const apiPath = `${API_BASE_URL}/orders/prepare?projection=webAdmin`;
    return axios.post(apiPath, params);
  };

  static createOrder = (order) => {
    const apiPath = `${API_BASE_URL}/orders?projection=webAdmin`;
    return axios.post(apiPath, order);
  };

  static saveOrder = (order) => {
    const apiPath = `${API_BASE_URL}/orders/${order.id}?projection=webAdmin`;
    return axios.put(apiPath, order);
  };

  static getOrderPayment = (orderId) => {
    const apiPath = `${API_BASE_URL}/orders/${orderId}/payment?projection=webAdmin`;
    return axios.get(apiPath);
  }

  static getOrderPaymentHistories = (orderId) => {
    const apiPath = `${API_BASE_URL}/orders/${orderId}/payment-histories?projection=webAdmin`;
    return axios.get(apiPath);
  }

  static uploadOrderDeliveries = (orderDeliveries) => {
    const apiPath = `${API_BASE_URL}/deliveries/upload?projection=webAdmin`;
    return axios.post(apiPath, orderDeliveries);
  }

  static deleteOrder = (orderId) => {
    const apiPath = `${API_BASE_URL}/orders/${orderId}?projection=webAdmin`;
    return axios.delete(apiPath);
  }

  static callTestPaymentCallback = (orderId) => {
    const apiPath = `${API_BASE_URL}/payments/test-callback?orderId=${orderId}&projection=webAdmin`;
    return axios.put(apiPath);
  }

  static getDeliveryStatus = (orderId) => {
    const apiPath = `${API_BASE_URL}/orders/${orderId}/delivery-status?projection=webAdmin`;
    return axios.get(apiPath);
  }

  static callTest179 = (message) => {
    const apiPath = `${API_BASE_URL}/customs/platDataOpen`;
    return axios.post(apiPath, message);
  }

  static fetchWeimobOrder = (orderNoArray) => {
    const apiPath = `${API_BASE_URL}/orders/weimob`;
    const params = {
      orders: orderNoArray
    };
    return axios.post(apiPath, params);
  }

  static downloadDownDeclare = (options) => {
    let apiPath = `${API_BASE_URL}/orders/download-order-declares?`;
    const paramKeyValues = []
    Object.keys(options).forEach(key => {
      if (options[key]) {
        paramKeyValues.push(key + "=" + options[key])
      }
    });
    apiPath += paramKeyValues.join("&");
    apiPath += "&projection=webAdmin";
    return axios.get(apiPath, {
      responseType: 'arraybuffer'
    });
  }

  static uploadOrders = (orders) => {
    const apiPath = `${API_BASE_URL}/orders/upload`;
    return axios.post(apiPath, orders);
  }

  static sentNotification = (orderId) => {
    const apiPath = `${API_BASE_URL}/orders/${orderId}/send-noti`;
    return axios.put(apiPath);
  }

  static refund = (orderId) => {
    const apiPath = `${API_BASE_URL}/orders/${orderId}/refund`;
    return axios.put(apiPath);
  }

  static getRefundStatus = (orderId) => {
    const apiPath = `${API_BASE_URL}/orders/${orderId}/refund-status`;
    return axios.put(apiPath);
  }

  static saveMarker = (orderId, params) => {
    const apiPath = `${API_BASE_URL}/orders/${orderId}/marker`;
    return axios.put(apiPath, params);
  }
}