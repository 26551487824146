import { adminConfig } from "../../config/admin.config";
import axios from "axios";
const API_BASE_URL = adminConfig.apiServer();

export default class CustomsUploadApi {
  static getCustomsUploads = (params) => {
    let apiPath = `${API_BASE_URL}/customs?projection=webAdmin&`;

    if (params) {
      apiPath += Object.keys(params).map(key => {
        return key + "=" + params[key]
      }).join("&");
    }

    return axios.get(apiPath);
  };
}