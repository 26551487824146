import axios from "axios";
import {adminConfig} from "../../config/admin.config";

const API_BASE_URL = adminConfig.apiServer();

export default class CompanyApi {
  static getCompanies = (params) => {
    let apiPath = `${API_BASE_URL}/companies?projection=webAdmin&`;

    const paramKeyValues = []
    Object.keys(params).forEach(key => {
      if (params[key]) {
        paramKeyValues.push(key + "=" + params[key])
      }
    });
    apiPath += paramKeyValues.join("&")

    return axios.get(apiPath);
  };

  static getCompany = (companyId) => {
    const apiPath = `${API_BASE_URL}/companies/${companyId}?projection=webAdmin`;
    return axios.get(apiPath);
  }

  // static getCompanyByDomain = (adminDomain) => {
  //   const apiPath = `${API_BASE_URL}/companies/domain?projection=webAdmin&domain=${adminDomain}`;
  //   return axios.get(apiPath);
  // }

  static updateCompany = (company) => {
    let apiPath = `${API_BASE_URL}/companies/${company.id}?projection=webAdmin`;
    return axios.put(apiPath, company);
  }

  static createCompany = (company) => {
    let apiPath = `${API_BASE_URL}/companies?projection=webAdmin`;
    return axios.post(apiPath, company);
  }

  static getDefaultCompanyId = () => {
    let apiPath = `${API_BASE_URL}/companies/default-id?projection=webAdmin`;
    return axios.get(apiPath);
  }

  static getInviteCode = (companyId) => {
    const apiPath = `${API_BASE_URL}/companies/${companyId}/invite-code?projection=webAdmin`;
    return axios.post(apiPath);
  }
}