import React, {useState} from "react";
import {
  Button, Divider,
  Spin, Input, Checkbox
} from "antd";
import ProductApi from "./ProductApi";
import JSONPretty from "react-json-pretty";

const InterlogisStock = (props) => {
  const [saving, setSaving] = useState(false);
  const [productId, setProductId] = useState("");
  const [page, setPage] = useState("");
  const [pageSize, setPageSize] = useState("");
  const [checkData, setCheckData] = useState(false);
  const [stockRes, setStockRes] = useState({});

  const checkDataChanged = (e) => {
    setCheckData(e.target.checked);
  };

  const getStocks = () => {
    setSaving(true);
    ProductApi.getInterlogisStocks({
      productId: productId,
      checkData: checkData,
      page: page,
      pageSize: pageSize,
    }).then(res => {
      setStockRes(res.data);
    }).finally(() => {
      setSaving(false);
    })
  };

  return (
    <Spin spinning={saving}>
      <div className="page-op-box">
        <div className="filter-box">
          <Input placeholder={"ProductID"} style={{width: 100}} onChange={(e) => setProductId(e.target.value)}/>
          <Divider type="vertical"/>
          <Input placeholder={"페이지"} style={{width: 100}} onChange={(e) => setPage(e.target.value)}/>
          <Divider type="vertical"/>
          <Input placeholder={"건수"} style={{width: 100}} onChange={(e) => setPageSize(e.target.value)}/>
          <Divider type="vertical"/>
          <Checkbox checked={checkData} onChange={checkDataChanged}>JSON 데이터 확인만하기</Checkbox>
          <div style={{marginLeft: "auto"}}>
            <Button type="primary" onClick={getStocks}>재고정보 가져오기</Button>
          </div>
        </div>
      </div>
      <div style={{marginTop: "10px"}}>
        <div>
          <ul>
            <li>조회 SKU 수(인터로지스): {stockRes.numFetched}</li>
            <li>처리 SKU 수(요우마): {stockRes.numUpdated}</li>
          </ul>
        </div>
        {stockRes.resBody && (
          <div style={{
            marginTop: 20,
            fontSize: "11px",
            overflow: "scroll",
            wordBreak: "break-all",
            wordWrap: "break-word",
            width: "100%",
            height: "400px",
            border: "1px solid #ededed",
            marginRight: 20}}
          >
            <JSONPretty id={"json-prepay-res"} data={stockRes.resBody}></JSONPretty>
          </div>
        )}
      </div>
    </Spin>
  );
}

export default InterlogisStock;