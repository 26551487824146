import {adminConfig} from "../../config/admin.config";
import { HttpUtil } from '../../util/HttpUtil';
import axios from "axios";

const API_BASE_URL = adminConfig.apiServer();

export default class BatchJobApi {
  static updateProductStatistics = () => {
    let apiPath = `${API_BASE_URL}/products/statistics?projection=webAdmin&`;
    return axios.put(apiPath);
  }
}