import React, {useEffect, useState} from "react";
import {Descriptions, Form, Input, Select, Button, Modal, Layout} from "antd";
import intl from "react-intl-universal";
import {SettingOutlined} from "@ant-design/icons";
import DeliveryStatus from "./clearance/DeliveryStatus";
import ApplicationLogisticsCompanyApi from "../application_logistics_company/ApplicationLogisticsCompanyApi";

const {Content} = Layout;

const OrderEditDelivery = (props) => {
  const [orderDelivery, setOrderDelivery] = useState({});
  const [visibleDeliveryModal, setVisibleDeliveryModal] = useState(false);
  const [logisticsCompanyOptions, setLogisticsCompanyOptions] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    const order = JSON.parse(JSON.stringify(props.order));
    if (order.orderDelivery) {
      setOrderDelivery(order.orderDelivery);
      form.setFieldsValue(order.orderDelivery);
    }
    loadApplicationLogisticsCompanies()

  }, [props.order.id]);

  const loadApplicationLogisticsCompanies = () => {
    ApplicationLogisticsCompanyApi.getApplicationLogistics().then(res => {
      // console.log('getApplicationLogistics.res :>> ', res.data);
      const options = res.data.map(item => {
        return {
          code: item.logisticsCompany.code,
          name: item.logisticsCompany.name,
        }
      })
      setLogisticsCompanyOptions(options)
    })
  }

  const onValuesChange = (values) => {
    const orderDeliveryState = {...orderDelivery};
    Object.assign(orderDeliveryState, values);
    setOrderDelivery(orderDeliveryState);
    props.setOrderAssociatedValue("orderDelivery", orderDeliveryState);
  }

  return (
      <div>
        <Form form={form} onValuesChange={onValuesChange}>
          <Descriptions bordered size={"small"} column={1}>
            <Descriptions.Item label={intl.get("order.delivery.logisticsCompany")}>
              <Form.Item name={"deliveryCompany"} required={true}>
                <Select style={{width: 300}}>
                  {/* <Select.Option key="YTO" value="YTO">圆通（1210）</Select.Option>
                  <Select.Option key="YTG" value="YTG">通达（9610）</Select.Option>
                  <Select.Option key="YUNDA" value="YUNDA">韵达</Select.Option>
                  <Select.Option key="SF" value="SF">顺丰速运</Select.Option> */}
                  {logisticsCompanyOptions.map(o => <Select.Option key={o.code} value={o.code}>{o.name}</Select.Option>)}
                </Select>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("order.delivery.logisticsNo")}>
              <Form.Item name={"deliveryNo"} required={true} noStyle>
                <Input style={{width: 300}}/>
              </Form.Item>
              <span style={{marginLeft: 10}}>
                <Button onClick={() => setVisibleDeliveryModal(true)}>
                  {intl.get("order.delivery.tracking")}
                </Button>
              </span>
            </Descriptions.Item>
          </Descriptions>
        </Form>
        {visibleDeliveryModal && (
          <Modal
            title={[<div key={1}><SettingOutlined/> {intl.get("order.delivery.tracking")}</div>]}
            visible={true}
            onCancel={() => setVisibleDeliveryModal(false)}
            onOk={() => setVisibleDeliveryModal(false)}
            cancelButtonProps={{ style: { display: 'none' } }}
            width={800}
            bodyStyle={{height: 500, margin: 0, padding: 0}}
          >
            <Layout>
              <Content className="modal-content">
                <DeliveryStatus order={props.order}/>
              </Content>
            </Layout>
          </Modal>
        )}
      </div>
  )
};

export default OrderEditDelivery;