import React, {useEffect, useState, useCallback} from 'react';
import { Switch, Route } from 'react-router-dom';
import { useLayoutState } from "../../components/layout/AppLayoutContext";
import OrderList from './OrderList';
import OrderEdit from './OrderEdit';
import dayjs from "dayjs";
import OrderApi from "./OrderApi";
import UserApi from "../user/UserApi";
import "./Order.less";
import {message} from "antd";
import { saveAs } from 'file-saver';
import intl from "react-intl-universal";
import ApplicationApi from "../application/ApplicationApi";
import ClearanceApi from "./clearance/ClearanceApi";
import ProductApi from "../product/ProductApi";

const initialtPagination = {
  current: 1,
  pageSize: 20,
  total: 0,
}

const initialFilter = {
  // nameType: "",
  // name: '',
  noType: '',
  targetNo: '',
  published: true,
  brandId: '',
  categoryId: '',
  subCategoryId: '',
  durationType: 'order',
  startDate: dayjs().subtract(30, "day").format("YYYY-MM-DD"),
  endDate: dayjs().format("YYYY-MM-DD"),
};

const Order = (props) =>  {
  const layoutState = useLayoutState();

  const [application, setApplication] = useState({});
  const [weimobApp, setWeimobApp] = useState({});
  const [parentPath, setParentPath] = useState("");
  const [pagination, setPagination] = useState(initialtPagination);
  const [filter, setFilter] = useState(initialFilter);
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    setParentPath(props.match.path);
  }, [props.match.path]);

  useEffect(() => {
    setApplication(layoutState.application);
    if (layoutState.application.id) {
      loadWeimobApp(layoutState.application.id);
    }
  }, [layoutState.application]);

  const loadWeimobApp = (appId) => {
    ApplicationApi.getWeimobApp(appId).then(res => {
      setWeimobApp(res.data);
    });
  };

  const searchOrders = (pagination, filterParam=null) => {
    // console.log('filter :>> ', filter);
    setLoading(true);
    const params = getSearchParam(pagination, filterParam);
    // console.log('params :>> ', params);
    if (params.managerMarkerColor) {
      params.managerMarkerColor = encodeURIComponent(params.managerMarkerColor);
    }
    OrderApi.getOrders(params).then(res => {
      const pagingResult = res.data;
      const pagination = {
        current: pagingResult.page,
        pageSize: pagingResult.pageSize,
        total: pagingResult.count,
      }
      const ordersRes = pagingResult.data;
      setOrders(ordersRes);
      setPagination(pagination);
      setLoading(false);
      loadSellerUsers(ordersRes);
      loadOrderDeclares(ordersRes);
      getEventOriginProducts(ordersRes);
    }).finally(() => {
     setLoading(false);
    });
  };

  // event 상품인 경우 원본 상품을 가져와서 SKU의 원본 상품명을 표시해 준다.
  const getEventOriginProducts = (orders) => {
    let eventOriginProductIds = [];
    orders.forEach(o => {
      eventOriginProductIds = eventOriginProductIds.concat(...o.orderSkus.filter(sku => sku.eventProduct).map(sku => sku.eventOriginProductId));
    });
    if (eventOriginProductIds.length === 0) {
      return;
    }
    const ordersState = JSON.parse(JSON.stringify(orders));

    ProductApi.getProducts({
      id: eventOriginProductIds,
      pageSize: eventOriginProductIds.length
    }).then(res => {
      const eventOriginProductMap = {};
      res.data.data.forEach(p => {
        eventOriginProductMap[p.id] = p;
      });

      const ordersWithEventOrigin = ordersState.map(o => {
        const orderSkus = o.orderSkus.map(sku => {
          if (sku.eventProduct) {
            const op = eventOriginProductMap[sku.eventOriginProductId];
            if (op) {
              sku.eventOriginProductName = op.name;
            }
            return sku;
          }
          return sku;
        });
        o.orderSkus = orderSkus;
        return o;
      });
      setOrders(ordersWithEventOrigin);
    });
  };

  const getSearchParam = (pagination, filterParam=null) => {
    const targetFilter = filterParam ? filterParam : filter;
    return {
      pageSize: pagination.pageSize,
      page: pagination.current,
      ...targetFilter
    };
  };

  const loadSellerUsers = (orders) => {
    const sellerUserIds = [];
    orders.forEach(order => {
      const skuUserIds = order.orderSkus.filter(os => os.sellerUserId !== 0).map(os => os.sellerUserId);
      if (skuUserIds.length > 0) {
        sellerUserIds.push(...skuUserIds);
      }
    });

    if (sellerUserIds.length === 0) {
      return;
    }

    UserApi.getUsers({id: sellerUserIds}).then(res => {
      const sellerUsers = res.data.data;
      const ordersWithSeller = orders.map(o => {
        const orderSkus = o.orderSkus.map(os => {
          if (os.sellerUserId !== 0) {
            const seller = sellerUsers.find(su => su.id === os.sellerUserId);
            if (seller) {
              os.selllerUserName = seller.name;
            }
          }
          return os;
        });
        o.orderSkus = orderSkus;
        return o;
      });

      setOrders(ordersWithSeller)
    });
  };

  const loadOrderDeclares = (orders) => {
    if (orders.length === 0) {
      return;
    }
    const orderIds = orders.map(o => o.id);

    ClearanceApi.getOrdersDeclares(orderIds).then(res => {
      const orderDeclares = res.data;
      const ordersWithDelare = orders.map(o => {
        const od = orderDeclares.find(od => o.id === od.orderId);
        if (od) {
          o.orderDeclare = od;
        }
        return o;
      });

      setOrders(ordersWithDelare);
    });
  };

  const deleteOrder = (orderId) => {
    const order = orders.find(o => o.id === orderId);
    if (!order) {
      message.error(orderId + " 주문 정보를 찾을 수 없습니다.");
      return;
    }

    if (order.finishedStatus !== "failed") {
      message.error("주문 상태가 failed(실페)인 주문만 삭제 가능합니다. 주문 수정에서 상태를 변경해주세요.");
      return;
    }

    setLoading(true);
    OrderApi.deleteOrder(order.id).then(res => {
      message.success(intl.get("common.message.deleted"));
      searchOrders(pagination);
    }).finally(() => {
      setLoading(false);
    });
  };

  const setOrder = (changedOrder) => {
    const ordersState = [...orders];
    const changedOrders = ordersState.map(o => {
      if (o.id === changedOrder.id) {
        return changedOrder;
      } else {
        return o;
      }
    });
    setOrders(changedOrders);
  };

  const setFilterState = (filter, search=false) => {
    setFilter(filter);
    if (search) {
      searchOrders(pagination, filter)
    }
  };

  const setPaginationState = (pagination) => {
    setPagination(pagination);
  };

  const downloadExcel = () => {
    const params = {
      page: 1,
      pageSize: 1000,
      ...props.filter,
    }

    setLoading(true);
    OrderApi.download(params).then(res => {
      const header = res.headers["content-disposition"];
      const filename = header.match(/filename="(.+)"/)[1];
      const contentType = res.headers["content-type"];

      const blob = new Blob([res.data], { contentType });
      saveAs(blob, filename);
    }).catch(err => {
      console.log("download error:", err);
      message.error("다운로드 중 문제가 발생했습나다. 관리자에게 문의하새요.");
    }).finally(() => {
      setLoading(false);
    });
  };

  // NOTICE: path argument를 사용하기 위해서는 Route의 순서가 중요. new -> edit -> show 순서로 작성
  return (
      <div>
        <div className="page-content">
          <Switch>
            <Route path={props.match.path} exact
                   render={(props) =>
                       (<OrderList
                            orders={orders}
                            filter={filter}
                            loading={loading}
                            pagination={pagination}
                            parentPath={parentPath}
                            application={application}
                            weimobApp={weimobApp}
                            setFilter={setFilterState}
                            setPagination={setPaginationState}
                            searchOrders={searchOrders}
                            setOrder={setOrder}
                            downloadExcel={downloadExcel}
                            deleteOrder={deleteOrder}
                            {...props}/>)
                   }
            />

            <Route path={`${props.match.path}/:orderId`}
                   render={(props) =>
                       ( <OrderEdit orders={orders}
                                    setOrder={setOrder}
                                    parentPath={parentPath}
                                    application={application}
                                    {...props}/>)
                   }
            />
          </Switch>
        </div>
      </div>
  )
};

export default Order;