import React, {useEffect, useState} from 'react';
import {Switch, Route, useHistory} from 'react-router-dom';

import AdminNoticeList from './AdminNoticeList';
import NoticeEdit from "./NoticeEdit";
import NoticeApi from "./NoticeApi";

const NoticeManager = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });

  const [filter, setFilter] = useState({
    keyword: '',
  });
  const [notices, setNotices] = useState([]);
  const [parentPath, setParentPath] = useState("");

  useEffect(() => {
    searchNotices(pagination);
  }, []);

  useEffect(() => {
    setParentPath(props.match.path);
  }, [props.match.path]);

  const searchNotices = (pageinfo = pagination) => {
    setLoading(true);
    NoticeApi.getNotices(getSearchParam(pageinfo)).then(res => {
      const pagingResult = res.data;
      const pagination = {
        current: pagingResult.page,
        pageSize: pagingResult.pageSize,
        total: pagingResult.count,
      }
      setNotices(pagingResult.data);
      setPagination(pagination);
    }).finally(() => {
      setLoading(false);
    });
  };

  const getSearchParam = (pagination) => {
    const params = {
      pageSize: pagination.pageSize,
      page: pagination.current,
      ...filter
    };
    return params;
  };

  const setFilterState = (filter) => {
    setFilter(JSON.parse(JSON.stringify(filter)));
  };

  const setPaginationState = (pagination) => {
    setPagination(JSON.parse(JSON.stringify(pagination)));
  };

  const setNotice = (notice) => {
    const noticesState = notices.map(n => {
      if (n.id === notice.id) {
        return notice;
      } else {
        return n;
      }
    });
    setNotices(noticesState);
  };

  // NOTICE: path argument를 사용하기 위해서는 Route의 순서가 중요. new -> edit -> show 순서로 작성
  return (
      <div>
        <div className="page-content">
          <Switch>
            <Route path={props.match.path} exact
                   render={(props) => (
                     <AdminNoticeList
                       notices={notices}
                       pagination={pagination}
                       filter={filter}
                       loading={loading}
                       setFilter={setFilterState}
                       setPagination={setPaginationState}
                       searchNotices={searchNotices}
                       setNotice={setNotice}
                       parentPath={parentPath}
                       {...props}/>
                   ) } />
            />
            <Route path={`${props.match.path}/new`}
                   render={(props) => (
                     <NoticeEdit parentPath={parentPath} {...props}/>
                   ) } />
            <Route path={`${props.match.path}/:id`}
                   render={(props) => (
                     <NoticeEdit parentPath={parentPath} {...props}/>
                   )} />
          </Switch>
        </div>
      </div>
  )
};

export default NoticeManager