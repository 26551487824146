import React, {useCallback, useEffect, useState} from "react";
import {Button, Input, Select, Form, Descriptions, message, Table, Tabs, Popconfirm, Divider} from "antd";
import CompanyUserApi from './CompanyUserApi';
import UserApi from './../user/UserApi';
import Util from "../../util/Util";
import {MemberContext} from "../../auth/member.context";
import intl from "react-intl-universal";
import UserListPopUp from "../user/UserListPopup";
import ProductApi from "../product/ProductApi";

const FormItem = Form.Item;
const { TabPane } = Tabs;

const CompanyUserEdit = (props) => {
  const { companyUser } = props;
  const [shopUserName, setShopUserName] = useState("");
  const [visibleUserPopup, setVisibleUserPopup] = useState(false);
  const [selectedAppID, setSelectedAppID] = useState(0);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSuppliers, setSelectedSuppliers] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    const cUser = JSON.parse(JSON.stringify(companyUser));
    // setEditCompanyUser(cUser);
    if (companyUser.shopUserId) {
      getShopUser(companyUser.shopUserId);
    } else {
      setShopUserName("");
    }
    loadSuppliers();
    form.setFieldsValue(cUser);

    if (companyUser.managingSuppliers) {
      setSelectedSuppliers(companyUser.managingSuppliers.map(s => s.supplier));
    } else {
      setSelectedSuppliers([]);
    }
  },[companyUser]);

  const loadSuppliers = () => {
    ProductApi.getSuppliers().then(res => {
      setSuppliers(res.data);
    });
  };

  const getShopUser = useCallback((shopUserId) => {
    UserApi.getUser(shopUserId).then(res => {
      setShopUserName(res.data.name);
    });
  }, [shopUserName]);

  const submitUser = (values) => {
    const formUser = JSON.parse(JSON.stringify(values));
    formUser.managingSuppliers = selectedSuppliers.map(supplier => {
      return {supplier: supplier}
    });
    if (!companyUser.id) {
      CompanyUserApi.createUser(formUser).then(res => {
        userSaved(res.data);
      });
    } else {
      if (formUser.companyId) {
        formUser.companyId = parseInt(formUser.companyId);
      }
      CompanyUserApi.saveUser(formUser).then(res => {
        userSaved(res.data);
      });
    }
  }

  const userSaved = (savedUser) => {
    message.success(intl.get("common.message.saved"));
    form.setFieldsValue(savedUser);
    if (props.setCompanyUser) {
      props.setCompanyUser(savedUser);
    }
  };

  const handleSupplierChange = (values) => {
    setSelectedSuppliers(values);
  };

  const handleSavePassword = () => {
    // const {password, passwordConfirm, user} = this.state;
    // if (!companyUser.id) {
    //   message.error("신규 생성인 경우 사용자 정보 등록 후 패스워드를 변경하세요.");
    //   return;
    // }
    //
    // if (!password || password.length < 6) {
    //   message.error("패스워드는 최소 6자리 이상이라야 합니다.");
    //   return;
    // }
    //
    // if (password !== passwordConfirm) {
    //   message.error("패스워드 확인값이 일치하지 않습니다.");
    //   return;
    // }
    // CompanyUserApi.changePassword(companyUser.id, password).then(res => {
    //   message.success("저장되었습니다.");
    //   setVisiblePasswordInput(false);
    // });
  };

  const deleteUser = () => {
    props.deleteCompanyUser(companyUser);
  };

  const removeAppRole = (applicationRoleId) => {
    CompanyUserApi.removeAppRole(companyUser.id, applicationRoleId).then(res => {
      userSaved(res.data);
    });
  };

  const addAppRole = () => {
    if (!selectedAppID) {
      message.error(intl.get("company.add.select.application"));
      return;
    }
    CompanyUserApi.addAppRole(companyUser.id, selectedAppID, companyUser.userType).then(res => {
      userSaved(res.data);
    });
  }

  const onSellerUserSelected = useCallback((user) => {
    const values = form.getFieldsValue();
    values.shopUserId = user.id;
    form.setFieldsValue(values);
    setShopUserName(user.name);
    setVisibleUserPopup(false);
  }, [visibleUserPopup]);

  const applicationColumns = [{
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    }, {
      title: intl.get("application.name"),
      dataIndex: 'appName',
      key: 'appName',
      render: (text, record) => (
          <span>{record.application.name}</span>
      )
    }, {
      title: intl.get("application.role"),
      dataIndex: 'role',
      key: 'role',
      render: (text, record) => (
          <span>{intl.get("company.user.userType." + record.role)}</span>
      )
    }, {
      title: intl.get("common.createdAt"),
      dataIndex: 'id',
      key: 'createdAt',
      render: (text, record) => (
          <span>{Util.getLocalTime(record.createdAt).substring(0, 10)}</span>
      )
    }, {
      title: "",
      dataIndex: 'id',
      key: 'action',
      align: 'center',
      render: (text, record) => (
          <Popconfirm title={intl.get("common.confirm.delete", {name: intl.get("application.xiaochengxu")})} onConfirm={() => { removeAppRole(record.id)}}>
            <Button size="small">{intl.get("common.button.delete")}</Button>
          </Popconfirm>
      )
    }];

  // const newUser = !editCompanyUser.id;
  const newUser = !companyUser.id;

  return (
      <div>
        <Tabs defaultActiveKey="1" type="card" size={"small"}>
          <TabPane tab={intl.get("company.user.basicInfo")} key="1">
            <div>
              <Form onFinish={submitUser} form={form}>
                <Descriptions bordered size={"small"} column={2}>
                  <Descriptions.Item label={"ID"} span={2}>
                    <FormItem name={"id"}>
                      <Input disabled={true}/>
                    </FormItem>
                  </Descriptions.Item>
                  <Descriptions.Item label={intl.get("company.user.loginId")}>
                    <div style={{display: "flex"}}>
                      <FormItem name={"loginId"} rules={[{ required: true }]}>
                        <Input disabled={!newUser}/>
                      </FormItem>
                      {/*{!newUser && (*/}
                      {/*<div style={{marginLeft: 10}}>*/}
                      {/*  <Button onClick={() => setVisiblePasswordInput(true)}>{intl.get("company.user.changePassword")}</Button>*/}
                      {/*</div>*/}
                      {/*)}*/}
                   </div>
                  </Descriptions.Item>
                  <Descriptions.Item label={intl.get("company.user.name")}>
                    <FormItem name={"name"} rules={[{ required: true }]}>
                      <Input/>
                    </FormItem>
                  </Descriptions.Item>
                  <Descriptions.Item label={intl.get("company.user.email")}>
                    <FormItem name={"email"} rules={[{ required: true }]}>
                      <Input/>
                    </FormItem>
                  </Descriptions.Item>
                  <Descriptions.Item label={intl.get("company.user.phone")}>
                    <FormItem name={"phone"} rules={[{ required: false }]}>
                      <Input />
                    </FormItem>
                  </Descriptions.Item>
                  <Descriptions.Item label={intl.get("company.user.userType")} span={2}>
                    <FormItem name={"userType"} rules={[{ required: true }]}>
                      <Select style={{ width: 200, marginRight: 10 }}>
                        {MemberContext.memberInformation.userType === "admin" && (
                          <Select.Option key="admin" value="admin">{intl.get("company.user.userType.admin")}</Select.Option>
                        )}
                        <Select.Option key="companyManager" value="companyManager">{intl.get("company.user.userType.companyManager")}</Select.Option>
                        <Select.Option key="appManager" value="appManager">{intl.get("company.user.userType.appManager")}</Select.Option>
                        <Select.Option key="supplier" value="supplier">{intl.get("company.user.userType.supplier")}</Select.Option>
                        {props.isInterlogis && (
                          <Select.Option key="logistics" value="logistics">{intl.get("company.user.userType.logistics")}</Select.Option>
                        )}
                        <Select.Option key="draft" value="draft">{intl.get("company.user.userType.draft")}</Select.Option>
                      </Select>
                    </FormItem>
                  </Descriptions.Item>
                  <Descriptions.Item label={intl.get("company.user.shopUserId")} span={2}>
                    <div style={{display: "flex", alignItems: "center"}}>
                      <FormItem name={"shopUserId"} rules={[{ required: false }]}>
                        <Input style={{width: 80}} disabled={true}/>
                      </FormItem>
                      {shopUserName && (
                        <div style={{marginLeft: 10}}>{shopUserName}</div>
                       )}
                      <div style={{marginLeft: 20}}>
                        <Button onClick={() => setVisibleUserPopup(true)}>{intl.get("company.button.searchUser")}</Button>
                      </div>
                    </div>
                  </Descriptions.Item>
                  {Util.isAdmin() && (
                    <Descriptions.Item label={"회사ID"} span={2}>
                      <FormItem name={"companyId"} rules={[{ required: true }]}>
                        <Input style={{width: 80}}/>
                      </FormItem>
                    </Descriptions.Item>
                  )}
                  <Descriptions.Item label={intl.get("suppliers.product")} span={2}>
                    <Select
                      mode="multiple"
                      allowClear
                      style={{ width: '100%' }}
                      value={selectedSuppliers}
                      onChange={handleSupplierChange}
                    >
                      {suppliers.map(supplier => (
                        <Select.Option key={supplier} value={supplier}>{supplier}</Select.Option>
                      ))}
                    </Select>
                  </Descriptions.Item>
                  <Descriptions.Item label={"등록일"} span={2}>
                    {Util.getLocalTime(companyUser.createdAt)}
                  </Descriptions.Item>
                </Descriptions>
                <div style={{width: "100%", marginTop: 10, display: "flex", justifyContent: "center"}}>
                  {companyUser.id && (
                    <>
                      <Button type="primary" onClick={deleteUser}>{intl.get("common.button.delete")}</Button>
                      <Divider type="vertical"/>
                      </>
                  )}
                  <Button type="primary" htmlType="submit">{intl.get("common.button.save")}</Button>
                </div>
              </Form>
            </div>
          </TabPane>
          <TabPane tab={intl.get("company.user.setting.role")} key="2">
            {companyUser.id && (
              <div>
                <div style={{display: "flex", alignItems: "center"}}>
                    <Select style={{width: 200, marginRight: 20}} placeholder={intl.get("application.xiaochengxu")} onChange={(value) => setSelectedAppID(value)}>
                      {
                        props.applications.map(eachApp => {
                          return (<Select.Option key={eachApp.id} value={eachApp.id}>{eachApp.name}</Select.Option>)
                        })
                      }
                    </Select>
                    <Button size={"small"} type={"primary"} onClick={addAppRole}>{intl.get("common.button.add")}</Button>
                </div>
                <div style={{marginTop: 10}}>
                  <h4>{intl.get("company.add.application")}</h4>
                  <Table size={'small'}
                         dataSource={companyUser.applicationUserRoles}
                         columns={applicationColumns}
                         rowKey={'id'}
                         pagination={false}

                  />
                </div>
              </div>
            )}
            {companyUser.id && (<div style={{color: "red"}}>{intl.get("company.add.application.help")}</div>)}
          </TabPane>
        </Tabs>
        {/*<Modal*/}
        {/*    title="패스워드 변경"*/}
        {/*    visible={this.state.visiblePasswordInput}*/}
        {/*    onOk={this.handleSavePassword}*/}
        {/*    onCancel={() => {this.setState({password: "", passwordConfirm: "", visiblePasswordInput: false})}}>*/}
        {/*  <Descriptions bordered size={"small"} column={1}>*/}
        {/*    <Descriptions.Item label={"패스워드"}>*/}
        {/*      <Input.Password value={this.state.password} onChange={(e) => this.setState({password: e.target.value})}/>*/}
        {/*    </Descriptions.Item>*/}
        {/*    <Descriptions.Item label={"패스워드 확인"}>*/}
        {/*      <Input.Password value={this.state.passwordConfirm} onChange={(e) => this.setState({passwordConfirm: e.target.value})}/>*/}
        {/*    </Descriptions.Item>*/}
        {/*  </Descriptions>*/}
        {/*</Modal>*/}
        {visibleUserPopup && (
          <UserListPopUp
            onClickUser={onSellerUserSelected}
            handleCancel={() => setVisibleUserPopup(false)}/>
        )}
      </div>
  )
}

export default CompanyUserEdit;