import React, {useEffect, useState} from 'react';
import {Route, useHistory} from 'react-router-dom';
import {AuthService} from "../../auth/auth.service";
import {
  EventBroadcaster,
  MEMBER_CONTEXT_AVAILABLE_EVENT_TOPIC
} from "../../event/event.broadcaster";
import {adminConfig} from "../../config/admin.config";
import {useLayoutDispatch} from "../layout/AppLayoutContext";
import axios from "axios";
import {MemberContext} from "../../auth/member.context";

const API_URL = adminConfig.apiServer();

const AuthRoute = (props) => {
  const layoutDispatch = useLayoutDispatch();

  const [authenticated, setAuthenticated] = useState(false);
  const [memberContextAvailable, setMemberContextAvailable] = useState(false);

  const { component: Component, needUpdate, path, exact = false } = props;
  const checkAuth = async () => {
    // 브라우저에서 reload 하는 경우 메모리에 있는 Authorization 정보가 사라진다.
    // 이때 silentRefresh를 호출하여 accessToken을 다시 가져와서 Authorization를 설정한다.
    AuthService.silentRefresh().then((res) => {
      axios.get(API_URL + "/company-users/my").then(memberResponse => {
        memberResponse.data.lastUsedApplicationId = res.data.applicationId;
        MemberContext.memberInformation = memberResponse.data;
      });
    }).catch((err) => {
      console.log("AuthService.silentRefresh err:", err);
      window.location = "/login";
    }).finally(() => {
      setAuthenticated(true);
    });
  };

  useEffect(() => {
    EventBroadcaster.on(MEMBER_CONTEXT_AVAILABLE_EVENT_TOPIC, (memberInformation) => {
      // console.log(">>>>AuthRoute: MEMBER_CONTEXT_AVAILABLE_EVENT_TOPIC:", MemberContext.memberInformation)
      layoutDispatch({
        type: 'REFRESH_ALL_GNB_ITEMS'
      });
      setMemberContextAvailable(true);
    });
    checkAuth();
  }, []);

  return (
    <Route exact={exact}
           path={path}
           render={(p) => {
              if (authenticated && memberContextAvailable) {
                return (<Component needUpdate={needUpdate} serviceSetting={props.serviceSetting} {...p}/>)
              } else {
                return <div></div>
              }
            }
          }
    />);
};

export default AuthRoute;
