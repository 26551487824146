import React from "react";
import {Layout, Modal} from "antd";
import intl from "react-intl-universal";
import {SettingOutlined} from "@ant-design/icons";
import ProductDetailView from "./ProductDetailView";

const {Content} = Layout;

const ProductDetailViewPopup = (props) => {
  return (
    <Modal
      title={[<div key={1}><SettingOutlined/> {intl.get("products.searchModal.title")}</div>]}
      visible={true}
      onOk={() => props.onOk()}
      onCancel={() => props.onCancel()}
      width={"80%"}
      cancelButtonProps={{style: {display: 'none'}}}
      bodyStyle={{margin: 0, padding: 0}}>
      <Layout>
        <Content className="modal-content">
          <ProductDetailView
            productId={props.productId}
            application={props.application}
            brands={props.brands}
            categories={props.categories}
            webmallUrl={props.webmallUrl}
          />
        </Content>
      </Layout>
    </Modal>
  )
}

export default ProductDetailViewPopup;