import React, {useEffect, useState} from 'react';
import {Row, Col, Card, Select, message, Spin, Radio} from 'antd';
import DashboardApi from './DashboardApi';
import NumberComparingIndicator from './NumberComparingIndicator';
import NumberFormat from "react-number-format";
import Util from "../../util/Util";
import DashboardCard from './DashboardCard';
import intl from "react-intl-universal";
// import './dashboard.css';

const OrderSummary = (props) => {
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("price");
  const [orderSummary, setOrderSummary] = useState({});
  const [baseDate, setBaseDate] = useState("order");

  useEffect(() => {
    loadSalesSummary(baseDate);
  }, []);

  useEffect(() => {
    setMode(props.mode ? props.mode : "price");
  }, [props.mode]);

  const loadSalesSummary = (baseDate) => {
    setLoading(true);
    const options = {
      baseDate: baseDate,
    };

    DashboardApi.getOrderSummary(options).then(res => {
      setOrderSummary(res.data);
    }).catch(error => {
      message.error("에러가 발생했습니다.");
    }).finally(() => {
      setLoading(false);
    })
  };

  const onChangeBaseDate = (value) => {
    setBaseDate(value);
    loadSalesSummary(value);
  }

  const numberStyle = {
    fontSize: 68, lineHeight: '68px', fontWeight: 'bold', color: '#000000'
  };

  const dateSelector = (
      <div style={{display: "flex", alignItems: "center", gap: 5}}>
        <Radio.Group value={mode} onChange={(e) => setMode(e.target.value)}>
          <Radio value={"price"}>{intl.get("dashboard.orderAmount")}</Radio>
          <Radio value={"count"}>{intl.get("dashboard.orderCount")}</Radio>
        </Radio.Group>
        <Select value={baseDate} style={{ width: 200}} onChange={onChangeBaseDate}>
          <Select.Option key="order" value="order">{intl.get("dashboard.date.order")}</Select.Option>
          <Select.Option key="finish" value="finish">{intl.get("dashboard.date.finish")}</Select.Option>
        </Select>
      </div>
  );

  const yesterday = mode === "count" ? orderSummary.YesterdayOrderCount : orderSummary.YesterdayOrderAmout;
  const lastWeekday = mode === "count" ? orderSummary.LastWeekdayOrderCount : orderSummary.LastWeekdayOrderAmount;
  const thisMonthSoFar = mode === "count" ? orderSummary.ThisMonthSoFarCount : orderSummary.ThisMonthSoFarAmount;
  const lastMonthSameDay = mode === "count" ? orderSummary.LastMonthSameDayCount : orderSummary.LastMonthSameDayAmount;
  const lastMonth = mode === "count" ? orderSummary.LastMonthCount : orderSummary.LastMonthAmount;
  const monthBefore = mode === "count" ? orderSummary.MonthBeforeCount : orderSummary.MonthBeforeAmount;

  const title = mode === "count" ? intl.get("dashboard.orderCount") : intl.get("dashboard.orderAmount")
  return (
    <Spin spinning={loading}>
      <DashboardCard title={title} loading={loading} extra={(dateSelector)}>
        <Row gutter={10}>
          <Col span={9}>
            <Card bodyStyle={{padding: 20}}>
              <div style={{textAlign: 'center', marginTop: 10}}>
                <div style={{fontSize: 14, color: '#292D36', fontWeight: 'bold'}}>{intl.get("dashboard.orderSummary.OrderAmount")}</div>
                <div>
                  <span style={{fontSize: 34, lineHeight: '68px', color: '#000000', marginRight: "3px"}}>{Util.getSymbol(props.application.productCurrency)}</span>
                  <span style={numberStyle}>
                    <NumberFormat value={orderSummary.OrderAmount}
                                                       displayType={'text'} thousandSeparator={true}/>
                  </span>
                </div>
              </div>
            </Card>
          </Col>
          <Col span={5}>
            <NumberComparingIndicator mainTitle={intl.get("dashboard.orderSummary.Yesterday")}
                                      versusTitle={intl.get("dashboard.orderSummary.YesterdayOrderAmout")}
                                      value={yesterday}
                                      versusValue={lastWeekday}
                                      mode={mode}
                                      {...props}
            />
          </Col>
          <Col span={5}>
            <NumberComparingIndicator mainTitle={intl.get("dashboard.orderSummary.ThisMonth")}
                                      versusTitle={intl.get("dashboard.orderSummary.ThisMonthSoFarAmount")}
                                      value={thisMonthSoFar}
                                      versusValue={lastMonthSameDay}
                                      mode={mode}
                                      {...props}
            />
          </Col>
          <Col span={5}>
            <NumberComparingIndicator mainTitle={intl.get("dashboard.orderSummary.LastMonth")}
                                      versusTitle={intl.get("dashboard.orderSummary.LastMonthAmount")}
                                      value={lastMonth}
                                      versusValue={monthBefore}
                                      mode={mode}
                                      {...props}

            />
          </Col>
        </Row>
      </DashboardCard>
    </Spin>
  )
};

export default OrderSummary;