import React from 'react';
import { Switch, Route } from 'react-router-dom';

import CategoryList from './CategoryList';
import intl from "react-intl-universal";

export default class Category extends React.Component {
  constructor(props) {
    super(props);
  };

  render() {
    // NOTICE: path argument를 사용하기 위해서는 Route의 순서가 중요. new -> edit -> show 순서로 작성
    return (
        <div>
          <div className="page-content">
            <Switch>
              <Route path={this.props.match.path} exact
                     render={(props) => (<CategoryList {...props}/>) } />
              />
            </Switch>
          </div>
        </div>
    )
  };
}