import {adminConfig} from "../../../config/admin.config";
import axios from "axios";

const API_BASE_URL = adminConfig.apiServer();

export default class CategoryViewSettingApi {
  static getCategoryViewSettings = (options) => {
    let apiPath = `${API_BASE_URL}/category-view-settings?projection=webAdmin&`;

    apiPath += Object.keys(options).map(key => {
      return key + "=" + options[key]
    }).join("&")

    return axios.get(apiPath);
  };

  static deleteCategoryViewSetting = (id) => {
    let apiPath = `${API_BASE_URL}/category-view-settings/${id}`;
    return axios.delete(apiPath);
  };

  static submitCategoryViewSetting = (cvs) => {
    if (cvs.id) {
      let apiPath = `${API_BASE_URL}/category-view-settings/${cvs.id}`;
      return axios.put(apiPath, cvs);
    } else {
      let apiPath = `${API_BASE_URL}/category-view-settings`;
      return axios.post(apiPath, cvs);
    }
  };

  static changSeq = (oldIndex, newIndex) => {
    let apiPath = `${API_BASE_URL}/category-view-settings/change-seq?projection=webAdmin&oldIndex=${oldIndex}&newIndex=${newIndex}`;
    return axios.put(apiPath);
  };
}