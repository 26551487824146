import React, {useState} from "react";
import {
  Button, Divider,
  DatePicker,
  Spin, Input, Checkbox
} from "antd";
import dayjs from "dayjs";
import ProductApi from "./ProductApi";
import JSONPretty from "react-json-pretty";
const { RangePicker } = DatePicker;

const dateFormat = 'YYYY-MM-DD';
const InterlogisProduct = (props) => {
  const [saving, setSaving] = useState(false);
  const [productId, setProductId] = useState("");
  const [startDate, setStartDate] = useState(dayjs().add(-30, 'day').format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [checkData, setCheckData] = useState(false);
  const [productsRes, setProductsRes] = useState({});

  const onDateChange = (dates, dateStrings) => {
    const dateDiff = dates[1].diff(dates[0], 'day');
    if (dateDiff > 31) {
      alert("최대 기간은 31일 입니다.");
      return;
    }
    if (dateDiff < 0) {
      alert("시작일자가 종료일자보다 큰 값입니다.");
      return;
    }

    setStartDate(dateStrings[0]);
    setEndDate(dateStrings[1]);
  };

  const checkDataChanged = (e) => {
    setCheckData(e.target.checked);
  };

  const getProducts = () => {
    setSaving(true);
    ProductApi.getInterlogisProducts({
      productId: productId,
      startDate: startDate,
      endDate: endDate,
      checkData: checkData,
    }).then(res => {
      setProductsRes(res.data);
    }).finally(() => {
      setSaving(false);
    })
  };

  return (
    <Spin spinning={saving}>
      <div className="page-op-box">
        <div className="filter-box">
          <Input placeholder={"ProductID"} style={{width: 100}} onChange={(e) => setProductId(e.target.value)}/>
          <Divider type="vertical"/>
          <RangePicker
            value={[dayjs(startDate, dateFormat), dayjs(endDate, dateFormat)]}
            format={dateFormat}
            onChange={onDateChange}
          />
          <Divider type="vertical"/>
          <Checkbox checked={checkData} onChange={checkDataChanged}>JSON 데이터 확인만하기</Checkbox>
          <div style={{marginLeft: "auto"}}>
            <Button type="primary" onClick={getProducts}>상품가져오기</Button>
          </div>
        </div>
      </div>
      <div style={{marginTop: "10px"}}>
        <div>
          <ul>
            <li>신규 등록 상품: {productsRes.numCreated}</li>
            <li>정보 수정 상품: {productsRes.numUpdated}</li>
          </ul>
        </div>
        {productsRes.resBody && (
          <div style={{
            marginTop: 20,
            fontSize: "11px",
            overflow: "scroll",
            wordBreak: "break-all",
            wordWrap: "break-word",
            width: "100%",
            height: "400px",
            border: "1px solid #ededed",
            marginRight: 20}}
          >
            <JSONPretty id={"json-prepay-res"} data={productsRes.resBody}></JSONPretty>
          </div>
        )}
      </div>
    </Spin>
  );
}

export default InterlogisProduct;