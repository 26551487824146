import React from "react";

import CommonApi from "../pages/common/CommonApi";
import Cookies from 'universal-cookie';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import ko_KR from 'antd/lib/locale-provider/ko_KR';
import {MemberContext} from "../auth/member.context";
import intl from "react-intl-universal";
import {SettingContext} from "../auth/setting.context";
import {message, Tooltip} from "antd";
import {QuestionCircleOutlined} from "@ant-design/icons";

dayjs.extend(utc);
dayjs.extend(timezone);
const zone = dayjs.tz.guess();
const cookies = new Cookies();

export default class Util {
  static ORDER_STATUSES = [
    {key: 'created', text: '주문생성'},
    {key: 'pay_prepared', text: '지불확인중'},
    {key: 'wait_pay', text: '지불대기중'},
    {key: 'paid', text: '지불완료'},
    {key: 'cancel_req', text: '취소요청'},
    {key: 'local_delivering', text: '한국내 배송중'},
    {key: 'pay_declared', text: '해관신고중(지불신고완료)'},
    {key: 'order_declaring', text: '해관신고중(주문신고)'},
    {key: 'order_declared', text: '해관신고중(주문신고완료)'},
    {key: 'prepare_delivery', text: '통관완료/배송준비중'},
    {key: 'delivering', text: '배송중'},
    {key: 'delivered', text: '배송완료'},
    {key: 'finished', text: '완료'},
  ];

  static ORDER_FAILED_REASONS = [
    {key: 'none', text: '-'},
    {key: 'prepare_pay', text: '지불준비실패'},
    {key: 'payment', text: '지불실패'},
    {key: 'pay_declared', text: '해관신고(지불신고)실패'},
    {key: 'order_declared', text: '해관신고(주문신고)실패'},
    {key: 'clearance', text: '해관통관신고실패'},
    {key: 'etc', text: '기타'},
  ];

  static ORDER_FINISH_STATUS = [
    {key: '', text: '없음'},
    {key: 'success', text: '성공'},
    {key: 'failed', text: '실패'},
    {key: 'refund', text: '환불'},
    {key: 'system_cancel', text: '시스템에 의한 취소'},
  ];

  static ORDER_DECLARE_STATUS = [
    {key: 'success', text: '성공'},
    {key: 'failed', text: '실패'},
    {key: 'created', text: '정보생성완료'},
    {key: 'wait_agent', text: '전송대기(SingleWindow)'},
    {key: 'agent_fetched', text: '전송중(SingleWindow)'},
    {key: 'sent', text: '전송완료(SingleWindow)'},
  ];

  static uuidv4 = () => {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  };

  static getOrderDeclareStatus = (status) => {
    const found = Util.ORDER_DECLARE_STATUS.find(s => s.key === status);
    if (found) {
      return intl.get("order.declare.status." + found.key) ? intl.get("order.declare.status." + found.key) : found.text;
    } else {
      return "N/A";
    }
  };

  static getOrderStatusText = (status) => {
    const found = Util.ORDER_STATUSES.find(s => s.key === status);
    if (found) {
      return intl.get("order.status." + found.key) ? intl.get("order.status." + found.key) : found.text;
    } else {
      return "N/A";
    }
  };

  // static getAppPageTypeName = (pageType) => {
  //   if (pageType === "index") {
  //     return intl.get("");"首页";
  //   } else if (pageType === "product") {
  //     return "商品详细页";
  //   } else {
  //     return "其他页";
  //   }
  // };

  static humanFileSize(bytes, si=false, dp=1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = si
        ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10**dp;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
  }

  static isAdmin = () => {
    return MemberContext.memberInformation.userType === "admin";
  };

  static getOssBucket = () => {
    let ossBucketUrl = "https://";

    const ossBucket = SettingContext.setting.systemSettings?.find(s => s.name === "ali.ossBucket");
    if (ossBucket) {
      ossBucketUrl += ossBucket.value
    } else {
      return "";
    }

    const ossEndpoint = SettingContext.setting.systemSettings?.find(s => s.name === "ali.ossEndPoint");
    if (ossEndpoint) {
      ossBucketUrl += "." + ossEndpoint.value
    } else {
      return "";
    }

    return ossBucketUrl;
  };

  static getBaseExchangeRate = () => {
    const baseCurrency = cookies.get("baseCurrency");
    const exchangeCurrency = cookies.get("currency");
    if (baseCurrency === exchangeCurrency) {
      return new Promise((resolve, reject) => {
        resolve({
          result: {
            rate: 1.0
          }
        });
      });
    } else {
      return CommonApi.getExchangeRate({
        baseCurrency: baseCurrency,
        exchangeCurrency: exchangeCurrency,
      })
    }
  };

  static getOrdinalNumber = (seq) => {
    const countLabels = intl.get("common.label.number").split(",");
    if (seq <= 0 || seq > countLabels.length) {
      return seq;
    } else {
      return countLabels[seq - 1];
    }
  };

  static getSymbol = (currency) => {
    if (currency == "KRW") {
      return "₩";
    } else if (currency === "USD") {
      return "$";
    } else if (currency === "CNY") {
      return "¥";
    } else {
      return "ERR";
    }
  }

  static currencyFormat = (value, currency) => {
    let num = value;
    let symbol = Util.getSymbol(currency);

    if (!num) {
      return symbol + "0";
    }

    if (typeof value === 'string' || value instanceof String) {
      num = parseFloat(value);
    }
    if ((num * 100) % 100 === 0) {
      num = Math.round(num);
      return symbol + num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } else {
      return symbol + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
  };

  static numberFormat = (num) => {
    if (!num) {
      return "0";
    }
    if ((num * 100) % 100 === 0) {
      num = Math.round(num);
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } else {
      return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
  }

  static deleteAllCookies = () => {
    const allCookies = cookies.getAll();
    Object.keys(allCookies).forEach(name => {
      console.log("===> deleteCookie:", name);
      cookies.remove(name, { path: '/' });
    });
  }

  static getMerchandiserId = () => {
    return cookies.get("merchandiserId") || "";
  }

  static getUser = () => {
    return cookies.get("user") || {};
  }

  static getLocale = () => {
    return cookies.get("locale") || ko_KR.locale
  }

  static unixTimeToStr(unixTime) {
    return dayjs.unix(unixTime).format('YYYY-MM-DD HH:mm:ss');
  }

  static getChinaLocalTime(dateTimeStr) {
    return dayjs.tz(dateTimeStr, 'Asia/Shanghai').format("YYYY-MM-DD HH:mm:ss") + "(GMT+8)";
  }

  static getLocalTime(dateTimeStr) {
    return dayjs.tz(dateTimeStr, zone).format("YYYY-MM-DD HH:mm:ss");
  }

  static getLocalTimeOrDate(dateTimeStr) {
    const localTime = dayjs.tz(dateTimeStr, zone);
    const dataDate = localTime.format("YYYY-MM-DD");
    const currentDate = dayjs().tz(zone).format("YYYY-MM-DD");
    if (dataDate != currentDate) {
      return (<span>{localTime.format("MM/DD")}<br/>{localTime.format("HH:mm A")}</span>)
    } else {
      return dayjs.tz(dateTimeStr, zone).format("HH:mm A");
    }
  }

  static toFixed2(num) {
    return Math.round(num * 100) / 100
  }

  static encodeImagePath = (path) => {
    // filename에 "+" 기호가 있는 경우 제대로 나타나지 않는다.
    try {
      const url = new URL(path);
      if (path.endsWith("/")) {
        path = path.substring(0, path.length - 1);
      }

      const index = path.lastIndexOf("/");
      if (index < url.origin.length) {
        return path;
      }

      const fileName = path.substring(index + 1);
      const encodedPath = path.substring(0, index) + "/" + encodeURIComponent(fileName);
      return encodedPath;
    } catch (err) {
      return path;
    }
  }

  static getServiceDomainName = () => {
    const hostName = window.location.hostname;
    if (hostName === "localhost" || hostName === "127.0.0.1") {
      return hostName;
    }
    const hostTokens = hostName.split(".");
    if (hostTokens.length >= 2) {
      return hostTokens[hostTokens.length - 2] + "." + hostTokens[hostTokens.length - 1];
    } else {
      return "youma.kr";
    }
  }

  static getLabelWithHelp(label, helpMessage, required = false) {
    return (
      <div>
        {required && (<span style={{marginRight: 4, color: "#ff4d4f"}}>*</span>)}
        <span>{label}</span>
        <span style={{marginLeft: "5px"}}>
            <Tooltip title={helpMessage} arrowPointAtCenter>
              <QuestionCircleOutlined/>
            </Tooltip>
          </span>
      </div>
    )
  }

  static getProductAttrs(product) {
    const attrs = [];
    if (product.stopSales) {
      attrs.push({color: "red", value: intl.get("products.stopSales")});
    }
    let notPublished = (!product.published) || (product.marketProduct && !product.marketProductPublished);
    if (notPublished) {
      attrs.push({color: "orange", value: intl.get("products.published.false")});
    }
    if (product.eventProduct && product.hideToUser) {
      attrs.push({color: "magenta", value: intl.get("products.hideToUser")});
    }
    if (product.openToMarket) {
      attrs.push({color: "geekblue", value: intl.get("products.openToMarket")});
    }
    if (product.marketProduct) {
      attrs.push({color: "cyan", value: intl.get("products.source.productMarket") + "(" + product.marketProductId + ")"});
    }
    if (product.wholesaleProduct) {
      attrs.push({color: "blue", value: intl.get("products.wholesaleProduct")});
    }
    return attrs;
  }

  static getRequiredLabel = (label) => {
    return (
      <>
        <span style={{ color: "red", marginRight: 2 }}>*</span>
        <span>{label}</span>
      </>
    );
  };
}