import React from 'react';

import {
  Button,
  Checkbox,
  message,
  Tag
} from 'antd';

import CategoryApi from "./CategoryApi";
import intl from "react-intl-universal";

export default class CategoryList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      appCategories: [],
      selectedCategory: {},
    };
  }

  componentDidMount() {
    this.loadAppCategories();
  }

  loadAppCategories = () => {
    CategoryApi.getCategoriesByApp().then(res => {
      this.setState({
        appCategories: res.data
      }, () => {
        this.loadCategories()
      })
    })
  };

  loadCategories = () => {
    const {appCategories} = this.state;
    CategoryApi.getCategories().then(res => {
      const categories = res.data;
      let appCategoryIds = [];
      appCategories.forEach(c => {
        appCategoryIds = appCategoryIds.concat(c.subCategories.map(sc => sc.id));
      });
      const modifiedCategories = categories.map(c => {
        c.subCategories = c.subCategories.map(sc => {
          if (appCategoryIds.includes(sc.id)) {
            sc.selected = true;
          }
          return sc;
        });
        return c;
      });
      this.setState({
        categories: modifiedCategories,
      });
    });
  };

  onSelectCategory = (subCategory, selected) => {
    const {categories} = this.state;
    const parentCategory = categories.find(c => c.id === subCategory.parentId);
    parentCategory.subCategories = parentCategory.subCategories.map(sc => {
      if (sc.id === subCategory.id) {
        sc.selected = selected;
      }
      return sc;
    });

    const changedCategories = categories.map(c => {
      if (c.id === parentCategory.id) {
        return parentCategory;
      } else {
        return c;
      }
    });

    this.setState({
      categories: changedCategories
    })
  };

  getSelectedsubCategories = () => {
    const {categories} = this.state;

    const selectedCategories = [];
    categories.forEach(c => {
      c.subCategories.filter(sc => sc.selected).forEach(sc => {
        sc.title = c.name + ": " + sc.name;
        selectedCategories.push(sc);
      })
    })
    return selectedCategories;
  };

  saveCategory = () => {
    const selectedCategories = this.getSelectedsubCategories();
    if (selectedCategories.length === 0 ){
      message.error(intl.get("common.inputField", {name: intl.get("category.category")}));
      return;
    }
    const selectedCategoryIds = selectedCategories.map(c => c.id);

    CategoryApi.saveAppCategories(selectedCategoryIds).then(res => {
      message.success(intl.get("common.message.saved"));
    });
  };

  render() {
    const { categories, selectedCategory } = this.state;
    const subCategories = selectedCategory && selectedCategory.subCategories ? selectedCategory.subCategories : []
    const selectedsubCategories = this.getSelectedsubCategories();

    return (
        <div style={{width: 600}}>
          {selectedsubCategories.length > 0 && (
              <div style={{marginTop: 10, marginBottom: 10}}>
                <div><h4>{intl.get("category.selected")}</h4></div>
                <div style={{backgroundColor: "#fafafa", padding: 10}}>
                  { selectedsubCategories.map(sc => {
                      return <Tag key={sc.id} color={"blue"} closable onClose={() => this.onSelectCategory(sc, false)}>{sc.title}</Tag>
                    })
                  }
                </div>
              </div>
          )}
          <div style={{marginTop: 10, display: "flex", justifyContent: "space-between"}}>
            <div style={{width: "38%"}}>
              <h4>{intl.get("category.level.1")}</h4>
              <div style={{width: "100%", height: 400, overflowY: "scroll", border: "1px solid #eeeeee"}}>
                {categories.map(c => {
                  return (
                      <div key={c.id}
                           style={{display: "flex", alignItems: "center", width:"100%", cursor: "pointer", padding: "5px 10px"}}
                           onClick={() => this.setState({selectedCategory: c})}>
                        <div>{c.name}</div>
                      </div>
                  )
                })}
              </div>
            </div>
            <div style={{width: "58%"}}>
              <h4>{intl.get("category.level.2")}</h4>
              <div style={{width: "100%", height: 400, overflowY: "scroll", border: "1px solid #eeeeee"}}>
                { subCategories.map(c => {
                  return (
                      <div key={c.id}  style={{display: "flex", alignItems: "center", width:"100%", cursor: "pointer", padding: "5px 10px"}}
                           onClick={() => this.onSelectCategory(c, !c.selected)}>
                        <div>{c.name}</div>
                        <div style={{marginLeft: "auto", marginRight: 10}}><Checkbox checked={c.selected}/></div>
                      </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div style={{marginTop: 20, textAlign: "center"}}>
            <Button type="primary" onClick={this.saveCategory}>{intl.get("common.button.save")}</Button>
          </div>
        </div>
    )
  }
}
