import React, {useState, useEffect} from "react";
import UserApi from "./UserApi";
import { Modal, Layout, Descriptions} from "antd";
import intl from "react-intl-universal";
import {UserOutlined} from "@ant-design/icons";

const {Content} = Layout;

const UserShowPopup = (props) => {
  const [user, setUser] = useState({});

  useEffect(() => {
    loadUser();
  }, [props.userId]);

  const loadUser = () => {
    UserApi.getUser(props.userId).then(res => {
      setUser(res.data);
    });
  };

  return (
    <Modal
      title={[<div key={1}><UserOutlined/> {intl.get("user.info")}</div>]}
      visible={true}
      onCancel={props.handleCancel}
      footer={null}
      width={800}
      bodyStyle={{margin: 0, padding: 0}}>
      <Layout>
        <Content className="modal-content">
          <Descriptions bordered size={"small"} column={2}>
            <Descriptions.Item label={"ID"}>{user.id}</Descriptions.Item>
            <Descriptions.Item label={intl.get("user.wxOpenId")}>{user.wxOpenId}</Descriptions.Item>
            <Descriptions.Item label={intl.get("user.name")}>{user.name}</Descriptions.Item>
            <Descriptions.Item label={intl.get("user.realName")}>{user.realName}</Descriptions.Item>
            <Descriptions.Item label={intl.get("user.phone")}>{user.phone}</Descriptions.Item>
            <Descriptions.Item label={intl.get("user.email")}>{user.email}</Descriptions.Item>
            <Descriptions.Item label={intl.get("user.loginId")}>{user.loginId}</Descriptions.Item>
          </Descriptions>
        </Content>
      </Layout>
    </Modal>
  )
}

export default UserShowPopup;