import React, {useEffect, useState} from "react";
import {Spin, Table, Button, Space, Divider, message, Descriptions, Modal} from "antd";
import ClearanceApi from "./ClearanceApi";
import Util from "../../../util/Util";
import intl from "react-intl-universal";
import PrettyData from "pretty-data";

const DeclarePayment = (props) => {
  const [updating, setUpdating] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [paymentDeclares, setPaymentDeclares] = useState([]);
  const [selectedPaymentDeclare, setSelectedPaymentDeclare] = useState({});
  const [visiblePaymentModal, setVisiblePaymentModal] = useState(false);

  useEffect(() => {
    loadPaymentDeclares(props.order.id);
  }, [props.order.id]);

  const loadPaymentDeclares = (orderId) => {
    ClearanceApi.getPaymentDeclares(orderId).then(res => {
      const paymentDeclares = res.data;
      const succeedItems = paymentDeclares.find(pd => pd.status === "success");
      setDisabled(succeedItems ? true : false);
      setPaymentDeclares(paymentDeclares);
    })
  };

  const showPaymentDeclare = (paymentDeclare, event) => {
    event.stopPropagation();
    setSelectedPaymentDeclare(paymentDeclare);
    setVisiblePaymentModal(true);
  };

  const deletePaymentDeclare = (id, event) => {
    event.stopPropagation();
    if(!window.confirm(intl.get("common.confirm.delete", {name: intl.get("order.clearance.payment")}))) {
      return;
    }
    ClearanceApi.deletePaymentDeclare(id).then(res => {
      message.info(intl.get("common.message.deleted"));
      loadPaymentDeclares(props.order.id);
    }).catch(e => {
      console.log("deletePaymentDeclare err:", e);
    }).finally(() => {
      setUpdating(false);
    });
  };

  const sendPaymentDeclare = () => {
    if(!window.confirm(intl.get("order.clearance.message.send_to_payment"))) {
      return;
    }
    setUpdating(true);
    ClearanceApi.sendPaymentDeclare(props.order.id).then(res => {
      message.info(intl.get("common.message.success"));
      loadPaymentDeclares(props.order.id);
    }).catch(e => {
      console.log("sendPaymentDeclare err:", e);
    }).finally(() => {
      setUpdating(false);
    });
  };

  const columns = [{
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    }, {
      title: intl.get("order.clearance.table.date"),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text, record) => {
        return (<div>{Util.getChinaLocalTime(record.createdAt)}</div>);
      }
    }, {
      title: intl.get("order.clearance.table.status"),
      dataIndex: 'status',
      key: 'status',
      render: (text, record) =>(
        <div>{text === "success" ? intl.get("order.clearance.table.success") : intl.get("order.clearance.table.failed")}</div>
      )
    }, {
      title: intl.get("order.clearance.table.errorMessage"),
      dataIndex: 'errorMessage',
      key: 'errorMessage',
      render: text => {
        return (<div style={{display: "flex", alignItems: "center", fontSize: 11, width: 200, height: 40, overflow: "auto"}}>{text}</div>)
      }
    }, {
      title: '',
      dataIndex: 'id',
      key: 'button',
      render: (text, record) => {
        return record.status === "fail"
          ? ( <Button size="small" onClick={(event) => deletePaymentDeclare(record.id, event)}>
                {intl.get("common.button.delete")}
              </Button>)
          : (<div></div>);
      }
    }];

  return (
    <Spin spinning={updating}>
      <div>
        <div style={{ display: "flex", marginBottom: 10}}>
          <div>{intl.get("order.clearance.payment.help")}</div>
          <div style={{marginLeft: "auto"}}>
            <Button type="primary" size="small" disabled={disabled}
                    onClick={sendPaymentDeclare}>
              {intl.get("order.clearance.payment")}
            </Button>
          </div>
        </div>
        <div style={{cursor: "pointer"}}>
          <Table size={'small'}
             dataSource={paymentDeclares}
             columns={columns}
             rowKey={'id'}
             pagination={false}
             onRow={(record, rowIndex) => {
               return {
                 onClick: event => {
                   showPaymentDeclare(record, event);
                 },
               }
             }}
          />
        </div>
      </div>
      {visiblePaymentModal && (
        <DeclarePaymentDetailModal
          paymentDeclare={selectedPaymentDeclare}
          handleOk={() => setVisiblePaymentModal(false)}/>
      )}
    </Spin>
  );
};

const DeclarePaymentDetailModal = (props) => {
  const getPaymentDetailView = (isRequest) => {
    const value = isRequest ? props.paymentDeclare.requestBody : props.paymentDeclare.responseBody

    if (!value) {
      return (<div></div>)
    }

    if (value.startsWith("<?xml") || value.startsWith("<ceb") || value.startsWith("<CustomDeclareOrderResponse")) {
      return (
        <div className={"request_message"}>
          <pre>{PrettyData.pd.xml(value)}</pre>
        </div>
      )
    } else {
      return (
        <div className={"request_message"}>
          {decodeURIComponent(value).split("&").map((keyVal, kvIndex) => (<div key={kvIndex}>{keyVal}</div>))}
        </div>
      )
    }
  };

  return (
    <Modal title={intl.get("order.clearance.payment_detail")}
           visible={true}
           onOk={() => props.handleOk()}
           onCancel={() => props.handleOk()}
           width="80%"
           cancelButtonProps={{ style: { display: 'none' } }}>
      <div>
        <Descriptions  bordered
                       layout={"vertical"}
                       size={"small"}
                       column={2}
        >
          <Descriptions.Item label={intl.get("order.clearance.payment_info")}>
            { getPaymentDetailView(true) }
          </Descriptions.Item>
          <Descriptions.Item label={intl.get("order.clearance.payment_result")}>
            { getPaymentDetailView(false) }
          </Descriptions.Item>
        </Descriptions>
      </div>
    </Modal>
  )
};

export default DeclarePayment;