import {adminConfig} from "../../config/admin.config";
import axios from "axios";

const API_BASE_URL = adminConfig.apiServer();

export default class ApplicationPageApi {
  static getAppPages = (options) => {
    let apiPath = `${API_BASE_URL}/app-pages?projection=webAdmin&`;

    apiPath += Object.keys(options).map(key => {
      return key + "=" + options[key]
    }).join("&")

    return axios.get(apiPath);
  };

  static getAppPage = (id) => {
    let apiPath = `${API_BASE_URL}/app-pages/${id}?projection=webAdmin`;
    return axios.get(apiPath);
  };

  static getAppPageByType = (pageType) => {
    let apiPath = `${API_BASE_URL}/app-pages/by-type?projection=webAdmin&pageType=${pageType}`;
    return axios.get(apiPath);
  };

  static deleteAppPage = (id) => {
    let apiPath = `${API_BASE_URL}/app-pages/${id}`;
    return axios.delete(apiPath);
  };

  static submitAppPage = (appPage) => {
    if (appPage.id) {
      let apiPath = `${API_BASE_URL}/app-pages/${appPage.id}`;
      return axios.put(apiPath, appPage);
    } else {
      let apiPath = `${API_BASE_URL}/app-pages`;
      return axios.post(apiPath, appPage);
    }
  };
}