import React, {useEffect, useState} from "react";
import {Descriptions, Form, Input, Radio, Button, message, Divider} from "antd";
import intl from "react-intl-universal";
import FaqApi from "./FaqApi";
import {Link} from "react-router-dom";

const FormItem = Form.Item;

const FaqEdit = (props) => {
  const [form] = Form.useForm();
  const [faq, setFaq] = useState({
    pinToTop: false,
    active: true,
  });

  useEffect(() => {
    if (props.match.params.faqId) {
      // 목록에 있는 경우 dirty되었을 수도 있기 때문에 edit view에서 다시 가져온다.
      loadFaq(props.match.params.faqId);
    } else {
      form.setFieldsValue(faq);
    }
  }, [props.match.params.faqId]);

  const loadFaq = (faqId) => {
    FaqApi.getFaq(faqId).then(res => {
      const faq = res.data;
      setFaq(faq);
      form.setFieldsValue(faq);
    });
  };

  const submitFaq = (values) => {
    const faqState = {...faq};
    Object.assign(faqState, values);
    FaqApi.submitFaq(faqState).then(res => {
      message.success(intl.get("common.message.saved"));
      loadFaq(res.data.id);
      if (!values.id) {
        props.loadFaqs();
      }
    });
  }

  return (
      <div>
        <Form onFinish={submitFaq} form={form}>
          <Descriptions bordered size={"small"} column={2}>
            <Descriptions.Item label="ID" span={2}>
              <FormItem name={"id"}>
                <Input disabled={true}/>
              </FormItem>
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("faq.pinToTop")}>
              <FormItem name={"pinToTop"} rules={[{ required: true }]}>
                <Radio.Group>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
              </FormItem>
            </Descriptions.Item>
            <Descriptions.Item label="Active">
              <FormItem name={"active"} rules={[{ required: true }]}>
                <Radio.Group>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
              </FormItem>
            </Descriptions.Item>
            <Descriptions.Item label="Question" span={2}>
              <FormItem name={"question"} rules={[{ required: true }]}>
                <Input/>
              </FormItem>
            </Descriptions.Item>
            <Descriptions.Item label="Answer" span={2}>
              <FormItem name={"answer"} rules={[{ required: true }]}>
                <Input.TextArea rows={20}/>
              </FormItem>
            </Descriptions.Item>
            <Descriptions.Item span={2}>
              <Button type="primary" htmlType="submit">{intl.get("common.button.save")}</Button>
              <Divider type="vertical"/>
              <Button type="primary"><Link to={`${props.parentPath}`}>{intl.get("common.button.list")}</Link></Button>
            </Descriptions.Item>
          </Descriptions>
        </Form>
      </div>
  )
};

export default FaqEdit;