import React, {useEffect, useState} from "react";
import {Button, Divider, Input, Select, Form, Space, Descriptions, message} from "antd";
import {Link} from "react-router-dom";
import UserApi from './UserApi';
import intl from "react-intl-universal";

const UserEdit = (props) => {
  const [user, setUser] = useState({});
  const [form] = Form.useForm();

  useEffect(() => {
    loadUser(props.match.params.userId);
  }, [props.match.params.userId]);

  const loadUser = (id) => {
    UserApi.getUser(id).then(res => {
      const user = res.data;
      setUser(user);
      form.setFieldsValue(user);
    });
  };

  const submitUser = (values) => {
    const userState = {...user};
    Object.keys(values).forEach(key => {
      userState[key] = values[key];
    });

    UserApi.saveUser(userState).then(res => {
      message.success(intl.get("common.message.saved"));
      const user = res.data;
      setUser(user);
      form.setFieldsValue(user);
      props.setUser(user);
    });
  };

  return (
      <div style={{width: "100%"}}>
        <Form onFinish={submitUser} form={form}>
          <Descriptions bordered size={"small"} column={1}>
            <Descriptions.Item label={"ID"}>
              <Form.Item name={"id"}>
                <Input disabled={true}/>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("user.wxOpenId")}>
              <Form.Item name={"wxOpenId"}>
                <Input disabled={true}/>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("user.name")}>
              <Form.Item name={"name"} rules={[{required: true}]}>
                <Input/>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("user.realName")}>
              <Form.Item name={"realName"}>
                <Input/>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("user.phone")}>
              <Form.Item name={"phone"}>
                <Input/>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("user.email")}>
              <Form.Item name={"email"}>
                <Input/>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("user.loginId")}>
              <Form.Item name={"loginId"}>
                <Input/>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={"Role"}>
              <Form.Item name={"role"}>
                <Select>
                  <Select.Option value={""}>일반사용자</Select.Option>
                  <Select.Option value={"manager"}>앱관리자</Select.Option>
                </Select>
              </Form.Item>
            </Descriptions.Item>
            {/*<Descriptions.Item label={"전화번호"}>*/}
            {/*  <div style={{display: "flex"}}>*/}
            {/*    <Form.Item name={"countryCode"}>*/}
            {/*      <Select style={{width: 80, marginRight: 10}}>*/}
            {/*        <Select.Option value={"86"}>中国</Select.Option>*/}
            {/*        <Select.Option value={"852"}>香港</Select.Option>*/}
            {/*        <Select.Option value={"82"}>韩国</Select.Option>*/}
            {/*      </Select>*/}
            {/*    </Form.Item>*/}
            {/*    <Form.Item name={"phone"}>*/}
            {/*      <Input/>*/}
            {/*    </Form.Item>*/}
            {/*  </div>*/}
            {/*</Descriptions.Item>*/}
            <Descriptions.Item label={intl.get("user.avatarUrl")}>
              <Form.Item name={"avatarUrl"}>
                <Input/>
              </Form.Item>
              {user.avatarUrl && (<img src={user.avatarUrl} style={{width: "64px", height: "64px"}}/>)}
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("user.gender")}>
              <Form.Item name={"gender"}>
                <Select>
                  <Select.Option key="1" value={1}>Male</Select.Option>
                  <Select.Option key="2" value={2}>Female</Select.Option>
                  <Select.Option key="0" value={0}>Unknown</Select.Option>
                </Select>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("user.province")}>
              <Form.Item name={"province"}>
                <Input/>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("user.city")}>
              <Form.Item name={"city"}>
                <Input/>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("user.country")}>
              <Form.Item name={"country"}>
                <Input/>
              </Form.Item>
            </Descriptions.Item>
          </Descriptions>
          <div style={{textAlign: "center", marginTop: 10}}>
            <Space>
              <Button type="primary" htmlType="submit">{intl.get("common.button.save")}</Button>
              <Divider type="vertical"/>
              <Button type="primary"><Link to={`${props.parentPath}`}>{intl.get("common.button.list")}</Link></Button>
            </Space>
          </div>
        </Form>
      </div>
  )
}
export default UserEdit;