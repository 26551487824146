import React, {useEffect} from "react";
import {Layout} from "antd";
import "./AppLayout.less";
import {
  EventBroadcaster,
  SHOW_LOADING_EVENT_TOPIC
} from "../../event/event.broadcaster";
import Sider from "./Sider";
import Header from "./Header";
import Content from "./Content";
import Footer from "./Footer";
import {useLayoutDispatch} from "./AppLayoutContext";
import intl from "react-intl-universal";
import {MemberContext} from "../../auth/member.context";
import PageRouter from "../../pages/router/PageRouter";
import LogisticsHeader from "./LogisticsHeader";

const AppLayout = (props) => {
  const layoutDispatch = useLayoutDispatch();

  useEffect(() => {
    const pathname = props.location.pathname;
    layoutDispatch({
      type: 'INIT_NAVIGATION', pathname
    });
  }, [
    props.location.pathname,
  ]);

  useEffect(() => {
    EventBroadcaster.on(SHOW_LOADING_EVENT_TOPIC, (data) => {
      const show = data.show;
      layoutDispatch({
        type: 'SHOW_LOADING', show
      });
    });
  }, [layoutDispatch]);

  const reloadPage = () => {
    window.location.reload();
  };

  if (MemberContext.memberInformation.userType === "logistics") {
    return (
      <Layout
        style={{
          minHeight: "100%"
        }}
      >
        <Sider/>
        <Layout className="site-layout">
          <LogisticsHeader props={props}/>
          <Layout.Content className="site-layout-content">
            <PageRouter/>
          </Layout.Content>
        </Layout>
      </Layout>
    )
  }

  return (
    <Layout
      style={{
        minHeight: "100%"
      }}
    >
      <Sider/>
      <Layout className="site-layout">
        {props.needUpdate && (
          <div style={{height: 30, fontSize: 14, width: "100%", background: "#f19d38", display: "flex", alignItems: "center", justifyContent: "center"}}>
            {intl.get("layout.reload")}
            <span style={{marginLeft: 10, height: 24, background: "#f9f9f9", border: "1px solid #dddddd", padding: "2px 10px", fontSize: 12}}>
              <a onClick={reloadPage}>Reload</a>
            </span>
          </div>
        )}

        <Header {...props}/>
        {MemberContext.memberInformation.userType === "logistics" && (
          <div>Logistics</div>
        )}
        {MemberContext.memberInformation.userType !== "logistics" && (
          <Content {...props}/>
        )}
        <Footer {...props}/>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
