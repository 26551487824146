import React, {useState, useEffect} from 'react';
import CommonApi from "../../pages/common/CommonApi";
import {useLayoutDispatch, useLayoutState} from "./AppLayoutContext";

const ExchangeRate = (props) => {
  const [productToPayExchangeRate, setProductToPayExchangeRate] = useState("");
  const [productToUserExchangeRate, setProductToUserExchangeRate] = useState("");
  const layoutState = useLayoutState();

  useEffect(() => {
    const {application} = layoutState;
    if (application.productCurrency && application.paymentRequestCurrency &&
      application.productCurrency != application.paymentRequestCurrency) {
      const params = {
        baseCurrency: application.productCurrency,
        exchangeCurrency: application.paymentRequestCurrency,
      };
      CommonApi.getExchangeRate(params).then(res => {
        setProductToPayExchangeRate(`1${application.productCurrency} = ${res.data.rate}${application.paymentRequestCurrency} (${res.data.baseDate})`);
      });
    } else {
      setProductToPayExchangeRate("");
    }
    if (application.productCurrency && application.paymentRequestCurrency !== application.userPayCurrency) {
      const params = {
        baseCurrency: application.productCurrency,
        exchangeCurrency: application.userPayCurrency,
      };
      CommonApi.getExchangeRate(params).then(res => {
        setProductToUserExchangeRate(`1${application.productCurrency} = ${res.data.rate}${application.userPayCurrency} (${res.data.baseDate})`);
      });
    } else {
      setProductToUserExchangeRate("");
    }
  }, [layoutState.application]);

  return (
    <div>
      {productToPayExchangeRate && (
        <div>{productToPayExchangeRate}</div>
      )}
      {productToUserExchangeRate && (
        <div>{productToUserExchangeRate}</div>
      )}
    </div>
  )
};

export default ExchangeRate;