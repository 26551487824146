import React, {useEffect, useState} from "react";
import {useLayoutState} from "../../components/layout/AppLayoutContext";
import {Alert} from "antd";
import SupplierOrderDailySummary from "./SupplierOrderDailySummary";
import SupplierOrderSummary from "./SupplierOrderSummary";

const SupplierHome = (props) => {
  const layoutState = useLayoutState();
  const [application, setApplication] = useState({});

  useEffect(() => {
    if (layoutState.application) {
      setApplication(layoutState.application);
    }
  }, [layoutState.application]);

  if (!application || !application.id) {
    return (
      <div className="page-content">
        <div style={{backgroundColor: "#EDF0F4"}}>
          <Alert
            message="등록된 미니프로그램이 없습니다."
            description={(
              <>
                <div>관리자에게 미니프로그램 권한을 요청을 하세요.</div>
              </>
            )}
            type="warning"
            showIcon
          />
        </div>
      </div>
    )
  }

  return (
    <div>
      <div className="page-content">
        <div style={{backgroundColor: "#EDF0F4"}}>
          <div>
            <SupplierOrderSummary application={application} mode={"count"}/>
          </div>
          <div style={{display: "flex", marginTop: "5px"}}>
            <div style={{width: "100%"}}>
              <SupplierOrderDailySummary/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupplierHome;