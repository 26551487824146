import axios from "axios";
import {adminConfig} from "../../config/admin.config";

const API_BASE_URL = adminConfig.apiServer();

export default class UserApi {
  static getUsers = (params) => {
    let apiPath = `${API_BASE_URL}/users?`;

    let paramKeyValues = []
    Object.keys(params).forEach(key => {
      if (params[key]) {
        const paramValue = params[key];
        if (Array.isArray(paramValue)) {
          paramKeyValues = paramKeyValues.concat(paramValue.map(v => key + "=" + v));
        } else {
          paramKeyValues.push(key + "=" + paramValue)
        }
      }
    });
    apiPath += paramKeyValues.join("&");
    if (!params.projection) {
      apiPath += "&projection=webAdmin";
    }
    return axios.get(apiPath);
  };

  static getUser = (userId) => {
    let apiPath = `${API_BASE_URL}/users/${userId}`;
    return axios.get(apiPath);
  };

  static saveUser = (user) => {
    const apiPath = `${API_BASE_URL}/users/${user.id}?projection=webAdmin`;
    return axios.put(apiPath, user);
  };
}