import React from "react";
import {Button, Descriptions, Select} from "antd";

const messageKoKR = {
  "menu.home": "Home",
  "menu.dashboard": "Dashboard",
  "menu.companyInitSetting": "로그인 설정",
  "menu.shop": "상점관리",
  "menu.shop.design": "기본디자인",
  "menu.shop.page-settings": "페이지 관리",
  "menu.shop.files": "파일(이미지) 관리",
  "menu.shop.terms": "약관설정",
  "menu.shop.application.logistics.company": "물류회사(상점)",
  "menu.operator-roles": "운영자 권한 설정",
  "menu.products": "상품관리",
  "menu.product.list": "내상품",
  "menu.product.productMarket": "상품마켓",
  "menu.product.share": "상품공유현황",
  "menu.product.category": "카테고리",
  "menu.product.brand": "브랜드",
  "menu.orders": "주문관리",
  "menu.orders.list": "주문목록",
  "menu.orders.upload": "주문업로드",
  "menu.events": "이벤트관리",
  "menu.events.coupons": "쿠폰관리",
  "menu.events.coupon-issues": "쿠폰발행현황",
  "menu.live": "방송",
  "menu.live.rooms": "방송목록",
  "menu.live.notRegisteredProduct": "미등록상품",
  "menu.live.registeredProduct": "등록상품",
  "menu.call-center": "쇼핑몰 고객관리",
  "menu.call-center.faq": "FAQ",
  "menu.call-center.user": "사용자관리",
  "menu.companySetting": "회사관리",
  "menu.systemSetting.logistics-company": "물류회사(시스템)",
  "menu.systemSetting.info": "회사정보",
  "menu.systemSetting.manager": "회사사용자",
  "menu.systemSetting.shop": "회사미니프로그램",
  "menu.systemSetting": "시스템설정",
  "menu.help-center": "고객센터",
  "menu.help-center.question": "1:1문의",
  "menu.help-center.guide": "사용가이드",
  "menu.help-center.notice": "공지사항",
  "menu.help-center.notice.admin": "공지사항관리",
  "menu.settlement": "정산관리",
  "menu.settlement.order": "주문정산",
  "menu.settlement.seller": "판매사정산",
  "menu.reply": "상품후기",
  "menu.logistics": "배송요청",
  "menu.logistics.register": "접수신청",
  "menu.logistics.list": "접수내역",
  "menu.miniprogram.setting": "미니프로그램 정보",
  "menu.supplier": "공급상",
  "menu.shop.component": "UI 컴포넌트 관리",

  "common.true": "예",
  "common.false": "아니오",
  "common.createdAt": "등록일",
  "common.updatedAt": "수정일",

  "common.button.ok": "확인",
  "common.button.cancel": "취소",
  "common.button.save": "저장",
  "common.button.close": "닫기",
  "common.button.add": "추가",
  "common.button.search": "조회",
  "common.button.select": "선택",
  "common.button.show": "상세",
  "common.button.edit": "수정",
  "common.button.list": "목록",
  "common.button.before": "이전",
  "common.button.next": "다음",
  "common.button.delete": "삭제",
  "common.button.back": "뒤로",
  "common.button.download": "다운로드",
  "common.button.upload": "업로드",
  "common.button.fileManager": "이미지관리",
  "common.button.sendMessage": "메시지 보내기",
  "common.button.multiSetting": "일괄설정",
  "common.button.newFolder": "새폴더",
  "common.button.qrdownload": "QRCode 다운로드",
  "common.button.selectFile": "파일선택",
  "common.button.copy": "복사",
  "common.button.addBulk": "일괄등록",
  "common.button.preview": "미리보기",
  "common.button.confirm": "확인",

  "common.inputField": "{name}을(를) 입력하세요.",
  "common.selectField": "{name}을(를) 선택하세요.",
  "common.confirm.delete": "{name}을(를) 삭제 하시겠습니까?",
  "common.confirm.delete2": "삭제 하시겠습니까?",
  "common.confirm.save": "{name}을(를) 저장 하시겠습니까?",
  "common.message.noData": "{name}가 없습니다.",
  "common.message.nth": "{index}번째",
  "common.message.deleted": "삭제되었습니다.",
  "common.message.saved": "저장되었습니다.",
  "common.message.selectAtLeastOne": "하나 이상의 {name}을 선택하세요.",
  "common.message.jian": "개",
  "common.message.success": "정상 처리되었습니다.",
  "common.message.contactAdmin": "문제가 발생했습니다. 시스템 관리자에게 문의하세요.",
  "common.message.copied": "복사되었습니다.",
  "common.message.inputNumber": "숫자를 입력하세요.",
  "common.message.selectFile": "파일을 선택하세요.",

  "common.label.youma": "요우미",
  "common.label.listTotal": "전체 {total}건",
  "common.label.total": "전체",
  "common.label.price": "가격",
  "common.label.password": "비밀번호",
  "common.label.select": "판매여부",
  "common.label.call-center": "고객센터",
  "common.label.language.korean": "한국어",
  "common.label.language.chinese": "중국어",
  "common.label.detail": "상세정보",
  "common.label.upload.help": "이미지 링크 직접 입력 또는 오른쪽 버튼 눌러서 업로드 및 선택",
  "common.label.download": "다운로드",
  "common.label.month": "월",
  "common.label.wechat": "위챗",
  "common.label.count": "{count} 건",
  "common.label.success": "성공",
  "common.label.fail": "실패",
  "common.label.number": "1,2,3,4,5,6,7,8,9,10",
  "common.label.approve": "승인",

  "common.paymentCompany.wechatpay": "위챗직접지불(중국법인)",
  "common.paymentCompany.eximbay": "엑심베이",
  "common.paymentCompany.smatro": "스마트로",
  "common.paymentCompany.nicepay": "나이스페이",

  "layout.reload": "시스템이 업데이트 되었습니다. 웹 페이지를 리로드하세요(윈도우: Ctrl+F5, 맥: Command + Shift + R)",
  "login.login": "로그인",
  "login.loginOut": "로그아웃",
  "login.welcome": "{name} 서비스에 오신것을 환영합니다.",
  "login.loginId": "아이디",
  "login.password": "비밀번호",
  "login.noMember": "회원이 아니신가요?",
  "login.findPasswordTitle": "비밀번호찾기",
  "login.findPasswordButton": "비밀번호찾기",
  "login.resetPassword": "비밀번호 변경",
  "login.resetPassword.wrongUrl": "올바르지 않은 비밀번호 설정 페이지 입니다. 메일에서 다시 확인하세요.",
  "login.password.changed": "비밀번호가 변경되었습니다.",

  "register.register": "회원가입하기",
  "register.diffPassword": "입력한 비밀번호와 다릅니다. 동일하게 입력해주세요.",
  "register.shortLoginId": "ID는 6자리 이상 입력하세요.",
  "register.shortPassword": "비밀번호는 8자리 이상 입력하세요.",
  "register.hasLoginId": "이미 계정이 있으신가요?",
  "register.confirmingPassword": "비밀번호확인",
  "register.agree": "동의",
  "register.must": "(필수)",
  "register.message.agree1": "이용 약관에 동의하세요",
  "register.message.agree2": "개인정보 수집 및 이용에 동의하세요",
  "register.message.alreadyUsedId": "이미 사용중인 ID 입니다.",
  "register.registered": "정상적으로 가입 되었습니다.",

  "application.design.basic": "기본설정",
  "application.design.app.shareImage": "앱 공유이미지",
  "application.design.app.logo": "로고이미지",
  "application.design.selectTheme": "테마 선택",
  "application.design.theme": "테마",
  "application.design.userDefined": "사용자정의",
  "application.design.colorTable": "선택된 테마 색상표",
  "application.design.primaryColor": "메인 색상",
  "application.design.secondaryColor": "보조 색상(버튼 등)",
  "application.design.titleBar": "타이틀바 설정",
  "application.design.fontSize": "글자크기",
  "application.design.fontWeight": "글자굵기",
  "application.design.padding": "여백",
  "application.design.padding.help": "(top,right,bottom,left 순서로 콤마(,)로 분리: 예: 10,5,10,5)",
  "application.design.title.style": "형태",
  "application.design.title.style1": "전체 페이지 텍스트로 표시",
  "application.design.title.style2": "페이지 타이틀바 사용하지 않음(다른 페이지는 텍스트)",
  "application.design.title.style3": "모든 페이지 이미지 사용",
  "application.design.title.style4": "메인 페이지만 이미지 사용(다른 페이지는 텍스트)",
  "application.design.image": "이미지",
  "application.design.align": "정렬",
  "application.design.searchIconOnIndex": "메인페이지에 검색 아이콘 표시",
  "application.design.priceSetting": "가격표시설정",
  "application.design.priceSetting.display":"실제화면표시",
  "application.design.priceSetting.mainCurrency": "1열 표시 통화",
  "application.design.priceSetting.subCurrency": "2열 표시 통화",
  "application.design.priceSetting.usdFormat": "표기 방법",
  "application.design.priceSetting.showAbout": "CNY앞에 약(约)표시",
  "application.design.priceSetting.showDiscount": "할인 금액 표시",
  "application.design.priceSetting.CNY": "CNY",
  "application.design.priceSetting.productCurrency": "상품관리가격 통화",
  "application.design.priceSetting.paymentCurrency": "지불가격통화(PG사와 계약시 알려준 통화)",
  "application.design.priceSetting.hidden": "표시안함",
  "application.design.priceSetting.show": "표시",
  "application.design.priceSetting.writtenByText": "글자로 표시(숫자 뒤에 美元, 韩币)",
  "application.design.priceSetting.writtenBySymbol": "심볼로 표시(숫자 앞에 $, ₩)",
  "application.design.priceSetting.discountRate": "할인율",
  "application.design.priceSetting.discountPrice": "할인가격",
  "application.design.webmall": "웹몰설정",
  "application.design.webmall.banner": "배너그림",
  "application.design.webmall.logo": "로고",
  "application.design.message.deployAfterSave": "저장후 배포 처리하세요.",
  "application.design.message.deployed": "배포 되었습니다.",
  "application.design.message.wrongFileType": "잘못된 파일 유형입니다.",

  "products.product": "상품",
  "products.source": "상품출처",
  "products.attr": "속성",
  "products.productMarket.desc": "해당 상품은 요우마 서비스를 사용하는 <span style='color: red'>다른 공급상 또는 브랜드사가 제공하는 상품</span>으로" +
    " 판매를 원하는 상품을 선택한 후 <span style='color: red'>'내상품에 추가'</span> 버튼을 클릭하면 판매를 할 수 있습니다.<br/>" +
    "상품의 정보나 등은 수정할 수 없으면 상품의 Tag만 추가해서 관리할 수 있습니다. " +
    "판매 후 주문이 확인되면 상품을 등록한 공급상이 소비자에게 배송하게 됩니다.<br/>" +
    "상품의 공급 단가, 판매 조건 등에 상세한 부분은 시스템 운영자에게 문의하세요.",
  "products.openToMarket": "마켓에 공유",
  "products.openToMarket.comment": "다른 판매 회사들에게 상품 정보를 제공하여 판매하는 경우 체크",
  "products.openToMarketOnly": "상품마켓에 제공 상품만",
  "products.addToMyProduct": "내상품에 추가",
  "products.removeFromMyProduct": "내상품에서 삭제",
  "products.confirmRemoveFromMyProduct": "내상품에서 삭제하시겠습니까?",
  "products.addedToMyProduct": "추가됨",
  "products.source.my": "내상품",
  "products.source.productMarket": "마켓상품",
  "products.preview": "미리보기",
  "products.wholesaleProduct": "도매몰상품",
  "products.wholesaleMinProducts": "최소구매수량",
  "products.wholesaleProduct.comment": "중국 도매상에게 판매하고자 하는 상품(pifa.youma.kr에 노출됨)",
  "products.message.selected.copied": "외부 상품이 선택되어 있습니다. 외부 상품은 수정할 수 없습니다.",
  "products.id": "상품ID",
  "products.title": "상품관리",
  "products.button.new": "등록",
  "products.button.search": "조회",
  "products.name": "상품명",
  "products.name.language": "상품명언어",
  "products.name.cn": "중국",
  "products.totalSales": "판매량",
  "products.id.kind": "ID 유형",
  "products.ignore.others": "(다른 조건은 무시됨)",
  "products.brand": "브랜드",
  "products.brandId": "브랜드",
  "products.name.ko": "한국어",
  "products.name.kr": "한국어",
  "products.name.en": "영어",
  "products.style": "스타일",
  "products.cbtType": "수출방식",
  "products.category": "분류",
  "products.categoryId": "분류",
  "products.sort": "정렬",
  "products.sort.latest": "최신순",
  "products.sort.lowest": "가격낮은순",
  "products.sort.highest": "가격높은순",
  "products.sort.name_cn": "상품명_중문",
  "products.sort.name_kr": "상품명_한글",
  "products.sort.total_sales_desc": "많이팔린순",
  "products.taxRate": "세율",
  "products.price": "가격",
  "products.price.rep": "대표가격",
  "products.published": "공개 여부",
  "products.published.true": "공개",
  "products.published.help": "쇼핑몰의 상품목록에 나타나지 않음",
  "products.published.false": "비공개",
  "products.eventProduct": "이벤트상품",
  "products.hideToUser": "공유로만 판매",
  "products.hideToUser.help": "소비지가 구매는 가능하지만 메인페이지, 상품검색 페이지 등에 노출되지 않는 상품<br/>관리자가 공유를 통해서만 판매를 할 경우 사용",
  "products.upcoming": "Upcoming",
  "products.sharedServices": "공유할 서비스",
  "products.removed": "백업 후 삭제",
  "products.featuredImage": "메인 이미지",
  "products.images": "이미지",
  "products.descriptions": "상세 설명 이미지",
  "products.tag": "Tag",
  "products.tag.message": "'{name}'은(은) 이미 존재하는 태그입니다.",
  "products.addTag": "Tag추가",
  "products.originCountry": "원산지",
  "products.delivery": "배송",
  "products.deliveryFee": "배송료",
  "products.profit": "수익",
  "products.listPrice": "할인전표시가격",
  "products.salesPrice": "판매가격",
  "products.minMaxSalesPrice": "소매판매가격범위",
  "products.bundled": "묶음판매",
  "products.bundled.comment": "묶음 판매는 상품에 구성된 모든 옵션을 동시에 구매하는 경우선택하세요<br/>" +
    "예를 들어 \"상품A\"와 \"상품B\"를 동시에 구매할 경우 할인 행사를 하는 경우 행사 상품을 만들때 \"판매 옵션 입력\" 입력 시 상품A, 상품B를 선택하고, 이 묶음 판매 옵션을 활성화 시키면 됩니다.",
  "products.autoExpiredAt": "만료일",
  "products.options": "상품 옵션",
  "products.options.count": "상품 옵션 갯수",
  "products.selectProduct": "상품선택",
  "products.options.count.single": "한개만 있는 상품",
  "products.options.count.multi": "여러개 있는 상품",
  "products.options.split": "옵션 구분하여 표시",
  "products.price.help": "대표가격은 여러개 상품을 묶은 상품이나, 옵션별 가격이 다른 경우에<br/>" +
    "상품 목록에 표시되는 가격으로 관리자가 임의로 결정해서 입력합니다.<br/>" +
    "옵션이 한개만 있는 경우 옵션의 판매가격, 할인전 가격과 같은 값을 입력합니다.",
  "products.options.add": "옵션 추가",
  "products.options.comment": "Option은 미니프로그램에서 상품 옵션 선택 시 사용, <span style='color: red'>이 정보 수정 시 SKU에서도 수정해야 함</span>",
  "products.options.name": "옵션명",
  "products.options.optionValues": "값 목록",
  "products.options.active": "Active",
  "products.options.edit": "편집",
  "products.options.delete": "삭제",
  "products.button.addProduct": "상품추가",
  "products.button.eventProduct": "이벤트상품",
  "products.button.addEventProduct": "이벤트상품 추가",
  "products.button.testOrder": "주문테스트",
  "products.button.coupon": "쿠폰",
  "products.button.selectFileOrUpload": "파일선택 또는 업로드",
  "products.edit.basic": "기본 정보 입력",
  "products.edit.detail": "옵션 입력",
  "products.edit.desc": "상세 설명",
  "products.edit.inputImage": "외부 이미지주소 직접 입력",
  "products.edit.selectImage": "업로드 또는 업로드된 이미지 선택",
  "products.upload.help1": "아래 샘플 파일을 다운로드 받아 수정하고 '파일선택'로 파일을 선택 후 '확인' 버튼을 누르면 상품이 등록 됩니다.",
  "products.upload.help2": "샘플 파일",
  "products.upload.help3": "파일선택",
  "products.stopSales": "판매종료",
  "products.stopSales.help": "상품목록 등에는 나타나지만 구매는 할수 없음",
  "products.marketProductPublished": "원본상품 변경사항",
  "products.marketProductPublished.help1": "상품 옵션 정보에서 확인 후 저장하면 이 메시지는 사라집니다.",
  "products.marketProductPublished.help2": "원본 상품의 공개상태가 비공개 상태입니다.",
  "products.supplierProductId": "외부공급상 상품ID",
  "products.supplier": "공급상코드",
  "products.imageLink": "이미지링크",
  "products.base64.error": "base64 이미지는 첨부할 수 없습니다.",
  "products.relatedProducts": "연관상품",
  "products.list.moreSku": "{count}개 SKU 더보기",
  "products.list.closeSku": "SKU 숨기기",

  "products.sku": "상품 정보(옵션)",
  "products.sku.add": "옵션 추가",
  "products.sku.edit": "옵션 수정",
  "products.sku.basicInfo": "옵션 기본정보",
  "products.sku.priceInfo": "가격 정보({symbol})",
  "products.sku.salesInfo": "판매 정보",
  "products.sku.stocksInfo": "재고 정보",
  "products.sku.declareInfo": "통관 정보",
  "products.sku.optionText": "옵션명",
  "products.sku.barCode": "바코드",
  "products.sku.skuCode": "내부코드",
  "products.sku.salesPrice": "개당판매가격",
  "products.sku.listPrice": "할인전가격",
  "products.sku.purchasePrice": "매입가격",
  "products.sku.supplyPrice": "판매공급가",
  "products.sku.minSalesPrice": "판매최소가격",
  "products.sku.maxSalesPrice": "판매최대가격",
  "products.sku.salesQuantity": "판매수량",
  "products.sku.displayStocks": "화면표시재고",
  "products.sku.realStocks": "실제재고",
  "products.sku.stocks": "재고",
  "products.sku.taxRate": "세율",
  "products.sku.hsCode": "HS-CODE",
  "products.sku.grossWeight": "총중량",
  "products.sku.netWeight": "상품중량",
  "products.sku.volume": "부피",
  "products.sku.package": "포장규격",
  "products.sku.unit": "포장단위",
  "products.sku.unitQty": "포장내수량",
  "products.sku.ingredients": "주성분",
  "products.sku.customsOption": "세관옵션",
  "products.sku.imageUrl": "옵션이미지",
  "products.sku.addedList": "추가된 옵션 목록",
  "products.sku.copied.message": "복사되었습니다. 입력항목에서 옵션명, 바코드 등을 입력한 후 [추가] 버튼을 클릭하세요.",
  "products.sku.exist.optionText": "옵션명은 이미 존재합니다.",
  "products.sku.exist.barCode": "바코드는 이미 존재합니다.",
  "products.sku.button.apply": "반영",
  "products.sku.salePrice.overMinMax": "판매가격이 최소판매가격 또는 최대판매가격을 초과 했습니다",
  "products.sku.taxRateDesc": "통관유형이 9610, 1210에 대해서만 입력<br/>" +
    "주의: 세금은 판매 후 중국 해관에서 주기적으로 정산 정보를 제공합니다.<br/>" +
    "해관에 납부하는 세금은 수입 신고 시 신고자가 신고하는 금액이 아니라, 해관에서 직접 별도로 계산된 금액으로 청구됩니다.<br/>" +
    "통관 신고 시 세울을 잘못 신고해도 통관은 될 수 있습니다.<br/>" +
    "따라서 세율을 잘못 입력해서 발생하는 손실에 대해서는 고객사가 책임을 집니다.<br/>",
  "products.sku.newSkuHelp": "하나의 상품에 여러 옵션이 있는 경우 옵션명, 바코드 등 <br/>" +
    "몇개 정보만 제외하고는 대부분의 정보는 동일하다.<br/>" +
    "따라서 하나만 입력한 후 필요한 내용만 수정하여 계속 추가할 수 있다.<br/>" +
    "<hr/>" +
    "기존 상품에 옵션을 추가하는 경우에는 기존 옵션을 클릭하면 <br/>" +
    "입력란에 기존 옵션의 정보가 채워지고 옵션명, 바코드 등 다른 정보만 수정한 후 \"새로운 옵션으로 추가\" 버튼을 클릭한다.",
  "products.sku.updateSkuHelp": "기존에 입력한 옵션을 수정하는 경우<br/>" +
    "1. 아래 테이블에서 옵션을 클릭한다.<br/>" +
    "2. 선택한 옵션의 값이 입력 항목에 나타난다.<br/>" +
    "3. 정보를 수정한 후 \"반영\" 버튼을 클릭한다.<br/>",

  "products.sku.optionText.help": "소비자가 구매시 선택하는 상품 옵션",
  "products.sku.barCode.help": "상품 포장에 있는 바코드",
  "products.sku.skuCode.help": "회사 내부에서 관리하는 코드",
  "products.sku.salesPrice.help": "실제판매가격으로 소비자가 지불하는 금액",
  "products.sku.listPrice.help": "권장소비자가격, 0이 아니면 할인전 가격 형태로 노출됨",
  "products.sku.purchasePrice.help": "상품의 원가, 관리를 위한 가격으로 소비자에게 노출되지 않음",
  "products.sku.supplyPrice.help": "판매상을 이용하여 판매하는 경우 판매상에게 공급하는 가격",
  "products.sku.minSalesPrice.help": "판매사를 통한 판매를 하는 경우 판매사가 설정할 수 있는 최소 판매 금액",
  "products.sku.maxSalesPrice.help": "판매사를 통한 판매를 하는 경우 판매사가 설정할 수 있는 최대 판매 금액",
  "products.sku.salesQuantity.help": "한번 구매 시 배송되는 수량(기본값은 1), 묶음 판매인 경우에 사용. 예: 4개 구매시 40%할인 판매",
  "products.sku.displayStocks.help": "소비자 화면에 표시되는 재고 수량, 관리하지 않는 입력하지 않아도 됨. 미니프로그램 UI 관리에서 표시안함 설정할 경우 표시하지 않음",
  "products.sku.realStocks.help": "실제 재고량, 관리하지 않는 입력하지 않아도 됨",
  "products.sku.hsCode.help": "수입 신고시 필요한 HSCODE. 통관 플러그인 사용 시 반드시 입력",
  "products.sku.customsOption.help": "중국 해관에서 정의한 옵션 규격에 맞춘 옵션 상세 내용. 통관 플러그인 사용 시 반드시 입력",
  "products.sku.grossWeight.help": "포장이후 중량",
  "products.sku.netWeight.help": "상품 외부에 표기된 중량",
  "products.sku.volume.help": "상품 외부에 표기된 부피",
  "products.sku.package.help": "포장방법. 예: 종이박스(纸箱)",
  "products.sku.unit.help": "마스크 팩인 경우 포장 단위가 상자이고 상자 내에 여러개의 팩을 포함하고 있는 겨웅 \"상자\"로 선택",
  "products.sku.unitQty.help": "예를 들어 마스크 팩의 경우 포장단위가 상자가 되고, 1상자 내에 개별포장되어 있는 상품의 수, 포장단위가 \"개\"인 경우 일반적으로는 1",
  "products.sku.ingredients.help": "주성분으로 통관시 필요한 정보. 통관 플러그인 사용 시 반드시 입력",
  "products.sku.priceByQty": "수량별가격",
  "products.sku.quantity": "수량",
  "products.sku.price": "가격",
  "products.sku.addQty": "수량추가",
  "products.sku.inputPriceByQuantity": "수량별 가격정보를 입력하세요.",
  "products.sku.requiredAtLeastOne": "가격 범위는 최소 1개 이상 필요합니다.",
  "products.sku.inputPrice": "{number}번쩨 가격을 입력하세요.",
  "products.sku.lessThanPrevious": "{number}번째 수량이 이전 수량보다 작거나 같습니다.",
  "products.sku.greatThanPrevious": "{number}번째 가격이 이전 가격보다 크거나 같습니다.",
  "products.sku.checkPrice" : "수량별 가격정보 오류를 확인하세요.",
  "products.sku.productNotSelected": "상품이 선택되지 않았습니다.",
  "products.sku.skuNotSelected": "상품 옵션이 선택되지 않았습니다.",

  "products.desc.mode": "편집모드",
  "products.desc.mode.images": " 이미지",
  "products.desc.mode.html": "HTML 편집",
  "products.desc.edit": "상품상세편집",
  "products.share.user_id": "공유사용자ID",
  "products.share.user": "공유사용자",
  "products.share.date": "공유시간",
  "products.searchModal.title": "상품검색",
  "products.marketProductDisabled": "원본 상품의 공개 설정이 변경되었습니다.",
  "products.marketProductSkuChanged": "원본 상품의 SKU(옵션이) 추가 또는 삭제 되었습니다.",
  "products.marketProductDeleted": "원본 상품이 삭제 되었습니다.",
  "products.marketProduct.message": "마켓상품인 경우 원본 상품의 공개상태가 미공개이거나, 삭제된 경우 수정할 수 없습니다.",
  "products.warnFailedCopiedProduct": "마켓에서 가져온 상품 중 {count}개 상품이 비공개 또는 삭제 처리 되었습니다.",
  "products.exceedPriceRange": "상품의 대표 가격이 옵션 가격의 범위를 초과했습니다.",
  "products.market.alreadyAdded": "이미 추가된 상품입니다. 계속 추가 하시겠습니까?",
  "products.market.confirmAdd": "추가 하시겠습니까?",
  "products.selectSku": "상품 옵션을 선택하세요.",

  "order.order": "주문",
  "order.title": "주문관리",
  "order.searchOrderDate": "주문일자",
  "order.searchPayDate": "지불일자",
  "order.searchOrderDateStart": "시작일자",
  "order.searchOrderDateEnd": "종료일자",
  "order.list.productInfo": "상품정보",
  "order.list.paymentInfo": "지불정보",
  "order.list.userInfo": "구매자정보",
  "order.detail": "주문상세정보",
  "order.paymentHistory": "지불처리내역",
  "order.list.moreProduct": "{count}개 상품 더보기",
  "order.list.closeProduct": "더보기 상품 숨기기",
  "order.merchandiserId": "Merchandiser",
  "order.orderNo": "주문번호",
  "order.externalOrderNo": "외부주문번호",
  "order.declareOrderNo": "해관신고 주문번호",
  "order.userId": "사용자ID",
  "order.userName": "주문자성명",
  "order.userId.help": "통관을 위해서는 반드시 신분증의 실명 및 신분증 번호를 입력",
  "order.phone": "전화번호",
  "order.offerId": "OfferID",
  "order.salesManagerId": "판매자ID",
  "order.search.download.comment": "엑셀 다운 시 배송 준비중 처리(통관불필요 또는 지불완료건만)",
  "order.upload.deliveryNo": "택배번호 업로드",
  "order.fetch.weimobOrder": "웨이멍주문",
  "order.featuredImage": "이미지",
  "order.offerTitle": "오퍼",
  "order.status": "상태",
  "order.paymentAmount": "지불금액",
  "order.paymentAmountPay": "지불요청금액",
  "order.userPaidAmountFormula": "계산식",
  "order.paymentAmountUser": "실제지불금액",
  "order.paymentCompany": "지불회사",
  "order.settlement.detail": "정산내역",
  "order.taxTotal": "세금",
  "order.customClearance": "통관필요",
  "order.noCustomClearance": "통관필요",
  "order.orderDate": "주문일자",
  "order.orderProduct": "주문상품",
  "order.finishedAt": "종료일자",
  "order.idCardNo": "신분증 번호",
  "order.shippingAddress": "배송지",
  "order.recipient": "수령인",
  "order.address": "주소",
  "order.productAmount": "상품판매가격",
  "order.discountAmount": "할인금액",
  "order.deliveryFee": "배송료",
  "order.failedReason": "실패 사유",
  "order.failedMessage": "실패 메시지",
  "order.verifyDepartmentTradeId": "지불신고ID",
  "order.chinaportSessionId": "세관 Session ID",
  "order.localLogisticsNo": "국내 물류 ID",
  "order.deliveredAt": "배송일자",
  "order.deliveryCompany": "배송회사",
  "order.refund.button": "환불",
  "order.refund": "환불",
  "order.refund.help": "지불에 대한 환불 처리는 별도로 해야 함",
  "order.refundAt": "환불일자",
  "order.requireIdCard": "신분증사진 요구",
  "order.idCardStatus": "신분증 사진 제출 상태",
  "order.idCardStatus.none": "미처리",
  "order.idCardStatus.uploaded": "사용자 업로드 완료",
  "order.idCardStatus.submitted": "세관 제출 완료",
  "order.managerMarker": "메모",
  "order.addManagerMarker": "메모추가",
  "order.clientApp.weimob": "웨이멍",
  "order.clientApp.etc": "기타",
  "order.orderDelivery": "배송",
  "order.offerListPrice": "상품 가격(개당)",
  "order.quantity": "수량",
  "order.orderAmount": "주문 금액",
  "order.taxRate": "세율",
  "order.cbtType": "수출방식",
  "order.cbtType.1210": "중국내 보세창고（1210）",
  "order.cbtType.9610": "직구（9610)",
  "order.cbtType.mail": "행우세",
  "order.cbtType.none": "통관안함",
  "order.cbtType.customOrderGuid": "주문세관신고번호",
  "order.cbtType.customReqGuid": "통관세관신고번호",
  "order.button.sendPayment": "지불신고전송",
  "order.button.reSendPayment": "지불신고 재전송",
  "order.button.sendLocalLogistics": "국내물류 주문전송",
  "order.button.sendLogistics": "물류단 전송",
  "order.button.sendOrder": "주문단 전송",
  "order.button.sendClearance": "해관수입신고 전송",
  "order.button.cancel": "주문취소",
  "order.sendPayment.comment": "(데이터 전송, 지불완료인 주문만 전송)",
  "order.sendOrderToCustoms.comment": "(데이터 전송, 세관처리중(WeChat) 주문만 전송)",
  "order.logisticsInfo": "통관 및 물류정보",
  "order.deliveryInfo": "배송지",
  "order.paymentInfo": "지불내역",
  "order.total.paymentAmount": "총지불금액",
  "order.sellerUserId": "판매원",
  "order.finish.status": "완료상테",
  "order.managerComment": "관리자 코멘트",
  "order.managerComment.help": "구매자 주문 상세 화면에 표시됨",

  "order.status.": "없음",
  "order.status.processing_clearance_9610": "해관통관처리중(9610)",
  "order.status.created": "생성완료",
  "order.status.prepared_wx_prepay": "지불확인중",
  "order.status.pay_prepared": "지불대기중",
  "order.status.paid": "지불완료",
  "order.status.cancel_req": "취소요청",
  "order.status.wait_pay": "지불대기",
  "order.status.wx_customs_success": "지불단 신고완료",
  "order.status.pay_declared": "해관신고중(지불신고완료)",
  "order.status.pay_declared1": "지불신고완료",
  "order.status.send_customs_success": "통관중",
  "order.status.declaring": "통관중",
  "order.status.declared": "통관완료",
  "order.status.order_declaring": "해관신고중(주문신고)",
  "order.status.order_declared": "해관신고중(주문신고완료)",
  "order.status.order_declared1": "주문신고완료",
  "order.status.preparing_delivery": "배송준비중",
  "order.status.prepare_delivery": "배송준비중",
  "order.status.local_delivering": "한국내 배송중",
  "order.status.delivering": "배송중",
  "order.status.delivered": "배송완료",
  "order.status.finished": "처리완료",
  "order.status.failed": "실패",
  "order.status.refund": "환불",
  "order.status.cancel": "취소",
  "order.status.user_cancel": "사용자취소",
  "order.status.system_cancel": "자동취소",
  "order.failed.payment_prepay_failed": "지불준비실패",
  "order.failed.payment_failed": "지불실패",
  "order.failed.call_wx_customdeclareorder_failed": "지불단 요청 실패",
  "order.failed.call_trade_push_failed": "해관 수입신고 전송 실패",
  "order.failed.trade_push_result_failed": "해관 통관 미통과",
  "order.status.success": "완료",
  "order.status.sent": "보세구전송완료",

  "order.payment.refundAmount": "환불금액",
  "order.payment.status.get_prepay_id": "지불준비중",
  "order.payment.status.success": "지불성공",
  "order.payment.status.failed": "지불실패",
  "order.payment.status.refund": "환불",

  "order.declare.status.created": "신고데이터생성완료",
  "order.declare.status.wait_agent": "전송대기(SingleWindow)",
  "order.declare.status.agent_fetched": "전송중(SingleWindow)",
  "order.declare.status.sent": "전송완료(SingleWindow)",

  "order.reason.prepare_pay": "지불준비실패",
  "order.reason.payment": "지불실패",
  "order.reason.pay_declared": "해관신고(지불신고)실패",
  "order.reason.order_declared": "해관신고(주문신고)실패",
  "order.reason.clearance": "해관통관신고실패",
  "order.reason.etc": "기타",
  "order.reason.none": " ",
  "order.declareInfo": "통관정보",
  "order.additional": "부가정보",
  "order.noOrder": "주문정보를 찾을 수 없습니다.",
  "order.error": "주문정보 조회 중 오류가 발생했습니다",

  "order.sku.productName": "상품명",
  "order.sku.skuId": "SKU ID",
  "order.sku.skuName": "SKU명",
  "order.sku.barcode": "바코드",
  "order.sku.optionText": "옵션",
  "order.sku.offerListPrice": "상품가격",
  "order.sku.quantity": "주문수량",
  "order.sku.shipQuantity": "배송수량",
  "order.sku.salesQuantity": "판매수량",
  "order.sku.productAmount": "판매금액",
  "order.sku.imageUrl": "이미지",
  "order.sku.discountAmount": "할인",
  "order.sku.salesAmount": "주문금액",
  "order.sku.taxAmount": "세금",
  "order.sku.offerTitle": "오퍼",
  "order.sku.salesManagerUserId": "판매자",
  "order.sku.campaignId": "캠페인",
  "order.sku.hsCode": "HS Code",
  "order.sku.customsOptionText": "세관옵션명",
  "order.sku.unit": "수량단위",
  "order.sku.unit1": "법정단위",
  "order.sku.qty": "법정단위수량",
  "order.sku.unit2": "수량단위2",
  "order.sku.qty2": "수량2",
  "order.sku.weight": "무게(kg)",
  "order.sku.volume": "부피(m2)",
  "order.sku.salesPrice": "단가",

  "order.wechatpay": "위챗 지불",
  "order.custominterface": "통관처리이력",

  "order.delivery.logisticsCompany": "물류기업",
  "order.delivery.logisticsNo": "배송번호",
  "order.delivery.trafMode": "운송방식(TrafMode)",
  "order.delivery.trafNo": "운송편번호(TrafNo)",
  "order.delivery.voyageNo": "운송편회차번호(VoyageNo)",
  "order.delivery.billNo": "운송장번호(BillNo)",
  "order.delivery.grossWeight": "포장중량(GrossWeight)",
  "order.delivery.netWeight": "상품중량(NetWeight)",
  "order.button.deliveryAndClearance": "통관/배송관리",
  "order.button.delivery": "배송관리",
  "order.clearance.message.send_to_payment": "해관으로 지불단 신고를 전송합니다. 계속하시겠습니까?",
  "order.clearance.payment.help": "WechatPay, AliPay 등의 지불회사로 지불정보 해관 신고를 요청합니다.",
  "order.clearance.payment_detail": "지불단 신고 상세내용",
  "order.clearance.payment_info": "전송 정보",
  "order.clearance.payment_result": "결과",
  "order.clearance.createDeclareInfo.confirm": "신고 정보를 생성합니다. 계속하시겠습니까?",
  "order.clearance.createDeclareInfo": "신고정보생성",
  "order.clearance.declareInfo": "신고정보",
  "order.clearance.declare.price": "신고금액",
  "order.clearance.createDeclareInfo.help": "통관 처리를 위해서 신고 정보를 생성합니다. 신고 정보 생성 시에는 상품의 단가 재계산, 세금 계산 등의 과정을 수행합니다.",
  "order.clearance.sendToLogistics.help1": "주문 정보를 보세구 또는 중국내 물류업체로 전송합니다",
  "order.clearance.sendToLogistics.help2": "<span style={{color: 'red'}}>중복 전송하는 경우 중복 배송</span>이 될 수 있기 때문에 전송 이력이 있는 경우 보세구 등에 확인 후 재전송 하세요.",
  "order.clearance.sendToLogistics.request": "전송정보",
  "order.clearance.sendToLogistics.response": "수신정보",
  "order.clearance.sendToLogistics.confirm": "주문 정보를 전송하시곘습니까?",
  "order.clearance.sendToLocalLogistics.company": "제휴 물류회사",
  "order.clearance.sendToLocalLogistics.help": "<span style={{color: 'red'}}>중복 전송하는 경우 중복 배송</span>이 될 수 있기 때문에 전송 이력이 있는 경우 물류회사 확인 후 재전송 하세요.",
  "order.clearance.sendToLocalLogistics.noCompany": "국내 물류회사 정보가 없습니다. 미니프로그램 설정에서 국내 물류정보를 입력하세요.",
  "order.clearance.sendToLocalLogistics.confirm": "한국 내 물류회사로 주문 정보를 전송합니다. 계속하시겠습니까?",

  "order.clearance": "수입신고",
  "order.clearance.payment": "지불단 신고",
  "order.clearance.order": "주문단 신고",
  "order.clearance.order.bonded": "주문전송(보세구)",
  "order.clearance.delivery": "배송정보",
  "order.clearance.deliveryStatus": "배송현황",
  "order.clearance.prepareDelivering": "배송준비",
  "order.clearance.delivering": "배송중",
  "order.clearance.localDelivery": "국내물류요청",
  "order.clearance.deliveryInfo": "물류정보",
  "order.clearance.logistics": "물류단 신고",
  "order.clearance.declare_company_interface_type.send_order": "주문정보전송",
  "order.clearance.declare_company_interface_type.callback_delivery": "배송정보회신",
  "order.clearance.declare_company_interface_type.callback_order": "주문정보회신",
  "order.deliveryNo": "베송번호",
  "order.noDeliveryNo": "베송번호가 없습니다",
  "order.delivery.tracking": "배송현황조회",
  "order.delivery.noTracking": "배송정보가 없습니다.",
  "order.deliveryInfo.help": "보세구에서 \"배송정보수신(From 보세구)\" 회신을 받아서 자동으로 배송번호가 저장됨. <br/>배송번호가 자동으로 저장이 안되는 경우 수작업으로 입력합니다.",
  "order.deliveryInfo.sendToWeimeb": "배송정보 weimob으로 전송",
  "order.clearance.sendToBondedArea": " 보세구로 주문정보를 전송합니다. \"보세구수입신고(保税区清单)\"를 정상적으로 전송한 후 <br/>\n" +
    "                  \"주문전송수신(From 보세구)\" 회신을 정상적으로 받아야 합니다. <br/>\n" +
    "                  \"주문전송수신(From 보세구)\"은 보세구에서 확인 후 회신을 받습니다.<br/>\n" +
    "                  배송 번호가 있으면 취소 처리할 수 없습니다.",
  "order.clearance.apyment.help": "WechatPay, AliPay 등의 지불회사로 지불정보 해관 신고를 요청합니다.",
  "order.clearance.table.date": "일시",
  "order.clearance.table.type": "타입",
  "order.clearance.table.status": "상태",
  "order.clearance.table.errorMessage": "에러메시지",
  "order.clearance.table.success": "성공",
  "order.clearance.table.failed": "실패",
  "order.clearance.table.checkStatus": "전송상태확인",
  "order.clearance.table.delete": "삭제",
  "order.clearance.table.title.agentStatus": "신고전송상태",
  "order.clearance.table.agentStatus.notSent": "미전송",
  "order.clearance.table.agentStatus.sending": "전송중",
  "order.clearance.table.agentStatus.sent": "전송완료",
  "order.clearance.table.agentStatus.error": "오류(관리자 문의 필요)",
  "order.clearance.table.title.sendDate": "신고전송일시",
  "order.clearance.table.title.orderFile": "신고파일",
  "order.clearance.receive.deliveryInfo": "배송정보수신(From 보세구)",
  "order.clearance.message.payment_not_declared": "지불단 신고가 정상적으로 처리되지 않았습니다. 지불단 신고가 정상 처리된 후 전송 가능합니다.",
  "order.clearance.message.already_sent_to_bonded_area": "이미 보세구로 주문 전송을 처리하였습니다.",
  "order.clearance.message.send_to_bonded_area": "보세구로 주문 통관을 요청하는 기능입니다. 계속하시겠습니까?",
  "order.clearance.message.send_to_local_logistics": "국내 물류 회사로 주문 정보를 전송합니다. 계속하시겠습니까?",
  "order.clearance.message.send_to_logistics": "중국내 수입 물류 회사로 물류단 세관 신고 정보를 전송합니다. 계속하시겠습니까?",
  "order.clearance.message.send_to_agent": "생성된 주문단 신고문서를 해관으로 전송합니다. 주문단 전송은 PC에 설치된 单一窗口客户端 프로그램을 이용하여 전송됩니다.",
  "order.clearance.message.send_to_agent_help": "현재 미니프로그램의 설정은 주문단 신고문서의 생성은 물류원에 의해 처리 됩니다. <p/>" +
        "주문단 전송은 이 화면에서는 처리하지 않으며, PC에 설치된 单一窗口客户端에서 확인하세요.",
  "order.clearance.message.send_order": "세관으로 주문단 정보를 전송합니다. 계속하시겠습니까?",
  "order.clearance.message.clearance": "세관으로 수입신고단 정보를 전송합니다. 계속하시겠습니까?",
  "order.clearance.message.wrong_request": "{action}는 잘못된 요청입니다",
  "order.clearance.message.payment_already_sent": "이미 지불단 신고를 처리하였습니다. 다시 전송할까요?",
  "order.clearance.message.cancel_order": "사용자가 주문을 취소한 경우에 대한 처리 입니다. 취소 하시겠습니까?",
  "order.clearance.message.cannot_cancel_order": "상태가 [실패], [완료]인 주문은 취소할 수 없습니다.",
  "order.clearance.message.canceled": "취소 처리되었습니다.",
  "order.clearance.help.local_logistics": "국내 물류 회사로 주문 정보를 전송합니다. 물류회사에서 주문 정보를 받으면 포장 및 수출 선적을 처리합니다.",
  "order.clearance.help.input_logistics_input": "국내 물류회사로부터 정보를 받아 입력합니다.(이후 시스템 연동을 통해 자동으로 처리할 예정입니다.)",
  "order.clearance.help.send_to_logistics_input": "중국내 물류회사로 물류단 신고를 위한 정보를 전송합니다. 물류 정보의 모든 항목을 입력해야 전송할 수 있습니다.",
  "order.clearance.help.send_to_customs": "주문단을 해관으로 신고합니다.",
  "order.clearance.send_detail": "해관 신고 상세내용",
  "order.clearance.send_info": "전송 정보",
  "order.clearance.receive_info": "회신 내용",
  "order.clearance.error_message": "에러 메시지",
  "order.clearance.resetDiscountAmount": "할인금액 0원으로 설정",
  "order.clearance.resetDiscountAmount.help": "할인금액 0원으로 설정 시 할인 금액을 0으로 변환하고, 판매단가를 재계산합니다.",
  "order.clearance.eximbay": "Exambay 지불 해관 신고 현황",
  "order.clearance.send": "주문신고전송",
  "order.declareOrder.declareAmount": "신고금액",
  "order.declareOrder.salesAmount": "상품가격",
  "order.declareOrder.sentAt": "전송일자",
  "order.declareOrder.xml": "신고용 XML 문서",
  "order.declareOrder.products": "주문상품",

  "order.weimob.title": "웨이멍 주문 정보 연동",
  "order.weimob.help1": "주문 정보를 가져오기 전에 해야 할일",
  "order.weimob.help2": "회사관리 -> 미니프로그램 -> 해관 계정 정보 입력",
  "order.weimob.help3": "회사관리 -> 미니프로그램 -> 웨이멍 API Key 정보 입력",
  "order.weimob.orderNo": "웨이멍 주문 번호",
  "order.weimob.order": "웨이멍주문",
  "order.weimob.maxOrders": "한번에 최대 10개 주문만 가능합니다.",
  "order.message.weimob.confirm": "웨이멍 주문번호 {weimobOrderNo}를 주문번호 {orderNo}로 저장하였습니다 \n 계속 웨이멍 다른 주문을 저장 하시겠습니까?",

  "order.upload.fileType": "파일타입",
  "order.upload.fileType.postfix": " 샘플파일 ",
  "order.upload.sampleFile": "샘플다운로드",
  "order.upload.selectFile": "파일을 선택하세요.",
  "order.upload.uploadFile": "파일내용확인",
  "order.upload.uploadAll": "전체업로드",
  "order.upload.uploadSelected": "선택업로드",
  "order.upload.maxOrders": "한번에 최대 20개 주문만 가능합니다.",

  "coupon.name": "이벤트명",
  "coupon.type": "이벤트유형",
  "coupon.type.welcome": "신규 가입자 쿠폰",
  "coupon.type.discount": "일반 할인(기간)",
  "coupon.type.share": "상품, 앱 공유 이벤트",
  "coupon.active": "상태",
  "coupon.active.true": "진행",
  "coupon.active.false": "미진행",
  "coupon.title": "쿠폰명",
  "coupon.discountType": "할인유형",
  "coupon.amountAndRate": "할인금액",
  "coupon.summary": "요약설명",
  "coupon.duration": "기간",
  "coupon.expiredAt": "만료일",
  "coupon.published": "공개",
  "coupon.discountType.price": "정가",
  "coupon.discountType.rate": "정률",
  "coupon.setting": "설정",
  "coupon.from.register": "가입일로부터",
  "coupon.to": "일 까지",
  "coupon.dateStart": "시작일자",
  "coupon.dateEnd": "종료일자",
  "coupon.issue.condition": "발급조건",
  "coupon.use.condition": "使用规则",
  "coupon.friends": "친구",
  "coupon.products": "할인상품",
  "coupon.products.all_products": "상품전체",
  "coupon.products.product": "지정된 상품",
  "coupon.products.brand": "특정 브랜드",
  "coupon.products.tag": "Tag 상품",
  "coupon.button.select.product": "상품선택",
  "coupon.button.select.tag": "Tag선택",
  "coupon.button.select.page": "페이지선택",
  "coupon.receive.like": "명이 공유하고 클릭받기",
  "coupon.share.object": "공유대상",
  "coupon.app": "미니프로그램",
  "coupon.page": "행사페이지",
  "coupon.canRedeem": "사용가능여부",
  "coupon.canRedeem.true": "사용가능",
  "coupon.canRedeem.false": "사용불가능",
  "coupon.redeemStatus": "사용여부",
  "coupon.redeemStatus.no": "미사용",
  "coupon.redeemStatus.check": "사용(지불확인중)",
  "coupon.redeemStatus.yes": "사용",
  "coupon.redeemAmount": "쿠폰사용금액",
  "coupon.redeemAt": "쿠폰 사용일",

  "category.category": "카테고리",
  "category.selected" : "선택한 카테고리",
  "category.level.1": "1 레벨",
  "category.level.2": "2 레벨",
  "category.modal.add": "카테고리 추가",

  "brand.name": "브랜드명",
  "brand.active": "사용",
  "brand.activeOnly": "사용만",
  "brand.modal.add": "브랜드추가",
  "brand.modal.edit": "브랜드수정",

  "dashboard.orderCount": "주문건수",
  "dashboard.orderQuantity": "주문수량",
  "dashboard.orderAmount": "매출현황",
  "dashboard.date.order": "주문일자기준",
  "dashboard.date.finish": "주문완료일자기준",
  "dashboard.orderSummary.OrderAmount": "Today so far",
  "dashboard.orderSummary.Yesterday": "Yesterday",
  "dashboard.orderSummary.YesterdayOrderAmout": "Same day last week",
  "dashboard.orderSummary.ThisMonth": "This month so far",
  "dashboard.orderSummary.ThisMonthSoFarAmount": "Same day last month",
  "dashboard.orderSummary.LastMonth": "Last month",
  "dashboard.orderSummary.LastMonthAmount": "Month  before",
  "dashboard.OrderDailySummary.title": "기간별 주문 현황",

  "log_finder.search": "검색",
  "log_finder.clear_filter": "필터 초기화",
  "log_finder.grouping": "Action 그룹",
  "log_finder.log_detail": "로그 상세정보",
  "log_finder.close": "닫기",

  "logistics.company.add": "물류회사 추가",
  "logistics.company.modify": "물류회사 수정",
  "logistics.company.name": "물류회사",
  "logistics.company.active": "사용 여부",
  "logistics.company.active.true": "사용",
  "logistics.company.active.false": "미사용",
  "logistics.company.weimob.code": "웨이멍코드",
  "logistics.company.search": "검색",

  "application.logistics.company.canuse": "사용 가능한 물류회사",
  "application.logistics.company.used": "사용중인 물류회사",

  "service_group.service": "서비스",
  "service_group.add_service": "서비스 추가",
  "service_group.new": "서비스그룹 추가",
  "service_group.name": "이름",
  "service_group.created_at": "등록일",
  "service_group.updated_at": "수정일",
  "service_group.action": "작업",
  "service_group.edit": "수정",
  "service_group.delete": "삭제",
  "service_group.save": "저장",
  "service_group.list": "목록",
  "service_group.input_service_name": "서비스 명을 입력하세요.",

  "file_manager.message.max_upload": "한번에 최대 5개 파일만 업로드 가능합니다.",
  "file_manager.message.exceed_file_size": "파일 {name} 크기가 3MB를 초과하였습니다.",
  "file_manager.message.selectFile": "파일을 선택하세요.",
  "file_manager.message.confirmDeleteFile": "선택하신 {count}개의 파일을 삭제 하시겠습니까？",

  "file_manager.fileKind": "파일종류",
  "file_manager.fileKind.index": "index 페이지",
  "file_manager.fileKind.product": "상품",
  "file_manager.fileKind.etc": "기타",
  "file_manager.uploadModal.title": "파일 업로드",
  "file_manager.folderName": "폴더명",
  "file_manager.fileName": "파일명",
  "file_manager.fileSize": "크기",
  "file_manager.image": "이미지",
  "file.name.placeholder": "파일명(영문 파일만 검색 가능)",

  "page_design.topPosition": "상단위치",
  "page_design.width": "넓이",
  "page_design.height": "높이",
  "page_design.size": "크기",

  "page_setting.page": "페이지",
  "page_setting.pageTitle": "페이지 관리",
  "page_setting.published": "공개",
  "page_setting.pageType": "페이지 타입",
  "page_setting.title": "페이지 제목",
  "page_setting.title.input": "{name}을 입력하세요(왼쪽 메뉴에서 설정 클릭)",
  "page_setting.message.inputClick": "클릭시 처리 정보를 입력하세요.",
  "page_setting.message.inputPopupClick": "Popup 클릭시 이동 항목을 입력하세요.",
  "page_setting.message.inputOpenAppID": "Popup 오픈 미니프로그램 App ID를 입력하세요.",
  "page_setting.message.inputMediaType": "Popup 컨텐츠의 미디어 종류를 선택하세요.",
  "page_setting.message.selectDeleteItem": "삭제할 항목을 선택해 주세요.",
  "page_setting.message.confirmDelete": "선택한 항목을 삭제하시겠습니끼?",
  "page_setting.message.cannotFindSetting": "해당 항목의 설정 값을 찾을 수 없습니다.",
  "page_setting.message.cannotFindItem": "해당 항목을 찾을 수 없습니다.",

  "page_setting.page.index": "메인페이지",
  "page_setting.page.sub": "서브페이지(행사 등)",
  "page_setting.page.product": "상품 상세 페이지",
  "page_setting.page.cart": "카트페이지",
  "page_setting.page.category": "카테고리페이지",
  "page_setting.page.category.menu": "항목설정",
  "page_setting.page.delivery": "배송상태페이지",
  "page_setting.page.my": "마이페이지",
  "page_setting.page.component": "컴포넌트",

  "page_setting.edit.icon.setting": "설정",
  "page_setting.edit.icon.search": "검색바",
  "page_setting.edit.icon.banner": "배너",
  "page_setting.edit.icon.image": "이미지",
  "page_setting.edit.icon.video": "동영상",
  "page_setting.edit.icon.product": "상품목록",
  "page_setting.edit.icon.text": "텍스트",
  "page_setting.edit.icon.line": "분리선",
  "page_setting.edit.icon.popup": "팝업",
  "page_setting.edit.icon.toolbar": "툴바",

  "page_setting.edit.publish": "배포",
  "page_setting.edit.publish.help": "도움말: 저장 버튼 클릭시 임시로 저장; 앱에 반영하기 위해서는 \"배포\" 버튼 클릭",
  "page_setting.edit.backgroundColor": "배경색상",
  "page_setting.edit.backgroundImage": "배경이미지",
  "page_setting.edit.borderColor": "테두리색상",
  "page_setting.edit.inputBackgroundColor": "입력필드 배경색",
  "page_setting.edit.pageTitle": "페이지 제목",
  "page_setting.edit.pageType": "페이지 타입",
  "page_setting.edit.pageTitle.component": "컴포넌트명",
  "page_setting.edit.shareImage": "공유 이미지",
  "page_setting.edit.shareImage.help": "(5:4비율, 600px)",
  "page_setting.edit.published": "공개 상테",
  "page_setting.edit.published.true": "공개",
  "page_setting.edit.published.false": "비공개",
  "page_setting.edit.published.help": "비공개로 하는 경우 해당 페이지에 사용자가 접근을 못하게 됨",
  "page_setting.edit.published.help1": "좌측의 각 항목을 클릭하면 설정 화면이 나타납니다.",
  "page_setting.edit.published.help2": "수정 후 저장하여도 사용자 화면에는 반영이 안됨. \"배포\" 버튼을 클릭해야 반영됨",

  "page_setting.edit.banner.title": "배너 설정",
  "page_setting.edit.banner.height": "Height",
  "page_setting.edit.banner.displayType": "표시방식",
  "page_setting.edit.banner.displayType.swiper": "슬라이드",
  "page_setting.edit.banner.displayType.split": "N분할",
  "page_setting.edit.banner.displayType.template": "템플릿에서 선택",
  "page_setting.edit.banner.displayType.icon": "아이콘",
  "page_setting.edit.banner.displayType.split.help": "분할비율(예시: 70,30)",
  "page_setting.edit.banner.autoPlay": "자동 넘김",
  "page_setting.edit.banner.autoPlayInterval": "자동 넘김 멈춤 시간",
  "page_setting.edit.banner.autoPlaySpeed": "자동 넘김 속도",

  "page_setting.edit.banner.area": "배너 영역",
  "page_setting.edit.banner.area.help": "오른쪽 설정에서 배너 항목(slot)을 추가하세요.",
  "page_setting.edit.banner.addedSlots": "추가된 슬롯",
  "page_setting.edit.banner.addSlot": "슬롯 추가",
  "page_setting.edit.banner.bannerMediaType": "미디어타입",
  "page_setting.edit.banner.bannerPath": "이미지 또는 동영상",
  "page_setting.edit.banner.bannerPathOrTitle": "이미지 또는 제목",
  "page_setting.edit.banner.action": "액션",
  "page_setting.edit.banner.param": "액션 파라미터",
  "page_setting.edit.banner.openApp": "다른 앱",
  "page_setting.edit.banner.modalTitle.update": "배너 슬롯 수정",
  "page_setting.edit.banner.modalTitle.add": "배너 슬롯 추가",

  "page_setting.edit.media.title": "제목",
  "page_setting.edit.media.image": "이미지",
  "page_setting.edit.media.video": "비디오",
  "page_setting.edit.media.media": "미디어종류",
  "page_setting.edit.media.file": "파일",
  "page_setting.edit.media.selectFile": "파일선택",
  "page_setting.edit.media.image.help": "배너인 경우: <br/>" +
      "이미지 비율: Width를 375px 기준으로 하여 설정한 Height 비율에 맞는 이미지 사용<br/>(예: 375 * 260, 750 * 520 등)<br/>" +
      "(Width 375px은 iPhone 6/7/8 기준이며 이 화면보다 해상도가 큰 디바이스를 위해 <br/>이미지 사이즈는 조금 더 큰 것으로 등록하는 것을 추천)",
  "page_setting.edit.media.action": "클릭시 이동",
  "page_setting.edit.media.action.none": "클릭시 이동하지 않음",
  "page_setting.edit.media.action.product": "상품상세 페이지로 이동",
  "page_setting.edit.media.action.contents": "컨텐츠 페이지로 이동(이미지/동영상)",
  "page_setting.edit.media.action.page": "다른 페이지로 이동",
  "page_setting.edit.media.action.brand": "특정 브랜드 상품 목록",
  "page_setting.edit.media.action.category": "특정 카테고리 상품 목록",
  "page_setting.edit.media.action.call_center": "고객센터로 이동",
  "page_setting.edit.media.action.home": "홈으로 이동",
  "page_setting.edit.media.action.cart": "카트로 이동",
  "page_setting.edit.media.action.singupCoupon": "가입자쿠폰",
  "page_setting.edit.media.action.openApp": "클릭시 다른앱으로 이동",
  "page_setting.edit.media.action.param": "클릭시 처리 정보",
  "page_setting.edit.media.action.help": "(Action이 상품이면 ProductID)<br/>" +
    "(Action이 컨텐츠 페이지 이동, 이미지 또는 동영상 Path 입력)",
  "page_setting.edit.media.openApp": "다른앱으로 이동",
  "page_setting.edit.media.appId": "미니프로그램 ID",
  "page_setting.edit.media.app.param": "오픈시 이동할 항목(상품 등) ID",
  "page_setting.edit.media.button.product": "상품선택",
  "page_setting.edit.media.button.contents": "파일선택",
  "page_setting.edit.media.button.page": "페이지선택",
  "page_setting.edit.media.button.home": "Home",
  "page_setting.edit.media.button.category": "Category",

  "page_setting.edit.basic.info": "기본설정",
  "page_setting.edit.toolbar.fixed": "위치",
  "page_setting.edit.toolbar.top": "TOP",
  "page_setting.edit.toolbar.bottom": "BOTTOM",
  "page_setting.edit.toolbar.none": "None",
  "page_setting.edit.toolbar.showTitle": "표시",
  "page_setting.edit.toolbar.title": "툴바",
  "page_setting.edit.toolbar.showIcon": "표시",
  "page_setting.edit.toolbar.iconSize": "아이콘",
  "page_setting.edit.toolbar.titleSize": "제목",
  "page_setting.edit.toolbar.fontSize": "폰트 크기",
  "page_setting.edit.toolbar.color": "색상",
  "page_setting.edit.toolbar.sort": "정렬",

  "page_setting.edit.product.title": "상품 목록",
  "page_setting.edit.product.slotType": "Display 형태",
  "page_setting.edit.product.slotType.product_1": "1열 1개",
  "page_setting.edit.product.slotType.product_2": "1열 2개",
  "page_setting.edit.product.slotType.product_3": "1열 3개",
  "page_setting.edit.product.slotType.slider": "좌우 슬라이드",
  "page_setting.edit.product.slotType.slider2": "2열 좌우 스크롤",
  "page_setting.edit.product.setting": "상품영역",
  "page_setting.edit.product.productAreaWidth": "한개상품넓이",
  "page_setting.edit.product.gap": "상품사이간격",
  "page_setting.edit.product.round": "상품라운드처리",
  "page_setting.edit.product.showGapBgColor": "상품분리표시",
  "page_setting.edit.product.showCart": "카트담기표시",
  "page_setting.edit.product.productWidth": "상품 Width",
  "page_setting.edit.product.show.help": "미리보기에 나타나는 크기와 실제 화면에서는 차이가 있습니다.",
  "page_setting.edit.product.search": "상품 검색 조건",
  "page_setting.edit.product.search.categoryId": "카테고리",
  "page_setting.edit.product.search.brandId": "브랜드",
  "page_setting.edit.product.search.tag": "Tag",
  "page_setting.edit.product.search.id": "직접상품선택",
  "page_setting.edit.product.search.all": "전체상품",
  "page_setting.edit.product.select": "상품선택",
  "page_setting.edit.product.addedSearch": "추가된 검색조건",
  "page_setting.edit.product.sort.help": "상품의 순서변경은 아래 상품 목록에서 마우스 드래그로 이동",
  "page_setting.edit.product.sort": "순서",
  "page_setting.edit.product.numProducts": "갯수",
  "page_setting.edit.product.moreProducts": "스크롤시 상품 더보기",
  "page_setting.edit.product.moreProducts.help": "마지막 상품 목록만 설정 가능",
  "page_setting.edit.product.addedProducts": "앱에 표시되는 상품 목록",
  "page_setting.edit.product.message1": "직접 상품을 선택하는 것과 다른 검색 조건을 같이 사용할 수 없습니다.",
  "page_setting.edit.product.message2": "검색 조건에 이미 직접 선택한 상품이 있습니다. 직접 상품을 선택하는 것과 다른 검색 조건을 같이 사용할 수 없습니다.",
  "page_setting.edit.product.message3": "검색 조건에 이미 다른 조건이 있습니다.",
  "page_setting.edit.product.displayArea": "상품 표시 영역",
  "page_setting.edit.product.pleaseSet": "오른쪽 설정에서 상품 표시 조건을 설정하세요.",
  "page_setting.edit.product.noProducts": "조건에 맞는 상품이 없습니다.",

  "page_setting.edit.image.setting": "이미지 설정",
  "page_setting.edit.image.setting.help": "설정한 이미지가 그냥 노출되는 형태로 첫페이지에 서비스의 소개 등을 넣을 때 사용",
  "page_setting.edit.image.size.help": "이미지 비율: Width를 375px 기준으로 하여 설정한 Height 비율에 맞는 이미지 사용<br/>(예: 375 * 260, 750 * 520 등)",

  "page_setting.edit.text.setting": "텍스트설정",
  "page_setting.edit.text": "텍스트",
  "page_setting.edit.text.fontSize": "크기",
  "page_setting.edit.text.textAlign": "정렬",
  "page_setting.edit.text.textAlign.left": "왼쪽",
  "page_setting.edit.text.textAlign.center": "가운데",
  "page_setting.edit.text.textAlign.right": "오른쪽",
  "page_setting.edit.text.padding": "여백",
  "page_setting.edit.text.padding.help": "top,right,bottom,left 순서로 콤마(,)로 분리: 예: 10,5,10,5",
  "page_setting.edit.text.color": "텍스트 색상(HEX)",
  "page_setting.edit.text.backgroundColor": "배경 색상(HEX)",

  "page_setting.edit.line.setting": "라인 설정",
  "page_setting.edit.line.color": "색깔",
  "page_setting.edit.line.height": "높이",
  "page_setting.edit.popup.setting": "Popup 설정",
  "page_setting.edit.popup.showEveryTime": "매번 나타내기",

  "page_setting.categoryPage": "카테고리 화면 메뉴 설정",
  "page_setting.categoryPage.addItem": "항목 추가",
  "page_setting.categoryPage.iconTitle": "텍스트",
  "page_setting.categoryPage.iconImage": "아이콘",

  "product_detail_page.sort": "정렬",
  "product_detail_page.page_setting": "상품상세페이지 설정",
  "product_detail_page.setting": "설정",
  "product_detail_page.addItem": "영역추가",
  "product_detail_page.productBanner": "상품 배너",
  "product_detail_page.noSettingItem": "설정 항목이 없습니다.",
  "product_detail_page.priceInfo": "상품 가격 정보",
  "product_detail_page.detailInfo": "상품 상세 정보",
  "product_detail_page.text": "직접입력",
  "product_detail_page.tag": "상품의 Tags",
  "product_detail_page.brand": "상품의 브랜드",
  "product_detail_page.optionText": "상품의 선택옵션",
  "product_detail_page.originCountry": "원산지",
  "product_detail_page.delivery": "배송비",
  "product_detail_page.item": "항목",
  "product_detail_page.title": "타이틀",
  "product_detail_page.value": "값",
  "product_detail_page.position": "위치",
  "product_detail_page.up": "위로",
  "product_detail_page.down": "아래로",
  "product_detail_page.contents": "내용",
  "product_detail_page.image.help": "<h4>이미지는 넓이 370px에 고정되고, 높이는 비율로 자동 계산 됩니다.</h4>",
  "product_detail_page.alreadyAdded": "이미 추가된 항목입니다.",
  "product_detail_page.confirmDeploy": "현재 화면 구성을 자동으로 저장한 후 배포합니다. 배포 하시겠습니까?",
  "product_detail_page.pageItem.type": "타입",

  "terms.pageTitle": "약관설정",
  "terms.termsType": "약관종류",
  "terms.termsType.user": "이용약관",
  "terms.termsType.privacy": "개인정보 수집 및 이용",
  "terms.published": "공개",
  "terms.not-published": "비공개",
  "terms.terms": "약관",
  "terms.useSampleTerm": "샘플약관 가져오기",
  "terms.publish.help": "저장 버튼 클릭시 임시로 저장; 앱에 반영하기 위해서는 \"공개\" 버튼 클릭",
  "terms.selectAll": "모두 동의합니다.",
  "terms.published.message": "공개 되었습니다.",
  "terms.private.message": "비공개 되었습니다.",
  "terms.setPublish.message": "현재 약관을 공개 하시겠습니까?",
  "terms.setPrivate.message": "현재 약관을 비공개 하시겠습니까?",
  "terms.sample.confirm1": "서비스 편의를 위해 제공하는 샘플입니다. \n반드시 검토/수정/보완 후 사용하세요. \n샘플 약관 사용에 대한 책임은 사용자에게 있습니다.",
  "terms.sample.confirm2": "편집중인 내용은 모두 삭제 됩니다.(화면만 삭제됨, DB는 저장시 반영). 진행하시겠습니까?",

  "address.province": "성",
  "address.city": "도시",
  "address.country": "구",

  "company.company": "회사",
  "company.name": "회사명",
  "company.companyCode": "회사코드",
  "company.companyCode.help": "회사 관리자 등록 시 회사를 식별할 수 있는 CODE. 미리보기 웹 URL 등으로 활용(영문으로 입력, 예: bettercode)",
  "company.businessRegistrationNumber": "사업자등록번호",
  "company.phone": "대표전화번호",
  "company.email": "대표이메일",
  "company.homePage": "홈페이지",
  "company.creator": "회사등록자",
  "company.button.searchUser": "사용자검색",

  "company.user.manager": "회사 관리자",
  "company.user.invite": "관리자 초대하기",
  "company.user.copy.link": "링크복사",
  "company.user.share.link": "아래 링크를 초대할 사용자에게 공유해주세요.",
  "company.user.copied": "복사되었습니다.",
  "company.user.name": "이름",
  "company.user.editing": "편집중",
  "company.user.userType": "사용자 유형",
  "company.user.userType.admin": "시스템 관리자",
  "company.user.userType.companyManager": "회사 관리자",
  "company.user.userType.appManager": "앱 관리자",
  "company.user.userType.seller": "판매사",
  "company.user.userType.supplier": "공급상",
  "company.user.userType.logistics": "물류 사용자",
  "company.user.userType.draft": "미승인",
  "company.user.basicInfo": "기본정보",
  "company.user.loginId": "로그인 ID",
  "company.user.changePassword": "비밀번호 변경",
  "company.user.email": "이메일",
  "company.user.phone": "전화번호",
  "company.user.password": "비밀번호",
  "company.message.sentPasswordMail": "패스워드 설정 정보를 메일로 보냈습니다.",
  "company.user.shopUserId": "판매사ID",
  "company.user.shopUser": "판매사정보",
  "company.user.notRegisteredSeller": "등륵되지 않은 판매사",

  "company.user.setting.role": "미니프로그램 권한 설정",
  "company.add.application": "관리중인 미니프로그램 목록",
  "company.add.application.help": "사용자 기본 정보 저장 후 설정하세요.",
  "company.add.select.application": "미니프로그램을 선택하세요.",

  "application.xiaochengxu": "미니프로그램",
  "application.role": "역할",
  "application.name": "미니프로그램 명칭",
  "application.appTitle": "미니프로그램 타이틀",
  "application.qrCode": "미니프로그램 QRCode",
  "application.companyId": "회사ID",
  "application.wxAppId": "APP ID",
  "application.wxOriginId": "Origin ID",
  "application.webMallDomain": "PC 쇼핑몰 도메인",
  "application.webMallDomain.help": "요우마 서비스에서 제공하는 웹 쇼핑몰로<br/>사용시 관리자에게 문의하세요.",
  "application.smsPrefix": "SMS제목 PREFIX",
  "application.useLive": "방송사용",
  "application.group.price": "가격 및 지불 정보",
  "application.productCurrency": "상품관리가격통화",
  "application.productCurrency.help": "<div>상품 등록 시 입력하는 가격의 통화, 이 가격은 임의로 관리자가 변경하지 않는 이상 변하지 않는 가격입니다.</div>\n" +
    "<div>예를 들어 상품관리가격통화가 KRW인 경우 소비자 화면에는 ₩11,000, ¥64 (소비자 지불금액), 두개의 가격이 표시됨</div>",
  "application.paymentCurrency": "지불요청통화",
  "application.paymentCurrency.help": "<div>지불회사로 지불 요청 시 통화, 상품 가격의 통화와 다른 경우 YOUMA 서비스에서의 환율로 변환 후 지불회사로 지불 요청</div>\n" +
    "<div style='color: \"red\"'>위챗 지불 대행 회사를 사용하는 경우 대행회사와 협의된 통화를 선택해주세요. 중국 법인으로 운영되는 미니프로그램의 경우 CNY 선택</div>\n" +
    "<div>CNY을 선택할 수도 있지만 한국 지불 회사에서 CNY을 USD로 변환하여 위챗으로 요청, 위챗은 USD를 CNY으로 변환하여 소비자에게 지불 요청하기 때문에 소비자가 지불하는 금액은 달라짐</div>",
  "application.paymentCompany": "자불회사",
  "application.paymentCompany.smatro": "스마트로",
  "application.paymentCompany.eximbay": "엑심베이",
  "application.paymentCompany.nicepay": "나이스",
  "application.paymentCompany.wechatpay": "위챗직접지불(중국법인)",
  "application.setting.key": "지불계정설정",
  "application.defaultCbtType": "기본통관방식",
  "application.group.local.logistics": "한국내 물류회사 정보",
  "application.local.logistics.help": "(행우세 또는 9610 통관인 경우 사용, 협력 업체 사용하는 경우만 입력)",
  "application.localLogisticsCompany": "한국내 물류회사",
  "application.localLogisticsCompany.none": "없음",
  "application.localLogisticsUserId": "물류회사 사용자 ID",
  "application.group.customs": "해관 계정 정보",
  "application.mchCustomsNo": "해관신고계정ID",
  "application.mchCustomsNo.help": "보세구(1210) 또는 직구(9610) 통관이 필요한 경우 관리자에게 문의하세요.",
  "application.declareDirectly": "주문단 신고방식",
  "application.declareDirectly.true": "직접 신고",
  "application.declareDirectly.false": "보세구로 주문정보 전송",
  "application.customsEbpCode": "플랫폼회사ID",
  "application.customsEbpCode.help": "특별한 상황이 아니면 데이터전송ID를 제외한 아래 ID는 \"해관신고계정ID\" 와 동일, 회사명은 \"해관신고계정ID\"의 회사",
  "application.customsEbpName": "플랫폼회사명",
  "application.customsEbpName.help": "아래 회사명 등록시 \"(\" 가 있는 경우 중문 \"（\"인지 영문 \"(\"인지 반드시 확인 후 입력 필요",
  "application.customsEbcCode": "판매회사ID",
  "application.customsEbcName": "판매회사명",
  "application.customsCopCode": "전송회사ID",
  "application.customsCopName": "전송회사명",
  "application.customsDxpId": "데이터전송ID",
  "application.customsCertNo": "CertNo",
  "application.group.bankAccount": "지불 계좌 정보",
  "application.bankRecpAccount": "지불계좌번호",
  "application.bankRecpCode": "계좌사업자번호",
  "application.bankRecpName": "계좌사압자명",

  "application.delete.message.atLeastOne": "최소 한개의 미니프로그램은 존재해야 합니다.",
  "application.input.password": "로그인 비밀번호 입력",
  "application.key.setting": "지불계정/미니프로그램 Key 설정",
  "application.wxAppSecret": "미니프로그램 SecretKey",
  "application.payId": "지불계정ID",
  "application.payId.help": "지불회사의 MID 또는 위챗지불에서 받은 MchID",
  "application.paySecretKey": "지블계정 SecretKey",
  "application.payV3SecretKey": "지블계정 V3 SecretKey",
  "application.certificateSerialNumber": "지블인증서번호",

  "user.info": "사용자정보",
  "user.name": "이름(위챗별명)",
  "user.realName": "실명",
  "user.avatarUrl": "썸네일",
  "user.gender": "성별",
  "user.province": "지역(성)",
  "user.city": "지역(도시)",
  "user.country": "지역(국가)",
  "user.wxOpenId": "위챗 OpenID",
  "user.loginId": "로그인 ID",
  "user.email": "이메일",
  "user.phone": "전화번호",
  "user.message.sentPasswordMail": "패스워드 설정 정보를 메일로 보냈습니다.",
  "faq.pinToTop": "상단고정",

  "company_setting.not_approved": "사용자 승인이 완료되지 않았습니다",
  "company_setting.not_approved.help": "<div>회사 담당자가 사용자 승인 중입니다.</div>" +
    "<div>담당자 승인 완료 후 사용 가능합니다.</div>" +
    "<div>승인은 회사 관리자에게 문의하세요.</div>",
  "company_setting.or": "또는",
  "company_setting.input.companyCode": "회사코드 입력",
  "company_setting.input.companyInfo": "회사정보 등록",
  "company_setting.setting.guide": "설정 가이드",
  "company_setting.setting.guide.help": "신규 가입한 사용자의 경우 다음 두가지 사항 중 하나를 입력하신 후 사용가능합니다.",
  "company_setting.setting1": "1. 회사가 등록되지 않은 경우",
  "company_setting.setting1.help": "회사 정보를 등록하신 후 사용하실 수 있습니다.",
  "company_setting.setting2": "2. 이미 등록된 회사의 사용자인 경우",
  "company_setting.setting2.help1": "회사 코드를 입력 후 사용하실 수 있습니다.",
  "company_setting.setting2.help2": "회사 코드는 회사 정보를 등록한 회사 담당자에게 문의하세요.",
  "company_setting.companyCode": "회사코드",
  "company_setting.notFound": "회사코드 [{companyCode}]의 회사정보가 없습니다. 회사 담당자에게 문의하세요.",

  "questions.question": "문의사항",
  "questions.title": "제목",
  "questions.contents": "내용",
  "questions.reply": "답변",

  "notice": "공지사항",
  "notice.title": "제목",
  "notice.contents": "내용",
  "notice.files": "첨부파일",

  "settlement.numOrders": "주문수",
  "settlement.quantity": "판매상품수량",
  "settlement.salesAmnount": "판매금액",

  "reply.productId": "상품ID",
  "reply.orderId": "주문ID",
  "reply.userId": "사용자ID",
  "reply.product": "상품",
  "reply.order": "주문",
  "reply.comment": "평가글",
  "reply.comment.score": "평가점수",
  "reply.comment.add": "평가글 추가",
  "reply.replyImage": "댓글 이미지",
  "reply.best": "베스트글",
  "reply.showProduct": "상품보기",
  "reply.showOrder": "주문보기",
  "reply.nickName": "이름",

  "live.cannotUseLive": "방송 사용 가능한 미니프로그램이 아닙니다. 시스템 관리자에게 문의하세요",
  "live.botton.addGoods": "상품추가",
  "live.botton.modifyGoods": "상품수정",
  "live.botton.addImage": "이미지추가",
  "live.botton.previewGoods": "상품미리보기",

  "live.goodsImage": "상품이미지",
  "live.goodsName": "상품명",
  "live.price": "가격",
  "live.link": "링크",
  "live.status": "상태",
  "live.status.0": "미신청",
  "live.status.1": "심사중",
  "live.status.2": "심사통과",
  "live.status.3": "심사미통과",

  "live.button.submit": "심사신청",
  "live.button.submit.help": "주의：심사신청 버튼 클릭 시 위챗으로 상품 승인이 요청됨",
  "live.modal.cover": "커버이미지",
  "live.modal.cover.help": "이미지크기：300 * 300，파일크기 1MB 미만<br/>기존 상품 이미지 파일과는 별도로 방송을 위해 사용되는 이미지",
  "live.modal.name": "상품이름",
  "live.modal.price": "가격",
  "live.modal.path": "링크",
  "live.preview.help": "위챗으로스캔하세요.",

  "live.room.name": "방이름",
  "live.room.time": "방송시간",
  "live.room.anchor_name": "진행자이름",
  "live.room.image": "이미지",
  "live.room.goods": "상품",
  "live.room.liveStatus": "상태",
  "live.room.liveStatus.101": "방송중",
  "live.room.liveStatus.102": "시작전",
  "live.room.liveStatus.103": "종료",
  "live.room.liveStatus.104": "방송금지",
  "live.room.liveStatus.105": "잠시멈춤",
  "live.room.liveStatus.106": "문제발생",
  "live.room.liveStatus.107": "만료",

  "suppliers.product": "공급상품",

  "shopComponent.title": "컴포넌트 명",
};

export default messageKoKR;