import React, { useContext, useState, useEffect, useRef } from 'react';
import {
  Table,
  Button,
  message,
  Popconfirm,
  Form,
  Input, Divider
} from 'antd';
import SystemSettingApi from "./SystemSettingApi";
import intl from "react-intl-universal";
import "./SystemSetting.less";
import {EditableRow, EditableCell} from "../../components/editable_table/EditableTable";

const SystemSettingList = (props) => {
  const [editingValue, setEditingValue] = useState(null);

  const setTableEditingValue = (value) => {
    value.active = (value.active || value.active === "true");
    const systemSettingsState = props.systemSettings.map(sc => {
      if (sc.id === value.id) {
        value.changed = true;
        return value;
      } else {
        return sc;
      }
    })

    setEditingValue(value);
    props.setSystemSettings(systemSettingsState);
  };

  const handleSaveSystemSetting = async (value) => {
    const setting = {...value};
    setting.active =  (setting.active === "true" || setting.active === true);
    SystemSettingApi.submitSystemSetting(setting).then(res => {
      message.info(intl.get("common.message.saved"));
      const saveSc = res.data;
      const systemSettingsState = props.systemSettings.map(sc => {
        if (sc.id === value.id) {
          return saveSc;
        } else {
          return sc;
        }
      });
      props.setSystemSettings(systemSettingsState);
      setEditingValue(null);
    });
  };

  const deleteSystemSetting = (deleteSystemSetting) => {
    if (deleteSystemSetting.added) {
      const systemSettingsState = props.systemSettings.filter(sc => sc.id !== deleteSystemSetting.id);
      props.setSystemSettings(systemSettingsState);
      message.success(intl.get("common.message.deleted"));
      return;
    }
    SystemSettingApi.deleteSystemSetting(deleteSystemSetting.id).then(res => {
      message.success(intl.get("common.message.deleted"));
      const systemSettingsState = props.systemSettings.filter(sc => sc.id !== deleteSystemSetting.id);
      props.setSystemSettings(systemSettingsState);
    });
  };

  const handleAdd = () => {
    const systemSettingsState = [...props.systemSettings];
    let maxId = 0;
    if (systemSettingsState) {
      maxId = systemSettingsState.sort((a,b)=>b.id - a.id)[0].id;
    }
    const newData = {
      id: maxId + 1,
      name: null,
      value: null,
      role: 'admin',
      added: true,
    };
    systemSettingsState.splice(0, 0, newData)
    props.setSystemSettings(systemSettingsState);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const originColumns = [{
    title: "ID",
    dataIndex: 'id',
    key: 'id',
    width: 50,
  }, {
    title: "Name",
    dataIndex: 'name',
    key: 'name',
    editable: true,
    width: 300,
  }, {
    title: "Value",
    dataIndex: 'value',
    key: 'value',
    editable: true,
  }, {
    title: "Active",
    dataIndex: 'active',
    key: 'active',
    editable: true,
    render: (text, record) => (
      (text === true || text === "true") ? "true" : "false"
    )
  }, {
    title: "권한",
    dataIndex: 'role',
    key: 'role',
    editable: true,
  }, {
    title: intl.get("common.updatedAt"),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    align: 'center',
    width: 150,
    render: (text, record) => (
        <span>{text ? text.substring(0, 16) : ""}</span>
    )
  }, {
    title: '',
    dataIndex: '',
    key: 'action',
    width: 200,
    render: (text, record) => (
      <div>
        <Button disabled={!record.changed} size="small" onClick={() => handleSaveSystemSetting(record)}>{intl.get("common.button.save")}</Button>
        <Divider type={"vertical"}/>
        <Popconfirm title={intl.get("common.confirm.delete", {name: record.name})}
                    onConfirm={() => { deleteSystemSetting(record)}}>
          <Button size="small">{intl.get("common.button.delete")}</Button>
        </Popconfirm>
      </div>
    )
  }];

  const columns = originColumns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: setTableEditingValue,
      }),
    };
  });

  return (
      <div>
        <div className="page-op-box">
          <div className="filter-box">
              <Button type="primary" onClick={props.loadSystemSettings}>{intl.get("common.button.search")}</Button>
          </div>
          <div className="extra-op-box">
            <Button type="default" size="small" onClick={handleAdd}>{intl.get("common.button.add")}</Button>
          </div>
        </div>
        <div style={{marginTop: "10px"}}>
          <Table size={'small'}
                 components={components}
                 rowClassName={() => 'editable-row'}
                 rowKey={'id'}
                 dataSource={props.systemSettings}
                 columns={columns}
                 pagination={false}
          />
        </div>
      </div>
  )
}

export default SystemSettingList;