import React, { useState, useEffect } from 'react';
import { Avatar, Rate, Checkbox, Button, Tag, Popconfirm, Image } from 'antd';
import ModifyReplyPopup from "./ModifyReplyPopup";
import Util from "../../util/Util";
import ReplyApi from "./ReplyApi";
import intl from 'react-intl-universal';
import NoImage from "../../asset/images/no_image.png";

const ReplyItem = (props) => {
  const [reply, setReply] = useState({});
  const [product, setProduct] = useState({});
  const [order, setOrder] = useState({});
  const [visibleModifyReplyPopup, setVisibleModifyReplyPopup] = useState(false);

  useEffect(() => {
    if (props.reply) {
      setReply(props.reply);
      if (props.products) {
        setProduct(props.products.find(p => p.id === props.reply.productId));
      }
      if (props.orders) {
        setOrder(props.orders.find(o => o.id === props.reply.orderId));
      }
    } else {
      setReply({});
    }
  }, [props.reply?.id, props.products, props.orders]);

  const onApprovedChanged = (e) => {
    const replyState = { ...reply };
    replyState.approved = e.target.checked;
    ReplyApi.saveReply(replyState).then(res => {
      setReply(replyState);
      if (props.setReply) {
        props.setReply(replyState);
      }
    });
  };

  const onBestChanged = (e) => {
    const replyState = { ...reply };
    replyState.bestReply = e.target.checked;
    ReplyApi.saveReply(replyState).then(res => {
      setReply(replyState);
      if (props.setReply) {
        props.setReply(replyState);
      }
    });
  };

  const showProductDetail = () => {
    if (props.showProductDetail) {
      props.showProductDetail(reply.productId);
    }
  };
  const onModifyReply = () => {
    setVisibleModifyReplyPopup(true)
  }
  return (
    <div style={{marginBottom: 20, borderBottom: "1px solid #E3E3E3"}}>
      <div style={{display: "flex", gap: 10, alignItems: "center"}}>
        <div><Avatar src={reply.avatar} alt={reply.nickName} /></div>
        <div>{reply.nickName}</div>
        <div style={{color: "#8c8c8c", fontSize: 11}}>{Util.getLocalTime(reply.createdAt)}</div>
        {!order && (<Tag color="blue">Manager</Tag>)}
      </div>
      <div style={{marginBottom: 10}}>
        <Rate style={{ fontSize: 16 }} disabled value={reply.productScore} />
      </div>
      <div>
        {product?.id && (
            <a onClick={showProductDetail}>
              <div style={{marginBottom: 10, gap: 5, display: "flex"}}>
                <div>
                  <img style={{width: 24, height: 24}} src={product.images.length > 0 ? product.images[0] + "?x-oss-process=image/resize,h_24,w_24" : NoImage}/>
                </div>
                <div>{product.name}</div>
              </div>
            </a>
        )}
        {!product?.id && order?.id && (
            <a href={"/orders/" + reply.orderId} target={"_blank"}>
              <div style={{marginBottom: 10, gap: 5, display: "flex"}}>
                <div>
                  <img style={{width: 24, height: 24}} src={order.featuredImage ? order.featuredImage + "?x-oss-process=image/resize,h_24,w_24" : NoImage}/>
                </div>
                <div>{order.orderTitle}</div>
              </div>
            </a>
        )}
      </div>
      <div style={{padding: 20}}>
        {reply.contents && reply.contents.split("\n").map((line, index) => (
            <div key={index}>{line}</div>
        ))}
        {reply.replyImage && (
          <Image style={{width: 100}} src={reply.replyImage}></Image>
        )}
        <div style={{display: "flex", gap: 10, alignItems: "center", marginTop: 20}}>
          <div>
            <Checkbox onChange={onApprovedChanged} checked={reply.approved}>{intl.get("common.label.approve")}</Checkbox>
            <Checkbox onChange={onBestChanged} checked={reply.bestReply}>{intl.get("reply.best")}</Checkbox>
          </div>
          {!reply.orderId && (
            <div>
              <Popconfirm
                  title={intl.get("common.confirm.delete", {name: intl.get("reply.comment")})}
                  onConfirm={(e) => { e.stopPropagation(); props.deleteReply(reply.id); }}
                  onCancel={(e) => e.stopPropagation()}
              >
                <Button type={"default"} size={"small"}>
                  {intl.get("common.button.delete")}
                </Button>
              </Popconfirm>
            </div>
          )}
          <Button type={"default"} size={"small"} onClick={onModifyReply}>
            {intl.get("common.button.edit")}
          </Button>
        </div>
      </div>
      {visibleModifyReplyPopup && (
        <ModifyReplyPopup 
          setReply={setReply}
          reply={reply}
          product={product}
          order={order}
          onOk={() => setVisibleModifyReplyPopup(false)}
          onCancel={() => setVisibleModifyReplyPopup(false)}
        />
      )}
    </div>
  )
}

export default ReplyItem;