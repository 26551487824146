import React, {useState, useEffect} from "react";
import {Button, Input, Modal, Table, Layout, Divider} from "antd";
import Util from "../../util/Util";
import {WechatOutlined} from "@ant-design/icons";
import ApplicationApi from "./ApplicationApi";
import intl from "react-intl-universal";

const {Content, Header} = Layout;

const ApplicationListPopup = (props) => {
  const [applications, setApplications] = useState([]);

  useEffect(() => {
    loadApplications(props.companyId);
  }, [props.companyId]);

  const loadApplications = (companyId) => {
    const params = {
      companyId: companyId,
      page: 1,
      pageSize: 100,
    }
    ApplicationApi.getApplications(params).then(res => {
      setApplications(res.data.data);
    });
  };

  const columns = [{
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
  }, {
    title: intl.get("application.name"),
    dataIndex: 'name',
    key: 'name',
    align: 'left',
  }, {
    title: intl.get("application.appTitle"),
    dataIndex: 'appTitle',
    key: 'appTitle',
    align: 'left',
  }, {
    title: intl.get("application.wxAppId"),
    dataIndex: 'wxAppId',
    key: 'wxAppId',
    align: 'left',
  }, {
    title: intl.get("application.paymentCompany"),
    dataIndex: 'paymentCompany',
    key: 'paymentCompany',
    align: 'left',
    render: (text, record) => (
      <span>{intl.get("application.paymentCompany." + text)}</span>
    )
  }, {
    title: intl.get("common.createdAt"),
    dataIndex: 'createdAt',
    key: 'createdAt',
    align: 'center',
    render: (text, record) => (
      <span>{Util.getLocalTime(text).substring(0, 10)}</span>
    )
  }];

  return (
    <Modal
      title={[<div key={1}><WechatOutlined/> Application</div>]}
      visible={true}
      onCancel={props.onClose}
      footer={null}
      width={1200}
      bodyStyle={{margin: 0, padding: 0}}>
      <Layout>
        <Content className="modal-content">
          <div style={{marginTop: "10px"}}>
            <Table size={'small'}
                   dataSource={applications}
                   columns={columns}
                   rowKey={'id'}
                   onRow={(record, rowIndex) => {
                     return {
                       onClick: event => {
                         if (props.onAppSelected) {
                           props.onAppSelected(record)
                         }
                       },
                     }
                   }}
                   pagination={null}
            />
          </div>
        </Content>
      </Layout>
    </Modal>
  )
};

export default ApplicationListPopup;