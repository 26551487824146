import React, {useEffect, useState} from "react";
import {
  Button, Checkbox,
  Descriptions,
  Divider,
  Input,
  InputNumber,
  Modal,
  Radio,
  Space,
  Select,
  Table,
  message
} from "antd";
import {DeleteOutlined, EditOutlined, MenuOutlined, PlusCircleOutlined} from "@ant-design/icons";
import MediaLinkSetting from "./MediaLinkSetting";
import BannerTemplateImg1 from "../../asset/images/banner_template_1.png";
import NoImg from "../../asset/images/no_image.png";
import DraggableTable from "../DraggableTable";
import {sortableHandle} from "react-sortable-hoc";

import intl from "react-intl-universal";

const BannerSetting = (props) => {
  const [settingValues, setSettingValues] = useState({});
  const [editingSlot, setEditingSlot] = useState({});
  const [maxSlotId, setMaxSlotId] = useState(0);
  const [slotEditorVisible, setSlotEditorVisible] = useState(false);

  useEffect(() => {
    const settingValuesProp = {...props.settingValues};

    let maxSlotId = 1;
    if (!settingValuesProp.slots) {
      settingValuesProp.slots = [];
    } else {
      maxSlotId = 0;
      settingValuesProp.slots.forEach(slot => {
        if (slot.id > maxSlotId) {
          maxSlotId = slot.id;
        }
      });
    }
    setMaxSlotId(maxSlotId);
    setSettingValues(settingValuesProp);
  }, [props.settingValues]);

  const addSlot = () => {
    setEditingSlot({
      action: "product",
    });
    setSlotEditorVisible(true);
  };

  const handleSlotEditorModalOk = () => {
    const settingValuesState = {...settingValues};
    const editingSlotState = {...editingSlot};

    if (settingValuesState.displayType === "icon") {
      editingSlotState.bannerMediaType = "image";
    }

    if (!editingSlotState.bannerMediaType) {
      message.error(intl.get("common.inputField",
        {name: intl.get("page_setting.edit.banner.bannerMediaType")}));
      return;
    }
    if (!editingSlotState.bannerPath) {
      message.error(intl.get("common.inputField",
        {name: intl.get("page_setting.edit.banner.bannerPath")}));
      return;
    }

    if (!editingSlotState.action) {
      message.error(intl.get("common.inputField",
        {name: intl.get("page_setting.edit.banner.action")}));
      return;
    }
    if (editingSlotState.action !== "logistics_order"
        && editingSlotState.action !== "call_center"
        && editingSlotState.action !== "none"
        && editingSlotState.action !== "home"
        && editingSlotState.action !== "cart"
        && editingSlotState.action !== "singupCoupon"
        && !editingSlotState.param) {
      message.error(intl.get("common.inputField",
        {name: intl.get("page_setting.edit.banner.param")}));
      return;
    }

    if (editingSlotState.openApp && !editingSlotState.appId) {
      message.error(intl.get("common.inputField",
        {name: intl.get("page_setting.edit.banner.openApp")}));
      return;
    }

    if (editingSlotState.action === "contents" && !editingSlotState.mediaType) {
      message.success("컨텐츠의 미디어 종류를 선택하세요.");
      return;
    }

    let slots = [...settingValuesState.slots];
    if (editingSlotState.id) {
      // 수정인 경우
      slots = slots.map(slot => {
        if (slot.id === editingSlotState.id) {
          return editingSlotState;
        } else {
          return slot;
        }
      });
    } else {
      // 추가인 경우
      editingSlotState.id = maxSlotId + 1;
      if (slots) {
        slots.push(editingSlotState);
      } else {
        slots = [editingSlotState];
      }
    }
    settingValuesState.slots = slots;
    setEditingSlot(editingSlotState);
    setMaxSlotId(maxSlotId + 1);
    setSlotEditorVisible(false);
    setSettingValues(settingValuesState);

    props.applySetting(props.id, settingValuesState);
  };

  const editSlot = (id) => {
    const settingValuesState = {...settingValues};
    const editingSlot = settingValuesState.slots.find(slot => slot.id === id);
    setEditingSlot(editingSlot);
    setSlotEditorVisible(true);
  };

  const deleteSlot = (id) => {
    const settingValuesState = {...settingValues};
    const selectedSlot = settingValuesState.slots.find(slot => slot.id === id);
    if(!window.confirm(
      intl.get("common.confirm.delete",
        {name: intl.get("page_setting.edit.media.action." + selectedSlot.action)}
      ))) {
      return;
    }

    settingValuesState.slots = settingValuesState.slots.filter(slot => slot.id !== id);
    setSettingValues(settingValuesState);
    props.applySetting(props.id, settingValuesState);
  };

  const getImageName = (path) => {
    if (!path) {
      return "";
    }
    try {
      let lastIndex = path.lastIndexOf("/");
      if (lastIndex === path.length - 1) {
        path = path.substring(0, path.length - 1);
        lastIndex = path.lastIndexOf("/");
      }
      return decodeURIComponent(path.substring(lastIndex + 1));
    } catch (err) {
      return path;
    }
  };

  const slotChanged = (editingSlot) => {
    setEditingSlot(editingSlot);
  };

  const onChangeSettingValue = (fieldName, value) => {
    const settingValuesState = {...settingValues};
    settingValuesState[fieldName] = value;

    if (fieldName === "displayType") {
      if (value === "template") {
        settingValuesState["templateId"] = 1;
        const slots = [{
          id: 1, action: "none", bannerMediaType: "image", param: "",
          bannerPath: "",
        }, {
          id: 2, action: "none", bannerMediaType: "image", param: "",
          bannerPath: "",
        }, {
          id: 3, action: "none", bannerMediaType: "image", param: "",
          bannerPath: "",
        }];
        settingValuesState.height = 201;
        settingValuesState.slots = slots;
      } else {
        settingValuesState.slots = [];
      }
    }
    setSettingValues(settingValuesState);
    props.applySetting(props.id, settingValuesState);
  }

  const findBrandName = (brandId) => {
    const brand = props.brands.find(b => b.id === brandId);
    if (brand) {
      return brand.name;
    } else {
      return "";
    }
  };

  const findCategoryName = (categoryId, subCategoryId) => {
    const category = props.categories.find(c => c.id === categoryId);
    if (category) {
      let names = [category.name];
      const subCategory = category.subCategories.find(sc => sc.id === subCategoryId);
      if (subCategory) {
        names.push(subCategory.name);
      } else {
        names.push(subCategoryId);
      }
      return names;
    } else {
      return [categoryId, subCategoryId];
    }
  };

  const onDragEnd = (sortedSlots) => {
    const settingValuesState = {...settingValues};
    settingValuesState.slots = sortedSlots;
    let slots = [...settingValuesState.slots];

    setSettingValues(settingValuesState);
    props.applySetting(props.id, settingValuesState);
  }

  const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

  const columns = [{
      title: intl.get("page_setting.edit.toolbar.sort"),
      dataIndex: 'sort',
      width: 50,
      className: 'drag-visible',
      render: () => <DragHandle/>
    }, {
      title: intl.get("page_setting.edit.banner.bannerMediaType"),
      dataIndex: "bannerMediaType",
      key: "bannerMediaType",
      align: "left",
      width: 100,
    }, {
      title: intl.get("page_setting.edit.banner.bannerPath"),
      dataIndex: "bannerPath",
      key: "bannerPath",
      align: "left",
      width: 150,
      render: (text, record) => {
        if (record.bannerMediaType === "image") {
          if (record.bannerPath) {
            return (<img src={record.bannerPath + "?x-oss-process=image/resize,h_70,w_100"} width={100} height={70}/>)
          } else {
            return (<img src={NoImg} width={100} height={70}/>)
          }
        } else {
          const mediaName = getImageName(record.bannerPath);
          return (<a href={record.bannerPath} target="_blank">{mediaName}</a>)
        }
      }
    }, {
      title: intl.get("page_setting.edit.banner.action"),
      dataIndex: "action",
      key: "action",
      align: "left",
      width: 150,
      render: (text, record) => {
        return (<div>{intl.get("page_setting.edit.media.action." + text)}</div>)
      }
    }, {
      title: intl.get("page_setting.edit.banner.param"),
      dataIndex: "param",
      key: "param",
      align: "left",
      render: (text, record) => {
        if (record.action === "logistics_order" || record.action === "call_center") {
          return (<span></span>)
        }
        if (record.action === "brand") {
          const brandName = findBrandName(record.param);
          return (<span>{brandName ? brandName : record.param }</span>)
        }
        if (record.action === "category") {
          const categoryNames = findCategoryName(record.categoryId, record.subCategoryId)
          if (categoryNames) {
            return (<span>{categoryNames[0]}{categoryNames[1] ? " -> " + categoryNames[1] : ""}</span>)
          } else {
            return (<span>{record.param}</span>)
          }
        }
        if (record.action === "contents") {
          const imageName = getImageName(record.param);
          return (<a href={record.bannerPath} target="_blank">{imageName}</a>)
        }
        if (record.action === "none"
          || record.action === "cart"
          || record.action === "home"
          || record.action === "singupCoupon"
          || record.action === "call_center") {
          return (<span></span>)
        }
        if (record.paramTitle) {
          return (<span>{record.paramTitle}</span>)
        } else {
          return (<span>ID: {record.param}</span>)
        }
      }
    }, {
      title: intl.get("page_setting.edit.banner.openApp"),
      dataIndex: "openApp",
      key: "openApp",
      width: 80,
    }, {
      title: "",
      dataIndex: "",
      key: "",
      align: "center",
      width: 80,
      render: (text, record) => {
        return (
          <div>
            <EditOutlined style={{cursor: 'pointer'}} onClick={() => {editSlot(record.id);}}/>
            <Divider type="vertical" />
            <DeleteOutlined style={{cursor: 'pointer'}} onClick={() => {deleteSlot(record.id);}}/>
          </div>
        )
      }
  }]

  return (
      <div>
        <Descriptions title={intl.get("page_setting.edit.banner.title")}
                      bordered layout="vertical" size={"small"} column={1}>
          <Descriptions.Item label={intl.get("page_setting.edit.banner.height")}>
            <InputNumber value={settingValues.height} min={1} max={10000} onChange={(value) => onChangeSettingValue("height", value)}/>
          </Descriptions.Item>
          <Descriptions.Item label={intl.get("page_setting.edit.banner.displayType")}>
            <div>
              <div style={{display: "flex"}}>
                <Select style={{ width: "20%"}} value={settingValues.displayType} onChange={(value) => onChangeSettingValue("displayType", value)}>
                  <Select.Option key="template" value="template">{intl.get("page_setting.edit.banner.displayType.template")}</Select.Option>
                  <Select.Option key="swiper" value="swiper">{intl.get("page_setting.edit.banner.displayType.swiper")}</Select.Option>
                  <Select.Option key="split" value="split">{intl.get("page_setting.edit.banner.displayType.split")}</Select.Option>
                  {/*<Select.Option key="icon" value="icon">{intl.get("page_setting.edit.banner.displayType.icon")}</Select.Option>*/}
                </Select>
                {settingValues.displayType === "split" && (
                    <div style={{width: "70%", marginLeft: "50px", display: "flex", alignItems: "center"}}>
                      <span>{intl.get("page_setting.edit.banner.displayType.split.help")}</span><Input style={{width: "200px", marginLeft: "5px"}} value={settingValues.splitRatio}
                                                         onChange={(e) => onChangeSettingValue("splitRatio", e.target.value)}/>
                    </div>
                  )}
              </div>
              {settingValues.displayType === "swiper" && (
                  <div style={{marginTop: 10}}>
                    <Descriptions bordered size={"small"} column={1}>
                      <Descriptions.Item label={intl.get("page_setting.edit.banner.autoPlay")}>
                        <Checkbox checked={settingValues.autoPlay} onChange={(e) => onChangeSettingValue('autoPlay', e.target.checked)}/>
                      </Descriptions.Item>
                      <Descriptions.Item label={intl.get("page_setting.edit.banner.autoPlayInterval")}>
                        <Input style={{width: 100}} suffix="ms" value={settingValues.autoPlayInterval} onChange={(e) => onChangeSettingValue('autoPlayInterval', e.target.value)}/>
                      </Descriptions.Item>
                      <Descriptions.Item label={intl.get("page_setting.edit.banner.autoPlaySpeed")}>
                        <Input style={{width: 100}} suffix="ms" value={settingValues.autoPlaySpeed} onChange={(e) => onChangeSettingValue('autoPlaySpeed', e.target.value)}/>
                      </Descriptions.Item>
                    </Descriptions>
                  </div>
              )}
              {settingValues.displayType === "template" && (
                <div style={{marginTop: 10}}>
                  <Space>
                    <div>
                      <Radio.Group value={settingValues.templateId} onChange={(e) => onChangeSettingValue('templateId', e.target.value)}>
                        <Radio value={1}>
                          <div><img src={BannerTemplateImg1} style={{width: 64, height: 64}}/></div>
                        </Radio>
                      </Radio.Group>
                    </div>
                  </Space>
                </div>
              )}
            </div>
          </Descriptions.Item>
          <Descriptions.Item label={intl.get("page_setting.edit.banner.addedSlots")}>
            <div style={{width: "100%", textAlign: "right"}}>
              <Button type="default" size="small" icon={<PlusCircleOutlined />} onClick={addSlot}>{intl.get("page_setting.edit.banner.addSlot")}</Button>
            </div>
            <DraggableTable
                   dataSource={settingValues.slots ? settingValues.slots : []}
                   columns={columns}
                   rowKey={'id'}
                   onSortEnd={onDragEnd}
            />
          </Descriptions.Item>
        </Descriptions>
        <Modal
              title={(editingSlot.id || editingSlot.id === 0)
                ? intl.get("page_setting.edit.banner.modalTitle.update")
                : intl.get("page_setting.edit.banner.modalTitle.add")}
              visible={slotEditorVisible}
              onOk={handleSlotEditorModalOk}
              onCancel={() => setSlotEditorVisible(false)}
              width={800}
          >
          <MediaLinkSetting
              settingValues={settingValues}
              slot={editingSlot}
              id={editingSlot.id}
              brands={props.brands}
              categories={props.categories}
              slotChanged={slotChanged}>
          </MediaLinkSetting>
        </Modal>
      </div>
    )
}

export default BannerSetting;