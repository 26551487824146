import React, { useRef, useEffect } from "react";
import "@toast-ui/editor/dist/toastui-editor.css";
import { Editor } from "@toast-ui/react-editor";
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import {adminConfig} from "../../config/admin.config";
import axios from "axios";

const API_BASE_URL = adminConfig.apiServer();

const HtmlEditor = (props) => {
  const editorRef = useRef();
  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.getInstance().removeHook("addImageBlobHook");
      editorRef.current.getInstance().addHook("addImageBlobHook", (blob, callback) => {
        (async () => {
          let formData = new FormData();
          formData.append("files", blob);
          formData.append("fileKind", props.fileKind ? props.fileKind : "index");
            formData.append("rename", true);
          formData.append("parentPath", "");

          axios.defaults.withCredentials = true;
          const res = await axios.post(
              `${API_BASE_URL}/file-managers`,
              formData,
              {
                header: { "content-type": "multipart/formdata" },
              }
          );
          callback(res.data[0].path + "?x-oss-process=image/resize,h_355", "alt text");
        })();

        return false;
      });
    }
    return () => {};
  }, [editorRef]);

  const setHtmlStyle = (htmlText) => {
    // table
    let styledHtmlText = htmlText.replaceAll("<table>", "<table style='border: 1px solid rgba(0, 0, 0, 0.1);margin: 12px 0 14px;color: #222;width: auto;border-collapse: collapse;-webkit-box-sizing: border-box;box-sizing: border-box;'>");
    styledHtmlText = styledHtmlText.replaceAll("<th>","<th style='background-color: #555; font-weight: 300; color: #fff; padding-top: 6px; border: 1px solid rgba(0, 0, 0, 0.1); padding: 5px 14px 5px 12px; height: 32px;'>");
    styledHtmlText = styledHtmlText.replaceAll("<td>", "<td style='border: 1px solid rgba(0, 0, 0, 0.1);padding: 5px 14px 5px 12px;height: 32px;'>")

    return styledHtmlText
  }
  return (
      <div>
        <Editor
            usageStatistics={false}
            initialValue={props.htmlText ? props.htmlText : "이미지는 폭에 맞추어 자동으로 높이가 계산됩니다. 폭은 370px 기준"}
            previewStyle="tab"
            height={props.height + "px"}
            useDefaultHTMLSanitizer={true}
            initialEditType="wysiwyg"
            useCommandShortcut={true}
            hideModeSwitch={true}
            plugins={[colorSyntax]}
            onChange={() => {
              const htmlText = editorRef.current.getInstance().getHTML();
              props.setText(setHtmlStyle(htmlText));
            }}
            toolbarItems={[
              ['heading', 'bold', 'italic', 'strike'],
              ['hr', 'quote'],
              ['ul', 'ol', 'task', 'indent', 'outdent'],
              ['table', 'image']]}
            ref={editorRef}
        />
      </div>
  );
};

export default HtmlEditor;