import React from "react";

const LineSlot = (props) => {
  const {id, settingValues} = props;

  const contentStyle = {
    width: "375px",
    backgroundColor: settingValues.backgroundColor,
    height: settingValues.height + "px"
  };

  return (
      <div style={contentStyle}>
      </div>
  )
}

export default LineSlot;