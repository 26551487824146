import React from "react";
import {Link, useHistory} from "react-router-dom";
import {Button, Divider, Input, message, Select, Table} from "antd";
import intl from "react-intl-universal";
import Util from "../../util/Util";
import ApplicationApi from "./ApplicationApi";
import {AuthService} from "../../auth/auth.service";
import {useLayoutDispatch} from "../../components/layout/AppLayoutContext";

const ApplicationList = (props) => {
  const history = useHistory();
  const layoutDispatch = useLayoutDispatch();
  const {applications, pagination, filter} = props;

  const deleteApplication = (e, app) => {
    e.stopPropagation();
    if (!Util.isAdmin() && applications.length === 1) {
      alert(intl.get("application.delete.message.atLeastOne"));
      return;
    }
    if (!window.confirm(intl.get("common.confirm.delete", {name: app.name}))) {
      return;
    }
    ApplicationApi.deleteApplication(app.id).then(res => {
      message.success(intl.get("common.message.deleted"));
      props.searchApplications(pagination.current);
    });
  };

  const handleSearchClick = () => {
    props.searchApplications(1);
  }

  const changeName = (e) => {
    const {filter} = props;
    filter.name = e.target.value;
    props.setFilter(filter);
  }

  const changeCompanyId = (value) => {
    const {filter} = props;
    filter.companyId = value;//e.target.value;
    props.setFilter(filter);
  }

  const createAppLink = (e, appId) => {
    e.stopPropagation();
    ApplicationApi.createAppLink(appId).then(res => {
      // console.log(">>>res>", res.data);
      message.info("URL:" + res.data);
    });
  };

  const columns = [{
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
    }, {
      title: intl.get("application.name"),
      dataIndex: 'name',
      key: 'name',
      align: 'left',
    }, {
      title: intl.get("application.appTitle"),
      dataIndex: 'appTitle',
      key: 'appTitle',
      align: 'left',
    }, {
      title: intl.get("application.wxAppId"),
      dataIndex: 'wxAppId',
      key: 'wxAppId',
      align: 'left',
    }, {
      title: intl.get("application.paymentCompany"),
      dataIndex: 'paymentCompany',
      key: 'paymentCompany',
      align: 'left',
      render: (text, record) => (
        <span>{intl.get("application.paymentCompany." + text)}</span>
      )
    }, {
      title: intl.get("common.createdAt"),
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: (text, record) => (
          <span>{Util.getLocalTime(text).substring(0, 10)}</span>
      )
    }];

  if (Util.isAdmin()) {
    columns.push({
      title: intl.get("application.companyId"),
      dataIndex: 'companyId',
      key: 'companyId',
    });
    columns.push({
      title: "Default",
      dataIndex: 'isDefaultApp',
      key: 'isDefaultApp',
      render: (text, record) => (
        <span>{text ? "Yes" : ""}</span>
      )
    })
  }
  columns.push({
    title: "",
    dataIndex: 'id',
    key: 'action',
    align: 'center',
    render: (text, record) => (
      <>
        <Button size="small" onClick={(e) => deleteApplication(e, record)}>{intl.get("common.button.delete")}</Button>
        {Util.isAdmin() && (
          <>
            <Divider type={"vertical"}/>
            <Button size="small" onClick={(e) => createAppLink(e, record.id)}>URL만들기</Button>
          </>
        )}
      </>
    )
  });

  return (
      <div>
        <div className="page-op-box">
          <div className="filter-box">
            <Input style={{ width: 200, marginRight: 10 }} value={filter.name} onChange={changeName} placeholder={intl.get("application.name")}/>
            {Util.isAdmin() && (
                <>
                  <Divider type="vertical" />
                  {/*<Input style={{ width: 200, marginRight: 10 }} value={filter.companyId} onChange={changeCompanyId} placeholder={intl.get("application.companyId")}/>*/}
                  <Select allowClear={true} style={{width: 250, marginRight: 10}} value={filter.companyId} onSelect={changeCompanyId}>
                    <Select.Option key={'all'} value={'all'}>All</Select.Option>
                    {props.companies?.filter(c => c.name !== "_default").map(c => (
                      <Select.Option key={c.id} value={c.id}>{c.name}</Select.Option>
                    ))}
                  </Select>
                </>
            )}
            <div style={{marginLeft: "auto"}}>
              <div style={{width: 100}}>
                <Button style={{width: 100}} type="primary" size="default" block onClick={handleSearchClick}>{intl.get("common.button.search")}</Button>
              </div>
            </div>
          </div>
          <div className="extra-op-box">
            <Link to={`${props.match.path}/new`}>
              <Button type="default" size="small">{intl.get("common.button.add")}</Button>
            </Link>
          </div>
        </div>
        <div style={{marginTop: "10px"}}>
          <Table size={'small'}
                 dataSource={applications}
                 columns={columns}
                 rowKey={'id'}
                 title={() => intl.get("common.label.total") + " " + pagination.total}
                 pagination={pagination}
                 onRow={(record, rowIndex) => {
                   return {
                     onClick: event => history.push(`/applications/${record.id}`),
                   };
                 }}
          />
        </div>
      </div>
  );
}

export default ApplicationList;