import axios from "axios";
import {adminConfig} from "../../config/admin.config";
import FileManager from "./FileManager";

const API_BASE_URL = adminConfig.apiServer();

export default class FileManagerApi {
  static getFiles = (options) => {
    let apiPath = `${API_BASE_URL}/file-managers?projection=webAdmin&`;

    if (options) {
      apiPath += Object.keys(options).map(key => {
        return key + "=" + options[key]
      }).join("&");
    }
    return axios.get(apiPath);
  };

  static createFolder = (fileKind, folderName) => {
    let apiPath = `${API_BASE_URL}/file-managers/folder?projection=webAdmin&fileKind=${fileKind}&folderName=${folderName}`;
    return axios.post(apiPath);
  };

  static deleteFiles = (files) => {
    let apiPath = `${API_BASE_URL}/file-managers?projection=webAdmin`;
    return axios.delete(apiPath, {data: {files: files}});
  }
};