import React from "react";
import Util from "../../../util/Util";
import PopupCloseBtnImg from "../../../asset/images/popup_close_btn.png";
import NoImage from "../../../asset/images/no_image.png";

const PopupSlot = (props) =>  {
  const {settingValues, id} = props;

  const contentStyle = {
    display: "block",
    position: "absolute",
    borderRadius: "5px",
    width: "85%",
    marginLeft: "25px",
    marginTop: "80px",
    height: settingValues.height,
    color: '#000',
    textAlign: 'center',
    background: '#fff',
    zIndex: 101,
    borderStyle: "none",
  };

  const imagePath = settingValues.slot && settingValues.slot.bannerPath ? Util.encodeImagePath(settingValues.slot.bannerPath) : NoImage;
  return (
      <div style={contentStyle}>
        <div style={{height: settingValues.height}}>
          <img style={{width: "100%", height: settingValues.height, borderRadius: "5px"}}
               src={imagePath}/>)
        </div>
        <img src={PopupCloseBtnImg} width="50px" style={{opacity: 0.5, cursor: "pointer"}} onClick={props.closePopup}/>
      </div>
  )
}

export default PopupSlot;