import React from 'react';
import {
  Input,
  message,
  Radio,
  Form,
  Modal,
  Descriptions,
} from 'antd';
import BrandApi from "./BrandApi";
import intl from "react-intl-universal";
import FileListPopup from "../file_manager/FileListPopup";
const { Search } = Input;

export default class BrandEditPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      visibleFileModal: false,
      brand: {
        merchandiserId: "",
        name: "",
        imageUrl: "",
        active: true,
      }
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible) {
      let brand = {
        merchandiserId: this.props.merchandiserId,
        name: "",
        imageUrl: "",
        active: true,
      };

      if (this.props.brand && this.props.brand.id) {
        brand = this.props.brand;
      }

      this.setState({
        brand: brand,
        visible: this.props.visible,
      })
    }
  };

  onNameChanged = (event) => {
    const { brand } = this.state;
    brand.name = event.target.value;
    this.setState({
      name: event.target.value
    });
  };

  onActiveChanged = (event) => {
    const { brand } = this.state;
    brand.active = event.target.value;
    this.setState({
      brand: brand
    });
  };

  onOk = () => {
    const { brand } = this.state;
    if (!brand.name) {
      message.error(intl.get("common.inputField", {name: intl.get("brand.name")}));
      return;
    }
    const brands = [brand];
    BrandApi.submitBrand(brands).then(json => {
      message.success("saved");
      this.props.onOk();
    }).catch(e => {
      console.log("save failed:", e)
    }).finally(() => {
      this.setState({
        uploading: false,
      });
    })
  };

  onCancel = () => {
    this.props.onCancel();
  };

  cancelFileModal = () => {
    this.setState({
      visibleFileModal: false
    })
  };

  okFileModal = (modalId, filePathList) => {
    if (filePathList.length === 0) {
      message.error(intl.get("common.message.selectFile"));
      return;
    }

    const {brand} = this.state;
    brand.imageUrl = filePathList[0];
    this.setState({
      visibleFileModal: false,
      brand: brand,
    });
  };

  imageUrlChanged = (e) => {
    const {brand} = this.state;
    brand.imageUrl = e.target.value;
    this.setState({
      brand: brand,
    });
  };

  render() {
    const { visible, brand } = this.state;

    return (
        <Modal
          open={visible}
          title={brand && brand.id ? intl.get("brand.modal.edit") : intl.get("brand.modal.add")}
          onCancel={this.onCancel}
          onOk={this.onOk}
          width={"800px"}
        >
          <div>
            <Descriptions bordered
                          size={"small"}
                          column={1}>
              <Descriptions.Item label={"ID"}>
                <Input disabled value={brand.id}/>
              </Descriptions.Item>
              <Descriptions.Item label={intl.get("brand.name")}>
                <Input value={brand.name} onChange={this.onNameChanged}/>
              </Descriptions.Item>
              <Descriptions.Item label={intl.get("brand.active")}>
                <Radio.Group value={brand.active} onChange={this.onActiveChanged}>
                  <Radio value={true}>{intl.get("common.true")}</Radio>
                  <Radio value={false}>{intl.get("common.false")}</Radio>
                </Radio.Group>
              </Descriptions.Item>
              <Descriptions.Item label={intl.get("application.design.image")}>
                <Search
                    allowClear
                    enterButton="Select File"
                    value={brand.imageUrl}
                    onChange={this.imageUrlChanged}
                    onSearch={() => this.setState({visibleFileModal: true})}
                />
                { this.state.imageUrl && (<div><img style={{width: 80, height: 80}} src={this.state.imageUrl}/></div>) }
              </Descriptions.Item>
            </Descriptions>
          </div>
          <FileListPopup id={"brand-image"}
                         fileKind={"etc"}
                         visible={this.state.visibleFileModal}
                         multipleFile={false}
                         canSelect={true}
                         handleCancel={this.cancelFileModal}
                         handleOk={this.okFileModal}
          />
        </Modal>
    );
  }
}