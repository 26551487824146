import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Input,
  Spin,
  Modal,
  message
} from 'antd';
import intl from "react-intl-universal";
import OrderApi from "./OrderApi";

const { TextArea } = Input;

const WeimobOrderFetchModal = (props) => {
  const [orderNoText, setOrderNoText] = useState("");
  const [fetchResult, setFetchResult] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchOrder = () => {
    if (!orderNoText) {
      message.error(intl.get("common.inputField", {name: intl.get("order.weimob.orderNo")}));
      return;
    }
    const orderNoList = orderNoText.split("\n").filter(line => line.trim().length > 0);
    if (orderNoList.length === 0) {
      message.error(intl.get("common.inputField", {name: intl.get("order.weimob.orderNo")}));
      return;
    }
    if (orderNoList.length > 10) {
      message.error(intl.get("order.weimob.maxOrders"));
      return;
    }

    setLoading(true);
    OrderApi.fetchWeimobOrder(orderNoList).then(res => {
      message.info(intl.get("common.message.success"));
      setFetchResult(res.data);
      setOrderNoText("");
    }).finally(() => {
      setLoading(false);
    });
  };

  const closeFetchOrder = () => {
    props.handleCancel();
  }

  const numErrors = fetchResult.filter(r => !r.success).length;
  const numSucceeded = fetchResult.length - numErrors;
  return (
      <Modal visible={true}
               onCancel={closeFetchOrder}
               cancelText={intl.get("common.button.close")}
               onOk={fetchOrder}
               width={600}
               title={intl.get("order.weimob.title")}
        >
          <Spin spinning={loading}>
            <div>
              <div>
                {intl.get("order.weimob.help1")}:
                <ul>
                  <li>{intl.get("order.weimob.help2")}</li>
                  <li>{intl.getHTML("order.weimob.help3")}</li>
                </ul>
              </div>
              {fetchResult.length > 0 && (
                <div>
                  <div style={{fontWeight: 700}}>{intl.get("order.order")}: {fetchResult.length} 건</div>
                  <ul>
                    <li>{intl.get("common.label.success")}: {intl.get("common.label.count", {count: numSucceeded})}</li>
                    <li>
                      <div style={{color: "red"}}>{intl.get("common.label.fail")}: {intl.get("common.label.count", {count: numErrors})}</div>
                      <div>
                        <ul>
                          {fetchResult.filter(r => !r.success).map((res, index) => {
                            return (<li key={index}>{res.orderNo}: {res.errorMessage}</li>);
                          })}
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              )}
              <div>
                {intl.get("order.weimob.orderNo")}:
                <TextArea rows={10} value={orderNoText} onChange={(e) => setOrderNoText(e.target.value)}/>
              </div>
            </div>
          </Spin>
        </Modal>
  )
};

export default WeimobOrderFetchModal;