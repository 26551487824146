import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import ProductList from "./ProductList";
import ProductEditNew from "./ProductEditNew";
import ProductEditExisted from "./ProductEditExisted";
import Util from "../../util/Util";
import CategoryApi from "../category/CategoryApi";
import BrandApi from "../brand/BrandApi";
import ProductApi from "./ProductApi";
import { useLayoutState } from "../../components/layout/AppLayoutContext";
import {message} from "antd";
import { saveAs } from 'file-saver';
import intl from "react-intl-universal";
import SystemSettingApi from "../system_setting/SystemSettingApi";

const initialPagination = {
  current: 1,
  pageSize: 20,
  total: 0,
};

const initialFilter = {
  name: "",
  idType: "",
  idTarget: "",
  published: "",
  eventProduct: "",
  hideToUser: "",
  brandId: "",
  categoryId: "",
  subCategoryId: "",
  sort: "latest",
  myOpenToMarket: false,
};

const Product = (props) => {
  const layoutState = useLayoutState();

  const [parentPath, setParentPath] = useState("");
  const [application, setApplication] = useState({});
  const [products, setProducts] = useState([]);
  const [hasEventProductIds, setHasEventProductIds] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(false);
  const [numOfFailedCopied, setNumOfFailedCopied] = useState(0);
  const [visibleFetchProductButton, setVisibleFetchProductButton] = useState(false);

  const [pagination, setPagination] = useState(initialPagination);
  const [filter, setFilter] = useState(initialFilter);

  useEffect(() => {
    setParentPath(props.match.path);
  }, [props.match.path]);

  useEffect(() => {
    setApplication(layoutState.application);
    searchProducts(pagination);
    loadBrands();
    loadCategories();
    countDisabledMarketProducts();
    checkVisibleFetchProductButton(layoutState.application.id);
    return () => {};
  }, []);

  const checkVisibleFetchProductButton = (appId) => {
    SystemSettingApi.getPublicSystemSettings().then(res => {
      const setting = res.data.find(setting => setting.name === "interlogis.appId");
      if (!setting) {
        setVisibleFetchProductButton(false);
      } else {
        setVisibleFetchProductButton(setting.value === ("" +  appId));
      }
    });
  };

  const countDisabledMarketProducts = () => {
    ProductApi.countDisabledMarketProducts().then(res => {
      setNumOfFailedCopied(res.data);
    }).catch(err => {
      console.log("countDisabledMarketProducts err:", err);
    });
  };

  const searchProducts = (pagination, filterParam=null) => {
    const currentFilter = filterParam == null ? filter : filterParam;
    setLoading(true);
    const params = getSearchParam(pagination, currentFilter);

    ProductApi.getProducts(params)
      .then((res) => {
        const pagingResult = res.data;
        const pagination = {
          current: pagingResult.page,
          pageSize: pagingResult.pageSize,
          total: pagingResult.count,
        };
        const productsRes = pagingResult.data;
        setProducts(productsRes);
        setPagination(pagination);
        countDisabledMarketProducts();
        getEventOriginProducts(productsRes);
        getHasEventProducts(productsRes.filter(p => !p.eventProduct).map(p => p.id));
      }).catch(err => {
        message.error(intl.get("common.message.contactAdmin"));
      }).finally(() => {
        setLoading(false);
      });
  };

  // 이벤트 상품이 아닌 경우 해당 상품과 연관된 이벤트 상품이 있는지 확인한다.
  const getHasEventProducts = (productIds) => {
    if (!productIds.length) {
      setHasEventProductIds([]);
    }
    ProductApi.getHasEventProducts(productIds).then(res => {
      setHasEventProductIds(res.data);
    });
  }

  // event 상품인 경우 원본 상품을 가져와서 SKU의 원본 상품명을 표시해 준다.
  const getEventOriginProducts = (products) => {
    const eventProducts = products.filter(p => p.eventProduct);
    let eventOriginProductIds = [];
    eventProducts.forEach(p => {
      eventOriginProductIds = eventOriginProductIds.concat(...p.skus.map(sku => sku.eventOriginProductId));
    });
    if (eventOriginProductIds.length === 0) {
      return;
    }
    ProductApi.getProducts({
      id: eventOriginProductIds,
      pageSize: eventOriginProductIds.length
    }).then(res => {
      const eventOriginProductMap = {};
      res.data.data.forEach(p => {
        eventOriginProductMap[p.id] = p;
      });

      const productsWithEventOrigin = products.map(p => {
        if (p.eventProduct) {
          p.skus = p.skus.map(s => {
            const op = eventOriginProductMap[s.eventOriginProductId];
            if (op) {
              s.eventOriginProductName = op.name;
            }
            return s;
          });
        }
        return p;
      });
      setProducts(productsWithEventOrigin);
    });
  };

  const getSearchParam = (pagination, currentFilter) => {
    const params = {
      pageSize: pagination.pageSize,
      page: pagination.current,
      ...currentFilter,
    };
    return params;
  };

  const loadCategories = () => {
    CategoryApi.getCategoriesByApp({}).then((res) => {
      setCategories(res.data);
    });
  };

  const loadBrands = () => {
    BrandApi.getBrands({}).then((res) => {
      setBrands(res.data);
    });
  };

  const setProductsState = (products) => {
    setProducts(products);
  };

  const setFilterState = (filter) => {
    setFilter({ ...filter });
  };

  const setPaginationState = (pagination) => {
    setPagination({ ...pagination });
  };

  const downloadProducts = () => {
    if (products.length === 0) {
      message.error(intl.get("common.message.noData", {name: intl.get("order.list.productInfo")}));
      return;
    }
    setLoading(true);
    ProductApi.download(filter).then(res => {
      const header = res.headers["content-disposition"];
      const filename = header.match(/filename="(.+)"/)[1];
      const contentType = res.headers["content-type"];

      const blob = new Blob([res.data], { contentType });
      saveAs(blob, filename);
    }).catch(err => {
      console.log("download error:", err);
      message.error(intl.get("common.message.contactAdmin"));
    }).finally(() => {
      setLoading(false);
    });
  };

  // NOTICE: path argument를 사용하기 위해서는 Route의 순서가 중요. new -> edit -> show 순서로 작성
  return (
    <div className="page-content">
      <Switch>
        <Route
          path={props.match.path}
          exact
          render={(props) => (
            <ProductList
              products={products}
              hasEventProductIds={hasEventProductIds}
              categories={categories}
              brands={brands}
              filter={filter}
              loading={loading}
              pagination={pagination}
              application={application}
              setProducts={setProductsState}
              setFilter={setFilterState}
              setPagination={setPaginationState}
              searchProducts={searchProducts}
              downloadProducts={downloadProducts}
              parentPath={parentPath}
              numOfFailedCopied={numOfFailedCopied}
              visibleFetchProductButton={visibleFetchProductButton}
              {...props}
            />
          )}
        />
        <Route
          path={`${props.match.path}/new`}
          render={(props) => (
            <ProductEditNew
              categories={categories}
              brands={brands}
              application={application}
              loadBrands={loadBrands}
              pagination={pagination}
              searchProducts={searchProducts}
              parentPath={parentPath}
              eventProduct={false}
              loadCategories={loadCategories}
              {...props}
            />
          )}
        />
        <Route
          path={`${props.match.path}/new-event-product`}
          render={(props) => (
            <ProductEditNew
              categories={categories}
              brands={brands}
              application={application}
              loadBrands={loadBrands}
              pagination={pagination}
              searchProducts={searchProducts}
              parentPath={parentPath}
              eventProduct={true}
              loadCategories={loadCategories}
              {...props}
            />
          )}
        />
        <Route
          path={`${props.match.path}/:productId`}
          render={(props) => (
            <ProductEditExisted
              categories={categories}
              brands={brands}
              application={application}
              loadBrands={loadBrands}
              pagination={pagination}
              searchProducts={searchProducts}
              parentPath={parentPath}
              loadCategories={loadCategories}
              {...props}
            />
          )}
        />
      </Switch>
    </div>
  );
};

export default Product;
