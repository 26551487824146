import React, {useEffect} from "react";
import { Typography, Divider, Alert } from 'antd';
import CommonApi from "../common/CommonApi";
import {useHistory} from "react-router-dom";

const { Title, Paragraph, Text, Link } = Typography;

const Guide = (props) => {
  const history = useHistory();
  const downloadPrivateFile = (file) => {
    let fileName = "";
    if (file === "miniprogram") {
      fileName = "위챗공식계정가입및미니프로그램개설가이드.pdf";
    }
    const path = "downloads/" + fileName;
    CommonApi.downloadPrivateFile(path).then(res => {
      console.log("decoded:", decodeURIComponent(res.data));
      let aTag = document.createElement('a');
      aTag.href = decodeURIComponent(res.data);
      aTag.download = fileName;
      aTag.target = "_blank";
      aTag.click();
    }).catch(e => {
    });
  }

  const goPage = (path) => {
    history.push(path);
  };

  return (
    <div>
      <Typography>
        <Title level={3}>
          <Link target="_blank" href="https://bettercode.dooray.com/share/pages/uR2pMCg_QuWx2lNXSA70tQ">
            서비스 사용 매뉴얼로 이동(클릭)
          </Link>
        </Title>
        {/*<Paragraph>*/}
        {/*  요우마 서비스는 중국 소비자를 대상으로 상품을 판매하는 쇼핑몰 앱(위챗 미니프로그램)과 이를 관리하기 위한 웹 기반 관리도구를 제공하는 서비스입니다.<br/>*/}
        {/*  미니프로그램 쇼핑몰의 색상, 메인 페이지의 구성, 행사 페이지 추가, 상품 배치 등을 사용자가 직접 설정할 편집 도구를 제공하고 있습니다.<br/>*/}
        {/*  미니프로그램은 위챗 플랫폼 웹페이지에서 고객사가 직접 신청하신 후 미니프로그램 APP ID를 받게 되면 요우마 서비스 운영자가 배포해 드리고 있습니다.<br/>*/}
        {/*</Paragraph>*/}
        {/*<Paragraph>*/}
        {/* <pre>*/}
        {/*    보세구콰징(1210) 통관 또는 직구콰징(9610) 통관은 중국 해관의 통관 규정에 부합되어야 하기 때문에 별도 문의하시는 고객에 대해 대응해 드리고 있습니다.<br/>*/}
        {/*    <a onClick={() => goPage("/questions")}>1:1 문의</a>를 통해 문의 주세요.*/}
        {/*  </pre>*/}
        {/*</Paragraph>*/}
        <Title level={5}>쇼핑몰 오픈 절차</Title>
        <Paragraph>
          서비스 처음 가입 후 쇼핑몰을 오픈하기 위해서는 다음과 같은 과정으로 진행 합니다.
          진행 중에 문의 사항이 있으면 <Link href="/questions">1:1 문의</Link>를 통해 문의 주세요.
        </Paragraph>
        <Paragraph>
          <ul>
            <li><a onClick={() => goPage("/products")}>상품 등록</a></li>
            <li><a onClick={() => goPage("/app-design")}>상점 기본 디자인 설정</a></li>
            <li><a onClick={() => goPage("/app-pages")}>상점 홈페이지 설정</a></li>
            <li><a onClick={() => downloadPrivateFile("miniprogram")}>위챗 플랫폼 웹페이지에서 미니프로그램 위챗 신청(매뉴얼 다운로드)</a></li>
            <li><a onClick={() => goPage("/company-applications")}>미니프로그램 정보 설정</a></li>
            <li><a onClick={() => goPage("/questions")}>1:1 문의사항에 미니프로그램 배포 요청</a></li>
            <li>시스템 관리자가 미니프로그램 배포</li>
            <li>중국 위챗에서 미니프로그램 심사</li>
            <li>심사 통과 후 시스템 관리자가 정식 배포</li>
            <li>소비자에게 노출되며 사용 가능</li>
          </ul>
        </Paragraph>
        <Paragraph>
          <pre>
            미니프로그램신청은 위챗이 운영하는 사이트에서<a href="https://mp.weixin.qq.com/" target="_blank"> 직접 등록</a>
            <p/>상점디자인, 상품등록의 기능은 위챗 미니프로그램 개설 이전에도 사용할 수 있습니다.
          </pre>
        </Paragraph>
      </Typography>
    </div>
  )
};

export default Guide;