import React from "react";

const messageZhCN = {
  "menu.home": "Home",
  "menu.dashboard": "概况",
  "menu.companyInitSetting": "登录设置",
  "menu.shop": "店铺",
  "menu.shop.design": "店铺设计",
  "menu.shop.page-settings": "店铺页面",
  "menu.shop.application.logistics.company": "物流公司(店铺)",
  "menu.shop.files": "图片管理",
  "menu.shop.terms": "设置条款",
  "menu.operator-roles": "管理员权限",
  "menu.products": "产品",
  "menu.product.list": "我的商品",
  "menu.product.productMarket": "供货",
  "menu.product.share": "商品共享",
  "menu.product.category": "分类",
  "menu.product.brand": "品牌",
  "menu.orders": "订单",
  "menu.orders.list": "订单管理",
  "menu.orders.upload": "订单上转",
  "menu.events": "活动管理",
  "menu.events.coupons": "优惠券",
  "menu.events.coupon-issues": "赠券情况",
  "menu.live": "直播",
  "menu.live.rooms": "直播间列表",
  "menu.live.notRegisteredProduct": "未入商品库",
  "menu.live.registeredProduct": "已入商品库",
  "menu.call-center": "客户管理",
  "menu.call-center.user": "客户",
  "menu.call-center.faq": "常见问题",
  "menu.companySetting": "设置",
  "menu.systemSetting.logistics-company": "物流公司(系统)",
  "menu.systemSetting.info": "公司信息",
  "menu.systemSetting.manager": "管理员",
  "menu.systemSetting.shop": "小程序",
  "menu.systemSetting": "系统设置",
  "menu.help-center": "客服中心",
  "menu.help-center.question": "1:1问答",
  "menu.help-center.guide": "使用指南",
  "menu.help-center.notice": "温馨提示",
  "menu.help-center.notice.admin": "管理公告",
  "menu.settlement": "对账",
  "menu.settlement.order": "订单对账",
  "menu.settlement.seller": "销售员对账",
  "menu.reply": "商品评价",
  "menu.logistics": "配送",
  "menu.logistics.register": "配送申请",
  "menu.logistics.list": "申请目录",
  "menu.miniprogram.setting": "小程序信息",
  "menu.supplier": "供货商",
  "menu.shop.component": "管理UI组件",

  "common.true": "是",
  "common.false": "否",
  "common.createdAt": "录入日期",
  "common.updatedAt": "修改日期",

  "common.button.ok": "确认",
  "common.button.cancel": "取消",
  "common.button.save": "保存",
  "common.button.close": "关闭",
  "common.button.add": "添加",
  "common.button.search": "查询",
  "common.button.select": "查询",
  "common.button.show": "查看",
  "common.button.edit": "修改",
  "common.button.list": "列表",
  "common.button.before": "上一步",
  "common.button.next": "下一步",
  "common.button.delete": "删除",
  "common.button.back": "上一步",
  "common.button.download": "下载",
  "common.button.upload": "上传",
  "common.button.fileManager": "图片管理",
  "common.button.sendMessage": "发消息",
  "common.button.multiSetting": "批量设置",
  "common.button.newFolder": "新建文件夾",
  "common.button.qrdownload": "下载二维码",
  "common.button.selectFile": "选择文件",
  "common.button.copy": "复制",
  "common.button.addBulk": "批量上传",
  "common.button.preview": "预览",
  "common.button.confirm": "确认",

  "common.inputField": "请输入{name}",
  "common.selectField": "请选择{name}",
  "common.confirm.delete": "确认删掉{name}",
  "common.confirm.save": "确认保存{name}",
  "common.message.noData": "没有{name}",
  "common.message.nth": "第{index}个",
  "common.message.saved": "已保存",
  "common.message.deleted": "已删除.",
  "common.message.selectAtLeastOne": "请选择至少一个{name}",
  "common.message.jian": "件",
  "common.message.success": "处理成功",
  "common.message.contactAdmin": "系统问题。请联系系统管理员",
  "common.message.copied": "已复制",
  "common.message.inputNumber": "请输入数字。",
  "common.message.selectFile": "请选择文件。",

  "common.label.youma": "优码",
  "common.label.listTotal": "总共{total}条",
  "common.label.total": "总共",
  "common.label.price": "价格",
  "common.label.select": "选择",
  "common.label.call-center": "客服中心",
  "common.label.language.korean": "韩语",
  "common.label.language.chinese": "简体中文",
  "common.label.upload.help": "输入图片链接或者上传图片",
  "common.label.download": "下载",
  "common.label.detail": "详细信息",
  "common.label.month": "月",
  "common.label.wechat": "微信",
  "common.label.count": "{count}件",
  "common.label.success": "成功",
  "common.label.fail": "失败",
  "common.label.number": "一,二,三,四,五,六,七,八,九,十",
  "common.label.approve": "发布",

  "layout.reload": "系统已更新，请刷新浏览器(windows系统: Ctrl+F5, 苹果Mac: Command + Shift + R)",

  "login.login": "登录",
  "login.loginOut": "推出",
  "login.welcome": " ",
  "login.loginId": "账号",
  "login.password": "密码",
  "login.noMember": " ",
  "login.findPasswordTitle": "找回密码",
  "login.findPasswordButton": "忘记密码",
  "login.resetPassword": "设置密码",
  "login.resetPassword.wrongUrl": "页面连接有错误，请再确认邮件发送的连接。",
  "login.password.changed": "改密码成功",

  "register.register": "注册",
  "register.diffPassword": "两次密码输入不一致",
  "register.shortLoginId": "注册账号需要至少六位",
  "register.shortPassword": "注册密码需要至少八位",
  "register.hasLoginId": " ",
  "register.confirmingPassword": "确认密码",
  "register.agree": "同意",
  "register.must": " ",
  "register.message.agree1": "请同意会员条款",
  "register.message.agree2": "请同意隐私权条款",
  "register.message.alreadyUsedId": "此账号已使用",
  "register.registered": "注册成功",

  "application.design.basic": "基本设置",
  "application.design.app.shareImage": "分享图片",
  "application.design.app.logo": "Logo图片",
  "application.design.selectTheme": "主题色",
  "application.design.theme": "主题色",
  "application.design.userDefined": "自定义",
  "application.design.colorTable": "选中的主题颜色表",
  "application.design.primaryColor": "主色",
  "application.design.secondaryColor": "副色",
  "application.design.titleBar": "顶部标题条",
  "application.design.fontSize": "字体大小",
  "application.design.fontWeight": "字体粗细",
  "application.design.padding": "空白幅度",
  "application.design.padding.help": "按上，右，下，左的顺序， 用英文逗号（,）区分设置：例如： 10,5,10,5 的形式设置。",
  "application.design.title.style": "类型",
  "application.design.title.style1": "所有页面适用文字显示",
  "application.design.title.style2": "在主页不使用顶部标题条（其他页面使用文字显示）",
  "application.design.title.style3": "适用图片于所有页面",
  "application.design.title.style4": "只适用图片于主页（其他页面使用文字）",
  "application.design.image": "图片",
  "application.design.align": "对齐",
  "application.design.searchIconOnIndex": "메인페이지에 검색 아이콘 표시",
  "application.design.priceSetting": "设置价格显示",
  "application.design.priceSetting.display":"实际页面",
  "application.design.priceSetting.mainCurrency": "1列货币",
  "application.design.priceSetting.subCurrency": "2列货币",
  "application.design.priceSetting.usdFormat": "货币显示方式",
  "application.design.priceSetting.showAbout": "人民币前显示（约）",
  "application.design.priceSetting.showDiscount": "折扣金额显示方式",
  "application.design.priceSetting.CNY": "人民币",
  "application.design.priceSetting.productCurrency": "商品管理金额货币",
  "application.design.priceSetting.paymentCurrency": "支付金额货币（跟支付公司约定的货币）",
  "application.design.priceSetting.hidden": "隐藏",
  "application.design.priceSetting.show": "显示",
  "application.design.priceSetting.writtenByText": "字母（金额后面 美元，韩币）",
  "application.design.priceSetting.writtenBySymbol": "字符（金额前面, $, ₩)",
  "application.design.priceSetting.discountRate": "折扣率",
  "application.design.priceSetting.discountPrice": "折扣价格",
  "application.design.webmall": "网站商店设置",
  "application.design.webmall.banner": "轮播图",
  "application.design.webmall.logo": "Logo图片",
  "application.design.message.deployAfterSave": "请保存后发布",
  "application.design.message.deployed": "已发布",
  "application.design.message.wrongFileType": "此文件形式有问题",

  "products.product": "商品",
  "products.source": "商品来源",
  "products.attr": "属性",
  "products.productMarket.desc": "此商品是使用优码服务的供应商和品牌商供应的商品，<br/>" +
      "请选择愿意销售的商品后点击<span style='color: red'>“添加我的商品”</span>将可以销售。<br/>" +
      "不能修改商品信息，请使用标签来管理商品。<br/>" +
      "销售后确认订单，上传商品的供应商将会发送商品。想要供货报价单等的更多信息， 请联系管理员。",
  "products.openToMarket": "分享到供货",
  "products.openToMarket.comment": "提供商品信息分销",
  "products.openToMarketOnly": "供货不分享商品信息",
  "products.addToMyProduct": "添加我的商品",
  "products.removeFromMyProduct": "放弃",
  "products.confirmRemoveFromMyProduct": "继续要放弃吗?",
  "products.addedToMyProduct": "已添加",
  "products.source.my": "我的商品",
  "products.source.productMarket": "供货",
  "products.preview": "预览",
  "products.wholesaleProduct": "批发商店",
  "products.wholesaleMinProducts": "最低购买数量",
  "products.wholesaleProduct.comment": "批发商抓用商品(在pifa.youma.kr上架)",
  "product.message.selected.copied": "已选择供货用商品，供货商品不可修改。",
  "products.id": "商品ID",
  "products.title": "产品管理",
  "products.button.new": "产品录入",
  "products.button.upload": "上传",
  "products.name": "产品名称",
  "products.name.language": "产品名称语言",
  "products.name.cn": "中文",
  "products.totalSales": "销售量",
  "products.id.kind": "编码种类",
  "products.ignore.others": "(不使用其他条件)",
  "products.brand": "品牌",
  "products.brandId": "品牌",
  "products.name.ko": "韩语",
  "products.name.kr": "韩语",
  "products.name.en": "英语",
  "products.style": "产品类型",
  "products.cbtType": "贸易方式",
  "products.category": "商品分类",
  "products.categoryId": "商品分类",
  "products.sort": "顺序",
  "products.sort.latest": "新品上市",
  "products.sort.lowest": "价格从低到高",
  "products.sort.highest": "价格从高到低",
  "products.sort.name_cn": "产品名称(中文)",
  "products.sort.name_kr": "产品名称(韩语)",
  "products.sort.total_sales_desc": "销售量多到少",
  "products.taxRate": "产品税率",
  "products.price": "价格",
  "products.price.rep": "商品目录显示价",
  "products.published": "上架",
  "products.published.help": "在商店显示",
  "products.published.true": "上架",
  "products.published.false": "非上架",
  "products.eventProduct": "活动商品",
  "products.hideToUser": "仅分享销售",
  "products.hideToUser.help": "顾客可以购买，但是我的，商品搜索页面中会隐藏该商品<br/>可用于管理员分享，并销售。",
  "products.upcoming": "上新",
  "products.sharedServices": "分享服务",
  "products.removed": "备份后删除",
  "products.featuredImage": "主图",
  "products.images": "图片",
  "products.descriptions": "详情页图",
  "products.tag": "标签",
  "products.tag.message": "'{name}'已存在的标签",
  "products.addTag": "添加标签",
  "products.originCountry": "原产地",
  "products.delivery": "配送",
  "products.deliveryFee": "运费",
  "products.profit": "利润",
  "products.listPrice": "原价",
  "products.salesPrice": "零售价",
  "products.minMaxSalesPrice": "零售价格范围",
  "products.bundled": "是否设置套餐",
  "products.bundled.comment": "套餐里的商品（SKU）不可单独购买",
  "products.autoExpiredAt": "有效期至",
  "products.options": "产品规格",
  "products.options.count": "商品规格",
  "products.selectProduct": "选择商品",
  "products.options.count.single": "统一规格",
  "products.options.count.multi": "多规格",
  "products.options.split": "分开显示规格",
  "products.price.help": "代表价格是套餐商品货西商品的每个选项价格不同时在商品目录显示的管理员输入的价格<br/>" +
          "如有一个选项，输入此选项的销售价或优惠前价格等的值",
  "products.options.add": "添加选项",
  "products.options.comment": "修改选项后必须修改SKU里选项",
  "products.options.name": "选项名称",
  "products.options.optionValues": "选项规格",
  "products.options.active": "使用与否",
  "products.options.edit": "编辑",
  "products.options.delete": "删除",
  "products.button.addProduct": "添加商品",
  "products.button.eventProduct": "活动商品",
  "products.button.addEventProduct": "添加活动商品",
  "products.button.testOrder": "测试购买",
  "products.button.coupon": "优惠券",
  "products.button.selectFileOrUpload": "上传/选择图片",
  "products.edit.basic": "基本信息",
  "products.edit.detail": "商品规格",
  "products.edit.desc": "详情页图",
  "products.edit.inputImage": "填写图片链接",
  "products.edit.selectImage": "上传图片/选择已上传的图片",
  "products.upload.help1": "下面点击下载【商品信息模板】填完模板，点击‘选择文件’选商品信息文件，再点击‘确认’",
  "products.upload.help2": "商品信息模板",
  "products.upload.help3": "选择文件",
  "products.stopSales": "销售结束",
  "products.stopSales.help": "出现在商品列表中，但无法购买",
  "products.marketProductPublished": "变更原始商品",
  "products.marketProductPublished.help1": "如果您在商品规格信息中确认并保存，此消息将消失。",
  "products.marketProductPublished.help2": "原始商品的公开状态为非公开状态。",
  "products.supplierProductId": "供应商商品ID",
  "products.supplier": "供应商代码",
  "products.imageLink": "图片链接",
  "products.base64.error": "无法附加base64图片",
  "products.relatedProducts": "연관상품",
  "products.list.moreSku": "点击查看剩余{count}件规格",
  "products.list.closeSku": "点击收起",

  "products.sku": "商品规格",
  "products.sku.add": "添加规格",
  "products.sku.edit": "修改规格",
  "products.sku.basicInfo": "规格基本信息",
  "products.sku.priceInfo": "规格价格({symbol})",
  "products.sku.salesInfo": "销售信息",
  "products.sku.stocksInfo": "库存信息",
  "products.sku.declareInfo": "审核信息",
  "products.sku.optionText": "规格名",
  "products.sku.barCode": "条形码",
  "products.sku.skuCode": "商家编码",
  "products.sku.salesPrice": "零售价(一个）",
  "products.sku.listPrice": "市场价",
  "products.sku.purchasePrice": "成本价",
  "products.sku.supplyPrice": "판매공급가",
  "products.sku.minSalesPrice": "最低零售价",
  "products.sku.maxSalesPrice": "最高零售价",
  "products.sku.salesQuantity": "零售数量",
  "products.sku.displayStocks": "库存",
  "products.sku.realStocks": "实际库存",
  "products.sku.stocks": "库存",
  "products.sku.taxRate": "税率",
  "products.sku.hsCode": "HS-CODE",
  "products.sku.grossWeight": "毛重(公斤)",
  "products.sku.netWeight": "净重(公斤)",
  "products.sku.volume": "体积(m2)",
  "products.sku.package": "包装",
  "products.sku.unit": "计量单位",
  "products.sku.unitQty": "计量单位数量",
  "products.sku.ingredients": "主成分",
  "products.sku.customsOption": "海关规格名称",
  "products.sku.imageUrl": "规格图片",
  "products.sku.addedList": "已添加的规格",
  "products.sku.copied.message": "已经复制了。填写信息后点击\"添加按钮\"",
  "products.sku.exist.optionText": "已有规格名",
  "products.sku.exist.barCode": "已有条形码",
  "products.sku.button.apply": "适用",
  "products.sku.salePrice.overMinMax": "销售价不满足价格区间",
  "products.sku.taxRateDesc": "通关类型是9610,1210时输入<br/>" +
        "注意：海关定期提供已结算商品的征税信息海关定期提供<br/>" +
        "以海关计算的跨境税报销，跨境税不是进口人申报的税。<br/>" +
        "税率不准确而申报的也有可能通关 <br/>" +
        "因税率输入错而造成的问题客户及销售人负责人",
  "products.sku.newSkuHelp": "一个商品有多个选项时，选项，条码等<br/>" +
    "除了几个信息意外，大部分的信息一样。\n<br/>" +
    "输入一个信息后，可以继续添加所需要修改的部分<br/>" +
    "<hr/>" +
    "在已有的商品上添加选项时点击已有的选项 <br/>" +
    "在输入框已有了选项信息后，修改选项名，条码等其他信息后，点击”添加新选项“。",
  "products.sku.updateSkuHelp": "修改已输入的选项时<br/>" +
    "1. 在列表中选择选项\<br/>" +
    "2. 已选的选项值显示在项目中<br/>" +
    "3. 修改信息后点击“适用”<br/>",
  "products.sku.optionText.help": "消费者购买时选的选项",
  "products.sku.barCode.help": "商品包装上的条形码",
  "products.sku.skuCode.help": "在企业内部管理时使用的代码",
  "products.sku.salesPrice.help": "零售价，消费者支付的价格",
  "products.sku.listPrice.help": "建议零售价，如建议零售价不是0元的话会显示为优惠前的价格",
  "products.sku.purchasePrice.help": "商品原价，是管理用的价格，消费者看不到",
  "products.sku.supplyPrice.help": "使用代理商销售时，提供给代理商的价格",
  "products.sku.minSalesPrice.help": "分销时销售员设置的最低金额",
  "products.sku.maxSalesPrice.help": "分销时销售员设置的最高金额\n",
  "products.sku.salesQuantity.help": "一次支付并购买时的配送数量（任意数量是1），套餐销售时使用。 例：买4 打6折",
  "products.sku.displayStocks.help": "在消费者页面显示的库存数量。不管理库存时不用输入。 在小程序设计管理中可以设置显示与否。",
  "products.sku.realStocks.help": "实际库存数量，不管理库存时不用输入。",
  "products.sku.hsCode.help": "进口时使用的海关编码。使用通关插件时一定要输入。",
  "products.sku.customsOption.help": "海关规定的选项的详细内容。使用通关插件时一定要输入。",
  "products.sku.grossWeight.help": "货物及包装材料的重量之和，计量单位为千克。",
  "products.sku.netWeight.help": "货物本身的实际重量，计量单位为千克，不含包装容器和外包装材料的重量。",
  "products.sku.volume.help": "商品尺寸规格",
  "products.sku.package.help": "包装种类：例：纸箱",
  "products.sku.unit.help": "规格型号：个，盒，瓶等",
  "products.sku.unitQty.help": "数量",
  "products.sku.ingredients.help": "成分含量，使用通关插件时一定要输入。",
  "products.sku.priceByQty": "每数量的价格",
  "products.sku.quantity": "数量",
  "products.sku.price": "价格",
  "products.sku.addQty": "添加数量",
  "products.sku.inputPriceByQuantity": "请输入每数量的价格",
  "products.sku.requiredAtLeastOne": "请设置至少1个价格区间",
  "products.sku.inputPrice": "请输入第{number}级的商品价格",
  "products.sku.lessThanPrevious": "第{number}级输入的数量少于或等于上一级的数量",
  "products.sku.greatThanPrevious": "第{number}级输入的价格大于或等于上一级的价格",
  "products.sku.checkPrice": "请确认是否每数量的价格有错误",
  "products.sku.productNotSelected": "请选择商品",
  "products.sku.skuNotSelected": "请选择商品选项",

  "products.desc.mode": "编辑方式",
  "products.desc.mode.images": "图片",
  "products.desc.mode.html": "编辑",
  "products.desc.mode.name.images": "图片",
  "products.desc.mode.name.html": "编辑",
  "products.share.user_id": "用户ID",
  "products.share.user": "用户",
  "products.share.date": "分享日期",
  "products.searchModal.title": "查询商品",
  "products.marketProductDisabled": "供货商品公开状态已变更",
  "products.marketProductSkuChanged": "供货商品规格已变更",
  "products.marketProductDeleted": "供货商品已删除",
  "products.marketProduct.message": "如果是营销商品，原始商品是未公开状态或者已删除，则无法修改。",
  "products.warnFailedCopiedProduct": "供货商品中{count}个商品已下架了。",
  "products.exceedPriceRange": "超过商品的价格范围",
  "products.market.alreadyAdded": "这商品已有，继续要添加吗?",
  "products.market.confirmAdd": "继续要添加吗?",
  "products.selectSku": "请选择商品规格",

  "order.order": "订单",
  "order.title": "订单管理",
  "order.searchOrderDate": "下单时间",
  "order.searchPayDate": "支付时间",
  "order.searchOrderDateStart": "开始",
  "order.searchOrderDateEnd": "结束",
  "order.list.productInfo": "商品信息",
  "order.list.paymentInfo": "实收金额",
  "order.list.userInfo": "客户信息",
  "order.detail": "订单详情",
  "order.paymentHistory": "支付详情",
  "order.list.moreProduct": "点击查看剩余{count}件商品",
  "order.list.closeProduct": "点击收起",
  "order.merchandiserId": "供应商ID",
  "order.orderNo": "订单编号",
  "order.externalOrderNo": "外部订单编号",
  "order.declareOrderNo": "海关申报订单编号",
  "order.userId": "用户ID",
  "order.userName": "买家姓名",
  "order.userId.help": "为了通关，请输入身份证号码和实名信息。",
  "order.phone": "买家电话",
  "order.offerId": "精选ID",
  "order.salesManagerId": "经销商ID",
  "order.search.download.comment": "下载已支付订单，并处理为准备配送状态",
  "order.upload.deliveryNo": "上传运单信息",
  "order.fetch.weimobOrder": "微盟订单",
  "order.offerImage": "精选主图",
  "order.featuredImage": "商品图片",
  "order.offerTitle": "精选ID",
  "order.status": "订单状态",
  "order.paymentAmount": "付款金额",
  "order.paymentAmountPay": "支付金额调用",
  "order.userPaidAmountFormula": "计算",
  "order.paymentAmountUser": "实付款",
  "order.paymentCompany": "支付公司",
  "order.settlement.detail": "结算明细",
  "order.taxTotal": "税额",
  "order.customClearance": "是否通关",
  "order.noCustomClearance": "不要通关",
  "order.orderDate": "订单日期",
  "order.orderProduct": "商品",
  "order.finishedAt": "交易成功日期",
  "order.idCardNo": "身份证",
  "order.shippingAddress": "收货地址",
  "order.recipient": "收货人",
  "order.address": "地址",
  "order.productAmount": "零售价",
  "order.discountAmount": "折扣价",
  "order.deliveryFee": "运费",
  "order.failedReason": "订单异常原因",
  "order.failedMessage": "订单异常反馈信息",
  "order.verifyDepartmentTradeId": "支付申报ID",
  "order.chinaportSessionId": "海关 ID",
  "order.localLogisticsNo": "韩国国内物流ID",
  "order.deliveredAt": "发货日期",
  "order.deliveryCompany": "物流公司",
  "order.refund.button": "退款",
  "order.refund": "是否退款",
  "order.refund.help": "退款由个别处理",
  "order.refundAt": "退款日期",
  "order.requireIdCard": "是否提交身份证",
  "order.idCardStatus": "提交身份证后状态",
  "order.idCardStatus.none": "尚未处理",
  "order.idCardStatus.uploaded": "用户已上传",
  "order.idCardStatus.submitted": "已提交海关",
  "order.managerMarker": "标记",
  "order.addManagerMarker": "添加标记",
  "order.clientApp.weimob": "微盟",
  "order.clientApp.etc": "其它",
  "order.orderDelivery": "配送",
  "order.offerListPrice": "产品价格",
  "order.quantity": "数量",
  "order.orderAmount": "订单额",
  "order.taxRate": "税率",
  "order.cbtType": "贸易方式",
  "order.cbtType.1210": "保税进口（1210）",
  "order.cbtType.9610": "直购进口（9610）",
  "order.cbtType.mail": "行邮税",
  "order.cbtType.none": "不清关",
  "order.cbtType.customOrderGuid": "订单报文号",
  "order.cbtType.customReqGuid": "进口报文号",
  "order.button.sendPayment": "推支付单",
  "order.button.reSendPayment": "重新推支付单",
  "order.button.sendLocalLogistics": "传输韩国物流",
  "order.button.sendLogistics": "传输运单",
  "order.button.sendOrder": "传输订单",
  "order.button.sendClearance": "传输清单",
  "order.button.cancel": "取消订单",
  "order.sendPayment.comment": "(发送信息, 只发送支付完成的订单信息)",
  "order.sendOrderToCustoms.comment": "(发送信息, 只发送支付信息处理中的订单信息)",
  "order.logisticsInfo": "物流/通关",
  "order.deliveryInfo": "配送信息",
  "order.paymentInfo": "支付明细",
  "order.total.paymentAmount": "总价",
  "order.sellerUserId": "销售员",
  "order.finish.status": "订单结束状态",
  "order.managerComment": "管理员评论",
  "order.managerComment.help": "显示在“买家订单详细信息”页面",

  "order.status.": "没有",
  "order.status.processing_clearance_9610": "通关中(9610)",
  "order.status.created": "生成订单",
  "order.status.prepared_wx_prepay": "确认支付中",
  "order.status.pay_prepared": "等待支付",
  "order.status.paid": "支付完成",
  "order.status.cancel_req": "申请取消",
  "order.status.wait_pay": "待支付",
  "order.status.wx_customs_success": "传输支付单成功",
  "order.status.send_customs_success": "通过海关审核",
  "order.status.pay_declared": "海关审核中(支付申报完成)",
  "order.status.pay_declared1": "支付申报完成",
  "order.status.order_declaring": "海关审核中(订单申报中)",
  "order.status.order_declared": "海关审核中(订单申报完成)",
  "order.status.order_declared1": "订单申报完成",
  "order.status.declared": "已通关",
  "order.status.declaring": "通关中",
  "order.status.preparing_delivery": "准备发货",
  "order.status.prepare_delivery": "准备发货",
  "order.status.delivering": "配送中",
  "order.status.local_delivering": "韩国国内配送中",
  "order.status.delivered": "配送完成",
  "order.status.finished": "已完成",
  "order.status.failed": "失败",
  "order.status.refund": "退款",
  "order.status.cancel": "取消",
  "order.status.user_cancel": "客户取消",
  "order.status.system_cancel": "自动取消",
  "order.failed.payment_prepay_failed": "准备支付失败",
  "order.failed.payment_failed": "支付失败",
  "order.failed.call_wx_customdeclareorder_failed": "传输支付单失败",
  "order.failed.call_trade_push_failed": "传输清单失败",
  "order.failed.trade_push_result_failed": "没通过海关审核",
  "order.status.success": "成功",
  "order.status.sent": "给保税区已发送",

  "order.payment.refundAmount": "退款额",
  "order.payment.status.get_prepay_id": "准备支付",
  "order.payment.status.success": "支付成功",
  "order.payment.status.failed": "支付失败",
  "order.payment.status.refund": "退款",

  "order.declare.status.created": "生成申报信息",
  "order.declare.status.wait_agent": "PC等待发送",
  "order.declare.status.agent_fetched": "PC已发送",
  "order.declare.status.sent": "给保税区已发送",

  "order.reason.prepare_pay": "支付准备失败",
  "order.reason.payment": "支付失败",
  "order.reason.pay_declared": "海关申报（支付单）失败",
  "order.reason.order_declared": "海关申报（订单）",
  "order.reason.clearance": "海关申报失败",
  "order.reason.etc": "其它",
  "order.reason.none": " ",
  "order.declareInfo": "通关信息",
  "order.additional": "扩展信息",
  "order.noOrder": "无法查询订单信息",
  "order.error": "发生错误， 无法查询订单信息",

  "order.sku.productName": "产品名称",
  "order.sku.skuId": "SKU ID",
  "order.sku.skuName": "SKU名称",
  "order.sku.barcode": "条形码",
  "order.sku.optionText": "选项",
  "order.sku.offerListPrice": "产品价格",
  "order.sku.quantity": "订购数量",
  "order.sku.shipQuantity": "配送数量",
  "order.sku.salesQuantity": "销售数量",
  "order.sku.productAmount": "零售额",
  "order.sku.imageUrl": "产品图片",
  "order.sku.discountAmount": "优惠",
  "order.sku.salesAmount": "订单额",
  "order.sku.taxAmount": "税额",
  "order.sku.offerTitle": "精选",
  "order.sku.salesManagerUserId": "经销商",
  "order.sku.campaignId": "活动",
  "order.sku.hsCode": "HS Code",
  "order.sku.customsOptionText": "海关SKU名称",
  "order.sku.unit": "计量单位",
  "order.sku.unit1": "法定计量单位",
  "order.sku.qty": "法定单位数量",
  "order.sku.unit2": "第二计量单位",
  "order.sku.qty2": "第二数量",
  "order.sku.weight": "重量(千克）",
  "order.sku.volume": "体积(m2)",
  "order.sku.salesPrice": "单价",

  "order.wechatpay": "微信支付",
  "order.custominterface": "通关处理日志",

  "order.delivery.logisticsCompany": "物流企业名称",
  "order.delivery.logisticsNo": "物流企业",
  "order.delivery.trafMode": "运输方式(TrafMode)",
  "order.delivery.trafNo": "运输工具编号(TrafNo)",
  "order.delivery.voyageNo": "航班航次号(VoyageNo)",
  "order.delivery.billNo": "提运单号(BillNo)",
  "order.delivery.grossWeight": "毛重(公斤)(GrossWeight)",
  "order.delivery.netWeight": "净重(公斤)(NetWeight)",
  "order.button.deliveryAndClearance": "申报/发货",
  "order.button.delivery": "发货",
  "order.clearance.message.send_to_payment": "向海关再申报支付单。 您继续要做吗？",
  "order.clearance.payment.help": "推支付信息和海关申报向微信支付和支付宝等的支付公司",
  "order.clearance.payment_detail": "支付按详细内容",
  "order.clearance.payment_info": "推送信息",
  "order.clearance.payment_result": "结果",
  "order.clearance.createDeclareInfo.confirm": "生成申报信息。您继续要做吗？",
  "order.clearance.createDeclareInfo": "生成申报信息",
  "order.clearance.declareInfo": "申报信息",
  "order.clearance.declare.price": "申报金额",
  "order.clearance.createDeclareInfo.help": "为了通关，生成申报信息。生成申报信息时，重新计算商品单价以及跨境税。",
  "order.clearance.sendToLogistics.help1": "将订单信息向保税区或国内物流企业推送",
  "order.clearance.sendToLogistics.help2": "<span style={{color: 'red'}}>注意：</span>重复推送的话有可能重复配送。如果之前推送过信息，请确认保税区再推送",
  "order.clearance.sendToLogistics.request": "推送信息",
  "order.clearance.sendToLogistics.response": "收取信息",
  "order.clearance.sendToLogistics.confirm": "确定推送订单信息吗？",
  "order.clearance.sendToLocalLogistics.company": "合作物流公司",
  "order.clearance.sendToLocalLogistics.help": "<span style={{color: 'red'}}>注意：</span>重新推送时，有可能重复配送。 如果之前推送过，请确认物流公司后再配送。",
  "order.clearance.sendToLocalLogistics.noCompany": "没有韩国物流公司信息。 请在小程序信息设置填写韩国物流公司信息。",
  "order.clearance.sendToLocalLogistics.confirm": "向韩国物流公司推送订单",

  "order.clearance": "清单",
  "order.clearance.payment": "推支付单",
  "order.clearance.order": "推订单",
  "order.clearance.order.bonded": "推订单（保税区）",
  "order.clearance.delivery": "配送信息",
  "order.clearance.deliveryStatus": "配送状态",
  "order.clearance.prepareDelivering": "准备配送",
  "order.clearance.delivering": "配送中",
  "order.clearance.localDelivery": "准韩国内物流",
  "order.clearance.deliveryInfo": "物流信息",
  "order.clearance.logistics": "推物流单",
  "order.clearance.declare_company_interface_type.send_order": "发送订单信息",
  "order.clearance.declare_company_interface_type.callback_delivery": "回复快递信息",
  "order.clearance.declare_company_interface_type.callback_order": "回复订单信息",
  "order.deliveryNo": "快递单号",
  "order.noDeliveryNo": "没有快递单号",
  "order.delivery.tracking": "物流跟踪",
  "order.delivery.noTracking": "没有配送信息",
  "order.deliveryInfo.help": "从保税区发的“订单信息处理成功”信息之后会自动保存快递单号。 <br/>如果快递单号没有自动保存，需要人工填写快递单号。",
  "order.deliveryInfo.sendToWeimeb": "向微盟发送快递单号",
  "order.clearance.sendToBondedArea": " 向保税区推订单信息。<br/>" +
    "需要把清单推到保税区，保税区正常收到清单后，保税区需要确认收取“订单信息处理成功”的信息" +
    "<br/>一旦生成任那个快递单号，就不能取消",
  "order.clearance.apyment.help": "推支付信息和海关申报向微信支付和支付宝等的支付公司",
  "order.clearance.table.date": "日期",
  "order.clearance.table.type": "类型",
  "order.clearance.table.status": "状态",
  "order.clearance.table.errorMessage": "错误信息",
  "order.clearance.table.success": "成功",
  "order.clearance.table.failed": "失败",
  "order.clearance.table.checkStatus": "查看推送状态",
  "order.clearance.table.delete": "删除",
  "order.clearance.table.title.agentStatus": "推送申报信息状态",
  "order.clearance.table.agentStatus.notSent": "尚未推送",
  "order.clearance.table.agentStatus.sending": "在推送中",
  "order.clearance.table.agentStatus.sent": "推送完成",
  "order.clearance.table.agentStatus.error": "错误（请联系管理者）",
  "order.clearance.table.title.sendDate": "推送时间",
  "order.clearance.table.title.orderFile": "申报文件",
  "order.clearance.receive.deliveryInfo": "从保税区接受物流信息",
  "order.clearance.receive.orderResult": "从保税接受发订单结果",
  "order.clearance.message.payment_not_declared": "支付信息尚未处理好。支付信息正常处理后可以推送",
  "order.clearance.message.already_sent_to_bonded_area": "已向保税区推送订单",
  "order.clearance.message.send_to_bonded_area": "此功能是向保税区邀请订单申报，您继续要做吗？",
  "order.clearance.message.send_to_local_logistics": "向韩国物流公司推送订单信息， 您继续要做吗？",
  "order.clearance.message.send_to_logistics": "向中国进口物流公司推送物流申报信息， 您继续要做吗？",
  "order.clearance.message.send_to_agent": "已生成的订单报文向海关推送。 订单报文通过PC段的单一窗口客户端推送。",
  "order.clearance.message.send_to_agent_help": "物流园生成小程序订单报文， 此页面不处理订单推送。请在PC单一窗口客户端处理。",
  "order.clearance.message.send_order": "向海关推送订单信息， 您继续要做吗？",
  "order.clearance.message.clearance": "向海关推送清单， 您继续要做吗？",
  "order.clearance.message.wrong_request": "{action}是错误请求",
  "order.clearance.message.payment_already_sent": "已处理好了支付单申报。您确定再推送支付单吗？",
  "order.clearance.message.cancel_order": "用户取消的订单的处理， 确定处理取消吗？",
  "order.clearance.message.cannot_cancel_order": "不能取消已【失败】 【完成】的订单",
  "order.clearance.message.canceled": "已取消",
  "order.clearance.help.local_logistics": "向韩国物流公司推订单信息。物流公司收取订单信息后开始做包装及出口处理。",
  "order.clearance.help.input_logistics_input": "填写从韩国物流公司获取的海运信息（将要升级系统自动处理功能）",
  "order.clearance.help.send_to_logistics_input": "向中国物流公司推物流单申报信息。需要都填写一下项目物流信息。",
  "order.clearance.help.send_to_customs": "将订单向海关申报",
  "order.clearance.send_detail": "海关申报详细内容",
  "order.clearance.send_info": "推送信息",
  "order.clearance.receive_info": "回执内容",
  "order.clearance.error_message": "错误信息",
  "order.clearance.resetDiscountAmount": "折扣金额设置为0",
  "order.clearance.resetDiscountAmount.help": "折扣金额设置为0时，重新计算销售价",
  "order.clearance.eximbay": "支付公司Exambay的海关申报状态",
  "order.clearance.send": "申报订单",
  "order.declareOrder.declareAmount": "申报金额",
  "order.declareOrder.salesAmount": "商品价格",
  "order.declareOrder.sentAt": "推送日期",
  "order.declareOrder.xml": "申报 XML文件",
  "order.declareOrder.products": "订购商品",

  "order.weimob.title": "获取微盟订单",
  "order.weimob.help1": "获取订单信息前必须处理一下的业务",
  "order.weimob.help2": "设置-》小程序-》填写海关账号信息息",
  "order.weimob.help3": "设置-》小程序-》填写微盟相关信息",
  "order.weimob.orderNo": "微盟订单号码",
  "order.weimob.order": "微盟订单",
  "order.weimob.maxOrders": "一次只能处理10个订单",
  "order.message.weimob.confirm": "您已将微盟订单号{weimobOrderNo}保存为订单号{orderNo}，是否继续保存微盟其他订单？",

  "order.upload.fileType": "格式类型",
  "order.upload.fileType.postfix": "格式",
  "order.upload.sampleFile": "下载模板",
  "order.upload.selectFile": "请选择文件",
  "order.upload.uploadFile": "查看文件内容",
  "order.upload.uploadAll": "全部申请",
  "order.upload.uploadSelected": "只申请勾选选的",
  "order.upload.maxOrders": "一次只能上传200个订单",

  "coupon.name": "活动名称",
  "coupon.type": "活动类型",
  "coupon.type.welcome": "注册优惠",
  "coupon.type.discount": "期间优惠",
  "coupon.type.share": "分享商品（APP）",
  "coupon.active": "状态",
  "coupon.active.true": "启用",
  "coupon.active.false": "未启用",
  "coupon.title": "优惠券名称",
  "coupon.discountType": "优惠券类型",
  "coupon.amountAndRate": "优惠额度",
  "coupon.summary": "优惠内容",
  "coupon.duration": "期间",
  "coupon.expiredAt": "有效期至",
  "coupon.published": "是否有效",
  "coupon.discountType.price": "优惠",
  "coupon.discountType.rate": "折扣",
  "coupon.setting": "规则",
  "coupon.from.register": "注册日起",
  "coupon.from.to": "天",
  "coupon.dateStart": "开始",
  "coupon.dateEnd": "结束",
  "coupon.issue.condition": "发放条件",
  "coupon.use.condition": "使用规则",
  "coupon.friends": "朋友",
  "coupon.products": "折扣商品",
  "coupon.products.all_products": "全部商品",
  "coupon.products.product": "指定商品",
  "coupon.products.brand": "品牌",
  "coupon.products.tag": "标签商品",
  "coupon.button.select.product": "选择商品",
  "coupon.button.select.tag": "选择标签",
  "coupon.button.select.page": "选择页面",
  "coupon.receive.like": "目标点赞数",
  "coupon.share.object": "分享项目",
  "coupon.app": "小程序",
  "coupon.page": "活动页面",
  "coupon.canRedeem": "是否使用",
  "coupon.canRedeem.true": "是",
  "coupon.canRedeem.false": "否",
  "coupon.redeemStatus": "使用状态",
  "coupon.redeemStatus.no": "未使用",
  "coupon.redeemStatus.check": "已用（正在确认支付）",
  "coupon.redeemStatus.yes": "已用",
  "coupon.redeemAmount": "优惠金额",
  "coupon.redeemAt": "使用日期",

  "category.category": "分类",
  "category.selected" : "选择的分类",
  "category.level.1": "一级",
  "category.level.2": "二级",
  "category.modal.add": "添加分类",

  "brand.name": "品牌名称",
  "brand.active": "使用",
  "brand.activeOnly": "使用与否",
  "brand.modal.add": "新增品牌",
  "brand.modal.edit": "修改品牌",

  "dashboard.orderCount": "订单数量",
  "dashboard.orderAmount": "销售现况",
  "dashboard.orderQuantity": "销售数量",
  "dashboard.date.order": "订购日期为准",
  "dashboard.date.finish": "订单完成日期为准",
  "dashboard.orderSummary.OrderAmount": "今天",
  "dashboard.orderSummary.Yesterday": "昨天",
  "dashboard.orderSummary.YesterdayOrderAmout": "（上周同比增长率）",
  "dashboard.orderSummary.ThisMonth": "当月",
  "dashboard.orderSummary.ThisMonthSoFarAmount": "（上月同比增长率）",
  "dashboard.orderSummary.LastMonth": "上月",
  "dashboard.orderSummary.LastMonthAmount": "（上上个月同比增长率）",
  "dashboard.OrderDailySummary.title": "每日订单现况",

  "logistics.company.add": "新增物流公司",
  "logistics.company.modify": "修改物流公司",
  "logistics.company.name": "物流公司名",
  "logistics.company.active": "是否启用",
  "logistics.company.active.true": "启用",
  "logistics.company.active.false": "未启用",
  "logistics.company.weimob.code": "微盟编码",
  "logistics.company.search": "搜索",

  "application.logistics.company.canuse": "可使用的物流公司",
  "application.logistics.company.used": "使用中的物流公司",

  "file_manager.message.max_upload": "一次只能上传5个文件",
  "file_manager.message.exceed_file_size": "{name}的大小超过3MB",
  "file_manager.message.selectFile": "请选择文件",
  "file_manager.message.confirmDeleteFile": "确定删除{count}个文件？",

  "file_manager.fileKind": "文件分类",
  "file_manager.fileKind.index": "首页图片",
  "file_manager.fileKind.product": "商品图片",
  "file_manager.fileKind.etc": "其他图片",
  "file_manager.uploadModal.title": "文件上转",
  "file_manager.folderName": "文件夹名称",
  "file_manager.fileName": "名称",
  "file_manager.fileSize": "大小",
  "file_manager.image": "图片",
  "file.name.placeholder": "名称（只能搜索英文名的文件）",

  "page_design.topPosition": "头部位置",
  "page_design.width": "宽度",
  "page_design.height": "高度",
  "page_design.size": "大小",

  "page_setting.page": "页面",
  "page_setting.pageTitle": "店铺页面",
  "page_setting.published": "公开",
  "page_setting.pageType": "页面分类",
  "page_setting.title": "页面题目",
  "page_setting.title.input": "请输入{name}（单击左侧菜单中的设置）",
  "page_setting.message.inputClick": "请输入点击后后的效果信息",
  "page_setting.message.inputPopupClick": "请输入点击后转换的效果项目",
  "page_setting.message.inputOpenAppID": "请输入open小程序的 App ID",
  "page_setting.message.inputMediaType": "请选择内容的类型",
  "page_setting.message.selectDeleteItem": "请选择要删除的项目",
  "page_setting.message.confirmDelete": "确定删除此项目吗？",
  "page_setting.message.cannotFindSetting": "没有此项目值",
  "page_setting.message.cannotFindItem": "没有查询结果",

  "page_setting.page.index": "首页",
  "page_setting.page.sub": "其他页",
  "page_setting.page.product": "商品详细页面",
  "page_setting.page.cart": "购物车页面",
  "page_setting.page.category": "分类页面",
  "page_setting.page.category.menu": "设置项目",
  "page_setting.page.delivery": "发货状态页面",
  "page_setting.page.my": "我的页面",

  "page_setting.edit.icon.setting": "设置",
  "page_setting.edit.icon.search": "搜索",
  "page_setting.edit.icon.banner": "轮播图",
  "page_setting.edit.icon.image": "图片",
  "page_setting.edit.icon.video": "视频",
  "page_setting.edit.icon.product": "商品目录",
  "page_setting.edit.icon.text": "文体",
  "page_setting.edit.icon.line": "分割占位",
  "page_setting.edit.icon.popup": "弹窗",
  "page_setting.edit.icon.toolbar": "图文导航",

  "page_setting.edit.publish": "发布",
  "page_setting.edit.publish.help": "点击“保存”是临时保存。点击“发布”是反映小程序。",
  "page_setting.edit.backgroundColor": "背景色",
  "page_setting.edit.backgroundImage": "背景图片",
  "page_setting.edit.borderColor": "边框颜色",
  "page_setting.edit.inputBackgroundColor": "输入框背景色",
  "page_setting.edit.pageTitle": "页面名",
  "page_setting.edit.pageType": "页面类型",
  "page_setting.edit.shareImage": "分享图片",
  "page_setting.edit.shareImage.help": "（5:4比率，600px)",
  "page_setting.edit.published": "公开是否",
  "page_setting.edit.published.true": "公开",
  "page_setting.edit.published.false": "非公开",
  "page_setting.edit.published.help": "非公开时，用户不能看此页面",
  "page_setting.edit.published.help1": "点击左边的临时页面就显示想要操作的页面。",
  "page_setting.edit.published.help2": "修改后点击“保存”也不会反映用户看的页面。需要点击“发布”反映用户看的页面。",

  "page_setting.edit.banner.title": "轮播图设置",
  "page_setting.edit.banner.height": "高度",
  "page_setting.edit.banner.displayType": "显示方式",
  "page_setting.edit.banner.displayType.swiper": "滑动式",
  "page_setting.edit.banner.displayType.split": "N分式",
  "page_setting.edit.banner.displayType.template": "模版",
  "page_setting.edit.banner.displayType.icon": "图标",
  "page_setting.edit.banner.displayType.split.help": "比率(例: 70,30)",
  "page_setting.edit.banner.autoPlay": "自动轮播",
  "page_setting.edit.banner.autoPlayInterval": "轮播图停止时间",
  "page_setting.edit.banner.autoPlaySpeed": "轮播间隔",

  "page_setting.edit.banner.area": "轮播图",
  "page_setting.edit.banner.area.help": "请在右边设置",
  "page_setting.edit.banner.addedSlots": "已添加的卡片",
  "page_setting.edit.banner.addSlot": "添加卡片",
  "page_setting.edit.banner.bannerMediaType": "形式",
  "page_setting.edit.banner.bannerPath": "图片或视频",
  "page_setting.edit.banner.bannerPathOrTitle": "图片或题目",
  "page_setting.edit.banner.action": "行动",
  "page_setting.edit.banner.param": "行动参数",
  "page_setting.edit.banner.openApp": "使用APP",
  "page_setting.edit.banner.modalTitle.update": "编辑卡片",
  "page_setting.edit.banner.modalTitle.add": "添加卡片",

  "page_setting.edit.media.title": "题目",
  "page_setting.edit.media.image": "图片",
  "page_setting.edit.media.video": "视频",
  "page_setting.edit.media.media": "形式",
  "page_setting.edit.media.file": "文件",
  "page_setting.edit.media.selectFile": "选择文件",
  "page_setting.edit.media.image.help": "轮播图: <br/>图片比率的宽度是375px为准设置高度（例如375*260,750*520）<br/>" +
    "宽度375px是苹果手机6/7/8为准，如果比这种手机的像素更高的机器<br/>需要上传更大的图片",
  "page_setting.edit.media.action": "点击时跳转",
  "page_setting.edit.media.action.none": "不跳转",
  "page_setting.edit.media.action.product": "跳转到商品详细页面",
  "page_setting.edit.media.action.contents": "跳转到内容页面（图片/视频）",
  "page_setting.edit.media.action.page": "跳转到活动页面",
  "page_setting.edit.media.action.brand": "跳转到指定品牌的商品目录",
  "page_setting.edit.media.action.category": "跳转到是定分类的商品目录",
  "page_setting.edit.media.action.call_center": "跳转到客服",
  "page_setting.edit.media.action.home": "首页",
  "page_setting.edit.media.action.cart": "购车",
  "page_setting.edit.media.action.singupCoupon": "注册优惠",
  "page_setting.edit.media.action.openApp": "跳转到其他APP",
  "page_setting.edit.media.action.param": "点击时处理信息",
  "page_setting.edit.media.action.help": "如果您做的行动是跳转到指定精选，那么是精选ID，体验团活动大话是体验团ID)<br/>" +
      "如果您做的行动是跳转到内容页面，那么是图片或者是视频路径)",
  "page_setting.edit.media.openApp": "跳转到其他APP",
  "page_setting.edit.media.appId": "小程序APP_ID",
  "page_setting.edit.media.app.param": "跳转目的地 ID（商品ID或者活动页面ID）",
  "page_setting.edit.media.button.product": "选择商品",
  "page_setting.edit.media.button.contents": "选择文件",
  "page_setting.edit.media.button.page": "选择页面",
  "page_setting.edit.media.button.home": "Home",
  "page_setting.edit.media.button.category": "Category",

  "page_setting.edit.basic.info": "基本信息",
  "page_setting.edit.toolbar.fixed": "位置",
  "page_setting.edit.toolbar.top": "头部",
  "page_setting.edit.toolbar.bottom": "底部",
  "page_setting.edit.toolbar.none": "自定义",
  "page_setting.edit.toolbar.showTitle": "显示题目",
  "page_setting.edit.toolbar.title": "图文导航",
  "page_setting.edit.toolbar.showIcon": "显示标志",
  "page_setting.edit.toolbar.iconSize": "标志",
  "page_setting.edit.toolbar.titleSize": "题目",
  "page_setting.edit.toolbar.fontSize": "字体大小",
  "page_setting.edit.toolbar.color": "颜色",
  "page_setting.edit.toolbar.sort": "顺序",

  "page_setting.edit.product.title": "商品目录",
  "page_setting.edit.product.slotType": "显示方式",
  "page_setting.edit.product.slotType.product_1": "1列1个",
  "page_setting.edit.product.slotType.product_2": "1列2个",
  "page_setting.edit.product.slotType.product_3": "1列3个",
  "page_setting.edit.product.slotType.slider": "左右滑动",
  "page_setting.edit.product.slotType.slider2": "两行左右滑动",
  "page_setting.edit.product.product.setting": "商品领域",
  "page_setting.edit.product.productAreaWidth": "商品领域宽度",
  "page_setting.edit.product.gap": "商品间距",
  "page_setting.edit.product.round": "圆角效果",
  "page_setting.edit.product.showGapBgColor": "表示商品隔离间颜色",
  "page_setting.edit.product.showCart": "显示购车图标",
  "page_setting.edit.product.productWidth": "商品宽度",
  "page_setting.edit.product.show.help": "预览页面上看的大小和实际页面上的大小会稍微不一样",
  "page_setting.edit.product.search": "显示商品条件",
  "page_setting.edit.product.search.categoryId": "分类",
  "page_setting.edit.product.search.brandId": "品牌",
  "page_setting.edit.product.search.tag": "标签",
  "page_setting.edit.product.search.id": "指定商品",
  "page_setting.edit.product.search.all": "全部",
  "page_setting.edit.product.select": "选择商品",
  "page_setting.edit.product.addedSearch": "已添加的条件",
  "page_setting.edit.product.sort.help": "顺序变更，拖拉拖放商品",
  "page_setting.edit.product.sort": "顺序",
  "page_setting.edit.product.numProducts": "数量",
  "page_setting.edit.product.moreProducts": "刷页面时自动显示更多商品",
  "page_setting.edit.product.moreProducts.help": "只有最后商品目录有效",
  "page_setting.edit.product.addedProducts": "已添加的商品",
  "page_setting.edit.product.message1": "不能同时使用\"指定商品\"和其他条件",
  "page_setting.edit.product.message2": "不能同时使用\"指定商品\"和其他条件",
  "page_setting.edit.product.message3": "不能同时使用\"全部商品\"和其他条件",
  "page_setting.edit.product.displayArea": "商品展示领域",
  "page_setting.edit.product.pleaseSet": "请在右边设置商品展示",
  "page_setting.edit.product.noProducts": "没有符合条件的商品",

  "page_setting.edit.image.setting": "图片设置",
  "page_setting.edit.image.setting.help": "设置的图片会显示页面，一般用于主页的介绍。",
  "page_setting.edit.image.size.help": "图片比率的宽度是375px为准设置高度。（例如375*260,750*520）",

  "page_setting.edit.text.setting": "文字设置",
  "page_setting.edit.text": "文字",
  "page_setting.edit.text.fontSize": "大小",
  "page_setting.edit.text.textAlign": "位置",
  "page_setting.edit.text.textAlign.left": "居左",
  "page_setting.edit.text.textAlign.center": "居中",
  "page_setting.edit.text.textAlign.right": "居右",
  "page_setting.edit.text.padding": "空白",
  "page_setting.edit.text.padding.help": "按上，有，下，做顺序， 用英文逗号（,）区分写：例如：10,5,10,5",
  "page_setting.edit.text.color": "字体颜色（HEX)",
  "page_setting.edit.text.backgroundColor": "背景色（HEX)",

  "page_setting.edit.line.setting": "线条设置",
  "page_setting.edit.line.color": "颜色",
  "page_setting.edit.line.height": "高度",
  "page_setting.edit.popup.setting": "弹窗设置",
  "page_setting.edit.popup.showEveryTime": "反复出现",

  "page_setting.categoryPage": "카테고리 화면 메뉴 설정",
  "page_setting.categoryPage.addItem": "항목 추가",
  "page_setting.categoryPage.iconTitle": "标签",
  "page_setting.categoryPage.iconImage": "图标",

  "product_detail_page.sort": "排列",
  "product_detail_page.page_setting": "商品详情页设置",
  "product_detail_page.setting": "设置",
  "product_detail_page.addItem": "添加项目",
  "product_detail_page.productBanner": "商品轮播图",
  "product_detail_page.noSettingItem": "没有设置项目",
  "product_detail_page.priceInfo": "商品价格信息",
  "product_detail_page.detailInfo": "商品纤细信息",
  "product_detail_page.text": "自定义",
  "product_detail_page.tag": "商品的标签",
  "product_detail_page.brand": "商品的品牌",
  "product_detail_page.optionText": "商品的规格",
  "product_detail_page.originCountry": "原产地",
  "product_detail_page.delivery": "运费",
  "product_detail_page.item": "项目",
  "product_detail_page.title": "题目",
  "product_detail_page.value": "值",
  "product_detail_page.position": "位置",
  "product_detail_page.up": "上",
  "product_detail_page.down": "下",
  "product_detail_page.contents": "内容",
  "product_detail_page.image.help": "<h4>提示: 图片宽度是固定370px，高度是按跨度比率自动调度.</h4>",
  "product_detail_page.alreadyAdded": "已经添加的项目",
  "product_detail_page.confirmDeploy": "暂存此页面后发布。确定要发布吗？",
  "product_detail_page.pageItem.type": "类型",

  "terms.pageTitle": "条款",
  "terms.termsType": "条款类型",
  "terms.termsType.user": "会员条款",
  "terms.termsType.privacy": "隐私权条款",
  "terms.published": "公开",
  "terms.not-published": "非公开",
  "terms.terms": "条款",
  "terms.useSampleTerm": "使用条款模式",
  "terms.publish.help": "点击“保存”是临时保存。点击“公开”是反映小程序。",
  "terms.selectAll": "全选",
  "terms.published.message": "已公开",
  "terms.private.message": "已处理不公开",
  "terms.setPublish.message": "确定公开条款吗？",
  "terms.setPrivate.message": "确定不公开条款吗？",
  "terms.sample.confirm1": "此条款是模板。必须修改修改使用。因条款所发生的所有问题由用户负责任。",
  "terms.sample.confirm2": "修改内容不保存（页面不保存，在书苦苦保存时适用），确定要放弃吗？",

  "address.province": "省",
  "address.city": "市",
  "address.country": "区",

  "company.company": "公司",
  "company.name": "公司名称",
  "company.companyCode": "公司编码",
  "company.companyCode.help": "简单写英文名称（例： bettercode）",
  "company.businessRegistrationNumber": "营业执照号",
  "company.phone": "电话号码",
  "company.email": "电子邮箱",
  "company.homePage": "公司官网",
  "company.creator": "注册人",
  "company.button.searchUser": "查询用户",

  "company.user.manager": "管理员",
  "company.user.invite": "邀请管理员",
  "company.user.copy.link": "复制链接",
  "company.user.share.link": "分享以下链接给想要邀请的人",
  "company.user.copied": "已复制",
  "company.user.name": "姓名",
  "company.user.editing": "编辑中",
  "company.user.userType": "用户类型",
  "company.user.userType.admin": "系统管理员",
  "company.user.userType.companyManager": "公司管理员",
  "company.user.userType.appManager": "APP管理员",
  "company.user.userType.seller": "销售员",
  "company.user.userType.supplier": "供应商",
  "company.user.userType.logistics": "物流用户",
  "company.user.userType.draft": "未审核用户",
  "company.user.basicInfo": "基本信息",
  "company.user.loginId": "登录账号",
  "company.user.changePassword": "修改密码",
  "company.user.email": "电子邮箱",
  "company.user.phone": "电话号码",
  "company.user.password": "密码",
  "company.message.sentPasswordMail": "密码设置信息已通过邮件发送。",
  "company.user.shopUserId": "销售员编码",
  "company.user.shopUser": "销售员信息",
  "company.user.notRegisteredSeller": "还没绑定销售员编码",

  "company.user.setting.role": "设置小程序权限",
  "company.add.application": "小程序管理目录",
  "company.add.application.help": "保存用户基本信息后操作",
  "company.add.select.application": "请选择小程序",

  "application.xiaochengxu": "小程序",
  "application.role": "角色",
  "application.name": "小程序名称",
  "application.appTitle": "小程序标题条",
  "application.qrCode": "小程序二维码",
  "application.companyId": "公司ID",
  "application.wxAppId": "APP ID",
  "application.wxOriginId": "Origin ID",
  "application.webMallDomain": "PC商店域名",
  "application.webMallDomain.help": "请联系系统管理员",
  "application.smsPrefix": "短信固定题目",
  "application.useLive": "直播",
  "application.group.price": "价格及支付信息",
  "application.productCurrency": "商品管理金额货币",
  "application.productCurrency.help": "企业设置的商品价格的货币。商品价格便是零售价。<br/>" +
    "<span style='color:red'>注意：如果企业设置的商品价格货币是外币的话，有汇率变动，而影响到人民币零售价的变动。</span>",
  "application.paymentCurrency": "支付金额货币",
  "application.paymentCurrency.help": "支付请求货币，从支付代理公司收到的信息",
  "application.paymentCompany": "支付公司",
  "application.paymentCompany.smatro": "SMATRO",
  "application.paymentCompany.eximbay": "EDXIMBAY",
  "application.paymentCompany.nicepay": "NICEPAY",
  "application.paymentCompany.wechatpay": "微信支付（中国主体）",
  "application.setting.key": "支付账号设置",
  "application.defaultCbtType": "默认通关模式",
  "application.group.local.logistics": "韩国物流公司信息",
  "application.local.logistics.help": "(行邮税或者9610时使用，填写只有使用优码合作企业)",
  "application.localLogisticsCompany": "韩国物流公司",
  "application.localLogisticsCompany.none": "无",
  "application.localLogisticsUserId": "物理公司账号",
  "application.group.customs": "海关账号信息",
  "application.mchCustomsNo": "海关编码",
  "application.mchCustomsNo.help": "保税区（1210）或者直购（9610）的话请联系系统客服",
  "application.declareDirectly": "订单申报方式",
  "application.declareDirectly.true": "自理申报",
  "application.declareDirectly.false": "向保税区推送订单信息",
  "application.customsEbpCode": "电商平台代码",
  "application.customsEbpCode.help": " ",
  "application.customsEbpName": "电商平台名称",
  "application.customsEbpName.help": " ",
  "application.customsEbcCode": "电商企业代码",
  "application.customsEbcName": "电商企业名称",
  "application.customsCopCode": "传输企业代码",
  "application.customsCopName": "传输企业名称",
  "application.customsDxpId": "报文传输编号",
  "application.customsCertNo": "CertNo",
  "application.group.bankAccount": "收款账号信息",
  "application.bankRecpAccount": "收款账号",
  "application.bankRecpCode": "收款企业代码",
  "application.bankRecpName": "收款企业名称",

  "application.delete.message.atLeastOne": "至少需要一个小程序。",
  "application.input.password": "登录账号密码",
  "application.key.setting": "Key设置",
  "application.wxAppSecret": "稍程序 SecretKey（密钥）",
  "application.payId": "支付账号代码",
  "application.payId.help": "从支付代理公司收到的MID或者微信支付账号里MchID",
  "application.paySecretKey": "支付账号 SecretKey（密钥）",
  "application.payV3SecretKey": "支付账号 V3 SecretKey",
  "application.certificateSerialNumber": "证书序列号",

  "user.info": "客户信息",
  "user.name": "(姓名)微信昵称",
  "user.realName": "实名",
  "user.avatarUrl": "头像",
  "user.gender": "性别",
  "user.province": "省",
  "user.city": "市",
  "user.country": "国家",
  "user.loginId": "登录账号",
  "user.email": "电子邮箱",
  "user.phone": "电话号码",
  "user.message.sentPasswordMail": "密码找回邮件发送成功， 请您注意接收邮件",

  "faq.pinToTop": "置顶",

  "company_setting.not_approved": "您的账号尚未审核",
  "company_setting.not_approved.help": "<div>企业管理员正在审核中</div>" +
    "<div>管理员审核通过以后可以使用</div>" +
    "<div>请联系企业管理员</div>",
  "company_setting.or": "或",
  "company_setting.input.companyCode": "填写企业代码",
  "company_setting.input.companyInfo": "填写企业信息",
  "company_setting.setting.guide": "设置指南",
  "company_setting.setting.guide.help": "新用户第一次登录时，填写以下两个事项之一，才能使用",
  "company_setting.setting1": "1。未登录企业信息的用户",
  "company_setting.setting1.help": "先填写企业信息后使用",
  "company_setting.setting2": "2。已登录企业的用户",
  "company_setting.setting2.help1": "请填写企业代码后使用",
  "company_setting.setting2.help2": "关于企业代码请联系企业管理员",
  "company_setting.companyCode": "企业代码",
  "company_setting.notFound": "[{companyCode}]，此代码没有企业信息。请联系企业管理员",

  "questions.question": "问题",
  "questions.title": "题目",
  "questions.contents": "内容",
  "questions.reply": "回复",

  "notice": "温馨提示",
  "notice.title": "题目",
  "notice.contents": "内容",
  "notice.files": "附件",

  "settlement.numOrders": "订单数",
  "settlement.quantity": "销售商品数量",
  "settlement.salesAmnount": "销售额",

  "reply.productId": "商品ID",
  "reply.orderId": "订单ID",
  "reply.userId": "用户ID",
  "reply.product": "商品",
  "reply.order": "订单",
  "reply.comment": "评价",
  "reply.comment.score": "评价分数",
  "reply.comment.add": "添加评价",
  "reply.replyImage": "图片",
  "reply.best": "人气评价",
  "reply.showProduct": "商品详情",
  "reply.showOrder": "查看订单",
  "reply.nickName": "昵称",

  "live.cannotUseLive": "此小程序尚未设置直播功能。 请联系系统管理员",
  "live.botton.addGoods": "添加商品",
  "live.botton.modifyGoods": "修改商品",
  "live.botton.addImage": "添加图片",
  "live.botton.previewGoods": "商品预览",

  "live.goodsImage": "商品图片",
  "live.goodsName": "商品名",
  "live.price": "价格",
  "live.link": "链接",
  "live.status": "状态",
  "live.status.0": "未审核",
  "live.status.1": "审核中",
  "live.status.2": "审核通过",
  "live.status.3": "审核失败",

  "live.button.submit": "提交审核",
  "live.button.submit.help": "注意：提交同时，进行商品审核",
  "live.modal.cover": "商品封面",
  "live.modal.cover.help": "建议尺寸：300像素 * 300像素，图片大小不得超过1M",
  "live.modal.name": "商品名称",
  "live.modal.price": "价格",
  "live.modal.path": "商品路径",
  "live.preview.help": "用微信扫码预览该商品",

  "live.room.name": "直播间名称",

  "shopComponent.title": "组件名",
};

export default messageZhCN;