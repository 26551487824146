import React, {useEffect, useState} from "react";
import {
  Button,
  Descriptions,
  Divider,
  Input,
  message, Modal,
  Select,
  Switch,
} from "antd";
import intl from "react-intl-universal";
import NoImg from "../../asset/images/no_image.png";
import {MenuOutlined, PlusCircleOutlined, EditOutlined, DeleteOutlined} from "@ant-design/icons";
import MediaLinkSetting from "./MediaLinkSetting";
import DraggableTable from "../DraggableTable";
import {sortableHandle} from "react-sortable-hoc";

const ToolbarSetting = (props) => {
  const [settingValues, setSettingValues] = useState({});
  const [editingSlot, setEditingSlot] = useState({});
  const [maxSlotId, setMaxSlotId] = useState(0);
  const [slotEditorVisible, setSlotEditorVisible] = useState(false);

  useEffect(() => {
    const settingValuesProp = {...props.settingValues};

    let maxSlotId = 1;
    if (!settingValuesProp.slots) {
      settingValuesProp.slots = [];
    } else {
      maxSlotId = 0;
      settingValuesProp.slots.forEach(slot => {
        if (slot.id > maxSlotId) {
          maxSlotId = slot.id;
        }
      });
    }
    setMaxSlotId(maxSlotId);
    setSettingValues(settingValuesProp);
  }, [props.settingValues]);

  const addSlot = () => {
    setEditingSlot({
      action: "product",
    });
    setSlotEditorVisible(true);
  };

  const handleSlotEditorModalOk = () => {
    const settingValuesState = {...settingValues};
    const editingSlotState = {...editingSlot};

    editingSlotState.bannerMediaType = "image";
    if (!editingSlotState.bannerPath && !editingSlotState.title) {
      message.error(intl.get("common.inputField",
        {name: intl.get("page_setting.edit.banner.bannerPathOrTitle")}));
      return;
    }
    if (!editingSlotState.action) {
      message.error(intl.get("common.inputField",
        {name: intl.get("page_setting.edit.banner.action")}));
      return;
    }

    if (editingSlotState.action !== "logistics_order"
      && editingSlotState.action !== "call_center"
      && editingSlotState.action !== "home"
      && editingSlotState.action !== "cart"
      && editingSlotState.action !== "none"
      && editingSlotState.action !== "singupCoupon"
      && !editingSlotState.param) {
      message.error(intl.get("common.inputField",
        {name: intl.get("page_setting.edit.banner.param")}));
      return;
    }

    if (editingSlotState.openApp && !editingSlotState.appId) {
      message.error(intl.get("common.inputField",
        {name: intl.get("page_setting.edit.banner.openApp")}));
      return;
    }

    if (editingSlotState.action === "contents" && !editingSlotState.mediaType) {
      message.success("컨텐츠의 미디어 종류를 선택하세요.");
      return;
    }

    let slots = [...settingValuesState.slots];
    if (editingSlotState.id) {
      // 수정인 경우
      slots = slots.map(slot => {
        if (slot.id === editingSlotState.id) {
          return editingSlotState;
        } else {
          return slot;
        }
      });
    } else {
      // 추가인 경우
      editingSlotState.id = maxSlotId + 1;
      if (slots) {
        slots.push(editingSlotState);
      } else {
        slots = [editingSlotState];
      }
    }
    settingValuesState.slots = slots;
    setEditingSlot(editingSlotState);
    setMaxSlotId(maxSlotId + 1);
    setSlotEditorVisible(false);
    setSettingValues(settingValuesState);

    props.applySetting(props.id, settingValuesState);
  };

  const editSlot = (id) => {
    const settingValuesState = {...settingValues};
    const editingSlot = settingValuesState.slots.find(slot => slot.id === id);
    setEditingSlot(editingSlot);
    setSlotEditorVisible(true);
  };

  const deleteSlot = (id) => {
    const settingValuesState = {...settingValues};
    const selectedSlot = settingValuesState.slots.find(slot => slot.id === id);
    if(!window.confirm(
      intl.get("common.confirm.delete",
        {name: intl.get("page_setting.edit.media.action." + selectedSlot.action)}
      ))) {
      return;
    }

    settingValuesState.slots = settingValuesState.slots.filter(slot => slot.id !== id);
    setSettingValues(settingValuesState);
    props.applySetting(props.id, settingValuesState);
  };

  const getImageName = (path) => {
    if (!path) {
      return "";
    }
    try {
      let lastIndex = path.lastIndexOf("/");
      if (lastIndex === path.length - 1) {
        path = path.substring(0, path.length - 1);
        lastIndex = path.lastIndexOf("/");
      }
      return decodeURIComponent(path.substring(lastIndex + 1));
    } catch (err) {
      return path;
    }
  };

  const slotChanged = (editingSlot) => {
    setEditingSlot(editingSlot);
  };

  const onChangeSettingValue = (fieldName, value) => {
    const settingValuesState = {...settingValues};
    settingValuesState[fieldName] = value;
    setSettingValues(settingValuesState);
    props.applySetting(props.id, settingValuesState);
  }

  const findBrandName = (brandId) => {
    const brand = props.brands.find(b => b.id === brandId);
    if (brand) {
      return brand.name;
    } else {
      return "";
    }
  };

  const findCategoryName = (categoryId, subCategoryId) => {
    const category = props.categories.find(c => c.id === categoryId);
    if (category) {
      let names = [category.name];
      const subCategory = category.subCategories.find(sc => sc.id === subCategoryId);
      if (subCategory) {
        names.push(subCategory.name);
      } else {
        names.push(subCategoryId);
      }
      return names;
    } else {
      return [categoryId, subCategoryId];
    }
  };

  const onDragEnd = (sortedSlots) => {
    const settingValuesState = {...settingValues};
    settingValuesState.slots = sortedSlots;
    let slots = [...settingValuesState.slots];

    setSettingValues(settingValuesState);
    props.applySetting(props.id, settingValuesState);
  }

  const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

  const columns = [{
    title: intl.get("page_setting.edit.toolbar.sort"),
    dataIndex: 'sort',
    width: 50,
    className: 'drag-visible',
    render: () => <DragHandle/>
  }, {
    title: intl.get("page_setting.edit.toolbar.titleSize"),
    dataIndex: "title",
    key: "title",
    width: 100,
    align: "left",
  }, {
    title: intl.get("page_setting.edit.banner.bannerPath"),
    dataIndex: "bannerPath",
    key: "bannerPath",
    width: 100,
    align: "left",
    render: (text, record) => {
      if (record.bannerPath) {
        return (<img src={record.bannerPath + (!record.bannerPath.endsWith(".svg") ? "?x-oss-process=image/resize,h_70,w_100" : "")}
                     width={48} height={48}/>)
      } else {
        return (<img src={NoImg} width={48} height={48}/>)
      }
    }
  }, {
    title: intl.get("page_setting.edit.banner.action"),
    dataIndex: "action",
    key: "action",
    align: "left",
    width: 150,
    render: (text, record) => {
      return (<div>{intl.get("page_setting.edit.media.action." + text)}</div>)
    }
  }, {
    title: intl.get("page_setting.edit.banner.param"),
    dataIndex: "param",
    key: "param",
    align: "left",
    render: (text, record) => {
      if (record.action === "brand") {
        const brandName = findBrandName(record.param);
        return (<span>{brandName ? brandName : record.param }</span>)
      }
      if (record.action === "category") {
        const categoryNames = findCategoryName(record.categoryId, record.param)
        if (categoryNames) {
          return (<span>{categoryNames[0]} > {categoryNames[1]}</span>)
        } else {
          return (<span>{record.param}</span>)
        }
      }
      if (record.action === "contents") {
        const imageName = getImageName(record.param);
        return (<a href={record.bannerPath} target="_blank">{imageName}</a>)
      }
      if (record.action === "none"
        || record.action === "cart"
        || record.action === "home"
        || record.action === "singupCoupon"
        || record.action === "call_center") {
        return (<span></span>)
      }
      if (record.paramTitle) {
        return (<span>{record.paramTitle}</span>)
      } else {
        return (<span>ID: {record.param}</span>)
      }
    }
  }, {
    title: "",
    dataIndex: "",
    key: "",
    align: "center",
    width: 80,
    render: (text, record) => {
      return (
        <div>
          <EditOutlined style={{cursor: 'pointer'}} onClick={() => {editSlot(record.id);}}/>
          <Divider type="vertical" />
          <DeleteOutlined style={{cursor: 'pointer'}} onClick={() => {deleteSlot(record.id);}}/>
        </div>
      )
    }
  }]

  return (
    <div>
      <Descriptions title={intl.get("page_setting.edit.toolbar.title")}
                    bordered
                    layout="vertical"
                    size={"small"} column={1}>
        <Descriptions.Item label={intl.get("page_setting.edit.basic.info")}>
          <div style={{display: "flex", alignItems: "center", gap: 20}}>
            <div style={{display: "flex", alignItems: "center", gap: 5}}>
              <div>{intl.get("page_setting.edit.backgroundColor")}: </div>
              <input value={settingValues.backgroundColor || "#ffffff"}
                     type="color"
                     onChange={(e) => onChangeSettingValue('backgroundColor', e.target.value)}/>
              <span style={{marginLeft: 5}}>{settingValues.backgroundColor}</span>
            </div>
            <div style={{display: "flex", alignItems: "center", gap: 5}}>
              <div>{intl.get("page_design.height")}: </div>
              <Input style={{width: 70}}
                     value={settingValues.height}
                     suffix={"px"}
                     onChange={(e) => onChangeSettingValue('height', e.target.value)}/>
            </div>
            {props.appPage?.pageType !== "index" && (
              <div style={{display: "flex", alignItems: "center", gap: 5}}>
                <div>{intl.get("page_setting.edit.toolbar.fixed")}: </div>
                <Select style={{width: 150}}
                        value={settingValues.fixed}
                        onChange={(value) => onChangeSettingValue("fixed", value)}>
                  <Select.Option key="none" value={"none"}>{intl.get("page_setting.edit.toolbar.none")}</Select.Option>
                  <Select.Option key="top" value={"top"}>{intl.get("page_setting.edit.toolbar.top")}</Select.Option>
                    <Select.Option key="bottom" value={"bottom"}>{intl.get("page_setting.edit.toolbar.bottom")}</Select.Option>
                </Select>
              </div>
            )}
            <div style={{display: "flex", alignItems: "center", gap: 5}}>
              <div>{intl.get("page_setting.edit.text.padding")}: </div>
              <Input style={{width: 120}}
                     value={settingValues.padding}
                     onChange={(e) => onChangeSettingValue('padding', e.target.value)}/>
            </div>
          </div>
          <div style={{marginTop:5, color: '#b8b8b8', fontSize: 13}}>{intl.get("page_setting.edit.text.padding")}: {intl.get("page_setting.edit.text.padding.help")}</div>
        </Descriptions.Item>
        <Descriptions.Item label={intl.get("page_setting.edit.toolbar.iconSize")}>
          <div style={{display: "flex", alignItems: "center", gap: 20}}>
            <div style={{display: "flex", alignItems: "center", gap: 5}}>
              <div>{intl.get("page_setting.edit.toolbar.showIcon")}:</div>
              <Switch checked={settingValues.showIcon} onClick={(checked) => onChangeSettingValue("showIcon", checked)} />
            </div>
            <div style={{display: "flex", alignItems: "center", gap: 5}}>
              <div>{intl.get("page_design.width")}: </div>
              <Input style={{width: 100}}
                     suffix={"px"}
                     value={settingValues.iconWidth}
                               onChange={(e) => onChangeSettingValue('iconWidth', e.target.value, )}/>
            </div>
            <div style={{display: "flex", alignItems: "center", gap: 5}}>
              <div>{intl.get("page_design.height")}: </div>
              <Input style={{width: 100}}
                     suffix={"px"}
                     value={settingValues.iconHeight}
                     onChange={(e) => onChangeSettingValue('iconHeight', e.target.value)}/>
            </div>
          </div>
        </Descriptions.Item>
        <Descriptions.Item label={intl.get("page_setting.edit.toolbar.titleSize")}>
          <div style={{display: "flex", alignItems: "center", gap: 20}}>
            <div style={{display: "flex", alignItems: "center", gap: 5}}>
              <div>{intl.get("page_setting.edit.toolbar.showTitle")}:</div>
              <Switch checked={settingValues.showTitle} onClick={(checked) => onChangeSettingValue("showTitle", checked)} />
            </div>
            <div style={{display: "flex", alignItems: "center", gap: 5}}>
              <div>{intl.get("page_setting.edit.toolbar.fontSize")}: </div>
              <Input style={{width: 100}}
                     suffix={"px"}
                     value={settingValues.titleFontSize}
                     onChange={(e) => onChangeSettingValue('titleFontSize', e.target.value)}/>
            </div>
            <div style={{display: "flex", alignItems: "center", gap: 5}}>
              <div>{intl.get("page_setting.edit.toolbar.color")}: </div>
              <input value={settingValues.titleColor || "#313131"}
                     type="color"
                     onChange={(e) => onChangeSettingValue('titleColor', e.target.value)}/>
            </div>
            <div style={{display: "flex", alignItems: "center", gap: 5}}>
              <div>{intl.get("application.design.fontWeight")}:</div>
              <Switch checked={settingValues.titleFontWeight} onClick={(checked) => onChangeSettingValue("titleFontWeight", checked)} />
            </div>
          </div>
        </Descriptions.Item>
        <Descriptions.Item label={intl.get("page_setting.edit.banner.addedSlots")}>
          <div style={{width: "100%", textAlign: "right"}}>
            <Button type="default" size="small" icon={<PlusCircleOutlined />} onClick={addSlot}>{intl.get("page_setting.edit.banner.addSlot")}</Button>
          </div>
          <DraggableTable
            dataSource={settingValues?.slots ? settingValues?.slots : [] }
            columns={columns}
            rowKey={"id"}
            onSortEnd={onDragEnd}
          />
        </Descriptions.Item>
      </Descriptions>
      <Modal
        title={(editingSlot.id || editingSlot.id === 0)
          ? intl.get("page_setting.edit.banner.modalTitle.update")
          : intl.get("page_setting.edit.banner.modalTitle.add")}
        visible={slotEditorVisible}
        onOk={handleSlotEditorModalOk}
        onCancel={() => setSlotEditorVisible(false)}
        width={800}
      >
        <MediaLinkSetting
          settingValues={settingValues}
          slot={editingSlot}
          id={editingSlot.id}
          imageOnly={true}
          brands={props.brands}
          categories={props.categories}
          showTitle={true}
          slotChanged={slotChanged}>
        </MediaLinkSetting>
      </Modal>
    </div>
  )
};

export default ToolbarSetting;