import React from "react";
import {MemberContext} from "../auth/member.context";
import Dashboard from "./dashboard/Dashboard";
import OrderUploadHome from "./order/upload/OrderUploadHome";
import SupplierHome from "./supplier/SupplierHome";


const Home = () => {
  if (MemberContext.memberInformation?.userType === "logistics") {
    return (<OrderUploadHome/>)
  } else if (MemberContext.memberInformation?.userType === "supplier") {
      return (<SupplierHome/>)
  } else {
    return (<Dashboard/>)
  }
};

export default Home;
