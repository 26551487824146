import React, {useEffect, useState} from 'react';
import {Layout, Divider} from "antd";
import Util from "../../util/Util";
import Cookies from "universal-cookie";
import {useLayoutState} from "./AppLayoutContext";
const cookies = new Cookies();

const Footer = (props) => {
  const layoutState = useLayoutState();
  const [domain, setDomain] = useState("");
  const [footerLabel, setFooterLabel] = useState("");

  useEffect(() => {
    const locale = cookies.get('locale') ? cookies.get('locale') : "ko-KR";
    setDomain(Util.getServiceDomainName());
    if (layoutState.application?.adminWebSetting?.copyright) {
      setFooterLabel(layoutState.application.adminWebSetting.copyright);
    } else {
      if (props.serviceSetting?.company?.footer) {
        setFooterLabel(props.serviceSetting?.company.footer);
      } else {
        const settingValue = props.serviceSetting.systemSettings.find((s) => s.name === "admin.footer." + locale);
        if (settingValue) {
          setFooterLabel(settingValue.value);
        } else {
          setFooterLabel("YOUMA");
        }
      }
    }
  }, [layoutState.application]);

  return (
    <Layout.Footer style={{textAlign: "center"}}>
      <div style={{display: "flex", justifyContent: "center"}}>
        <div>{footerLabel}</div>
        {domain === "youma.kr" && (
          <>
            <Divider type={"vertical"}/>
            <div><a href="https://www.youma.kr/terms_supplier.html" target="_blank">이용약관</a></div>
            <Divider type={"vertical"}/>
            <div><a href="https://www.youma.kr/privacy.html" target="_blank">개인정보처리방침</a></div>
          </>
        )}
      </div>
    </Layout.Footer>
  )
}

export default Footer;
