const CustomsCodeUnit = [
  {id: "7", value: "个"},
  {id: "11", value: "件"},
  {id: "20", value: "片"},
  {id: "140", value: "盒"},
  {id: "142", value: "瓶"},
  {id: "35", value: "千克"},
  {id: "36", value: "克"},
  {id: "10", value: "张"},
  {id: "12", value: "支"},
  {id: "1", value: "台"},
  {id: "2", value: "座"},
  {id: "3", value: "辆"},
  {id: "4", value: "艘"},
  {id: "5", value: "架"},
  {id: "6", value: "套"},
  {id: "8", value: "只"},
  {id: "9", value: "头"},
  {id: "13", value: "枝"},
  {id: "14", value: "根"},
  {id: "15", value: "条"},
  {id: "16", value: "把"},
  {id: "17", value: "块"},
  {id: "18", value: "卷"},
  {id: "19", value: "副"},
  {id: "21", value: "组"},
  {id: "22", value: "份"},
  {id: "23", value: "幅"},
  {id: "25", value: "双"},
  {id: "26", value: "对"},
  {id: "27", value: "棵"},
  {id: "28", value: "株"},
  {id: "29", value: "井"},
  {id: "30", value: "米"},
  {id: "31", value: "盘"},
  {id: "32", value: "平方米"},
  {id: "33", value: "立方木"},
  {id: "34", value: "筒"},
  {id: "37", value: "盆"},
  {id: "38", value: "万个"},
  {id: "39", value: "具"},
  {id: "40", value: "百副"},
  {id: "41", value: "百支"},
  {id: "42", value: "百把"},
  {id: "43", value: "百个"},
  {id: "44", value: "百片"},
  {id: "45", value: "刀"},
  {id: "46", value: "疋"},
  {id: "47", value: "公担"},
  {id: "48", value: "扇"},
  {id: "49", value: "百枝"},
  {id: "50", value: "千只"},
  {id: "51", value: "千块"},
  {id: "52", value: "千盒"},
  {id: "53", value: "千枝"},
  {id: "54", value: "千个"},
  {id: "55", value: "亿支"},
  {id: "56", value: "亿个"},
  {id: "57", value: "万套"},
  {id: "58", value: "千张"},
  {id: "59", value: "万张"},
  {id: "60", value: "千伏安"},
  {id: "61", value: "千瓦"},
  {id: "62", value: "千瓦时"},
  {id: "63", value: "千升"},
  {id: "67", value: "英尺"},
  {id: "70", value: "吨"},
  {id: "71", value: "长吨"},
  {id: "72", value: "短吨"},
  {id: "73", value: "司马担"},
  {id: "74", value: "司马斤"},
  {id: "75", value: "斤"},
  {id: "76", value: "磅"},
  {id: "77", value: "担"},
  {id: "78", value: "英担"},
  {id: "79", value: "短担"},
  {id: "80", value: "两"},
  {id: "81", value: "市担"},
  {id: "83", value: "盎司"},
  {id: "84", value: "克拉"},
  {id: "85", value: "市尺"},
  {id: "86", value: "码"},
  {id: "88", value: "英寸"},
  {id: "89", value: "寸"},
  {id: "95", value: "升"},
  {id: "96", value: "毫升"},
  {id: "97", value: "英加仑"},
  {id: "98", value: "美加仑"},
  {id: "99", value: "立方英尺"},
  {id: "101", value: "立方尺"},
  {id: "110", value: "平方码"},
  {id: "111", value: "平方英尺"},
  {id: "112", value: "平方尺"},
  {id: "115", value: "英制马力"},
  {id: "116", value: "公制马力"},
  {id: "118", value: "令"},
  {id: "120", value: "箱"},
  {id: "121", value: "批"},
  {id: "122", value: "罐"},
  {id: "123", value: "桶"},
  {id: "124", value: "扎"},
  {id: "125", value: "包"},
  {id: "126", value: "箩"},
  {id: "127", value: "打"},
  {id: "128", value: "筐"},
  {id: "129", value: "罗"},
  {id: "130", value: "匹"},
  {id: "131", value: "册"},
  {id: "132", value: "本"},
  {id: "133", value: "发"},
  {id: "134", value: "枚"},
  {id: "135", value: "捆"},
  {id: "136", value: "袋"},
  {id: "139", value: "粒"},
  {id: "141", value: "合"},
  {id: "143", value: "千支"},
  {id: "144", value: "万双"},
  {id: "145", value: "万粒"},
  {id: "146", value: "千粒"},
  {id: "147", value: "千米"},
  {id: "148", value: "千英尺"},
  {id: "149", value: "百万贝可"},
  {id: "163", value: "部"},
];

function getCustomsCodeText(id) {
  const code = CustomsCodeUnit.find(c => c.id === id);
  if (code) {
    return code.value;
  } else {
    return "";
  }
}

export {CustomsCodeUnit, getCustomsCodeText}