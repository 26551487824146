import React, {useState, useEffect} from "react";
import {
  Button,
  Descriptions,
  InputNumber,
  Input,
  Radio,
  Select,
  AutoComplete, Tag,
  Space,
  message,
  Table, Switch,
  Checkbox
} from "antd";
import {MenuOutlined} from "@ant-design/icons";
import { sortableHandle } from 'react-sortable-hoc';
import intl from "react-intl-universal";
import ProductApi from "../product/ProductApi";
import ProductSearchPopup from "../product/ProductSearchPopup";
import NoImage from "../../asset/images/no_image.png";
import DraggableTable from "../DraggableTable";
import Util from "../../util/Util";
import { sortBy, indexOf } from "lodash";
import FileListPopup from "../file_manager/FileListPopup";

const { Search } = Input;
const { Option } = AutoComplete;

const ProductSetting = (props) => {
  const [settingValues, setSettingValues] = useState({});

  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState("");
  const [selectedBrandId, setSelectedBrandId] = useState("");

  const [tags, setTags] = useState([]);
  const [filteredTags, setFilteredTags] = useState([]);
  const [tagKeyword, setTagKeyword] = useState("");

  const [productFilterType, setProductFilterType] = useState("categoryId");
  const [productSearchVisible, setProductSearchVisible] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showAllProducts, setShowAllProducts] = useState(false);
  const [visibleFileModal, setVisibleFileModal] = useState(false);

  useEffect(() => {
    const settingValuesProp = {...props.settingValues};

    if (settingValuesProp.products) {
      settingValuesProp.products = settingValuesProp.products.map(p => {
        // Drag Component에서 String Type Key를 요구.
        p.idStr = "" + p.id;
        return p;
      });
    }
    if (settingValuesProp.productFilters?.length > 0 && settingValuesProp.productFilters[0].type === "id") {
      setProductFilterType("id");
    }

    setSettingValues(settingValuesProp);
    loadFilterProducts(settingValuesProp, showAllProducts);
  }, [props.settingValues]);

  useEffect(() => {
    loadTags();
  }, []);

  const loadTags = () => {
    ProductApi.getAllTags().then(res => {
      setTags(res.data);
    });
  };

  const onSearchTag = (searchText) => {
    const tagsState = [...tags];
    const filteredTags = tagsState.filter(t => t.value.startsWith(searchText));
    setFilteredTags(filteredTags);
  };

  const onCategorySelect = (value) => {
    setSelectedCategoryId(value);
    setSelectedSubCategoryId("");
  };

  const deleteProduct = (productId) => {
    const filteredProductsState = [...filteredProducts];
    const newProductList = filteredProductsState.filter(p => p.id !== productId);
    setFilteredProducts(newProductList);

    const settingValuesState = {...settingValues};
    const idFilter = settingValuesState.productFilters.find(f => f.type === "id")
    idFilter.value = newProductList.map(p => p.id).join(",");
    settingValuesState.productFilters = settingValuesState.productFilters.map(f => {
      if (f.type === "id") {
        return idFilter;
      } else {
        return f;
      }
    });
    setSettingValues(settingValuesState);
    props.applySetting(props.id, settingValuesState);
  };

  //////////////////////////////////////////////////////////////
  // 특정 ProductID를 선택한 경우 settingValues의 productFilters에
  // ids 타입의 filter를 추가한다.
  // ids 타입의 filter는 하나에 제품 ID가 "id1,id2" 형태로 관리하기 때문에
  // 제품 목록 컨포넌트당 0 또는 1개만 존재한다.
  // 따라서 아래 로직은 기존 filter에 새로 추가된 ID를 추가하는 방식으로 구현되어 있다.
  const okProductSearch = (products) => {
    if (products.length === 0) {
      setProductSearchVisible(false);
      return;
    }

    // 이미 추가된 제품을 찾아서 없는 제품만 다시 추가한다.
    const settingValuesState = {...settingValues};
    let productIdFilter
    if (settingValuesState.productFilters) {
      productIdFilter = settingValuesState.productFilters.find(f => f.type === "id");
    }
    if (!productIdFilter) {
      productIdFilter = {type: "id", value: ""};
    }
    let filterProductIds = productIdFilter ? productIdFilter.value.split(",") : [];

    let filteredProductsState = [...filteredProducts];

    products.forEach(selectedProduct => {
      if (!filteredProductsState.find(p => selectedProduct.id === p.id)) {
        filteredProductsState.push(selectedProduct);
      }

      if (!filterProductIds.find(id => id === selectedProduct.id)) {
        filterProductIds.push(selectedProduct.id);
      }
    });

    // Drag 처리를 위해 index 컬럼이 필요
    filteredProductsState = filteredProductsState.map((p, index) => {
      p.index = index;
      return p;
    })

    productIdFilter.value = filterProductIds.join(",");
    productIdFilter.desc = "";
    settingValuesState.productFilters = [productIdFilter];

    setSettingValues(settingValuesState);
    setFilteredProducts(filteredProductsState);
    setProductSearchVisible(false);
    props.applySetting(props.id, settingValuesState);
  };

  const onProductDragEnd = (sortedProducts) => {
    setFilteredProducts(sortedProducts);

    const settingValuesState = JSON.parse(JSON.stringify(settingValues));
    const idFilter = settingValuesState.productFilters.find(f => f.type === "id")
    idFilter.value = sortedProducts.map(p => p.id).join(",");
    settingValuesState.productFilters = settingValuesState.productFilters.map(f => {
      if (f.type === "id") {
        return idFilter;
      } else {
        return f;
      }
    });
    setSettingValues(settingValuesState);
    props.applySetting(props.id, settingValuesState);
  }

  const onTitleChange = (e) => {
    const settingValuesState = JSON.parse(JSON.stringify(settingValues));
    settingValuesState.title = e.target.value;
    setSettingValues(settingValuesState);
    props.applySetting(props.id, settingValuesState);
  };

  const onNumProductsChange = (value) => {
    const settingValuesState = JSON.parse(JSON.stringify(settingValues));
    settingValuesState.numProducts = value;
    setSettingValues(settingValuesState);
    props.applySetting(props.id, settingValuesState);
    loadFilterProducts(settingValuesState, showAllProducts);
  };

  const onSortChange = (e) => {
    const settingValuesState = JSON.parse(JSON.stringify(settingValues));
    settingValuesState.sort = e.target.value;
    setSettingValues(settingValuesState);
    props.applySetting(props.id, settingValuesState);

    loadFilterProducts(settingValuesState, showAllProducts);
  };

  const onShowCartChange = (checked) => {
    const settingValuesState = JSON.parse(JSON.stringify(settingValues));
    settingValuesState.showCart = checked;
    setSettingValues(settingValuesState);
    props.applySetting(props.id, settingValuesState);
  }

  const onMoreProductChange = (checked) => {
    const settingValuesState = JSON.parse(JSON.stringify(settingValues));
    settingValuesState.moreProducts = checked;
    setSettingValues(settingValuesState);
    props.applySetting(props.id, settingValuesState);
  }

  const onSlotTypeChange = (e) => {
    const settingValuesState = JSON.parse(JSON.stringify(settingValues));
    settingValuesState.slotType = e.target.value;
    if (settingValuesState.slotType === "product_2") {
      settingValuesState.productWidth = 145;
      settingValuesState.productGap = 18;
    } else if (settingValuesState.slotType === "product_3") {
      settingValuesState.productWidth = 96;
      settingValuesState.productGap = 8;
    }
    setSettingValues(settingValuesState);
    props.applySetting(props.id, settingValuesState);
    loadFilterProducts(settingValuesState, showAllProducts);
  };

  const loadFilterProducts = (settingValuesState, showAll) => {
    if (!settingValuesState.productFilters || settingValuesState.productFilters.length === 0) {
      setFilteredProducts([]);
      return;
    }

    const categoryIds = [];
    const tags = [];
    const brandIds = [];
    let ids = [];
    let numProducts = settingValuesState.numProducts;
    settingValuesState.productFilters.forEach(filter => {
      if (filter.type === "categoryId") {
        categoryIds.push(filter.value);
      } else if (filter.type === "brandId") {
        brandIds.push(filter.value);
      } else if (filter.type === "tag") {
        tags.push(filter.value);
      } else if (filter.type === "id") {
        ids = filter.value.split(",");
        if (canSelectMoreProduct() && showAll) {
          numProducts = 500;
        }
      }
    });
    const searchParam = {
      categoryId: categoryIds,
      tag: tags,
      brandId: brandIds,
      id: ids,
      sort: settingValuesState.sort,
      page: 1,
      pageSize: numProducts,
      projection: "app"
    };
    ProductApi.getProducts(searchParam).then(res => {
      const pageResult = res.data;

      const products = pageResult.data;
      if (ids) {
        // id로 처리한 경우 id를 순서를 맞춘다.
        // const sortedProducts = [];
        const idsInt = ids.map(id => parseInt(id));
        const sortedProducts = sortBy(products, (obj) => {
          return indexOf(idsInt, obj.id);
        });
        setFilteredProducts(sortedProducts);
      } else {
        setFilteredProducts(products);
      }
    });
  };

  const onChangeSettingValue = (fieldName, value) => {
    const settingValuesState = JSON.parse(JSON.stringify(settingValues));
    settingValuesState[fieldName] = value;
    setSettingValues(settingValuesState);
    props.applySetting(props.id, settingValuesState);
  }

  const onWidthChange = (value) => {
    const settingValuesState = JSON.parse(JSON.stringify(settingValues));
    settingValuesState.productWidth = value;
    setSettingValues(settingValuesState);
    props.applySetting(props.id, settingValuesState);
  };

  const showProductSearch = () => {
    if (!settingValues.productFilters || settingValues.productFilters.length === 0) {
      setProductSearchVisible(true);
      return;
    }
    if (settingValues.productFilters.filter(f => f.type !== "id").length > 0) {
      message.error(intl.get("page_setting.edit.product.message1"));
      return;
    }
    setProductSearchVisible(true);
  }

  const addProductFilter = () => {
    const settingValuesState = JSON.parse(JSON.stringify(settingValues));

    if ((productFilterType === "all" || productFilterType === "id")) {
      if (settingValues.productFilters && settingValues.productFilters.length > 0) {
        message.error(intl.get("page_setting.edit.product.message3"));
        return;
      }
    } else {
      if (settingValues.productFilters && settingValues.productFilters.filter(f => f.type === "all" || f.type === "id").length > 0) {
        message.error(intl.get("page_setting.edit.product.message1"));
        return;
      }
    }

    let filterItem = {
      type: productFilterType,
    }

    if (productFilterType === "categoryId") {
      if (!selectedCategoryId && !selectedSubCategoryId) {
        message.error(intl.get("common.inputField", {name: intl.get("products.category")}));
        return;
      }
      const selectedCategory = props.categories.find(c => c.id === selectedCategoryId);
      const selectedSubCategory = selectedSubCategoryId ? selectedCategory.subCategories.find(sc => sc.id === selectedSubCategoryId) : {};

      filterItem.value = selectedSubCategoryId ? selectedSubCategoryId : selectedCategoryId ;
      filterItem.desc = selectedSubCategoryId ? selectedCategory.name + ":" + selectedSubCategory.name : selectedCategory.name;
    } else if (productFilterType === "brandId") {
      if (!selectedBrandId) {
        message.error(intl.get("common.inputField", {name: intl.get("products.brand")}));
        return;
      }
      filterItem.value = selectedBrandId;
      filterItem.desc = props.brands.find(b => b.id === selectedBrandId).name;
    } else if (productFilterType === "tag") {
      if (!tagKeyword) {
        message.error(intl.get("common.inputField", {name: intl.get("products.tag")}));
        return;
      }
      filterItem.value = tagKeyword;
      filterItem.desc = tagKeyword;
    }
    if (settingValuesState.productFilters) {
      settingValuesState.productFilters.push(filterItem);
    } else {
      settingValuesState.productFilters = [filterItem];
    }

    // 초기화
    setSelectedCategoryId("");
    setSelectedSubCategoryId("");
    setSelectedBrandId("");
    setTagKeyword("");
    setFilteredTags([]);

    setSettingValues(settingValuesState);
    props.applySetting(props.id, settingValuesState);

    loadFilterProducts(settingValuesState, showAllProducts);
  };

  const removeProductFilter = (index) => {
    const settingValuesState = JSON.parse(JSON.stringify(settingValues));
    settingValuesState.productFilters.splice(index, 1);
    setSettingValues(settingValuesState);
    props.applySetting(props.id, settingValuesState);

    loadFilterProducts(settingValuesState, showAllProducts);
  };

  const getFilterColor = (filterType) => {
    if (filterType === "categoryId") return "green";
    if (filterType === "brandId") return "cyan";
    if (filterType === "tag") return "blue";
    if (filterType === "id") return "geekblue";
    if (filterType === "all") return "geekblue";
    return "";
  }

  const okFileModal = (modalName, filePathList) => {
    if (filePathList.length === 0) {
      return;
    }
    const settingValuesState = {...settingValues};
    settingValuesState.backgroundImage = filePathList[0];
    setSettingValues(settingValuesState);
    setVisibleFileModal(false);
    props.applySetting(props.id, settingValuesState);
  };

  const onImageChange = (e) => {
    const settingValuesState = {...settingValues};
    settingValuesState.backgroundImage = e.target.value;
    setSettingValues(settingValuesState);
    props.applySetting(props.id, settingValuesState);
  };

  const canSelectMoreProduct = () => {
    return props.isLast && settingValues.slotType !== "slider";
  }

  const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
  const showDragTable = settingValues.productFilters && settingValues.productFilters.find(f => f.type === "id");

  let columns = [];
  if (showDragTable) {
    columns.push({
      title: 'Sort',
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle/>
    });
  }

  columns = columns.concat([{
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      className: 'drag-visible',
    }, {
      title: intl.get("products.images"),
      dataIndex: 'images',
      key: 'images',
      align: 'center',
      render: (text, record) => {
        const image = record.images.length > 0 ? record.images[0] + "?x-oss-process=image/resize,h_48,w_48" : NoImage;
        return  (<span><img src={image} style={{width: "48px", height: "48px"}}/></span>);
      }
    }, {
      title: intl.get("products.name"),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
          <div>
            <div>{record.name}</div>
            { (record.name !== record.nameKr) && (<div>{record.nameKr}</div>) }
          </div>
      )
    }, {
      title: intl.get("products.salesPrice"),
      dataIndex: 'salesPrice',
      key: 'salesPrice',
      align: "right",
      render: (text, record) => (<div>{Util.currencyFormat(record.salesPrice.secondaryPrice, props.application.userPayCurrency)}</div>)
    }]
  );

  if (showDragTable) {
    columns.push({
      title: '',
      dataIndex: 'id',
      key: 'action',
      render: (text, record) => (
        <Button size="small" onClick={() => deleteProduct(record.id)}>{intl.get("common.button.delete")}</Button>
      )
    });
  }

  const subCategories = selectedCategoryId ? props.categories.find(c => c.id === selectedCategoryId).subCategories : [];
  const showCartDisabled = settingValues.slotType !== "product_1" && settingValues.slotType !== "product_2" && settingValues.slotType !== "slider_2";
  const canMoreProducts = canSelectMoreProduct();

  return (
      <div>
        <Descriptions title={intl.get("page_setting.edit.product.title")} bordered size={"small"} column={1}>
          <Descriptions.Item label="Title">
            <Input disabled={settingValues.slotType === 'slider_2'} value={settingValues.title} onChange={onTitleChange}/>
          </Descriptions.Item>
          <Descriptions.Item label={intl.get("page_setting.edit.text.padding")}>
            <Input style={{width: 120}}
                   value={settingValues.padding}
                   // disabled={settingValues.slotType === 'slider_2'}
                   onChange={(e) => onChangeSettingValue('padding', e.target.value)}/>
            <div style={{marginTop:5, color: '#b8b8b8', fontSize: 13}}>{intl.get("page_setting.edit.text.padding")}: {intl.get("page_setting.edit.text.padding.help")}</div>
          </Descriptions.Item>
          <Descriptions.Item label={intl.get("page_setting.edit.backgroundColor")}>
            <div style={{display: "flex", alignItems: "center", gap: 5}}>
              <input value={settingValues.backgroundColor || "#ffffff"}
                     type="color"
                     onChange={(e) => onChangeSettingValue('backgroundColor', e.target.value)}/>
              <span style={{marginLeft: 5}}>{settingValues.backgroundColor}</span>
            </div>
          </Descriptions.Item>
          <Descriptions.Item label={intl.get("page_setting.edit.backgroundImage")}>
            <Search
              allowClear
              enterButton={intl.get("page_setting.edit.media.button.contents")}
              value={settingValues.backgroundImage || ""}
              onChange={onImageChange}
              onSearch={() => setVisibleFileModal(true)}
            />
          </Descriptions.Item>
          <Descriptions.Item label={intl.get("page_setting.edit.product.slotType")}>
            <Radio.Group value={settingValues.slotType} onChange={onSlotTypeChange}>
              <Radio value={"product_1"}>{intl.get("page_setting.edit.product.slotType.product_1")}</Radio>
              <Radio value={"product_2"}>{intl.get("page_setting.edit.product.slotType.product_2")}</Radio>
              <Radio value={"product_3"}>{intl.get("page_setting.edit.product.slotType.product_3")}</Radio>
              <Radio value={"slider"}>{intl.get("page_setting.edit.product.slotType.slider")}</Radio>
              <Radio value={"slider_2"}>{intl.get("page_setting.edit.product.slotType.slider2")}</Radio>
            </Radio.Group>
          </Descriptions.Item>
          <Descriptions.Item label={intl.get("page_setting.edit.product.setting")}>
            <div style={{display: "flex", alignItems: "center", gap: 20}}>
              <div style={{display: "flex", alignItems: "center", gap: 5}}>
                <div>{intl.get("page_setting.edit.product.productAreaWidth")}:</div>
                <Input style={{width: 100}}
                       suffix={"px"}
                       value={settingValues.productWidth}
                       disabled={settingValues.slotType !== 'product_2' && settingValues.slotType !== 'product_3'}
                       onChange={(e) => onChangeSettingValue('productWidth', e.target.value, )}/>
              </div>
              <div style={{display: "flex", alignItems: "center", gap: 5}}>
                <div>{intl.get("page_setting.edit.product.gap")}:</div>
                <Input style={{width: 100}}
                       suffix={"px"}
                       value={settingValues.productGap}
                       disabled={settingValues.slotType !== 'product_2' && settingValues.slotType !== 'product_3'}
                       onChange={(e) => onChangeSettingValue('productGap', e.target.value, )}/>
              </div>
            </div>
            <div style={{display: "flex", alignItems: "center", gap: 20, marginTop: 10}}>
              <div style={{display: "flex", alignItems: "center", gap: 5}}>
                <div>{intl.get("page_setting.edit.product.round")}:</div>
                <Switch checked={settingValues.round}
                        disabled={settingValues.slotType !== 'product_2' && settingValues.slotType !== 'product_3' && settingValues.slotType !== 'slider_2'}
                        onClick={(checked) => onChangeSettingValue("round", checked)} />
              </div>
              <div style={{display: "flex", alignItems: "center", gap: 5}}>
                <div>{intl.get("page_setting.edit.product.showGapBgColor")}:</div>
                <Switch checked={settingValues.showGapBgColor}
                        disabled={settingValues.slotType !== 'product_2' && settingValues.slotType !== 'product_3'}
                        onClick={(checked) => onChangeSettingValue("showGapBgColor", checked)} />
              </div>
              <div style={{display: "flex", alignItems: "center", gap: 5}}>
                <div>{intl.get("page_setting.edit.product.showCart")}: </div>
                <Switch disabled={showCartDisabled} checked={settingValues.showCart} onClick={onShowCartChange}/>
              </div>
            </div>
          </Descriptions.Item>
          {settingValues.slotType === "slider" && (
              <Descriptions.Item label={intl.get("page_setting.edit.product.productWidth")}>
                <InputNumber value={settingValues.productWidth} min={1} max={1000} onChange={onWidthChange}/>
                <span style={{marginLeft: 10}}>{intl.get("page_setting.edit.product.show.help")}</span>
              </Descriptions.Item>
          )}
          <Descriptions.Item label={intl.get("page_setting.edit.product.search")}>
            <Space>
              <Select style={{width: 150}} value={productFilterType} onChange={(value) => setProductFilterType(value)}>
                <Select.Option value={"categoryId"}>{intl.get("page_setting.edit.product.search.categoryId")}</Select.Option>
                <Select.Option value={"brandId"}>{intl.get("page_setting.edit.product.search.brandId")}</Select.Option>
                <Select.Option value={"tag"}>{intl.get("page_setting.edit.product.search.tag")}</Select.Option>
                <Select.Option value={"id"}>{intl.get("page_setting.edit.product.search.id")}</Select.Option>
                <Select.Option value={"all"}>{intl.get("page_setting.edit.product.search.all")}</Select.Option>
              </Select>
              {productFilterType === "categoryId" && (
                <>
                  <div style={{width: 150}}>
                    <Select value={selectedCategoryId ? selectedCategoryId : ""}
                            onChange={onCategorySelect}
                            style={{width: "100%"}}>
                      {
                        props.categories.map(eachCategory => {
                          return (<Select.Option key={eachCategory.id} value={eachCategory.id}>{eachCategory.name}</Select.Option>)
                        })
                      }
                    </Select>
                  </div>
                  <div style={{width: 150}}>
                    <Select
                        value={selectedSubCategoryId ? selectedSubCategoryId : ""}
                        onChange={(value) => setSelectedSubCategoryId(value)}
                        style={{width: "100%"}}>
                      { subCategories && subCategories.map(eachCategory => {
                        return (<Select.Option key={eachCategory.id}
                                               value={eachCategory.id}>{eachCategory.name}</Select.Option>)
                      })
                      }
                    </Select>
                  </div>
                </>
              )}
              { productFilterType === "brandId" && (
                <Select value={selectedBrandId} onChange={(value) => setSelectedBrandId(value)} style={{width: 400}}>
                  {
                    props.brands.map(eachBrand => {
                      return (<Select.Option key={eachBrand.id} value={eachBrand.id}>{eachBrand.name}</Select.Option>)
                    })
                  }
                </Select>
              )}
              { productFilterType === "tag" && (
                  <AutoComplete style={{ width: 150 }} value={tagKeyword} onSearch={onSearchTag}
                                onChange={(value) => setTagKeyword(value)} placeholder="Tag">
                    {filteredTags.map((tag, index) => (
                        <Option key={index} value={tag.value} onChange={(value) => {setTagKeyword(value)}}>{tag.value}</Option>
                    ))}
                  </AutoComplete>
              )}
              { productFilterType !== "id" && (
                <Button onClick={addProductFilter}>{intl.get("common.button.add")}</Button>
              )}
              { productFilterType === "id" && (
                  <Button onClick={showProductSearch}>{intl.get("page_setting.edit.product.select")}</Button>
              )}
            </Space>
          </Descriptions.Item>
          <Descriptions.Item label={intl.get("page_setting.edit.product.addedSearch")}>
            {settingValues.productFilters && settingValues.productFilters.map((pf, index) => {
                return (
                    <Tag key={index} color={getFilterColor(pf.type)} closable onClose={e => {e.preventDefault();  removeProductFilter(index);}}>
                      {intl.get("page_setting.edit.product.search." + pf.type)}{pf.desc ? ":" + pf.desc : ""}
                    </Tag>
                )
            })}
            {settingValues.productFilters && settingValues.productFilters.find(pf => pf.type === "id") && (
              <div style={{fontSize: 12, color: "gray"}}>{intl.get("page_setting.edit.product.sort.help")}</div>
            )}
          </Descriptions.Item>
          { settingValues.productType !== "selected" && (
              <>
                <Descriptions.Item label={intl.get("page_setting.edit.product.sort")}>
                  <Radio.Group value={settingValues.sort} onChange={onSortChange}>
                    <Radio value={"latest"}>{intl.get("products.sort.latest")}</Radio>
                    <Radio value={"lowest"}>{intl.get("products.sort.lowest")}</Radio>
                    <Radio value={"highest"}>{intl.get("products.sort.highest")}</Radio>
                  </Radio.Group>
                </Descriptions.Item>
                <Descriptions.Item label={intl.get("page_setting.edit.product.numProducts")}>
                  <InputNumber value={settingValues.numProducts ? settingValues.numProducts : 6} min={1} max={50}
                               onChange={onNumProductsChange}/>
                  <div>페이지가 한번에 가져오는 상품의 갯수로 많이 설정하면 페이지 로딩 속도가 느려진다.</div>
                  <div>페이지의 마지막 상품 목록인 경우 설정된 상품이 많은 경우라도 이 숫자는 20 ~ 30 정도로 설정하고 "스크롤시 상품 더보기"를 선택한다.</div>
                </Descriptions.Item>
                <Descriptions.Item label={intl.get("page_setting.edit.product.moreProducts")}>
                  <Switch disabled={!canMoreProducts} checked={settingValues.moreProducts} onClick={onMoreProductChange}/>
                  <span style={{marginLeft: 10}}>{intl.get("page_setting.edit.product.moreProducts.help")}</span>
                </Descriptions.Item>
              </>
          )}
        </Descriptions>
        <div style={{marginTop: 10}}>
          <div style={{display: "flex"}}>
            <h3>{intl.get("page_setting.edit.product.addedProducts")}</h3>
            {(canMoreProducts && productFilterType === "id") && (
              <div style={{marginLeft: "auto"}}>
                <Checkbox checked={showAllProducts} onChange={(e) => {
                  setShowAllProducts(e.target.checked);
                  loadFilterProducts(settingValues, e.target.checked);
                }}>
                  모든 상품 보기
                </Checkbox>
              </div>
            )}
          </div>
          {showDragTable && (
            <DraggableTable
                dataSource={filteredProducts}
                columns={columns}
                rowKey={"id"}
                onSortEnd={onProductDragEnd}
            />
          )}
          {!showDragTable && (
              <Table
                  size={'small'}
                  dataSource={filteredProducts}
                  columns={columns}
                  rowKey={"id"}
                  pagination={false}
              />
          )}
        </div>
        { productSearchVisible && (
          <ProductSearchPopup
              selectMultiple={true}
              showSku={false}
              projection={"app"}
              handleCancel={() => setProductSearchVisible(false)}
              handleOk={okProductSearch}
          />
        )}
        {visibleFileModal && (
          <FileListPopup id={'productBackgroundSelectModal'}
                         fileKind={"index"}
                         visible={true}
                         multipleFile={false}
                         canSelect={true}
                         handleCancel={() => setVisibleFileModal(false)}
                         handleOk={okFileModal}
          />
        )}
      </div>
  )
};

export default ProductSetting;