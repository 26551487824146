import React from 'react';

import ReplyListView from './ReplyListView';

const Reply = (props) => {
  return (
    <div>
      <div className="page-content">
         <ReplyListView />
      </div>
    </div>
  )
};

export default Reply;