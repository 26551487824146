import React, {useEffect, useState} from "react";
import Util from "../../util/Util";
import {Descriptions, Form, Input, Radio, Button, message, Divider, Spin} from "antd";
import intl from "react-intl-universal";
import NoticeApi from "./NoticeApi";
import {Link, useHistory} from "react-router-dom";

const NoticeEdit = (props) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [notice, setNotice] = useState({
    pinToTop: false,
    active: true,
  });

  useEffect(() => {
    if (props.match?.params.id) {
      // 목록에 있는 경우 dirty되었을 수도 있기 때문에 edit view에서 다시 가져온다.
      loadNotice(props.match.params.id);
    }
  }, [props.match?.params.id]);

  const loadNotice = (noticeId) => {
    NoticeApi.getNotice(noticeId).then(res => {
      const notice = res.data;
      if (notice.fileNames) {
        notice.fileNames = JSON.stringify(notice.fileNames);
      }
      if (notice.files) {
        notice.files = JSON.stringify(notice.files);
      }
      setNotice(notice);
      form.setFieldsValue(notice);
    });
  };

  const submitNotice = (values) => {
    const noticeState = JSON.parse(JSON.stringify(notice));
    if (values.files) {
      try {
        values.files = JSON.parse(values.files);
      } catch(err) {
        alert("파일 URL이 JSON 형태가 아닙니다.");
        return;
      }
    }
    if (values.fileNames) {
      try {
        values.fileNames = JSON.parse(values.fileNames);
      } catch(err) {
        alert("파일명이 JSON 형태가 아닙니다.");
        return;
      }
    }

    if (values.fileNames || values.files) {
      if (values.fileNames.length != values.files.length) {
        alert("파일과 파일 URL의 갯수가 다릅니다.");
        return;
      }
    }

    Object.assign(noticeState, values);

    NoticeApi.submitNotice(noticeState).then(res => {
      message.success("저장 되었습니다.");
    });
  };

  return (
    <Spin spinning={loading}>
      <div style={{width: "100%"}}>
        <Form onFinish={submitNotice} form={form}>
          <Descriptions bordered size={"small"} column={2}>
            <Descriptions.Item label="ID" span={2}>
              <Form.Item name={"id"}>
                <Input disabled={true}/>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label="상단고정">
              <Form.Item name={"pinToTop"} rules={[{ required: true }]}>
                <Radio.Group>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label="Active">
              <Form.Item name={"active"} rules={[{ required: true }]}>
                <Radio.Group>
                  <Radio value={true}>True</Radio>
                  <Radio value={false}>False</Radio>
                </Radio.Group>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label="Title" span={2}>
              <Form.Item name={"title"} rules={[{ required: true }]}>
                <Input/>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label="내용" span={2}>
              <Form.Item name={"contents"} rules={[{ required: true }]}>
                <Input.TextArea rows={20}/>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label="파일명" span={2}>
              <div>{'["file1","file2"] 형태로 작성'}</div>
              <Form.Item name={"fileNames"} rules={[{ required: false }]}>
                <Input/>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label="파일 URL" span={2}>
              <div>{'["file_path1","file_path2"] 형태로 작성'}</div>
              <Form.Item name={"files"} rules={[{ required: false }]}>
                <Input/>
              </Form.Item>
            </Descriptions.Item>
          </Descriptions>
        </Form>
        <div style={{marginTop: 10, width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
          <Button type="primary" onClick={() => form.submit()}>{intl.get("common.button.save")}</Button>
          <Divider type="vertical"/>
          <Button type="primary"><Link to={`${props.parentPath}`}>{intl.get("common.button.list")}</Link></Button>
        </div>
      </div>
    </Spin>
  )
};

export default NoticeEdit;