import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';
import FileList from './FileList';
import {Modal, Button, message} from "antd";
import intl from "react-intl-universal";

const FileListPopup = (props) => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  useEffect(() => {
    setSelectedFiles([]);
  }, [props.id]);

  const handleOk = () => {
    if (selectedFiles.length === 0) {
      message.error(intl.get("common.message.selectFile"));
      return;
    }
    if (!props.multipleFile && selectedFiles.length > 1) {
      message.error("하나의 파밀만 선택하세요.");
      return;
    }

    props.handleOk(props.id, selectedFiles);
  };

  const fileChanged = (selectedFiles) => {
    setSelectedFiles(selectedFiles);
  }

  const footer = props.canSelect ? [
    (<Button key="cancel" onClick={props.handleCancel}>Cancel</Button>),
    (<Button key="ok" type="primary" onClick={handleOk}>Ok</Button>)
  ] :  [
    (<Button key="cancel" onClick={props.handleCancel}>Cancel</Button>)
  ];

  return (
      <Modal
          title="File Manager"
          open={props.visible}
          onCancel={props.handleCancel}
          onOk={handleOk}
          footer={footer}
          width="90%"
          bodyStyle={{height: "600px"}}
      >
        <FileList id={props.id} canSelect={props.canSelect} fileChanged={fileChanged}/>
      </Modal>
  );
};

FileListPopup.propTypes = {
  id: PropTypes.string,
  fileKind: PropTypes.string,
  visible: PropTypes.bool,
  multipleFile: PropTypes.bool,
  handleCancel: PropTypes.func,
  handleOk: PropTypes.func,
  canSelect: PropTypes.bool,
};

export default FileListPopup;