import {adminConfig} from "../../config/admin.config";
import axios from "axios";
const API_BASE_URL = adminConfig.apiServer();

export default class SettlementApi {
  static getSellerSettlements = (params) => {
    let apiPath = `${API_BASE_URL}/settlements/seller?`;

    const paramKeyValues = []
    Object.keys(params).forEach(key => {
      if (params[key]) {
        paramKeyValues.push(key + "=" + params[key])
      }
    });
    apiPath += paramKeyValues.join("&");
    if (!params.projection) {
      apiPath += "&projection=webAdmin";
    }
    return axios.get(apiPath);
  };
}