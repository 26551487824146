import React, {useEffect, useState, useCallback} from "react";
import ProductApi from "./ProductApi";
import {Descriptions, Table, Button} from "antd";
import intl from "react-intl-universal";
import Util from "../../util/Util";
import {useLayoutState} from "../../components/layout/AppLayoutContext";

const ProductDetailView = (props) => {
  const layoutState = useLayoutState();

  const [product, setProduct] = useState({});

  useEffect(() => {
    loadProduct(props.productId);
  }, [props.productId]);

  const loadProduct = useCallback((productId) => {
    ProductApi.getProduct(productId).then(res => {
      const product = res.data;
      const brand = props.brands?.find(b => b.id === product.brandId);
      if (brand) {
        product.brand = brand;
      } else {
        product.brand = {
          id: product.brandId,
          name: product.brandId,
        }
      }

      const category = props.categories?.find(c => c.id === product.categoryId);
      if (category) {
        const subCategory = category.subCategories.find(sc => sc.id === product.subCategoryId);
        if (subCategory) {
          product.category = category.name + " => " + subCategory.name;
        } else {
          product.category = category.name
        }
      } else {
        product.category = product.subCategoryId;
      }
      setProduct(product);
    });
  }, [product]);

  const openPreview = () => {
    window.open(`${props.webmallUrl}/products/${product.id}`, '_blank').focus();
  };

  const {productCurrency} = layoutState.application;

  const skuColumns = [{
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
  }, {
    title: intl.get("products.sku.optionText"),
    dataIndex: "optionText",
    key: "optionText",
  }, {
    title: intl.get("products.sku.supplyPrice"),
    dataIndex: "supplyPrice",
    key: "supplyPrice",
    align: "right",
    render: (text, record) => {
      return (
        <>
          <div>{text ? Util.currencyFormat(text, productCurrency) : '0'}</div>
        </>
      )
    }
  }, {
    title: intl.get("products.sku.salesPrice"),
    dataIndex: "salesPrice",
    key: "salesPrice",
    align: "right",
    render: (text, record) => {
      return (<span>{text ? Util.currencyFormat(text, productCurrency) : '0'}</span>)
    }
  }, {
    title: intl.get("products.sku.minSalesPrice"),
    dataIndex: "minSalesPrice",
    key: "minSalesPrice",
    align: "right",
    render: (text, record) => {
      return (<span>{text ? Util.currencyFormat(text, productCurrency) : '0'}</span>)
    }
  }, {
    title: intl.get("products.sku.maxSalesPrice"),
    dataIndex: "maxSalesPrice",
    key: "maxSalesPrice",
    align: "right",
    render: (text, record) => {
      return (<span>{text ? Util.currencyFormat(text, productCurrency) : '0'}</span>)
    }
  }];

  return (
    <div>
      {props.webmallUrl && (
        <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: 10}}>
          <Button type="primary" onClick={openPreview}>{intl.get("products.preview")}</Button>
        </div>
      )}
      <Descriptions bordered size={"small"} column={3}>
        <Descriptions.Item label={intl.get("products.name") + intl.get("products.name.cn")} span={3}>
          {product.name}
        </Descriptions.Item>
        <Descriptions.Item label={intl.get("products.name") + intl.get("products.name.ko")} span={3}>
          {product.nameKr}
        </Descriptions.Item>
        <Descriptions.Item label={intl.get("products.brand")}>
          {product.brand?.name}
        </Descriptions.Item>
        <Descriptions.Item label={intl.get("products.category")}>
          {product.category}
        </Descriptions.Item>
        <Descriptions.Item label={intl.get("products.originCountry")}>
          {product.originCountry}
        </Descriptions.Item>
        <Descriptions.Item label={intl.get("products.images")} span={3}>
          {product.images?.map((img, index) => {
            return (<img key={index} src={img} style={{width:100, height: 100}}/>)
          })}
        </Descriptions.Item>

      </Descriptions>
      <div style={{marginTop: "10px"}}>
        <h2>{intl.get("products.options")}(SKU)</h2>
        <Table size={'small'}
               dataSource={product.skus}
               columns={skuColumns}
               rowKey={'id'}
               pagination={false}
        />
      </div>

    </div>
  );
}

export default ProductDetailView;