import React, {useState, useEffect} from 'react';
import CompanyUserApi from './CompanyUserApi';
import CompanyUserEdit from "./CompanyUserEdit";
import {message, Table, Modal, Layout, Tag, Button, Alert, Space} from "antd";
import {SettingOutlined} from "@ant-design/icons";
import ApplicationApi from "../application/ApplicationApi";
import {adminConfig} from "../../config/admin.config";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { SyncOutlined } from '@ant-design/icons';
import intl from "react-intl-universal";

import Util from "../../util/Util";
import CompanyApi from "./CompanyApi";
import SystemSettingApi from "../system_setting/SystemSettingApi";
import {MemberContext} from "../../auth/member.context";

const {Content, Sider, Header} = Layout;

const CompanyUserManager = (props) => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [selectedCompanyUser, setSelectedCompanyUser] = useState({});
  const [filter, setFilter] = useState({
    role: '', name: '',
  });
  const [applications, setApplications] = useState([]);
  const [companyUsers, setCompanyUsers] = useState([]);
  const [visibleInviteLink, setVisibleInviteLink] = useState(false);
  const [inviteCode, setInviteCode] = useState({});
  const [isInterlogis, setIsInterlogis] = useState(false);

  useEffect(() => {
    searchCompanyUsers(1);
    loadApplications();
  }, []);

  useEffect(() => {
    getInviteCode();
    checkInterlogisCompany();
  }, [props.companyId]);

  const getSearchParams = (page) => {
    const params = {
      ...filter,
      companyId: props.companyId,
      page: page,
      pageSize: pagination.pageSize,
    };

    return params;
  };

  const getInviteCode = () => {
    const companyId = props.companyId ? props.companyId : 0;
    CompanyApi.getInviteCode(companyId).then(res => {
      setInviteCode(res.data);
    });
  };

  const loadApplications = () => {
    const companyId = Util.isAdmin() ? "all" : props.companyId;
    ApplicationApi.getApplications({companyId: companyId, page: 1, pageSize: 200}).then(res => {
      const pagingResult = res.data;
      setApplications(pagingResult.data);
    });
  };

  const checkInterlogisCompany = () => {
    const companyId = props.companyId ? props.companyId : MemberContext.memberInformation.companyId;
    SystemSettingApi.getPublicSystemSettings().then(res => {
      const interlogisCompanySetting = res.data.find(ss => ss.name === "interlogis.companyId");
      if (interlogisCompanySetting) {
        setIsInterlogis(interlogisCompanySetting.value === (companyId + ""));
      } else {
        setIsInterlogis(false);
      }
    }).catch(err => {
      console.log("checkInterlogisCompany err:", err);
      setIsInterlogis(false);
    });
  };

  const searchCompanyUsers = (page) => {
    CompanyUserApi.getCompanyUsers(getSearchParams(page)).then(res => {
      const pagingResult = res.data;
      const pagination = {
        current: pagingResult.page,
        pageSize: pagingResult.pageSize,
        total: pagingResult.count,
      }
      const companyUsers = pagingResult.data;

      setCompanyUsers(companyUsers);
      setPagination(pagination);
      setSelectedCompanyUser(companyUsers.length > 0 ? companyUsers[0] : {});
    });
  };

  const deleteCompanyUser = (user) => {
    if(!window.confirm(intl.get("common.confirm.delete", {name: user.name}))) {
      return;
    }
    CompanyUserApi.deleteUser(user.id).then(res => {
      searchCompanyUsers(pagination.current);
      message.success(intl.get("common.message.deleted"));
    }).catch(e => {
      console.log(">>err>", e);
    });
  };

  const setFilterState = (filter) => {
    setFilter(filter);
  };

  const userTableChanged = (e) => {
    searchCompanyUsers(e.current);
  };

  const selectCompanyUser = (event, user) => {
    setSelectedCompanyUser(user);
  };

  const setUserTableRowClassName = (record, index) => {
    // if (record.id == this.state.selectedCompanyUser.id) {
    //   return "table-row-selected";
    // } else {
    //   return "";
    // }
  };

  const setCompanyUser = (companyUser) => {
    const companyUsersState = [...companyUsers];

    let found = false;
    let changedCompanyUsers = [];
    companyUsersState.forEach(cu => {
      if (cu.id === companyUser.id) {
        found = true;
        changedCompanyUsers.push(companyUser);
      } else {
        changedCompanyUsers.push(cu);
      }
    });
    if (!found) {
      // 신규 추가인 경우 가장 처음에 넣는다.
      changedCompanyUsers = ([companyUser]).concat(changedCompanyUsers);
    }

    setSelectedCompanyUser(companyUser);
    setCompanyUsers(changedCompanyUsers);
  }

  const columns = [{
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (text, record) => (
          <span>{text}</span>
      )
    }, {
      title: intl.get("company.user.name"),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        if (record.id === selectedCompanyUser.id) {
          return (<div><span style={{marginRight: 10}}>{text}</span><Tag color={"volcano"} size={"small"}>{intl.get("company.user.editing")}</Tag></div>)
        } else {
          return <span>{text}</span>
        }
      }
    }, {
      title: intl.get("company.user.userType"),
      dataIndex: 'userType',
      key: 'userType',
      render: (text, record) => {
        return (<span>{intl.get("company.user.userType." + text)}</span>)
      }
  }];

  const inviteLink = `${adminConfig.adminWebServer()}/register?code=${inviteCode}`
  const contents = (
      <Layout>
        <Header style={{backgroundColor: "white", padding: props.mode === "modal" ? 10 : 0, height: "auto"}}>
          <div style={{marginBottom: 20}}>
            <Button type={"primary"} onClick={() => setVisibleInviteLink(true)}>{intl.get("company.user.invite")}</Button>
            {visibleInviteLink && (
              <div>
                <Alert
                  message={intl.get("company.user.share.link")}
                  description={(
                    <Space>
                      <div style={{fontSize: 16, fontWeight: "bold", color: "#ff4d4f"}}>{inviteLink}</div>
                      <CopyToClipboard text={inviteLink}>
                        <div><Button onClick={() => message.info(intl.get("company.user.copied"))}>{intl.get("company.user.copy.link")}</Button></div>
                      </CopyToClipboard>
                    </Space>
                  )}
                  type="info"
                  showIcon
                  closable
                  afterClose={() => setVisibleInviteLink(false)}
                />
              </div>
            )}
          </div>
        </Header>
        <Layout>
          <Sider width={400} className="modal-sider">
            <div style={{padding: 10, backgroundColor: "white", height: "100%"}}>
              <Table size={'small'}
                     dataSource={companyUsers}
                     columns={columns}
                     rowKey={'id'}
                     title={() => (
                       <div style={{display: "flex", alignItems: "center"}}>
                         <div>{intl.get("common.label.listTotal", {total: pagination.total})}</div>
                         <div style={{marginLeft: "auto"}}>
                           <Button size={"small"}
                                   shape="circle"
                                   icon={<SyncOutlined />}
                                   onClick={() => searchCompanyUsers(1)}/>
                         </div>
                       </div>
                     )}
                     pagination={pagination}
                     onChange={userTableChanged}
                     rowClassName = {setUserTableRowClassName}
                     onRow={(record, rowIndex) => {
                       return {
                         onClick: event => selectCompanyUser(event, record),
                       };
                     }}
              />
            </div>
          </Sider>
          <Content className="modal-content">
            <div style={{margin: 10}}>
              <CompanyUserEdit applications={applications}
                               isInterlogis={isInterlogis}
                               companyUser={selectedCompanyUser}
                               deleteCompanyUser={deleteCompanyUser}
                               setCompanyUser={setCompanyUser}/>
            </div>
          </Content>
        </Layout>
      </Layout>
  );

  return (
      <>
        { props.mode === "modal" && (
          <Modal title={[<div key={1}><SettingOutlined/> {intl.get("company.user.manager")}</div>]}
                 visible={true}
                 onCancel={props.onClose} footer={null}
                 width={1200} bodyStyle={{margin: 0, padding: 0}}>
            {contents}
          </Modal>
        )}
        { props.mode !== "modal" && (
            <div>
              {contents}
            </div>
        )}
      </>
  )
}

export default CompanyUserManager;