import React, {useEffect, useState} from "react";
import "./Preview.less";
import ProductApi from "../../product/ProductApi";
import intl from "react-intl-universal";
import 'react-multi-carousel/lib/styles.css';
import ProductColumns from "./product/ProductColumns";
import ProductOneColumn from "./product/ProductOneColumn";
import ProductSlider from "./product/ProductSlider";
import ProductSlider2Row from "./product/ProductSlider2Row";

const ProductSlot = (props) => {
  const [products, setProducts] = useState([]);

  const {settingValues} = props;

  useEffect(() => {
    if (!settingValues.productFilters || settingValues.productFilters.length === 0) {
      setProducts([]);
      return;
    }
    const categoryIds = [];
    const tags = [];
    const brandIds = [];
    let ids = [];
    settingValues.productFilters.forEach(filter => {
      if (filter.type === "categoryId") {
        categoryIds.push(filter.value);
      } else if (filter.type === "brandId") {
        brandIds.push(filter.value);
      } else if (filter.type === "tag") {
        tags.push(filter.value);
      } else if (filter.type === "id") {
        ids = filter.value.split(",");
      }
    });

    const searchParam = {
      categoryId: categoryIds,
      tag: tags,
      brandId: brandIds,
      id: ids,
      sort: ids.length > 0 ? '' : settingValues.sort,
      page: 1,
      pageSize: settingValues.numProducts,
      projection: "app"
    };
    ProductApi.getProducts(searchParam).then(res => {
      const pageResult = res.data;
      setProducts(pageResult.data);
    });
  }, [props.settingValues]);

  const handleDragStart = (e) => e.preventDefault();
  // const {application} = props;
  const hasProductFilter = settingValues.productFilters && settingValues.productFilters.length > 0;
  let errMessage = "";
  if (!hasProductFilter) {
    errMessage = intl.get("page_setting.edit.product.pleaseSet");
  }
  if (!products || products.length === 0) {
    errMessage = intl.get("page_setting.edit.product.noProducts");
  }

  if (errMessage.length > 0) {
    return (
        <div className="one-column-box" style={{marginTop: 16 }}>
          {settingValues.title && (<div className="header">{settingValues.title}</div>)}
          <div className="content-box">
            <div className={"empty-slot"} style={{height: settingValues.height}}>
              <div style={{fontSize: 16, fontWeight: 500, marginBottom: 8}}>{intl.get("page_setting.edit.product.displayArea")}</div>
              <div>{errMessage}</div>
            </div>
          </div>
        </div>
    )
  }

  if (settingValues.slotType === "product_1") {
    return (<ProductOneColumn settingValues={settingValues} products={products} {...props} />)
  } else if (settingValues.slotType === "product_2") {
    return (<ProductColumns
        settingValues={settingValues}
        columns={2}
        products={products}
        {...props} />)
  } else if (settingValues.slotType === "product_3") {
    return (<ProductColumns title={settingValues.title} columns={3} products={products} {...props} />)
  } else if (settingValues.slotType === "slider") {
    return (<ProductSlider title={settingValues.title}
                           productWidth={settingValues.productWidth}
                           products={products}
                           {...props}
                           handleDragStart={handleDragStart}/>)
  } else if (settingValues.slotType === "slider_2") {
    return (<ProductSlider2Row settingValues={settingValues}
                               products={products}
                               {...props}
                               handleDragStart={handleDragStart}/>)
  } else {
    return (<div>잘못된 상품 설정 옵션입니다.</div>)
  }
}

export default ProductSlot;