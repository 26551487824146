import React, {useEffect, useState} from "react";
import {
  Tabs
} from "antd";
import ApplicationBasicEdit from "./ApplicationBasicEdit";
import intl from "react-intl-universal";
import WeimobAppEdit from "./WeimobAppEdit";
import {useLayoutState} from "../../components/layout/AppLayoutContext";

const ApplicationEdit = (props) => {
  const layoutState = useLayoutState();
  const [applicationId, setApplicationId] = useState(0);

  useEffect(() => {
    if (window.location.pathname === "/app-setting") {
      setApplicationId(layoutState.application.id);
    } else {
      if (props.match.params.applicationId) {
        setApplicationId(props.match.params.applicationId);
      } else {
        setApplicationId(0);
      }
    }
  }, [props.match.params.applicationId]);

  return (
    <div>
      <div className="page-content">
        <Tabs defaultActiveKey="1"
              type={"card"}
              items={[{
                key: "1",
                label: intl.get("application.design.basic"),
                children: (<ApplicationBasicEdit applicationId={applicationId} {...props} />)
              }, {
                key: "2",
                label: "微盟(Weimob)",
                children: (<WeimobAppEdit applicationId={applicationId} {...props} />)
              }]}
        />
      </div>
    </div>
  );
}

export default ApplicationEdit;