import React, { useState, useEffect } from "react";
import LogisticsCompanyApi from "./LogisticsCompanyApi";
import { Modal, Layout, Descriptions, Button, Select, Input, message, Radio } from "antd";
import intl from "react-intl-universal";
import { UserOutlined, UploadOutlined } from "@ant-design/icons";
// import originLogisticData from './OriginLogisticData.json'
import logisticData from './LogisticData.json'
const { Content } = Layout;
const LogisticOptions = logisticData
const LogisticEditPopup = (props) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    code: '',
    name: '',
    weimobCode: '',
    active: true,
  })

  useEffect(() => {
    if (props.logistic?.id) {
      setFormData(props.logistic)
    }
  }, [props.logistic]);

  const onFilterChanged = (key, e) => {
    // console.log('key :>> ', key)
    // console.log('e :>> ', e)
    // console.log(`${key}: ${e.target.value}`)
    const obj = { ...formData }
    if (typeof (e) == 'object') {
      obj[key] = e.target.value
    } else if (typeof (e) == 'number' || typeof (e) == 'string') {
      obj[key] = e
    }
    setFormData(obj)
  }
  const handelSave = () => {
    if (!formData.code) {
      message.error(intl.get("common.inputField", { name: intl.get("logistics.company.name") }));
      return
    }
    if (formData.code) {
      const option = logisticData.find(item => item.value == formData.code)
      formData.name = option?.label || ''
    }
    // console.log('formData :>> ', formData);
    // return
    setLoading(true)
    LogisticsCompanyApi.submitLogistic(formData).then(res => {
      setFormData({
        code: '',
        name: '',
        weimobCode: '',
        active: true,
      })
      message.success(intl.get("common.message.saved"))
      props.onOk()
    }).finally(_ => {
      setLoading(false)
    })

  }
  return (
    <div>
      <Modal
        title={[<div key={1}>{props.logistic?.id ? intl.get("logistics.company.modify") : intl.get("logistics.company.add")}</div>]}
        open={props.open}
        // onOk={() => props.onOk()}
        onCancel={() => props.onCancel()}
        footer={[
          <Button key="1" onClick={() => props.onCancel()}>
            {intl.get("common.button.close")}
          </Button>,
          <Button key="2" type="primary" onClick={handelSave} loading={loading}>
            {intl.get("common.button.save")}
          </Button>,
        ]}
        width={800}
        bodyStyle={{ margin: 0, padding: 0 }}>
        <Layout>

          <Content className="modal-content">
            <Descriptions bordered size={"small"} column={1}>
              {/* <Descriptions.Item label={intl.get("logistics.company.name")}> */}
              <Descriptions.Item
                label={
                  <>
                    <span>{intl.get("logistics.company.name")}</span>
                    <span style={{ marginLeft: 2, color: 'red' }}>*</span>
                  </>
                }
              >
                <Select
                  style={{ width: 300, marginRight: 10 }}
                  placeholder={intl.get("logistics.company.search")}
                  value={formData.code}
                  onChange={e => onFilterChanged('code', e)}
                  options={LogisticOptions}
                  showSearch
                  optionFilterProp="label"
                  filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                  }
                />
              </Descriptions.Item>
              <Descriptions.Item label={intl.get("logistics.company.weimob.code")}>
                <Input value={formData.weimobCode} onChange={e => onFilterChanged('weimobCode', e)} />
              </Descriptions.Item>
              <Descriptions.Item label={intl.get("brand.active")}>
                <Radio.Group value={formData.active} defaultValue={true} onChange={e => onFilterChanged('active', e)}>
                  <Radio value={true}>{intl.get("common.true")}</Radio>
                  <Radio value={false}>{intl.get("common.false")}</Radio>
                </Radio.Group>
              </Descriptions.Item>
            </Descriptions>
          </Content>
        </Layout>
      </Modal>
    </div>

  )
}

export default LogisticEditPopup;