import React, {useEffect, useState} from "react";
import {Button, Divider, Form, Input, message} from 'antd';
import {adminConfig} from "../../config/admin.config";
import {AuthService} from "../../auth/auth.service";
import {Link, useHistory} from "react-router-dom";
import {MemberContext} from "../../auth/member.context";
import "./Login.less";
import ChinaFlag from "../../asset/images/flag-china.svg";
import KoreaFlag from "../../asset/images/flag-korea.svg";
import LogoImg from "../../asset/images/youma_logo.png";

import intl from 'react-intl-universal';
import Cookies from "universal-cookie";

const cookies = new Cookies();

const Login = (props) => {
  const [loading, setLoading] = useState(false);
  const [authChecked, setAuthChecked] = useState(false);
  const [form] = Form.useForm();

  const checkAuth = () => {
    setAuthChecked(false);
    AuthService.checkAuth().then(() => {
      document.location.href = "/";
    }).catch((err) => {
    }).finally(() => setAuthChecked(true));
  };

  useEffect(() => {
    if (MemberContext.memberInformation?.id) {
      document.location.href = "/";
      return;
    }
    checkAuth();
  }, []);

  const onFinish = (values) => {
    const signId = values.id;
    const password = values.password;

    setLoading(true);
    AuthService.login(signId, password).then(() => {
      setLoading(false);
      goToNextPage();
    }).catch(error => {
      setLoading(false);
      if (error.response && error.response.status === 400) {
        message.warn("아이디 혹시 비밀번호를 확인해 주세요.")
      } else if (error.response && error.response.status === 406) {
        message.warn("신청한 계정은 아직 미승인 상태 입니다. 관리자에게 문의하세요.")
      }
    });
  };

  const goToNextPage = () => {
    if (MemberContext.memberInformation?.userType === "draft") {
      window.location = "/company-setting";
    } else {
      window.location = adminConfig.homePage;
    }
  }

  const handleLanguageClick = (locale) => {
    cookies.set("locale", locale, {path: '/'});
    window.location.reload();
  }

  const {serviceSetting} = props;

  if (!authChecked) {
    return (<div></div>)
  }

  return (
    <div className="login-page" style={{ height: "100vh"}}>
      <div className="header">
        {serviceSetting.loaded && !serviceSetting.company?.id && (
          <div><a href={adminConfig.portalServer()}><img className="logo-img" src={LogoImg}/></a></div>
        )}
      </div>
      <div className={"contents"}>
        <div className={"form-wrapper"}>
          <div className={"title"}>{intl.get("login.login")}</div>
          <div className={"title-desc"}>{intl.get("login.welcome", {name: props.serviceSetting?.company?.serviceName})}</div>
          <div className={"form-box"}>
            <Form name="normal_login" initialValues={{remember: true}} onFinish={onFinish} form={form}>
              <div className={"form-item"}>
                <div className={"form-label"}>{intl.get("login.loginId")}</div>
                <Form.Item name="id"
                           rules={[{required: true, message: intl.get("common.inputField", {name: intl.get("login.loginId")})}]}>
                  <Input className={"input"}/>
                </Form.Item>
              </div>
              <div className={"form-item"}>
                <div className={"form-label"}>{intl.get("login.password")}</div>
                <Form.Item name="password" rules={[{required: true, message: intl.get("common.inputField", {name: intl.get("login.password")})}]}>
                  <Input className={"input"} type="password" onPressEnter={() => form.submit()}/>
                </Form.Item>
              </div>
              <div>
                <Form.Item>
                  <Link to={"/find-password"}>
                    <span className="login-form-forgot">{intl.get("login.findPasswordButton")}</span>
                  </Link>
                </Form.Item>
              </div>
            </Form>
            <div className={"btn-wrapper"} style={{marginBottom: 24}}>
              {/*<Form.Item>*/}
                <Button block type="primary" onClick={() => form.submit()} className="login-form-button" loading={loading}>{intl.get("login.login")}</Button>
                <div style={{marginTop: 20, display: "flex", alignItems: "center", justifyContent: "center"}}>
                  <div style={{fontSize: 14, color: "#9e9e9e"}}>{intl.get("login.noMember")}</div>
                  <Link to="/register"><Button type="link"><span className={"login-form-forgot"}>{intl.get("register.register")}</span></Button></Link>
                </div>
              {/*</Form.Item>*/}
            </div>
          </div>
        </div>
      </div>
      <div style={{marginTop:24, display: "flex", justifyContent: "center", alignItems: "center"}}>
        <div><img style={{width: 20, height: 20, marginRight: 8}} src={ChinaFlag}/></div>
        <div style={{fontSize: 14, color: "#616161", marginRight: 17, cursor: "pointer"}}
             onClick={() => handleLanguageClick('zh-CN')}>
          简体中文
        </div>
        <Divider type={"vertical"}/>
        <div style={{marginLeft: 17, marginRight: 8}}><img style={{width: 20, height: 20}} src={KoreaFlag}/></div>
        <div style={{fontSize: 14, color: "#616161", cursor: "pointer"}}
             onClick={() => handleLanguageClick('ko-KR')}>
          한국어
        </div>
      </div>
      <div style={{paddingTop: 50}}>
      </div>
    </div>
  );
};

export default Login;
