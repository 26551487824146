import React from "react";
import {Typography} from "antd";
const { Title, Paragraph, Text, Link } = Typography;

const OrderUploadHome = () => {
  return (
    <div>
      <Title level={2}>배송신청 전 필독사항</Title>
      <Paragraph>
        <ol>
          <li>제품 이미지, 옵션, 링크 등 을 정확히 기입하지 않은 경우 검수가 제대로 안될 수 있습니다.</li>
          <li>사고 건에 대한 배상신청 부분은 [이용안내] [서비스안내] [전자상거래] 에서 상단 고정된 게시글을 확인해주세요. 배송 신청 전 해당 공지를 확인하여 배상 제외 품목에 해당 되는지 확인하신 후 접수 부탁드립니다.</li>
          <li>합포의 경우 접수 시 기입하신 상품정보에 대하여 무료로 합포 처리되며 접수내역에서 접수건의 상세보기 페이지 하단에 제품추가로 추가 합포 상품 등록이 가능합니다.해당 접수번호의 상태값 [입고완료] 부터는 검수 지연 및 제품 오 배송 등의 사고 방지를 위해 불가하니 미리 처리 부탁드립니다.</li>
          <li>현지 구매사이트에 정확한 수취인 및 주소를 이용안내에 나온 대로 기입해 주셔야 미 접수 선 입고 건에 대해서 처리 가능하며 미 접수 건 입고 시 회원정보가 수취인정보에 없을 시 모든 회원에게 해당 물품의 정보가 공개됩니다.</li>
          <li>현지 구매사이트에서 판매자가 입력한 현지송장번호를 정확히 입력해 주셔야 바로 입고처리 가능합니다. 만약 송장번호에 불필요한 문자가 들어있거나 반대로 필요한 문자가 빠진 경우 입고가 불가능하니 정확한 송장번호로 입력 부탁드립니다.</li>
          <li>접수 시 입력한 품목명[한글], 품목명[영문], 단가 는 현지 세관 및 한국 세관에 그대로 신고됩니다. 잘못된 정보 입력으로 생기는 세관보류 및 압수, 세금청구, 검사지정 등 의 사고에 대해서는 회원분의 책임이니 정확한 품목명으로 기입 부탁드립니다.</li>
          <li>HS코드는 버튼으로 이동되어지는 한국무역통계진흥원의 검색서비스를 이용하여 2자리코드만 기입해주시면 됩니다. 해당 검색 란에 한글품명 기입 후 SEARCH버튼 클릭 시 아래 품목코드 표에 빨간색 네모 칸으로 표기되지만 굵은 네모로 표기되는 건이 100% 맞는 코드가 아니며 얇은 네모 표기 건도 확인하여 본인 물품의 정확한 코드를 확인하여 왼쪽에 표기된 10의 자리 숫자와 상단에 표기된 1의 자리 숫자를 합하여 2자리 숫자로 기입해주시면 됩니다.</li>
          <li>목록통관은 목록통관 제외 품목 외에 자가사용기준의 물건과 수량만 통관 가능하며 정확한 각 품목별 수량이 정해진 것이 아닌 세관원의 판단 하에 목록통관이 취하될 수 있습니다.</li>
        </ol>
      </Paragraph>
    </div>
  )
};

export default OrderUploadHome;