import React, {useState, useEffect} from "react";
import ProductApi from "./ProductApi";
import {Button, Input, Modal, Table, message, Divider, Checkbox, Select, Layout, Spin} from "antd";
import intl from "react-intl-universal";
import {SettingOutlined} from "@ant-design/icons";

const {Content} = Layout;

const TagSettingPopup = (props) => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [filter, setFilter] = useState({
    value: "",
  });

  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTagValues, setSelectedTagValues] = useState([]);

  useEffect(() => {
    searchTags(1);
  }, []);

  const searchTags = (page) => {
    setLoading(true);
    const params = getSearchParam(page);

    ProductApi.getTags(params).then(res => {
      const pagingResult = res.data;
      const pagination = {
        current: pagingResult.page,
        pageSize: pagingResult.pageSize,
        total: pagingResult.count,
      }
      setTags(pagingResult.data);
      setPagination(pagination);
      setLoading(false);
    });
  };

  const getSearchParam = (page) => {
    const params = {
      pageSize: pagination.pageSize,
      page: page,
      ...filter
    };
    return params;
  };

  const tagTableChanged = (e) => {
    const paginationState = {...pagination};
    paginationState.pageSize = e.pageSize;
    setPagination(paginationState);
    searchTags(e.current);
  };

  const handleOk = () => {
    if (selectedTagValues.length === 0) {
      message.error("Tag를 선택하세요.");
      return;
    }
    if (!props.selectMultiple && selectedTagValues.length > 1) {
      message.error("Tag 하나만 선택하세요.");
      return;
    }
    if (props.handleOk) {
      props.handleOk([...selectedTagValues]);
    }
  }

  const onFilterChanged = (field, value) => {
    const filterState = {...filter};
    filterState[field] = value;
    setFilter(filterState);
  };

  let columns = [{
    title: "Tag",
    dataIndex: 'value',
    key: 'value',
  }];

  const rowSelection = {
    selectedRowKeys: selectedTagValues,
    onChange: (selectedTagValues) => {setSelectedTagValues(selectedTagValues)},
  };

  return (
    <Modal
        title={[<div key={1}><SettingOutlined/> Tag검색</div>]}
        visible={true}
        onCancel={props.handleCancel}
        onOk={handleOk}
        width={400}
        bodyStyle={{margin: 0, padding: 0}}>
      <Layout>
        <Content className="modal-content">
          <Spin spinning={loading}>
            <div className="page-op-box">
              <div className="filter-box">
                <Input style={{ width: 200 }} value={filter.value}
                       onChange={e => onFilterChanged('value', e.target.value)}
                       placeholder={"Tag명"}
                       onPressEnter={() => searchTags(1)}/>
                <div style={{marginLeft: "auto"}}>
                  <div style={{width: 100}}>
                    <Button style={{width: 100}} type="primary" block size="default" onClick={() => searchTags(1)}>{intl.get("common.button.search")}</Button>
                  </div>
                </div>
              </div>
            </div>
            <div style={{marginTop: "10px"}}>
              <Table size={'small'}
                     dataSource={tags}
                     columns={columns}
                     rowKey={'value'}
                     title={() => pagination.total + '건'}
                     pagination={pagination}
                     onChange={tagTableChanged}
                     rowSelection={rowSelection}
                />
            </div>
          </Spin>
        </Content>
      </Layout>
    </Modal>
  )
}

export default TagSettingPopup;