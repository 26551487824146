import React from 'react';
import {
  Table,
  Button,
  message,
} from 'antd';
import FaqApi from "./FaqApi";
import intl from "react-intl-universal";
import {Link, useHistory} from "react-router-dom";

const FaqList = (props) => {
  const history = useHistory();

  const deleteFaq = (e, faq) => {
    e.stopPropagation();
    if (!window.confirm(intl.get("common.confirm.delete"))) {
      return;
    }
    FaqApi.deleteFaq(faq.id).then(res => {
      message.success(intl.get("common.message.deleted"));
      props.loadFaqs();
    });
  };

  const { faqs } = props;

  const columns = [{
    title: "ID",
    dataIndex: 'id',
    key: 'id',
  }, {
    title: "Question",
    dataIndex: 'question',
    key: 'question',
    render: (text, record) => {
      const value = text.length > 20 ? text.substring(0, 20) : text;
      return (<span>{value}</span>);
    }
  }, {
    title: intl.get("faq.pinToTop"),
    dataIndex: 'pinToTop',
    key: 'pinToTop',
    render: (text, record) => {
      const textLabel = record.pinToTop ? intl.get("common.true") : intl.get("common.false");
      return (<span>{textLabel}</span>)
    }
  }, {
    title: "Active",
    dataIndex: 'active',
    key: 'active',
    render: (text, record) => {
      const textLabel = record.active ? intl.get("common.true") : intl.get("common.false");
      return (<span>{textLabel}</span>)
    }
  }, {
    title: intl.get("common.updatedAt"),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    align: 'center',
    render: (text, record) => (
        <span>{text.substring(0, 16)}</span>
    )
  }, {
    title: '',
    dataIndex: '',
    key: 'action',
    render: (text, record) => {
      return(
        <Button size="small" onClick={(e) => deleteFaq(e, record)}>{intl.get("common.button.delete")}</Button>
      )
    }
  }];

  return (
      <div>
        <div className="page-op-box">
          <div className="filter-box">
              <Button style={{width: 100}} type="primary" onClick={props.loadFaqs}>{intl.get("common.button.search")}</Button>
          </div>
          <div className="extra-op-box">
            <Link to={`${props.match.path}/new`}>
              <Button type="default" size="small">{intl.get("common.button.add")}</Button>
            </Link>
          </div>
        </div>
        <div style={{marginTop: "10px"}}>
          <Table size={'small'}
                 rowKey={'id'}
                 dataSource={faqs}
                 columns={columns}
                 pagination={false}
                 onRow={(record, rowIndex) => {
                   return {
                     onClick: event => history.push(`/faqs/${record.id}`),
                   };
                 }}
          />
        </div>
      </div>
  )
}

export default FaqList;