import {Layout, Modal, Table} from "antd";
import {QuestionCircleOutlined} from "@ant-design/icons";
import React from "react";
import QuestionEdit from "./QuestionEdit";

const {Content} = Layout;

const QuestionNewModal = (props) => {
  return (
    <Modal
      title={[<div key={1}><QuestionCircleOutlined/> 1:1 문의 등록</div>]}
      visible={true}
      onCancel={props.handleCancel}
      footer={null}
      width={800}
      bodyStyle={{margin: 0, padding: 0}}
    >
      <Layout>
        <Content className="modal-content">
          <div style={{marginTop: "10px"}}>
            <QuestionEdit mode={"modal"}
                          afterSave={props.handleCancel}
                          onCancel={props.handleCancel}/>
          </div>
        </Content>
      </Layout>
    </Modal>
  )
};

export default QuestionNewModal;