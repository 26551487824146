import { adminConfig } from "../../config/admin.config";
import { HttpUtil } from '../../util/HttpUtil';
import axios from "axios";

const API_BASE_URL = adminConfig.apiServer();

export default class LogisticApi {
  static getLogistics = (options) => {
    let apiPath = `${API_BASE_URL}/logistics-companies?projection=webAdmin&`;

    if (options) {
      apiPath += Object.keys(options).map(key => {
        return key + "=" + options[key]
      }).join("&");
    }

    return axios.get(apiPath);
  };
  static getLogistic = (id) => {
    let apiPath = `${API_BASE_URL}/logistics-companies/${id}?projection=webAdmin`;
    return axios.get(apiPath);
  };

  static submitLogistic = (logisticsCompany) => {
    if (logisticsCompany.id) {
      const apiPath = `${API_BASE_URL}/logistics-companies/${logisticsCompany.id}?projection=webAdmin`;
      return axios.put(apiPath, logisticsCompany);
    } else {
      const apiPath = `${API_BASE_URL}/logistics-companies?projection=webAdmin`;
      return axios.post(apiPath, logisticsCompany);
    }
  };

  static deleteLogistic = (id) => {
    let apiPath = `${API_BASE_URL}/logistics-companies/${id}?projection=webAdmin`;
    return axios.delete(apiPath);
  };
}