import {Input} from "antd";
import NoImage from "../../asset/images/no_image.png";
import React, {useEffect, useState} from "react";
import ProductDetailPriceView from "./ProductDetailPriceView";
import styled from 'styled-components';
import {themeColorSet} from "../../components/layout/DefaultMiniAppDesign";

const ProductPreviewSelectSku = (props) => {
  const [colorTable, setColorTable] = useState(themeColorSet.theme1);

  useEffect(() => {
    if (props.appDesign.themeSetting) {
      setColorTable(props.appDesign.themeSetting.themeColor.colorTable);
    }
  }, [props.appDesign.themeSetting]);

  return (
    <div className="sku-page">
      {/*<div className="b-b-line" style={{height: 44}}></div>*/}
      {props.groupingProducts.map((groupProduct, index) => (
        <div key={index}>
          <div className="u-p-40 u-flex">
            <img src={groupProduct.product.images[0]} style={{width: 110, height: 110}}/>
            <div className="u-m-l-32">
              <div style={{color: "#212121", fontWeight: 500}}>{props.productName}</div>
              <ProductDetailPriceView application={props.application}
                                      salesPrice={groupProduct.skus.length > 0 ? groupProduct.skus[0].salesPrice : groupProduct.product.salesPrice}
                                      listPrice={groupProduct.skus.length > 0 ? groupProduct.skus[0].listPrice : groupProduct.product.listPrice}
                                      appDesign={props.appDesign}
              />
            </div>
          </div>
          <div className="u-flex u-flex-wrap b-b-line" style={{padding: "0 20px 12px"}}>
            {groupProduct.skus.map((sku, skuIndex) => (
              <div key={skuIndex} className="item-btn" style={{
                backgroundColor: skuIndex === 0 ? colorTable.primary100 : "#f5f5f5",
                border: "solid 1px " + (skuIndex === 0 ? colorTable.primary200 : "#ffffff"),
                color: skuIndex === 0 ? colorTable.primary200 : "#616161"
              }}>
                <img src={sku.skuImage ? sku.skuImage : NoImage} style={{width: 24, height: 24}}/>
                <div className="u-font-24 u-m-l-12"> {sku.optionText}</div>
              </div>
            ))}
          </div>
        </div>
      ))}
      <div className="u-flex u-row-between u-p-40">
        <div style={{color: "#212121"}}>数量</div>
        <Input size="small" style={{width: 100}} addonBefore="+" addonAfter="-" defaultValue="1" />
      </div>
      <div style={{display: "flex", justifyContent: "space-between"}}>
        <StyledButton className="add-btn"
                      setting={{
                        backgroundColor: "#ffffff",
                        border: "1px solid " + colorTable.primary200,
                        color: colorTable.primary200,
                        borderRadius: 4
                      }}
        >
          加入购物车
        </StyledButton>
        <StyledButton className="add-btn"
                      setting={{
                        backgroundColor: colorTable.primary200,
                        color: colorTable.primary000,
                        borderRadius: 4
                      }}
        >
          立即购买
        </StyledButton>
        </div>
    </div>
  )
};

const StyledButton = styled.div`
  ${props => (props.setting)}
`;

export default ProductPreviewSelectSku;