import React, {useEffect, useState} from "react";
import {Spin, Table, Button, message, Modal, Descriptions} from "antd";
import ClearanceApi from "./ClearanceApi";
import Util from "../../../util/Util";
import intl from "react-intl-universal";
import OrderDeclareDetail from "./OrderDeclareDetail";

const SendOrderToPCAgent = (props) => {
  const [updating, setUpdating] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [orderDeclare, setOrderDeclare] = useState([]);
  const [selectedOrderDeclare, setSelectedOrderDeclare] = useState({});
  const [visibleDetailModal, setVisibleDetailModal] = useState(false);

  useEffect(() => {
    loadOrderDeclares(props.order.id);
  }, [props.order.id]);

  const loadOrderDeclares = (orderId) => {
    ClearanceApi.getCurrentOrderDeclare(orderId).then(res => {
      const orderDeclare = res.data;

      if (props.application.declareDirectly) {
        setDisabled(orderDeclare.status !== "created");
      } else {
        setDisabled(true);
      }
      setOrderDeclare(orderDeclare);
    })
  };

  const sendOrderToAgent = () => {
    if(!window.confirm("해관으로 주문정보를 신고합니다.. 계속하시겠습니까?")) {
      return;
    }
    setUpdating(true);
    ClearanceApi.sendOrderDeclareToAgent(props.order.id).then(res => {
      message.info(intl.get("common.message.success"));
      loadOrderDeclares(props.order.id);
    }).catch(e => {
      console.log("sendOrderToAgent err:", e);
    }).finally(() => {
      setUpdating(false);
    });
  };

  return (
    <Spin spinning={updating}>
      <div>
        <div style={{ display: "flex", marginBottom: 10}}>
          <div>
            <div>
              {intl.get("order.clearance.message.send_to_agent")}
            </div>
            {!props.application.declareDirectly && (
              <div>
                {intl.getHTML("order.clearance.message.send_to_agent_help")}
              </div>
            )}
          </div>
          <div style={{marginLeft: "auto"}}>
            <Button type="primary" size="small" disabled={disabled} onClick={sendOrderToAgent}>{intl.get("order.clearance.send")}</Button>
          </div>
        </div>
        <OrderDeclareDetail orderDeclare={orderDeclare}/>
      </div>
    </Spin>
  );
};

export default SendOrderToPCAgent;