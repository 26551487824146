import React, {useEffect, useState} from "react";
import {Button, Divider, Form, Input, message} from 'antd';
import {adminConfig} from "../../config/admin.config";
import CompanyUserApi from "../../pages/company/CompanyUserApi";
import "./Login.less";
import ChinaFlag from "../../asset/images/flag-china.svg";
import KoreaFlag from "../../asset/images/flag-korea.svg";
import LogoImg from "../../asset/images/youma_logo.png";

import intl from 'react-intl-universal';
import urlParse from "url-parse";
import Cookies from "universal-cookie";
import {Link} from "react-router-dom";

const cookies = new Cookies();

const FindPassword = (props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    const loginId = values.loginId;
    const email = values.email;

    setLoading(true);
    CompanyUserApi.findPassword(loginId, email).then(() => {
      alert(intl.get("user.message.sentPasswordMail"));
      window.location = "/login";
    }).catch(error => {
      if (error.response && error.response.status === 500 && error.response.data) {
        message.error(error.response.data);
      } else {
        message.error(intl.get("common.message.contactAdmin"));
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  const handleLanguageClick = (locale) => {
    cookies.set("locale", locale, {path: '/'});
    window.location.reload();
  }

  const {serviceSetting} = props;

  return (
    <div className="login-page" style={{ height: "100vh"}}>
      <div className="header">
        {serviceSetting.loaded && !serviceSetting.company?.id && (
          <div><a href={adminConfig.portalServer()}><img className="logo-img" src={LogoImg}/></a></div>
        )}
      </div>
      <div className={"contents"}>
        <div className={"form-wrapper"}>
          <div className={"title"}>{intl.get("login.findPasswordTitle")}</div>
          <div className={"title-desc"}></div>
          <div className={"form-box"}>
            <Form name="normal_login" initialValues={{remember: true}} onFinish={onFinish} form={form}>
              <div className={"form-item"}>
                <div className={"form-label"}>{intl.get("login.loginId")}</div>
                <Form.Item name="loginId"
                           rules={[{required: true, message: intl.get("common.inputField", {name: intl.get("login.loginId")})}]}>
                  <Input className={"input"}/>
                </Form.Item>
              </div>
              <div className={"form-item"}>
                <div className={"form-label"}>{intl.get("company.user.email")}</div>
                <Form.Item name="email" rules={[{required: true, message: intl.get("common.inputField", {name: intl.get("company.user.email")})}]}>
                  <Input className={"input"}/>
                </Form.Item>
              </div>
              <div>
                <Form.Item>
                  <Link to={"/login"}>
                    <span className="login-form-forgot">{intl.get("login.login")}</span>
                  </Link>
                </Form.Item>
              </div>
            </Form>
            <div className={"btn-wrapper"} style={{marginBottom: 48}}>
              <Button block type="primary" onClick={() => form.submit()} className="login-form-button" loading={loading}>{intl.get("login.findPasswordTitle")}</Button>
            </div>
          </div>
        </div>
      </div>
      <div style={{marginTop:24, display: "flex", justifyContent: "center", alignItems: "center"}}>
        <div><img style={{width: 20, height: 20, marginRight: 8}} src={ChinaFlag}/></div>
        <div style={{fontSize: 14, color: "#616161", marginRight: 17, cursor: "pointer"}}
             onClick={() => handleLanguageClick('zh-CN')}>
          简体中文
        </div>
        <Divider type={"vertical"}/>
        <div style={{marginLeft: 17, marginRight: 8}}><img style={{width: 20, height: 20}} src={KoreaFlag}/></div>
        <div style={{fontSize: 14, color: "#616161", cursor: "pointer"}}
             onClick={() => handleLanguageClick('ko-KR')}>
          한국어
        </div>
      </div>
      <div style={{paddingTop: 50}}>
      </div>
    </div>
  );
};

export default FindPassword;
