import React, {useEffect, useState} from 'react';

import { Link } from 'react-router-dom';
import {Table, Divider, Button, message, Select} from 'antd';
import {HomeFilled, SkinFilled} from "@ant-design/icons";

import '../../App.less';
import Util from "../../util/Util";
import intl from "react-intl-universal";
import ApplicationPageApi from "./ApplicationPageApi";

const ApplicationPageList = (props) => {
  const deleteAppPage = (title, id) => {
    if (!window.confirm(intl.get("common.confirm.delete", {name: title}))) {
      return;
    }
    ApplicationPageApi.deleteAppPage(id).then(res => {
      message.success(intl.get("common.message.deleted"));
      props.loadAppPages();
    })
  };

  const onPageTypeChanged = (value) => {
    const filter = {...props.filter};
    filter.pageType = value === undefined ? "" : value;
    props.setFilter(filter);
  };

  const columns = [{
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    }, {
      title: intl.get("page_setting.pageType"),
      dataIndex: 'pageType',
      key: 'pageType',
      render: (text, record) => {
        return (<span>{intl.get("page_setting.page." + text)}</span>)
      }
    }, {
      title: intl.get("page_setting.title"),
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => {
        if (record.pageType === "index") {
          return (<span><HomeFilled style={{ fontSize: '16px', color: '#08c', marginRight: 10 }}/>{intl.get("page_setting.page." + record.pageType)}</span>)
        } else if (record.pageType === "product") {
          return (<span><SkinFilled style={{ fontSize: '16px', color: '#de723e', marginRight: 10 }}/>{intl.get("page_setting.page." + record.pageType)}</span>)
        } else if (record.pageType === "etc") {
          return (<span>{text}</span>)
        } else {
          return (<span>{intl.get("page_setting.page." + record.pageType)}</span>)
        }
      }
    }, {
      title: intl.get("page_setting.published"),
      dataIndex: 'published',
      key: 'published',
      render: (text, record) => {
        const textLabel = record.published ? "true" : "false";
        return (<span>{textLabel}</span>)
      }
    }, {
      title: intl.get("common.createdAt"),
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: (text, record) => (
          <span>{text ? text.substring(0, 10) : ""}</span>
      )
    }, {
      title: '',
      key: 'action',
      render: (text, record) => {
        if (record.pageType === 'sub') {
          return (
            <div>
              <Link to={`${props.parentPath}/${record.id}/edit`}>{intl.get("common.button.edit")}</Link>
              <Divider type="vertical"/>
              <span style={{cursor: "pointer"}} onClick={() => deleteAppPage(record.title, record.id)}>{intl.get("common.button.delete")}</span>
            </div>
          )
        } else if (record.pageType === 'product') {
          return (<Link to={`${props.parentPath}/${record.id}/product`}>{intl.get("common.button.edit")}</Link>)
        } else if (record.pageType === 'index') {
          return (<Link to={`${props.parentPath}/${record.id}/edit`}>{intl.get("common.button.edit")}</Link>)
        } else if (record.pageType === "category") {
          return (
            <div>
              <Link to={`${props.parentPath}/${record.id}/etc`}>{intl.get("common.button.edit")}</Link>
              <Divider type="vertical"/>
              <Link to={`${props.parentPath}/category-view-setting`}>{intl.get("page_setting.page.category.menu")}</Link>
            </div>
          )
        } else {
          return (<Link to={`${props.parentPath}/${record.id}/etc`}>{intl.get("common.button.edit")}</Link>)
        }
      }
  }];

  return (
      <div>
        <div className="page-op-box">
          <div className="filter-box">
            <Select value={props.filter.pageType ? props.filter.pageType : undefined}
                    style={{width: 200}}
                    allowClear={true}
                    onChange={onPageTypeChanged}
                    placeholder={"패이지 타입"}
            >
              <Select.Option key={"default"} value={"default"}>기본 페이지</Select.Option>
              <Select.Option key={"sub"} value={"sub"}>서브페이지(행사 등)</Select.Option>
            </Select>
            <div style={{marginLeft: "auto", display: "flex"}}>
              <div style={{width: 100}}>
                <Button style={{width: 100}} type="primary" block onClick={props.loadAppPages}>{intl.get("common.button.search")}</Button>
              </div>
              <Divider type="vertical"/>
              <div style={{width: 100}}>
                <Link to={`${props.parentPath}/new`}>
                  <Button style={{width: 100}} block type="primary">{intl.get("common.button.add")}</Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div style={{marginTop: "10px"}}>
          <Table size={'small'}
                 dataSource={props.appPages}
                 columns={columns}
                 rowKey={'id'}
                 pagination={false}
          />
        </div>
      </div>
  )
}

export default ApplicationPageList;