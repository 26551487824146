import React, {useEffect, useState} from "react";
import intl from "react-intl-universal";
import {Button, Input, Table, Divider, Spin, DatePicker, message} from "antd";
import {Link} from "react-router-dom";
import ApplicationPageApi from "../app_page/ApplicationPageApi";

const ShopComponentList = (props) => {
  const [loading, setLoading] = useState(false);

  const pageChanged = (pagination) => {
    props.searchAppPageComponents(pagination);
  };

  const deleteAppPage = (title, id) => {
    if (!window.confirm(intl.get("common.confirm.delete", {name: title}))) {
      return;
    }
    ApplicationPageApi.deleteAppPage(id).then(res => {
      message.success(intl.get("common.message.deleted"));
      props.loadAppPageComponents();
    })
  };

  const columns = [{
    title: "ID",
    dataIndex: 'id',
    key: 'id',
    align: 'center',
  }, {
    title: intl.get("page_setting.edit.pageTitle.component"),
    dataIndex: 'title',
    key: 'title',
  }, {
    title: intl.get("page_setting.published"),
    dataIndex: 'published',
    key: 'published',
    render: (text, record) => {
      const textLabel = record.published ? "true" : "false";
      return (<span>{textLabel}</span>)
    }
  }, {
    title: intl.get("common.createdAt"),
    dataIndex: 'createdAt',
    key: 'createdAt',
    align: 'center',
    render: (text, record) => (
      <span>{text ? text.substring(0, 10) : ""}</span>
    )
  }, {
    title: "",
    dataIndex: 'id',
    key: 'action',
    render: (text, record) => {
      return (
        <>
          <Link to={`${props.parentPath}/${record.id}/edit`}>
            <Button type="default" size="small">{intl.get("common.button.edit")}</Button>
          </Link>
          <Divider type={"vertical"}/>
          <Button type="default" size="small" onClick={() => deleteAppPage(record.title, record.id)}>{intl.get("common.button.delete")}</Button>
        </>
      )
    }
  }];

  return (
    <Spin spinning={loading}>
      <div className="page-op-box">
        <div className="filter-box">
          <div style={{marginLeft: "auto", display: "flex"}}>
            <div style={{width: 100}}>
              <Button style={{width: 100}} type="primary" block onClick={props.loadAppPageComponents}>{intl.get("common.button.search")}</Button>
            </div>
            <Divider type="vertical"/>
            <div style={{width: 100}}>
                <Link to={`${props.parentPath}/new`}>
                  <Button style={{width: 100}} block type="primary">{intl.get("common.button.add")}</Button>
                </Link>
              </div>
          </div>
        </div>
      </div>
      <div style={{marginTop: "10px"}}>
        <Table
          size={'small'}
          dataSource={props.appPages}
          columns={columns}
          rowKey={'id'}
          title={() => intl.get("common.label.listTotal", {total: props.pagination.total})}
          pagination={props.pagination}
          onChange={pageChanged}
        />
      </div>
    </Spin>
  )
};

export default ShopComponentList;