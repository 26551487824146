import {adminConfig} from "../../config/admin.config";
import { HttpUtil } from '../../util/HttpUtil';
import axios from "axios";

const API_BASE_URL = adminConfig.apiServer();

export default class CategoryApi {
  static getCategories = (options) => {
    let apiPath = `${API_BASE_URL}/categories?projection=webAdmin&`;

    if (options) {
      apiPath += Object.keys(options).map(key => {
        return key + "=" + options[key]
      }).join("&");
    }

    return axios.get(apiPath);
  };

  static getCategoriesByApp = (options) => {
    let apiPath = `${API_BASE_URL}/categories/app?projection=webAdmin&`;

    if (options) {
      apiPath += Object.keys(options).map(key => {
        return key + "=" + options[key]
      }).join("&");
    }

    return axios.get(apiPath);
  };

  static saveAppCategories = (categoryIds) => {
    let apiPath = `${API_BASE_URL}/categories/app?projection=webAdmin&`;

    return axios.post(apiPath, categoryIds);
  };

  static submitCategory = (category) => {
    let apiPath = `${API_BASE_URL}/categories`;
    if (category.id) {
      apiPath = `${API_BASE_URL}/categories/${category.id}`;
    }

    return fetch(apiPath, { method: 'post', body: JSON.stringify(category), headers: HttpUtil.getHeader() })
        .then(HttpUtil.handleHttpStatus)
        .then(res => res.json())
        .catch(HttpUtil.handleHttpStatusError)
  };

  static changeSeq = (option) => {
    let apiPath = `${API_BASE_URL}/categories/seq`;

    return fetch(apiPath, { method: 'put', body: JSON.stringify(option), headers: HttpUtil.getHeader() })
        .then(HttpUtil.handleHttpStatus)
        .then(res => res.json())
        .catch(HttpUtil.handleHttpStatusError)
  }

  static deleteCategory = (id) => {
    let apiPath = `${API_BASE_URL}/categories/${id}`;

    return fetch(apiPath, { method: 'delete', headers: HttpUtil.getHeader() })
        .then(HttpUtil.handleHttpStatus)
        .then(res => res.json())
        .catch(HttpUtil.handleHttpStatusError)
  }
}