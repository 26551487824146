import React, {useEffect, useState} from "react";
import {Descriptions, Input, InputNumber, Radio, Switch} from "antd";
import intl from "react-intl-universal";

const TextSetting = (props) => {
  const [settingValues, setSettingValues] = useState({});
  useEffect(() => {
    setSettingValues({...props.settingValues});
  }, [props.settingValues]);

  const onValueChanged = (fieldName, value) => {
    const settingValuesState = {...settingValues};
    settingValuesState[fieldName] = value;
    setSettingValues(settingValuesState);
    props.applySetting(props.id, settingValuesState);
  }

  // 처음 component mount 될때는 color, backgroundColor  값이 없는데 이때 <inout type=color>의 경우 값이 undefined 인 경우
  // A component is changing an uncontrolled input of type text to be controlled error in ReactJS 경고 발생
  // https://stackoverflow.com/questions/47012169/a-component-is-changing-an-uncontrolled-input-of-type-text-to-be-controlled-erro
  return (
      <div>
        <Descriptions title={intl.get("page_setting.edit.text.setting")}
                      bordered size={"small"} column={1}>
          <Descriptions.Item label={intl.get("page_setting.edit.text")}>
            <Input value={settingValues.text} onChange={(e) => onValueChanged("text", e.target.value)} />
          </Descriptions.Item>
          <Descriptions.Item label={intl.get("page_setting.edit.text.fontSize")}>
            <InputNumber value={settingValues.fontSize} min={1} max={100} defaultValue={16} onChange={(value) => onValueChanged("fontSize", value)} />
          </Descriptions.Item>
          <Descriptions.Item label={intl.get("application.design.fontWeight")}>
            <Switch checked={settingValues.fontBold} onClick={(checked) => onValueChanged("fontBold", checked)} />
          </Descriptions.Item>
          <Descriptions.Item label={intl.get("page_setting.edit.text.textAlign")}>
            <Radio.Group value={settingValues.textAlign} onChange={(e) => onValueChanged("textAlign", e.target.value)}>
              <Radio value={"left"}>{intl.get("page_setting.edit.text.textAlign.left")}</Radio>
              <Radio value={"center"}>{intl.get("page_setting.edit.text.textAlign.center")}</Radio>
              <Radio value={"right"}>{intl.get("page_setting.edit.text.textAlign.right")}</Radio>
            </Radio.Group>
          </Descriptions.Item>
          <Descriptions.Item label={intl.get("page_setting.edit.text.padding")}>
            <Input value={settingValues.padding} onChange={(e) => onValueChanged("padding", e.target.value)} />
            {intl.get("page_setting.edit.text.padding.help")}
          </Descriptions.Item>
          <Descriptions.Item label={<div style={{width: '150px'}}>{intl.get("page_setting.edit.text.color")}</div>}>
            <span><input type="color" value={settingValues.color ? settingValues.color : "#000000"} onChange={e => onValueChanged("color", e.target.value)} /></span>
            <span style={{marginLeft: 10}}>{settingValues.color}</span>
          </Descriptions.Item>
          <Descriptions.Item label={<div style={{width: '150px'}}>{intl.get("page_setting.edit.text.backgroundColor")}</div>}>
            <span><input type="color" value={settingValues.backgroundColor ? settingValues.backgroundColor : "#ffffff"} onChange={e => onValueChanged("backgroundColor", e.target.value)} /></span>
            <span style={{marginLeft: 10}}>{settingValues.backgroundColor}</span>
          </Descriptions.Item>
        </Descriptions>
      </div>
  )
};

export default TextSetting;