import React from 'react';

const DashboardCard = (props) => {
  const cardStyle = {backgroundColor: "white", border: "1px solid #dbdde2", height: "100%"};
  const contentStyle = {padding: "10px"};

  if (props.height) {
    contentStyle.height = props.height;
  } else {
    contentStyle.height = "100%";
  }
  return (
    <div style={cardStyle}>
      <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", padding: "5px 10px"}}>
        <div style={{fontSize: "18px", fontWeight: "600", color: "#303236", paddingTop: "5px"}}>{props.title}</div>
        { props.extra && (<div>{(props.extra)}</div>)}
      </div>
      <div style={contentStyle}>
        {(props.children)}
      </div>
    </div>
  );
}

export default DashboardCard;