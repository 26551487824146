import React, {useEffect, useState} from 'react';
import LoginInfo from "./LoginInfo";
import {Layout, Dropdown, Menu, Alert} from "antd";
import {useLayoutDispatch, useLayoutState} from "./AppLayoutContext";
import {CaretDownOutlined} from "@ant-design/icons";
import intl from "react-intl-universal";
import {useHistory} from "react-router-dom";
import QuestionNewModal from "../../pages/question/QuestionNewModal";
import NoticeApi from "../../pages/notice/NoticeApi";
import NoticeModal from "../../pages/notice/NoticeModal";
import ExchangeRate from "./ExchangeRate";

const Header = (props) => {
  const history = useHistory();
  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const [visibleQuestionModal, setVisibleQuestionModal] = useState(false);
  const [topNotice, setTopNotice] = useState({});
  const [visibleNoticeModal, setVisibleNoticeModal] = useState(false);

  useEffect(() => {
    NoticeApi.getNotices({pageSize: 5, page: 1}).then(res => {
      const notices = res.data.data;
      if (notices.length > 0 && notices[0].pinToTop) {
        setTopNotice(notices[0]);
      }
    });
  }, []);

  let breadcrumbItems = layoutState.breadcrumbItems;
  if (breadcrumbItems && breadcrumbItems.length >= 1) {
    // breadcrumbItems = breadcrumbItems.slice(1, breadcrumbItems.length);
  }

  const handleLanguageClick = ({key}) => {
    layoutDispatch({type: 'CLICK_LANGUAGE', key});
  }

  const handleHelCenterClick = ({key}) => {
    if (key === "question") {
      // history.push("/questions");
      setVisibleQuestionModal(true);
    } else if (key === "guide") {
      history.push("/guide");
    } else if (key === "notice") {
      history.push("/notices");
    }
  };

  const onClickNotice = () => {
    setVisibleNoticeModal(true);
  };

  return (
    <Layout.Header className="site-layout-header">
      <div style={{backgroundColor: "white", padding: "0 15px"}}>
        {topNotice.id && (
          <div style={{cursor: "pointer"}} onClick={onClickNotice}><Alert banner message={topNotice.title}/></div>
        )}
      </div>
      <div className={"gnb-right"}>
        <div className="exchange-rate">
          <ExchangeRate {...props} />
        </div>
        <div className="login-info">
          <LoginInfo {...props} />
        </div>
        <div className="select-language">
          <Dropdown menu={{
            items: [
              {key: "zh-CN", label: "简体中文"},
              {key: "ko-KR", label: "한국어"}
            ],
            selectable: true,
            selectedKeys: [layoutState.language],
            onClick: handleLanguageClick,
          }}>
            <div>{layoutState.language === "zh-CN" ? "简体中文" : "한국어"} <CaretDownOutlined /></div>
          </Dropdown>
        </div>
        <div className="select-language">
          <Dropdown menu={{
            items: [
              {key: "question", label: intl.get("menu.help-center.question")},
              {key: "notice", label: intl.get("menu.help-center.guide")},
              {key: "guide", label: intl.get("menu.help-center.guide")}
            ],
            onClick: handleHelCenterClick,
          }}>
            <div>{intl.get("menu.help-center")} <CaretDownOutlined /></div>
          </Dropdown>
        </div>
      </div>
      {visibleQuestionModal && (
        <QuestionNewModal handleCancel={() => setVisibleQuestionModal(false)}/>
      )}
      {visibleNoticeModal && (
        <NoticeModal notice={topNotice} handleCancel={() => setVisibleNoticeModal(false)}/>
      )}
    </Layout.Header>
  );
}

export default Header;
