import Util from "../../../../util/Util";
import React from "react";

export const PriceView = (props) => {
  const p1Size = '10px';
  const p2Size = '14px';
  const p3Size = '16px';
  const p4Size = '10px';

  const {listPrice, salesPrice, application, applicationDesign} = props;
  const {themeColor} = applicationDesign.themeSetting;
  return (
    <div className="price-box" style={{width: props.width}}>
      <div className="price-1" style={{fontSize: p1Size}}>
        {Util.currencyFormat(listPrice.paymentPrice, application.paymentRequestCurrency)}
      </div>
      <div style={{display: "flex", alignItems: "flex-end"}}>
        <div style={{display: "flex", alignItems: "flex-end", letterSpacing: -1}}>
          <div className="price-2" style={{
            color: themeColor.colorTable.price,
            fontSize: p2Size
          }}>{Util.getSymbol(application.userPayCurrency)}</div>
          <div className="price-3" style={{
            color: themeColor.colorTable.price,
            fontSize: p3Size
          }}>{Util.numberFormat(salesPrice.secondaryPrice)}</div>
        </div>
        <div className="price-4" style={{
          fontSize: p4Size,
          letterSpacing: -1,
          paddingBottom: 3
        }}>{Util.currencyFormat(salesPrice.paymentPrice, application.paymentRequestCurrency)}</div>
      </div>
    </div>
  )
};