import { adminConfig } from "../../config/admin.config";
import { HttpUtil } from '../../util/HttpUtil';
import axios from "axios";

const API_BASE_URL = adminConfig.apiServer();

export default class ApplicationLogisticsCompanyApi {
  static getApplicationLogistics = (options) => {
    let apiPath = `${API_BASE_URL}/application-logistics-companies?projection=webAdmin&`;

    if (options) {
      apiPath += Object.keys(options).map(key => {
        return key + "=" + options[key]
      }).join("&");
    }

    return axios.get(apiPath);
  };

  static createApplicationLogistics = (logisticsCompanies) => {
    const apiPath = `${API_BASE_URL}/application-logistics-companies?projection=webAdmin`;
    return axios.post(apiPath, logisticsCompanies);
  };

  static deleteApplicationLogistics = (ids) => {
    let apiPath = `${API_BASE_URL}/application-logistics-companies?projection=webAdmin&ids=${ids}`;
    return axios.delete(apiPath);
  };
}