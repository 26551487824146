import React, { useState, useEffect } from "react";
import { Button, Divider, Input, Modal, Select, Tag, Radio, Spin, message } from "antd";
import intl from "react-intl-universal";
import { BookFilled } from '@ant-design/icons';
import OrderApi from "./OrderApi";

const MarkerModal = (props) => {
  const [orderId, setOrderId] = useState();
  const [managerMarkerColor, setManagerMarkerColor] = useState("");
  const [managerMarker, setManagerMarker] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.order.id) {
      setOrderId(props.order.id);
      setManagerMarkerColor(props.order.managerMarkerColor);
      setManagerMarker(props.order.managerMarker);
    }
  }, [props.order.id]);

  const handleOk = () => {
    setLoading(true);
    const params = {
      id: orderId,
      managerMarker: managerMarker,
      managerMarkerColor: managerMarkerColor
    };
    // console.log('params :>> ', params);
    OrderApi.saveMarker(orderId, params).then(res => {
      message.info(intl.get("common.message.saved"));
      props.handleOk(orderId, managerMarker, managerMarkerColor);
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <Modal
      visible={true}
      title={intl.get("order.addManagerMarker")}
      onCancel={props.handleCancel}
      onOk={handleOk}
      width={"480px"}
      cancelText={intl.get("common.button.close")}
      okText={intl.get("common.button.add")}
      confirmLoading={loading}
    >
      <div>
        <Radio.Group value={managerMarkerColor} onChange={(e) => setManagerMarkerColor(e.target.value)}>
          {props.colorSet.map((color, index) => (
            <Radio key={index} value={color}>
              {color ? <BookFilled style={{ color: color, fontSize: 16 }} /> : <span style={{ color: color, fontSize: 16 }}>{intl.get("common.button.delete")}</span>}
            </Radio>
          ))}
        </Radio.Group>
        <div style={{ marginTop: 10 }}>
          <Input.TextArea rows={7} value={managerMarker} onChange={e => setManagerMarker(e.target.value)} />
        </div>
      </div>
    </Modal>
  );
};

export default MarkerModal;