import React, {useEffect, useState, useCallback} from 'react';
import {Button, Input, Space, Spin, message, Pagination} from 'antd';
import intl from "react-intl-universal";
import ReplyApi from "./ReplyApi";
import ReplyItem from "./ReplyItem";
import ProductDetailViewPopup from "../product/ProductDetailViewPopup";
import AddReplyPopup from "./AddReplyPopup";
import ProductApi from "../product/ProductApi";
import OrderApi from "../order/OrderApi";

const initialPagination = {
  current: 1,
  pageSize: 20,
  total: 0,
}

const ReplyListView = (props) => {
  const [replies, setReplies] = useState([]);
  const [pagination, setPagination] = useState(initialPagination);
  const [filter, setFilter] = useState({
    productId: "",
    orderId: "",
    userId: "",
  });
  const [loading, setLoading] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(0);
  const [visibleProductPopup, setVisibleProductPopup] = useState(false);
  const [visibleAddReplyPopup, setVisibleAddReplyPopup] = useState(false);
  const [products, setProducts] = useState([]);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    searchReplies(pagination);
  }, []);

  const showProductDetail = (productId) => {
    setSelectedProductId(productId);
    setVisibleProductPopup(true);
  };

  const searchReplies = (pagination) => {
    setLoading(true);
    ReplyApi.getReplies(getSearchParam(pagination)).then(res => {
      const pagingResult = res.data;
      const pagination = {
        current: pagingResult.page,
        pageSize: pagingResult.pageSize,
        total: pagingResult.count,
      }
      setReplies(pagingResult.data);
      getReplyProducts(pagingResult.data);
      getReplyOrders(pagingResult.data);
      setPagination(pagination);
    }).finally(() => {
      setLoading(false);
    });
  };

  const getReplyProducts = (repliesRes) => {
    const productIds = repliesRes.filter(r => r.productId > 0).map(r => r.productId);
    if (productIds.length === 0) {
      return;
    }
    ProductApi.getProducts({id: productIds, pageSize: productIds.length}).then(res => {
      setProducts(res.data.data);
    });
  };

  const getReplyOrders = (repliesRes) => {
    const orderIds = repliesRes.filter(r => r.orderId > 0).map(r => r.orderId);
    if (orderIds.length === 0) {
      return;
    }
    OrderApi.getOrders({id: orderIds, pageSize: orderIds.length}).then(res => {
      setOrders(res.data.data);
    });
  };
  const getSearchParam = (pagination) => {
    const params = {
      pageSize: pagination.pageSize,
      page: pagination.current,
      ...filter
    };
    return params;
  };

  const onClickSearch = () => {
    const paginationState = {...pagination};
    paginationState.current = 1;
    setPagination(paginationState);
    searchReplies(pagination);
  }

  const replyTableChanged = (pagination) => {
    searchReplies(pagination);
  }

  const onChangeProductId = useCallback((e) => {
    const filterState = {...filter};
    filterState.productId = e.target.value;
    setFilter(filterState);
  }, [filter]);

  const onChangeOrderId = (e) => {
    const filterState = {...filter};
    filterState.orderId = e.target.value;
    setFilter(filterState);
  };

  const onChangeUserId = (e) => {
    const filterState = {...filter};
    filterState.userId = e.target.value;
    setFilter(filterState);
  };

  const setReply = (reply) => {
    setReplies(replies.map(r => {
      if (r.id === reply.id) {
        return reply;
      } else {
        return r;
      }
    }));
  };

  const onPageChanged = (current, pageSize) => {
    const paginationState = {...pagination};
    paginationState.current = current;
    paginationState.pageSize = pageSize;
    searchReplies(paginationState);
  };
  const onClickAdd = () => {
    setVisibleAddReplyPopup(true)
  };

  const deleteReply = (replyId) => {
    ReplyApi.deleteReply(replyId).then(res => {
      message.info(intl.get("common.message.deleted"));
      searchReplies(pagination);
    });
  };

  return (
    <Spin spinning={loading}>
      <div className="page-op-box">
        <div className="filter-box">
          <Space>
            <Input value={filter.productId}
                   onChange={onChangeProductId}
                   style={{ width: 100, marginRight: 10 }}
                   placeholder={intl.get("reply.productId")}
                   onPressEnter={onClickSearch}
            />
            <Input value={filter.orderId}
                   onChange={onChangeOrderId}
                   style={{ width: 100, marginRight: 10 }}
                   placeholder={intl.get("reply.orderId")}
                   onPressEnter={onClickSearch}
            />
            <Input value={filter.userId}
                   onChange={onChangeUserId}
                   style={{ width: 100, marginRight: 10 }}
                   placeholder={intl.get("reply.userId")}
                   onPressEnter={onClickSearch}
            />
          </Space>
          <div style={{marginLeft: "auto"}}>
            <div style={{width: 100}}>
              <Button style={{width: 100}} type="primary" block size="default" onClick={onClickSearch}>{intl.get("common.button.search")}</Button>
            </div>
          </div>
        </div>
        <div className="extra-op-box">
          <Button type="default" size="small" style={{ width: 60 }} onClick={onClickAdd}>{intl.get("common.button.add")}</Button>
        </div>
      </div>
      <div style={{marginTop: "10px"}}>
        Total: {pagination.total}
      </div>
      <div style={{marginTop: "10px"}}>
        {replies.map((reply, index) => (
          <ReplyItem key={index}
                     reply={reply}
                     deleteReply={deleteReply}
                     products={products}
                     orders={orders}
                     setReply={setReply}
                     showProductDetail={showProductDetail}
          />
        ))}
      </div>
      <div style={{width: "100%", textAlign: "center"}}>
        <Pagination
          onChange={onPageChanged}
          {...pagination}
        />
      </div>
      { visibleProductPopup && (
        <ProductDetailViewPopup productId={selectedProductId}
                                onOk={() => setVisibleProductPopup(false)}
                                onCancel={() => setVisibleProductPopup(false)}
        />
      )}
      {visibleAddReplyPopup && (
        <AddReplyPopup  onOk={() => setVisibleAddReplyPopup(false)}
                        onCancel={() => setVisibleAddReplyPopup(false)}
                        refreshReply={() => onClickSearch()}
        />
      )}
    </Spin>
  )
};

export default ReplyListView;