import React, {useEffect, useState} from "react";
import Util from "../../util/Util";
import CommonApi from "../common/CommonApi";
import {themeColorSet} from "../../components/layout/DefaultMiniAppDesign";

const ProductDetailPriceView = (props) => {
  const p1Size = '10px';
  const p2Size = '14px';
  const p3Size = '16px';
  const p4Size = '10px';

  const [listPriceUser, setListPriceUser] = useState(0);
  const [salesPriceUser, setSalesPriceUser] = useState(0);
  const [exchangeRate, setExchangeRate] = useState(1);
  const [colorTable, setColorTable] = useState(themeColorSet.theme1);

  useEffect(() => {
    if (props.appDesign.themeSetting) {
      setColorTable(props.appDesign.themeSetting.themeColor.colorTable);
    }
  }, [props.appDesign.themeSetting]);

  useEffect(() => {
    loadExchangeRate();
  }, []);

  useEffect(() => {
    if (props.listPrice) {
      setListPriceUser(Util.toFixed2(props.listPrice * exchangeRate));
    }
    if (props.salesPrice) {
      setSalesPriceUser(Util.toFixed2(props.salesPrice * exchangeRate));
    }
  }, [exchangeRate, props.listPrice, props.salesPrice]);

  const loadExchangeRate = () => {
    const params = {
      baseCurrency: props.application.productCurrency,
      exchangeCurrency: props.application.userPayCurrency,
    }
    CommonApi.getExchangeRate(params).then(res => {
      setExchangeRate(res.data.rate);
    });
  }

  const {listPrice, salesPrice, application, appDesign} = props;

  const mainCurrency = appDesign.priceDisplaySetting?.mainCurrency === "product" ? application.productCurrency : application.userPayCurrency;
  const secondaryCurrency = appDesign.priceDisplaySetting?.secondaryCurrency === "product" ? application.productCurrency : application.userPayCurrency;

  const listPriceMain = appDesign.priceDisplaySetting?.mainCurrency === "product" ? listPrice : listPriceUser;
  const listPriceSecondary = appDesign.priceDisplaySetting?.secondaryCurrency === "product" ? listPrice : listPriceUser;
  const salesPriceMain = appDesign.priceDisplaySetting?.mainCurrency === "product" ? salesPrice : salesPriceUser;
  const salesPriceSecondary = appDesign.priceDisplaySetting?.secondaryCurrency === "product" ? salesPrice : salesPriceUser;

  return (
    <div className="price-box" style={{width: props.width}}>
      <div className="price-1" style={{fontSize: p1Size}}>
        {Util.currencyFormat(listPriceMain, mainCurrency)}
      </div>
      <div style={{display: "flex", alignItems: "flex-end"}}>
        <div style={{display: "flex", alignItems: "flex-end", letterSpacing: -1}}>
          <div className="price-2" style={{fontSize: p2Size, color: colorTable.price}}>{Util.getSymbol(mainCurrency)}</div>
          <div className="price-3" style={{fontSize: p3Size, color: colorTable.price}}>{Util.numberFormat(salesPriceMain)}</div>
        </div>
        <div className="price-4" style={{
          fontSize: p4Size,
          letterSpacing: -1,
        }}>{Util.currencyFormat(salesPriceSecondary, secondaryCurrency)}</div>
      </div>
    </div>
  )
};

export default ProductDetailPriceView;