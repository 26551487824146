import React, {useEffect, useState} from 'react';
import {Button, Descriptions, Divider, Input, message, Radio, Tooltip} from "antd";
import BannerSetting from "./BannerSetting";
import PopupSetting from "./PopupSetting";
import ProductSetting from "./ProductSetting";
import ImageSetting from "./ImageSetting";
import SearchSetting from "./SearchSetting";
import TextSetting from "./TextSetting";
import LineSetting from "./LineSetting";
import ToolbarSetting from "./ToolbarSetting";

import Preview from "./index_preivew/Preview";
import ApplicationPageApi from "./ApplicationPageApi";
import intl from "react-intl-universal";
import {
  CloseCircleOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  CaretDownOutlined,
  CaretUpOutlined
} from "@ant-design/icons";
import {Link} from "react-router-dom";
import FileListPopup from "../file_manager/FileListPopup";
import CommonApi from "../common/CommonApi";
import CategoryApi from "../category/CategoryApi";
import BrandApi from "../brand/BrandApi";
import {useLayoutState} from "../../components/layout/AppLayoutContext";
import {useHistory} from "react-router-dom";
import ApplicationPreviewFrame from "../application/ApplicationPreviewFrame";
import ApplicationApi from "../application/ApplicationApi";

import IconSetting from "../../asset/images/page_icon_setting.png";
import IconBanner from "../../asset/images/page_icon_banner.png";
import IconImage from "../../asset/images/page_icon_img.png";
import IconVideo from "../../asset/images/page_icon_video.png";
import IconProduct from "../../asset/images/page_icon_product.png";
import IconLine from "../../asset/images/page_icon_line.png";
import IconSearch from "../../asset/images/page_icon_search.png";
import IconText from "../../asset/images/page_icon_text.png";
import IconPopup from "../../asset/images/page_icon_popup.png";
import ToolbarPopup from "../../asset/images/page_icon_toolbar.png";
import {defaultAppDesign} from "../../components/layout/DefaultMiniAppDesign";

import "./ApplicationPageEidt.less";

const { Search } = Input;

const defaultComponentSettingValues = {
  line: {
    height: 10,
    backgroundColor: "#f2f2f2",
  },
  text: {
    height: 40,
    text: '',
    color: '#000000',
    backgroundColor: '#ffffff',
    fontSize: "18",
    textAlign: "left",
    padding: "10,5,10,5",
  },
  search: {
    backgroundColor: "#ffffff",
    borderColor: "#9e9e9e",
    inputBackgroundColor: "#ffffff"
  },
  popup: {
    height: 300,
    slot: {
      action: "product",
      bannerMediaType: "image",
    },
  },
  banner: {
    height: 300,
    displayType: "swiper",
    slots: [],
  },
  image: {
    image: "",
    height: 200
  },
  product: {
    height: 300,
    slotType: "product_2",
    productType: "categoryId",
    numProducts: 10,
    padding: "10,0,10,0",
    sort: "latest"
  }
};

const PageDesigner = (props) => {
  const history = useHistory();
  const layoutState = useLayoutState();
  const [appPage, setAppPage] = useState({});
  const [applicationDesign, setApplicationDesign] = useState(defaultAppDesign);
  const [pageItems, setPageItems] = useState([]);
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [exchangeRate, setExchangeRate] = useState({});
  const [pageItemsVer, setPageItemsVer] = useState(0);
  const [selectedPageItem, setSelectedPageItem] = useState({});
  const [maxItemId, setMaxItemId] = useState(0);
  const [showSettingInfo, setShowSettingInfo] = useState(true);
  const [fileModalVisible, setFileModalVisible] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [pageBasicInfo, setPageBasicInfo] = useState({});
  const [focusedMenu, setFocusedMenu] = useState(-1);

  const getMenuItems = () => {
    let menuItems = [{
      image: IconSetting,
      title: intl.get("page_setting.edit.icon.setting"),
      onClick: () => setShowSettingInfo(true),
      focused: false,
    }, {
      image: IconSearch,
      title: intl.get("page_setting.edit.icon.search"),
      onClick: () => onAddPageItem('search'),
      focused: false,
    }, {
      image: IconBanner,
      title: intl.get("page_setting.edit.icon.banner"),
      onClick: () => onAddPageItem('banner'),
      focused: false,
    }, {
      image: IconImage,
      title: intl.get("page_setting.edit.icon.image"),
      onClick: () => onAddPageItem('image'),
      focused: false,
    }, {
      image: IconVideo,
      title: intl.get("page_setting.edit.icon.video"),
      onClick: () => onAddPageItem('image'),
      focused: false,
    }, {
      image: IconProduct,
      title: intl.get("page_setting.edit.icon.product"),
      onClick: () => onAddPageItem('product'),
      focused: false,
    }, {
      image: IconText,
      title: intl.get("page_setting.edit.icon.text"),
      onClick: () => onAddPageItem('text'),
      focused: false,
    }, {
      image: IconLine,
      title: intl.get("page_setting.edit.icon.line"),
      onClick: () => onAddPageItem('line'),
      focused: false,
    }];

    if (props.pageType !== "component") {
      menuItems = menuItems.concat([{
        image: ToolbarPopup,
        title: intl.get("page_setting.edit.icon.toolbar"),
        onClick: () => onAddPageItem('toolbar'),
        focused: false,
      }, {
        image: IconPopup,
        title: intl.get("page_setting.edit.icon.popup"),
        onClick: () => onAddPageItem('popup'),
        focused: false,
      }])
    }
    return menuItems;
  };

  useEffect(() => {
    if (props.match.params.pageId) {
      // 수정인 경우
      loadAppPage(props.match.params.pageId);
    } else {
      // 신규추가인 경우
      const defaultPageApp = {
        pageType: props.pageType,
        published: false,
      };
      setAppPage(defaultPageApp);
      initPageTitle(defaultPageApp);
    }
    loadApplicationDesign();
    loadCategories();
    loadBrands();
  }, [props.match.params.pageId]);

  const loadAppPage = (pageId) => {
    ApplicationPageApi.getAppPage(pageId).then(res => {
      const appPage = res.data;
      initPageTitle(appPage);

      const pageItemsRes = appPage.editingSettings ? appPage.editingSettings : [];

      let maxItemId = 0;
      pageItemsRes.forEach(item => {
        if (item.id > maxItemId) {
          maxItemId = item.id;
        }
      });

      setPageItems(pageItemsRes);
      setMaxItemId(maxItemId);
      setAppPage(appPage);
    });
  };

  const initPageTitle = (appPage) => {
    let pageTitle = "";
    let previewTitle = "";
    if (appPage.pageType === "index") {
      previewTitle = layoutState.application.appTitle;
      pageTitle = intl.get("page_setting.page." + appPage.pageType)
    } else if (appPage.pageType === "component") {
      previewTitle = "";
      pageTitle = appPage.title;
    } else if (appPage.pageType === "sub") {
      previewTitle = appPage.title;
      pageTitle = appPage.title;
    }
    setPageBasicInfo({
      pageTitle: pageTitle,
      previewTitle: previewTitle,
    });
  }

  const loadApplicationDesign = () => {
    ApplicationApi.getApplicationDesign().then(res => {
      setApplicationDesign(res.data);
    });
  }

  const loadCategories = () => {
    CategoryApi.getCategoriesByApp().then(res => {
      setCategories(res.data);
    });
  };

  const loadBrands = () => {
    BrandApi.getBrands().then(res => {
      setBrands(res.data);
    });
  };

  const onAddPageItem = (itemType) => {
    // 좌측 아이콘 클릭 시
    const pageItemsState = [...pageItems];

    if (itemType === 'popup') {
      const popupItem = pageItemsState.find(item => item.type === 'popup');
      if (popupItem) {
        setPopupVisible(true);
        setSelectedPageItem(popupItem);
        return;
      }
    }

    const pageItem = {
      id: maxItemId + 1,
      type: itemType,
      settingValues: {},
    };

    if (defaultComponentSettingValues[itemType]) {
      pageItem.settingValues = {...defaultComponentSettingValues[itemType]};
    }

    // 현재 선택된 아이템 다음에 추가 한다.
    let pageItemsIncludeAdded = [];
    if (selectedPageItem?.id) {
      const index = pageItemsState.findIndex(item => {
        return item.id === selectedPageItem.id;
      });
      if (index >= 0) {
        pageItemsState.splice(index + 1, 0, pageItem);
        pageItemsIncludeAdded = pageItemsState;
      } else {
        pageItemsIncludeAdded = [pageItem].concat(pageItemsState);
      }
    } else {
      pageItemsIncludeAdded = [pageItem].concat(pageItemsState);
    }

    setPageItems(pageItemsIncludeAdded);
    setMaxItemId(maxItemId + 1);
    if (pageItemsIncludeAdded.length === 1) {
      setSelectedPageItem(pageItem);
    }
    setPageItemsVer(pageItemsVer + 1);
    setShowSettingInfo(false);

    if (itemType === "popup") {
      setPopupVisible(true);
    }
  };

  const applySetting = (id, settingValues) => {
    const pageItemsState = [...pageItems];
    const changedPageItems = pageItemsState.map(item => {
      if (item.id === id) {
        item.settingValues = settingValues;
      }
      return item;
    });
    setPageItems(changedPageItems);
    setPageItemsVer(pageItemsVer + 1);
  };

  const showSetting = (id) => {
    const pageItemsState = JSON.parse(JSON.stringify(pageItems));
    const pageItem = pageItemsState.find(item => item.id === id);

    if (!pageItem) {
      message.error(intl.get("page_setting.message.cannotFindSetting"));
      return;
    }
    setSelectedPageItem(pageItem);
    setShowSettingInfo(false);
  };

  const moveUp = (id) => {
    const pageItemsState = [...pageItems];
    let itemIndex = -1;
    pageItemsState.forEach((item, index) => {
      if (item.id === id) {
        itemIndex = index;
      }
    });

    if (itemIndex < 0) {
      message.error(intl.get("page_setting.message.cannotFindItem"));
      return;
    }

    if (itemIndex === 0) {
      return;
    }

    const targetPageItem = pageItemsState[itemIndex];
    pageItemsState[itemIndex] = pageItemsState[itemIndex - 1];
    pageItemsState[itemIndex - 1] = targetPageItem;

    setPageItems(pageItemsState);
    setPageItemsVer(pageItemsVer + 1);
  };

  const moveDown = (id) => {
    const pageItemsState = [...pageItems];
    let itemIndex = -1;
    pageItemsState.forEach((item, index) => {
      if (item.id === id) {
        itemIndex = index;
      }
    });

    if (itemIndex < 0) {
      message.error(intl.get("page_setting.message.cannotFindItem"));
      return;
    }

    if (itemIndex === (pageItemsState.length -1)) {
      return;
    }

    const targetPageItem = pageItemsState[itemIndex];
    pageItemsState[itemIndex] = pageItemsState[itemIndex + 1];
    pageItemsState[itemIndex + 1] = targetPageItem;

    setPageItems(pageItemsState);
    setPageItemsVer(pageItemsVer + 1);
  };

  const removeItem = (id) => {
    if(!id){
      message.error(intl.get("page_setting.message.selectDeleteItem"))
      return;
    }
    if(!window.confirm(intl.get("page_setting.message.confirmDelete"))) {
      return;
    }
    const pageItemsState = [...pageItems];
    const afterRemovingItems = pageItemsState.filter(item => item.id !== id);
    const selectedPageItem = afterRemovingItems.length > 0 ? afterRemovingItems : {};

    setPageItems(afterRemovingItems);
    setPageItemsVer(pageItemsVer + 1);
    setSelectedPageItem(selectedPageItem);
  };

  const saveAppPage = (publish) => {
    if ((appPage.pageType === "sub" || appPage.pageType === "component")  && !appPage.title) {
      const name = appPage.pageType === "sub" ? intl.get("page_setting.title") : intl.get("page_setting.edit.pageTitle.component");
      message.error(intl.get("page_setting.title.input", {name: name}));
      return;
    }
    if (appPage.pageType === "sub" && !appPage.pageShareImage) {
      message.error(intl.get("common.inputField", {name: intl.get("page_setting.edit.shareImage")}));
      return;
    }
    let hasError = false;
    const pageItemsState = [...pageItems];

    // product 상세 정보는 삭제하고 ID만 남긴다.
    const refinedPageItems = pageItemsState.map((item, index) => {
      if (item.type === "product") {
        if (!item.settingValues.productFilters || item.settingValues.productFilters.length === 0) {
          message.error(intl.get("common.inputField", {name: intl.get("page_setting.edit.product.search")}));
          hasError = true;
        }
        if (!isLastProductItem(item)) {
          item.settingValues.moreProducts = false;
        }
      } else if(item.type === "popup") {
        if (!validatePopupItem(item)) {
          hasError = true;
        }
      }
      return item;
    });

    if (hasError) {
      return;
    }

    appPage.editingSettings = refinedPageItems;
    if (publish) {
      appPage.published = true;
      appPage.publishedSettings = refinedPageItems;
    }
    ApplicationPageApi.submitAppPage(appPage).then(res => {
      message.success(intl.get("common.message.saved"));
      const updatedAppPage = res.data;
      setAppPage(res.data);
      if (props.pageSaved) {
        props.pageSaved();
      }
    }).catch(e => {
      console.log("saveAppPage error:", e);
      message.error(intl.get("common.message.contactAdmin"));
    });
  };

  const validatePopupItem = (item) => {
    const {settingValues} = item;
    const editingSlot = settingValues.slot;

    if (!editingSlot.bannerMediaType) {
      message.error(intl.get("common.inputField", {name: "Banner" + intl.get("page_setting.edit.banner.bannerMediaType")}));
      return false;
    }
    if (!editingSlot.bannerPath) {
      message.error(intl.get("common.inputField", {name: "Popup" + intl.get("page_setting.edit.icon.image")}));
      return false;
    }
    if (!editingSlot.action) {
      message.error(intl.get("page_setting.message.inputPopupClick"));
      return false;
    }
    if (editingSlot.action !== "logistics_order"
      && editingSlot.action !== "call_center"
      && editingSlot.action !== "none"
      && !editingSlot.param) {
      message.error(intl.get("page_setting.message.inputClick"));
      return;
    }

    if (editingSlot.openApp && !editingSlot.appId) {
      message.error(intl.get("page_setting.message.inputOpenAppID"));
      return false;
    }

    if (editingSlot.action === "contents" && !editingSlot.bannerMediaType) {
      message.error(intl.get("page_setting.message.inputMediaType"));
      return false;
    }
    return true;
  };

  const submitPublish = () => {
    if (!(window.confirm(intl.get("product_detail_page.confirmDeploy")))) {
      return;
    }
    saveAppPage(true);
  };

  const onTitleChanged = (e) => {
    const appPageState = {...appPage};
    appPageState.title = e.target.value;
    setAppPage(appPageState);
  };

  const onPageShareImageChanged = (e) => {
    const appPageState = {...appPage};
    appPageState.pageShareImage = e.target.value;
    setAppPage(appPageState);
  };

  const okFileModal = (modalName, filePathList) => {
    const appPageState = {...appPage};
    if (filePathList.length > 0) {
      appPageState.pageShareImage = filePathList[0];
    }
    setAppPage(appPageState);
    setFileModalVisible(false);
  };

  const onPublishedChanged = (e) => {
    const appPageState = {...appPage};
    appPageState.published = e.target.value;
    setAppPage(appPageState);
  };

  const deletePageSetting = () => {
    if (!window.confirm(intl.get("common.confirm.delete", {name: intl.get("page_setting.page")}))) {
      return;
    }
    ApplicationPageApi.deleteAppPage(appPage.id).then(json => {
      message.success(intl.get("common.message.deleted"));
      props.loadAppPages();
      history.push("/app-pages");
    });
  };

  const moveList = () => {
    if (props.loadAppPages) {
      props.loadAppPages();
    }
    if (props.pageType === "component") {
      history.push("/components");
    } else {
      history.push("/app-pages");
    }
  }

  const actionButtons = (
    <Button type="default" size="small"
            icon={<CloseCircleOutlined/>}
            onClick={() => removeItem(selectedPageItem.id)}>
      {intl.get("common.button.delete")}
    </Button>
  );

  const getCurrentSettingComponent = () => {
    const settingProps = {
      appPage: appPage,
      brands: brands,
      categories: categories,
      pageItemsVer: pageItemsVer,
      applySetting: applySetting,
      moveUp: moveUp,
      moveDown: moveDown,
      removeItem: removeItem,
      actionButtons: actionButtons,
      applicationDesign: applicationDesign,
      application: props.application,
    };

    if (selectedPageItem.type === "banner") {
      return (<BannerSetting {...settingProps} {...selectedPageItem} />)
    } else if (selectedPageItem.type === "popup") {
      return (<PopupSetting {...settingProps} {...selectedPageItem} />)
    } else if (selectedPageItem.type === "product") {
      const isLast = isLastProductItem(selectedPageItem);
      return (<ProductSetting {...settingProps} {...selectedPageItem} isLast={isLast}/>)
    } else if (selectedPageItem.type === "image") {
      return (<ImageSetting  {...settingProps} {...selectedPageItem} />)
    } else if (selectedPageItem.type === "search") {
      return (<SearchSetting  {...settingProps} {...selectedPageItem} />)
    } else if (selectedPageItem.type === "text") {
      return (<TextSetting  {...settingProps} {...selectedPageItem} />)
    } else if (selectedPageItem.type === "line") {
      return (<LineSetting  {...settingProps} {...selectedPageItem} />)
    } else if (selectedPageItem.type === "toolbar") {
      return (<ToolbarSetting  {...settingProps} {...selectedPageItem} />)
    }
  };

  const isLastProductItem = (item) => {
    const productItems = pageItems.filter(pi => pi.type === 'product');
    if (productItems.length === 0) {
      return false;
    }
    return item.id === productItems[productItems.length - 1].id;
  };

  return (
    <div className={"app_page_edit"}>
      <div style={{width: "100%", marginLeft: "auto"}}>
        <div>
          <span style={{marginRight: 10, textAlign: "right", display: "block"}}>
            <Button type="primary" onClick={moveList}>{intl.get("common.button.list")}</Button>
            <Divider type="vertical"/>
            <Button type="primary" onClick={() => saveAppPage(false)}>{intl.get("common.button.save")}</Button>
            <Divider type="vertical"/>
            <Button type="primary" onClick={submitPublish}>{intl.get("page_setting.edit.publish")}</Button>
            { appPage.id && appPage.pageType === "sub" && (
              <span style={{display: "inline-block"}}>
                  <Divider type="vertical"/>
                  <Button type="primary" onClick={deletePageSetting}>{intl.get("common.button.delete")}</Button>
                </span>
            )}
            <Tooltip title={intl.get("page_setting.edit.publish.help")}>
              <span style={{marginLeft: 20, fontSize: 18}}><QuestionCircleOutlined /></span>
            </Tooltip>
          </span>
        </div>
      </div>
      <div style={{marginTop: 20}}>
        <div style={{display: "flex", xAlignItems: "stretch"}}>
          <div style={{width: 190, marginRight: 20, backgroundColor: "#f0f2f5"}}>
            <div style={{marginTop: 20, marginBottom: 20, display: "flex", justifyContent: "space-evenly", alignItems: "center"}}>
              <div>
                <Button shape="circle" type="danger" icon={<DeleteOutlined/>} onClick={() => removeItem(selectedPageItem.id)}></Button>
              </div>
              <div>
                <Button shape="circle" type="primary" icon={<CaretUpOutlined />} onClick={() => moveUp(selectedPageItem.id)}></Button>
              </div>
              <div>
                <Button shape="circle" type="primary" icon={<CaretDownOutlined/>} onClick={() => moveDown(selectedPageItem.id)}></Button>
              </div>
            </div>
            <div style={{display: "flex", flexWrap: "wrap", marginLeft: 10, marginTop: 20}}>
              {getMenuItems().map((item, index) => (
                <div key={index} className={`menu_icon round-lg ${focusedMenu === index ? 'menu_icon_focus' : ''}`}
                     onClick={item.onClick}
                     onMouseEnter={() => setFocusedMenu(index)}
                     onMouseLeave={() => setFocusedMenu(-1)}
                >
                  <img className={"icon_image"} src={item.image}/>
                  <div className={"icon_title"}>{item.title}</div>
                </div>
              ))}
            </div>
          </div>
          <div style={{width: "375px"}}>
            <ApplicationPreviewFrame applicationDesign={applicationDesign} title={pageBasicInfo.previewTitle}>
              <Preview pageItems={pageItems}
                       selectedPageItemId={selectedPageItem.id}
                       pageItemsVer={pageItemsVer}
                       exchangeRate={exchangeRate}
                       popupVisible={popupVisible}
                       removeItem={removeItem}
                       closePopup={() => setPopupVisible(false)}
                       application={layoutState.application}
                       applicationDesign={applicationDesign}
              showSetting={showSetting}/>
            </ApplicationPreviewFrame>
          </div>
          <div style={{flex: 1, overflow: "auto", marginLeft: 20}}>
            { showSettingInfo && (
              <Descriptions bordered size={"small"} column={1}>
                { (appPage.pageType === "sub" || appPage.pageType === "component") && (
                  <Descriptions.Item label={appPage.pageType === "component" ? intl.get("page_setting.edit.pageTitle.component") : intl.get("page_setting.edit.pageTitle")}>
                    <Input value={appPage.title} onChange={onTitleChanged}/>
                  </Descriptions.Item>
                )}
                { (appPage.pageType !== "component") && (
                  <Descriptions.Item label={intl.get("page_setting.edit.pageType")}>
                    {appPage.pageType ? intl.get("page_setting.page." + appPage.pageType) : ""}
                  </Descriptions.Item>
                )}
                { appPage.pageType === "sub" && (
                  <Descriptions.Item label={intl.get("page_setting.edit.shareImage")}>
                    <div>{intl.get("page_setting.edit.shareImage.help")}</div>
                    <Search
                      allowClear
                      enterButton={(<Button>{intl.get("common.button.selectFile")}</Button>)}
                      value={appPage.pageShareImage}
                      onChange={onPageShareImageChanged}
                      onSearch={() => setFileModalVisible(true)}
                    />
                    {appPage.pageShareImage && (<div><img src={appPage.pageShareImage} width={200}/></div>)}
                  </Descriptions.Item>
                )}
                <Descriptions.Item label={intl.get("page_setting.edit.published")}>
                  <Radio.Group value={appPage.published} disabled={appPage.pageType !== "sub"} onChange={onPublishedChanged}>
                    <Radio value={true}>{intl.get("page_setting.edit.published.true")}</Radio>
                    <Radio value={false}>{intl.get("page_setting.edit.published.false")}</Radio>
                  </Radio.Group>
                  <div>{intl.get("page_setting.edit.published.help")}</div>
                </Descriptions.Item>
                <Descriptions.Item>
                  <div style={{color: "red"}}>{intl.get("page_setting.edit.published.help1")}</div>
                  <div style={{color: "red"}}>{intl.get("page_setting.edit.published.help2")}</div>
                </Descriptions.Item>
              </Descriptions>
            )}
            {!showSettingInfo && (getCurrentSettingComponent())}
          </div>
        </div>
      </div>
      {fileModalVisible && (
        <FileListPopup id={"event-share-image"}
                       fileKind={"index"}
                       visible={fileModalVisible}
                       multipleFile={false}
                       canSelect={true}
                       handleCancel={() => setFileModalVisible(false)}
                       handleOk={okFileModal}
        />
      )}
    </div>
  )
}

export default PageDesigner;