import React, {useEffect, useState} from "react";
import ApplicationPreviewFrame from "../application/ApplicationPreviewFrame";
import ApplicationApi from "../application/ApplicationApi";
import NoImage from "../../asset/images/no_image.png";
import Util from "../../util/Util";
import {Carousel, Tag, Drawer} from "antd";
import "./ProductPreview.less";
import ProductDetailPriceView from "./ProductDetailPriceView";
import ProductPreviewSelectSku from "./ProductPreviewSelectSku";
import ApplicationPageApi from "../app_page/ApplicationPageApi";
import ProductDetailInfoView from "../app_page/detail_preview/ProductDetailInfoView";

const ProductPreview = (props) => {
  const {product} = props;
  const [appDesign, setAppDesign] = useState({});
  const [pageItem, setPageItem] = useState({settingValues: []});

  useEffect(() => {
    if (props.application.id) {
      loadApplicationDesign();
      loadDetailPageDesign();
    }
  }, [props.application.id]);

  const loadApplicationDesign = () => {
    ApplicationApi.getApplicationDesign().then(res => {
      setAppDesign(res.data);
    });
  }

  const loadDetailPageDesign = () => {
    ApplicationPageApi.getAppPages({pageType: "product"}).then(res => {
      const pages = res.data.data;
      if (pages.length === 0) {
        return;
      }
      const infoSetting = pages[0].publishedSettings.find(s => s.type === "info");
      if (infoSetting) {
        setPageItem(infoSetting);
      }
    });
  }

  const getBrandName = () => {
    const brand = props.brands.find(b => b.id === product.brandId);
    return brand ? brand.name : "";
  }

  const groupByEventOriginProduct = () => {
    // eventOriginProductId로 그룹해서 반환한다.
    if (!product.eventProduct) {
      return [{product: product, skus: product.skus}];
    } else if (product.skus.length > 0 && !product.skus[0].eventOriginProduct) {
      // sku의 eventOriginProduct가 설정이 안된 경우
      return [];
    }

    const productMap = {};
    product.skus.forEach(sku => {
      if (productMap[sku.eventOriginProductId]) {
        productMap[sku.eventOriginProductId].push(sku);
      } else {
        productMap[sku.eventOriginProductId] = [sku];
      }
    });
    const groupingProducts = [];

    Object.keys(productMap).forEach(key => {
      const skus = productMap[key];
      groupingProducts.push({product: skus[0].eventOriginProduct, skus: skus});
    });
    return groupingProducts;
  };

  if (!appDesign.id) {
    return (<div></div>)
  }

  const groupingProducts = groupByEventOriginProduct();
  const productImages = product.images ? product.images : [NoImage];
  const descImages = product.descImages ? product.descImages : [NoImage];
  return (
      <div>
        <ApplicationPreviewFrame applicationDesign={appDesign}
                                 title={product.name}>
          <div className="product-detail-box">
            <Carousel autoplay={true}>
              { productImages.map((image, index) => (
                  <div key={index}>
                    <img style={{width:373, height: 375}} src={Util.encodeImagePath(image)}/>
                  </div>
                )
              )}
            </Carousel>
            <div className="detail-info-box">
              <ProductDetailPriceView application={props.application}
                                      salesPrice={product.salesPrice}
                                      listPrice={product.listPrice}
                                      appDesign={appDesign}
              />
              <div className="product-name u-line-2">
                {product.name}
              </div>
            </div>
            <ProductDetailInfoView product={props.product} pageItem={pageItem}/>
            <div className="detail-img-box u-m-b-16">
              {descImages.map((image, index) => (
                  <img key={index} src={image} style={{width: 373}}/>
              ))}
            </div>
            {props.visibleSkuPreview && (
              <Drawer
                title=""
                placement="bottom"
                closable={true}
                onClose={() => props.setVisibleSkuPreview(false)}
                visible={true}
                getContainer={false}
                height={400}
                style={{ position: 'absolute' }}
              >
                <ProductPreviewSelectSku
                  groupingProducts={groupingProducts}
                  productName={product.name}
                  application={props.application}
                  appDesign={appDesign}
                />
              </Drawer>
            )}
          </div>
        </ApplicationPreviewFrame>
      </div>
  )
};


export default ProductPreview;