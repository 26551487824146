import axios from "axios";
import {adminConfig} from "../config/admin.config";
import {MemberContext} from "./member.context";
import Cookies from "universal-cookie";
import {message} from "antd";
import intl from "react-intl-universal";
import Util from "../util/Util";

const API_URL = adminConfig.apiServer();

class AuthService {
  async login(id, password) {
    const params = {
      loginId: id,
      password: password,
    }
    const response = await axios.post(API_URL + "/auth-company-user/login-with-id", params).catch(err => {
      console.log("loginErr:", err.response);
      if (err.response && err.response.data) {
        if (typeof err.response.data === "string") {
          message.error(err.response.data);
        } else {
          message.error(intl.get("common.message.contactAdmin"));
        }
      } else {
        message.error(intl.get("common.message.contactAdmin"));
      }
    });
    const authResponse = response.data;

    axios.defaults.headers['Authorization'] = `Bearer ${authResponse.accessToken}`;
    const memberResponse = await axios.get(API_URL + "/company-users/my");
    memberResponse.data.lastUsedApplicationId = authResponse.applicationId;
    MemberContext.memberInformation = memberResponse.data;
  }

  async logout() {
    console.log(">>>>>auth.logout")
    Util.deleteAllCookies();
    const res = await axios.post(API_URL + "/auth-company-user/logout");
    delete axios.defaults.headers['Authorization'];
    return res;
  }

  async changeApplication(appID) {
    const response = await axios.get(API_URL + "/auth-company-user/change-app?appId=" + appID);
    const authResponse = response.data;
    axios.defaults.headers['Authorization'] = `Bearer ${authResponse.accessToken}`;

    const memberResponse  = await axios.get(API_URL + "/company-users/my");
    memberResponse.data.lastUsedApplicationId = authResponse.applicationId;
    MemberContext.memberInformation = memberResponse.data;
    return response;
  }

  async checkAuth() {
    // TODO 오류를 대비해 Retry 추가해야
    const authResponse = await axios.get(API_URL + "/auth-company-user/check");
    return authResponse;
  }

  async silentRefresh() {
    delete axios.defaults.headers['Authorization'];
    const authResponse = await axios.post(API_URL + "/auth-company-user/refresh")
    const {accessToken, applicationId} = authResponse.data;
    axios.defaults.headers['Authorization'] = `Bearer ${accessToken}`;
    return authResponse;
  }
}

const instance = new AuthService()
export {instance as AuthService}
