import React, {useEffect, useState} from "react";

import {
  Button,
  Radio,
  Descriptions,
  Table,
  message, InputNumber, Popconfirm, Form
} from "antd";
import Util from "../../util/Util";
import intl from "react-intl-universal";
import ProductSkuFormPopup from "./ProductSkuFormPopup";
import ProductSkuEditForm from "./ProductSkuEditForm";

const ProductSkuEdit = (props) => {
  ///////////////////////////////////////////////////////////////////////
  // state and variable
  const product = JSON.parse(JSON.stringify(props.product));
  const [form] = Form.useForm();

  const [multipleSkuMode, setMultipleSkuMode] = useState(false);
  const [optionSelectionMode, setOptionSelectionMode] = useState(false);
  const [skuEditMode, setSkuEditMode] = useState("new");
  const [maxSkuId, setMaxSkuId] = useState(1);
  const [editingSku, setEditingSku] = useState({});
  const [productCurrencySymbol, setProductCurrencySymbol] = useState("USD");
  const [visibleAddSkuPopup, setVisibleAddSkuPopup] = useState(false);
  const [skuFieldGroups, setSkuFieldGroups] = useState([]);
  const [skuDataInputFields, setSkuDataInputFields] = useState([]);
  const [skuPriceErrors, setSkuPriceErrors] = useState([]);
  const [help, setHelp] = useState({});
  // state and variable
  ///////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////
  // react hook
  useEffect(() => {
    const taxRateDesc = (
      <div style={{color: "red"}}>
        {intl.getHTML("products.sku.taxRateDesc")}
      </div>
    )
    const newSkuHelp = (
      <div>
        {intl.getHTML("products.sku.newSkuHelp")}
      </div>
    );
    const updateSkuHelp = (
      <div>
        {intl.getHTML("products.sku.updateSkuHelp")}
      </div>
    )
    setHelp({
      taxRateDesc: taxRateDesc,
      newSkuHelp: newSkuHelp,
      updateSkuHelp: updateSkuHelp,
    })
  }, []);

  // SKU 입력 화면 구성을 초기에 하나 입력으로 할지, N개 입력으로 할지 설정
  useEffect(() => {
    setMultipleSkuMode(product.skus && product.skus.length > 1);
    setOptionSelectionMode(product.optionSelectionMode || false)
    // timestamp는 sku가 다시 설정되는 경우 form의 setFieldsValue를 호출하기 위해
    // useEffect에서 변경 사항을 확인하기 위함이다.
    // ProductSkuEditForm의 useEffect 참고
    let skuMaxId = 0;
    if (product.skus.length > 0) {
      product.skus = product.skus.map(sku => {
        // maxSkuId를 찾는다.
        if (sku.id > skuMaxId) {
          skuMaxId = sku.id;
        }
        sku.timestamp = Date.now();
        return sku;
      });
    } else {
      product.skus = [{
        id: skuMaxId + 1,
      }];
      skuMaxId++;
    }
    const editingSku = JSON.parse(JSON.stringify(product.skus[0]));
    setEditingSku(editingSku);

    setMaxSkuId(skuMaxId);
  }, [product.id]);

  useEffect(() => {
    const currencySymbol = Util.getSymbol(props.application.productCurrency);
    setProductCurrencySymbol(currencySymbol);
    setSkuFieldGroups([
      {label: intl.get("products.sku.basicInfo"), key: "basic"},
      {label: intl.get("products.sku.priceInfo", {symbol: currencySymbol}), key: "price"},
      {label: intl.get("products.sku.salesInfo"), key: "sales"},
      {label: intl.get("products.sku.stocksInfo"), key: "stocks"},
      {label: intl.get("products.sku.declareInfo"), key: "customs"}
    ]);

    setSkuDataInputFields([
      {name: "optionText", group: "basic", required: true, label: "옵션명", prefix: "", suffix: "", desc: intl.get("products.sku.optionText.help")},
      {name: "barCode", group: "basic", required: true, label: "바코드", prefix: "", suffix: "", desc: intl.get("products.sku.barCode.help")},
      {name: "skuCode", group: "basic", label: "내부관리코드", prefix: "", suffix: "", desc: intl.get("products.sku.skuCode.help")},
      {name: "salesPrice", group: "price", required: true, type: 'float', label: "판매가격", prefix: currencySymbol, suffix: "", desc: intl.get("products.sku.salesPrice.help")},
      {name: "listPrice", group: "price", label: "할인전가격", type: 'float', prefix: currencySymbol, suffix: "", desc:intl.get("products.sku.listPrice.help")},
      {name: "minSalesPrice", group: "price", required: true, label: "판매최소가격", type: 'float', prefix: currencySymbol, suffix: "", desc: intl.get("products.sku.minSalesPrice.help")},
      {name: "maxSalesPrice", group: "price", required: true, label: "판매최대가격", type: 'float', prefix: currencySymbol, suffix: "", desc: intl.get("products.sku.maxSalesPrice.help")},
      {name: "purchasePrice", group: "price", label: "매입가", type: 'float', prefix: currencySymbol, suffix: "", desc: intl.get("products.sku.purchasePrice.help")},
      {name: "supplyPrice", group: "price", label: "판매공급가", type: 'float', prefix: currencySymbol, suffix: "", desc: intl.get("products.sku.supplyPrice.help")},
      {name: "salesQuantity", group: "sales", label: "판매수량", type: 'int', prefix: "", suffix: "", desc:intl.get("products.sku.salesQuantity.help")},
      {name: "displayStocks", group: "stocks", label: "화면표시재고", type: 'int', prefix: "", suffix: "", desc: intl.get("products.sku.displayStocks.help")},
      {name: "realStocks", group: "stocks", label: "실제재고", type: 'int', prefix: "", suffix: "", desc: intl.get("products.sku.realStocks.help")},
      {name: "taxRate", group: "customs", label: "세율", type: 'float', prefix: "", suffix: "%", desc: help.taxRateDesc},
      {name: "hsCode", group: "customs", label: "HS-CODE", prefix: "", suffix: "", desc: intl.get("products.sku.hsCode.help")},
      {name: "customsOption", group: "customs", label: "세관옵션", prefix: "", suffix: "", desc: intl.get("products.sku.customsOption.help")},
      {name: "grossWeight", group: "customs", label: "총중량", type: 'float', prefix: "", suffix: "kg", desc: intl.get("products.sku.grossWeight.help")},
      {name: "netWeight", group: "customs", label: "상품중량", type: 'float', prefix: "", suffix: "kg", desc: intl.get("products.sku.netWeight.help")},
      {name: "volume", group: "customs", label: "부피", type: 'float', prefix: "", suffix: "m³", desc: intl.get("products.sku.volume.help")},
      {name: "unit", group: "customs", label: "포장내단위", prefix: "", suffix: "", desc: intl.get("products.sku.unit.help")},
      {name: "unitQty", group: "customs", label: "포장내수량", type: 'int', prefix: "", suffix: "", desc: intl.get("products.sku.unitQty.help")},
      {name: "ingredients", group: "customs", label: "주성분", prefix: "", suffix: "", desc: intl.get("products.sku.ingredients.help")},
      {name: "package", group: "customs", label: "포장", prefix: "", suffix: "", desc: intl.get("products.sku.package.help")},
    ]);
  }, [props.application]);

  // SKU 미리 보기 옵션을 on
  useEffect(() => {
    props.setVisibleSkuPreview(true);
  }, []);
  // react hook
  ///////////////////////////////////////////////////////////////////////

  const onProductPriceChanged = (fieldName, value) => {
    product[fieldName] = value;
    props.setProduct(product);
  }

  const removeSku = (event, skuId) => {
    event.stopPropagation();
    product.skus = product.skus.filter(s => s.id !== skuId);
    props.setProduct(product);
  };

  const copySku = (event, sku) => {
    event.stopPropagation();
    const copiedSku = JSON.parse(JSON.stringify(sku));
    copiedSku.id = maxSkuId + 1;
    copiedSku.barCode = "";
    copiedSku.optionText = "";
    copiedSku.skuCode = "";
    copiedSku.added = true;

    setSkuEditMode("new");
    setEditingSku(copiedSku);
    setVisibleAddSkuPopup(true);
  };

  const onSelectSku = (sku) => {
    setSkuEditMode("update");
    setEditingSku(sku);
    setVisibleAddSkuPopup(true);
  };

  const getSkuMinSalesPrice = () => {
    if (product?.skus) {
      return Math.min(...product.skus.map(s => s.minSalesPrice));
    } else {
      return 0;
    }
  }

  const getSkuMaxSalesPrice = () => {
    if (product?.skus) {
      let skuMaxSalesPrice = Math.max(...product.skus.map(s => s.maxSalesPrice));
      if (skuMaxSalesPrice === 0) {
        skuMaxSalesPrice = Number.MAX_SAFE_INTEGER;
      }
      return skuMaxSalesPrice;
    } else {
      return Number.MAX_SAFE_INTEGER;
    }
  }

  // sku가 한개만 있는 경우 skuForm에 없는 정보를 product에 반영한다.
  // (sku image 및 skuPriceRanges 갑)
  const skuValueChanged = (values) => {
    const editingSku = JSON.parse(JSON.stringify(values));

    if (editingSku.usePriceByQuantity) {
      findSkuPriceError(editingSku);
    } else {
      editingSku.priceByQuantities = [];
    }

    if (!product.skus || product.skus.length === 0) {
      product.skus = [editingSku];
    } else {
      Object.assign(product.skus[0], editingSku);
    }
    setEditingSku(editingSku);
    props.setProduct(product);
  }

  const findSkuPriceError = (sku) => {
    const errors = [];
    if (!sku.usePriceByQuantity) {
      setSkuPriceErrors(errors);
      return errors;
    }
    if (!sku.priceByQuantities) {
      errors.push(intl.get("products.sku.inputPriceByQuantity"));
      setSkuPriceErrors(errors);
      return errors;
    }
    if (sku.priceByQuantities.length === 0) {
      errors.push(intl.get("products.sku.requiredAtLeastOne"));
      setSkuPriceErrors(errors);
      return errors;
    }
    let prevSkuPrice = {};
    sku.priceByQuantities.forEach((sp, index) => {
      const isLast = index === sku.priceByQuantities.length - 1;
      if (sp.salesPrice === 0) {
        const ordinalNumber= Util.getOrdinalNumber(index + 1);
        errors.push(intl.get("products.sku.inputPrice", {number: ordinalNumber}));
      }
      if (index > 0) {
        if (!isLast && sp.minQuantity >= sp.maxQuantity) {
          errors.push(intl.get("products.sku.lessThanPrevious", {number: Util.getOrdinalNumber(index + 2)}));
        }
        if (sp.salesPrice !== 0 && sp.salesPrice >= prevSkuPrice.salesPrice) {
          errors.push(intl.get("products.sku.greatThanPrevious", {number: Util.getOrdinalNumber(index + 1)}));
        }
      }
      prevSkuPrice = sp;
    });
    setSkuPriceErrors(errors);
    return errors;
  }

  // sku가 여러개인 경우 popup에서 ok 버튼 클릭 시 처리
  const handleOkSkuFormPopup = (skuValues) => {
    const priceErrors = findSkuPriceError(skuValues);
    if (priceErrors.length > 0) {
      message.error(intl.get("products.sku.checkPrice"));
      return;
    }

    if (skuEditMode === 'new') {
      if (product.skus.find(s => s.optionText === skuValues.optionText)) {
        message.error("[" + skuValues.optionText + "] " + intl.get("products.sku.exist.optionText"));
        return;
      }
      if (product.skus.find(s => s.barCode === skuValues.barCode)) {
        message.error("[" + skuValues.barCode + "] " + intl.get("products.sku.exist.barCode"));
        return;
      }
      setMaxSkuId(skuValues.id);
      product.skus.push(skuValues);
      setVisibleAddSkuPopup(false);
      props.setProduct(product);
    } else {
      setVisibleAddSkuPopup(false);

      product.skus = product.skus.map(s => {
        if (s.id === skuValues.id) {
          return skuValues;
        } else {
          return s;
        }
      });
      props.setProduct(product);
    }
  };

  const onClickAddSku = () => {
    const sku = {
      id: maxSkuId + 1,
    }
    setSkuEditMode("new");
    setEditingSku(sku);
    setVisibleAddSkuPopup(true);
  };

  // sku 단일모드, 복수모드 선택 변경 시
  // 단일모드에서 입력중인 경우 복수모드의 첫번째 sku 값으로 설정한다.
  // 복수모드인 경우 첫번째 sku의 값으로 설정한다.
  const onMultipleSkuModeChanged = (e) => {
    const multiMode = e.target.value;
    if (multiMode) {
      const editingSKuState = JSON.parse(JSON.stringify(editingSku));
      Object.assign(editingSKuState, form.getFieldsValue());
      product.skus = [editingSKuState];
      setEditingSku(editingSKuState);
      props.setProduct(product);
    } else {
      if (product.skus.length > 1) {
        if (!window.confirm("첫번째 SKU를 제외하고 모두 삭제됩니다. 계속하시겠습니까?")) {
          return;
        }
      }
      const firstSku = product.skus[0];
      firstSku.timestamp = Date.now();
      product.skus = [firstSku];
      setEditingSku(firstSku);
      props.setProduct(product);
    }
    setMultipleSkuMode(multiMode);
  };
  const onOptionSelectionModeChanged = (e) => {
    const val = e.target.value
    setOptionSelectionMode(val)
    product.optionSelectionMode = val
    props.setProduct(product);
  }

  const getSkuDataTableColumns = () => {
    const disabled = product?.marketProduct ? true : false;

    let columns = [{
      title: intl.get("products.sku.optionText"),
      dataIndex: "optionText",
      key: "optionText",
      width: 250,
      fixed: 'left',
      render: (text, record) => {
        return (
          <div style={{fontSize: 12, display: "flex", alignItems: "center", gap: 10}}>
            <div>
              <img src={record.skuImage} style={{width: 64, height: 64}}/>
            </div>
            <div>
              <div>{text}</div>
              {product.eventProduct && record.eventOriginProduct && (<div>{record.eventOriginProduct.name}</div>)}
            </div>
          </div>
        )
      }
    }, {
      title: intl.get("products.sku.salesPrice"),
      dataIndex: "salesPrice",
      key: "salesPrice",
      align: "right",
      width: 80,
      render: (text, record) => {
        return (<span style={{fontSize: 12}}>
          {text ? Util.currencyFormat(text, props.application.productCurrency) : ''}
        </span>)
      }
    }, {
      title: intl.get("products.sku.salesQuantity"),
      dataIndex: "salesQuantity",
      key: "salesQuantity",
      align: "right",
      width: 60,
      render: (text, record) => {
        return (<span style={{fontSize: 12}}>{text}</span>)
      }
    }];
    const alreadyAddedColumns = ["optionText", "salesPrice", "salesQuantity"];
    skuDataInputFields.forEach((field, index) => {
      if (field.name && !alreadyAddedColumns.includes(field.name)) {
        columns.push({
          title: intl.get("products.sku." + field.name),
          dataIndex: field.name,
          key: field.name,
          width: field.type ? 80 : 100,
          align: field.type ? "right" : "",
          ellipsis: field.name === "customsOption",
          render: (text, record) => {
            return (<div style={{fontSize: 12}}>{field.prefix} {field.type ? Util.numberFormat(text) : text}</div>)
          }
        });
      }
    });
    columns.push({
      title: "",
      dataIndex: "id",
      key: "action",
      width: 60,
      align: 'center',
      fixed: 'right',
      render: (text, record) => {
        if (product.marketProduct) {
          return (<div>{text}</div>)
        }
        return (
          <div>
            <div>
              <Popconfirm
                title={intl.get("common.confirm.delete", {name: intl.get("products.sku.optionText")})}
                onConfirm={(e) => removeSku(e, record.id)}
                onCancel={(e) => e.stopPropagation()}
              >
                <Button disabled={disabled} block size={"small"} onClick={(e) => e.stopPropagation()}>
                  {intl.get("common.button.delete")}
                </Button>
              </Popconfirm>
            </div>
            {!product.eventProduct && (
              <div style={{marginTop: 5}}>
                <Button disabled={disabled} block size={"small"} onClick={(e) => copySku(e, record)}>
                  {intl.get("common.button.copy")}
                </Button>
              </div>
            )}
          </div>
        );
      }
    });
    return columns;
  };

  const disabled = product?.marketProduct ? true : false;

  return (
      <>
        <Descriptions bordered size={"small"} column={1}>
          <Descriptions.Item label={product.eventProduct ? "" : intl.get("products.options.count")}>
            <Radio.Group disabled={disabled}  value={multipleSkuMode} onChange={onMultipleSkuModeChanged}>
              <Radio value={false}>{intl.get("products.options.count.single")}</Radio>
              <Radio value={true}>{intl.get("products.options.count.multi")}</Radio>
            </Radio.Group>
          </Descriptions.Item>
          {multipleSkuMode && (
            <Descriptions.Item label={product.eventProduct ? "" : intl.get("products.options.split")}>
            <Radio.Group value={optionSelectionMode} onChange={onOptionSelectionModeChanged}>
                <Radio value={false}>{intl.get("common.false")}</Radio>
                <Radio value={true}>{intl.get("common.true")}</Radio>
              </Radio.Group>
            </Descriptions.Item>
          )}
          {multipleSkuMode && (
            <Descriptions.Item label={intl.get("products.price.rep")}>
              <>
                <div style={{display: "flex", alignItems: "center", gap: 10}}>
                  <div>
                    <span style={{color: "red", marginRight: 2}}>*</span>
                    <span>{intl.get("products.salesPrice")}:</span>
                  </div>
                  <span style={{marginRight: 0}}>{productCurrencySymbol}</span>
                  <InputNumber value={product.salesPrice}
                               style={{width: 200, marginRight: 10}} min={getSkuMinSalesPrice()} max={getSkuMaxSalesPrice()} step={0.01}
                               onChange={(value) => onProductPriceChanged('salesPrice', value)}/>
                  <span>{intl.get("products.listPrice")}:</span>
                  <span style={{marginRight:0}}>{productCurrencySymbol}</span>
                  <InputNumber value={product.listPrice}
                               style={{width: 200}} min={0} step={0.01}
                               onChange={(value) => onProductPriceChanged('listPrice', value)}/>
                </div>
                <div>{intl.getHTML("products.price.help")}</div>
              </>
            </Descriptions.Item>
          )}
        </Descriptions>
        {multipleSkuMode ? (
          <div style={{marginTop: 20}}>
            <div style={{textAlign: "right",  marginLeft: "auto"}}>
              <Button type={"primary"} onClick={onClickAddSku}>
                {intl.get("common.button.add")}
              </Button>
            </div>
            <div style={{marginTop: 10, cursor: "pointer"}}>
              <Table size={"small"}
                     dataSource={product.skus}
                     columns={getSkuDataTableColumns()}
                     rowKey={'id'}
                     pagination={false}
                     scroll={{x: 2300}}
                     onRow={(record, rowIndex) => {
                       return {
                         onClick: event => onSelectSku(record),
                       };
                     }}
              />
            </div>
          </div>
          ) : (
            <ProductSkuEditForm sku={editingSku}
                                form={form}
                                skuPriceErrors={skuPriceErrors}
                                skuFieldGroups={skuFieldGroups}
                                skuDataInputFields={skuDataInputFields}
                                skuValueChanged={skuValueChanged}
                                {...props}
            />
        )}
        {visibleAddSkuPopup && (
          <ProductSkuFormPopup
            skuEditMode={skuEditMode}
            sku={editingSku}
            multipleSkuMode={multipleSkuMode}
            skuFieldGroups={skuFieldGroups}
            skuDataInputFields={skuDataInputFields}
            skuPriceErrors={skuPriceErrors}
            findSkuPriceError={findSkuPriceError}
            handleOk={handleOkSkuFormPopup}
            handleCancel={() => setVisibleAddSkuPopup(false)}
            {...props}/>
        )}
      </>
  )
}

export default ProductSkuEdit;