import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import {Table, Divider, Button, Input, Spin, DatePicker, Space} from 'antd';
import Util from "../../util/Util";
import ProductApi from "./ProductApi";
import intl from "react-intl-universal";
import dayjs from "dayjs";
import NoImage from "../../asset/images/no_image.png";

const { RangePicker } = DatePicker;

const ProductShareList = (props) =>{
  const [loading, setLoading] = useState(false);
  const [productShares, setProductShares] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });

  const [filter, setFilter] = useState({
    userId: '',
    productId: '',
    startDate: dayjs().subtract(30, "day").format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
  })

  useEffect(() => {
    onClickSearch();
  }, []);

  const tableChanged = (e) => {
    const paginationState = {...pagination};
    paginationState.current = e.current;
    searchProductShares(paginationState);
  };

  const onClickSearch = () => {
    const paginationState = {...pagination};
    paginationState.current = 1;
    searchProductShares(paginationState);
  }

  const searchProductShares = (pagination) => {
    setLoading(true);
    ProductApi.getProductShares(getSearchParam(pagination)).then(res => {
      const pagingResult = res.data;
      const pagination = {
        current: pagingResult.page,
        pageSize: pagingResult.pageSize,
        total: pagingResult.count,
      }
      setProductShares(pagingResult.data);
      setPagination(pagination);
    }).finally(() => {
      setLoading(false);
    });
  };

  const getSearchParam = (pagination) => {
    const params = {
      pageSize: pagination.pageSize,
      page: pagination.current,
      ...filter
    };
    return params;
  };

  const onChangeProductId = (e) => {
    const filterState = {...filter};
    filterState.productId = e.target.value;
    setFilter(filterState);
  };

  const onChangeUserId = (e) => {
    const filterState = {...filter};
    filterState.userId = e.target.value;
    setFilter(filterState);
  };

  const onDateChange = (value, dateString) => {
    const filterState = {...filter};
    if (value[0] && value[1]) {
      filterState.startDate = dateString[0];
      filterState.endDate = dateString[1];
      setFilter(filterState);
    }
  }

  const onDateOk = (value, dateString) => {
    if (value[1]) {
      const filterState = {...filter};
      filterState.startDate =  value[0].format("YYYY-MM-DD HH:mm:ss");
      filterState.endDate = value[1].format("YYYY-MM-DD HH:mm:ss");
      setFilter(filterState);
    }
  }

  const columns = [{
    title: intl.get("products.id"),
    dataIndex: 'productId',
    key: 'productId',
  }, {
    title: intl.get("products.product"),
    dataIndex: 'productId',
    key: 'productId',
    render: (text, record) => {
      const image = record.product.images.length > 0 ? record.product.images[0] + "?x-oss-process=image/resize,h_48,w_48" : NoImage;
      return (
        <Link to={`/products/${record.productId}`}>
          <Space size={"middle"}>
            <div><img src={image} style={{width: 32, height: 32}}/></div>
            <div>{record.product.name}</div>
          </Space>
          {text}
        </Link>
      )
    }
  }, {
    title: intl.get("products.share.user_id"),
    dataIndex: 'userId',
    key: 'userId',
  }, {
    title: intl.get("products.share.user"),
    dataIndex: 'userId',
    key: 'userId',
    render: (text, record) => {
      const image = record.user.avatarUrl ? record.user.avatarUrl : NoImage;
      return (
          <Link to={`/users/${record.userId}`}>
            <Space size={"middle"}>
              <div><img src={image} style={{width: 32, height: 32}}/></div>
              <div>{record.user.name}</div>
            </Space>
            {text}
          </Link>
      )
    }
  }, {
    title: intl.get("products.share.date"),
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text, record) => (
        <span>{text ? Util.getLocalTime(text) : ""}</span>
    )
  }];

  const dateRange = [dayjs(filter.startDate), dayjs(filter.endDate)];

  return (
      <Spin spinning={loading}>
        <div className="page-op-box">
          <div className="filter-box">
            <div>
              <RangePicker
                  format="YYYY-MM-DD"
                  placeholder={[intl.get("order.searchOrderDateStart"), intl.get("order.searchOrderDateEnd")]}
                  onChange={onDateChange}
                  onOk={onDateOk}
                  value={dateRange}
              />
              <Divider type="vertical" />
              <Input value={filter.productId} onChange={onChangeProductId} style={{ width: 100, marginRight: 10 }} placeholder={intl.get("products.id")}/>
              <Divider type="vertical" />
              <Input value={filter.userId} onChange={onChangeUserId} style={{ width: 100, marginRight: 10 }} placeholder={intl.get("products.share.user_id")}/>
            </div>
            <div style={{marginLeft: "auto"}}>
              <div style={{width: 100}}>
                <Button style={{width: 100}} type="primary" block size="default" onClick={onClickSearch}>{intl.get("common.button.search")}</Button>
              </div>
            </div>
          </div>
        </div>
        <div style={{marginTop: "10px"}}>
          <Table size={'small'}
                 dataSource={productShares}
                 columns={columns}
                 rowKey={'id'}
                 title={() => intl.get("common.label.listTotal", {total: pagination.total})}
                 pagination={pagination}
                 onChange={tableChanged}
          />
        </div>
      </Spin>
  )
};

export default ProductShareList;