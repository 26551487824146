import React, {useState, useEffect} from "react";
import { Descriptions } from 'antd';
import JSONPretty from "react-json-pretty";

const SmatroPayView = (props) => {
  const {payment} = props;

  return (
    <div>
      <Descriptions layout="vertical"
                    bordered
                    size={"small"}
                    column={1}>
        <Descriptions.Item label="지불준비요청">
          <div style={{fontSize: "11px", overflow: "scroll", wordBreak: "break-all", wordWrap: "break-word", height: "150px", width: "500px"}}>
            <div>
              {decodeURIComponent(payment.pgPrepayReq).split("&").map((keyVal, kvIndex) => (<div key={kvIndex}>{keyVal}</div>))}
            </div>
          </div>
        </Descriptions.Item>
        <Descriptions.Item label="지불준비요청 회신">
          <div style={{fontSize: "11px", overflow: "scroll", wordBreak: "break-all", wordWrap: "break-word", width: "400px", height: "150px", marginRight: 20}}>
            <JSONPretty id={"json-prepay-res"} data={payment.pgPrepayRes}></JSONPretty>
          </div>
        </Descriptions.Item>
        <Descriptions.Item label="지불처리완료 회신">
          <div style={{fontSize: "11px", overflow: "scroll", wordBreak: "break-all", wordWrap: "break-word", width: "400px", height: "150px", marginRight: 20}}>
            <div>
              {decodeURIComponent(payment.pgCallbackRes).split("&").map((keyVal, kvIndex) => (<div key={kvIndex}>{keyVal}</div>))}
            </div>
          </div>
        </Descriptions.Item>
      </Descriptions>
    </div>
  )
}

export default SmatroPayView;