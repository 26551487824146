import React, {useState} from 'react';
import User from "./User";
import {Layout, Modal} from "antd";
import {UserOutlined} from "@ant-design/icons";
import intl from "react-intl-universal";

const {Content} = Layout;

const UserListPopUp = (props) => {
  const onClickUser = (user) => {
    props.onClickUser(user);
  };

  return (
    <Modal
      title={[<div key={1}><UserOutlined/> {intl.get("user.info")}</div>]}
      visible={true}
      onCancel={props.handleCancel}
      footer={null}
      width={900}
      bodyStyle={{margin: 0, padding: 0}}>
      <Layout>
        <Content className="modal-content">
          <User isPopup={true}
                onClickUser={onClickUser}
          />
        </Content>
      </Layout>
    </Modal>
  )
};

export default UserListPopUp;