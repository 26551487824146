import React, {useState, useEffect} from "react";
import ProductApi from "./ProductApi";
import {Button, Input, Modal, Table, message, Divider, Checkbox, Select, Layout, Spin, Tag} from "antd";
import intl from "react-intl-universal";
import CategoryApi from "../category/CategoryApi";
import BrandApi from "../brand/BrandApi";
import {SettingOutlined} from "@ant-design/icons";
import NoImage from "../../asset/images/no_image.png";
import Util from "../../util/Util";
import {useLayoutState} from "../../components/layout/AppLayoutContext";
const {Content} = Layout;

const EventProductList = (props) => {
  const layoutState = useLayoutState();
  const [products, setProducts] = useState([]);
  const [application, setApplication] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.eventOriginProductId) {
      searchProducts(props.eventOriginProductId);
    }
  }, [props.eventOriginProductId]);

  useEffect(() => {
    setApplication(layoutState.application);
  }, [layoutState.application]);

  const searchProducts = (eventOriginProductId) => {
    ProductApi.getProducts({
      eventOriginProductId: eventOriginProductId,
      pageSize: 100,
    }).then(res => {
      const pagingResult = res.data;
      const products = pagingResult.data;
      setProducts(products);
      setLoading(false);
    });
  };

  let columns = [{
    title: 'id',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
  }, {
    title: intl.get("products.featuredImage"),
    dataIndex: 'images',
    key: 'images',
    align: 'center',
    render: (text, record) => {
      const image = record.images.length > 0 ? record.images[0] + "?x-oss-process=image/resize,h_48,w_48" : NoImage;
      return  (<span><img src={image} style={{width: "48px", height: "48px"}}/></span>);
    }
  }, {
    title: intl.get("products.name"),
    dataIndex: 'name',
    key: 'name'
  }, {
    title: intl.get("products.salesPrice"),
    dataIndex: 'salesPrice',
    key: 'salesPrice',
    align: 'right',
    render: (text, record) => (
      <div>
        <div>{Util.currencyFormat(record.productPrices.salesPrice.originPrice, application.productCurrency)}</div>
        <div style={{color: "gray", fontSize: 13}}>{Util.currencyFormat(record.productPrices.salesPrice.secondaryPrice, application.userPayCurrency)}</div>
      </div>
    )
  }, {
    title: intl.get("products.attr"),
    dataIndex: 'published',
    key: 'published',
    align: 'left',
    render: (text, record) => {
      return (
        <div>{Util.getProductAttrs(record).map((attr, index) => (
          <Tag key={index} color={attr.color}>{attr.value}</Tag>))}</div>
      )
    }
  }];

  return (
    <Modal
      title={[<div key={1}><SettingOutlined/> {intl.get("products.button.eventProduct")} [{props.product.name}]</div>]}
      visible={true}
      onCancel={props.handleCancel}
      footer={[
        <Button key="1" onClick={props.handleCancel}>
          {intl.get("common.button.close")}
        </Button>,
      ]}
      width={"80%"}
      bodyStyle={{margin: 0, padding: 0}}>
      <Layout>
        <Content className="modal-content">
          <Spin spinning={loading}>
            <Table size={'small'}
                   dataSource={products}
                   columns={columns}
                   rowKey={'id'}
                   pagination={false}
            />
          </Spin>
        </Content>
      </Layout>
    </Modal>
  )
}

export default EventProductList;