import React, {useEffect, useState} from "react";
import {Descriptions, Input, Checkbox} from "antd";
import intl from "react-intl-universal";

const SearchSetting = (props) => {
  const [settingValues, setSettingValues] = useState({});
  useEffect(() => {
    const settingValuesState = JSON.parse(JSON.stringify(props.settingValues));
    if (!settingValuesState.backgroundColor) {
      settingValuesState.backgroundColor = "#ffffff";
    }
    if (!settingValuesState.borderColor) {
      settingValuesState.borderColor = "#9e9e9e";
    }
    if (!settingValuesState.inputBackgroundColor) {
      settingValuesState.inputBackgroundColor = "#ffffff";
    }

    setSettingValues(settingValuesState);
  }, [props.settingValues]);

  const onValueChanged = (fieldName, value) => {
    const settingValuesState = {...settingValues};
    settingValuesState[fieldName] = value;
    setSettingValues(settingValuesState);
    props.applySetting(props.id, settingValuesState);
  }

  return (
      <div>
        <Descriptions title={intl.get("page_setting.edit.icon.search") + " " + intl.get("page_setting.edit.icon.setting")}
                      bordered layout="vertical"
                      size={"small"}
                      column={1}>
          <Descriptions.Item label={intl.get("page_setting.edit.backgroundColor")}>
            <span><input type="color" value={settingValues.backgroundColor ? settingValues.backgroundColor : "#ffffff"} onChange={e => onValueChanged("backgroundColor", e.target.value)} /></span>
            <span style={{marginLeft: 10}}>{settingValues.backgroundColor}</span>
          </Descriptions.Item>
          <Descriptions.Item label={intl.get("page_setting.edit.borderColor")}>
            <span><input type="color" value={settingValues.borderColor ? settingValues.borderColor : "#9e9e9e"} onChange={e => onValueChanged("borderColor", e.target.value)} /></span>
            <span style={{marginLeft: 10}}>{settingValues.borderColor}</span>
          </Descriptions.Item>
          <Descriptions.Item label={intl.get("page_setting.edit.inputBackgroundColor")}>
            <span><input type="color" value={settingValues.inputBackgroundColor ? settingValues.inputBackgroundColor : "#ffffff"} onChange={e => onValueChanged("inputBackgroundColor", e.target.value)} /></span>
            <span style={{marginLeft: 10}}>{settingValues.inputBackgroundColor}</span>
          </Descriptions.Item>
        </Descriptions>
      </div>
  )
};

export default SearchSetting;