import React, {useState} from "react";
import {
  Button, Divider, Input, Table,
  DatePicker, Spin, Popconfirm, message, Modal, Layout
} from "antd";
import intl from "react-intl-universal";
import dayjs from "dayjs";
import OrderApi from "../OrderApi";
import Util from "../../../util/Util";
import {SettingOutlined} from "@ant-design/icons";
import DeliveryStatus from "../clearance/DeliveryStatus";

const { RangePicker } = DatePicker;
const {Content} = Layout;

const UploadedOrderList = () => {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);
  const [visibleDeliveryModal, setVisibleDeliveryModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [filter, setFilter] = useState({
    noType: "externalOrderNo",
    targetNo: "",
    startDate: dayjs().subtract(30, "day").format("YYYY-MM-DD"),
    endDate: dayjs().format("YYYY-MM-DD"),
  });
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });

  const onFilterChanged = (field, value) => {
    const filterState = {...filter};
    filterState[field] = value;
    setFilter(filterState);
 };

  const handleSearchClick = (e) => {
    const paginationState = {...pagination};
    paginationState.current = 1
    searchOrders(paginationState);
  }

  const getSearchParam = (pagination) => {
    return {
      pageSize: pagination.pageSize,
      page: pagination.current,
      uploaded: true,
      ...filter
    };
  };

  const searchOrders = (pagination) => {
    setLoading(true);
    const params = getSearchParam(pagination);

    OrderApi.getOrders(params).then(res => {
      const pagingResult = res.data;
      const pagination = {
        current: pagingResult.page,
        pageSize: pagingResult.pageSize,
        total: pagingResult.count,
      }
      setOrders(pagingResult.data);
      setPagination(pagination);
      setLoading(false);
    }).finally(() => {
      setLoading(false);
    });
  };

  const orderTableChanged = (pagination) => {
    searchOrders(pagination);
  }

  const onDateChange = (value, dateString) => {
    const filterState = {...filter};
    if (value[0] && value[1]) {
      filterState.startDate = dateString[0];
      filterState.endDate = dateString[1];
      setFilter(filterState);
    }
  }

  const onDateOk = (value, dateString) => {
    if (value[1]) {
      const filterState = {...filter};
      filterState.startDate =  value[0].format("YYYY-MM-DD HH:mm:ss");
      filterState.endDate = value[1].format("YYYY-MM-DD HH:mm:ss");
      setFilter(filterState);
    }
  }

  const deleteOrder = (orderId) => {
    const order = orders.find(o => o.id === orderId);
    if (!order) {
      message.error(orderId + " 주문 정보를 찾을 수 없습니다.");
      return;
    }

    if (order.currentStatus !== "paid" && order.finishedStatus !== "failed") {
      message.error("주문 상태가 지불완료 또는 실페인 주문만 삭제 가능합니다.");
      return;
    }

    setLoading(true);
    OrderApi.deleteOrder(order.id).then(res => {
      message.success(intl.get("common.message.deleted"));
      searchOrders(pagination);
    }).finally(() => {
      setLoading(false);
    });
  };

  const showDeliveryStatus = (order) => {
    setSelectedOrder(order);
    setVisibleDeliveryModal(true);
  };

  const columns = [{
    title: intl.get("order.externalOrderNo"),
    dataIndex: 'externalOrderNo',
    key: 'externalOrderNo',
    align: 'left',
  }, {
    title: intl.get("order.orderNo"),
    dataIndex: 'orderNo',
    key: 'orderNo',
    align: 'left',
  }, {
    title: intl.get("order.orderDate"),
    dataIndex: 'orderDate',
    key: 'orderDate',
    align: 'left',
    render: (text, value) => {
      return Util.getChinaLocalTime(text).substring(0, 10);
    }
  }, {
    title: intl.get("order.deliveryNo"),
    dataIndex: 'orderNo',
    key: 'deliveryNo',
    align: 'left',
    render: (text, value) => {
      if (!value.orderDelivery.deliveryNo) {
        return (<div></div>)
      }
      return (
        <div>
          <a onClick={() => showDeliveryStatus(value)}>
            {value.orderDelivery.deliveryCompany}<br/>{value.orderDelivery.deliveryNo}
          </a>
        </div>
      )
    }
  }, {
    title: intl.get("order.recipient"),
    dataIndex: 'recipient',
    key: 'recipient',
    align: 'left',
    render: (text, value) => {
      return (
        <div>
          {value.shippingAddress.recipient}
          <br/>
          {value.shippingAddress.addressProvince}{value.shippingAddress.addressCity}{value.shippingAddress.addressDistrict}
        </div>
      )
    }
  }, {
    title: intl.get("products.product"),
    dataIndex: 'orderTitle',
    key: 'orderTitle',
    align: 'left',
    width: "400px",
    render: (text, value) => {
      return value.orderSkus.map((orderSku, index) => {
        return (<div key={index}>{orderSku.productName}{orderSku.optionText ? " " + orderSku.optionText : ""}（¥{orderSku.salesAmount}) * {orderSku.shipQuantity}</div>)
      })
    }
  }, {
    title: intl.get("order.paymentAmountUser"),
    dataIndex: 'paymentAmountUser',
    key: 'paymentAmountUser',
    align: 'right',
  }, {
    title: "",
    dataIndex: 'currentStatus',
    key: 'currentStatus',
    align: 'right',
    render: (text, value) => {
      return (
        <>
          <div>{value.currentStatus === "finished" ? intl.get("order.status." + value.finishedStatus) : intl.get("order.status." + value.currentStatus)}</div>
          {value.finishedStatus === "failed" && (<div>{value.failedMessage}</div>)}
        </>
      )
    }
  }, {
    title: intl.get("order.status"),
    dataIndex: 'currentStatus',
    key: 'currentStatus',
    align: 'right',
    render: (text, value) => {
      if (value.currentStatus !== "paid" || value.finishedStatus === "failed") {
        return (<div></div>)
      }
      return (
        <Popconfirm
          title={intl.get("common.confirm.delete", {name: intl.get("order.order")})}
          onConfirm={(e) => deleteOrder(value.id)}
        >
          <Button block type="default" size="small"
                  disabled={value.currentStatus !== "paid" || value.finishedStatus === "failed"}>{intl.get("common.button.delete")}</Button>
        </Popconfirm>
      )
    }
  }];

  // const rowSelection = {
  //   selectedRowKeys: selectedOrderIds,
  //   onChange: (selectedOrderIds) => {
  //     setSelectedOrderIds(selectedOrderIds);
  //   },
  // };

  const dateRange = [dayjs(filter.startDate), dayjs(filter.endDate)];

  return (
    <Spin spinning={loading}>
      <div className="page-op-box">
        <div className="filter-box">
          <div>
            <Input style={{ width: 200, marginRight: 10 }}
                   value={filter.externalOrderNo}
                   onChange={(e) => onFilterChanged("targetNo", e.target.value)} placeholder={intl.get("order.orderNo")}/>
          </div>
          <div>
            <RangePicker
              format="YYYY-MM-DD"
              placeholder={[intl.get("order.searchOrderDateStart"), intl.get("order.searchOrderDateEnd")]}
              onChange={onDateChange}
              onOk={onDateOk}
              value={dateRange}
              allowClear={false}
              style={{ marginRight: 10 }}
            />
          </div>
          <div style={{marginLeft: "auto"}}>
            <div style={{width: 100}}>
              <Button style={{width: 100}} type="primary" block size="default" onClick={handleSearchClick}>{intl.get("common.button.search")}</Button>
            </div>
          </div>
        </div>
      </div>
      <div style={{marginTop: "10px"}}>
        <Table size={'small'}
               dataSource={orders}
               columns={columns}
               rowKey={'id'}
               title={() => intl.get("common.label.listTotal", {total: pagination.total})}
               pagination={pagination}
               onChange={orderTableChanged}
        />
      </div>
      {visibleDeliveryModal && (
        <Modal
          title={[<div key={1}><SettingOutlined/> {intl.get("order.delivery.tracking")}</div>]}
          visible={true}
          onCancel={() => setVisibleDeliveryModal(false)}
          onOk={() => setVisibleDeliveryModal(false)}
          cancelButtonProps={{ style: { display: 'none' } }}
          width={800}
          bodyStyle={{height: 500, margin: 0, padding: 0}}
        >
          <Layout>
            <Content className="modal-content">
              <DeliveryStatus order={selectedOrder}/>
            </Content>
          </Layout>
        </Modal>
      )}
    </Spin>
  )
};

export default UploadedOrderList;