import React, {useEffect, useState} from "react";
import intl from "react-intl-universal";
import {
  Button, Checkbox, Input, DatePicker, Form, Radio,
  message, Modal, Select, Spin, Tag, InputNumber, Descriptions
} from "antd";
import {PlusOutlined} from "@ant-design/icons";
import ProductApi from "./ProductApi";

const ProductMultiSettingPopup = (props) => {
  const [loading, setLoding] = useState(false);
  const [itemSelected, setItemSelected] = useState({});
  const [subCategories, setSubCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const tagInputRef = React.useRef(null);
  const [tag, setTag] = useState({
    tagValue: "",
    tagMaxId: 0,
    tagInputVisible: "",
  });
  const [form] = Form.useForm();

  // form 값이 변경되면 자동으로 사용하기 체크(제목의 체크박스)
  const onFormValuesChange = (changedValues, allValues) => {
    const keys = Object.keys(changedValues);
    if (keys.length === 0) {
      return;
    }

    const itemSelectedState = {...itemSelected};
    if (keys[0] === "published" || keys[0] === "openToMarket") {
      itemSelectedState[keys[0]] = true;
    } else {
      itemSelectedState[keys[0]] = changedValues[keys[0]] ? true : false;
    }
    setItemSelected(itemSelectedState);
  };

  // 사용자가 직접 제목의 체크박스를 선택한 경우
  const onMultiSettingItemChecked = (itemName, checked) => {
    const itemSelectedState = {...itemSelected};
    itemSelectedState[itemName] = checked;
    setItemSelected(itemSelectedState);
  };

  const handleMultiSettingOk = (values) => {
    const itemSelectedState = {...itemSelected};
    const checkedItems = Object.keys(itemSelectedState).filter(key => itemSelectedState[key]);
    if (checkedItems.length === 0) {
      message.error("수정할 항목을 선택하세요.");
      return;
    }

    const productValues = {};
    const selectedColumns = [];
    let taxRate = 0.0;
    let errorMessage = "";
    Object.keys(itemSelectedState).forEach(key => {
      if (key === "tag") {
        if (!tags.length) {
          errorMessage = intl.get("common.inputField", {name: "Tag"});
          return;
        }
        productValues["tags"] = tags;
      } else if (key === "taxRate") {
        if (!values[key]) {
          errorMessage = intl.get("common.inputField", {name: intl.get("products.taxRate")});
          return;
        }
        taxRate = values[key];
      } else if (key === "categoryId" || key === "subCategoryId") {
        if (!values["categoryId"] || !values["subCategoryId"]) {
          errorMessage = intl.get("common.inputField", {name: intl.get("products.categoryId")});
          return;
        }
        productValues[key] = values[key];
      } else {
        if (!values[key]) {
          errorMessage = intl.get("common.inputField", {name: intl.get("products." + key)});
          return;
        }
        productValues[key] = values[key];
      }
      selectedColumns.push(key);
    });
    if (errorMessage) {
      message.error(errorMessage);
      return;
    }

    const confirmMessage = `${props.selectedProductIds.length}${intl.get("common.message.jian")}${intl.get("products.product")}`;
    if (!window.confirm(intl.get("common.confirm.save", {name: confirmMessage}))) {
      return;
    }
    if (tags.length > 0 && !itemSelectedState.tag) {
      if (window.confirm("Tag 값은 수정되었는데 Tag 변경항목이 선택되지 않았습니다. Tag 수정 사항도 반영하시겠습니까?")) {
        itemSelectedState.tag = true;
      }
    }
    if (itemSelectedState.tag && tags.length === 0) {
      if (!window.confirm("Tag 수정을 선택하고 Tag에 값이 없는 경우 선택한 상품의 기존 모든 Tag가 삭제됩니다. Tag 삭제 반영하시겠습니까?")) {
        return;
      }
    }

    const data = {
      productIds: props.selectedProductIds,
      taxRate: taxRate,
      productValues: productValues,
      columns: selectedColumns,
    }

    setLoding(true);
    ProductApi.saveProducts(data).then(res => {
      props.handleMultiSettingOk();
    }).catch(err => {
      console.log("handleMultiSettingOk error:",  err);
      message.error("오류가 발생했습니다. 관리자에게 문의하세요.");
    }).finally(() => {
      setLoding(false);
    });
  };

  const handleMultiSettingCancel = e => {
    props.handleMultiSettingCancel();
  };

  const onCategorySelect = (value) => {
    const { categories } = props;

    const found = categories.find((c) => c.id === value);

    if (found) {
      setSubCategories(found.subCategories);
    } else {
      setSubCategories([]);
    }
  };

  /////////////////////////////////////////////////////////////////////////////
  // Tag 처리
  const handleRemoveTag = (removedTag) => {
    const product = JSON.parse(JSON.stringify(props.product));
    product.tags = product.tags.filter((t) => t.id !== removedTag.id);
    props.setProduct(product);
  };

  const showTagInput = () => {
    const tagState = { ...tag };
    tagState.tagInputVisible = true;
    setTag(tagState);
  };

  const handleTagInputChange = (e) => {
    const tagState = { ...tag };
    tagState.tagValue = e.target.value;
    setTag(tagState);
  };

  const handleTagInputConfirm = (e) => {
    const tagState = { ...tag };
    if (
      tagState.tagValue.length === 0 ||
      tagState.tagValue.trim().length === 0
    ) {
      tagState.tagInputVisible = false;
      setTag(tagState);
      return;
    }

    const tagsState = JSON.parse(JSON.stringify(tags));

    const sameTag = tagsState.find((m) => m.value === tagsState.tagValue);
    if (sameTag) {
      message.error(intl.get("products.tag.message", {name: tagState.tagValue}));
      tagState.tagInputVisible = false;
      tagState.tagValue = "";
      setTag(tagState);
      return;
    }

    tagState.tagMaxId = tagState.tagMaxId + 1;
    tagsState.push({
      id: tagState.tagMaxId,
      value: tagState.tagValue,
    });
    setTags(tagsState);

    tagState.tagValue = "";
    tagState.tagInputVisible = false;
    setTag(tagState);
  };
  // Tag 처리
  /////////////////////////////////////////////////////////////////////////////

  const getCheckboxLabel = (label, checked, name) => {
    return (
      <Checkbox checked={checked}
                onChange={e => onMultiSettingItemChecked(name, e.target.checked)}>
        {label}
      </Checkbox>
    )
  };

  return (
    <Modal
      title={intl.get("common.button.multiSetting")}
      open={true}
      footer={[
        <Button key="close" onClick={handleMultiSettingCancel}>{intl.get("common.button.cancel")}</Button>,
        <Button key="submit" type="primary" onClick={form.submit}>{intl.get("common.button.ok")}</Button>
      ]}
      onCancel={handleMultiSettingCancel}
      width="800px"
    >
      <Spin spinning={loading}>
        <Form form={form} onValuesChange={onFormValuesChange} onFinish={handleMultiSettingOk}>
          <Descriptions bordered size={"small"} column={1}>
            <Descriptions.Item label={getCheckboxLabel(intl.get("products.published"), itemSelected.published, "published")}>
              <Form.Item name={"published"}>
                <Radio.Group>
                  <Radio value={true}>{intl.get("common.true")}</Radio>
                  <Radio value={false}>{intl.get("common.false")}</Radio>
                </Radio.Group>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={getCheckboxLabel(intl.get("products.brand"), itemSelected.brandId, "brandId")}>
              <Form.Item name={"brandId"}>
                <Select>
                  {props.brands.map((eachBrand) => {
                    return (
                      <Select.Option key={eachBrand.id} value={eachBrand.id}>
                        {eachBrand.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={getCheckboxLabel(intl.get("products.tag"), itemSelected.tag, "tag")}>
              <div>
                {tags.map((tag) => (
                  <Tag
                    key={tag.id}
                    color="purple"
                    closable
                    onClose={(e) => {
                      e.preventDefault();
                      handleRemoveTag(tag);
                    }}
                  >
                    {tag.value}
                  </Tag>
                ))}
                <span style={{ marginLeft: 10 }}>
                  {tag.tagInputVisible && (
                    <Input
                      ref={tagInputRef}
                      type="text"
                      size="small"
                      style={{ width: 200 }}
                      value={tag.tagValue}
                      onChange={handleTagInputChange}
                      onBlur={handleTagInputConfirm}
                      onPressEnter={handleTagInputConfirm}
                    />
                  )}
                  {!tag.tagInputVisible && (
                    <Tag onClick={showTagInput} className="site-tag-plus">
                      <PlusOutlined />
                      {intl.get("products.addTag")}
                    </Tag>
                  )}
                </span>
              </div>
            </Descriptions.Item>
            <Descriptions.Item label={getCheckboxLabel(intl.get("products.category"), itemSelected.categoryId, "categoryId")}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Form.Item
                    name={"categoryId"}
                    style={{ width: 190, marginRight: 10 }}>
                    <Select onSelect={onCategorySelect}>
                      {props.categories.map((eachCategory) => {
                        return (
                          <Select.Option
                            key={eachCategory.id}
                            value={eachCategory.id}
                          >
                            {eachCategory.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name={"subCategoryId"}
                    style={{ width: 300, marginRight: 10 }}>
                    <Select>
                      {subCategories.map((eachCategory) => {
                        return (
                          <Select.Option
                            key={eachCategory.id}
                            value={eachCategory.id}
                          >
                            {eachCategory.name}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
            </Descriptions.Item>
            <Descriptions.Item label={getCheckboxLabel(intl.get("order.cbtType"), itemSelected.cbtType, "cbtType")}>
              <Form.Item name={"cbtType"}>
                <Select>
                  <Select.Option key={""} value={""}>{intl.get("order.cbtType")}</Select.Option>
                  <Select.Option key={"9610"} value={"9610"}>{intl.get("order.cbtType.9610")}</Select.Option>
                  <Select.Option key={"1210"} value={"1210"}>{intl.get("order.cbtType.1210")}</Select.Option>
                  <Select.Option key={"mail"} value={"mail"}>{intl.get("order.cbtType.mail")}</Select.Option>
                  <Select.Option key={"none"} value={"none"}>{intl.get("order.cbtType.none")}</Select.Option>
                </Select>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={getCheckboxLabel(intl.get("products.taxRate") + "(%)", itemSelected.taxRate, "taxRate")}>
              <Form.Item name={"taxRate"} noStyle>
                <InputNumber/>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={getCheckboxLabel(intl.get("products.autoExpiredAt"), itemSelected.autoExpiredAt, "autoExpiredAt")}>
              <Form.Item name={"autoExpiredAt"}>
                <DatePicker onChange={() => onMultiSettingItemChecked("autoExpiredAt", true)} format="YYYY-MM-DD"/>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={getCheckboxLabel(intl.get("products.openToMarket"), itemSelected.openToMarket, "openToMarket")}>
              <Form.Item name={"openToMarket"}>
                <Radio.Group>
                  <Radio value={true}>{intl.get("common.true")}</Radio>
                  <Radio value={false}>{intl.get("common.false")}</Radio>
                </Radio.Group>
              </Form.Item>
            </Descriptions.Item>
          </Descriptions>
        </Form>
      </Spin>
    </Modal>
  )
};

export default ProductMultiSettingPopup;