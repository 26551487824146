import React, {useEffect, useState} from "react";
import {Button, Steps, message, Spin} from "antd";
import DeclarePayment from "./DeclarePayment";
import intl from "react-intl-universal";
import SendOrderToKoreaLogistics from "./SendOrderToKoreaLogistics";
import OrderDelivery from "./OrderDelivery";
import DeliveryStatus from "./DeliveryStatus";
import CreateOrderDeclare from "./CreateOrderDeclare";

const { Step } = Steps;

const ClearanceMail = (props) => {
  const [currentStep, setCurrentStep] = useState(0);

  const onChangeStep = (step) => {
    setCurrentStep(step);
  };

  const steps = [{
      title: "전송정보생성",
    }, {
      title: "한국물류 주문전송",
    }, {
      title: "배송 정보",
    }, {
      title: "배송 현황",
    }];

  return (
    <div>
      <Steps size="small"
             current={currentStep}
             onChange={onChangeStep}>
        { steps.map((step, index) => (<Step key={index} title={step.title}/>)) }
      </Steps>
      <div className="steps-item">
        <div className="steps-item-body">
          <div>
            { currentStep === 0 && (
              <CreateOrderDeclare order={props.order} application={props.application}/>
            )}
            { currentStep === 1 && (
              <SendOrderToKoreaLogistics order={props.order} application={props.application}/>
            )}
            { currentStep === 2 && (
              <OrderDelivery order={props.order} application={props.application} loadOrder={props.loadOrder}/>
            )}
            { currentStep === 3 && (
              <DeliveryStatus order={props.order} application={props.application}/>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClearanceMail;