import React, { useEffect, useState } from "react";
import {DatePicker, message} from "antd";
import DashboardApi from "./DashboardApi";
import dayjs from 'dayjs';
import DashboardCard from './DashboardCard';
import { ResponsiveBar } from '@nivo/bar'
import intl from "react-intl-universal";

const { RangePicker } = DatePicker;

const OrderDailySummary = (props) => {
  const [loading, setLoading] = useState(false);
  const [orderSummaries, setOrderSummaries] = useState([]);
  const [startDate, setStartDate] = useState(dayjs().add(-30, 'day').format("YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(dayjs().format("YYYY-MM-DD"));

  useEffect(() => {
    const params = {
      startDate: startDate,
      endDate: endDate,
    }
    getDailyOrderSummary(params);
  }, []);

  const getDailyOrderSummary = (options) => {
   setLoading(true);

    DashboardApi.getDailyOrderSummary(options).then(res => {
      const dateKeys = [];
      const startDate = dayjs(options.startDate, "YYYY-MM-DD");
      const endDate = dayjs(options.endDate, "YYYY-MM-DD");
      const days = endDate.diff(startDate, 'day');

      let dateKey = startDate;
      for (let i = 0; i < days; i++) {
        dateKey = dateKey.add(1, 'day');
        dateKeys.push(dateKey.format("YYYY-MM-DD"))
      }
      const orderSummariesRes = res.data.map(eachSummary => {
        eachSummary.orderDate = eachSummary.orderDate.substring(0, 10);
        return eachSummary;
      })
      const orderSummaries = dateKeys.map(dateKey => {
        const dateOrderSummary =  orderSummariesRes.find(summary => summary.orderDate.substring(0,10) === dateKey);
        const value = {};
        if (dateOrderSummary) {
          value["OrderDate"] = dateOrderSummary.orderDate.substring(8,10);
          value[intl.get("order.status.wait_pay")] = dateOrderSummary.waitPay;
          value[intl.get("order.status.paid")] = dateOrderSummary.paid;
          value[intl.get("order.status.declaring")] = dateOrderSummary.progressCustomClearance;
          value[intl.get("order.status.declared")] = dateOrderSummary.finishedCustomClearance;
          value[intl.get("order.status.delivering")] = dateOrderSummary.delivering;
          value[intl.get("order.status.delivered")] = dateOrderSummary.delivered;
          value[intl.get("order.status.finished")] = dateOrderSummary.finished;
          value[intl.get("order.status.refund")] = dateOrderSummary.refund;
          value[intl.get("order.status.cancel_req")] = dateOrderSummary.cancelReq
          value[intl.get("order.status.failed")] = dateOrderSummary.failed;
        } else {
          value["OrderDate"] = dateKey.substring(8, 10);
          value[intl.get("order.status.wait_pay")] = 0;
          value[intl.get("order.status.paid")] = 0;
          value[intl.get("order.status.declaring")] = 0;
          value[intl.get("order.status.declared")] = 0;
          value[intl.get("order.status.delivering")] = 0;
          value[intl.get("order.status.delivered")] = 0;
          value[intl.get("order.status.finished")] = 0;
          value[intl.get("order.status.refund")] = 0;
          value[intl.get("order.status.cancel_req")] = 0;
          value[intl.get("order.status.failed")] = 0;
        }
        return value;
      });
      setLoading(false);
      setOrderSummaries(orderSummaries);
    }).catch(error => {
      console.log("OrderStatistics error:", error);
      message.error("기간별 주문 현황 조회 오류");
    }).finally(() => {
      setLoading(false);
    });
  };

  const onDateChange = (dates, dateStrings) => {
    const dateDiff = dates[1].diff(dates[0], 'day');
    if (dateDiff > 31) {
      alert("최대 기간은 31일 입니다.");
      return;
    }
    if (dateDiff < 0) {
      alert("시작일자가 종료일자보다 큰 값입니다.");
      return;
    }

    setStartDate(dateStrings[0]);
    setEndDate(dateStrings[1]);
    getDailyOrderSummary({
      startDate: dateStrings[0],
      endDate: dateStrings[1],
    })
  };

  // const {loading, orderSummaries, startDate, endDate} = this.state;
  const keys = [
    intl.get("order.status.failed"),
    intl.get("order.status.refund"),
    intl.get("order.status.cancel_req"),
    intl.get("order.status.finished"),
    intl.get("order.status.delivered"),
    intl.get("order.status.delivering"),
    intl.get("order.status.declared"),
    intl.get("order.status.declaring"),
    intl.get("order.status.paid"),
    intl.get("order.status.wait_pay")];

  const commonProps = {
    margin: { top: 10, right: 120, bottom: 24, left: 50 },
    data: orderSummaries,
    indexBy: "OrderDate",
    keys,
    padding: 0.2,
    innerPadding: 2,
    labelTextColor: "inherit:darker(1.4)",
    labelSkipWidth: 0,
    labelSkipHeight: 6,
    enableGridX: true,
    colors: { scheme: 'nivo' },
    // valueScale: { type: 'linear' },
    // indexScale: { type: 'band', round: true }
    axisLeft: {
      format: e => Math.floor(e) === e && e
    },
    legends: [{
      dataFrom: 'keys',
      anchor: 'bottom-right',
      direction: 'column',
      justify: false,
      translateX: 120,
      translateY: 0,
      itemsSpacing: 2,
      itemWidth: 100,
      itemHeight: 20,
      itemDirection: 'left-to-right',
      itemOpacity: 0.85,
      symbolSize: 20,
      effects: [
        {
          on: 'hover',
          style: {
            itemOpacity: 1
          }
        }
      ]
    }]
  };

  const dateFormat = 'YYYY-MM-DD';

  const rangePicker = (<RangePicker
    value={[dayjs(startDate, dateFormat), dayjs(endDate, dateFormat)]}
    format={dateFormat}
    onChange={onDateChange}
  />);

  return (
      <div>
        <DashboardCard height="290px" title={intl.get("dashboard.OrderDailySummary.title")} extra={rangePicker}>
          <div style={{height: 250, width: "100%"}}>
            <ResponsiveBar {...commonProps}/>
          </div>
        </DashboardCard>
      </div>
  )
};

export default OrderDailySummary;