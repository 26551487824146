import React, {useState} from "react";
import {Button, Divider, Form, Input, message, Spin} from 'antd';
import {adminConfig} from "../../config/admin.config";
import CompanyUserApi from "../../pages/company/CompanyUserApi";
import "./Login.less";
import ChinaFlag from "../../asset/images/flag-china.svg";
import KoreaFlag from "../../asset/images/flag-korea.svg";
import * as queryString from "query-string";
import LogoImg from "../../asset/images/youma_logo.png";
import {Link, useHistory} from "react-router-dom";
import intl from 'react-intl-universal';
import urlParse from "url-parse";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const ResetPassword = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = (values) => {
    const urlParams = queryString.parse(window.location.search)
    if (!urlParams.token) {
      alert(intl.get("login.resetPassword.wrongUrl"));
      return;
    }

    if (values.password.length < 8) {
      message.error(intl.get("register.shortPassword"));
      return;
    }

    if (values.password !== values.confirm) {
      message.error(intl.get("register.diffPassword"));
      return;
    }
    setLoading(true);
    CompanyUserApi.changePassword(urlParams.token, values.password).then(() => {
      alert(intl.get("login.password.changed"));
      history.push("/login");
    }).catch((err) => {
      if (err.response && err.response.status === 500 && err.response.data) {
        message.error(err.response.data);
      } else {
        message.error(intl.get("common.message.contactAdmin"));
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  const handleLanguageClick = (locale) => {
    cookies.set("locale", locale, {path: '/'});
    window.location.reload();
  }

  const {serviceSetting} = props;

  return (
    <div className="login-page" style={{ height: "100vh"}}>
      <div className="header">
        {serviceSetting.loaded && !serviceSetting.company?.id && (
          <div><a href={adminConfig.portalServer()}><img className="logo-img" src={LogoImg}/></a></div>
        )}
      </div>
      <div className={"contents"}>
        <Spin spinning={loading}>
          <div className={"form-wrapper"}>
          <div className={"title"}>{intl.get("login.resetPassword")}</div>
          <div className={"title-desc"}></div>
          <div className={"form-box"}>
            <Form name="normal_login" initialValues={{remember: true}} onFinish={onFinish} form={form}>
              <div className={"form-item"}>
                <div className={"form-label"}><span style={{color: "#ec5c57", marginRight: 4}}>*</span>{intl.get("company.user.password")}</div>
                <Form.Item name="password"
                           rules={[{required: true, message: intl.get("common.inputField", {name: intl.get("company.user.password")})}]}>
                  <Input.Password className={"input"}/>
                </Form.Item>
              </div>
              <div className={"form-item"}>
                <div className={"form-label"}><span style={{color: "#ec5c57", marginRight: 4}}>*</span>{intl.get("register.confirmingPassword")}</div>
                <Form.Item name="confirm" dependencies={['password']} hasFeedback
                           rules={[{required: true, message: intl.get("common.inputField", {name: intl.get("register.confirmingPassword")})},
                             ({getFieldValue}) => ({
                               validator(rule, value) {
                                 if (!value || getFieldValue('password') === value) {
                                   return Promise.resolve();
                                 }

                                 return Promise.reject(intl.get("register.diffPassword"));
                               },
                             }),
                           ]}
                >
                  <Input.Password className={"input"}/>
                </Form.Item>
              </div>
              <div>
                <Form.Item>
                  <Link to={"/login"}>
                    <span className="login-form-forgot">{intl.get("login.login")}</span>
                  </Link>
                </Form.Item>
              </div>
            </Form>
            <div className={"btn-wrapper"} style={{marginBottom: 48}}>
              <Button block type="primary" onClick={() => form.submit()} className="login-form-button" loading={loading}>
                {intl.get("common.button.save")}
              </Button>
            </div>
          </div>
        </div>
        </Spin>
      </div>
      <div style={{marginTop:24, display: "flex", justifyContent: "center", alignItems: "center"}}>
        <div><img style={{width: 20, height: 20, marginRight: 8}} src={ChinaFlag}/></div>
        <div style={{fontSize: 14, color: "#616161", marginRight: 17, cursor: "pointer"}}
             onClick={() => handleLanguageClick('zh-CN')}>
          简体中文
        </div>
        <Divider type={"vertical"}/>
        <div style={{marginLeft: 17, marginRight: 8}}><img style={{width: 20, height: 20}} src={KoreaFlag}/></div>
        <div style={{fontSize: 14, color: "#616161", cursor: "pointer"}}
             onClick={() => handleLanguageClick('ko-KR')}>
          한국어
        </div>
      </div>
      <div style={{paddingTop: 50}}>
      </div>
    </div>
  );
};

export default ResetPassword;
