import React, {useState, useEffect} from "react";
import ProductSlot from "../index_preivew/ProductSlot";
import BannerSlot from "../index_preivew/BannerSlot";
import ImageSlot from "../index_preivew/ImageSlot";
import LineSlot from "../index_preivew/LineSlot";
import PopupSlot from "../index_preivew/PopupSlot";
import SearchBarSlot from "../index_preivew/SearchBarSlot";
import TextSlot from "../index_preivew/TextSlot";
import ToolbarSlot from "../index_preivew/ToolbarSlot";
import { DeleteFilled } from '@ant-design/icons';
import intl from "react-intl-universal";

import "../index_preivew/Preview.less";

const ComponentPreview = (props) => {
  const {pageItems} = props;

  const getItemComponent = (pageItem) => {
    const passingProps = {
      application: props.application,
      applicationDesign: props.applicationDesign,
      closePopup: props.closePopup,
    }
    if (pageItem.type === "banner") {
      return (<BannerSlot {...pageItem} {...passingProps}/>)
    } else if (pageItem.type === "product") {
      return (<ProductSlot {...pageItem} {...passingProps}/>)
    } else if (pageItem.type === "image") {
      return (<ImageSlot {...pageItem} {...passingProps}/>)
    } else if (pageItem.type === "search") {
      return (<SearchBarSlot {...pageItem} {...passingProps}/>)
    } else if (pageItem.type === "text") {
      return (<TextSlot {...pageItem} {...passingProps}/>)
    } else if (pageItem.type === "line") {
      return (<LineSlot {...pageItem} {...passingProps}/>)
    } else if (pageItem.type === "toolbar") {
      return (<ToolbarSlot {...pageItem} {...passingProps}/>)
    }
  };

  return (
    <div>
      <div className="preview-body-wrapper">
        {pageItems.length === 0 ? (
          <div style={{height: 200, display: "flex", alignItems: "center", justifyContent: "center"}}>
            <div style={{textAlign: "center"}}>표시할 항목이 없습니다.</div>
          </div>
        ) : (
          <div>
            {pageItems.map((eachItem, index) => {
              return (
                <div key={eachItem.id}>
                  {getItemComponent(eachItem)}
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}

export default ComponentPreview;