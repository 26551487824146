import React, { useEffect, useState } from "react";

import {
  Button,
  Form,
  Input,
  Radio,
  Select,
  Descriptions,
  Switch,
  message,
  Popover,
  Tag,
  Space,
  InputNumber,
  Alert,
} from "antd";
import BrandEditPopup from "../brand/BrandEditPopup";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {PlusOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import Util from "../../util/Util";
import FileListPopup from "../file_manager/FileListPopup";
import CategoryNewPopup from "../category/CategoryNewPopup";
import intl from "react-intl-universal";

const ProductBasicDataEdit = (props) => {
  ///////////////////////////////////////////////////////////////////////
  // state and variable
  const product = {...props.product};

  const [visibleCategoryPopup, setVisibleCategoryPopup] = useState(false);
  const [visibleBrandPopup, setVisibleBrandPopup] = useState(false);
  const [visibleFileModal, setVisibleFileModal] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState("");

  const [form] = Form.useForm();

  const tagInputRef = React.useRef(null);
  const [tag, setTag] = useState({
    tagValue: "",
    tagMaxId: 0,
    tagInputVisible: "",
  });

  const [productImage, setProductImage] = useState({
    addingMode: "upload",
    currentImagePath: "",
    maxId: 0,
    addedImages: [],
  });
  // state and variable
  ///////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////
  // react hook
  useEffect(() => {
    if (tag.tagInputVisible) {
      tagInputRef.current.focus();
    }
  }, [tag]);

  // 제품 가격의 currency 심볼을 설정한다.
  useEffect(() => {
    if (props.application.productCurrency) {
      const symbol = Util.getSymbol(props.application.productCurrency);
      setCurrencySymbol(symbol);
    }
  }, [props.application.productCurrency]);

  // Form의 값을 product 정보로 채운다.
  useEffect(() => {
    if (props.product.id) {
      const productImageState = { ...productImage };
      let maxId = productImageState.maxId;
      productImageState.addedImages = product.images?.map((image, index) => {
        maxId++;
        return {
          id: "" + maxId,
          image: image,
        }
      });
      productImageState.maxId = maxId;
      setProductImage(productImageState);
      if (!product.id) {
        form.resetFields();
      } else {
        if (product.marketProduct && product.marketProductMessage) {
          product.published = product.prevPublished;
        }
      }
      form.setFieldsValue(product);
    }
  }, [props.product.id]);
  ///////////////////////////////////////////////////////////////////////

  const handleBrandModalOk = () => {
    props.loadBrands();
    setVisibleBrandPopup(false);
  };

  const handleCategoryModalOk = () => {
    props.loadCategories();
    setVisibleCategoryPopup(false);
  };

  const onCategorySelect = (value) => {
    // category 선책 시 sub-category의 select box 채우기 위한 용도
    product.categoryId = value;
    product.subCategoryId = undefined;
    props.setProduct(product);
  };

  /////////////////////////////////////////////////////////////////////////////
  // product file drag and drop
  const grid = 8;
  const getDragItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    // padding: grid * 2,
    margin: `0 ${grid}px 0 0`,
    // background: "grey",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getDragListStyle = (isDraggingOver) => ({
    background: "white",
    display: "flex",
    flexWrap: "wrap",
    // padding: grid,
    // overflow: 'auto',
  });

  const onDragEnd = (result) => {
    if (product.marketProduct) {
      return;
    }
    const productImageState = JSON.parse(JSON.stringify(productImage));
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    productImageState.addedImages = reorderImages(
      productImageState.addedImages,
      result.source.index,
      result.destination.index
    );
    setProductImage(productImageState);
    setEditingProductImages(productImageState);
  };

  const reorderImages = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  // product file drag and drop
  /////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////
  // 이미지 처리
  const addProductImage = () => {
    const productImageState = { ...productImage };
    if (!productImageState.currentImagePath) {
      message.error(intl.get("common.inputField", {name: intl.get("products.imageLink")}));
      return;
    }
    if (productImageState.currentImagePath.includes("base64")) {
      message.error(intl.get("products.base64.error"));
      setProductImage((prev) => {
        return { ...prev, 
                currentImagePath: "" };
      });
      return;
    }
    let maxId = productImageState.maxId + 1;
    productImageState.addedImages.push({
      id: "" + maxId,
      image: productImageState.currentImagePath,
    });
    productImageState.maxId = maxId;
    productImageState.currentImagePath = "";
    setProductImage(productImageState);

    setEditingProductImages(productImageState);
  };

  const deleteProductImage = (index) => {
    const productImageState = { ...productImage };
    productImageState.addedImages.splice(index, 1);
    setProductImage(productImageState);

    setEditingProductImages(productImageState);
  };

  // 이미지 입력을 위한 설정 또는 이미지 파일명 입력 항목 값 변경인 경우(실제 product의 이미지 변화는 없음)
  const onProductImageChanged = (fieldName, value) => {
    const productImageState = { ...productImage };
    productImageState[fieldName] = value;
    setProductImage(productImageState);
  };

  // 이미지 선택 시
  const okFileModal = (fileType, filePathList) => {
    if (filePathList.length === 0) {
      message.error(intl.get("common.message.selectFile"));
      return;
    }
    const productImageState = { ...productImage };
    let maxId = productImageState.maxId + 1;
    productImageState.addedImages.push({
      id: "" + maxId,
      image: filePathList[0]
    });
    productImageState.maxId = maxId;
    setProductImage(productImageState);
    setEditingProductImages(productImageState);

    setVisibleFileModal(false);
  };
  // 이미지 처리
  /////////////////////////////////////////////////////////////////////////////

  // 이미지 추가, 삭제, 순서 이동 등으로 변경된 사항을 상품 정보에 반영
  // 미리보기에 나타내기 위함
  const setEditingProductImages = (productImageState) => {
    product.images = productImageState.addedImages.map(item => item.image);
    props.setProduct(product);
  };

  /////////////////////////////////////////////////////////////////////////////
  // Tag 처리
  const handleRemoveTag = (removedTag) => {
    product.tags = product.tags.filter((t) => t.id !== removedTag.id);
    props.setProduct(product);
  };

  const showTagInput = () => {
    const tagState = { ...tag };
    tagState.tagInputVisible = true;
    setTag(tagState);
  };

  const handleTagInputChange = (e) => {
    const tagState = { ...tag };
    tagState.tagValue = e.target.value;
    setTag(tagState);
  };

  const handleTagInputConfirm = (e) => {
    const tagState = { ...tag };
    if (
      tagState.tagValue.length === 0 ||
      tagState.tagValue.trim().length === 0
    ) {
      tagState.tagInputVisible = false;
      setTag(tagState);
      return;
    }

    const sameTag =
      product.tags && product.tags.find((m) => m.value === product.tagValue);
    if (sameTag) {
      message.error(intl.get("products.tag.message", {name: tagState.tagValue}));
      tagState.tagInputVisible = false;
      tagState.tagValue = "";
      setTag(tagState);
      return;
    }

    tagState.tagMaxId = tagState.tagMaxId + 1;
    product.tags.push({
      id: tagState.tagMaxId,
      value: tagState.tagValue,
    });
    props.setProduct(product);

    tagState.tagValue = "";
    tagState.tagInputVisible = false;
    setTag(tagState);
  };
  // Tag 처리
  /////////////////////////////////////////////////////////////////////////////

  const getSubCategories = () => {
    const found = props.categories.find((c) => c.id === product.categoryId);

    if (found) {
      return found.subCategories;
    } else {
      return [];
    }
  };

  let disabled = product?.marketProduct ? true : false;
  let disablePublished = product?.marketProduct && !product?.marketProductPublished;

  return (
    <>
      <Form form={form} name="basicDataForm">
        <Descriptions bordered size={"small"} column={2}>
          {props.editMode !== "new" && (
            <Descriptions.Item label="ID" span={2}>
              {product?.marketProduct && (
                <div>
                  <Tag color={"purple"}>{intl.get("products.source.productMarket")}({product.marketProductId})</Tag>
                </div>
              )}
              <Form.Item name="id">
                <Input disabled={true} />
              </Form.Item>
            </Descriptions.Item>
          )}
          {product?.marketProductMessage && (
            <Descriptions.Item label={intl.get("products.marketProductPublished")} span={2}>
              <div>
                <div>{product.marketProductPublished ? intl.get("products.marketProductPublished.help1") : intl.get("products.marketProductPublished.help2")}</div>
                {product.marketProductMessage.split(",").map((message, index) => (
                  <Alert key={index} message={intl.get(message)} type="error" showIcon banner />
                ))}
              </div>
            </Descriptions.Item>
          )}
          {product?.supplierProductId && (
            <Descriptions.Item label={intl.get("products.supplierProductId")} span={2}>
              {product.supplierProductId}
            </Descriptions.Item>
          )}
          <Descriptions.Item label={Util.getLabelWithHelp(intl.get("products.published"), intl.get("products.published.help"))}>
            <Form.Item name={"published"} valuePropName="checked">
              <Switch disabled={disablePublished}/>
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label={Util.getLabelWithHelp(intl.get("products.stopSales"), intl.get("products.stopSales.help"))}>
            <Form.Item name={"stopSales"} valuePropName="checked">
              <Switch/>
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label={Util.getRequiredLabel(intl.get("products.name") + intl.get("products.name.cn"))} span={2}>
            <Form.Item name="name"
                       rules={[{
                         required: true,
                         whitespace: false,
                         message: intl.get("common.inputField", {name: intl.get("products.sort.name_cn")})
                       }]}
            >
              <Input disabled={disabled} autoComplete="off" />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label={intl.get("products.name") + intl.get("products.name.ko")}>
            <Form.Item name="nameKr">
              <Input disabled={disabled} autoComplete="off" />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label={intl.get("products.name") + intl.get("products.name.en")}>
            <Form.Item name="nameEn">
              <Input disabled={disabled} autoComplete="off" />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item  label={Util.getRequiredLabel(intl.get("products.brand"))}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Form.Item name="brandId"
                         style={{ width: 200, marginRight: 10 }}
                         rules={[{
                           required: true,
                           message: intl.get("common.inputField", {name: intl.get("products.brand")})
                         }]}
              >
                <Select disabled={disabled}>
                  {props.brands.map((eachBrand) => {
                    return (
                      <Select.Option key={eachBrand.id} value={eachBrand.id}>
                        {eachBrand.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Button disabled={disabled} size="small" onClick={() => setVisibleBrandPopup(true)}>
                {intl.get("brand.modal.add")}
              </Button>
            </div>
          </Descriptions.Item>
          <Descriptions.Item label={Util.getRequiredLabel(intl.get("products.category"))}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Form.Item
                name="categoryId"
                style={{ width: 100, marginRight: 10 }}
                rules={[{
                  required: true,
                  message: intl.get("common.inputField", {name: intl.get("products.category")})
                }]}
              >
                <Select disabled={disabled} onSelect={onCategorySelect}>
                  {props.categories.map((eachCategory) => {
                    return (
                      <Select.Option
                        key={eachCategory.id}
                        value={eachCategory.id}
                      >
                        {eachCategory.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                name="subCategoryId"
                style={{ width: 150, marginRight: 10 }}
                rules={[{
                  required: true,
                  message: intl.get("common.inputField", {name: intl.get("products.category")})
                }]}
              >
                <Select disabled={disabled} >
                  {getSubCategories().map((eachCategory) => {
                    return (
                      <Select.Option
                        key={eachCategory.id}
                        value={eachCategory.id}
                      >
                        {eachCategory.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Button
                size="small"
                disabled={disabled}
                onClick={() => setVisibleCategoryPopup(true)}
              >
                {intl.get("category.modal.add")}
              </Button>
            </div>
          </Descriptions.Item>
          <Descriptions.Item label={Util.getRequiredLabel(intl.get("products.images"))} span={2}>
            {product.marketProduct ? (
              <>
                {product.images?.map((image, index) => {
                  return (<img key={index} src={image} style={{width: 128, height: 128}}/>)
                })}
              </>
            ) : (
              <div>
                <div style={{ marginBottom: 10 }}>
                  <div style={{ marginBottom: 10 }}>
                    <Radio.Group value={productImage.addingMode}
                                 disabled={disabled}
                                 onChange={(e) =>  onProductImageChanged("addingMode", e.target.value)}>
                      <Radio value={"upload"}>{intl.get("products.edit.selectImage")}</Radio>
                      <Radio value={"link"}>{intl.get("products.edit.inputImage")}</Radio>
                    </Radio.Group>
                  </div>
                  {productImage.addingMode === "link" && (
                    <div style={{ display: "flex" }}>
                      <Input value={productImage.currentImagePath}
                             disabled={disabled}
                             onChange={(e) => onProductImageChanged("currentImagePath", e.target.value)}
                             style={{ width: 500, marginRight: 10 }}
                             onPressEnter={addProductImage}
                      />
                      <div><Button onClick={addProductImage}>{intl.get("common.button.add")}</Button></div>
                    </div>
                  )}
                  {productImage.addingMode === "upload" && (
                    <div><Button disabled={disabled} onClick={() => setVisibleFileModal(true)}>{intl.get("products.button.selectFileOrUpload")}</Button></div>
                  )}
                </div>
                <div>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable" direction="horizontal">
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef}
                          // style={{padding: 10, display: "flex", alignItems: "center"}}
                             style={getDragListStyle(snapshot.isDraggingOver)} {...provided.droppableProps}>
                          {productImage.addedImages.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                              {(provided, snapshot) => (
                                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                     style={getDragItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                                  <div style={{marginRight: 10, width: 128, position: "relative"}}>
                                    <Popover key={item.id} content={item.image} title={""}>
                                      <img src={item.image} style={{ width: 128, height: 128 }}/>
                                    </Popover>
                                    <div style={{ position: "absolute", bottom: 0, left: "50%", transform: "translate(-50%, -50%)" }}>
                                      <Button disabled={disabled}
                                              size={"small"}
                                              type="default"
                                              onClick={() => deleteProductImage(index)}>
                                        {intl.get("common.button.delete")}
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>
            )}
          </Descriptions.Item>
          <Descriptions.Item label={intl.get("products.tag")}>
            {product.tags?.map((tag) => (
              <Tag key={tag.id} color="purple" closable
                   onClose={(e) => {
                     e.preventDefault();
                     handleRemoveTag(tag);
                   }}
              >
                {tag.value}
              </Tag>
            ))}
            <span style={{ marginLeft: 10 }}>
              {tag.tagInputVisible && (
                <Input ref={tagInputRef} value={tag.tagValue} type="text" size="small" style={{ width: 200 }}
                       onChange={handleTagInputChange}
                       onBlur={handleTagInputConfirm}
                       onPressEnter={handleTagInputConfirm}
                />
              )}
              {!tag.tagInputVisible && (
                <Tag onClick={showTagInput} className="site-tag-plus">
                  <PlusOutlined />
                  {intl.get("products.addTag")}
                </Tag>
              )}
            </span>
          </Descriptions.Item>
          <Descriptions.Item label={intl.get("products.originCountry")}>
            <Form.Item name={"originCountry"}>
              <Input disabled={disabled} />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label={intl.get("order.cbtType")}>
            <Form.Item name={"cbtType"}>
              <Select style={{ width: 200 }} disabled={disabled}>
                <Select.Option key={"none"} value={"none"}>{intl.get("order.cbtType.none")}</Select.Option>
                <Select.Option key={"mail"} value={"mail"}>{intl.get("order.cbtType.mail")}</Select.Option>
                <Select.Option key={"1210"} value={"1210"}>{intl.get("order.cbtType.1210")}</Select.Option>
                <Select.Option key={"9610"} value={"9610"}>{intl.get("order.cbtType.9610")}</Select.Option>
              </Select>
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label={intl.get("products.deliveryFee")}>
            <Space>
              <Form.Item name={"deliveryFee"}>
                <InputNumber prefix={currencySymbol} style={{ width: 200 }} disabled={product.dynamicDeliveryFee} min={0} step={0.01}/>
              </Form.Item>
            </Space>
          </Descriptions.Item>
          {!product.marketProduct && (
            <Descriptions.Item label={Util.getLabelWithHelp(intl.get("products.bundled"), intl.getHTML("products.bundled.comment"))}>
              <Form.Item name={"bundled"} valuePropName="checked">
                <Switch disabled={disabled}/>
              </Form.Item>
            </Descriptions.Item>
          )}
          {!product.marketProduct && !product.eventProduct && (
            <Descriptions.Item label={Util.getLabelWithHelp(intl.get("products.openToMarket"), intl.getHTML("products.openToMarket.comment"))}>
              <Form.Item name={"openToMarket"} valuePropName="checked">
                <Switch disabled={disabled}/>
              </Form.Item>
            </Descriptions.Item>
          )}
          {!product.marketProduct && product.eventProduct && (
            <Descriptions.Item label={Util.getLabelWithHelp(intl.get("products.hideToUser"), intl.getHTML("products.hideToUser.help"))} span={2}>
              <Form.Item name={"hideToUser"} valuePropName="checked">
                <Switch/>
              </Form.Item>
            </Descriptions.Item>
          )}
          {Util.isAdmin() && (
            <>
              <Descriptions.Item label={Util.getLabelWithHelp(intl.get("products.wholesaleProduct"), intl.getHTML("products.wholesaleProduct.comment"))}>
                <Space>
                  <Form.Item name={"wholesaleProduct"} valuePropName="checked">
                    <Switch disabled={disabled}/>
                  </Form.Item>
                  <div style={{marginLeft: 20}}>{intl.get("products.wholesaleMinProducts")}</div>
                  <Form.Item name={"wholesaleMinProducts"} noStyle>
                    <InputNumber style={{ width: 150 }} disabled={disabled} min={0} step={1}/>
                  </Form.Item>
                </Space>
              </Descriptions.Item>
              <Descriptions.Item label={intl.get("products.supplier")}>
                <Form.Item name={"supplier"}>
                  <Input style={{width: 200}}/>
                </Form.Item>
              </Descriptions.Item>
            </>
          )}
        </Descriptions>
      </Form>
      <BrandEditPopup
        visible={visibleBrandPopup}
        onOk={handleBrandModalOk}
        onCancel={() => setVisibleBrandPopup(false)}
      />
      <CategoryNewPopup
        visible={visibleCategoryPopup}
        onCancel={handleCategoryModalOk}
      />
      {visibleFileModal && (
        <FileListPopup
          id={"productImage"}
          fileKind={"product"}
          visible={true}
          multipleFile={false}
          canSelect={true}
          handleCancel={() => setVisibleFileModal(false)}
          handleOk={okFileModal}
        />
      )}
    </>
  );
};

export default ProductBasicDataEdit;
