import React, {useEffect, useState} from "react";
import CustomsUploadApi from "./CustomsUploadApi";
import intl from "react-intl-universal";
import {Button, Input, Table, Divider, Spin, DatePicker, Modal, Layout, Descriptions} from "antd";
import Util from "../../util/Util";
import NoImg from "../../asset/images/no_image.png";
import dayjs from "dayjs";
import {SettingOutlined} from "@ant-design/icons";
import JSONPretty from "react-json-pretty";
const {Content} = Layout;

const { RangePicker } = DatePicker;

const initialFilter = {
  startDate: dayjs().subtract(31, "day").format("YYYY-MM-DD"),
  endDate: dayjs().format("YYYY-MM-DD"),
}

const statusMap = {
  "getting-sign-value": "처리중",
  "sent_realtime_data_upload": "전송완료",
  "error_realtime_data_upload": "에러"
};

const CustomsUploadList = (props) => {
  const [loading, setLoading] = useState(false);
  const [customsUploads, setCustomsUploads] = useState([]);
  const [filter, setFilter] = useState(initialFilter);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [selectedItem, setSelectedItem] = useState({});
  const [visibleDetail, setVisibleDetail] = useState(false);

  useEffect(() => {
    searchCustomsUpload(pagination);
  }, []);

  const onDateOk = (value, dateString) => {
    if (value[1]) {
      const {filter} = props;
      filter.startDate =  value[0].format("YYYY-MM-DD HH:mm:ss");
      filter.endDate = value[1].format("YYYY-MM-DD HH:mm:ss");
      props.setFilter(filter);
    }
  }

  const searchCustomsUpload = (paginationState) => {
    setLoading(true);
    CustomsUploadApi.getCustomsUploads(getSearchParam(paginationState)).then(res => {
      const pagingResult = res.data;
      const pagination = {
        current: pagingResult.page,
        pageSize: pagingResult.pageSize,
        total: pagingResult.count,
      }
      setCustomsUploads(pagingResult.data);
      setPagination(pagination);
    }).finally(() => {
      setLoading(false);
    });
  }

  const getSearchParam = (pagination) => {
    const params = {
      pageSize: pagination.pageSize,
      page: pagination.current,
    };
    if (filter.productId) {
      params.productId = filter.productId
    } else {
      params.startDate = filter.startDate;
      params.endDate = filter.endDate;
    }
    return params;
  };

  const pageChanged = (pagination) => {
    searchCustomsUpload(pagination);
  };

  const onSearchClick = () => {
    const paginationState = {...pagination};
    paginationState.current = 1;
    searchCustomsUpload(paginationState);
  }

  const filterChanged = (fieldName, value) => {
    const filterState = {...filter}
    filterState[fieldName] = value;
    setFilter(filterState);
  };

  const columns = [{
    title: "ID",
    dataIndex: 'id',
    key: 'id',
    align: 'center',
  }, {
    title: intl.get("order.order") + "ID",
    dataIndex: 'orderId',
    key: 'orderId',
    align: 'center',
  }, {
    title: "SessionID",
    dataIndex: 'sessionId',
    key: 'sessionId',
    align: 'center',
  }, {
    title: intl.get("order.status"),
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    render: (text, record) => (
      <div>{statusMap[text] ? statusMap[text] : text}</div>
    )
  }, {
    title: intl.get("order.clearance.error_message"),
    dataIndex: 'errorMessage',
    key: 'errorMessage',
  }, {
    title: intl.get("common.createdAt"),
    dataIndex: 'createdAt',
    key: 'createdAt',
    align: 'center',
    render: (text, record) => (
      <span>{text ? text.substring(0, 10) : ""}</span>
    )
  }];

  const dateRange = [dayjs(filter.startDate), dayjs(filter.endDate)];

  return (
    <div className="page-content">
      <Spin spinning={loading}>
        <div className="page-op-box">
          <div className="filter-box">
            <Input style={{width: 100}} value={filter.productId} placeholder={intl.get("products.id")} onChange={(e) => filterChanged('productId', e.target.value)}/>
            <Divider type={"vertical"}/>
            <RangePicker
              format="YYYY-MM-DD"
              placeholder={[intl.get("order.searchOrderDateStart"), intl.get("order.searchOrderDateEnd")]}
              onOk={onDateOk}
              value={dateRange}
              allowClear={false}
              style={{ marginRight: 10 }}
            />
            <div style={{marginLeft: "auto"}}>
              <div style={{width: 100}}>
                <Button style={{width: 100}} type="primary" block size="default" onClick={onSearchClick}>{intl.get("common.button.search")}</Button>
              </div>
            </div>
          </div>
        </div>
        <div style={{marginTop: "10px", cursor: "pointer"}}>
          <Table size={'small'}
                 dataSource={customsUploads}
                 columns={columns}
                 rowKey={'id'}
                 title={() => intl.get("common.label.listTotal", {total: pagination.total})}
                 pagination={pagination}
                 onChange={pageChanged}
                 onRow={(record, rowIndex) => {
                   return {
                     onClick: event => {
                       setSelectedItem(record);
                       setVisibleDetail(true);
                     },
                   }
                 }}
          />
        </div>
      </Spin>
      {visibleDetail && (
        <Modal
          title={[<div key={1}><SettingOutlined/> </div>]}
          visible={true}
          onOk={() => setVisibleDetail(false)}
          onCancel={() => setVisibleDetail(false)}
          cancelButtonProps={{ style: { display: 'none' } }}
          width={800}
          bodyStyle={{margin: 0, padding: 0}}>
          <Layout>
            <Content className="modal-content">
              <Descriptions bordered size={"small"} layout="vertical" column={1}>
                <Descriptions.Item label={"Request"}>
                  <div style={{fontSize: 10, overflow: "auto", width: 700}}>
                    <JSONPretty id={"json-pretty-req"} data={selectedItem.requestBody}></JSONPretty>
                  </div>
                </Descriptions.Item>
                <Descriptions.Item label={"Response"}>
                  <div style={{fontSize: 10, overflow: "auto", width: 700}}>
                    <JSONPretty id={"json-pretty-res"} data={selectedItem.responseBody}></JSONPretty>
                  </div>
                </Descriptions.Item>
              </Descriptions>
            </Content>
          </Layout>
        </Modal>
      )}
    </div>
  )
}

export default CustomsUploadList;