import React, {useState, useEffect} from "react";
import {Form, Modal, message, Layout} from "antd";
import intl from "react-intl-universal";
import {SettingOutlined} from "@ant-design/icons";
import ProductSkuEditForm from "./ProductSkuEditForm";

const {Content} = Layout;

const ProductSkuFormPopup = (props) => {
  const [editingSku, setEditingSku] = useState({});
  const [form] = Form.useForm();

  useEffect(() => {
    const sku = JSON.parse(JSON.stringify(props.sku));
    setEditingSku(sku);
  }, [props.sku.id]);

  const skuValueChanged = (values) => {
    setEditingSku(values);
    if (values.useSkuPrice) {
      props.findSkuPriceError(values);
    }
  };

  const submitSku = async (formName, info) => {
    const skuFormPassed = await info.forms["skuForm"].validateFields().then(() => {
      return true;
    }).catch(errorInfo => {
      return false;
    });
    if (!skuFormPassed) {
      return;
    }
    const editingSkuState = JSON.parse(JSON.stringify(editingSku));
    if (!editingSkuState.skuImage) {
      message.error(intl.get("common.inputField", {name: intl.get("products.sku.imageUrl")}));
      return;
    }

    Object.assign(editingSkuState, info.forms["skuForm"].getFieldsValue());
    props.handleOk(editingSkuState);
  }

  return (
    <Modal
      title={[<div key={1}><SettingOutlined/> {props.editMode === "new" ? intl.get("products.sku.add") : intl.get("products.sku.edit")}</div>]}
      open={true}
      onCancel={props.handleCancel}
      onOk={() => form.submit()}
      width={900}
      bodyStyle={{margin: 0, padding: 0}}>
      <Layout>
        <Content className="modal-content">
          <Form.Provider onFormFinish={submitSku}>
            <Form name="skuRootForm" form={form}></Form>
            <ProductSkuEditForm skuValueChanged={skuValueChanged}
                                {...props}/>
          </Form.Provider>
        </Content>
      </Layout>
    </Modal>
  )
}

export default ProductSkuFormPopup;