import React from 'react';
import hexRgb from 'hex-rgb';
import {useLayoutState} from "../../components/layout/AppLayoutContext";
import {defaultAppDesign, themeColorSet} from "../../components/layout/DefaultMiniAppDesign";

const ApplicationPreviewFrame = (props) => {
  const layoutState = useLayoutState();
  const {application} = layoutState;

  if (!props.applicationDesign || !props.applicationDesign.themeSetting) {
    return (<div/>)
  }

  const {navigationBar, themeColor} = props.applicationDesign.themeSetting;

  Object.keys(defaultAppDesign.themeSetting.navigationBar).forEach(key => {
    if (!navigationBar[key]) {
      navigationBar[key] = defaultAppDesign.themeSetting.navigationBar[key];
    }
  });

  let titlePadding = "0px 0px 0px 10px";
  if (navigationBar.padding) {
    titlePadding = "";
    navigationBar.padding.split(",").forEach(p => {
      titlePadding += p + 'px ';
    });
  }

  const colorTable = themeColor.colorTable ? themeColor.colorTable : themeColorSet.theme1;
  const navigationBarBgColor = colorTable.titleBarBg;
  const navigationBarBgColorOpacity = navigationBarBgColor + "90";

  return (
    <div style={{width: "375px", minHeight: 700}}>
      <div>
        <div style={{ position: "relative", width: "375px", height: "44px",
          borderTop: "1px solid gray",
          borderLeft: "1px solid gray",
          borderRight: "1px solid gray",
          boxSizing: "content-box"}}>
          <div style={{
            position: "absolute",
            left: 0,
            top: 0,
            backgroundColor: navigationBarBgColor,
            height: '44px',
            lineHeight: '44px',
            verticalAlign: 'middle',
            width: "375px",
          }}>
            <div style={{marginLeft: 20, fontSize: navigationBar.fontSize + 'px', fontWeight: navigationBar.fontWeight, color: colorTable.titleBarText}}>
              { navigationBar.type === "text" && (<div style={{textAlign: "left"}}>{props.title ? props.title : (application.appTitle ? application.appTitle : "Sample")}</div>) }
              { navigationBar.type === "index_notitle" && (<span/>) }
              { (navigationBar.type === "all_image" || navigationBar.type === "index_image") && (
                  <div style={{textAlign: navigationBar.titleAlign}}>
                    <img src={navigationBar.image} style={{width: navigationBar.imageWidth + 'px', height: '15px'}}/>
                  </div>
                )}
            </div>
          </div>
          <div style={{ position: "absolute", backgroundColor: navigationBarBgColor, left: 285, top: 0, width: "90px", height: "15px"}}/>
          <div style={{ position: "absolute", left: 280, top: 10, width: "88px", height: "24px", marginRight: 3, backgroundColor: "rgba(64, 64, 64, 1)", borderRadius: "20px"}}/>
          <div style={{ position: "absolute", backgroundColor: navigationBarBgColorOpacity, left: 280, top: 10, width: "90px", height: "24px", borderRadius: "20px"}}>
            <div>
              <div  style={{ backgroundColor: "white", width: "3px", height: "3px", position: "absolute", top: 10, left: 14, borderRadius: "50%"}}/>
              <div  style={{ backgroundColor: "white", width: "6px", height: "6px", position: "absolute", top: 9, left: 20, borderRadius: "50%"}}/>
              <div  style={{ backgroundColor: "white", width: "3px", height: "3px", position: "absolute", top: 10, left: 30, borderRadius: "50%"}}/>
            </div>
            <div  style={{ backgroundColor: navigationBarBgColorOpacity, width: "16px", height: "16px", position: "relative", top: 3, left: 58, border: "2px solid white", borderRadius: "50%"}}>
              <div style={{ backgroundColor: "white", top: "25%", left: "25%", width: "50%", height: "50%", position: "absolute", border: navigationBarBgColorOpacity, borderRadius: "50%"}}>
                  &nbsp;
              </div>
            </div>
          </div>
        </div>
        <div style={{position: "absolute", width: 375,
          backgroundColor: "#f8f8f8",
          borderLeft: "1px solid gray",
          borderRight: "1px solid gray",
          borderBottom: "1px solid gray",
          boxSizing: "content-box"}}>
          { props.children }
        </div>
      </div>
    </div>
  )
};

export default ApplicationPreviewFrame;