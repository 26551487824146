import React from "react";
import {Link, useHistory} from "react-router-dom";
import {Button, Divider, Input, message, Select, Table, Spin} from "antd";
import intl from "react-intl-universal";
import Util from "../../util/Util";
import CouponEventApi from "./CouponEventApi";

const CouponEventList = (props) => {
  const history = useHistory();
  const {couponEvents, pagination, filter} = props;

  const deleteCouponEvent = (e, couponEvent) => {
    e.stopPropagation();
    if (!window.confirm(intl.get("common.confirm.delete", {name: couponEvent.name}))) {
      return;
    }
    CouponEventApi.deleteCouponEvent(couponEvent).then(res => {
      message.success(intl.get("common.message.deleted"));
      props.searchCouponEvents(pagination);
    });
  };

  const handleSearchClick = () => {
    pagination.current = 1;
    props.searchCouponEvents(pagination);
  };

  const changeType = (value) => {
    const {filter} = props;
    filter.type = value;
    props.setFilter(filter);
  };

  const changeName = (e) => {
    const {filter} = props;
    filter.name = e.target.value;
    props.setFilter(filter);
  };

  const changeActive = (value) => {
    const {filter} = props;
    filter.active = value;
    props.setFilter(filter);
  };

  const columns = [{
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
    }, {
      title: intl.get("coupon.name"),
      dataIndex: 'name',
      key: 'name',
    }, {
      title: intl.get("coupon.type"),
      dataIndex: 'type',
      key: 'type',
      render: (text, record) => (
          <span>{intl.get("coupon.type." + text)}</span>
      )
    }, {
      title: intl.get("coupon.active"),
      dataIndex: 'active',
      key: 'active',
      align: 'left',
      render: (text, record) => (
          <span>{record.active ? intl.get("coupon.active.true") :  intl.get("coupon.active.false") }</span>
      )
    }, {
      title: intl.get("coupon.amountAndRate"),
      dataIndex: 'discountAmount',
      key: 'discountAmount',
      align: 'right',
      render: (text, record) => {
        if (record.discountType === "price") {
         return (
           <div>
             <div>{Util.currencyFormat(record.discountAmountPrice.originPrice, props.application.productCurrency)}</div>
             <div style={{color: "gray", fontSize: 13}}>{Util.currencyFormat(record.discountAmountPrice.secondaryPrice, props.application.userPayCurrency)}</div>
           </div>
         )
        } else {
          return (<span>{record.discountAmount + "%"}</span>)
        }
      }
    }, {
      title: intl.get("coupon.duration"),
      dataIndex: ' startDate',
      key: 'startDate',
      align: 'center',
      render: (text, record) => (
          <span>{record.startDate} ~ {record.endDate}</span>
      )
    }, {
      title: intl.get("common.createdAt"),
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: (text, record) => (
          <span>{Util.getLocalTime(text).substring(0, 10)}</span>
      )
    }, {
      title: "",
      dataIndex: 'id',
      key: 'action',
      align: 'center',
      render: (text, record) => (
        <Button size="small" onClick={(e) => deleteCouponEvent(e, record)}>{intl.get("common.button.delete")}</Button>
      )
    }];

  return (
      <Spin spinning={props.loading}>
        <div className="page-op-box">
          <div className="filter-box">
            <Input style={{ width: 200, marginRight: 10 }} value={filter.name} onChange={changeName} placeholder={intl.get("coupon.name")}/>
            <Divider type="vertical" />
            <Select value={filter.active ? filter.active : undefined}
                    allowClear={true}
                    placeholder={intl.get("coupon.active")}
                    onClear={() => changeActive('')}
                    onSelect={(value) => changeActive(value)}
                    style={{width: 100}}>
              <Select.Option key={"true"} value={"true"}>{intl.get("coupon.active.true")}</Select.Option>
              <Select.Option key={"false"} value={"false"}>{intl.get("coupon.active.false")}</Select.Option>
            </Select>
            <div style={{marginLeft: "auto"}}>
              <div style={{width: 100}}>
                <Button style={{width: 100}} type="primary" block size="default" onClick={handleSearchClick}>{intl.get("common.button.search")}</Button>
              </div>
            </div>
          </div>
          <div className="extra-op-box">
            <Link to={`${props.match.path}/new`}>
              <Button type="default" size="small">{intl.get("common.button.add")}</Button>
            </Link>
          </div>
        </div>
        <div style={{marginTop: "10px"}}>
          <Table size={'small'}
                 dataSource={couponEvents}
                 columns={columns}
                 rowKey={'id'}
                 title={() => intl.get("common.label.total") + " " + pagination.total}
                 pagination={pagination}
                 onRow={(record, rowIndex) => {
                   return {
                     onClick: event => history.push(`/coupon-events/${record.id}`),
                   };
                 }}
          />
        </div>
      </Spin>
  );
}

export default CouponEventList;