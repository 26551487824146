import React, {useCallback, useEffect, useState} from "react";

const OrderSettlement = (props) => {
  return (
    <div>
      <div className="page-content">
        주문정산
      </div>
    </div>
  )
};

export default OrderSettlement;