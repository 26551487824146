import axios from "axios";
import {adminConfig} from "../../config/admin.config";

const API_BASE_URL = adminConfig.apiServer();

export default class CompanyUserApi {
  static getMy = () => {
    let apiPath = `${API_BASE_URL}/company-users/my?projection=webAdmin&`;
    return axios.get(apiPath);
  };

  static getCompanyUsers = (options) => {
    let apiPath = `${API_BASE_URL}/company-users?projection=webAdmin&`;

    apiPath += Object.keys(options).map(key => {
      return key + "=" + options[key]
    }).join("&")

    return axios.get(apiPath);
  };

  static getCompanyUser = (userId) => {
    let apiPath = `${API_BASE_URL}/company-users/${userId}`;

    return axios.get(apiPath);
  };

  static existsLoginId = (loginId) => {
    let apiPath = `${API_BASE_URL}/company-users/check-loginid?loginId=${loginId}`;
    return axios.get(apiPath);
  }

  static createUser = (companyUser) => {
    const apiPath = `${API_BASE_URL}/company-users?projection=webAdmin`;

    return axios.post(apiPath, companyUser);
  };

  static saveUser = (companyUser) => {
    const apiPath = `${API_BASE_URL}/company-users/${companyUser.id}?projection=webAdmin`;

    return axios.put(apiPath, companyUser);
  };

  static deleteUser = (userId) => {
    const apiPath = `${API_BASE_URL}/company-users/${userId}?projection=webAdmin`;

    return axios.delete(apiPath);
  };

  static addAppRole = (userId, appId, role) => {
    const apiPath = `${API_BASE_URL}/company-users/${userId}/applications`;

    const applicationUserRole = {
      companyUserId: parseInt(userId, 10),
      applicationId: appId,
      role: role,
    };

    return axios.post(apiPath, applicationUserRole);
  };

  static removeAppRole = (userId, appRoleId) => {
    const apiPath = `${API_BASE_URL}/company-users/${userId}/applications/${appRoleId}?projection=webAdmin`;
    return axios.delete(apiPath);
  };

  static changePassword = (token, password) => {
    const apiPath = `${API_BASE_URL}/company-users/password?token=${token}`;
    const user = {
      password: password
    };

    return axios.put(apiPath, user);
  };

  static setCompanyCode = (userId, companyCode) => {
    const apiPath = `${API_BASE_URL}/company-users/${userId}/company-code?companyCode=${companyCode}&projection=webAdmin`;

    return axios.put(apiPath);
  }

  static findPassword = (loginId, email) => {
    const apiPath = `${API_BASE_URL}/company-users/find-password?loginId=${loginId}&email=${email}`;
    return axios.get(apiPath);
  }
}