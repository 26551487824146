import React, {useEffect, useState} from "react";
import {Descriptions, Form, Input, Spin, Button, message, Divider} from "antd";
import intl from "react-intl-universal";
import QuestionApi from "./QuestionApi";
import {Link} from "react-router-dom";
import {useHistory} from "react-router-dom";
import Util from "../../util/Util";
import ReplyList from "./ReplyList";

const QuestionEdit = (props) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [question, setQuestion] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.match?.params.id) {
      // 목록에 있는 경우 dirty되었을 수도 있기 때문에 edit view에서 다시 가져온다.
      loadQuestion(props.match.params.id);
    }
  }, [props.match?.params.id]);

  const loadQuestion = (id) => {
    QuestionApi.getQuestion(id).then(res => {
      const question = res.data;

      setQuestion(question);
      form.setFieldsValue(question);
    });
  };

  const submitQuestion = (values) => {
    Object.assign(question, values);
    setLoading(true);
    QuestionApi.submitQuestion(question).then(res => {
      message.success(intl.get("common.message.saved"));
      if (props.afterSave) {
        props.afterSave();
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <Spin spinning={loading}>
      <div style={{width: "100%"}}>
        <Form onFinish={submitQuestion} form={form}>
          <Descriptions bordered size={"small"} column={1}>
            {question.id && (
              <Descriptions.Item label="ID">
                <Form.Item name={"id"}>
                  <Input disabled={true}/>
                </Form.Item>
              </Descriptions.Item>
            )}
            <Descriptions.Item label={intl.get("questions.title")}>
              <Form.Item name={"title"}
                         rules={[{ required: true, message: intl.get("common.inputField", {name: intl.get("questions.title")}) }]}>
                <Input disabled={question.id}/>
              </Form.Item>
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("questions.contents")}>
              <Form.Item name={"contents"}
                         rules={[{ required: true, message: intl.get("common.inputField", {name: intl.get("questions.contents")}) }]}>
                <Input.TextArea rows={10}
                                disabled={question.id}/>
              </Form.Item>
            </Descriptions.Item>
          </Descriptions>
        </Form>
        <div style={{marginTop: 10, width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
          <Button type="primary" onClick={() => form.submit()}>{intl.get("common.button.save")}</Button>
          <Divider type="vertical"/>
          {props.mode === "modal" ? (
              <>
                <Button type="default" onClick={() => {props.onCancel(); history.push("/questions")}}>
                  {intl.get("common.button.list")}
                </Button>
                <Divider type="vertical"/>
                <Button type="default" onClick={() => props.onCancel()}>{intl.get("common.button.close")}</Button>
              </>
          ) : (
            <Button type="default">
              <Link to={`${props.parentPath}`}>{intl.get("common.button.list")}</Link>
            </Button>
          )}
        </div>
        {question.id && Util.isAdmin() && (
          <div style={{marginTop: 10, width: "100%"}}>
            <ReplyList
              question={question}
              afterSave={() => loadQuestion(question.id)}
            />
          </div>
        )}
      </div>
    </Spin>
  )
};

export default QuestionEdit;