import React, {useEffect, useState} from "react";
import {Carousel} from "antd";
import Util from "../../../util/Util";
import ReactPlayer from "react-player";
import intl from "react-intl-universal";
import BannerTemplateOneTwoPreview from "./BannerTemplateOneTwoPreview";

const BannerSlot = (props) => {
  const [settingValues, setSettingValues] = useState({});

  useEffect(() => {
    setSettingValues(JSON.parse(JSON.stringify(props.settingValues)));
  }, [props.settingValues])
  // const {id, settingValues} = props;

  let contentStyle = {
    height: settingValues.height + "px",
    color: '#000',
    lineHeight: settingValues.height + "px",
    textAlign: 'center',
    background: '#fff'
  };

  let iconBoxPadding = "";
  let iconPadding = "";
  let iconGroupSlots = [];

  if (!settingValues.slots || settingValues.slots.length === 0) {
    return (
        <div className={"empty-slot"} style={{height: settingValues.height}}>
          <h3>{intl.get("page_setting.edit.banner.area")}</h3>
          <div>{intl.get("page_setting.edit.banner.area.help")}</div>
        </div>
    )
  }

  let splitRatios = settingValues.slots ? settingValues.slots.map(slot => 100/settingValues.slots.length) : 1;
  try {
    if (settingValues.splitRatio) {
      splitRatios = settingValues.splitRatio.split(",");
    }
  } catch(err) {
  }
  return (
      <div style={{height: settingValues.height, marginBottom: 0, backgroundColor: "white"}}>
        <section className="fullimg_type">
          <div className="box">
            { settingValues.displayType === "template" && (
              <BannerTemplateOneTwoPreview settingValues={settingValues}/>
            )}
            { settingValues.displayType === "swiper" && (
                <Carousel autoplay={settingValues.autoPlay}>
                  {
                    settingValues.slots.map(slot => {
                      return (
                          <div key={slot.id} style={contentStyle}>
                            {
                              slot.bannerMediaType === "image" ?
                                  (<img style={{width:373, height: settingValues.height}} src={Util.encodeImagePath(slot.bannerPath)}/>)
                                  :
                                  (<ReactPlayer width="375px" height={settingValues.height + "px"} url={slot.bannerPath}/>)
                            }
                          </div>
                      )
                    })
                  }
                </Carousel>
            )}
            {settingValues.displayType === "split" && (
                <div style={contentStyle}>
                  <div style={{display: "flex"}}>
                    {
                      settingValues.slots.map((slot, index) => {
                        return (
                            <div style={{flexBasis: splitRatios[index] + "%"}} key={slot.id}>
                              {
                                slot.bannerMediaType === "image" ?
                                    (<img style={{width:"98%", height: settingValues.height}} src={Util.encodeImagePath(slot.bannerPath)}/>)
                                    :
                                    (<ReactPlayer width="98%" height={settingValues.height + "px"} url={slot.bannerPath}/>)
                              }
                            </div>
                        )
                      })
                    }
                  </div>
                </div>
            )
            }
            {settingValues.displayType === "icon" && settingValues.iconLayout === 'wrap' && (
                <div style={contentStyle}>
                  <div style={{display: "flex", flexWrap: 'wrap',  justifyContent: "space-around", padding: iconBoxPadding}}>
                    {
                      settingValues.slots.map((slot, index) => {
                            return (
                                <div key={slot.id} style={{borderRadius: settingValues.iconBorderRadius, boxShadow: settingValues.iconBoxShadow, position: "relative", padding: iconPadding}}>
                                  <img style={{width: settingValues.iconWidth + "px", height: settingValues.iconHeight + "px"}} src={Util.encodeImagePath(slot.bannerPath)}/>
                                  {slot.title && (
                                      <div style={{textAlign: "center",
                                        fontSize: settingValues.titleFontSize ? settingValues.titleFontSize + 'px' : 12,
                                        color: settingValues.titleColor ? settingValues.titleColor : '#000000'}}>{slot.title}</div>
                                  )}
                                  {slot.action === "logistics_order" && (<div style={{position: "absolute", top: 0, right: 10, color: "#f9944b", fontSize: 14}}>5件</div>)}
                                </div>
                            )
                          }
                      )}
                  </div>
                </div>
            )}
            {settingValues.displayType === "icon" && settingValues.iconLayout === 'slide' && (
                <Carousel autoplay={settingValues.autoPlay ? true : false} dots={false}>
                  {
                    iconGroupSlots.map((group, index) => {
                      return (
                          <div key={index}>
                            <div style={{display: "flex", justifyContent: "space-around", padding: iconBoxPadding, width: 375}}>
                              {
                                group.map((slot, sindex) => {
                                  return (
                                      <div key={sindex} style={{padding: iconPadding}}>
                                        <img style={{width: settingValues.iconWidth + "px", height: settingValues.iconHeight + "px"}} src={Util.encodeImagePath(slot.bannerPath)}/>
                                        {slot.title && (
                                            <div style={{textAlign: "center",
                                              fontSize: settingValues.titleFontSize ? settingValues.titleFontSize + 'px' : 12,
                                              color: settingValues.titleColor ? settingValues.titleColor : '#000000'}}>{slot.title}</div>
                                        )}
                                      </div>
                                  )
                                })
                              }
                            </div>
                          </div>
                      )
                    })
                  }
                </Carousel>
            )}
          </div>
        </section>
      </div>
  )
};

export default BannerSlot;