import React, { useContext, useState, useEffect, useRef } from 'react';
import {
  Form,
  Input,
  Button
} from 'antd';

const EditableContext = React.createContext(null);

const EditableRow = ({
    index,
    ignoreEditing=false,
    renderCell,
    ...props
  }) => {
  const [form] = Form.useForm();
  const contextValue = {
    form: form,
    ignoreEditing: ignoreEditing,
    renderCell: renderCell,
  };
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={contextValue}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
    title,
    editable,
    suffix,
    children,
    dataIndex,
    record,
    handleSave,
    ...restProps
  }) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const {form, ignoreEditing, renderCell} = useContext(EditableContext);

  useEffect(() => {
    if (!ignoreEditing && editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    if (!ignoreEditing) {
      setEditing(!editing);
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    }
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({...record, ...values}, dataIndex, values[dataIndex]);
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (!ignoreEditing && editable) {
    childNode = editing ? (
      <Form.Item style={{margin: 0, width: "100%"}}
                 name={dataIndex}
                 rules={ [{
                    required: true, message: `${title} is required.`,
                 }] }
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} suffix={suffix}/>
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  if (!ignoreEditing && editable) {
    return <td {...restProps}>{childNode}</td>;
  } else {
    if (renderCell) {
      return <td {...restProps}>{renderCell(dataIndex, record)}</td>
    } else {
      return <td {...restProps}>{childNode}</td>;
    }
  }
};

export {
  EditableRow,
  EditableCell,
}