import React, {useEffect, useState, useCallback} from "react";
import {Input, Select, Descriptions, message, Button, Radio, Tabs, Divider, Space, Switch, Checkbox} from "antd";
import ApplicationApi from "./ApplicationApi";
import MissingFaceImage from "../../asset/images/missing-face.png";
import IconWallet from "../../asset/images/icon-wallet.svg";
import IconBox from "../../asset/images/icon-box.svg";
import IconDelivery from "../../asset/images/icon-delivery.svg";
import IconSpeech from "../../asset/images/icon-speech.svg";
import IconSuccess from "../../asset/images/icon-success.svg";
import NoImage from "../../asset/images/no_image.png";
import FileListPopup from "../file_manager/FileListPopup";
import ApplicationPreviewFrame from "./ApplicationPreviewFrame";
import {themeColorSet, defaultAppDesign, themeColorLabel} from "../../components/layout/DefaultMiniAppDesign";
import intl from "react-intl-universal";
import {PriceView} from "../app_page/index_preivew/product/PriceView";
import {ShoppingCartOutlined} from "@ant-design/icons";
import {useLayoutState} from "../../components/layout/AppLayoutContext";
import "./ApplicationDesignEdit.css";
import ProductPreviewSelectSku from "../product/ProductPreviewSelectSku";

const { Search } = Input;

const myPageMenuList = [{
    image: IconWallet,
    name: '全部',
  }, {
    image: IconBox,
    name: '待配送',
  }, {
    image: IconDelivery,
    name: '配送中',
  }, {
    image: IconSpeech,
    name: '待评价',
  }, {
    image: IconSuccess,
    name: '完成',
  },
];

const products = [{
  id: 1,
  name: "Test1",
  images: [NoImage],
  listPrice: { paymentPrice: 253 },
  salesPrice: { paymentPrice: 231, secondaryPrice: 45.62 },
}, {
  id: 2,
  name: "Test2",
  images: [NoImage],
  listPrice: { paymentPrice: 150 },
  salesPrice: { paymentPrice: 420, secondaryPrice: 143.62 },
}];
//   , {
//   id: 3,
//   name: "Test1",
//   images: [NoImage],
//   listPrice: { paymentPrice: 250 },
//   salesPrice: { paymentPrice: 545, secondaryPrice: 145.62 },
// }, {
//   id: 4,
//   name: "Test1",
//   images: [NoImage],
//   listPrice: { paymentPrice: 234 },
//   salesPrice: { paymentPrice: 200, secondaryPrice: 245.62 },
// }];


const groupingProducts = [{
  product: products[0],
  skus: [{
    skuImage: NoImage,
    optionText: "Option1",
  }, {
    skuImage: NoImage,
    optionText: "Option2",
  }],
}];

const ApplicationDesignEdit = (props) => {
  const layoutState = useLayoutState();
  const [applicationDesign, setApplicationDesign] = useState(defaultAppDesign);
  const [fileModalConfig, setFileModalConfig] = useState({
    fileType: "",
    visibleFileModal: false,
  });

  useEffect(() => {
    loadApplicationDesign();
  }, []);

  const loadApplicationDesign = () => {
    ApplicationApi.getApplicationDesign().then(res => {
      const design = res.data;
      // 이전 버전의 themeColor 설정값을 삭제한다. 아레 코드는 나중에는 지워야 함
      if (design.themeSetting.themeColor.buttonStyle) {
        design.themeSetting.themeColor = {...defaultAppDesign.themeSetting.themeColor};
      }
      setApplicationDesign(design);
    });
  }

  const onNavigationBarChanged = (fieldName, value) => {
    const appDesign = {...applicationDesign};
    appDesign.themeSetting.navigationBar[fieldName] = value;
    setApplicationDesign(appDesign);
  };

  const onWebmallChanged = (fieldName, value) => {
    const appDesign = {...applicationDesign};
    if (!appDesign.themeSetting.webmall) {
      appDesign.themeSetting.webmall = {};
    }
    appDesign.themeSetting.webmall[fieldName] = value;
    setApplicationDesign(appDesign);
  };

  const validateSubmitData = () => {
    const appDesign = {...applicationDesign};
    const {priceDisplaySetting} = appDesign;
    return true;
  };

  const submitAppDesign = () => {
    if (!validateSubmitData()) {
      return;
    }
    const appDesign = {...applicationDesign};
    delete appDesign.priceDisplaySetting.secondaryCurrency;

    if (appDesign.themeSetting.navigationBar["titleAlign"] === "left") {
      appDesign.themeSetting.navigationBar["backgroundPositionX"] = "20";
    } else {
      delete appDesign.themeSetting.navigationBar["backgroundPositionX"];
    }

    // 사용하지 않는 속성 삭제
    delete appDesign.themeSetting.navigationBar.backgroundColor;
    delete appDesign.themeSetting.navigationBar.titleColor;
    if (appDesign.themeSetting.myPage) {
      delete appDesign.themeSetting.myPage;
    }

    ApplicationApi.submitAppDesign(appDesign).then(res => {
      message.success(intl.get("common.message.saved"));
      setApplicationDesign(res.data);
    });
  };

  const publishAppDesign = () => {
    if (!applicationDesign.id) {
      message.error(intl.get("application.design.message.deployAfterSave"));
      return;
    }
    if (!validateSubmitData()) {
      return;
    }
    const appDesign = {...applicationDesign};
    ApplicationApi.publishAppDesign(appDesign.id).then(res => {
      message.success(intl.get("application.design.message.deployed"));
      setApplicationDesign(res.data);
    });
  }

  const onAppShareImageChanged = (value) => {
    const appDesign = {...applicationDesign};
    appDesign.appShareImage = value;
    setApplicationDesign(appDesign);
  };

  const onAppLogoImageChanged = (value) => {
    const appDesign = {...applicationDesign};
    appDesign.appLogoImage = value;
    setApplicationDesign(appDesign);
  };

  const showFileModal = (fileType) => {
    setFileModalConfig({
      fileType: fileType,
      visibleFileModal: true,
    });
  };

  const cancelFileModal = () => {
    const fileModalConfigState = {...fileModalConfig};
    fileModalConfigState.visibleFileModal = false;
    setFileModalConfig(fileModalConfigState);
  };

  const okFileModal = (fileType, filePathList) => {
    if (filePathList.length === 0) {
      message.error(intl.get("common.inputField", {"name": intl.get("page_setting.edit.media.file")}));
      return;
    }

    if (fileType === "appShareImage") {
      onAppShareImageChanged(filePathList[0]);
    } else if (fileType === "appLogoImage") {
      onAppLogoImageChanged(filePathList[0]);
    } else if (fileType === "navigationBarImage") {
      onNavigationBarChanged("image", filePathList[0]);
    } else if (fileType === "webmallLogo") {
      onWebmallChanged("logo", filePathList[0]);
    } else if (fileType === "webmallBanner") {
      onWebmallChanged("banner", filePathList[0]);
    } else {
      message.error(intl.get("application.design.message.wrongFileType"));
    }
    setFileModalConfig({
      fileType: "",
      visibleFileModal: false,
    });
  };

  const onThemeColorNameChanged = useCallback((e) => {
    const themeName = e.target.value;
    const appDesign = JSON.parse(JSON.stringify(applicationDesign));

    appDesign.themeSetting.themeColor.name = themeName;

    //themeColor.customColorTable에는 사용자가 정의한 custom 컬러 설정이 있다.
    //themeColor.colorTable는 현재 사용하고 있는 컬러 조합이다.
    //name == custom인 경우 themeColor.customColorTable => themeColor.colorTable로 복사한다.
    //화면에 표시되는 것은 항상 themeColor.colorTable이다.
    if (themeName === "custom") {
      if (appDesign.themeSetting.themeColor.customColorTable) {
        // 기존에 정의해 놓은 customColorTable가 있으면 이 값으로 colorTable에 복사
        appDesign.themeSetting.themeColor.colorTable = {...appDesign.themeSetting.themeColor.customColorTable};
      } else {
        // 기존에 정의해 놓은 customColorTable가 없으면 디폴트 custom 컬러 사용
        appDesign.themeSetting.themeColor.colorTable = {...themeColorSet.custom};
      }
    } else {
      appDesign.themeSetting.themeColor.colorTable = {...themeColorSet[themeName]};
    }
    setApplicationDesign(appDesign);
  }, [applicationDesign])

  const onThemeCustomColorChanged = useCallback((colorName, value) => {
    const appDesign = {...applicationDesign};
    appDesign.themeSetting.themeColor.customColorTable[colorName] = value;
    appDesign.themeSetting.themeColor.colorTable[colorName] = value;
    setApplicationDesign(appDesign);
  }, [applicationDesign]);

  const onChangePriceDisplaySetting = useCallback((fieldName, value) => {
    const appDesign = {...applicationDesign};
    if (fieldName === "mainCurrency") {
      appDesign.priceDisplaySetting.subCurrency = value !== "CNY" ? "CNY" : value;
    } else if (fieldName === "subCurrency" && value !== "none") {
      appDesign.priceDisplaySetting.mainCurrency = value !== "CNY" ? "CNY" : value;
    }
    appDesign.priceDisplaySetting[fieldName] = value;
    setApplicationDesign(appDesign);
  }, [applicationDesign]);

  let {navigationBar, themeColor, webmall} = applicationDesign.themeSetting;
  let {priceDisplaySetting} = applicationDesign;

  ///////////////////////////////////////////////
  // 값이 없는 경우 초기값으로 설정
  // if (!myPage)  myPage = {};
  if (!navigationBar)  navigationBar = {};
  if (!themeColor)  themeColor = {};
  if (!priceDisplaySetting)  priceDisplaySetting = {};

  Object.keys(defaultAppDesign.themeSetting.navigationBar).forEach(key => {
    if (!navigationBar[key]) {
      navigationBar[key] = defaultAppDesign.themeSetting.navigationBar[key];
    }
  });

  Object.keys(defaultAppDesign.themeSetting.themeColor).forEach(key => {
    if (!themeColor[key]) {
      themeColor[key] = defaultAppDesign.themeSetting.themeColor[key];
    }
  });

  Object.keys(defaultAppDesign.themeSetting.priceDisplaySetting).forEach(key => {
    if (!priceDisplaySetting[key]) {
      priceDisplaySetting[key] = defaultAppDesign.themeSetting.priceDisplaySetting[key];
    }
  });
  ///////////////////////////////////////////////

  const tab1 = (
    <Descriptions bordered size={"small"} column={1}>
      <Descriptions.Item label={intl.get("application.design.app.shareImage")}>
        <Search
          allowClear
          placeholder={intl.get("common.label.upload.help")}
          onChange={(e) => onAppShareImageChanged(e.target.value)}
          value={applicationDesign.appShareImage}
          onSearch={() => showFileModal("appShareImage")}
        />
      </Descriptions.Item>
      <Descriptions.Item label={intl.get("application.design.app.logo")}>
        <Search
          allowClear
          placeholder={intl.get("common.label.upload.help")}
          onChange={(e) => onAppLogoImageChanged(e.target.value)}
          value={applicationDesign.appLogoImage}
          onSearch={() => showFileModal("appLogoImage")}
        />
      </Descriptions.Item>
      <Descriptions.Item label={intl.get("application.design.selectTheme")}>
        <Radio.Group value={themeColor.name} onChange={onThemeColorNameChanged}>
          <div style={{display: "flex", gap: 20}}>
            {Object.keys(themeColorSet).map((key, index) => {
              const colorSet = themeColorSet[key];
              let themeName = intl.get("application.design.selectTheme") + (index + 1);
              if (index === Object.keys(themeColorSet).length - 1) {
                themeName = intl.get("application.design.userDefined") + " " + intl.get("application.design.theme")
              }
              return (
                <div key={index} style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                  <Radio value={key}>{themeName}</Radio>
                  <div style={{backgroundColor: colorSet.primary200, width: 50, height: 50,  cursor: "pointer"}}/>
                </div>
              )
            })}
          </div>
        </Radio.Group>
      </Descriptions.Item>
      <Descriptions.Item label={intl.get("application.design.colorTable")}>
        <div style={{display: "flex", justifyContent: "space-around", flexWrap: "wrap"}}>
          { Object.keys(themeColorLabel).map((key, index) => {
            const colorName = key;
            const colorLabel = themeColorLabel[colorName];
            return (
              <div key={index} style={{marginTop: 20, textAlign: "center", flex: "0 0 33.3333%"}}>
                { key.startsWith("empty") && (
                  <div></div>
                )}
                { !key.startsWith("empty") && (key === "titleBarText" || key === "mypageText") && (
                  <div>
                    <div>{colorLabel}</div>
                    <Radio.Group
                      disabled={themeColor.name !== 'custom'}
                      onChange={(e) => onThemeCustomColorChanged(colorName, e.target.value)}
                      value={themeColor.colorTable[colorName] || "#000000"}>
                      <Radio value="#FFFFFF">White</Radio>
                      <Radio value="#000000">Black</Radio>
                    </Radio.Group>
                  </div>
                )}
                { !key.startsWith("empty") && (key !== "titleBarText" && key !== "mypageText") && (
                  <>
                    <div>{colorLabel}</div>
                    <input type={"color"}
                           disabled={themeColor.name !== 'custom'}
                           value={themeColor.colorTable[colorName] || "#ffffff"}
                           style={{height: 50, marginRight: 10}}
                           onChange={(e) => onThemeCustomColorChanged(colorName, e.target.value)}/>
                  </>
                )}
              </div>
            )
          })}
        </div>
      </Descriptions.Item>
    </Descriptions>
  );

  const tab2 = (
    <Descriptions bordered size={"small"} column={1}>
      <Descriptions.Item label={intl.get("application.design.fontSize")}>
        <Input value={navigationBar.fontSize} onChange={e => onNavigationBarChanged("fontSize", e.target.value)} />
      </Descriptions.Item>
      <Descriptions.Item label={intl.get("application.design.fontWeight")}>
        <Switch checked={navigationBar.fontBold} onClick={(checked) => onNavigationBarChanged("fontBold", checked)} />
      </Descriptions.Item>
      <Descriptions.Item label={intl.get("application.design.title.style")}>
        <Select style={{width: 400}} value={navigationBar.type} onSelect={(value) => onNavigationBarChanged('type', value)}>
          <Select.Option value={"text"}>{intl.get("application.design.title.style1")}</Select.Option>
          <Select.Option value={"index_notitle"}>{intl.get("application.design.title.style2")}</Select.Option>
          <Select.Option value={"all_image"}>{intl.get("application.design.title.style3")}</Select.Option>
          <Select.Option value={"index_image"}>{intl.get("application.design.title.style4")}</Select.Option>
        </Select>
      </Descriptions.Item>
      {(navigationBar.type === "all_image" || navigationBar.type === "index_image" ) && (
        <Descriptions.Item label={intl.get("application.design.image")}>
          <div>
            <Search
              allowClear
              onChange={(e) => onNavigationBarChanged('image', e.target.value)}
              value={navigationBar.image}
              onSearch={() => showFileModal("navigationBarImage")}
            />
          </div>
          <div style={{marginTop: 10}}>
            <Space>
              <Space>
                <div>{intl.get("page_design.width")}:</div>
                <Input value={navigationBar.imageWidth}
                       style={{ width: 70 }}
                       onChange={e=> onNavigationBarChanged('imageWidth', e.target.value)}
                       suffix={"px"}/>
              </Space>
              <Space>
                <div>{intl.get("page_design.height")}:</div>
                <Input value={navigationBar.imageHeight}
                       style={{ width: 70 }}
                       onChange={e=> onNavigationBarChanged('imageHeight', e.target.value)}
                       suffix={"px"}/>
              </Space>
            </Space>
          </div>
        </Descriptions.Item>
      )}
      <Descriptions.Item label={intl.get("application.design.align")}>
        <Radio.Group
          disabled={navigationBar.type === "text"}
          value={navigationBar.titleAlign}
          onChange={(e) => onNavigationBarChanged("titleAlign", e.target.value)}>
          <Radio value={"left"}>Left</Radio>
          <Radio value={"center"}>Center</Radio>
        </Radio.Group>
      </Descriptions.Item>
      <Descriptions.Item label={""}>
        <Checkbox checked={navigationBar.searchIconOnIndex} onChange={e => onNavigationBarChanged("searchIconOnIndex", e.target.checked)}>
          {intl.get("application.design.searchIconOnIndex")}
        </Checkbox>
      </Descriptions.Item>
    </Descriptions>
  );

  const tab3 = (
    <Descriptions bordered size={"small"} column={1}>
      <Descriptions.Item label={intl.get("application.design.priceSetting.display")}>
        <div>
          <span>$200</span>
        </div>
        <div>
          <span>$150</span><span style={{marginLeft: 10}}>打7.5折</span>
        </div>
        <div>
          <span>约</span><span>¥546.78</span>
        </div>
      </Descriptions.Item>
      <Descriptions.Item label={intl.get("application.design.priceSetting.mainCurrency")}>
        <Radio.Group value={priceDisplaySetting.mainCurrency} onChange={(e) => onChangePriceDisplaySetting('mainCurrency', e.target.value)}>
          <Radio value={"CNY"}>{intl.get("application.design.priceSetting.CNY")}</Radio>
          <Radio value={"product"}>{intl.get("application.design.priceSetting.productCurrency")}</Radio>
          {/*<Radio value={"payment"}>{intl.get("application.design.priceSetting.paymentCurrency")}</Radio>*/}
        </Radio.Group>
      </Descriptions.Item>
      <Descriptions.Item label={intl.get("application.design.priceSetting.subCurrency")}>
        <Radio.Group value={priceDisplaySetting.subCurrency} onChange={(e) => onChangePriceDisplaySetting('subCurrency', e.target.value)}>
          <Radio value={"CNY"}>{intl.get("application.design.priceSetting.CNY")}</Radio>
          <Radio value={"product"}>{intl.get("application.design.priceSetting.productCurrency")}</Radio>
          {/*<Radio value={"payment"}>{intl.get("application.design.priceSetting.paymentCurrency")}</Radio>*/}
          <Radio value={"none"}>{intl.get("application.design.priceSetting.hidden")}</Radio>
        </Radio.Group>
      </Descriptions.Item>
      <Descriptions.Item label={intl.get("application.design.priceSetting.usdFormat")}>
        <Radio.Group value={priceDisplaySetting.usdFormat} onChange={(e) => onChangePriceDisplaySetting('usdFormat', e.target.value)}>
          <Radio value={"char"}>{intl.get("application.design.priceSetting.writtenByText")}</Radio>
          <Radio value={"symbol"}>{intl.get("application.design.priceSetting.writtenBySymbol")}</Radio>
        </Radio.Group>
      </Descriptions.Item>
      <Descriptions.Item label={intl.get("application.design.priceSetting.showAbout")}>
        <Radio.Group value={priceDisplaySetting.showAbout} onChange={(e) => onChangePriceDisplaySetting('showAbout', e.target.value)}>
          <Radio value={true}>{intl.get("application.design.priceSetting.show")}</Radio>
          <Radio value={false}>{intl.get("application.design.priceSetting.hidden")}</Radio>
        </Radio.Group>
      </Descriptions.Item>
      <Descriptions.Item label={intl.get("application.design.priceSetting.showDiscount")}>
        <Radio.Group value={priceDisplaySetting.showDiscount} onChange={(e) => onChangePriceDisplaySetting('showDiscount', e.target.value)}>
          <Radio value={"none"}>{intl.get("application.design.priceSetting.hidden")}</Radio>
          <Radio value={"ratio"}>{intl.get("application.design.priceSetting.discountRate")}</Radio>
          <Radio value={"price"}>{intl.get("application.design.priceSetting.discountPrice")}</Radio>
        </Radio.Group>
      </Descriptions.Item>
    </Descriptions>
  );

  const tab4 = (
    <Descriptions bordered size={"small"} column={1}>
      <Descriptions.Item label={intl.get("application.design.webmall.logo")}>
        <div>
          <Search
            allowClear
            onChange={(e) => onWebmallChanged('logo', e.target.value)}
            value={webmall?.logo || ""}
            onSearch={() => showFileModal("webmallLogo")}
          />
        </div>
      </Descriptions.Item>
      <Descriptions.Item label={intl.get("application.design.webmall.banner")}>
        <div>
          <Search
            allowClear
            onChange={(e) => onWebmallChanged('banner', e.target.value)}
            value={webmall?.banner || ""}
            onSearch={() => showFileModal("webmallBanner")}
          />
        </div>
      </Descriptions.Item>
    </Descriptions>
  );

  return (
      <div>
        <div className="page-content">
          <div style={{display: "flex", alignItems: "stretch"}}>
            <div style={{width: 375, height: 2000}}>
              <ApplicationPreviewFrame applicationDesign={applicationDesign}>
                <div className="user-section"
                     style={{backgroundColor: themeColor.colorTable.mypageBg}}>
                  <div className="user-info-box">
                    <div className="portrait-box">
                      <img className="portrait" src={MissingFaceImage}/>
                    </div>
                    <div className="info-box">
                      <div className="username" style={{color: themeColor.colorTable.mypageText}}>游客</div>
                    </div>
                    <div className="b-btn login"
                         style={{
                           color: themeColor.colorTable.mypageText,
                           backgroundColor: themeColor.colorTable.mypageText + "20"}}>
                      登录
                    </div>
                  </div>
                </div>
                <div className="order-section">
                  <div className="order-section-title">
                    <div style={{color: "#212121", fontSize: 14}}>订单情况</div>
                    <div className="more">
                      <div>全部订单</div>
                    </div>
                  </div>
                  <div className="order-items">
                    {myPageMenuList.map((item, index) => {
                      return (
                        <div key={index} className="item">
                          <img src={item.image} style={{width: 24, height: 24}} />
                          <div className={"label"}>{item.name}</div>
                        </div>
                      ); })
                    }
                  </div>
                </div>
                <ProductListDesignPreview
                  applicationDesign={applicationDesign}
                  application={layoutState.application}
                />
                <div style={{backgroundColor: "#FFFFFF", border: "solid 1px #000000"}}>
                  <ProductPreviewSelectSku
                    productName={products[0].name}
                    groupingProducts={groupingProducts}
                    application={layoutState.application}
                    appDesign={applicationDesign}
                  />
                </div>
              </ApplicationPreviewFrame>
            </div>
            <div style={{flex: 1, overflow: "auto", marginLeft: 30}}>
              <div className="card-container">
                <Tabs defaultActiveKey="1"
                      type={"card"}
                      items={[{
                        key: "1",
                        label: intl.get("application.design.basic"),
                        children: tab1,
                      }, {
                        key: "2",
                        label: intl.get("application.design.titleBar"),
                        children: tab2,
                      }, {
                        key: "3",
                        label: intl.get("application.design.priceSetting"),
                        children: tab3,
                      }, {
                        key: "4",
                        label: intl.get("application.design.webmall"),
                        children: tab4,
                      }]}
                  />
              </div>
              <div style={{marginTop: 20, textAlign: "center"}}>
                <Button type="primary" onClick={submitAppDesign}>{intl.get("common.button.save")}</Button>
                <Divider type="vertical"/>
                <Button type="primary" onClick={publishAppDesign}>{intl.get("page_setting.edit.publish")}</Button>
              </div>
            </div>
          </div>
        </div>
        {fileModalConfig.visibleFileModal && (
          <FileListPopup id={fileModalConfig.fileType}
                         fileKind={"index"}
                         visible={true}
                         multipleFile={false}
                         canSelect={true}
                         handleCancel={cancelFileModal}
                         handleOk={okFileModal}
          />
        )}
      </div>
  )
}

const ProductListDesignPreview = (props) => {
  const {applicationDesign} = props;
  const { themeColor } = applicationDesign.themeSetting;

  return (
    <div className="two-column-box" style={{backgroundColor: "#ffffff"}}>
      <div style={{backgroundColor: "#ffffff"}}>
        <div className="content-box" style={{columnGap: 16, rowGap: 16, padding: 15}}>
          {products.map(product => (
            <div key={product.id} className="item-box"
                 style={{width: 160, borderRadius: 4}}>
              <img
                style={{
                  width: 160, height: 160,
                  borderTopRightRadius: "inherit",
                  borderTopLeftRadius: "inherit"
                }}
                src={product.images[0]}/>
              <div style={{width: "100%", padding: "0 10px", marginTop: 8}}>
                <div className="u-line-2 title" style={{width: "100%", height: 40, fontSize: 14}}>
                  {product.name}
                </div>
                <div style={{display: "flex", alignItems: "flex-end"}}>
                  <PriceView listPrice={product.listPrice}
                             salesPrice={product.salesPrice}
                             width={128}
                             {...props}
                  />
                  <div style={{marginLeft: "auto"}}>
                    <ShoppingCartOutlined style={{ fontSize: '20px', color: themeColor.colorTable.cart }}/>
                  </div>
                </div>
              </div>

            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ApplicationDesignEdit;