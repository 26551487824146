import React, {useState, useEffect} from "react";
import { Select, Space, message } from "antd";
import AddressData from "./AddressData";
import intl from 'react-intl-universal';
import postCodeData from "./OpendbCityChina.json";

// https://gitee.com/dcloud/opendb/blob/master/collection/opendb-city-china/data.json
const AddressSelect = (props) => {
  const [editingAddress, setEditingAddress] = useState({
    addressCode: "",
    postCode: "",
    province: {},
    city: {},
    country: {},
  });

  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [counties, setCounties] = useState([]);

  useEffect(() => {
    initAddressData();
  }, []);

  useEffect(() => {
    if (!props.address) {
      return;
    }
    if (props.address?.addressCode) {
      findAddress();
    }
  }, [props.address]);

  const initAddressData = () => {
    const provinces = [];

    let currentProvince;
    let currentCity;
    postCodeData.forEach(item => {
      if (item.type === 0) {
        if (currentProvince) {
          provinces.push(currentProvince);
        }
        currentProvince = item;
        currentProvince.cities = [];
      } else if (item.type === 1) {
        currentCity = item;
        currentProvince.cities.push(currentCity);
        currentCity.counties = [];
      } else {
        currentCity.counties.push(item);
      }
    });
    provinces.push(currentProvince);
    setProvinces(provinces);
  };

  const findAddress = () => {
    const country = postCodeData.find(pc => pc.code === props.address.addressCode && pc.type === 2);
    if (!country) {
      message.error("주소정보오류");
      return;
    }
    const city = postCodeData.find(pc => pc.code === country.parent_code && pc.type === 1);
    if (!city) {
      message.error("주소정보오류");
      return;
    }
    const province = postCodeData.find(pc => pc.code === city.parent_code);
    if (!province) {
      message.error("주소정보오류");
      return;
    }

    const editingAddressState = {
      addressCode: props.address.addressCode,
      postCode: country.postCode,
      province: province,
      city: city,
      country: country,
    }
    setEditingAddress(editingAddressState);
    setCities(province.cities);
    setCounties(city.counties);
    // props.selectCounty(editingAddressState);
  }

  const handleChangeProvince = (value) => {
    const province = provinces.find(p => p.code === value);
    if (!province) {
      message.error(intl.get("common.message.contactAdmin"));
      return;
    }
    const editingAddressState = {...editingAddress};
    editingAddressState.province = province
    editingAddressState.city = {};
    editingAddressState.country = {};
    setEditingAddress(editingAddressState);
    // props.selectCounty(editingAddressState);

    setCities(province.cities);
    setCounties([]);
  }

  const handleChangeCity = (value) => {
    if (!value) {
      return;
    }
    const editingAddressState = {...editingAddress};
    const province = provinces.find(p => p.code === editingAddressState.province.code);
    const city = province.cities.find(c => c.code === value);
    if (!city) {
      message.error(intl.get("common.message.contactAdmin"));
      return;
    }
    editingAddressState.city = city;
    editingAddressState.country = {};
    setEditingAddress(editingAddressState);
    // props.selectCounty(editingAddressState);

    setCounties(city.counties);
  }

  const handleChangeCounty = (value) => {
    if (!value) {
      return;
    }
    const editingAddressState = {...editingAddress};
    const province = provinces.find(p => p.code === editingAddressState.province.code);
    const city = province.cities.find(c => c.code === editingAddressState.city.code);
    const county = city.counties.find(c => c.code === value)
    if (!county) {
      message.error(intl.get("common.message.contactAdmin"));
      return;
    }
    editingAddressState.country = county;

    setEditingAddress(editingAddressState);
    props.selectCounty(editingAddressState);
  }

  if (provinces.length == 0) {
    return (<div></div>)
  }

  return(
    <div style={{marginBottom: 5}}>
      <Space gap={5}>
        <Select disabled={props.disabled}
                style={{width: 150, marginRight: 10}}
                value={editingAddress.province.code}
                onChange={handleChangeProvince}
                options={provinces.map(p => {
                  return {value: p.code, label: p.name}
                })}
        />
        <Select disabled={props.disabled}
                style={{width: 150, marginRight: 10}}
                value={editingAddress.city.code}
                onChange={handleChangeCity}
                options={cities.map(c => {
                  return {value: c.code, label: c.name}
                })}
        />

        <Select disabled={props.disabled}
                style={{width: 250}}
                value={editingAddress.country.code}
                onChange={handleChangeCounty}
                options={counties.map(c => {
                  return {value: c.code, label: c.name}
                })}
        />
      </Space>
    </div>
  )
};

export default AddressSelect;