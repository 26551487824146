import {adminConfig} from "../../config/admin.config";
import axios from "axios";
const API_BASE_URL = adminConfig.apiServer();

export default class SupplierApi {
  static getOrderSummary = (params) => {
    let apiPath = `${API_BASE_URL}/suppliers/order-summary?`;

    let paramKeyValues = []
    Object.keys(params).forEach(key => {
      if (params[key]) {
        const paramValue = params[key];
        paramKeyValues.push(key + "=" + paramValue)
      }
    });
    apiPath += paramKeyValues.join("&");
    apiPath += "&projection=webAdmin";

    return axios.get(apiPath);
  };

  static getDailyOrderSummary = (params) => {
    let apiPath = `${API_BASE_URL}/suppliers/order-daily-summary?`;

    let paramKeyValues = []
    Object.keys(params).forEach(key => {
      if (params[key]) {
        const paramValue = params[key];
        paramKeyValues.push(key + "=" + paramValue)
      }
    });
    apiPath += paramKeyValues.join("&");
    apiPath += "&projection=webAdmin";

    return axios.get(apiPath);
  };

  static getProducts = (params) => {
    let apiPath = `${API_BASE_URL}/suppliers/products?`;

    let paramKeyValues = []
    Object.keys(params).forEach(key => {
      if (params[key]) {
        const paramValue = params[key];
        paramKeyValues.push(key + "=" + paramValue)
      }
    });
    apiPath += paramKeyValues.join("&");
    apiPath += "&projection=webAdmin";

    return axios.get(apiPath);
  }

  static getOrders = (params) => {
    let apiPath = `${API_BASE_URL}/suppliers/orders?`;

    let paramKeyValues = []
    Object.keys(params).forEach(key => {
      if (params[key]) {
        const paramValue = params[key];
        paramKeyValues.push(key + "=" + paramValue)
      }
    });
    apiPath += paramKeyValues.join("&");
    apiPath += "&projection=webAdmin";

    return axios.get(apiPath);
  }
}