import React, {createContext, useContext, useEffect, useState} from "react";
import {
  message,
  Spin,
  Steps,
  Drawer,
  Button,
  Divider,
  Form
} from "antd";

import './Product.less';
import ProductBasicDataEdit from "./ProductBasicDataEdit";
import ProductSkuEdit from "./ProductSkuEdit";
import ProductDescEdit from "./ProductDescEdit";
import ProductApi from "./ProductApi";
import ProductPreview from "./ProductPreview";
import {useHistory} from "react-router-dom";
import intl from "react-intl-universal";
import EventProductSkuEdit from "./EventProductSkuEdit";
import {Link} from "react-router-dom";

const initialProduct = {
  listPrice: 0,
  salesPrice: 0,
  images: [],
  descMode: 'image',
  descImages: [],
  tags: [],
  skus: [{id: 0}],
  deliveryFee: 0,
  cbtType: 'none',
  eventProduct: false,
};

const ProductEditor = (props) => {
  const history = useHistory();
  const [uploading, setUploading] = useState(true);
  const [visibleSkuPreview, setVisibleSkuPreview] = useState(false);
  const [product, setProduct] = useState(initialProduct);
  const [visiblePreview, setVisiblePreview] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (props.editMode === "modify") {
      // 목록에 있는 경우 dirty되었을 수도 있기 때문에 editor에서는 상품정보를 다시 가져온다.
      loadProduct(props.id);
    } else {
      const newProduct = JSON.parse(JSON.stringify(initialProduct));
      newProduct.id = props.id; // 리액트 컴포넌트의 상태 변화 관리를 위한 임시 ID
      newProduct.eventProduct = props.eventProduct;
      newProduct.cbtType = props.application.defaultCbtType;
      if (props.eventProduct) {
        newProduct.skus = [];
      }
      setProduct(newProduct);
      setUploading(false);
    }
  }, [props.id]);

  useEffect(() => {
    if (props.editMode !== "modify") {
      const productState = JSON.parse(JSON.stringify(product));
      if (!productState.cbtType) {
        productState.cbtType = props.application.defaultCbtType;
        setProduct(productState);
      }
    }
  }, [props.application]);

  const loadProduct = (id) => {
    setUploading(true);
    ProductApi.getProduct(id).then(res => {
      const productRes = res.data;
      if (productRes.eventProduct) {
        getEventOriginProductAndSet(productRes);
      } else {
        setProduct(productRes);
      }
    }).finally(() => {
      setUploading(false);
    });
  };

  const deleteProduct = () => {
    if (!window.confirm(intl.get("common.confirm.delete", {name: intl.get("products.product")}))) {
      return;
    }
    setUploading(true);
    ProductApi.deleteProduct(product.id).then(res => {
      message.info(intl.get("common.message.deleted"));
      const {pagination} = props;
      props.searchProducts(pagination);
      history.push(props.parentPath);
    }).finally(() => {
      setUploading(false);
    });
    return;
  };

  const getEventOriginProductAndSet = (loadedProduct) => {
    const eventOriginProductIds = [...new Set(loadedProduct.skus.map(s => s.eventOriginProductId))];

    ProductApi.getProducts({id: eventOriginProductIds, pageSize: eventOriginProductIds.length}).then(res => {
      const eventOriginProducts = res.data.data;   //paging result
      const skusWithEventOriginProducts = loadedProduct.skus.map(sku => {
        const foundProduct = eventOriginProducts.find(p => p.id === sku.eventOriginProductId);
        sku.productName = foundProduct ? foundProduct.name : "";
        return sku;
      });
      loadedProduct.skus = skusWithEventOriginProducts;
      setProduct(loadedProduct);
    });
  };

  const setProductState = (productState) => {
    setProduct(JSON.parse(JSON.stringify(productState)));
  };

  const validateForm = (formName, subForm) => {
    return subForm.validateFields().then(() => {
      return true;
    }).catch(errorInfo => {
      return false;
    });
  }

  const submitProduct = async (formName, info) => {
    if (formName !== "productRootForm") {
      return;
    }
    const productState = JSON.parse(JSON.stringify(product));

    const basicDataFormPassed = await validateForm(formName, info.forms["basicDataForm"]);
    if (!basicDataFormPassed) {
      return;
    }
    Object.assign(productState, info.forms["basicDataForm"].getFieldsValue());

    if (info.forms["skuForm"]) {
      // skuForm이 있는 경우는 SKU 1개만 있는 상품 등록 시
      const skuFormPassed = await validateForm(formName, info.forms["skuForm"]);
      if (!skuFormPassed) {
        return;
      }
      if (!productState.skus?.length) {
        // sku가 없는 경우
        const sku = info.forms["skuForm"].getFieldsValue();
        sku.added = true;
        productState.skus = [sku];
      } else {
        Object.assign(productState.skus[0], info.forms["skuForm"].getFieldsValue());
      }
    }

    if (props.editMode !== "modify") {
      productState.id = 0;
    }

    if (productState.marketProduct && !productState.marketProductPublished) {
      message.error(intl.get("products.marketProduct.message"));
      return;
    }

    // SKU가 한개인 경우 기본값 설정
    if (productState.skus.length === 1) {
      // SKU이미지가 입력되지 않은 경우 상품 이미지를 사용
      if (!productState.skus[0].skuImage && productState.images) {
        productState.skus[0].skuImage = productState.images[0];
      }
      // 상품의 ListPrice, SalesPrice를 SKU 값으로 사용
      productState.listPrice = productState.skus[0].listPrice;
      productState.salesPrice = productState.skus[0].salesPrice;
    }

    // 상품 장보 상태 확인
    if (!validateProduct(productState)) {
      return;
    }

    // 추가된 SKU의 경우 ID를 0으로 설정
    productState.skus = productState.skus.map(sku => {
      if (sku.added) {
        sku.id = 0;
      }
      return sku;
    });

    // 서버로 저장 요청
    ProductApi.saveProduct(productState).then(res => {
      message.success(intl.get("common.message.saved"));
      props.searchProducts(props.pagination);
      history.push(props.parentPath + "/" + res.data.id);
      loadProduct(res.data.id);
    }).catch(err => {
      console.log("saveProduct error:", err);
      message.error(intl.get("common.message.contactAdmin"));
    });
  };

  const validateProduct = (product) => {
    if (product.images.length === 0) {
      message.error(intl.get("common.inputField", {name: intl.get("products.product") + intl.get("products.images")}));
      return false;
    }
    if (!product.skus) {
      message.error(intl.get("common.inputField", {name: intl.get("products.sku")}));
      return false;
    }
    if (product.skus.length > 1 && product.salesPrice == 0) {
      message.error(intl.get("common.inputField", {name: intl.get("products.product") + intl.get("products.salesPrice")}));
      return false;
    }

    let minSalesPrice = Math.min(...product.skus.map(s => s.minSalesPrice));
    let maxSalesPrice = Math.max(...product.skus.map(s => s.maxSalesPrice));
    if (product.salesPrice > maxSalesPrice || product.salesPrice < minSalesPrice) {
      message.error(intl.get("products.exceedPriceRange"));
      return false;
    }
    const exceedSkus = product.skus.filter(s => s.salesPrice > s.maxSalesPrice || s.salesPrice < s.minSalesPrice);
    if (exceedSkus.length > 0) {
      message.error(intl.get("products.exceedPriceRange"));
      return false;
    }

    if (!product.eventProduct && product.descImages?.length === 0) {
      message.error(intl.get("common.inputField", {name: intl.get("products.descriptions")}));
      return false;
    }
    return true;
  };

  const  isEventProduct = props.editMode === "new" ? props.eventProduct : product.eventProduct ;
  return (
    <Spin spinning={uploading}>
      <div style={{display: "flex", justifyContent: "flex-end", marginLeft: 5}}>
        <a onClick={() => setVisiblePreview(!visiblePreview)}>미리보기</a>
      </div>
      <div>
        <Form.Provider onFormFinish={submitProduct}>
          <Form name="productRootForm" form={form}></Form>
          <ProductBasicDataEdit product={product}
                                setProduct={setProductState}
                                submitProduct={submitProduct}
                                deleteProduct={deleteProduct}
                                setVisibleSkuPreview={setVisibleSkuPreview}
                                {...props}
          />
          <ProductDescEdit product={product}
                           setProduct={setProductState}
                           submitProduct={submitProduct}
                           visibleSkuPreview={visibleSkuPreview}
                           setVisibleSkuPreview={setVisibleSkuPreview}
                           {...props}
          />
          <div><h2>{intl.get("products.edit.detail")}</h2></div>
          {isEventProduct ? (
            <EventProductSkuEdit product={product}
                                 setProduct={setProductState}
                                 submitProduct={submitProduct}
                                 setVisibleSkuPreview={setVisibleSkuPreview}
                                 {...props}
            />
          ) : (
            <ProductSkuEdit product={product}
                            setProduct={setProductState}
                            submitProduct={submitProduct}
                            setVisibleSkuPreview={setVisibleSkuPreview}
                            {...props}
            />
          )}
        </Form.Provider>
        <div className="steps-action">
          <Link to={`${props.parentPath}`}><Button type="primary">{intl.get("common.button.list")}</Button></Link>
          <Divider type="vertical"/>
          <Button disabled={(product.marketProduct && !product.marketProductPublished)}
                  type="primary"
                  onClick={() => form.submit()}
          >
            {intl.get("common.button.save")}
          </Button>
          <Divider type="vertical"/>
          <Button>{intl.get("common.button.delete")}</Button>
        </div>
      </div>
      <Drawer placement="right"
              width={380}
              mask={false}
              autoFocus={false}
              bodyStyle={{padding: 0}}
              onClose={() => setVisiblePreview(false)}
              open={visiblePreview}>
        <ProductPreview
          product={product}
          visibleSkuPreview={visibleSkuPreview}
          setVisibleSkuPreview={setVisibleSkuPreview}
          {...props}
        />
      </Drawer>
    </Spin>
  );
}

export default ProductEditor;