import React, {useState, useEffect} from 'react';
import { Switch, Route } from 'react-router-dom';
import LiveRoomList from "./LiveRoomList";
import RegisteredProduct from "./RegisteredProduct";
import NotRegisteredProduct from "./NotRegisteredProduct";
import {useLayoutState} from "../../components/layout/AppLayoutContext";
import ApplicationApi from "../application/ApplicationApi";

const Live = (props) => {
  const layoutState = useLayoutState();

  const [parentPath, setParentPath] = useState("");
  const [application, setApplication] = useState({});

  useEffect(() => {
    if (props.match) {
      setParentPath(props.match.path);
    }
  }, [props.match?.path]);

  useEffect(() => {
    if (layoutState.application.id) {
      loadApplication();
    }
  }, [layoutState.application.id]);

  const loadApplication = () => {
    ApplicationApi.getApplication(layoutState.application.id).then(res => {
      setApplication(res.data);
    });
  };

  return (
    <div>
      {props.match?.path && (
        <div className="page-content">
          <Switch>
            <Route path={props.match.path} exact
                   render={(props) =>
                     (<LiveRoomList application={application} parentPath={parentPath} {...props}/>) } />

            <Route path={`${props.match.path}/products`}
                   render={(props) =>
                     ( <RegisteredProduct application={application} parentPath={parentPath} {...props}/>)} />
            />

            <Route path={`${props.match.path}/add-product`}
                   render={(props) =>
                     ( <NotRegisteredProduct application={application} parentPath={parentPath} {...props}/>)} />
            />

          </Switch>
        </div>
      )}
    </div>
  )
};

export default Live;