import React, {useEffect, useState} from "react";
import {Button, Descriptions, Input, Layout, message, Modal, Select} from "antd";
import intl from "react-intl-universal";
import {SettingOutlined} from "@ant-design/icons";
import FileListPopup from "../../file_manager/FileListPopup";
const {Content, Header} = Layout;
const {Search} = Input;

const IconEditModal = (props) => {
  const [selectedIcon, setSelectedIcon] = useState({});
  const [visibleFileModal, setVisibleFileModal] = useState(false);
  const [productFilterType, setProductFilterType] = useState("categoryId");
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState("");
  const [selectedBrandId, setSelectedBrandId] = useState("");
  const [subCategories, setSubCategories] = useState([]);

  useEffect(() => {
    const icon = JSON.parse(JSON.stringify(props.selectedIcon));
    console.log("icon>>>>", icon);

    if (!icon.productFilters || icon.productFilters.length === 0) {
      setProductFilterType("categoryId");
      setSelectedCategoryId("");
      setSelectedSubCategoryId("");
      setSelectedBrandId("");
      icon.productFilters = [];
    } else {
      const filter = icon.productFilters[0];
      setProductFilterType(filter.type);
      if (filter.type === "categoryId") {
        const selectedCategory = props.categories.find(c => c.id === filter.value);
        if (selectedCategory) {
          // 필터 조건이 1차 카테고리인 경우
          setSelectedCategoryId(filter.value);
        } else {
          // 필터 조건이 2차 카테고리인 경우
          let found = false;
          props.categories.forEach(c => {
            const selectedShuCategory = c.subCategories.find(sc => sc.id === filter.value);
            if (selectedShuCategory) {
              found = true;
              setSelectedCategoryId(c.id);
              setSelectedSubCategoryId(filter.value);
            }
          });
          if (!found) {
            setSelectedCategoryId("");
            setSelectedSubCategoryId("");
          }
        }
      } else {
        setSelectedBrandId(filter.value);
      }
    }
    setSelectedIcon(icon);
  }, [props.selectedIcon]);

  useEffect(() => {
    setSubCategories(selectedCategoryId ? props.categories.find(c => c.id === selectedCategoryId).subCategories : []);
  }, [selectedCategoryId]);

  const okFileModal = (modalName, filePathList) => {
    if (filePathList.length === 0) {
      return;
    }
    onFieldChange("image", filePathList[0]);
    setVisibleFileModal(false);
  };

  const onFieldChange = (fieldName, value) => {
    const selectedIconState = {...selectedIcon};
    selectedIconState[fieldName] = value;
    setSelectedIcon(selectedIconState);
  };

  const handleOk = () => {
    const selectedIconState = JSON.parse(JSON.stringify(selectedIcon));

    if (!selectedIconState.title) {
      message.error(intl.get("common.inputField", {name: intl.get("page_setting.categoryPage.iconTitle")}));
      return;
    }
    if (!selectedIconState.image) {
      message.error(intl.get("common.inputField", {name: intl.get("page_setting.categoryPage.iconImage")}));
      return;
    }
    let filterItem = {
      type: productFilterType,
    }

    if (productFilterType === "categoryId") {
      if (!selectedCategoryId && !selectedSubCategoryId) {
        message.error(intl.get("common.inputField", {name: intl.get("page_setting.edit.product.search.categoryId")}));
        return;
      }
      filterItem.value = selectedSubCategoryId ? selectedSubCategoryId : selectedCategoryId;
    } else if (productFilterType == "brandId") {
      if (!selectedBrandId) {
        message.error(intl.get("common.inputField", {name: intl.get("page_setting.edit.product.search.brandId")}));
        return;
      }
      filterItem.value = selectedBrandId;
    }
    selectedIconState.productFilters = [filterItem];
    props.handleOk(selectedIconState);
  };

  const handleDelete = () => {
    if (!window.confirm(intl.get("common.confirm.delete2"))) {
      return;
    }
    props.handleDeleteIcon(selectedIcon);
  };

  const onCategorySelect = (value) => {
    setSelectedCategoryId(value);
    setSelectedSubCategoryId("");
  };

  return (
    <Modal
      title={[<div key={1}><SettingOutlined/>아이콘 설정</div>]}
      visible={true}
      onCancel={props.handleCancel}
      onOk={handleOk}
      width={800}
      bodyStyle={{margin: 0, padding: 0}}
      footer={[
        <Button key="cancel" onClick={props.handleCancel}>{intl.get("common.button.cancel")}</Button>,
        <Button key="delete" type="primary" onClick={handleDelete}>{intl.get("common.button.delete")}</Button>,
        <Button key="save" type="primary" onClick={handleOk}>{intl.get("common.button.ok")}</Button>,
      ]}
    >
      <Layout>
        <Content className="modal-content">
          <Descriptions bordered size={"small"} column={1}>
            <Descriptions.Item label={intl.get("page_setting.categoryPage.iconTitle")}>
              <Input value={selectedIcon ? selectedIcon.title : ""} onChange={(e) => onFieldChange("title", e.target.value)}/>
            </Descriptions.Item>
            <Descriptions.Item label={intl.get("page_setting.categoryPage.iconImage")}>
              <Search
                allowClear
                enterButton={intl.get("page_setting.edit.media.button.contents")}
                value={selectedIcon ? selectedIcon.image : ""}
                onChange={(e) => onFieldChange("image", e.target.value)}
                onSearch={() => setVisibleFileModal(true)}
              />
              {selectedIcon.image && (
                <img src={selectedIcon.image} style={{marginTop: 10, width: 72, height: 72}}/>
              )}
            </Descriptions.Item>
            <Descriptions.Item label={"상품조건"}>
              <Select style={{width: 150}} value={productFilterType} onChange={(value) => setProductFilterType(value)}>
                <Select.Option value={"categoryId"}>{intl.get("page_setting.edit.product.search.categoryId")}</Select.Option>
                <Select.Option value={"brandId"}>{intl.get("page_setting.edit.product.search.brandId")}</Select.Option>
              </Select>
            </Descriptions.Item>
            <Descriptions.Item label={productFilterType === "categoryId" ? intl.get("page_setting.edit.product.search.categoryId") : intl.get("page_setting.edit.product.search.brandId")}>
              {productFilterType === "categoryId" && (
                <div style={{display: "flex", gap: 10}}>
                  <div style={{width: 150}}>
                    <Select value={selectedCategoryId ? selectedCategoryId : ""}
                            onChange={onCategorySelect}
                            style={{width: "100%"}}>
                      {
                        props.categories.map(eachCategory => {
                          return (<Select.Option key={eachCategory.id} value={eachCategory.id}>{eachCategory.name}</Select.Option>)
                        })
                      }
                    </Select>
                  </div>
                  <div style={{width: 150}}>
                    <Select
                      value={selectedSubCategoryId ? selectedSubCategoryId : ""}
                      onChange={(value) => setSelectedSubCategoryId(value)}
                      style={{width: "100%"}}>
                      { subCategories && subCategories.map(eachCategory => {
                        return (<Select.Option key={eachCategory.id}
                                               value={eachCategory.id}>{eachCategory.name}</Select.Option>)
                      })
                      }
                    </Select>
                  </div>
                </div>
              )}
              { productFilterType === "brandId" && (
                <Select value={selectedBrandId} onChange={(value) => setSelectedBrandId(value)} style={{width: 400}}>
                  {
                    props.brands.map(eachBrand => {
                      return (<Select.Option key={eachBrand.id} value={eachBrand.id}>{eachBrand.name}</Select.Option>)
                    })
                  }
                </Select>
              )}
            </Descriptions.Item>
          </Descriptions>
        </Content>
      </Layout>
      {visibleFileModal && (
        <FileListPopup id={'categoryIcon'}
                       fileKind={"index"}
                       visible={true}
                       multipleFile={false}
                       canSelect={true}
                       handleCancel={() => setVisibleFileModal(false)}
                       handleOk={okFileModal}
        />
      )}
    </Modal>
  )
};

export default IconEditModal;