import React, {useEffect, useState} from "react";
import {Button, Descriptions, Divider, Form, Input, Radio} from "antd";
import intl from "react-intl-universal";
import ApplicationApi from "./ApplicationApi";
import {Link} from "react-router-dom";

const WeimobAppEdit = (props) => {
  const [form] = Form.useForm();
  const [weimonApp, setWeimobApp] = useState({});
  const [appId, setAppId] = useState(0);

  useEffect(() => {
    setAppId(props.applicationId);
    loadWeimonApp(props.applicationId);
  }, [props.applicationId]);

  const submitWeimobApp = (editingWeimobApp) => {
    editingWeimobApp.id = weimonApp.id;
    if (weimonApp.id) {
      editingWeimobApp.createdAt = weimonApp.createdAt;
      editingWeimobApp.updatedAt = weimonApp.updatedAt;
    }
    ApplicationApi.submitWeimobApp(appId, editingWeimobApp).then(res => {
      loadWeimonApp(appId);
    });
  };

  const loadWeimonApp = (appId) => {
    ApplicationApi.getWeimobApp(appId).then(res => {
      setWeimobApp(res.data);
      form.setFieldsValue(res.data);
    }).catch(err => {
      // console.log(">>>err>", err.response);
      if (err.response && err.response.status === 404) {
        const weimonApp = {
          id: 0,
          useSelfWeimobApp: true
        };
        form.setFieldsValue(weimonApp);
        setWeimobApp(weimonApp);
      }
    });
  };

  return (
    <div>
      <Form onFinish={submitWeimobApp} initialValues={weimonApp} form={form}>
        <Descriptions bordered size={"small"} column={1}>
          <Descriptions.Item label={"微盟店铺ID"}>
            <Form.Item name={"shopId"}>
              <Input/>
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label={"微盟 Client Id" }>
            <Form.Item name={"clientId"}>
              <Input/>
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label={"微盟 Client Secret"}>
            <Form.Item name={"clientSecret"}>
              <Input/>
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label={"自营微盟云账号"}>
            <Form.Item name={"useSelfWeimobApp"}>
              <Radio.Group>
                <Radio value={true}>{intl.get("common.true")}</Radio>
                <Radio value={false}>{intl.get("common.false")}</Radio>
              </Radio.Group>
            </Form.Item>
          </Descriptions.Item>
        </Descriptions>
      </Form>
      <div style={{marginTop: 10, width: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}>
        <Button type="primary" onClick={() => form.submit()}>{intl.get("common.button.save")}</Button>
      </div>
    </div>
  )
}

export default WeimobAppEdit;