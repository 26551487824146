import axios from "axios";
import {adminConfig} from "../../config/admin.config";

const API_BASE_URL = adminConfig.apiServer();

export default class CouponEventApi {
  static getCouponEvent = (couponEventId) => {
    const apiPath = `${API_BASE_URL}/coupon-events/${couponEventId}?projection=webAdmin`;
    return axios.get(apiPath);
  };

  static getCouponEvents = (params) => {
    let apiPath = `${API_BASE_URL}/coupon-events?`;

    const paramKeyValues = []
    Object.keys(params).forEach(key => {
      if (params[key]) {
        paramKeyValues.push(key + "=" + params[key])
      }
    });
    apiPath += paramKeyValues.join("&");
    if (!params.projection) {
      apiPath += "&projection=webAdmin";
    }
    return axios.get(apiPath);
  };

  static submitCouponEvent = (couponEvent) => {
    if (couponEvent.id) {
      const apiPath = `${API_BASE_URL}/coupon-events/${couponEvent.id}?projection=webAdmin`;
      return axios.put(apiPath, couponEvent);

    } else {
      const apiPath = `${API_BASE_URL}/coupon-events?projection=webAdmin`;
      return axios.post(apiPath, couponEvent);
    }
  };

  static deleteCouponEvent = (couponEvent) => {
    const apiPath = `${API_BASE_URL}/coupon-events/${couponEvent.id}?projection=webAdmin`;
    return axios.delete(apiPath, couponEvent);
  };

  static getUserCoupons = (params) => {
    let apiPath = `${API_BASE_URL}/user-coupons?`;

    const paramKeyValues = []
    Object.keys(params).forEach(key => {
      if (params[key]) {
        paramKeyValues.push(key + "=" + params[key])
      }
    });
    apiPath += paramKeyValues.join("&");
    if (!params.projection) {
      apiPath += "&projection=webAdmin";
    }
    return axios.get(apiPath);
  };

  static getCouponRedeems = (params) => {
    let apiPath = `${API_BASE_URL}/coupon-redeems?`;

    const paramKeyValues = []
    Object.keys(params).forEach(key => {
      if (params[key]) {
        paramKeyValues.push(key + "=" + params[key])
      }
    });
    apiPath += paramKeyValues.join("&");
    if (!params.projection) {
      apiPath += "&projection=webAdmin";
    }
    return axios.get(apiPath);
  };
}