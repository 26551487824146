import React, {useEffect, useState} from "react";
import {Button, Descriptions, Divider, Form, Input, message, Select, Upload} from "antd";
import intl from "react-intl-universal";
import {Link} from "react-router-dom";
import CompanyApi from "./CompanyApi";
import {MemberContext} from "../../auth/member.context";
import RequiredLabel from "../../components/RequiredLabel";
import Util from '../../util/Util';
import SystemSettingApi from "../system_setting/SystemSettingApi";
import ApplicationListPopup from "../application/ApplicationListPopup";

const CompanyForm = (props) => {
  const [form] = Form.useForm();
  const [visibleAppList, setVisibleAppList] = useState(false);

  useEffect(() => {
    form.setFieldsValue(props.company);
  }, [props.company.id]);

  const submitCompany = (values) => {
    if (!window.confirm(intl.get("common.confirm.save", {name: intl.get("company.company")}))) {
      return
    }

    const company = {...props.company};

    Object.assign(company, values);
    // company.BusinessRegistrationFile = props.company.BusinessRegistrationFile;

    if (company.id) {
      CompanyApi.updateCompany(company).then(res => {
        message.success(intl.get("common.message.saved"));
        loadCompany(res.data.id);
      });
    } else {
      CompanyApi.createCompany(company).then(res => {
        message.success(intl.get("common.message.saved"));
        if (MemberContext.memberInformation.userType === "admin") {
          loadCompany(res.data.id);
        } else {
          // 일반 사용자인 경우 다시 사용자 정보를 가져와서 설정한다.
          if (props.setCompany) {
            props.setCompany(res.data);
          }
        }
      });
    }
  };

  const loadCompany = (companyId) => {
    CompanyApi.getCompany(companyId).then(res => {
      setCompanyForm(res.data);
    });
  };

  const setCompanyForm = (company) => {
    // const masterUserName = company.masterCompanyUser && company.masterCompanyUser.id ? company.masterCompanyUser.name : "";
    // setMasterUserName(masterUserName);
    form.setFieldsValue(company);
    if (props.setCompany) {
      props.setCompany(company);
    }
  };

  const onAppSelected = (app) => {
    const formValues = form.getFieldsValue();
    formValues.defaultAppId = app.id;
    form.setFieldsValue(formValues);
    setVisibleAppList(false);
  }

  return (
    <div style={{width: "70%"}}>
      <Form onFinish={submitCompany} form={form}>
        <Descriptions bordered size={"small"} column={1}>
          <Descriptions.Item label={"ID"}>
            <Form.Item name={"id"}>
              <Input disabled={true}/>
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label={(<RequiredLabel label={intl.get("company.name")}/>)}>
            <Form.Item name={"name"}
                       rules={[{ required: true, message: intl.get("common.inputField", {name: intl.get("company.name")}) }]}>
              <Input/>
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label={(<RequiredLabel label={intl.get("company.companyCode")} />)}>
            <Form.Item name={"companyCode"}
                       rules={[{ required: true, message: intl.get("common.inputField", {name: intl.get("company.companyCode")})}]}
                       extra={intl.get("company.companyCode.help")}>
              <Input/>
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label={(<RequiredLabel label={intl.get("company.businessRegistrationNumber")} />)}>
            <Form.Item name={"businessRegistrationNumber"}
                       rules={[{ required: true, message: intl.get("common.inputField", {name: intl.get("company.businessRegistrationNumber")}) }]}>
              <Input/>
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label={(<RequiredLabel label={intl.get("company.phone")} />)}>
            <Form.Item name={"phone"}
                       rules={[{ required: true, message: intl.get("common.inputField", {name: intl.get("company.phone")}) }]}>
              <Input/>
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label={intl.get("company.email")}>
            <Form.Item name={"email"}>
              <Input/>
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label={intl.get("company.homePage")}>
            <Form.Item name={"homePage"}>
              <Input/>
            </Form.Item>
          </Descriptions.Item>
          {props.company.id && (
            <>
              <Descriptions.Item label={"기본앱ID"}>
                <div style={{display: "flex", alignItems: "center"}}>
                  <div style={{width: 100}}>
                    <Form.Item name={"defaultAppId"}>
                      <Input disabled={true}/>
                    </Form.Item>
                  </div>
                  <div style={{marginLeft: 10}}>
                    <Button onClick={() => setVisibleAppList(true)}>{intl.get("common.button.select")}</Button>
                  </div>
                </div>
              </Descriptions.Item>
              <Descriptions.Item label={"기본 사용자 권한"}>
                <Form.Item name={"defaultUserType"}>
                  <Select style={{ width: 200, marginRight: 10 }}>
                    <Select.Option key="companyManager" value="companyManager">{intl.get("company.user.userType.companyManager")}</Select.Option>
                    <Select.Option key="appManager" value="appManager">{intl.get("company.user.userType.appManager")}</Select.Option>
                    <Select.Option key="logistics" value="logistics">{intl.get("company.user.userType.logistics")}</Select.Option>
                    <Select.Option key="draft" value="draft">{intl.get("company.user.userType.draft")}</Select.Option>
                  </Select>
                </Form.Item>
              </Descriptions.Item>
            </>
          )}

          <Descriptions.Item label={intl.get("company.creator")}>
            <Form.Item name={"creatorId"}>
              <Input disabled={true}/>
            </Form.Item>
          </Descriptions.Item>
          {Util.isAdmin() && (
            <>
              <Descriptions.Item label="서비스명">
                <Form.Item name={"serviceName"}>
                  <Input/>
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="관리웹 도메인">
                <Form.Item name={"adminDomain"}>
                  <Input/>
                </Form.Item>
              </Descriptions.Item>
              <Descriptions.Item label="관리웹 CopyRight">
                <Form.Item name={"footer"}>
                  <Input/>
                </Form.Item>
              </Descriptions.Item>
            </>
          )}
        </Descriptions>
        <div style={{width:"100%", textAlign:"center", marginTop: 20}}>
          <Button type="primary" htmlType="submit">{intl.get("common.button.save")}</Button>
          {props.showListButton && (
            <>
              <Divider type="vertical"/>
              <Button type="primary"><Link to={`${props.parentPath}`}>{intl.get("common.button.list")}</Link></Button>
            </>
          )}
        </div>
      </Form>
      {visibleAppList && (
        <ApplicationListPopup
          onClose={() => setVisibleAppList(false)}
          onAppSelected={onAppSelected}
          companyId={props.company.id}/>
      )}
    </div>
  )
};

export default CompanyForm;