import React from 'react';
import { Switch, Route } from 'react-router-dom';
import FileList from "./FileList";
import FileListPopup from "./FileListPopup";

const FileManager = (props) => {
  // NOTICE: path argument를 사용하기 위해서는 Route의 순서가 중요. new -> edit -> show 순서로 작성
  return (
      <div>
        <div className="page-content">
          <Switch>
            <Route path={props.match.path} exact
                   render={(props) => (<FileList fileKind={""} {...props}/>) } />
            />
          </Switch>
        </div>
      </div>
  );
};

export default FileManager;