import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const SearchBarSlot = (props) => {
  const {id, settingValues} = props;

  const backgroundColor = settingValues?.backgroundColor ? settingValues.backgroundColor : "#FFFFFF";
  const borderColor = settingValues?.borderColor ? settingValues.borderColor : "#9e9e9e";
  const inputBackgroundColor = settingValues?.inputBackgroundColor ? settingValues?.inputBackgroundColor : "#FFFFFF";

  return (
      <div style={{backgroundColor: backgroundColor, padding: "20px 16px", height: 64}}>
        <div style={{
          height: 32, width: '100%', border: "solid 1px " + borderColor,
          borderRadius: 50, display: "flex", alignItems: "center",
          backgroundColor: inputBackgroundColor,
          color: "#929292", fontSize: 14,
        }}>
          <div style={{paddingLeft: 10}}>
            <FontAwesomeIcon icon={faSearch} />
          </div>
          <div style={{paddingLeft: 10}}>搜索</div>
        </div>
      </div>
  )
}

export default SearchBarSlot;