import {adminConfig} from "../../config/admin.config";
import axios from "axios";

const API_BASE_URL = adminConfig.apiServer();

export default class NoticeApi {
  static getNotice = (id) => {
    let apiPath = `${API_BASE_URL}/notices/${id}?projection=webAdmin&`;
    return axios.get(apiPath);
  };

  static getNotices = (options) => {
    let apiPath = `${API_BASE_URL}/notices?projection=webAdmin&`;

    if (options) {
      apiPath += Object.keys(options).map(key => {
        return key + "=" + options[key]
      }).join("&");
    }
    return axios.get(apiPath);
  };

  static getFilePath = (noticeId, fileIndex) => {
    let apiPath = `${API_BASE_URL}/notices/${noticeId}/files/${fileIndex}?projection=webAdmin&`;
    return axios.get(apiPath);
  };

  static submitNotice = (notice) => {
    if (notice.id) {
      const apiPath = `${API_BASE_URL}/notices/${notice.id}?projection=webAdmin`;
      return axios.put(apiPath, notice);
    } else {
      const apiPath = `${API_BASE_URL}/notices?projection=webAdmin`;
      return axios.post(apiPath, notice);
    }
  };

  static deleteNotice = (id) => {
    let apiPath = `${API_BASE_URL}/notices/${id}`;
    return axios.delete(apiPath);
  };
}