import React, {useEffect, useState} from 'react';

import {
  List, Typography
} from 'antd';

import NoticeApi from "./NoticeApi";
import NoticeModal from "./NoticeModal";
import Util from "../../util/Util";

const NoticeList = (props) => {
  const [notices, setNotices] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [selectedNotice, setSelectedNotice] = useState({});
  const [visibleNoticeModal, setVisibleNoticeModal] = useState(false);

  useEffect(() => {
    searchNotices(pagination);
  }, []);

  const searchNotices = (pageinfo = pagination) => {
    NoticeApi.getNotices(getSearchParam(pageinfo)).then(res => {
      const pagingResult = res.data;
      const pagination = {
        current: pagingResult.page,
        pageSize: pagingResult.pageSize,
        total: pagingResult.count,
      }
      setNotices(pagingResult.data);
      setPagination(pagination);
    });
  };

  const getSearchParam = (pagination) => {
    const params = {
      pageSize: pagination.pageSize,
      page: pagination.current,
    };
    return params;
  };

  const pageChanged = (page, pageSize) => {
    const paginationState = {...pagination};
    paginationState.current = page;
    paginationState.pageSize = pageSize;
    setPagination(paginationState);
    searchNotices(paginationState);
  };

  const onClickNotice = (notice) => {
    setSelectedNotice(notice);
    setVisibleNoticeModal(true);
  };

  return (
    <div>
      <List
        bordered
        dataSource={notices}
        pagination={{
          onChange: pageChanged,
          ...pagination
        }}
        renderItem={notice => {
          return (
            <List.Item>
              <List.Item.Meta
                title={(<span style={{fontSize: 16, fontWeight: "bold"}}>{notice.title}</span>)}
                description={(
                  <Typography.Paragraph
                    style={{cursor: "pointer"}}
                    onClick={() => onClickNotice(notice)}
                    ellipsis={{rows: 3, expandable: false}}>
                    {notice.contents}
                  </Typography.Paragraph>
                )}
              />
              {Util.getLocalTime(notice.updatedAt).substring(0, 10)}
            </List.Item>
          )
        }}
      >
      </List>
      {visibleNoticeModal && (
        <NoticeModal notice={selectedNotice} handleCancel={() => setVisibleNoticeModal(false)}/>
      )}
    </div>
  )
};

export default NoticeList;
