import React, { useEffect, useState } from 'react';

import {
  Table,
  Button,
  // Divider,
  message,
  Spin,
  Transfer
} from 'antd';
import ApplicationLogisticsCompanyApi from "./ApplicationLogisticsCompanyApi";
import LogisticsCompanyApi from "../logistics_company/LogisticsCompanyApi";

import intl from "react-intl-universal";

// const mockData = [{ "key": 4, "title": "中通快递" }, { "key": 8, "title": "京东物流" }, { "key": 7, "title": "圆通速递" }, { "key": 9, "title": "天天快递" }, { "key": 5, "title": "申通快递" }, { "key": 6, "title": "百世快递" }, { "key": 2, "title": "邮政快递包裹" }, { "key": 1, "title": "韵达快递" }, { "key": 3, "title": "顺丰速运" }]
// const initialTargetKeys = mockData.filter((item) => Number(item.key) > 14).map((item) => item.key);

const ApplicationLogisticsCompanyList = (props) => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [applicationLogisticsCompanies, setApplicationLogisticsCompanies] = useState([]);

  const [targetKeys, setTargetKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  useEffect(() => {
    loadLogistics()
  }, []);

  const loadLogistics = () => {
    LogisticsCompanyApi.getLogistics({ active: 1 }).then(res => {
      // console.log('res :>> ', res.data);
      const arr = res.data.map(item => {
        return {
          key: item.id,
          title: item.name,
        }
      })
      // console.log('arr :>> ', arr);
      setDataSource(arr);
    });
    ApplicationLogisticsCompanyApi.getApplicationLogistics().then(res => {
      console.log('getApplicationLogistics.res :>> ', res.data);
      setApplicationLogisticsCompanies(res.data)
      const targetList = res.data.map(item => item.logisticsCompany.id)
      // console.log('targetList :>> ', targetList);
      setTargetKeys(targetList);
    })
  };

  const onChange = async (nextTargetKeys, direction, moveKeys) => {
    // console.log('targetKeys:', nextTargetKeys);
    // console.log('direction:', direction);
    // console.log('moveKeys:', moveKeys);
    if (direction == 'left') {
      const deleteIds = []
      applicationLogisticsCompanies.forEach(item => {
        if (moveKeys.includes(item.logisticsCompany.id)) {
          deleteIds.push(item.id)
        }
      })
      // console.log('deleteIds :>> ', deleteIds);
      await ApplicationLogisticsCompanyApi.deleteApplicationLogistics(deleteIds)
      message.success(intl.get("common.message.saved"))
    }
    if (direction == 'right') {
      const createParam = moveKeys.map(key => {
        return {
          logisticsCompanyID: key
        }
      })
      await ApplicationLogisticsCompanyApi.createApplicationLogistics(createParam)
      message.success(intl.get("common.message.saved"))
    }
    // setTargetKeys(nextTargetKeys);
    loadLogistics()
  };
  const onSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    // console.log('sourceSelectedKeys:', sourceSelectedKeys);
    // console.log('targetSelectedKeys:', targetSelectedKeys);
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };
  const onScroll = (direction, e) => {
    // console.log('direction:', direction);
    // console.log('target:', e.target);
  };

  return (
    <Spin spinning={loading}>
      <div className="page-op-box">
        <div className="filter-box">
          <div style={{ marginLeft: "auto" }}>
            <div style={{ width: 100 }}>
              <Button style={{ width: 100 }} type="primary" block size="default" onClick={loadLogistics}>{intl.get("common.button.search")}</Button>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: 10 }}>
        <Transfer
          listStyle={{ height: 600, width: 400 }}
          dataSource={dataSource}
          titles={[intl.get('application.logistics.company.canuse'), intl.get('application.logistics.company.used')]}
          targetKeys={targetKeys}
          selectedKeys={selectedKeys}
          onChange={onChange}
          onSelectChange={onSelectChange}
          onScroll={onScroll}
          render={(item) => item.title}
        />
      </div>

    </Spin>
  )
}

export default ApplicationLogisticsCompanyList;