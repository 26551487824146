import React, {useState, useEffect} from "react";
import {Input, Modal, message, Descriptions, Layout, Spin} from "antd";
import intl from "react-intl-universal";
import OrderApi from "./OrderApi";
import {SettingOutlined} from "@ant-design/icons";
import Util from "../../util/Util";
import AddressSelect from "../../components/address/AddressSelect";

const {Content} = Layout;

const CreateOrderPopup = (props) => {
  const [loading, setLoading] = useState(false);
  const [order, setOrder] = useState({
    orderSkus: [],
    shippingAddress: {},
    settlement: {},
  });

  useEffect(() => {
    OrderApi.prepareOrder(props.preparingOrder).then(res  => {
      setOrder(res.data);
    });
  }, [props.preparingOrder]);

  const handleOk = () => {
    OrderApi.createOrder(order).then(res  => {
      // setOrder(res.data);
      message.success("저장 되었습니다.");
      props.handleCancel();
    });
  }

  const selectCounty = (provinceName, cityName, countyName, county) => {
    const orderState = {...order}
    orderState.shippingAddress.addressProvince = provinceName;
    orderState.shippingAddress.addressCity = cityName;
    orderState.shippingAddress.addressDistrict = countyName;
    orderState.shippingAddress.addressCode = county;
    setOrder(orderState);
  };

  const onChangeOrder = (fieldName, value) => {
    const orderState = {...order}
    const fieldTokens = fieldName.split(".");
    if (fieldTokens.length === 2) {
      orderState[fieldTokens[0]][fieldTokens[1]] = value;
    } else {
      if (fieldName === "userId") {
        orderState[fieldName] = parseInt(value);
      } else {
        orderState[fieldName] = value;
      }
    }
    setOrder(orderState);
  };

  return (
      <Modal
          title={[<div key={1}><SettingOutlined/> 테스트 주문 생성</div>]}
          visible={true}
          onCancel={props.handleCancel}
          onOk={handleOk}
          width={900}
          bodyStyle={{margin: 0, padding: 0}}>
        <Layout>
          <Content className="modal-content">
            <Spin spinning={loading}>
              <Descriptions bordered size={"small"} column={1}>
                <Descriptions.Item label={"주소입력"}>
                  <AddressSelect address={{}} selectCounty={selectCounty}/>
                  <Input placeholder="주소상세" value={order.shippingAddress.addressDetail} onChange={(e) => onChangeOrder("shippingAddress.addressDetail", e.target.value)}/>
                </Descriptions.Item>
                <Descriptions.Item label={"제품"}>
                {order.orderSkus.map((sku, index) => {
                  return (
                      <div key={index} style={{marginBottom: 10}}>
                        <div>
                          <div style={{display: "flex", alignItems: "center"}}>
                            <div style={{width: 100}}><img src={sku.orderProductSku.skuImage} style={{width: 64, height: 64}}/></div>
                            <div style={{flexGrow: 1}}>
                              <div style={{fontWeight: "bold"}}>{sku.productName}</div>
                              <div style={{display: "flex"}}>
                                <div>
                                  {Util.currencyFormat(sku.salesPrice, order.productCurrency) + (sku.salesQuantity > 1 ? "X" + sku.salesQuantity : "")} * {sku.quantity} {"개"}
                                </div>
                                <div style={{marginLeft: "auto", display: "flex", alignItems: "center"}}>
                                  <div style={{color:"gray", fontSize: 12, marginRight: 20}}>{Util.currencyFormat(sku.salesAmount, order.productCurrency)}</div>
                                  <div style={{marginLeft: "auto"}}>{Util.currencyFormat(sku.settlement.salesAmount, order.settlement.userCurrency)}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                  )
                })}
                </Descriptions.Item>
                <Descriptions.Item label={"제품판매금액 합계"}>
                  <div style={{display: "flex", alignItems: "center"}}>
                    <div style={{width: "50%"}}></div>
                    <div style={{marginLeft: "auto", display: "flex", alignItems: "center"}}>
                      <div style={{color:"gray", fontSize: 12, marginRight: 20}}>{Util.currencyFormat(order.salesAmount, order.productCurrency)}</div>
                      <div style={{marginLeft: "auto"}}>{Util.currencyFormat(order.settlement.salesAmount, order.settlement.userCurrency)}</div>
                    </div>
                  </div>
                </Descriptions.Item>
                <Descriptions.Item label={"할인금액"}>
                  <div style={{display: "flex", alignItems: "center"}}>
                    <div style={{width: "50%"}}></div>
                    <div style={{marginLeft: "auto", display: "flex", alignItems: "center"}}>
                      <div style={{color:"gray", fontSize: 12, marginRight: 20}}>{Util.currencyFormat(order.discountAmount, order.productCurrency)}</div>
                      <div style={{marginLeft: "auto"}}>{Util.currencyFormat(order.settlement.discountAmount, order.settlement.userCurrency)}</div>
                    </div>
                  </div>
                </Descriptions.Item>
                <Descriptions.Item label={"배송료"}>
                  <div style={{display: "flex", alignItems: "center"}}>
                    <div style={{width: "50%"}}></div>
                    <div style={{marginLeft: "auto", display: "flex", alignItems: "center"}}>
                      <div style={{color:"gray", fontSize: 12, marginRight: 20}}>{Util.currencyFormat(order.deliveryFee, order.productCurrency)}</div>
                      <div style={{marginLeft: "auto"}}>{Util.currencyFormat(order.settlement.deliveryFee, order.settlement.userCurrency)}</div>
                    </div>
                  </div>
                </Descriptions.Item>
                <Descriptions.Item label={"지불금액"}>
                  <div style={{display: "flex", alignItems: "center"}}>
                    <div style={{width: "50%"}}></div>
                    <div style={{marginLeft: "auto", display: "flex", alignItems: "center"}}>
                      <div style={{color:"gray", fontSize: 12, marginRight: 20}}>{Util.currencyFormat(order.paymentAmount, order.productCurrency)}</div>
                      <div style={{marginLeft: "auto"}}>{Util.currencyFormat(order.settlement.paymentAmount, order.settlement.userCurrency)}</div>
                    </div>
                  </div>
                </Descriptions.Item>
                <Descriptions.Item label={"테스트 사용자 ID"}>
                  <Input value={order.userId} onChange={(e) => onChangeOrder("userId", e.target.value)}/>
                </Descriptions.Item>
                <Descriptions.Item label={"메모"}>
                  <Input value={order.shippingAddress.userComments} onChange={(e) => onChangeOrder("shippingAddress.userComments", e.target.value)}/>
                </Descriptions.Item>
              </Descriptions>
            </Spin>
          </Content>
        </Layout>
      </Modal>
  )
}

export default CreateOrderPopup;