import React, {useEffect, useState} from 'react';
import {Row, Col, Card, Select, message, Spin} from 'antd';
import SupplierApi from './SupplierApi';
import NumberComparingIndicator from '../dashboard/NumberComparingIndicator';
import NumberFormat from "react-number-format";
import Util from "../../util/Util";
import DashboardCard from '../dashboard/DashboardCard';
import intl from "react-intl-universal";

const SupplierOrderSummary = (props) => {
  const [loading, setLoading] = useState(false);
  const [orderSummary, setOrderSummary] = useState({});
  const [baseDate, setBaseDate] = useState("order");

  useEffect(() => {
    loadSalesSummary(baseDate);
  }, []);

  const loadSalesSummary = (baseDate) => {
    setLoading(true);
    const options = {
      baseDate: baseDate,
    };

    SupplierApi.getOrderSummary(options).then(res => {
      setOrderSummary(res.data);
    }).catch(error => {
      message.error("에러가 발생했습니다.");
    }).finally(() => {
      setLoading(false);
    })
  };

  const onChangeBaseDate = (value) => {
    setBaseDate(value);
    loadSalesSummary(value);
  }

  const numberStyle = {
    fontSize: 68, lineHeight: '68px', fontWeight: 'bold', color: '#000000'
  };

  const dateSelector = (
    <div>
      <Select value={baseDate} style={{ width: 200}} onChange={onChangeBaseDate}>
        <Select.Option key="order" value="order">{intl.get("dashboard.date.order")}</Select.Option>
        <Select.Option key="finish" value="finish">{intl.get("dashboard.date.finish")}</Select.Option>
      </Select>
    </div>
  );

  const today = props.mode === "count" ? orderSummary.OrderCount : orderSummary.OrderAmount;
  const yesterday = props.mode === "count" ? orderSummary.YesterdayOrderCount : orderSummary.YesterdayOrderAmout;
  const lastWeekday = props.mode === "count" ? orderSummary.LastWeekdayOrderCount : orderSummary.LastWeekdayOrderAmount;
  const thisMonthSoFar = props.mode === "count" ? orderSummary.ThisMonthSoFarCount : orderSummary.ThisMonthSoFarAmount;
  const lastMonthSameDay = props.mode === "count" ? orderSummary.LastMonthSameDayCount : orderSummary.LastMonthSameDayAmount;
  const lastMonth = props.mode === "count" ? orderSummary.LastMonthCount : orderSummary.LastMonthAmount;
  const monthBefore = props.mode === "count" ? orderSummary.MonthBeforeCount : orderSummary.MonthBeforeAmount;
  const title = props.mode === "count" ? intl.get("dashboard.orderCount") : intl.get("dashboard.orderAmount")

  return (
    <Spin spinning={loading}>
      <DashboardCard title={title} loading={loading} extra={(dateSelector)}>
        <Row gutter={10}>
          <Col span={9}>
            <Card bodyStyle={{padding: 20}}>
              <div style={{textAlign: 'center', marginTop: 10}}>
                <div style={{fontSize: 14, color: '#292D36', fontWeight: 'bold'}}>{intl.get("dashboard.orderSummary.OrderAmount")}</div>
                <div>
                  {props.mode !== "count" && (
                    <span style={{fontSize: 34, lineHeight: '68px', color: '#000000', marginRight: "3px"}}>
                      {Util.getSymbol(props.application.productCurrency)}
                    </span>
                  )}
                  <span style={numberStyle}>
                    <NumberFormat value={today} displayType={'text'} thousandSeparator={true}/>
                  </span>
                </div>
              </div>
            </Card>
          </Col>
          <Col span={5}>
            <NumberComparingIndicator mainTitle={intl.get("dashboard.orderSummary.Yesterday")}
                                      versusTitle={intl.get("dashboard.orderSummary.YesterdayOrderAmout")}
                                      value={yesterday}
                                      versusValue={lastWeekday}
                                      {...props}
            />
          </Col>
          <Col span={5}>
            <NumberComparingIndicator mainTitle={intl.get("dashboard.orderSummary.ThisMonth")}
                                      versusTitle={intl.get("dashboard.orderSummary.ThisMonthSoFarAmount")}
                                      value={thisMonthSoFar}
                                      versusValue={lastMonthSameDay}
                                      {...props}
            />
          </Col>
          <Col span={5}>
            <NumberComparingIndicator mainTitle={intl.get("dashboard.orderSummary.LastMonth")}
                                      versusTitle={intl.get("dashboard.orderSummary.LastMonthAmount")}
                                      value={lastMonth}
                                      versusValue={monthBefore}
                                      {...props}

            />
          </Col>
        </Row>
      </DashboardCard>
    </Spin>
  )
};

export default SupplierOrderSummary;