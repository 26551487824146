import React, {useEffect, useState} from "react";
import ApplicationApi from "./ApplicationApi";
import {Route, Switch} from "react-router-dom";
import Util from "../../util/Util";

import ApplicationEdit from "./ApplicationEdit";
import ApplicationList from "./ApplicationList";
import CompanyApi from "../company/CompanyApi";

const Application = (props) => {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const [filter, setFilter] = useState({
    name: '',
    companyId: '',
  });
  const [companies, setCompanies] = useState([]);
  const [applications, setApplications] = useState([]);
  const [parentPath, setParentPath] = useState("");

  useEffect(() => {
    searchApplications(pagination.current);
  }, []);

  useEffect(() => {
    if (Util.isAdmin()) {
      loadCompanies();
    }
  }, []);

  useEffect(() => {
    setParentPath(props.match.path);
  }, [props.match.path]);

  const searchApplications = (page) => {
    ApplicationApi.getApplications(getSearchParam(page)).then(res => {
      const pagingResult = res.data;
      const pagination = {
        current: pagingResult.page,
        pageSize: pagingResult.pageSize,
        total: pagingResult.count,
      }
      setApplications(pagingResult.data);
      setPagination(pagination);
    });
  };

  const loadCompanies = () => {
    const companyPagination = {
      current: 1,
      pageSize: 100,
    }
    CompanyApi.getCompanies(companyPagination).then(res => {
      setCompanies(res.data.data);
    });
  };

  const getSearchParam = (page) => {
    const params = {
      pageSize: pagination.pageSize,
      page: page,
      ...filter
    };
    return params;
  };

  const setFilterState = (filter) => {
    setFilter(JSON.parse(JSON.stringify(filter)));
  };

  const setPaginationState = (pagination) => {
    setPagination(JSON.parse(JSON.stringify(pagination)));
  };

  const setApplicationState = (changedApp) => {
    const apps = [...applications].map(app => {
      if (changedApp.id === app.id) {
        return changedApp;
      } else {
        return app;
      }
    });
    setApplications(apps);
  };

  // NOTICE: path argument를 사용하기 위해서는 Route의 순서가 중요. new -> edit -> show 순서로 작성
  return (
      <div>
        <div className="page-content">
          <Switch>
            <Route path={props.match.path} exact render={(props) => (
                       <ApplicationList
                           applications={applications}
                           pagination={pagination}
                           filter={filter}
                           companies={companies}
                           setFilter={setFilterState}
                           setPagination={setPaginationState}
                           searchApplications={searchApplications}
                           parentPath={parentPath}
                           {...props}
                       />) }
            />
            <Route path={`${props.match.path}/new`} render={(props) => (
                <ApplicationEdit
                    applications={applications}
                    pagination={pagination}
                    searchApplications={searchApplications}
                    parentPath={parentPath}
                    {...props}
                />) }
            />
            <Route path={`${props.match.path}/:applicationId`} render={(props) => (
                  <ApplicationEdit
                      applications={applications}
                      pagination={pagination}
                      parentPath={parentPath}
                      searchApplications={searchApplications}
                      setApplication={setApplicationState}
                      {...props}
                  />) }
            />
          </Switch>
        </div>
      </div>
  );
}
export default Application