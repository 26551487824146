import React, {useEffect, useState, useCallback} from "react";
import axios from "axios";
import {
  Button, Divider,
  message, Modal, Table, Upload, Spin, Layout, Tag, Popconfirm, Alert
} from "antd";
import ProductApi from "./ProductApi";
import {adminConfig} from "../../config/admin.config";
import { UploadOutlined } from '@ant-design/icons';

import Util from "../../util/Util";
import {Link, useHistory} from "react-router-dom";
import intl from "react-intl-universal";
import FileListPopup from "../file_manager/FileListPopup";
import NoImage from "../../asset/images/no_image.png";
import CreateOrderPopup from "../order/CreateOrderPopup";
import ProductCouponPopup from "../coupons/ProductCouponPopup";

import {SettingOutlined} from "@ant-design/icons";
import {
  EventBroadcaster,
  SHOW_ERROR_MESSAGE_EVENT_TOPIC
} from "../../event/event.broadcaster";
import ProductMultiSettingPopup from "./ProductMultiSettingPopup";
import ProductFilter from "./ProductFilter";
import Cookies from "universal-cookie";
import InterlogisProductModal from "./InterlogisProductModal";
import EventProductList from "./EventProductList";

const {Content} = Layout;
const cookies = new Cookies();

const ProductList = (props) => {
  const history = useHistory();
  const {application} = props;
  const [visibleUploadModal, setVisibleUploadModal] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [saving, setSaving] = useState(false);
  const [fileModalVisible, setFileModalVisible] = useState(false);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [visibleMultiSetting, setVisibleMultiSetting] = useState(false);
  const [visibleOrderPopup, setVisibleOrderPopup] = useState(false);
  const [preparingOrder, setPreparingOrder] = useState({});
  const [visibleCouponPopup, setVisibleCouponPopup] = useState(false);
  const [couponPopupProduct, setCouponPopupProduct] = useState({});
  const [visibleFetchProductModal, setVisibleFetchProductModal] = useState(false);
  const [visibleEventProduct, setVisibleEventProduct] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});

  useEffect(() => {
    EventBroadcaster.on(SHOW_ERROR_MESSAGE_EVENT_TOPIC, () => {
      setSaving(false);
    });
  }, []);

  const showUploadModal = () => {
    setVisibleUploadModal(true);
    setFileList([]);
  };

  const handleMultiSetting = e => {
    if (selectedProductIds.length === 0) {
      message.error(intl.get("common.message.selectAtLeastOne", {name: intl.get("products.product")}));
      return;
    }
    let hasMarketProduct = false;
    selectedProductIds.forEach(id => {
      const product = props.products.find(p => p.id === id);
      if (product && product.marketProduct) {
        hasMarketProduct = true;
      }
    });
    if (hasMarketProduct) {
      message.error(intl.get("products.message.selected.copied"));
      return;
    }
    setVisibleMultiSetting(true);
  };

  const handleMultiSettingOk = () => {
    setVisibleMultiSetting(false);
    const {pagination} = props;
    props.searchProducts(pagination);
  };

  const productTableChanged = (pagination) => {
    props.searchProducts(pagination);
  };

  const handleSearchClick = (filter) => {
    const {pagination} = props;
    pagination.current = 1
    props.searchProducts(pagination, filter);
  };

  const setFilter = (filter) => {
    props.setFilter(filter);
  };

  const uploadProps = {
    onRemove: file => {
      const fileListState = [...fileList];
      const index = fileListState.indexOf(file);
      const newFileList = fileListState.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: file => {
      setFileList([file]);
      return false;
    },
    fileList,
  };

  const handleFileUploadOk = () => {
    const formData = new FormData();
    fileList.forEach(file => {
      formData.append('files', file);
    });
    setSaving(true);

    let apiPath = adminConfig.apiServer() + "/products/file";
    // let headers = HttpUtil.getHeader();
    axios.post(apiPath, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      message.success(intl.get("common.message.saved"));
      setFileList([]);
      setVisibleUploadModal(false);
      setSaving(false);

      const {pagination} = props;
      pagination.current = 1
      props.searchProducts(pagination);
    }).catch(err => {
      console.log("Error:", err);
    }).finally(() => {
      setSaving(false);
    });
  };

  const handleOrderTest = () => {
    const selectedProductIdsState = [...selectedProductIds];
    if (!selectedProductIdsState || selectedProductIdsState.length === 0) {
      message.error("주문할 제품을 선택하세요.");
      return;
    }
    const skus = [];
    selectedProductIdsState.forEach(pid => {
      const product = products.find(p => p.id === pid);
      if (!product) {
        message.error(intl.get("common.message.noData", {name: intl.get("order.list.productInfo")}));
        return;
      }
      skus.push({
        cartId: 0,
        productId: product.id,
        skuId: product.skus[0].id,
        quantity: 1,
      })
    })
    const prepareOrder = {
      skus: skus,
      couponIds: [],
    };
    setPreparingOrder(prepareOrder);
    setVisibleOrderPopup(true);
  };

  const showProductCouponPopup = (e, product) => {
    e.stopPropagation();
    setVisibleCouponPopup(true)
    setCouponPopupProduct(product);
  };

  const deleteProduct = (e, product) => {
    e.stopPropagation();
    setSaving(true);
    ProductApi.deleteProduct(product.id).then(res => {
      message.info(intl.get("common.message.deleted"));
      const {pagination, filter} = props;
      props.searchProducts(pagination);
    }).finally(() => {
      setSaving(false);
    });
  };

  const showEventProducts = (e, product) => {
    e.stopPropagation();
    setSelectedProduct(product);
    setVisibleEventProduct(true);
  };

  const showMoreSku = (e, productId) => {
    e.stopPropagation();
    const products = JSON.parse(JSON.stringify(props.products)).map(p => {
      if (p.id === productId) {
        p.showMore = !p.showMore;
      }
      return p;
    });

    props.setProducts(products);
  };

  const alertClosed = () => {
    // 2 day
    cookies.set("productAlert", "closed", {path: '/', maxAge: 86400 * 2});
  };
  const productAlertCookie =  cookies.get("productAlert") || "";

  const columns = [{
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      render: (text, record) => {
        if (record.supplierProductId) {
          return (<><div>{record.id}</div><div>({record.supplierProductId})</div></>)
        } else if (record.marketProduct) {
          return (<><div>{record.id}</div><div>({record.marketProductId})</div></>)
        } else {
          return text;
        }
      }
    }, {
      title: "",
      dataIndex: 'images',
      key: 'images',
      align: 'center',
      render: (text, record) => {
        const image = record.images.length > 0 ? record.images[0] + "?x-oss-process=image/resize,h_48,w_48" : NoImage;
        return  (<span><img src={image} style={{width: "48px", height: "48px"}}/></span>);
      }
    }, {
      title: intl.get("products.name"),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        return (
            <div>
              <div>{record.name}</div>
              { (record.name !== record.nameKr) && (<div>{record.nameKr}</div>) }
              { record.eventProduct && (<Tag color="geekblue">{intl.get("products.button.eventProduct")}</Tag>)}
              { record.tags && (
                <div>
                  { record.tags.map((t, index) => (<Tag key={index} color="purple">{t.value}</Tag>)) }
                </div>
              )}
            </div>
        )
      }
    }, {
      title: intl.get("products.totalSales"),
      dataIndex: 'totalSales',
      key: 'totalSales',
    }, {
      title: intl.get("products.options") + "/" +
        intl.get("products.sku.barCode") + "/" +
        intl.get("products.taxRate") + "/" +
        intl.get("products.sku.stocks"),
      key: 'skus',
      dataIndex: 'skus',
      align: 'left',
      render: (text, record) => {
        const skuInfos = [];
        record.skus.forEach((sku, index) => {
          if (!record.showMore && index >= 5) {
            if (index === 5) {
              skuInfos.push((
                <div key={index} className="product-list-sku-item" style={{textAlign: "left"}}>
                  <a onClick={(event) => {showMoreSku(event, record.id)}}>
                    {intl.get("products.list.moreSku", {count: record.skus.length - 5})}
                  </a>
                </div>
              ));
            }
            return;
          }
          const eventOriginProductName = sku.eventOriginProductName + " " ? sku.eventOriginProductName : "";
          skuInfos.push((
            <div className="product-list-sku-item" key={sku.id} style={{display: "flex"}}>
              <div style={{marginRight: 5, width: "50%"}}>{eventOriginProductName}{sku.optionText}</div>
              <div style={{marginRight: 5, width: "30%"}}>{sku.barCode}</div>
              <div style={{marginRight: 5, width: "10%"}}>{sku.taxRate}%</div>
              <div style={{width: "10%"}}>{sku.realStocks}</div>
            </div>
          ));
        })
        if (record.showMore && record.skus.length > 5) {
          skuInfos.push((
            <div key={"close"} className="product-list-sku-item" style={{textAlign: "left"}}>
              <a onClick={(event) => {showMoreSku(event, record.id);}}>{intl.get("products.list.closeSku")}</a>
            </div>
          ));
        }
        return (<div style={{fontSize: 11}}>{skuInfos}</div>)
      }
    }, {
      title: intl.get("products.salesPrice"),
      dataIndex: 'salesPrice',
      key: 'salesPrice',
      align: 'right',
      render: (text, record) => (
          <div>
            {(record.productPrices.listPrice.originPrice != 0 && record.productPrices.listPrice.originPrice !== record.productPrices.salesPrice.originPrice) && (
              <div style={{fontSize: 12, color: "gray", textDecoration: "line-through"}}>
                {Util.currencyFormat(record.productPrices.listPrice.originPrice, application.productCurrency)}
              </div>
            )}
            <div>{Util.currencyFormat(record.productPrices.salesPrice.originPrice, application.productCurrency)}</div>
            <div style={{color: "gray", fontSize: 13}}>({Util.currencyFormat(record.productPrices.salesPrice.secondaryPrice, application.userPayCurrency)})</div>
          </div>
      )
    }, {
      title: intl.get("products.attr"),
      dataIndex: 'published',
      key: 'published',
      align: 'left',
      render: (text, record) => {
        return (
          <div>{Util.getProductAttrs(record).map((attr, index) => (<Tag key={index} color={attr.color}>{attr.value}</Tag>))}</div>
        )
      }
    }, {
      title: intl.get("common.createdAt"),
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: (text, record) => (
          <span>{text ? text.substring(0, 10) : ""}</span>
      )
    }, {
      title: '',
      dataIndex: 'id',
      key: 'action',
      align: 'center',
      render: (text, record) => {
        const hasEventProduct = props.hasEventProductIds.find(id => id === record.id);
        return (
          <div>
            {/*<div style={{marginTop: 10}}>*/}
            {/*  <div style={{color: "#006aff", cursor: "pointer", marginBottom: "5px"}}*/}
            {/*       onClick={(e) => {*/}
            {/*         e.stopPropagation();*/}
            {/*         history.push(props.parentPath + "/" + record.id);*/}
            {/*       }}>*/}
            {/*    {intl.get("common.button.edit")}*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div>
              <Popconfirm
                title={intl.get("common.confirm.delete", {name: intl.get("products.product")})}
                onConfirm={(e) => deleteProduct(e, record)}
                onCancel={(e) => e.stopPropagation()}
              >
                <div style={{color: "#006aff", cursor: "pointer", marginBottom: "5px"}}
                     onClick={(e) =>  e.stopPropagation()}>
                  {intl.get("common.button.delete")}
                </div>
              </Popconfirm>
            </div>
            {hasEventProduct && (
              <div>
                <div style={{color: "#006aff", cursor: "pointer", marginBottom: "5px"}}
                     onClick={(e) => showEventProducts(e, record)}>
                  {intl.get("products.relatedProducts")}
                </div>
              </div>
            )}
          </div>
        )
      }
  }];

  const rowSelection = {
    selectedRowKeys: selectedProductIds,
    onChange: (selectedProductIds) => {
      setSelectedProductIds(selectedProductIds);
    },
    onSelect: (record, selected, selectedRows, nativeEvent) => {
      nativeEvent.stopPropagation();
    }
  };

  const { pagination, products } = props;

  return (
    <Spin spinning={props.loading || saving}>
      {props.numOfFailedCopied > 0 && !productAlertCookie && (
        <Alert
          message={intl.get("products.warnFailedCopiedProduct", {count: props.numOfFailedCopied})}
          type="error"
          showIcon
          closable
          afterClose={alertClosed}
        />
      )}
      <div className="page-op-box">
        <ProductFilter
          setFilter={setFilter}
          handleSearch={handleSearchClick}
          {...props}
        />
        <div className="extra-op-box">
          <Link to={`${props.match.path}/new`}>
            <Button type="default" size="small">{intl.get("products.button.addProduct")}</Button>
          </Link>
          <Divider type="vertical" />
          <Link to={`${props.match.path}/new-event-product`}>
            <Button type="default" size="small">{intl.get("products.button.addEventProduct")}</Button>
          </Link>
          <Divider type="vertical" />
          <Button type="default" size="small" onClick={showUploadModal}>{intl.get("common.button.upload")}</Button>
          <Divider type="vertical" />
          <Button type="default" size="small" onClick={props.downloadProducts}>{intl.get("common.button.download")}</Button>
          <Divider type="vertical"/>
          <Button type="default" size="small" onClick={() => setFileModalVisible(true)}>{intl.get("common.button.fileManager")}</Button>
          <Divider type="vertical" />
          <Button type="default" size="small" onClick={handleMultiSetting}>{intl.get("common.button.multiSetting")}</Button>
          <Divider type="vertical" />
          <Button type="default" size="small" onClick={handleOrderTest}>{intl.get("products.button.testOrder")}</Button>
          { props.visibleFetchProductButton && (
            <>
              <Divider type="vertical" />
              <Button type="default" size="small" onClick={() => setVisibleFetchProductModal(true)}>상품정보가져오기</Button>
            </>
          )}
        </div>
      </div>
      <div style={{marginTop: "10px", cursor: "pointer"}}>
        <Table size={'small'}
               dataSource={products}
               columns={columns}
               rowKey={'id'}
               title={() => intl.get("common.label.listTotal", {total: pagination.total})}
               pagination={pagination}
               onChange={productTableChanged}
               rowSelection={rowSelection}
               rowClass={(record) => record.marketProduct ? "market_product_row" : "'"}
               onRow={(record, rowIndex) => {
                 return {
                   onClick: event => {
                     history.push(props.parentPath + "/" + record.id);
                   },
                 }
               }}
        />
      </div>
      {visibleMultiSetting && (
      <ProductMultiSettingPopup
          products={products}
          selectedProductIds={selectedProductIds}
          categories={props.categories}
          brands={props.brands}
          handleMultiSettingOk={handleMultiSettingOk}
          handleMultiSettingCancel={() => setVisibleMultiSetting(false)}
      />
      )}

      {visibleUploadModal && (
        <Modal
            title={[<div key={1}><SettingOutlined/> {intl.get("common.button.upload")}</div>]}
            visible={true}
            onOk={handleFileUploadOk}
            onCancel={() => setVisibleUploadModal(false)}
            confirmLoading={saving}
        >
          <Layout>
            <Content className="modal-content">
              <div>
                <p>{intl.get("products.upload.help1")}</p>
                <p><a href={Util.getOssBucket() + "/downloads/youma-product-sample.xlsx"} style={{color: 'blue'}}>[{intl.get("products.upload.help2")}]</a></p>
              </div>
              <Divider dashed />
              <div>
                <Upload {...uploadProps}>
                  <Button>
                    <UploadOutlined /> {intl.get("products.upload.help3")}
                  </Button>
                </Upload>
              </div>
            </Content>
          </Layout>
        </Modal>
      )}
      {fileModalVisible && (
        <FileListPopup id={"productModal"}
                       fileKind={"product"}
                       visible={fileModalVisible}
                       multipleFile={false}
                       canSelect={false}
                       handleCancel={() => {setFileModalVisible(false)}}
                       handleOk={() => {}}
        />
      )}
      {visibleOrderPopup && (
          <CreateOrderPopup
              handleCancel={() => setVisibleOrderPopup(false)}
              preparingOrder={preparingOrder}
          />
      )}
      {visibleCouponPopup && (
          <ProductCouponPopup
              handleCancel={() => setVisibleCouponPopup(false)}
              product={couponPopupProduct}
          />
      )}
      {visibleFetchProductModal && (
        <InterlogisProductModal handleCancel={() => setVisibleFetchProductModal(false)}/>
      )}
      {visibleEventProduct && (
        <EventProductList eventOriginProductId={selectedProduct.id}
                          product={selectedProduct}
                          handleCancel={() => setVisibleEventProduct(false)}
                          />
      )}
    </Spin>
  );
}

export default ProductList;