import React from 'react';
import NumberFormat from 'react-number-format';
import { Card } from 'antd';
import Util from "../../util/Util";

import IncreaseIcon from "../../asset/images/increase_icon.png";
import DecreaseIcon from "../../asset/images/decrease_icon.png";

const NumberComparingIndicator = (props) => {
  const { mainTitle, versusTitle, value, versusValue } = props;

  let percent = 0;
  if (versusValue === 0 && value === 0) {
    percent = 0;
  } else if (versusValue === 0) {
    percent = 999;
  } else {
    percent = Math.round(((value-versusValue)/versusValue) * 100);
  }

  const diff = Math.abs(value - versusValue);
  const increase = value - versusValue >= 0;

  const icon = increase ? IncreaseIcon : DecreaseIcon;
  const color = increase ? "#00A958" : "#C34C4A";

  return (
    <Card bodyStyle={{padding: 20}}>
      <div>
        <div style={{lineHeight: '12px'}}>
          <span style={{display: 'block', textAlign: 'center', fontSize: 14, fontWeight: 'bold', color: '#292D36',  marginBottom: "3px"}}>{mainTitle}</span>
          <span style={{display: 'block', textAlign: 'center', fontSize: 12, color: 'gray'}}> vs {versusTitle}</span>
        </div>
        <div style={{marginTop: '5px', textAlign: 'center'}}>
          {props.mode !== "count" && (
            <span style={{textAlign: 'center', fontSize: 16, lineHeight: '38px', color: '#464646', marginRight: "3px"}}>
              {Util.getSymbol(props.application.productCurrency)}
            </span>
            )}
          <span style={{textAlign: 'center', fontSize: 38, lineHeight: '38px', fontWeight: 'bold', color: '#464646'}}>
            <NumberFormat value={value} displayType={'text'} thousandSeparator={true}/>
          </span>
        </div>
        <div style={{textAlign: 'center'}}>
          <img src={icon} style={{width: 24, height: 12}}/>
            <span style={{color: color, fontWeight: 'bold'}}>
              {props.mode !== "count" && (<span style={{fontSize: 10}}>{Util.getSymbol(props.application.productCurrency)}</span>)}
              <NumberFormat value={diff} displayType={'text'} thousandSeparator={true}/> ({percent}%)
            </span>
        </div>
      </div>
    </Card>
  )
};

export default NumberComparingIndicator;