import React from "react";
import { Button, Modal } from "antd";
import CategoryApi from "./CategoryApi";

import intl from "react-intl-universal";

import { SettingOutlined } from "@ant-design/icons";
import CategoryList from "./CategoryList";

export default class CategoryNewPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      merchandiserId: "",
      firstLevelCategories: [],
      selectedCategoryId: "",
      level: 1,
      name: "",
      imageUrl: "",
      active: true,
      visible: false,
      visibleFileModal: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible) {
      this.setState(
        {
          merchandiserId: this.props.merchandiserId,
          visible: this.props.visible,
          selectedCategoryId: "",
          level: 1,
          name: "",
          imageUrl: "",
          active: true,
        },
        () => {
          this.loadCategories();
        }
      );
    }
  }

  loadCategories = () => {
    const options = {
      merchandiser_id: this.state.merchandiserId,
      level: 1,
      pageSize: 1000,
    };

    CategoryApi.getCategories(options)
      .then((json) => {
        const categories = json.result.data;
        const firstLevelCategories = CategoryApi.groupByFirstLevel(categories);

        this.setState({
          firstLevelCategories: firstLevelCategories,
        });
      })
      .catch((e) => {});
  };

  onCancel = () => {
    this.props.onCancel();
  };

  render() {
    const { visible } = this.state;

    return (
      <Modal
        open={visible}
        title={[
          <div key={1}>
            <SettingOutlined /> {intl.get("category.modal.add")}
          </div>,
        ]}
        onCancel={this.onCancel}
        width={"800px"}
        footer={[
          <Button key="1" onClick={this.onCancel}>
            {intl.get("common.button.close")}
          </Button>,
        ]}
      >
        <CategoryList />
      </Modal>
    );
  }
}
