import React, {useEffect, useState} from 'react';
import intl from "react-intl-universal";
import {Button, Divider, Tag, message, Spin} from "antd";
import {Link} from "react-router-dom";
import CategoryViewSettingApi from "./CategoryViewSettingApi";
import {MenuOutlined, SettingOutlined, DeleteOutlined} from "@ant-design/icons";
import DraggableTable from "../../DraggableTable";
import {sortableHandle} from "react-sortable-hoc";
import "./CategoryViewSetting.less";
import IconOrderingModal from "./IconOrderingModal";
import IconEditModal from "./IconEditModal";
import SettingEditModal from "./SettingEditModal";
import CategoryApi from "../../category/CategoryApi";
import BrandApi from "../../brand/BrandApi";

const CategoryViewSetting = (props) => {
  const [categoryViewSettings, setCategoryViewSettings] = useState([]);
  const [maxSeq, setMaxSeq] = useState(0);
  const [selectedCvs, setSelectedCvs] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [visibleCVSEdit, setVisibleCVSEdit] = useState(false);
  const [modalCvs, setModalCvs] = useState({});
  const [visibleIconEdit, setVisibleIconEdit] = useState(false);
  const [maxIconId, setMaxIconId] = useState(0);
  const [visibleIconOrdering, setVisibleIconOrdering] = useState(false);
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    loadCategoryViewSettings(selectedCvs);
    loadCategories();
    loadBrands();
  }, []);

  useEffect(() => {
    let maxIconId = 0;
    if (selectedCvs?.editingSettings?.length > 0) {
      maxIconId = Math.max(...selectedCvs.editingSettings.map(icon => icon.id));
    }
    setMaxIconId(maxIconId);
  }, [selectedCvs]);

  const loadCategories = () => {
    CategoryApi.getCategoriesByApp().then(res => {
      setCategories(res.data);
    });
  };

  const loadBrands = () => {
    BrandApi.getBrands().then(res => {
      setBrands(res.data);
    });
  };

  const loadCategoryViewSettings = (selected) => {
    CategoryViewSettingApi.getCategoryViewSettings({}).then(res => {
      const cvsList = res.data;
      setCategoryViewSettings(cvsList);
      const maxSeq = cvsList.length > 0 ? Math.max(...cvsList.map(cvs => cvs.seq)) : 0;
      setMaxSeq(maxSeq);
      if (selected) {
        setSelectedCvs(selected);
      } else {
        if (cvsList.length > 0) {
          const firstCvs = JSON.parse(JSON.stringify(cvsList[0]));
          firstCvs.saved = true;
          setSelectedCvs(firstCvs);
        } else {
          setSelectedCvs(null);
        }
      }
    });
  };

  const submitSave = (cvs, publish) => {
    const categoryViewSettingsState = JSON.parse(JSON.stringify(categoryViewSettings));
    const copiedCvs = JSON.parse(JSON.stringify(cvs));
    if (publish) {
      copiedCvs.publishedSettings = copiedCvs.editingSettings;
    }
    CategoryViewSettingApi.submitCategoryViewSetting(copiedCvs).then(res => {
      message.info(intl.get("common.message.saved"));
      const savedCvs = res.data;
      if (copiedCvs.id === 0) {
       // created
        categoryViewSettingsState.push(savedCvs);
        setCategoryViewSettings(categoryViewSettingsState);
      } else {
        setCategoryViewSettings(categoryViewSettingsState.map(cvs => {
          if (cvs.id === copiedCvs.id) {
            return savedCvs;
          } else {
            return cvs;
          }
        }));
      }
    });
  };

  const submitPublish = () => {
    submitSave(selectedCvs, true);
  };

  const submitSaveCurrentCvs = () => {
    if (!selectedCvs) {
      message.error(intl.get("common.selectField", {name: intl.get("page_setting.page.category")}));
      return;
    }
    submitSave(selectedCvs, false);
  };

  const addCvs = () => {
    const newMaxSeq = maxSeq + 1;
    setMaxSeq(newMaxSeq);
    setModalCvs({
      id: 0,   // new
      seq: newMaxSeq,
      active: true,
      editingSettings: [],
      publishedSettings: [],
      saved: true,     //  화면 제어용 필드(저장은 안됨)
    });
    setVisibleCVSEdit(true);
  };

  const onDragEnd = (sortedList, oldIndex, newIndex) => {
    if (oldIndex !== newIndex) {
      setLoading(true);
      CategoryViewSettingApi.changSeq(oldIndex, newIndex).then(res => {
      }).finally(() => {
        loadCategoryViewSettings(selectedCvs);
        setLoading(false);
      })
    }
  };

  const onCvsSelected = (record) => {
    const updatedCvsList = categoryViewSettings.map(cvs => {
      cvs.saved = true;
      if (cvs.id === record.id) {
        setSelectedCvs(JSON.parse(JSON.stringify(cvs)));
      }
      return cvs;
    });
    setCategoryViewSettings(updatedCvsList);
  };

  const deleteCvs = (cvs) => {
    if (!window.confirm(intl.get("common.confirm.delete2"))) {
      return;
    }
    CategoryViewSettingApi.deleteCategoryViewSetting(cvs.id).then(res => {
      message.info(intl.get("common.message.deleted"));
      loadCategoryViewSettings(null);
    });
  };

  const iconSaved = (editingIcon) => {
    const selectedCvsState = JSON.parse(JSON.stringify(selectedCvs));
    if (!selectedCvsState.editingSettings) {
      selectedCvsState.editingSettings = [];
    }
    if (selectedCvsState.editingSettings.find(icon => icon.id === editingIcon.id)) {
      selectedCvsState.editingSettings = selectedCvsState.editingSettings.map(icon => {
        if (icon.id === editingIcon.id) {
          return editingIcon;
        } else {
          return icon;
        }
      });
    } else {
      selectedCvsState.editingSettings.push(editingIcon);
      setMaxIconId(editingIcon.id);
    }
    setSelectedCvs(selectedCvsState);
    setSelectedIcon(editingIcon);
    setVisibleIconEdit(false);
  };

  const iconDeleted = (editingIcon) => {
    const selectedCvsState = JSON.parse(JSON.stringify(selectedCvs));
    if (!selectedCvsState.editingSettings) {
      return;
    }
    selectedCvsState.editingSettings = selectedCvsState.editingSettings.filter(icon => icon.id !== editingIcon.id);
    setSelectedCvs(selectedCvsState);
    setVisibleIconEdit(false);
  }

  const onIconOrderChanged = (editingIcons) => {
    if (editingIcons.length === 0) {
      return;
    }

    const selectedCvsState = JSON.parse(JSON.stringify(selectedCvs));
    selectedCvsState.editingSettings = editingIcons;
    setSelectedCvs(selectedCvsState);
    setVisibleIconOrdering(false);
  };

  const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
  const columns = [{
    title: intl.get("page_setting.edit.toolbar.sort"),
    dataIndex: 'sort',
    width: 50,
    className: 'drag-visible',
    render: () => <DragHandle/>
  }, {
    title: "Label",
    dataIndex: "title",
    key: "title",
    width: 200,
    render: (text, record) => {
      if (selectedCvs && record.id === selectedCvs.id) {
        return (
          <div style={{display: "flex"}}>
            <div className={"title-column"} style={{marginRight: 10, color: record.active ? "black": "#a8a8a8"}}>{text}</div>
            <div style={{marginLeft: "auto"}}><Tag color={"volcano"} size={"small"}>{intl.get("company.user.editing")}</Tag></div>
          </div>
        )
      } else {
        return <div className={"title-column"} style={{color: record.active ? "black": "#a8a8a8"}}>{text}</div>
      }
    }
  }, {
    title: "",
    dataIndex: "id",
    key: "id",
    render: (text, record) => {
      return (
        <div style={{display: "flex", gap: 5}}>
          <SettingOutlined onClick={() => {setModalCvs(record); setVisibleCVSEdit(true)}}/>
          <DeleteOutlined onClick={() => deleteCvs(record)}/>
        </div>
      )
    }
  }];

  return (
    <Spin spinning={loading}>
      <div className={"category-view-setting"}>
        <div style={{width: "100%", marginLeft: "auto"}}>
          <div style={{display: "flex", alignItems: "center"}}>
            <div style={{fontSize: 16, fontWeight: "bold", marginRight: 10}}>
              {intl.get("page_setting.categoryPage")}
            </div>
            <div style={{marginRight: 10, marginLeft: "auto"}}>
              <div style={{width: 100}}>
                <Button style={{width: 100}} type="primary" block><Link to={`${props.parentPath}`}>{intl.get("common.button.list")}</Link></Button>
              </div>
            </div>
          </div>
        </div>
        <div style={{marginTop: 20}}>
          <div style={{display: "flex", alignItems: "flex-start"}}>
            <div style={{width: 300}}>
              <div>
                <Button onClick={addCvs}>{intl.get("page_setting.categoryPage.addItem")}</Button>
              </div>
              <div style={{marginTop: 10}}>
                <DraggableTable
                  showHeader={false}
                  dataSource={categoryViewSettings}
                  columns={columns}
                  rowKey={"id"}
                  onSortEnd={onDragEnd}
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: event => {
                        onCvsSelected(record);
                      },
                    }
                  }}
                  />
              </div>
            </div>
            <div style={{width: 400, marginLeft: 20}}>
              <div>
                <Button onClick={() => {setSelectedIcon({id: maxIconId + 1}); setVisibleIconEdit(true)}}>아이콘{intl.get("common.button.add")}</Button>
                <Divider type={"vertical"}/>
                <Button disabled={selectedCvs?.editingSettings?.length === 0} onClick={() => setVisibleIconOrdering(true)}>아이콘순서</Button>
                <Divider type={"vertical"}/>
                <Button onClick={submitSaveCurrentCvs}>{intl.get("common.button.save")}</Button>
                <Divider type={"vertical"}/>
                <Button onClick={submitPublish}>{intl.get("page_setting.published")}</Button>
              </div>
              <div style={{marginTop: 10, minHeight: 400, padding: 12, width: 400}}>
                <div>화면 미리보기</div>
                <div style={{marginTop: 10, minHeight: 400, padding: 12, width: 272, border: "1px solid #ebebeb"}}>
                  <div style={{display: "flex", flexWrap: "wrap", gap:12, rowGap: 16}}>
                    {selectedCvs?.editingSettings && selectedCvs.editingSettings.map(icon => (
                      <div key={icon.id} style={{display: "flex", flexDirection: "column", justifyContent: "center"}}
                           onClick={() => { setSelectedIcon(icon); setVisibleIconEdit(true);} }>
                        <img src={icon.image} style={{width: 74, height: 74}}/>
                        <div className={"icon-title"}>{icon.title}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div style={{flex: 1, marginLeft: 20}}>
            </div>
          </div>
        </div>
      </div>
      {visibleCVSEdit && (
        <SettingEditModal
          cvs={modalCvs}
          saveCvs={submitSave}
          handleOk={() => setVisibleCVSEdit(false)}
          handleCancel={() => setVisibleCVSEdit(false)}
        />
      )}
      {visibleIconEdit && (
        <IconEditModal selectedIcon={selectedIcon}
                       brands={brands}
                       categories={categories}
                       handleOk={iconSaved}
                       handleDeleteIcon={iconDeleted}
                       handleCancel={() => setVisibleIconEdit(false)}
        />
      )}
      {visibleIconOrdering && (
        <IconOrderingModal
          cvs={selectedCvs}
          handleOk={onIconOrderChanged}
          handleCancel={() => setVisibleIconOrdering(false)}
        />

      )}
    </Spin>
  )
};

export default CategoryViewSetting;