import React, {useCallback, useEffect, useState} from "react";
import {Button, DatePicker, Divider, Select, Table, Input} from "antd";
import intl from "react-intl-universal";
import dayjs from "dayjs";
import SettlementApi from "./SettlementApi";
import CompanyUserDetailPopup from "../company/CompanyUserDetailPopup";
import UserListPopUp from "../user/UserListPopup";

const { Search } = Input;

const SellerSettlement = (props) => {
  const [sellerSettlements, setSellerSettlements] = useState([]);
  const [month, setMonth] = useState(dayjs());
  const [baseDate, setBaseDate] = useState("finished_at");
  const [selectedCompanyUserId, setSelectedCompanyUserId] = useState(0);
  const [visibleCompanyUser, setVisibleCompanyUser] = useState(false);
  const [sellerUserId, setSellerUserId] = useState("");
  const [visibleSellerSearch, setVisibleSellerSearch] = useState(false);

  const getSellerSettlements = useCallback((month, baseDate) => {
    const params = {
      month: month.format("YYYY-MM"),
      baseDate: baseDate,
      sellerUserId: sellerUserId,
    };
    SettlementApi.getSellerSettlements(params).then(res => {
      setSellerSettlements(res.data);
    });
  }, [sellerSettlements, sellerUserId]);

  const onBaseDateChanged = (value) => {
    setBaseDate(value);
    getSellerSettlements(month, value);
  };

  const onMonthChanged = (value) => {
    setMonth(value);
    getSellerSettlements(value, baseDate);
  }

  const showCompanyUser = (e, companyUserId) => {
    e.stopPropagation();
    setSelectedCompanyUserId(companyUserId);
    setVisibleCompanyUser(true);
  }

  const onChangeSellerUserId = useCallback((e) => {
    setSellerUserId(e.target.value);
  }, [sellerUserId]);

  const onSellerUserSelected = useCallback((user) => {
    setVisibleSellerSearch(false);
    setSellerUserId(user.id);
  }, [sellerUserId, visibleSellerSearch]);

  const columns = [{
    title: intl.get("common.label.month"),
    dataIndex: 'month',
    key: 'month',
  }, {
    title: intl.get("order.sellerUserId") + "(" + intl.get("common.label.wechat") +  ")",
    dataIndex: 'sellerUserId',
    key: 'sellerUserId',
    render: (text, record) => {
      return (<div>ID: {record.sellerUserId}<br/>{intl.get("user.name")}: {record.sellerUserName}</div>)
    }
  }, {
    title: intl.get("company.user.shopUser"),
    dataIndex: 'sellerCompanyUserId',
    key: 'sellerCompanyUserId',
    render: (text, record) => {
      if (record.sellerCompanyUserId !== 0) {
        return (
          <div onClick={(e) => showCompanyUser(e, record.sellerCompanyUserId)}>
            ID: {record.sellerCompanyUserId}<br/>
            Name: {record.sellerCompanyUserName}<br/>
            LoginID: {record.sellerCompanyLoginId}
          </div>
        )
      } else {
        return (<div>{intl.get("company.user.notRegisteredSeller")}</div>)
      }
    }
  }, {
    title: intl.get("settlement.numOrders"),
    dataIndex: 'numOrders',
    key: 'numOrders',
    align: "right"
  }, {
    title: intl.get("settlement.quantity"),
    dataIndex: 'quantity',
    key: 'quantity',
    align: "right"
  }, {
    title: intl.get("settlement.salesAmnount"),
    dataIndex: 'paymentAmountUser',
    key: 'paymentAmountUser',
    align: "right",
  }];

  return (
    <div>
      <div className="page-content">
        <div className="page-op-box">
          <div className="filter-box">
            <div>
              <DatePicker picker="month" format={"YYYY-MM"} value={month} onChange={onMonthChanged}/>
            </div>
            <Divider type={"vertical"}/>
            <div>
              <Select value={baseDate} style={{ width: 130, marginRight: 10 }} onChange={onBaseDateChanged}>
                <Select.Option key={"finished_at"} value={"finished_at"}>{intl.get("order.finishedAt")}</Select.Option>
                <Select.Option key={"order_date"} value={"order_date"}>{intl.get("order.orderDate")}</Select.Option>
              </Select>
            </div>
            <Divider type={"vertical"}/>
            <Search value={sellerUserId}
                    placeholder={intl.get("order.sellerUserId")}
                    style={{width: 150}}
                    onChange={onChangeSellerUserId}
                    onSearch={() => setVisibleSellerSearch(true)} />
            <div style={{marginLeft: "auto"}}>
              <div style={{width: 100}}>
                <Button style={{width: 100}} type="primary" block size="default" onClick={() => getSellerSettlements(month, baseDate)}>{intl.get("common.button.search")}</Button>
              </div>
            </div>
          </div>
        </div>
        <div style={{marginTop: "10px", cursor: "pointer"}}>
          <Table size={'small'}
                 dataSource={sellerSettlements}
                 columns={columns}
                 rowKey={'sellerUserId'}
                 pagination={false}
          />
        </div>
      </div>
      {visibleCompanyUser && (
        <CompanyUserDetailPopup
          companyUserId={selectedCompanyUserId}
          onClose={() => setVisibleCompanyUser(false)}
        />
      )}
      {visibleSellerSearch && (
        <UserListPopUp onClickUser={onSellerUserSelected}
                       handleCancel={() => setVisibleSellerSearch(false)}/>
      )}
    </div>
  )
};

export default SellerSettlement;