import React, {useEffect, useState} from 'react';
import { Switch, Route } from 'react-router-dom';

import FaqList from './FaqList';
import FaqEdit from "./FaqEdit";
import FaqApi from "./FaqApi";

const Faq = (props) => {
  const [faqs, setFaqs] = useState([]);
  const [parentPath, setParentPath] = useState("");

  useEffect(() => {
    loadFaqs();
  }, []);

  useEffect(() => {
    setParentPath(props.match.path);
  }, [props.match.path]);

  const loadFaqs = () => {
    FaqApi.getFaqs({page:1, pageSize:1000}).then(res => {
      const pagingResult = res.data;
      setFaqs(pagingResult.data);
    })
  };

  const setFaqState = (faq) => {
    const changedFaqs = [...faqs].map(f => {
      if (faq.id === f.id) {
        return faq;
      } else {
        return f;
      }
    });
    setFaqs(changedFaqs);
  };

  // NOTICE: path argument를 사용하기 위해서는 Route의 순서가 중요. new -> edit -> show 순서로 작성
  return (
      <div>
        <div className="page-content">
          <Switch>
            <Route path={props.match.path} exact
                   render={(props) => (
                       <FaqList faqs={faqs}
                                loadFaqs={loadFaqs}
                                parentPath={parentPath} {...props}/>) } />
            />
            <Route path={`${props.match.path}/new`}
                   render={(props) => (
                         <FaqEdit
                             loadFaqs={loadFaqs}
                             parentPath={parentPath} {...props}/>) }
            />
            <Route path={`${props.match.path}/:faqId`}
                   render={(props) => (
                       <FaqEdit
                           setFaq={setFaqState}
                           loadFaqs={loadFaqs}
                           parentPath={parentPath} {...props}/>)} />
          </Switch>
        </div>
      </div>
  );
}

export default Faq;