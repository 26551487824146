import React, {useState} from "react";
import {Link, useHistory} from "react-router-dom";
import Util from "../../util/Util";
import intl from "react-intl-universal";
import {Button, Divider, Input, Table, Space} from "antd";
import CompanyUserManager from "./CompanyUserManager";

const CompanyList = (props) => {
  const history = useHistory();
  const [visibleCompanyUserManager, setVisibleCompanyUserManager] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState({});

  const changeCompanyName = (e) => {
    const {filter} = props;
    filter.companyName = e.target.value;
    props.setFilter(filter);
  }

  const handleSearchClick = () => {
    const {pagination} = props;
    pagination.current = 1
    props.loadCompanies(pagination);
  }

  const companyTableChanged = (pagination) => {
    props.loadCompanies(pagination);
  }

  const columns = [{
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
  }, {
    title: intl.get("company.name"),
    dataIndex: 'name',
    key: 'name',
    align: 'left',
  }, {
    title: intl.get("company.phone"),
    dataIndex: 'phone',
    key: 'phone',
  }, {
    title: intl.get("company.homePage"),
    dataIndex: 'homePage',
    key: 'homePage',
  }, {
    title: intl.get("company.creator"),
    dataIndex: 'creatorId',
    key: 'creatorId',
  }, {
    title: intl.get("company.businessRegistrationNumber"),
    dataIndex: 'businessRegistrationNumber',
    key: 'businessRegistrationNumber',
  }, {
    title: intl.get("common.createdAt"),
    dataIndex: 'createdAt',
    key: 'createdAt',
    align: 'center',
    render: (text, record) => (
        <span>{Util.getLocalTime(text).substring(0, 10)}</span>
    )
  }, {
    title: "",
    dataIndex: 'id',
    key: 'action',
    align: 'center',
    render: (text, record) => (
      <Button size="small" onClick={(e) => { e.stopPropagation(); setSelectedCompany(record); setVisibleCompanyUserManager(true);} }>사용자관리</Button>
    )
  }];

  return (
      <div>
        <div className="page-op-box">
          <div className="filter-box">
            <Space>
              <Input style={{ width: 300, marginRight: 10 }} value={props.filter.companyName} onChange={changeCompanyName} placeholder={"회사명"}/>
              <div style={{width: 150}}>
                <Button style={{width: 100}} type="primary" size="default" onClick={handleSearchClick}>{intl.get("common.button.search")}</Button>
              </div>
            </Space>
          </div>
          <div className="extra-op-box">
            <Link to={`${props.match.path}/new`}>
              <Button block type="default" size="small">{intl.get("common.button.add")}</Button>
            </Link>
          </div>
        </div>
        <div style={{marginTop: "10px"}}>
          <Table size={'small'}
                 dataSource={props.companies}
                 columns={columns}
                 rowKey={'id'}
                 title={() => intl.get("common.label.total") + " " + props.pagination.total}
                 pagination={props.pagination}
                 onChange={companyTableChanged}
                 onRow={(record, rowIndex) => {
                   return {
                     onClick: event => history.push(`/companies/${record.id}/edit`),
                   };
                 }}
          />
        </div>
        {visibleCompanyUserManager && (
          <CompanyUserManager mode={"modal"} companyId={selectedCompany.id} onClose={() => {setVisibleCompanyUserManager(false)}}/>
        )}
      </div>
  )
}

export default CompanyList;