import React, {useEffect, useState} from "react";
import intl from "react-intl-universal";
import Util from "../../../util/Util";
import NoImg from "../../../asset/images/no_image.png";

const ToolbarSlot = (props) => {
  const [settingValues, setSettingValues] = useState({});

  useEffect(() => {
    setSettingValues(JSON.parse(JSON.stringify(props.settingValues)));
  }, [props.settingValues])

  if (!settingValues.slots || settingValues.slots.length === 0) {
    return (
      <div className={"empty-slot"} style={{height: 50}}>
        <h3>{intl.get("page_setting.edit.toolbar.title")}</h3>
      </div>
    )
  }

  const paddingStyle = settingValues.padding ? settingValues.padding.split(",").map(p => p.trim() + "px").join(" ") : "10px 30px";
  const titleFontSize = settingValues.titleFontSize ? settingValues.titleFontSize + "px" : "24px";
  const titleColor = settingValues.titleColor ? settingValues.titleColor : "#313131";
  const titleFontWeight = settingValues.titleFontWeight ? "bold" : "";
  const height = settingValues.height ? settingValues.height + "px" : "50px";
  return (
    <div style={{height: height, marginBottom: 0, backgroundColor: settingValues.backgroundColor || "white"}}>
      <div style={{display: "flex", justifyContent: "space-between", flexWrap: "wrap", alignItems: "center", padding: paddingStyle}}>
        {settingValues.slots.map((slot, index) => {
            return (
              <div key={slot.id}>
                {settingValues.showIcon && (
                  <div style={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center"}}>
                    <img style={{width: settingValues.iconWidth + 'px', height: settingValues.iconHeight + 'px'}}
                          src={slot.bannerPath ? Util.encodeImagePath(slot.bannerPath) : NoImg}/>
                  </div>
                )}
                {settingValues.showTitle && (
                  <div style={{marginTop: 8, fontSize: titleFontSize, color: titleColor, fontWeight: titleFontWeight}}>{slot.title}</div>
                )}
              </div>
            )
        })}
      </div>
    </div>
  )
}

export default ToolbarSlot;