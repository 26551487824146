import React, {useEffect, useState} from 'react';

import {
  Table,
  Button,
  Divider,
  message,
  Spin
} from 'antd';

import NoticeApi from "./NoticeApi";
import Util from "../../util/Util";
import intl from "react-intl-universal";
import {Link, useHistory} from "react-router-dom";

const AdminNoticeList = (props) => {
  const history = useHistory();

  const deleteNotice = (faq) => {
    if(!window.confirm(intl.get("common.confirm.delete", {name: faq.question}))) {
      return;
    }

    NoticeApi.deleteNotice(faq.id).then(res => {
      message.success(intl.get("common.message.deleted"));
      // this.loadNotices();
    })
  };

  const onClickSearch = () => {
    const paginationState = {...props.pagination};
    paginationState.current = 1;
    props.setPagination(paginationState);
    props.searchNotices(paginationState);
  };

  const tableChanged = (e) => {
    const paginationState = {...props.pagination};
    paginationState.current = e.current;
    paginationState.pageSize = e.pageSize;
    props.setPagination(paginationState);
    props.searchNotices(paginationState);
  };

  const columns = [{
    title: "Title",
    dataIndex: 'title',
    key: 'title',
    render: (text, record) => {
      const value = text.length > 20 ? text.substring(0, 20) : text;
      return (<Link to={`${props.parentPath}/${record.id}`}>{value}</Link>);
    }
  }, {
    title: intl.get("common.updatedAt"),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    align: 'center',
    render: (text, record) => (
        <span>{Util.getLocalTime(text)}</span>
    )
  }];

  if (Util.isAdmin()) {
    columns.push({
      title: "상단고정",
        dataIndex: 'pinToTop',
        key: 'pinToTop',
        render: (text, record) => {
          const textLabel = record.pinToTop ? intl.get("common.true") : intl.get("common.false");
          return (<span>{textLabel}</span>)
        }
      }, {
        title: "Active",
        dataIndex: 'active',
        key: 'active',
        render: (text, record) => {
          const textLabel = record.active ? intl.get("common.true") : intl.get("common.false");
          return (<span>{textLabel}</span>)
        }
      }, {
        title: '',
        dataIndex: '',
        key: 'action',
        render: (text, record) => {
          return (
              <div>
                <a onClick={(e) => {
                  e.stopPropagation();
                  history.push(`${props.parentPath}/${record.id}/edit`);
                }}>{intl.get("common.button.edit")}</a>
                <Divider type="vertical"/>
                <a onClick={(e) => {
                  e.stopPropagation();
                  deleteNotice(record)
                }}>{intl.get("common.button.delete")}</a>
              </div>
          )
        }
    });
  }

  return (
    <div>
      <Spin spinning={props.loading}>
        <div className="page-op-box">
          <div className="filter-box">
            <div style={{width: "100%", display: "flex", gap: 10, alignItems: "center"}}>
              <div style={{marginLeft: "auto"}}>
                <div style={{width: 100}}>
                  <Button type="primary" block shape={"round"} onClick={onClickSearch}>{intl.get("common.button.search")}</Button>
                </div>
              </div>
            </div>
          </div>
          <div className="extra-op-box">
            <Link to={`${props.match.path}/new`}>
              <Button type="default" size="small">{intl.get("common.button.add")}</Button>
            </Link>
          </div>
        </div>
        <div style={{marginTop: "10px"}}>
          <Table size={'small'}
                 rowKey={'id'}
                 dataSource={props.notices}
                 columns={columns}
                 title={() => 'Total: ' + props.pagination.total}
                 pagination={props.pagination}
                 onChange={tableChanged}
                 onRow={(record, rowIndex) => {
                   return {
                     onClick: event => {
                       history.push(`${props.match.path}/${record.id}`);
                     },
                   }
                 }}
          />
        </div>
      </Spin>
    </div>
  )
};

export default AdminNoticeList;
