import React, { useEffect, useState } from 'react';
import { Button, Table, Spin, Popover } from "antd";
import intl from "react-intl-universal";
import LiveApi from "./LiveApi";

const RegisteredProduct = (props) => {
  let currentPage = 1;
  const PAGE_SIZE = 20;

  const [loading, setLoading] = useState(false);
  const [goods, setGoods] = useState([]);
  const [previewQr, setPreviewQr] = useState('')

  // 처음에는 로딩을 하지 않기 때문에 테이블을 나타내지 않는다.
  // 한번이라도 검색 버튼을 클릭하면 이 값은 true로 변경된다.
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // wechat api를 호출하기 때문에 페이지 로딩시 바로 조회하지 않고 사용자가 버튼 클릭 시 조회한다.
    // loadGoods();
  }, []);

  const loadGoods = () => {
    setLoading(true);
    // auditStatus-商品状态，0：未审核。1：审核中，2：审核通过，3：审核驳回
    LiveApi.getGoods({ page: currentPage, pageSize: PAGE_SIZE, auditStatus: 2 }).then(res => {
      const pagingResult = res.data;
      // console.log('pagingResult :>> ', pagingResult);
      setGoods(pagingResult.data);
      setLoading(false);
      setLoaded(true);
    }).catch(err => {
      setLoading(false);
    })
  };

  const columns = [{
    title: "ID",
    dataIndex: 'goods_id',
    key: 'goods_id',
  }, {
    title: intl.get("live.goodsImage"),
    dataIndex: 'cover_img_url',
    key: 'cover_img_url',
    render: (text, record) => {
      return <img src={record.cover_img_url} style={{ width: "80px", height: "80px" }} />
    }
  }, {
    title: intl.get("live.goodsName"),
    dataIndex: 'name',
    key: 'name',
  }, {
    title: intl.get("live.price"),
    dataIndex: 'price',
    key: 'price',
  }, {
    title: intl.get("live.link"),
    dataIndex: 'url',
    key: 'url',

  }, {
    title: '',
    dataIndex: '',
    key: 'action',
    render: (text, record) => {
      return (
        <div>
          <Popover trigger="click" placement="bottom" visible={record.previewVisible} content={previewContent}>
            <Button style={{ marginLeft: "4px" }} size="small" onClick={() => previewGoods(record)}>{intl.get("live.botton.previewGoods")}</Button>
          </Popover>
        </div>
      )
    }
  }];
 
  const previewContent = (
    <div style={{ width: '160px', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10px' }}>
      <img style={{ width: '112px', height: '112px' }} src={previewQr}></img>
      <p style={{ marginTop: '10px' }}>{intl.get("live.preview.help")}</p>
    </div>
  )
  const previewGoods = (record) => {
    // console.log('previewGoods :>> ', record);
    setPreviewQr('')
    LiveApi.createQrcode({page: 'pages/offer-detail/offer-detail'}).then(res => {
      // console.log('res :>> ', res);
      setPreviewQr(`data:image/jpg;base64,${res.data}`)
    })
  }

  if (!props.application?.useLive) {
    return (
      <>
        <div>
          {intl.get("live.cannotUseLive")}
        </div>
      </>
    )
  }

  return (
    <Spin spinning={loading}>
      <div className="page-op-box">
        <div className="filter-box">
          <div style={{marginLeft: "auto"}}>
            <div style={{width: 100}}>
              <Button style={{width: 100}} type="primary" block size="default" onClick={loadGoods}>{intl.get("common.button.search")}</Button>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "10px" }}>
        { loaded && (
          <Table size={'small'}
            rowKey={'goods_id'}
            dataSource={goods}
            columns={columns}
            pagination={false}
            onRow={(record, rowIndex) => {
              // return {
              //   onClick: event => history.push(`/faqs/${record.id}`),
              // };
            }}
          />
        )}
      </div>
    </Spin>
  );
}

export default RegisteredProduct;