import React, {useState, useEffect} from "react";
import { Table } from 'antd';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import { arrayMoveImmutable } from 'array-move';

const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

const SortableItem = sortableElement(props => <tr {...props} />);
const SortableContainer = sortableContainer(props => <tbody {...props} />);

const DraggableTable = (props) => {
  const [dataSource, setDataSource] = useState([]);
  useEffect(() => {
    setDataSource(props.dataSource);
  }, [props.dataSource])

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const dataSourceState = JSON.parse(JSON.stringify(dataSource));
      const changedDataSource = arrayMoveImmutable([].concat(dataSourceState), oldIndex, newIndex).filter(el => !!el);
      setDataSource(changedDataSource);
      if (props.onSortEnd) {
        props.onSortEnd(changedDataSource, oldIndex, newIndex);
      }
    }
  };

  const DraggableContainer = props => (
      <SortableContainer
          useDragHandle
          disableAutoscroll
          helperClass="row-dragging"
          onSortEnd={onSortEnd}
          {...props}
      />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // const { dataSource } = this.state;
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(item => {
      return item[props.rowKey] === restProps['data-row-key']
    });

    return <SortableItem index={index} {...restProps} />;
  };

  return (
      <Table
          size={'small'}
          pagination={false}
          dataSource={dataSource}
          columns={props.columns}
          // rowKey={'index'}
          rowKey={props.rowKey}
          showHeader={props.showHeader}
          onRow={props.onRow}
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
      />
  );
}

export default DraggableTable;