import React, {useEffect, useState} from "react";
import {Button, Input, Space, message, Typography, Alert} from "antd";
import CompanyForm from "./CompanyForm";
import {useLayoutDispatch, useLayoutState} from "./../../components/layout/AppLayoutContext";
import {adminConfig} from "../../config/admin.config";
import CompanyUserApi from "./CompanyUserApi";
import {AuthService} from "../../auth/auth.service";
import CompanyApi from "./CompanyApi";
import intl from 'react-intl-universal';

const { Title, Paragraph } = Typography;

const CompanyRegister = (props) => {
  const layoutState = useLayoutState();
  const layoutDispatch = useLayoutDispatch();

  const [defaultCompanyId, setDefaultCompanyId] = useState(-1);
  const [companyCode, setCompanyCode] = useState("");
  const [action, setAction] = useState("companyCode");
  const [companyUser, setCompanyUser] = useState({});

  useEffect(() => {
    CompanyApi.getDefaultCompanyId().then(res => {
      setDefaultCompanyId(res.data);
    });
    CompanyUserApi.getMy().then(res => {
      const companyUser = res.data;
      if (companyUser.userType !== "draft") {
        layoutDispatch({type:'REMOVE_ALL_TAB'});
        window.location = adminConfig.homePage;
      } else {
        setCompanyUser(res.data);
      }
    });
  }, []);

  const reloadUser = async (goIndex) => {
    CompanyUserApi.getMy().then(res => {
      const companyUser = res.data;
      if (companyUser.applicationUserRoles.length > 0) {
        AuthService.changeApplication(companyUser.applicationUserRoles[0].applicationId).then(() => {
          // 모든 Tab 삭제
          if (layoutState.pageTab.histories.length > 0) {
            layoutState.pageTab.histories.forEach(eachHistory => {
              const id = eachHistory.id;
              layoutDispatch({
                type: 'REMOVE_TAB_PAGE', id
              });
            });
          }

          // 페이지 이동
          if (goIndex) {
            window.location = adminConfig.homePage;
          } else {
            window.location.href = "/company-setting";
            window.location.reload();
          }
        }).catch(err => {
          console.log("changeApplication error:", err);
          message.warn("사용자 정보 조회시 오류가 발생했습니다. 다시 로그인 하세요.");
          AuthService.logout();
        });
      } else {
        setCompanyUser(companyUser);
      }
    }).catch(err => {
      console.log("getMy error:", err);
      message.warn("사용자 정보 조회시 오류가 발생했습니다. 다시 로그인 하세요.");
      AuthService.logout();
    });
  };

  const companyCreated = (company) => {
    reloadUser(true);
  };

  const saveCompanyCode = () => {
    if (!companyCode) {
      message.error(intl.get("common.inputField", {name: intl.get("company_setting.companyCode")}));
      return;
    }
    CompanyUserApi.setCompanyCode(companyUser.id, companyCode).then(res => {
      message.success(intl.get("common.message.saved"));
      reloadUser(false);
    }).catch(err => {
      console.log("saveCompanyCode:", err.response);
      if (err.response?.data && err.response.data.indexOf(companyCode) >= 0) {

        message.error(intl.get("company_setting.notFound", {companyCode: companyCode}));
      } else {
        message.error("저장 중 오류가 발생했습니다. 관리자에게 문의하세요.");
      }
    })
  }

  let userStatus = "";
  // 기본 정보 설정이 되었으면
  if (companyUser.companyId > 0 && defaultCompanyId !== -1) {
    // 현재 사용자의 상태가.
    // 1. 신청 후 회사 Code를 입력했지만 승인되지 않은 상태: wait_approve
    // 2. 신청 후 회사 Code또는 회사정보를 입력하지 않은 상태:
    // 3. 회사정보 입력 또는 승인이 된 상태
    if (companyUser.companyId !== defaultCompanyId) {
      if (companyUser.userType === 'draft') {
        //1. 신청 후 회사 Code를 입력했지만 승인되지 않은 상태
        userStatus = "wait_approve";
      } else {
        // 2. 회사정보 입력 또는 승인이 된 상태 -> 첫페이지 다시 리로딩
        window.location = adminConfig.homePage;
        window.location.reload();
        return (<></>);
      }
    }
  }

  if (!companyUser.id) {
    return (<></>)
  }

  if (userStatus === 'wait_approve') {
    return (
      <Alert
        message={intl.get("company_setting.not_approved")}
        description={(
          <div>{intl.getHTML("company_setting.not_approved.help")}</div>
        )}
        type="warning"
        showIcon
      />
    )
  }
  return (
    <div>
      <Space>
        <Button type="primary" danger style={{width: 200, height: 50, fontSize: 20}}
                onClick={() => setAction('companyCode')}>
          {intl.get("company_setting.input.companyCode")}
        </Button>
        <div style={{fontSize: 16}}> {intl.get("company_setting.or")} </div>
        <Button type="primary" style={{width: 200, height: 50, fontSize: 20}}
                onClick={() => setAction('register')}>
          {intl.get("company_setting.input.companyInfo")}
        </Button>
      </Space>
      <div style={{marginTop: 20}}>
        <Typography>
          <Title level={3}>{intl.get("company_setting.setting.guide")}</Title>
          <Paragraph>
            <blockquote>
              {intl.get("company_setting.setting.guide.help")}
            </blockquote>
          </Paragraph>
          <Paragraph>
            <ol>
              <li><Title level={5}>{intl.get("company_setting.setting1")}</Title>
                <ul>
                  <li>{intl.get("company_setting.setting1.help")}&nbsp;&nbsp;
                    {userStatus !== 'draft' && (<Button  onClick={() => setAction('register')}>{intl.get("company_setting.input.companyInfo")}</Button>)}
                  </li>
                </ul>
              </li>
              <li><Title level={5}>{intl.get("company_setting.setting2")}</Title>
                <ul>
                  <li>{intl.get("company_setting.setting2.help1")}&nbsp;&nbsp;
                    {userStatus !== 'draft' && (<Button  onClick={() => setAction('companyCode')}>{intl.get("company_setting.input.companyCode")}</Button>)}
                  </li>
                  <li>{intl.get("company_setting.setting2.help2")}</li>
                </ul>
              </li>
            </ol>
          </Paragraph>
        </Typography>
      </div>
      {userStatus !== 'draft' && (
        <div style={{marginTop: 20}}>
          {action === "register" && (
            <Typography>
              <Title level={3}>{intl.get("company_setting.input.companyInfo")}</Title>
              <CompanyForm company={{}} showListButton={false} setCompany={companyCreated}/>
            </Typography>
          )}
          {action === "companyCode" && (
            <Typography>
              <Title level={3}>{intl.get("company_setting.input.companyCode")}</Title>
              <Space>
                <Input style={{height: 50, fontSize: 18, width: 300 }} value={companyCode} onChange={(e) => setCompanyCode(e.target.value)}/>
                <Button type="primary" style={{width: 100, height: 50, fontSize: 20}} onClick={saveCompanyCode}>{intl.get("common.button.save")}</Button>
              </Space>
            </Typography>
          )}
        </div>
      )}
    </div>
  )
};

export default CompanyRegister