import React, { useEffect, useState } from 'react';

import {
  Table,
  Button,
  // Divider,
  message,
  Spin,
  Tag
} from 'antd';
import LogisticsCompanyApi from "./LogisticsCompanyApi";
import LogisticsCompanyEditPopup from "./LogisticsCompanyEditPopup";
import intl from "react-intl-universal";

const LogisticsCompanyList = (props) => {
  const [loading, setLoading] = useState(false);
  const [logistics, setLogistics] = useState([]);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [selectedLogistic, setSelectedLogistic] = useState({});

  useEffect(() => {
    loadLogistics()
  }, []);

  const loadLogistics = () => {
    LogisticsCompanyApi.getLogistics({}).then(res => {
      // console.log('getLogistics.data :>> ', res.data);
      setLogistics(res.data);
    });
  };

  const showEditModal = (id) => {
    const logisticsState = [...logistics];
    let selLogistic = {};
    if (id) {
      selLogistic = logisticsState.find(logistic => logistic.id === id);
    }
    setSelectedLogistic(selLogistic);
    setVisibleEdit(true);
  };

  const editModalOk = () => {
    setVisibleEdit(false);
    loadLogistics();
  };

  const deleteLogistic = (logistic) => {
    if (!window.confirm(intl.get("common.confirm.delete", { name: logistic.name }))) {
      return;
    }
    LogisticsCompanyApi.deleteLogistic(logistic.id).then(res => {
      message.success(intl.get("common.message.deleted"));
      loadLogistics()
    });
  };

  const logisticColumns = [
    {
      title: "ID",
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: intl.get("logistics.company.name"),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        return (
          <div>
            <span>{text}</span>
          </div>
        )
      }
    },
    {
      title: intl.get("logistics.company.weimob.code"),
      dataIndex: 'weimobCode',
      key: 'weimobCode',
    },
    {
      title: intl.get("logistics.company.active"),
      dataIndex: 'active',
      key: 'active',
      render: (text, record) => {
        const used = <Tag color='blue'>{intl.get("logistics.company.active.true")}</Tag>
        const notUse = <Tag color='red'>{intl.get("logistics.company.active.false")}</Tag>
        return (
          <div>{record.active ? used : notUse}</div>
        )
      }
    },
    {
      title: '',
      dataIndex: '',
      key: 'action',
      render: (text, record) => {
        return (
          <>
            <Button type="link" onClick={() => { showEditModal(record.id) }}>{intl.get("common.button.edit")}</Button>
            <Button type="link" onClick={() => { deleteLogistic(record); }}>{intl.get("common.button.delete")}</Button>
          </>
        )
      }
    }];

  return (
    <Spin spinning={loading}>
      <div className="page-op-box">
        <div className="filter-box">
          <div style={{ marginLeft: "auto" }}>
            <div style={{ width: 100 }}>
              <Button style={{ width: 100 }} type="primary" block size="default" onClick={loadLogistics}>{intl.get("common.button.search")}</Button>
            </div>
          </div>
        </div>
        <div className="extra-op-box">
          <Button type="default" size="small" onClick={() => showEditModal()}>{intl.get("common.button.add")}</Button>
        </div>
      </div>
      <div style={{ marginTop: "10px" }}>
        <Table size={'small'}
          rowKey={'id'}
          dataSource={logistics}
          columns={logisticColumns}
          pagination={false}
          style={{ width: 600 }}
        />
      </div>
      <LogisticsCompanyEditPopup
        open={visibleEdit}
        onOk={editModalOk}
        onCancel={() => setVisibleEdit(false)}
        logistic={selectedLogistic}
      />
    </Spin>
  )
}

export default LogisticsCompanyList;