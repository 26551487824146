import React, {useEffect, useState} from 'react';
import { Switch, Route } from 'react-router-dom';

import ApplicationPageApi from './ApplicationPageApi';
import ApplicationPageList from './ApplicationPageList';
import ApplicationPageEdit from "./ApplicationPageEdit";
import {useLayoutState} from "../../components/layout/AppLayoutContext";
import ProductDetailPageEdit from "./ProductDetailPageEdit";
import EtcPageEdit from "./EtcPageEdit";
import CategoryViewSetting from "./category/CategoryViewSetting";

const ApplicationPage = (props) => {
  const layoutState = useLayoutState();

  const [parentPath, setParentPath] = useState("");
  const [application, setApplication] = useState([]);
  const [appPages, setAppPages] = useState([]);
  const [filter, setFilter] = useState({
    pageType: "",
    componentType: false,
  });

  useEffect(() => {
    setParentPath(props.match.path);
  }, [props.match.path]);

  useEffect(() => {
    loadAppPages();
  }, []);

  useEffect(() => {
    setApplication(layoutState.application);
  }, [layoutState.application]);

  const loadAppPages = () => {
    const params = {...filter};
    ApplicationPageApi.getAppPages(params).then(res => {
      const pagingResult = res.data;
      setAppPages(pagingResult.data);
    });
  };

  const setFilterState = (filter) => {
    setFilter(filter)  ;
  };

  // NOTICE: path argument를 사용하기 위해서는 Route의 순서가 중요. new -> edit -> show 순서로 작성
  return (
      <div>
        <div className="page-content">
          <Switch>
            <Route path={props.match.path} exact
                   render={(props) => (
                       <ApplicationPageList
                           appPages={appPages}
                           application={application}
                           loadAppPages={loadAppPages}
                           parentPath={parentPath}
                           filter={filter}
                           setFilter={setFilterState}
                           {...props}/>) } />
            <Route path={`${props.match.path}/new`}
                   render={(props) => {
                     return (<ApplicationPageEdit
                         appPages={appPages}
                         application={application}
                         loadAppPages={loadAppPages}
                         parentPath={parentPath}
                         pageType={"sub"}
                         {...props}/>) }} />
            <Route path={`${props.match.path}/category-view-setting`}
                   render={(props) => {
                     return (<CategoryViewSetting
                       appPages={appPages}
                       application={application}
                       loadAppPages={loadAppPages}
                       parentPath={parentPath}
                       {...props}/>) }} />
            <Route path={`${props.match.path}/:pageId/edit`}
                   render={(props) => (
                       <ApplicationPageEdit
                           appPages={appPages}
                           application={application}
                           loadAppPages={loadAppPages}
                           parentPath={parentPath}
                           {...props}/>)} />
            />
            <Route path={`${props.match.path}/:pageId/product`}
                   render={(props) => (
                       <ProductDetailPageEdit
                           appPages={appPages}
                           application={application}
                           loadAppPages={loadAppPages}
                           parentPath={parentPath}
                           {...props}/>)} />
            />
            <Route path={`${props.match.path}/:pageId/etc`}
                   render={(props) => (
                     <EtcPageEdit
                       appPages={appPages}
                       application={application}
                       loadAppPages={loadAppPages}
                       parentPath={parentPath}
                       {...props}/>)} />
            />
          </Switch>
        </div>
      </div>
  )
};

export default ApplicationPage;