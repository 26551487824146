import React, {useState, useEffect} from "react";
import {Button, Descriptions, Divider, Layout, Modal, Table, Tabs, message} from "antd";
import Util from "../../../util/Util";
import PrettyData from "pretty-data";
import intl from "react-intl-universal";
import OrderApi from "../OrderApi";
import JSONPretty from "react-json-pretty";
import {SettingOutlined} from "@ant-design/icons";
import WechatPayView from "./WechatPayView";
import NicePayView from "./NicePayView";
import SmatroPayView from "./SmatroPayView";

const {Content, Sider} = Layout;
const { TabPane } = Tabs;

const PaymentModal = (props) => {
  const [currentPayment, setCurrentPayment] = useState({});
  const [paymentHistories, setPaymentHistories] = useState([]);

  useEffect(() => {
    loadPayment();
  }, [props.order.id]);

  const loadPayment = () => {
    OrderApi.getOrderPayment(props.order.id).then(res => {
      setCurrentPayment(res.data);
      loadPaymentHistories(res.data);
    });
  };

  const loadPaymentHistories = (orderPayment) => {
    const loadedHistories = [orderPayment];

    OrderApi.getOrderPaymentHistories(props.order.id).then(res => {
      loadedHistories.concat(res.data);
      setPaymentHistories(loadedHistories);
    });
  };

  const loadOrder = () => {
    const {order} = props;
    const orderId = order.id;

    OrderApi.getOrder(orderId).then(res => {
      props.setOrder(res.data);
    });
  };

  const getPayOriginData = () => {
    const {order} = props;
    const orderId = order.id;
    alert("아직 미구현 기능");
    // OrderApi.getPayOriginData(order.paymentCompany, order.id).then(res => {
    //   this.setState({
    //     order: {}
    //   }, () => {
    //     props.reloadOrder(orderId)
    //   });
    // });
  };

  const callTestPaymentCallback = () => {
    const {order} = props;
    if (paymentHistories.length === 0) {
      message.error("지불 처리 내역이 없습니다.");
      return;
    }
    const lastPaymentHistory = paymentHistories.find(ph => ph.id === order.lastPaymentId);
    if (!lastPaymentHistory) {
      message.error("주문의 마지막 지불 처리 내역을 찾을 수 없습니다.");
      return;
    }

    if (lastPaymentHistory.paymentStatus !== "get_prepay_id") {
      message.error("주문의 마지막 지불 처리 상태가 '지불준비완료' 상태가 아닙니다.");
      return;
    }

    if (order.currentStatus !== "pay_prepared") {
      message.error("주문 상테가 '지불확인중' 인 경우만 처리 가능합니다.");
      return;
    }
    if (!window.confirm("지불처리결과 회신 테스트를 실행합니다. 운영에서는 사용하지 마세요. 계속하시겠습니까?")) {
      return;
    }
    const orderId = order.id;

    OrderApi.callTestPaymentCallback(orderId).then(res => {
      message.info(intl.get("common.message.success"))
      loadPayment();
      loadOrder();
    }).catch(err => {
      console.log("callTestPaymentCallback error: ", err);
    });
  };

  const getStatusText = (statusCode) => {
    if (props.order.refund) {
      return intl.get("order.payment.status.refund");
    }
    return intl.get("order.payment.status." + statusCode);
  }

  const {order} = props;

  if (currentPayment.wxPrepayRes) {
    currentPayment.wxPrepayRes = currentPayment.wxPrepayRes.replace(/(?:\r\n\r\n)/g, '').replace(/(?:\n\n)/g, '');
  }
  if (currentPayment.pgPrepayRes) {
    currentPayment.pgPrepayRes = currentPayment.pgPrepayRes.replace(/(?:\r\n\r\n)/g, '').replace(/(?:\n\n)/g, '');
  }

  const columns = [{
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    }, {
      title: '일자',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text, record) => {
        return (<div>{Util.getChinaLocalTime(text)}</div>)
      }
    }, {
      title: '상태',
      dataIndex: 'paymentStatus',
      key: 'paymentStatus',
      render: (text, record) => {
        return (<span>{getStatusText(text)}</span>)
      }
  }];

  console.log(">>>>paymentHistories:", paymentHistories);
  return (
      <Modal  title={[<div key={1}><SettingOutlined/> 지불처리내역 ({order.orderNo})</div>]}
              visible={true}
              onCancel={props.onCancel} footer={null}
              width={"80%"} bodyStyle={{margin: 0, padding: 0}}>
        <Layout>
          <Sider width={400} className="modal-sider">
            <div style={{padding: 10, backgroundColor: "white", height: "100%"}}>
              <Table size={'small'}
                     dataSource={paymentHistories}
                     columns={columns}
                     rowKey={'id'}
                     onRow={(record, rowIndex) => {
                       return {
                         onClick: event => setCurrentPayment(record),
                       };
                     }}
              />
            </div>
          </Sider>
          <Layout>
            <Content className="modal-content">
              {currentPayment.id ? (
                <Descriptions  bordered
                               size={"small"}
                               column={1}
                               extra={(
                                 <>
                                   {Util.isAdmin() && process.env.REACT_APP_ENV !== "production" && (
                                     <>
                                       <Button size="small" type="primary" onClick={callTestPaymentCallback}>지불회신테스트</Button>
                                       <Divider type="vertical" />
                                     </>
                                   )}
                                    <Button size="small" type="primary">소비자지불금액</Button>
                                    <Divider type="vertical" />
                                    <Button size="small" type="primary">지불원시데이터</Button>
                                 </>
                               )}
                >
                  <Descriptions.Item label="지불 상태">{getStatusText(currentPayment.paymentStatus)}</Descriptions.Item>
                  {/*<Descriptions.Item label="지불 해관주문번호">{currentPayment.paymentOrderNo}</Descriptions.Item>*/}
                  <Descriptions.Item label="지불 준비 요청ID">{currentPayment.wxPrepayId}<br/>
                    ({currentPayment.wxPrepayAt.Valid ? Util.getChinaLocalTime(currentPayment.wxPrepayAt.Time) : ""})
                  </Descriptions.Item>
                  <Descriptions.Item label="지불 ID">
                    <div>
                      <div>위챗지불ID: {currentPayment.wxTransactionId ? currentPayment.wxTransactionId : "없음"}</div>
                      <div>{order.paymentCompanny}지불ID: {currentPayment.pgTransactionId ? currentPayment.pgTransactionId : "없음"}</div>
                      {
                        currentPayment.pgTransactionId && (
                            <div>
                              <div>지불완료일시: {currentPayment.payFinishedAt.Valid ? Util.getChinaLocalTime(currentPayment.payFinishedAt.Time) : ""}</div>
                              <div>위챗처리완료일시: {currentPayment.wxTimeEnd}</div>
                            </div>
                        )}
                    </div>
                  </Descriptions.Item>
                  <Descriptions.Item label="환불">
                    <div>{props.order.refund ? intl.get("order.status.refund") : "X"}</div>
                    {props.order.refund && (
                      <div>{intl.get("order.payment.refundAmount")}: {Util.toFixed2(currentPayment.refundAmount / 100)}</div>
                    )}
                  </Descriptions.Item>
                </Descriptions>
              ) : (<div>선택된 지불 내역이 없습니다.</div>)}
              <div style={{marginTop: 10}}>
                {order.paymentCompany === "wechatpay" && (
                    <WechatPayView payment={currentPayment}/>
                )}
                {order.paymentCompany === "nicepay" && (
                    <Tabs defaultActiveKey="1">
                      <TabPane tab="NICE" key="1">
                        <NicePayView payment={currentPayment}/>
                      </TabPane>
                      <TabPane tab="Wechat 원본" key="2">
                        <WechatPayView payment={currentPayment}/>
                      </TabPane>
                    </Tabs>
                )}
                {order.paymentCompany === "smatro" && (
                    <Tabs defaultActiveKey="1">
                      <TabPane tab="SMATRO" key="1">
                        <SmatroPayView payment={currentPayment}/>
                      </TabPane>
                      <TabPane tab="Wechat 원본" key="2">
                        <WechatPayView payment={currentPayment}/>
                      </TabPane>
                    </Tabs>
                )}

              </div>
            </Content>
          </Layout>
        </Layout>
      </Modal>
    )
}

export default PaymentModal;