import React, {useState, useEffect} from "react";
import {Button, Checkbox, Descriptions, Divider, Input, message, Select} from "antd";
import FileListPopup from "../file_manager/FileListPopup";
import AppPageSearchPopup from "./AppPageSearchPopup";
import ProductSearchPopup from "../product/ProductSearchPopup";
import intl from "react-intl-universal";

const { Search } = Input;

const MediaLinkSetting = (props) => {
  const [settingValues, setSettingValues] = useState({});
  const [subCategories, setSubCategories] = useState([]);
  const [editingSlot, setEditingSlot] = useState({});
  const [fileModalName, setFileModalName] = useState("");
  const [fileModalVisible, setFileModalVisible] = useState(false);
  const [productModalVisible, setProductModalVisible] = useState(false);
  const [appPageModalVisible, setAppPageModalVisible] = useState(false);

  useEffect(() => {
    setSettingValues({...props.settingValues});
  }, [props.settingValues]);

  useEffect(() => {
    if (!props.slot) {
      return;
    }
    let subCategories = [];
    if (props.slot.action && props.slot.action === "category") {
      const selectedCategory = props.categories.find(c => c.id === props.slot.categoryId);
      if (selectedCategory) {
        subCategories = selectedCategory.subCategories;
      }
    }
    setSubCategories(subCategories);
    setEditingSlot({...props.slot});
  }, [props.slot, props.categories]);

  const onSlotValueChanged = (fieldName, value) => {
    const editingSlotStat = {...editingSlot};
    editingSlotStat[fieldName] = value;
    setEditingSlot(editingSlotStat);
    if (props.slotChanged) {
      props.slotChanged(editingSlotStat);
    }
  };

  const showFileModal = () => {
    setFileModalVisible(true);
    setFileModalName("bannerFile");
  };

  const showBannerSelectItem = () => {
    if (editingSlot.action === "product") {
      setProductModalVisible(true);
    } else if (editingSlot.action === "contents") {
      setFileModalVisible(true);
      setFileModalName("paramFile");
    } else if (editingSlot.action === "page") {
      setAppPageModalVisible(true);
    }
  };

  const okProductSearch = (products) => {
    if (products.length > 1) {
      message.warn("상품이 1개 이상 선택되었습니다. 첫번째 상품을 사용합니다.");
    }
    const editingSlotState = {...editingSlot};
    editingSlotState.param = products[0].id;
    editingSlotState.paramTitle = products[0].Title;
    setProductModalVisible(false);
    setEditingSlot(editingSlotState);

    if (props.slotChanged) {
      props.slotChanged(editingSlotState);
    }
  };

  const okAppPageSearch = (appPage) => {
    const editingSlotState = {...editingSlot};
    editingSlotState.param = appPage.id;
    editingSlotState.paramTitle = appPage.appTitle;
    setAppPageModalVisible(false);
    setEditingSlot(editingSlotState);

    if (props.slotChanged) {
      props.slotChanged(editingSlotState);
    }
  };

  const okFileModal = (modalName, filePathList) => {
    const editingSlotState = {...editingSlot};

    if (filePathList.length > 0) {
      if (modalName === "bannerFile") {
        editingSlotState.bannerPath = filePathList[0];
      } else {
        editingSlotState.param = filePathList[0];
      }
    }
    setFileModalVisible(false);
    setEditingSlot(editingSlotState);

    if (props.slotChanged) {
      props.slotChanged(editingSlotState);
    }
  };

  const onActionSelect = (value) => {
    const editingSlotState = {...editingSlot};
    editingSlotState.action = value;
    editingSlotState.param = "";
    editingSlotState.categoryId = "";

    setEditingSlot(editingSlotState);
    if (props.slotChanged) {
      props.slotChanged(editingSlotState);
    }
  };


  const onActionOtherAppChanged = (event) => {
    const editingSlotState = {...editingSlot};
    editingSlotState.openApp = event.target.checked;
    editingSlotState.paramTitle = "";
    setEditingSlot(editingSlotState);
    if (props.slotChanged) {
      props.slotChanged(editingSlotState);
    }
  };


  const onCategorySelect = (value) => {
    const editingSlotState = {...editingSlot};
    const categoryId = value;
    let selectedCategory = props.categories.find(category => category.id === categoryId);

    let subCategories = [];
    if (selectedCategory) {
      subCategories = selectedCategory.subCategories;
    }
    editingSlotState.categoryId = categoryId;
    editingSlotState.param = categoryId;
    editingSlotState.subCategoryId = "";
    if (props.slotChanged) {
      props.slotChanged(editingSlotState);
    }

    setEditingSlot(editingSlotState);
    setSubCategories(subCategories);
  };

  const onSubCategorySelect = (value) => {
    onSlotValueChanged("param", value);
    const editingSlotState = {...editingSlot};
    editingSlotState.subCategoryId = value;
    editingSlotState.param = value;
    setEditingSlot(editingSlotState);
    if (props.slotChanged) {
      props.slotChanged(editingSlotState);
    }
  }

  let selectActionItemButtonLabel = "";
  if (editingSlot.action) {
    selectActionItemButtonLabel = intl.get("page_setting.edit.media.button." + editingSlot.action);
  }

  return (
      <div>
        <Descriptions bordered size={"small"} column={1}>
          {props.showTitle && (
            <Descriptions.Item label={intl.get("page_setting.edit.media.title")}>
              <Input value={editingSlot.title} onChange={(e) => onSlotValueChanged("title", e.target.value)}></Input>
            </Descriptions.Item>
          )}
          {!props.imageOnly && (
            <Descriptions.Item label={intl.get("page_setting.edit.banner.bannerMediaType")}>
              <Select style={{marginBottom:5, width: "100%"}} value={editingSlot.bannerMediaType}
                      onChange={(value) => onSlotValueChanged("bannerMediaType", value)}>
                <Select.Option key="image" value="image">{intl.get("page_setting.edit.media.image") }</Select.Option>
                <Select.Option key="video" value="video">{intl.get("page_setting.edit.media.video") }</Select.Option>
              </Select>
            </Descriptions.Item>
          )}
          <Descriptions.Item label={props.imageOnly ? intl.get("page_setting.edit.media.image") : intl.get("page_setting.edit.media.media")}>
            <Search
                allowClear
                enterButton={(<Button>{intl.get("page_setting.edit.media.selectFile")}</Button>)}
                value={editingSlot.bannerPath}
                onChange={(e) => onSlotValueChanged("bannerPath", e.target.value)}
                onSearch={showFileModal}
            />
            <span>
              {intl.getHTML("page_setting.edit.media.image.help")}
            </span>
          </Descriptions.Item>
          <Descriptions.Item label={intl.get("page_setting.edit.media.action")}>
            <Select value={editingSlot.action} style={{width: "100%"}} onChange={onActionSelect}>
              <Select.Option key="none" value="none">{intl.get("page_setting.edit.media.action.none")}</Select.Option>
              <Select.Option key="product" value="product">{intl.get("page_setting.edit.media.action.product")}</Select.Option>
              <Select.Option key="contents" value="contents">{intl.get("page_setting.edit.media.action.contents")}</Select.Option>
              <Select.Option key="page" value="page">{intl.get("page_setting.edit.media.action.page")}</Select.Option>
              <Select.Option key="brand" value="brand">{intl.get("page_setting.edit.media.action.brand")}</Select.Option>
              <Select.Option key="category" value="category">{intl.get("page_setting.edit.media.action.category")}</Select.Option>
              <Select.Option key="call_center" value="call_center">{intl.get("page_setting.edit.media.action.call_center")}</Select.Option>
              <Select.Option key="home" value="home">{intl.get("page_setting.edit.media.action.home")}</Select.Option>
              <Select.Option key="cart" value="cart">{intl.get("page_setting.edit.media.action.cart")}</Select.Option>
              <Select.Option key="singupCoupon" value="singupCoupon">{intl.get("page_setting.edit.media.action.singupCoupon")}</Select.Option>
            </Select>
          </Descriptions.Item>
          <Descriptions.Item label={intl.get("page_setting.edit.media.action.openApp")}>
            <Checkbox checked={editingSlot.openApp}
                      onChange={(e) => onSlotValueChanged("openApp", e.target.checked)}>
              {intl.get("page_setting.edit.media.openApp")}
            </Checkbox>
          </Descriptions.Item>
          <Descriptions.Item label={intl.get("page_setting.edit.media.action.param")}>
            {
              editingSlot.openApp && (
                  <Descriptions bordered size={"small"} column={1}>
                    <Descriptions.Item label={intl.get("page_setting.edit.media.appId")}>
                      <Input value={editingSlot.appId} onChange={(e) => onSlotValueChanged("appId", e.target.value)}/>
                    </Descriptions.Item>
                    <Descriptions.Item label={intl.get("page_setting.edit.media.app.param")}>
                      <Input value={editingSlot.param} onChange={(e) => onSlotValueChanged("params", e.target.value)}/>
                    </Descriptions.Item>
                  </Descriptions>
              )
            }
            {
              !editingSlot.openApp &&
              (editingSlot.action === 'product' || editingSlot.action === 'sample_campaign' || editingSlot.action === 'contents' || editingSlot.action === 'page') && (
                  <div>
                    <Search
                        allowClear
                        enterButton={(<Button>{selectActionItemButtonLabel}</Button>)}
                        value={editingSlot.param}
                        onChange={(e) => onSlotValueChanged("param", e.target.value)}
                        onSearch={showBannerSelectItem}
                    />
                    { editingSlot.action === "contents" && (
                        <div style={{marginTop: 10}}>
                          <span style={{marginRight: 10}}>{intl.get("page_setting.edit.media.media")}:</span>
                          <span>
                                <Select style={{marginBottom:5, width: "150px"}} value={editingSlot.mediaType}
                                        onChange={(value) => onSlotValueChanged("mediaType", value)}>
                                  <Select.Option key="image" value="image">{intl.get("page_setting.edit.media.image")}</Select.Option>
                                  <Select.Option key="video" value="video">{intl.get("page_setting.edit.media.video")}</Select.Option>
                                </Select>
                              </span>
                        </div>
                    )}
                    <div>
                      {intl.getHTML("page_setting.edit.media.action.help")}
                    </div>
                  </div>
              )
            }
            {
              !editingSlot.openApp && editingSlot.action === 'brand' && (
                <div>
                  <Select value={editingSlot.param} onSelect={(value) => onSlotValueChanged("param", value)} style={{width: 200}}>
                    {
                      props.brands.map(eachBrand => {
                        return (<Select.Option key={eachBrand.id} value={eachBrand.id}>{eachBrand.name}</Select.Option>)
                      })
                    }
                  </Select>
                </div>
              )
            }
            {
              !editingSlot.openApp && editingSlot.action === 'category' && (
                  <div>
                    <Select value={editingSlot.categoryId}
                            onSelect={onCategorySelect}
                            style={{width: "200px"}}>
                      {
                        props.categories.map(eachCategory => {
                          return (<Select.Option key={eachCategory.id} value={eachCategory.id}>{eachCategory.name}</Select.Option>)
                        })
                      }
                    </Select>
                    <Divider type="vertical" />
                    <Select value={editingSlot.subCategoryId}
                            onSelect={onSubCategorySelect}
                            style={{width: "200px"}}>
                      {
                        subCategories.map(eachCategory => {
                          return (<Select.Option key={eachCategory.id} value={eachCategory.id}>{eachCategory.name}</Select.Option>)
                        })
                      }
                    </Select>
                  </div>
              )
            }
          </Descriptions.Item>
        </Descriptions>
        {fileModalVisible && (
          <FileListPopup id={fileModalName}
                         fileKind={"index"}
                         visible={fileModalVisible}
                         multipleFile={false}
                         canSelect={true}
                         handleCancel={() => setFileModalVisible(false)}
                         handleOk={okFileModal}
          />
        )}
        {productModalVisible && (
          <ProductSearchPopup
              showSku={false}
              selectMultiple={false}
              handleCancel={() => setProductModalVisible(false)}
              handleOk={okProductSearch}
          />
        )}
        {appPageModalVisible && (
          <AppPageSearchPopup
              handleCancel={() => setAppPageModalVisible(false)}
              handleOk={okAppPageSearch}
          />
        )}
      </div>
  )
}

export default MediaLinkSetting;