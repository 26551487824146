import React, {useState, useEffect} from "react";
import {Button, Modal, message, Descriptions, Layout, Spin} from "antd";
import intl from "react-intl-universal";
import {SettingOutlined} from "@ant-design/icons";
import InterlogisProduct from "./InterlogisProduct";

const {Content} = Layout;

const InterlogisProductModal = (props) => {
  return (
    <Modal
      title={[<div key={1}><SettingOutlined/> 인터로지스 상품정보 가져오기</div>]}
      visible={true}
      onCancel={props.handleCancel}
      footer={[<Button key="submit" type="primary" onClick={props.handleCancel}>{intl.get("common.button.close")}</Button>,]}
      width={900}
      bodyStyle={{margin: 0, padding: 0}}>
      <Layout>
        <Content className="modal-content">
          <InterlogisProduct/>
        </Content>
      </Layout>
    </Modal>
  )
}

export default InterlogisProductModal;