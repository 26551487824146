import React, { useEffect, useState } from 'react';
import {Button, Radio, message, Table, Spin, Modal, Upload, Input, Checkbox, Descriptions} from "antd";
import { PlusOutlined } from '@ant-design/icons';
import intl from "react-intl-universal";
import LiveApi from "./LiveApi";
import { adminConfig } from "../../config/admin.config";
import ProductSearchPopup from "../product/ProductSearchPopup";
const API_BASE_URL = adminConfig.apiServer();

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt1M = file.size / 1024 / 1024 < 1;
  if (!isLt1M) {
    message.error('Image must smaller than 1MB!');
  }
  return isJpgOrPng && isLt1M;
}

const NotRegisteredProduct = (props) => {
  let currentPage = 1;
  const PAGE_SIZE = 20;

  const [loading, setLoading] = useState(false);
  const [auditStatus, setAuditStatus] = useState(0);
  const [goods, setGoods] = useState([]);

  const [visible, setVisible] = useState(false); //弹窗
  const [isAdd, setIsAdd] = useState(true); //弹窗

  const [mediaId, setMediaId] = useState('');
  const [fileList, setFileList] = useState([]);

  const [visibleSearchProduct, setVisibleSearchProduct] = useState(false);

  // 처음에는 로딩을 하지 않기 때문에 테이블을 나타내지 않는다.
  // 한번이라도 검색 버튼을 클릭하면 이 값은 true로 변경된다.
  const [loaded, setLoaded] = useState(false);

  const [form, setForm] = useState({
    id: '',
    name: '',
    price: '',
    path: '',
    // isSubmitAndAudit: false,
  })
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    // wechat api를 호출하기 때문에 페이지 로딩시 바로 조회하지 않고 사용자가 버튼 클릭 시 조회한다.
    // loadGoods();
  }, []);

  const loadGoods = () => {
    setLoading(true);
    // auditStatus-商品状态，0：未审核。1：审核中，2：审核通过，3：审核驳回
    LiveApi.getGoods({ page: currentPage, pageSize: PAGE_SIZE, auditStatus: auditStatus }).then(res => {
      const pagingResult = res.data;
      // console.log('pagingResult :>> ', pagingResult);
      setGoods(pagingResult.data);
      setLoading(false);
      setLoaded(true);
    }).catch(err => {
      setLoading(false);
    })
  };

  const handleSearchClick = () => {
    // console.log('handleSearchClick :>> ',);
    loadGoods()
  };
  const onChangeAuditStatus = (e) => {
    // console.log('radio checked', e.target.value);
    setAuditStatus(e.target.value);
  }
  const addGoods = () => {
    setForm({
      id: '',
      name: '',
      price: '',
      path: '',
    })
    setVisible(true)
    setFileList([])
    setIsAdd(true)
  }
  const editGoods = (record) => {
    const tempForm = {
      id: record.goods_id,
      name: record.name,
      price: record.price,
      path: record.url,
      coverImgUrl: record.cover_img_url
    }
    setForm(tempForm)
    setVisible(true)
    setIsAdd(false)
  }
  // const addImage = () => {
  //   console.log('addImage :>> ');
  // }
  const deleteGoods = (record) => {
    console.log('deleteGoods.goods_id :>> ', record.goods_id);
    LiveApi.deleteGoogs(record.goods_id).then(res => {
      message.success(intl.get('common.message.deleted'))
      loadGoods()
    })
  }
  const submitGoods = (record) => {
    console.log('submitGoods :>> ', record);
  }

  const columns = [{
    title: "ID",
    dataIndex: 'goods_id',
    key: 'goods_id',
  }, {
    title: intl.get("live.goodsImage"),
    dataIndex: 'cover_img_url',
    key: 'cover_img_url',
    render: (text, record) => {
      return <img src={record.cover_img_url} style={{ width: "80px", height: "80px" }} />
    }
  }, {
    title: intl.get("live.goodsName"),
    dataIndex: 'name',
    key: 'name',
  }, {
    title: intl.get("live.price"),
    dataIndex: 'price',
    key: 'price',
  }, {
    title: intl.get("live.link"),
    dataIndex: 'url',
    key: 'url',

  }, {
    title: intl.get("live.status"),
    dataIndex: 'audit_status',
    key: 'audit_status',
    render: (text, record) => {
      // 0：未审核，1：审核中，2:审核通过，3审核失败
      // console.log('record.audit_status :>> ', record);
      if (record.audit_status === 0) {
        return (<span>未审核</span>)
      } else if (record.audit_status === 1) {
        return (<span>审核中</span>)
      } else if (record.audit_status === 2) {
        return (<span>审核通过</span>)
      } else {
        return (<span>审核失败</span>)
      }
    }
  }, {
    title: '',
    dataIndex: '',
    key: 'action',
    render: (text, record) => {
      return (
        <div>
          {/*{record.audit_status == 0 && <Button size="small" onClick={() => { submitGoods(record) }}>{intl.get("live.button.submit")}</Button>}*/}
          {record.audit_status == 2 && <Button style={{ marginLeft: "4px" }} size="small" onClick={() => editGoods(record)}>{intl.get("common.button.edit")}</Button>}
          <Button style={{ marginLeft: "4px" }} size="small" onClick={() => deleteGoods(record)}>{intl.get("common.button.delete")}</Button>
        </div>
      )
    }
  }];

  const uploadConfig = {
    name: 'files',
    action: `${API_BASE_URL}/wx-media/upload-temp`,
    // headers: {
    //   authorization: 'authorization-text',
    // },
    // showUploadList: false,
    listType: "picture-card",
    // fileList: fileList,
    onChange(info) {
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`图片上传成功`);
        console.log('done.info :>> ', info);
        const mediaIdStr = info?.file?.response?.media_id || ''
        setMediaId(mediaIdStr)
        setFileList(info.fileList)
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onRemove(info) {
      setMediaId('')
      setFileList([])
      return true
    }
  };
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{intl.get("live.botton.addImage")}</div>
    </div>
  );

  const onChangeName = (e) => {
    const formState = {...form};
    formState.name = e.target.value
    setForm(formState)
  }
  const onChangePrice = (e) => {
    const formState = {...form};
    formState.price = e.target.value
    setForm(formState)
  }
  const onChangePath = (e) => {
    const formState = {...form};
    formState.path = e.target.value
    setForm(formState)
  }
  // const onChangeSubmitAndAudit = (e) => {
  //   const checked = e.target.checked
  //   // console.log('onChangeSubmitAndAudit.checked :>> ', checked);
  //   form.isSubmitAndAudit = checked
  //   setForm(form)
  // }

  const onProductSelected = (products) => {
    setVisibleSearchProduct(false);
    if (products.length == 0) {
      return;
    }

    const formState = {...form};
    formState.path = "pages2/product/detail?id=" + products[0].id;
    formState.price = products[0].productPrices.salesPrice.secondaryPrice;
    formState.name = products[0].name.length > 14 ? products[0].name.substring(0, 14) : products[0].name;
    setForm(formState)
  };

  const modalOk = () => {
    if (isAdd && !mediaId) {
      message.error(intl.get("common.selectField", { name: intl.get("live.modal.cover") }))
      return
    }
    if (!form.name) {
      message.error(intl.get("common.inputField", { name: intl.get("live.modal.name") }))
      return
    }
    if (!form.price) {
      message.error(intl.get("common.inputField", { name: intl.get("live.modal.price") }))
      return
    }
    if (!form.path) {
      message.error(intl.get("common.inputField", { name: intl.get("live.modal.path") }))
      return
    }
    // TODO:
    // 价格类型，1：一口价（只需要传入price，price2不传） 2：价格区间（price字段为左边界，price2字段为右边界，price和price2必传） 3：显示折扣价（price字段为原价，price2字段为现价， price和price2必传）
    let param = {}
    if (isAdd) {
      param = {
        coverImgUrl: mediaId,
        name: form.name,
        priceType: 1,
        price: parseFloat(form.price),
        url: form.path,
      }
    } else {
      // 调用此接口可以更新商品信息，审核通过的商品仅允许更新价格类型与价格，审核中的商品不允许更新，未审核的商品允许更新所有字段， 只传入需要更新的字段。
      param = {
        goodsId: form.id,
        // coverImgUrl: mediaId,
        // name: form.name,
        priceType: 1,
        price: parseFloat(form.price),
        // url: form.path,
      }
    }

    LiveApi.submitGoods(param).then(res => {
      // console.log('submitGoods.res :>> ', res);
      message.success(intl.get('common.message.saved'))
      setVisible(false);
      setConfirmLoading(false);
    })
  }

  if (!props.application?.useLive) {
    return (
      <>
        <div>
          {intl.get("live.cannotUseLive")}
        </div>
      </>
    )
  }
  return (
    <Spin spinning={loading}>
      <div className="page-op-box">
        <div className="filter-box">
          <div>
            <Radio.Group onChange={onChangeAuditStatus} value={auditStatus}>
              <Radio value={0}>{intl.get("live.status.0")}</Radio>
              <Radio value={1}>{intl.get("live.status.1")}</Radio>
              <Radio value={2}>{intl.get("live.status.2")}</Radio>
              <Radio value={3}>{intl.get("live.status.3")}</Radio>
            </Radio.Group>
          </div>
          <div style={{ marginLeft: "auto" }}>
            <div style={{ width: 100 }}>
              <Button style={{width: 100}} type="primary" block size="default" onClick={handleSearchClick}>{intl.get("common.button.search")}</Button>
            </div>
          </div>
        </div>
        <div className="extra-op-box">
          <Button type="default" size="small" onClick={addGoods}>{intl.get("live.botton.addGoods")}</Button>
        </div>
      </div>
      <div style={{ marginTop: "10px" }}>
        { loaded && (
          <Table size={'small'}
            rowKey={'goods_id'}
            dataSource={goods}
            columns={columns}
            pagination={true}
            onRow={(record, rowIndex) => {
              // return {
              //   onClick: event => history.push(`/faqs/${record.id}`),
              // };
            }}
          />
        )}
      </div>

      <Modal
        title={isAdd ? intl.get("live.botton.addGoods") : intl.get("live.botton.modifyGoods") }
        centered
        visible={visible}
        confirmLoading={confirmLoading}
        okText={intl.get("live.button.submit")}
        onOk={modalOk}
        onCancel={() => setVisible(false)}
        width={800}
      >
        <div >
          <div>
            <Descriptions bordered size={"small"} column={1} extra={(
              <Button type="primary" onClick={() => setVisibleSearchProduct(true)}>상품검색</Button>
            )}>
              <Descriptions.Item label={intl.get("live.modal.cover")}>
                <div style={{ color: '#999' }}>{intl.getHTML("live.modal.cover.help")}</div>
                {isAdd && <Upload {...uploadConfig}>
                  {/* <Button icon={<UploadOutlined />}>{intl.get("live.botton.addImage")}</Button> */}
                  {fileList.length >= 1 ? null : uploadButton}
                </Upload>}
                {!isAdd && <img style={{ width: '102px', height: '102px' }} src={form.coverImgUrl}></img>}
              </Descriptions.Item>
              <Descriptions.Item label={intl.get("live.modal.name")}>
                <Input style={{ width: '300px' }} maxLength="14" value={form.name} disabled={!isAdd} showCount onChange={onChangeName} />
              </Descriptions.Item>
              <Descriptions.Item label={intl.get("live.modal.price")}>
                <Input style={{ width: '200px' }} addonAfter="元" value={form.price} onChange={onChangePrice} />
              </Descriptions.Item>
              <Descriptions.Item label={intl.get("live.modal.path")}>
                <Input style={{ width: '500px' }} value={form.path} disabled={false} onChange={onChangePath} />
              </Descriptions.Item>
            </Descriptions>
          </div>
          <div style={{ marginTop: '10px', color: '#999' }}>
            {intl.get("live.button.submit.help")}
          </div>
        </div>
      </Modal>
      {visibleSearchProduct && (
        <ProductSearchPopup
          showSku={false}
          selectMultiple={false}
          handleOk={onProductSelected}
          handleCancel={() => setVisibleSearchProduct(false)}
        />
      )}
    </Spin>
  );
}

export default NotRegisteredProduct;