import Util from "../../../util/Util";
import {Tag} from "antd";
import React from "react";

const ProductDetailInfoView = (props) => {
  const {product} = props;

  const getAttrValue = (attr) => {
    if (attr.field === "brand") {
      if (product.name) {
        return product.name;
      }
      if (product.brand?.name) {
        return product.brand.name;
      }
      return "";
    } else if (attr.field === "optionText") {
      return product.skus.length > 0 ? product.skus.map(s => s.optionText).join(" ") : "";
    } else if (attr.field === "originCountry") {
      return product.originCountry;
    } else if (attr.field === "delivery") {
      if (!product.deliveryFee || product.deliveryFee === 0) {
        return "免费";
      } else {
        return Util.currencyFormat(product.deliveryFee, props.application.productCurrency);
      }
    } else if (attr.field === "text") {
      return attr.value;
    }
  };

  const {settingValues} = props.pageItem;
  return (
    <div>
      {settingValues.attrs?.map((attr, index) => (
        <div key={index}>
          {attr.field === "tag" && !product.tags && (<></>)}
          {attr.field === "tag" && product.tags && (
            <div className="u-m-t-16 bg-fff">
              <div className="b-b-line" style={{padding: "12px 16px"}}>
                {product.tags.map((tag, tIndex) => (
                  <Tag key={tIndex} color="purple">{tag.value}</Tag>
                ))}
              </div>
            </div>
          )}
          {attr.field !== "tag" && (
            <div className="u-m-t-16 bg-fff">
              <div className="b-b-line" style={{padding: "12px 16px"}}>
                <span className="selected-label">{attr.name}</span>
                <span className="u-m-l-40">{getAttrValue(attr)}</span>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  )
};

export default ProductDetailInfoView;