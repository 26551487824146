import React, {useEffect} from "react";
import {Input, Modal, Layout, Descriptions, Form, message, Space} from "antd";
import {SettingOutlined} from "@ant-design/icons";
import intl from "react-intl-universal";

const {Content} = Layout;

const ApplicationKeyEditModal = (props) => {
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue(props.applicationKey.keyData);
  }, [props.applicationKey]);

  const submitApplicationKey = (values) => {
    props.onOk(values);
  };

  return (
      <Modal
          title={[<div key={1}><SettingOutlined/> {intl.get("application.key.setting")}</div>]}
          visible={true}
          onCancel={props.onCancel}
          onOk={() => form.submit()}
          width={700}
          bodyStyle={{margin: 0, padding: 0}}
      >
        <Layout>
          <Content className="modal-content">
            <Form onFinish={submitApplicationKey} form={form}>
              <Descriptions bordered size={"small"} column={1}>
                <Descriptions.Item label={intl.get("application.wxAppSecret")}>
                  <Form.Item name={"wxAppSecret"}>
                    <Input/>
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label={intl.get("application.paymentCompany")}>
                  {intl.get("application.paymentCompany." + props.paymentCompany)}
                </Descriptions.Item>
                <Descriptions.Item label={intl.get("application.payId")}>
                  <Form.Item name={"payId"}>
                    <Input/>
                  </Form.Item>
                  <div>
                    {intl.get("application.payId.help")}
                  </div>
                </Descriptions.Item>
                <Descriptions.Item label={intl.get("application.paySecretKey")}>
                  <Form.Item name={"paySecretKey"}>
                    <Input/>
                  </Form.Item>
                </Descriptions.Item>
                {props.paymentCompany === "wechatpay" && (
                  <>
                  <Descriptions.Item label={intl.get("application.payV3SecretKey")}>
                    <Form.Item name={"payV3SecretKey"}>
                      <Input/>
                    </Form.Item>
                  </Descriptions.Item>
                  <Descriptions.Item label={intl.get("application.certificateSerialNumber")}>
                    <Form.Item name={"certificateSerialNumber"}>
                    <Input/>
                    </Form.Item>
                  </Descriptions.Item>
                  </>
                )}
                {props.paymentCompany === "smatro" && (
                    <Descriptions.Item label={"MCC CODE"}>
                      <Form.Item name={"wxPayGoodsId"}>
                        <Input/>
                      </Form.Item>
                    </Descriptions.Item>
                )}
              </Descriptions>
            </Form>
          </Content>
        </Layout>
      </Modal>
  )
}

export default ApplicationKeyEditModal;