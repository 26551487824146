import React, {useState, useEffect} from "react";
import ProductApi from "./ProductApi";
import {Button, Input, Modal, Table, message, Divider, Checkbox, Select, Layout, Spin} from "antd";
import intl from "react-intl-universal";
import CategoryApi from "../category/CategoryApi";
import BrandApi from "../brand/BrandApi";
import {SettingOutlined} from "@ant-design/icons";
import NoImage from "../../asset/images/no_image.png";
import Util from "../../util/Util";
import {useLayoutState} from "../../components/layout/AppLayoutContext";
import ReplyList from "../question/ReplyList";

const {Content} = Layout;

const ProductSearchPopup = (props) => {
  const layoutState = useLayoutState();

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const [filter, setFilter] = useState({
    nameType: "",
    name: '',
    idType: '',
    idTarget: '',
    published: true,
    brandId: '',
    categoryId: '',
    subCategoryId: '',
  });

  const [products, setProducts] = useState([]);
  const [application, setApplication] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [selectedSkuIds, setSelectedSkuIds] = useState([]);

  useEffect(() => {
    searchProducts(1);
    loadBrands();
    loadCategories();
    setLoading(false);
  }, []);

  useEffect(() => {
    setApplication(layoutState.application);
  }, [layoutState.application]);

  const searchProducts = (page) => {
    const params = getSearchParam(page);

    ProductApi.getProducts(params).then(res => {
      const pagingResult = res.data;
      const pagination = {
        current: pagingResult.page,
        pageSize: pagingResult.pageSize,
        total: pagingResult.count,
      }
      setProducts(pagingResult.data);
      setPagination(pagination);
      setSelectedProduct({});
      setLoading(false);
    });
  };

  const getSearchParam = (page) => {
    const params = {
      pageSize: pagination.pageSize,
      page: page,
      marketProductPublished: props.projection === "app",
      ...filter
    };
    return params;
  };

  const loadCategories = () => {
    CategoryApi.getCategoriesByApp({}).then(res => {
      setCategories(res.data);
    });
  };

  const loadBrands = () => {
    BrandApi.getBrands({}).then(res => {
      setBrands(res.data);
    });
  };

  const productTableChanged = (e) => {
    const paginationState = {...pagination};
    paginationState.pageSize = e.pageSize;
    setPagination(paginationState);
    searchProducts(e.current);
  };

  const handleOk = () => {
    if (props.showSku) {
      if (selectedSkuIds.length === 0) {
        message.error("제품 옵션을 선택하세요.");
        return;
      }

      const selectedProductState = {...selectedProduct};
      const selProducts = [selectedProductState];
      const selSkus = [];
      selectedSkuIds.forEach(sid => {
        const sku = selectedProductState.skus.find(s => s.id === sid);
        if (sku) {
          selSkus.push(sku);
        }
      });
      if (props.handleOk) {
        props.handleOk([...selProducts], [...selSkus]);
      }
    } else {
      if (selectedProductIds.length === 0) {
        message.error("제품을 선택하세요.");
        return;
      }
      if (!props.selectMultiple && selectedProductIds.length > 1) {
        message.error("제품 하나만 선택하세요.");
        return;
      }
      const productsState = [...products];
      const selProducts = [];

      selectedProductIds.forEach(id => {
        const product = productsState.find(p => p.id === id);
        if (product) {
          selProducts.push(product);
        }
      });
      if (props.handleOk) {
        props.handleOk([...selProducts]);
      }
    }
  }

  const selectProduct = (product) => {
    const productsState = [...products];
    const selectedProduct = productsState.find(p => p.id === product.id);
    setSelectedProduct(selectedProduct);
  };

  const onCategorySelect = (value) => {
    const categoriesState = [...categories];
    const filterState = {...filter};

    let selectedCategory = categoriesState.find(category => category.id === value);
    if (selectedCategory) {
      setSelectedCategory(selectedCategory);
      filterState.categoryId = value;
      setFilter(filter);
    }
  };

  const onFilterChanged = (field, value) => {
    const filterState = {...filter};
    filterState[field] = value;
    setFilter(filterState);
  };

  let columns = [{
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    }, {
      title: "",
      dataIndex: 'images',
      key: 'images',
      align: 'center',
      render: (text, record) => {
        const image = record.images.length > 0 ? record.images[0] + "?x-oss-process=image/resize,h_48,w_48" : NoImage;
        return  (<span><img src={image} style={{width: "48px", height: "48px"}}/></span>);
      }
    }, {
      title: intl.get("products.name"),
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
          <div>
            <div>{record.name}</div>
            { (record.name !== record.nameKr) && (<div style={{color: "gray", fontSize: 11}}>{record.nameKr}</div>) }
          </div>
      )
    }, {
      title: intl.get("products.salesPrice"),
      dataIndex: 'salesPrice',
      key: 'salesPrice',
      align: "right",
      render: (text, record) => (
          <span>{Util.currencyFormat(record.salesPrice, application.productCurrency)}</span>
      )
  }];

  if (!props.showSku) {
    columns = columns.concat([{
        title: intl.get("products.options"),
        key: 'skus',
        dataIndex: 'skus',
        align: 'left',
        render: (text, record) => {
          const barcodes = [];
          record.skus.forEach((sku, index) => {
            if (index >= 5) {
              return
            }
            let barcodeText = sku.optionText ? sku.optionText + ": " : "";
            barcodeText += sku.barCode;
            barcodes.push((<div key={sku.id}>{barcodeText}</div>));
          })
          if (record.skus.length >= 5) {
            barcodes.push((<div>외 {record.skus.length - 5}개 SKU</div>));
          }
          return (<div style={{fontSize: 11}}>{barcodes}</div>)
        }
      }, {
        title: intl.get("products.published"),
        dataIndex: 'published',
        key: 'published',
        align: 'center',
        render: (text, record) => {
          let published = text;
          if (record.marketProduct && (!record.marketProductPublished || record.marketProductDeletedAt.Valid)) {
            published = false;
          }
          return (<span>{intl.get("products.published." + published)}</span>)
        }
    }]);
  };

  const skuTableColumns = [{
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  }, {
    title: intl.get("products.images"),
    dataIndex: 'skuImage',
    key: 'skuImage',
    render: (text, record) => (
      <span><img src={text + "?x-oss-process=image/resize,h_48,w_48"} style={{width: "48px", height: "48px"}}/></span>
    )
  }, {
    title: intl.get("products.sku.optionText"),
    dataIndex: 'optionText',
    key: 'optionText',
  }, {
    title: intl.get("products.sku.barCode"),
    dataIndex: 'barCode',
    key: 'barCode',
  }, {
    title: intl.get("products.sku.salesPrice"),
    dataIndex: 'salesPrice',
    key: 'salesPrice',
    align: "right",
    render: (text, record) => (
      <span>{Util.currencyFormat(record.salesPrice, props.application.productCurrency)}</span>
    )
  }];

  const idTypeName = (idType) => {
    if (idType === "id") {
      return intl.get("products.id");
    } else if (idType === "barCode" || idType === "barcode") {
      return intl.get("products.sku.barCode");
    } else if (idType === "hsCode") {
      return intl.get("products.sku.hsCode");
    } else if (idType === "skuCode") {
      return intl.get("products.sku.skuCode");
    } else {
      return intl.get("products.id");
    }
  };

  const subCategories = selectedCategory.id ? selectedCategory.subCategories : [];

  const rowSelection = {
    type: props.selectionType || 'checkbox',
    selectedRowKeys: selectedProductIds,
    onChange: (selectedProductIds) => {
      setSelectedProductIds(selectedProductIds)
    },
  };

  const skuRowSelection = {
    selectedRowKeys: selectedSkuIds,
    onChange: (selectedSkuIds) => {setSelectedSkuIds(selectedSkuIds)},
  };

  return (
    <Modal
        title={[<div key={1}><SettingOutlined/> {intl.get("products.searchModal.title")}</div>]}
        open={true}
        onCancel={props.handleCancel}
        onOk={handleOk}
        // footer={[<Button key="submit" type="primary" onClick={handleOk}>{intl.get("common.button.ok")}</Button>,]}
        width={props.showSku ? 1200 : 1200}
        bodyStyle={{margin: 0, padding: 0}}>
      <Layout>
        <Content className="modal-content">
          <Spin spinning={loading}>
            <div className="page-op-box">
              <div className="filter-box">
              <div>
                <div>
                  <Select allowClear={true}
                          value={filter.nameType ? filter.nameType : undefined}
                          placeholder={intl.get("products.name.language")}
                          onClear={() => {onFilterChanged('nameType', ''); onFilterChanged('name', '')}}
                          onSelect={(value) => onFilterChanged('nameType', value)}
                          style={{width: 100}}>
                    <Select.Option value={"cn"}>{intl.get("products.name.cn")}</Select.Option>
                    <Select.Option value={"kr"}>{intl.get("products.name.ko")}</Select.Option>
                    <Select.Option value={"en"}>{intl.get("products.name.en")}</Select.Option>
                  </Select>
                  <Divider type="vertical" />
                  <Input style={{ width: 200 }} value={filter.name}
                         onChange={e => onFilterChanged('name', e.target.value)}
                         placeholder={intl.get("products.name")}
                         onPressEnter={() => searchProducts(1)}/>
                  <Divider type="vertical" />
                  <Select allowClear={true}
                          value={filter.idType ? filter.idType : undefined}
                          placeholder={intl.get("products.id.kind")}
                          onClear={() => {onFilterChanged('idType', ''); onFilterChanged('idTarget', '')}}
                          onSelect={(value) => onFilterChanged('idType', value)} style={{width: 100}}>
                    <Select.Option value={"id"}>{intl.get("products.id")}</Select.Option>
                    <Select.Option value={"barcode"}>{intl.get("products.sku.barCode")}</Select.Option>
                    <Select.Option value={"hsCode"}>{intl.get("products.sku.hsCode")}</Select.Option>
                    <Select.Option value={"skuCode"}>{intl.get("products.sku.skuCode")}</Select.Option>
                  </Select>
                  <Divider type="vertical" />
                  <Input style={{ width: 200 }} value={filter.idTarget} onChange={e => onFilterChanged('idTarget', e.target.value)} placeholder={idTypeName(filter.idType)} onPressEnter={() => searchProducts(1)}/>
                  <Divider type="vertical" />
                  <Checkbox checked={filter.published} style={{ marginRight: 10 }} onChange={e => onFilterChanged('published', e.target.checked)}>{intl.get("products.published")}</Checkbox>
                </div>
                <div style={{marginTop: 10}}>
                  <Select allowClear={true}
                          value={filter.brandId ? filter.brandId : undefined} onSelect={value => onFilterChanged('brandId', value)}
                          placeholder={intl.get("products.brand")}
                          onClear={() => onFilterChanged('brandId', '')}
                          style={{width: 150}}>
                    {
                      brands.map(eachBrand => {
                        return (<Select.Option key={eachBrand.id} value={eachBrand.id}>{eachBrand.name}</Select.Option>)
                      })
                    }
                  </Select>
                  <Divider type="vertical" />
                  <Select value={filter.categoryId ? filter.categoryId : undefined}
                          placeholder={intl.get("products.category") + "1"}
                          allowClear={true}
                          onClear={() => onFilterChanged('categoryId', '')}
                          onSelect={onCategorySelect}
                          style={{width: 150}}>
                    { categories.map(eachCategory => {
                        return (<Select.Option key={eachCategory.id} value={eachCategory.id}>{eachCategory.name}</Select.Option>)
                      })
                    }
                  </Select>
                  <Divider type="vertical" />
                  <Select value={filter.subCategoryId ? filter.subCategoryId : undefined}
                          placeholder={intl.get("products.category") + "2"}
                          allowClear={true}
                          onClear={() => onFilterChanged('subCategoryId', '')}
                          onSelect={(value) => onFilterChanged('subCategoryId', value)}
                          style={{width: 150}}>
                    { subCategories.map(eachCategory => {
                        return (<Select.Option key={eachCategory.id} value={eachCategory.id}>{eachCategory.name}</Select.Option>)
                      })
                    }
                  </Select>
                </div>
              </div>
              <div style={{marginLeft: "auto"}}>
                <div style={{width: 100}}>
                  <Button style={{width: 100}} type="primary" block size="default" onClick={() => searchProducts(1)}>{intl.get("common.button.search")}</Button>
                </div>
              </div>
            </div>
            </div>
            <div style={{marginTop: "10px", minHeight: 500}}>
              <Table size={'small'}
                     dataSource={products}
                     columns={columns}
                     rowKey={'id'}
                     title={() => intl.get("common.label.listTotal", {total: pagination.total})}
                     pagination={pagination}
                     onChange={productTableChanged}
                     rowSelection={rowSelection}
                     onRow={(record, rowIndex) => {
                       return {
                         onClick: event => {
                           selectProduct(record)
                         },
                       }
                     }}
                     // expandable={{
                     //   expandedRowRender: record => (<SkuList product={record} application={application}/>),
                     //   rowExpandable: record => true,
                     // }}
              />
              {/*{props.showSku && (*/}
              {/*  <div style={{flexGrow: 1}}>*/}
              {/*    <Table size={'small'}*/}
              {/*           dataSource={selectedProduct.skus}*/}
              {/*           columns={skuTableColumns}*/}
              {/*           rowKey={'id'}*/}
              {/*           pagination={false}*/}
              {/*           title={() => intl.get("common.label.listTotal", {total: (selectedProduct.skus ? selectedProduct.skus.length : 0)})}*/}
              {/*           rowSelection={skuRowSelection}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*)}*/}
            </div>
          </Spin>
        </Content>
      </Layout>
    </Modal>
  )
}

export default ProductSearchPopup;