import axios from "axios";
import { adminConfig } from "../../config/admin.config";

const API_BASE_URL = adminConfig.apiServer();

export default class ReplyApi {
  static getReplies = (params) => {
    let apiPath = `${API_BASE_URL}/replies?`;

    let paramKeyValues = []
    Object.keys(params).forEach(key => {
      if (params[key]) {
        const paramValue = params[key];
        if (Array.isArray(paramValue)) {
          paramKeyValues = paramKeyValues.concat(paramValue.map(v => key + "=" + v));
        } else {
          paramKeyValues.push(key + "=" + paramValue)
        }
      }
    });
    apiPath += paramKeyValues.join("&");
    if (!params.projection) {
      apiPath += "&projection=webAdmin";
    }
    return axios.get(apiPath);
  };

  static getReply = (id) => {
    let apiPath = `${API_BASE_URL}/replies/${id}`;
    return axios.get(apiPath);
  };

  static createReply = (reply) => {
    const apiPath = `${API_BASE_URL}/replies?projection=webAdmin`;
    return axios.post(apiPath, reply, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  };

  static updateReply = (replyId, reply) => {
    const apiPath = `${API_BASE_URL}/replies/${replyId}/image?projection=webAdmin`;
    return axios.put(apiPath, reply, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  };
  static saveReply = (reply) => {
    const apiPath = `${API_BASE_URL}/replies/${reply.id}?projection=webAdmin`;
    return axios.put(apiPath, reply);
  };

  static deleteReply = (replyId) => {
    const apiPath = `${API_BASE_URL}/replies/${replyId}?projection=webAdmin`;
    return axios.delete(apiPath);
  }
}