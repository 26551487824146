import React, {useEffect, useState} from "react";
import {Table, Descriptions} from "antd";
import Util from "../../../util/Util";
import intl from "react-intl-universal";
import PrettyData from "pretty-data";

const OrderDeclareDetail = (props) => {
  const orderDeclare = JSON.parse(JSON.stringify(props.orderDeclare));

  // TODO useEffect로 변경
  let shipQuantitySum = 0;
  let salesAmountSum = 0;
  let discountAmountSum = 0;
  let deliveryFeeSum = 0;
  let taxAmountSum = 0;

  if (orderDeclare.orderSkuDeclares) {
    orderDeclare.orderSkuDeclares.forEach(sku => {
      shipQuantitySum += sku.shipQuantity;
      salesAmountSum += sku.salesAmount;
      discountAmountSum += sku.discountAmount;
      deliveryFeeSum += sku.deliveryFee;
      taxAmountSum += sku.taxAmount;
    });
    orderDeclare.orderSkuDeclares.push({
      id: 99999,
      gnum: "Sum",
      salesPrice: 0,
      shipQuantity: shipQuantitySum,
      salesAmount: salesAmountSum,
      discountAmount: discountAmountSum,
      deliveryFee: deliveryFeeSum,
      taxAmount: taxAmountSum,
    });
  }

  const columns = [{
      title: 'No',
      dataIndex: 'gnum',
      key: 'gnum',
    }, {
      title: intl.get("order.sku.salesPrice"),
      dataIndex: 'salesPrice',
      key: 'salesPrice',
      align: "right",
      render: (text, record) => {
        if (record.gnum === "Sum") {
          return "";
        }
        return (<div>{Util.currencyFormat(text, "CNY")}</div>);
      }
    }, {
      title: intl.get("order.sku.shipQuantity"),
      dataIndex: 'shipQuantity',
      key: 'shipQuantity',
      align: "right",
    }, {
      title: intl.get("order.sku.salesAmount"),
      dataIndex: 'salesAmount',
      key: 'salesAmount',
      align: "right",
      render: (text, record) => {
        return (<div>{Util.currencyFormat(text, "CNY")}</div>);
      }
    }, {
      title: intl.get("order.discountAmount"),
      dataIndex: 'discountAmount',
      key: 'discountAmount',
      align: "right",
      render: (text, record) => {
        return (<div>{Util.currencyFormat(text, "CNY")}</div>);
      }
    }, {
      title: intl.get("order.deliveryFee"),
      dataIndex: 'deliveryFee',
      key: 'deliveryFee',
      align: "right",
      render: (text, record) => {
        return (<div>{Util.currencyFormat(text, "CNY")}</div>);
      }
    }, {
      title: intl.get("order.taxTotal"),
      dataIndex: 'taxAmount',
      key: 'taxAmount',
      align: "right",
      render: (text, record) => {
        return (<div>{Util.currencyFormat(text, "CNY")}({record.taxRate}%)</div>);
      }
  }];

  return (
    <div>
      <Descriptions bordered size={"small"} column={2}>
        <Descriptions.Item label={intl.get("order.declareOrder.declareAmount")}>
          <div style={{width: 300, fontSize: 11}}>
            <div style={{display: "flex", alignItems: "center", gap: 10}}>
              <div style={{width: 80, textAlign: "right"}}>{intl.get("order.declareOrder.salesAmount")}:</div>
              <div style={{width: 100, textAlign: "right"}}>{Util.currencyFormat(orderDeclare.salesAmount, "CNY")}</div>
            </div>
            <div style={{display: "flex", alignItems: "center", gap: 10}}>
              <div style={{width: 80, textAlign: "right"}}>{intl.get("order.discountAmount")}:</div>
              <div style={{width: 100, textAlign: "right"}}>{orderDeclare.discountAmount != 0 ? "-" : ""}{Util.currencyFormat(orderDeclare.discountAmount, "CNY")}</div>
            </div>
            <div style={{display: "flex", alignItems: "center", gap: 10}}>
              <div style={{width: 80, textAlign: "right"}}>{intl.get("order.deliveryFee")}:</div>
              <div style={{width: 100, textAlign: "right"}}>{Util.currencyFormat(orderDeclare.deliveryFee, "CNY")}</div>
            </div>
            <div style={{display: "flex", alignItems: "center", gap: 10}}>
              <div style={{width: 80, textAlign: "right"}}>{intl.get("order.taxTotal")}:</div>
              <div style={{width: 100, textAlign: "right"}}>{Util.currencyFormat(orderDeclare.taxTotal, "CNY")}</div>
            </div>
            <div style={{display: "flex", alignItems: "center", gap: 10}}>
              <div style={{width: 80, textAlign: "right", fontSize: 14, fontWeight: "bold"}}>{intl.get("order.paymentAmount")}:</div>
              <div style={{width: 100, textAlign: "right", fontSize: 14, fontWeight: "bold"}}>{Util.currencyFormat(orderDeclare.paymentAmount, "CNY")}</div>
            </div>
          </div>
        </Descriptions.Item>
        <Descriptions.Item label={intl.get("order.declareOrderNo")}>
          {orderDeclare.declareOrderNo}
        </Descriptions.Item>
        <Descriptions.Item label={intl.get("order.status")}>
          <span style={{color: "red"}}>{Util.getOrderDeclareStatus(orderDeclare.status)}</span>
          {orderDeclare.errorMessage && (<div>{orderDeclare.errorMessage}</div>)}
        </Descriptions.Item>
        <Descriptions.Item label={intl.get("order.declareOrder.sentAt")}>
          {orderDeclare.sentAt && orderDeclare.sentAt.Valid && (<div>{orderDeclare.sentAt.Time}</div>)}
        </Descriptions.Item>
        <Descriptions.Item label={intl.get("order.declareOrder.xml")} span={2}>
          {orderDeclare.declareXmlDoc && (
            <div style={{fontSize: 11, height: 200, overflowY: "auto"}}>
              <pre>{PrettyData.pd.xml(orderDeclare.declareXmlDoc)}</pre>
            </div>
          )}
        </Descriptions.Item>
        <Descriptions.Item label={intl.get("order.declareOrder.products")} span={2}>
          <Table size={'small'}
                 dataSource={orderDeclare.orderSkuDeclares}
                 columns={columns}
                 rowKey={'id'}
                 pagination={false}
          />
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default OrderDeclareDetail;