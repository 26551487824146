import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { BookFilled } from '@ant-design/icons';
import {
  Table,
  Divider,
  Button,
  Input,
  Select,
  Radio,
  message, DatePicker, Spin, Tag, Popconfirm,
  Modal,
  Checkbox,
  Tooltip
} from 'antd';
import intl from 'react-intl-universal';
import copyClipboard from 'copy-to-clipboard';
import Util from '../../util/Util';
import dayjs from "dayjs";
import OrderSkuShowModal from "./OrderSkuShowModal";
import PaymentModal from "./payment/PaymentModal";

import NoImg from "../../asset/images/no_image.png";
import {adminConfig} from "../../config/admin.config";
import axios from "axios";
import DeliveryUploadModal from "./DeliveryUploadModal";
import ClearancePopup from "./clearance/ClearancePopup";
import UserShowPopup from "../user/UserShowPopup";
import WeimobOrderFetchModal from "./WeimobOrderFetchModal";
import MarkerModal from "./MarkerModal";
import {SettingOutlined} from "@ant-design/icons";
import OrderApi from "./OrderApi";
import { saveAs } from 'file-saver';

const { RangePicker } = DatePicker;
const colorSet = ["#FF4849", "#FFB22C", "#00C989", "#00A4FC", "#6C21FB", ""];

const OrderList = (props) => {
  const [deliveryNofileList, setDeliveryNofileList] = useState([]);
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);
  const [workingSku, setWorkingSku] = useState({
    order: {}, orderSku: {}
  });
  const [visibleOrderSkuModal, setVisibleOrderSkuModal] = useState(false);
  const [visibleDeliveryModal, setVisibleDeliveryModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(0);
  const [visibleUserModal, setVisibleUserModal] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [visiblePayment, setVisiblePayment] = useState(false);
  const [visibleClearance, setVisibleClearance] = useState(false);
  const [visibleWeimobOrder, setVisibleWeimobOrder] = useState(false);
  const [hasWeimobApp, setHasWeimobApp] = useState(false);
  const [visibleDownloadDeclareModal, setVisibleDownloadDeclareModal] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [workingOrder, setWorkingOrder] = useState({});
  const [visibleMarkerModal, setVisibleMarkerModal] = useState(false);

  useEffect(() => {
    props.searchOrders(props.pagination);
  }, []);

  const onDateChange = (value, dateString) => {
    const {filter} = props;
    if (value[0] && value[1]) {
      filter.startDate = dateString[0];
      filter.endDate = dateString[1];
      props.setFilter(filter);
    }
  }

  const onDateOk = (value, dateString) => {
    if (value[1]) {
      const {filter} = props;
      filter.startDate =  value[0].format("YYYY-MM-DD HH:mm:ss");
      filter.endDate = value[1].format("YYYY-MM-DD HH:mm:ss");
      props.setFilter(filter);
    }
  }

  const uploadDeliveryNoFile = () => {
    const deliveryNofileListState = [...deliveryNofileList];
    const formData = new FormData();
    deliveryNofileListState.forEach(file => {
      formData.append('files', file);
    });

    setUploading(true);

    let apiPath = adminConfig.apiServer() + "/orders/upload-deliveryno";
    axios.post(apiPath, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      message.success(intl.get("common.message.saved"));
      setDeliveryNofileList([]);
      setUploading(false);
      setVisibleDeliveryModal(false);
    });
  };

  const orderTableChanged = (pagination) => {
    props.searchOrders(pagination);
  }

  const handleSearchClick = (e) => {
    const {pagination} = props;
    pagination.current = 1
    props.searchOrders(pagination);
  }

  const onDurationTypeChanged = (value) => {
    const filter = {...props.filter};
    filter.durationType = value;
    props.setFilter(filter);
  };

  const onFilterChanged = (fieldName, value) => {
    const filter = {...props.filter};
    filter[fieldName] = value;
    let search = false;

    if (fieldName === "managerMarkerColor" ||
      fieldName === "currentStatus" ||
      fieldName === "finishedStatus") {
      search = true;
    }
    props.setFilter(filter, search);
  }

  const showDeliveryStatus = (order) => {

  };

  const showMoreSku = (orderId) => {
    const orders = [...props.orders];
    const order = orders.find(o => o.id === orderId);
    if (!order) {
      return;
    }

    order.showMore = !order.showMore;
    props.setOrder(order);
  };

  const showPaymentRequest = (order) => {
    setWorkingSku({
      order: order,
      orderSku: {},
    });
    setVisiblePayment(true);
  };

  const showCustomClearance = (order) => {
    setWorkingSku({
      order: order,
      orderSku: {},
    });
    setVisibleClearance(true);
  };

  const showOrderSkuModal = (order, orderSku) => {
    setWorkingSku({
      order: order,
      orderSku: orderSku,
    });
    setVisibleOrderSkuModal(true);
  };

  const showUserInfo = (order) => {
    setSelectedUserId(order.userId);
    setVisibleUserModal(true);
  };

  const onClickSeller = (sellerUserId) => {
    setSelectedUserId(sellerUserId);
    setVisibleUserModal(true);
  };

  const downloadDeclareFile = () => {
    setVisibleDownloadDeclareModal(true);
  };

  const onCheckChange = (orderId, e) => {
    const checked = e.target.checked;
    let selectedIds = [...selectedOrderIds];

    if (checked) {
      selectedIds.push(orderId);
    } else {
      selectedIds = selectedIds.filter(id => id !== orderId);
    }
    setSelectedOrderIds(selectedIds)
    setIndeterminate(!!selectedIds.length && selectedIds.length < props.orders.length)
    setCheckAll(selectedIds.length === props.orders.length)
  };
  const onCheckAllChange = (e) => {
    setSelectedOrderIds(e.target.checked ? props.orders.map(o => o.id) : [])
    setIndeterminate(false)
    setCheckAll(e.target.checked)
  };
  const okMarkerModal = (orderId, managerMarker, managerMarkerColor) => {
    props.setOrder(workingOrder)
    workingOrder.managerMarker = managerMarker
    workingOrder.managerMarkerColor = managerMarkerColor
    setWorkingOrder(workingOrder)
    setVisibleMarkerModal(false)
  };
  const copyMarkerToClipboard = (marker) => {
    copyClipboard(marker, {
      onCopy: () => message.info(intl.get("common.message.copied"))
    });
  };
  const columns = [{
    title: () => {
      return (
          <div className="order-table-header">
            <div className="order-checkbox-header">
              <Checkbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll} 
                />
            </div>
            <div className="order-sku-header">{intl.get("order.list.productInfo")}</div>
            <div className="order-payment-header">{intl.get("order.list.paymentInfo")}</div>
            <div className="order-user-header">{intl.get("order.list.userInfo")}</div>
            <div className="order-type-header">{intl.get("order.deliveryInfo")}</div>
            <div className="order-status-header">{intl.get("order.status")}</div>
            <div className="order-button-header"></div>
          </div>
      );
    },
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    render: (text, order, index) => {
      const showMore = order.showMore;

      return (
          <div style={{marginTop: index === 0 ? 20 : 0}}>
            <div className="order-header">
              <div style={{ marginRight: 10 }}>
                <Checkbox checked={selectedOrderIds.find(id => order.id === id)} 
                  onChange={(e) => onCheckChange(order.id, e)} />
              </div>
              {order.managerMarkerColor && (
                <div style={{marginRight: 10}}>
                  <Tooltip title={order.managerMarker}>
                  <BookFilled onClick={() => copyMarkerToClipboard(order.managerMarker)}
                                style={{color: order.managerMarkerColor,
                                  fontSize: 16}}/>
                  </Tooltip>
                </div>
              )}
              <div style={{marginRight: 40}}>
                <div>
                  <Link to={`${props.parentPath}/${order.id}`}>{intl.get("order.orderNo")}: {order.orderNo}</Link>
                </div>
              </div>
              {order.externalOrderNo && (
                  <div style={{marginRight: 40}}>{intl.get("order.clientApp." + order.clientApp)}: {order.externalOrderNo}</div>)
              }
              {order.declareOrderNo && (
                  <div style={{marginRight: 40}}>{intl.get("order.declareOrderNo")}: {order.declareOrderNo}</div>
                )
              }
              <div>{intl.get("order.searchOrderDate")}: {Util.getChinaLocalTime(order.createdAt)}</div>
              <div style={{marginLeft: "auto", display: "flex", color: "#006aff"}}>
                <div style={{marginRight: 20}}>
                  <Link to={`${props.parentPath}/${order.id}`}>{intl.get("order.detail")}</Link>
                </div>
                <div style={{marginLeft: "auto", cursor: "pointer"}}
                      onClick={() => {
                        setWorkingOrder(order)
                        setVisibleMarkerModal(true)
                      }}>
                  {intl.get("order.managerMarker")}
                </div>
              </div>
            </div>
            <div className="order-body">
              <div className="order-sku order-cell">
                {order.orderSkus.map((sku, index) => {
                  if (!showMore && index > 2) {
                    if (index === 3) {
                      return (
                          <div className="more-text" key={sku.id}>
                            <a onClick={() => {showMoreSku(order.id)}}>
                              {intl.get("order.list.moreProduct", {count: order.orderSkus.length - 3})}
                            </a>
                          </div>)
                    } else {
                      return (<div key={sku.id}></div>)
                    }
                  }
                  const eventOriginProductName = sku.eventOriginProductName + " " ? sku.eventOriginProductName : "";

                  return (
                      <div className="order-sku-box" key={sku.id}>
                        <div className="order-sku-info">
                          <div className="order-sku-image">
                            <img src={sku.skuImage ? sku.skuImage + "?x-oss-process=image/resize,h_48,w_48" : NoImg}
                              style={{ width: "48px", height: "48px" }} />
                          </div>
                          <div style={{marginLeft: 8}}>
                            <div>
                              <a onClick={() => showOrderSkuModal(order, sku)}>
                                {sku.productName}
                              </a>
                            </div>
                            <div className="extra-info">
                              <div>{eventOriginProductName}{sku.optionText}</div>
                              {/* <div>{intl.get("order.sku.shipQuantity")}: {sku.salesQuantity > 1 ? sku.salesQuantity + "*" : ""} {sku.quantity}</div> */}
                              {/* <div>{intl.get("order.sku.offerListPrice")}: {Util.currencyFormat(sku.salesPricePay, order.paymentCurrency)}</div> */}
                              <div>{intl.get("order.sku.barcode")}: {sku.barCode}</div>
                              {sku.sellerUserId !== 0 && (
                                <div style={{ marginTop: 5, cursor: "pointer" }} onClick={() => onClickSeller(sku.sellerUserId)}>
                                  {intl.get("order.sellerUserId")}: {sku.selllerUserName}({sku.sellerUserId})<br />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="order-sku-price">
                          <div>
                            <div>{Util.currencyFormat(sku.salesPricePay, order.paymentCurrency)}</div>
                            <div>
                              {(sku.offerSkuQuantity !== 1 && sku.quantity > 1)
                                ? (<span>{sku.quantity}{intl.get("common.message.jian")} * {sku.offerSkuQuantity}</span>)
                                : (<span>{sku.shipQuantity}{intl.get("common.message.jian")}</span>)
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                  );
                })}
                {showMore && (
                    <div className="more-text">
                      <a onClick={() => {showMoreSku(order.id);}}>{intl.get("order.list.closeProduct")}</a>
                    </div>
                )}
              </div>
              <div className="order-payment order-cell">
                <div>
                  {order.paymentRequest && order.paymentRequest.status === "success" && (
                    <Tag color={"#108ee9"}>{intl.get("order.status.paid")}</Tag>
                  )}
                </div>
                <div>
                  <div className={"user-paid-amount"}>
                    <div>{Util.currencyFormat(order.paymentAmount, order.productCurrency)}</div>
                    { (order.productCurrency !== order.paymentCurrency && order.paymentAmountPay !== 0) && (
                      <div style={{color: "gray", fontSize: 11, marginLeft: 5}}>({Util.currencyFormat(order.paymentAmountPay, order.paymentCurrency)})</div>
                    )}
                  </div>
                  { (order.cbtType == "9610" || order.cbtType == "1210") && (
                    <div>
                      <div>{intl.get("order.taxTotal")}:
                      {order.orderDeclare ? Util.currencyFormat(order.orderDeclare.taxTotal, order.userCurrency) : ""}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className="order-user order-cell">
                <div>
                  {order.userId !== 0 && (<a onClick={() => showUserInfo(order)}>{order.userName}</a>)}
                  {order.userId === 0 && (<div>{order.userName}</div>)}
                </div>
              </div>
              <div className="order-recipient order-cell">
                <div>
                  <div>{order.shippingAddress.recipient}</div>
                  <div>{order.shippingAddress.phone}</div>
                  <Tooltip title={order.shippingAddress.addressProvince + order.shippingAddress.addressCity + order.shippingAddress.addressDistrict + order.shippingAddress.addressDetail}>
                    <div className={"address"}>{order.shippingAddress.addressProvince + order.shippingAddress.addressCity + order.shippingAddress.addressDistrict + order.shippingAddress.addressDetail}</div>
                  </Tooltip>
                  <div>{intl.get("order.cbtType." + order.cbtType)}</div>
                </div>
              </div>
              {/* <div className="order-type order-cell">
                <div>{intl.get("order.cbtType")}: {intl.get("order.cbtType." + order.cbtType)}</div>
                { (order.cbtType === "9610" || order.cbtType === "1210") && (
                  <div>{intl.get("order.declareOrderNo")}: {order.declareOrderNo}</div>
                )}
                { order.orderDelivery.deliveryNo && (
                    <div style={{cursor: "pointer", marginTop: 10}} onClick={() => showCustomClearance(order)}>
                      <div>{intl.get("order.deliveryCompany")}: {order.orderDelivery.deliveryCompany}</div>
                      <div>{intl.get("order.deliveryNo")}: {order.orderDelivery.deliveryNo}</div>
                    </div>
                )}
                { order.deliveredAt.Valid && (
                  <div>{intl.get("order.deliveredAt")}: {Util.getLocalTime(order.deliveredAt.Time).substring(0, 10)}</div>
                )}
                <div style={{marginTop: 10}}>
                  <Button type="default" size="small" onClick={() => { showCustomClearance(order); }}>{intl.get("order.button.deliveryAndClearance")}</Button>
                </div>
              </div> */}
              <div className="order-status order-cell">
                <div>{order.currentStatus === "finished" ? intl.get("order.status." + order.finishedStatus) : intl.get("order.status." + order.currentStatus)}</div>
                {order.finishedStatus === "failed" && (<div>{order.failedMessage}</div>)}
                {/*{order.settlementMonth && (<div style={{marginTop: 5}}>정산월: {order.settlementMonth}</div>)}*/}
              </div>
              <div className="order-button">
                <div className="button-box">
                  {(order.currentStatus === 'finished' && order.finishedStatus !== 'success' && order.finishedStatus !== "refund") && (
                    <div className="button-box">
                      <div className="button">
                        <Popconfirm
                          title={intl.get("common.confirm.delete", {name: intl.get("order.order")})}
                          onConfirm={(e) => props.deleteOrder(order.id)}
                        >
                          <div className="button">{intl.get("common.button.delete")}</div>
                        </Popconfirm>
                      </div>
                    </div>
                  )}
                  <div className="button" onClick={() => showCustomClearance(order)}>{intl.get("order.button.deliveryAndClearance")}</div>
                  { order.lastPaymentRequestID !== 0 && (
                    <div className="button" >
                      <div onClick={() => { showPaymentRequest(order); }}>{intl.get("order.paymentHistory")}</div>
                    </div>
                  )}
                  {Util.isAdmin() && (order.cbtType === "9610" || order.cbtType === "1210") && (
                   <div className="button">
                      <div>{intl.get("order.settlement.detail")}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
      );
    }
  }];

  const rowSelection = {
    selectedRowKeys: selectedOrderIds,
    onChange: (selectedOrderIds) => {
      setSelectedOrderIds(selectedOrderIds);
    },
  };

  const {filter, pagination, orders} = props;
  const dateRange = [dayjs(filter.startDate), dayjs(filter.endDate)];

  return (
    <div>
      <Spin spinning={props.loading}>
        <div className="page-op-box">
          <div className="filter-box">
            <div>
              <div>
                <Select value={filter.durationType} style={{ width: 150 }} onChange={onDurationTypeChanged}>
                  <Select.Option key={"order"} value={"order"}>{intl.get("order.searchOrderDate")}</Select.Option>
                  <Select.Option key={"pay"} value={"pay"}>{intl.get("order.searchPayDate")}</Select.Option>
                  <Select.Option key={"deliveredAt"} value={"finishedAt"}>{intl.get("order.deliveredAt")}</Select.Option>
                  <Select.Option key={"finishedAt"} value={"finishedAt"}>{intl.get("order.finishedAt")}</Select.Option>
                </Select>
                <Divider type="vertical" />
                <RangePicker
                    format="YYYY-MM-DD"
                    placeholder={[intl.get("order.searchOrderDateStart"), intl.get("order.searchOrderDateEnd")]}
                    onChange={onDateChange}
                    onOk={onDateOk}
                    value={dateRange}
                    allowClear={false}
                />
                <Divider type="vertical" />
                <Select value={filter.noType ? filter.noType : undefined} style={{ width: 170}}
                        allowClear={true}
                        onClear={() => onFilterChanged('noType', '')}
                        onChange={(value) => onFilterChanged("noType", value)}>
                  <Select.Option key={"orderNo"} value={"orderNo"}>{intl.get("order.orderNo")}</Select.Option>
                  <Select.Option key={"userId"} value={"userId"}>{intl.get("order.userId")}</Select.Option>
                  <Select.Option key={"loginId"} value={"loginId"}>{intl.get("user.loginId")}</Select.Option>
                  <Select.Option key={"userName"} value={"userName"}>{intl.get("order.userName")}</Select.Option>
                  <Select.Option key={"recipient"} value={"recipient"}>{intl.get("order.recipient")}</Select.Option>
                  <Select.Option key={"phone"} value={"phone"}>{intl.get("order.phone")}</Select.Option>
                  <Select.Option key={"productId"} value={"productId"}>{intl.get("products.id")}</Select.Option>
                  <Select.Option key={"productName"} value={"productName"}>{intl.get("order.sku.productName")}</Select.Option>
                  <Select.Option key={"barcode"} value={"barcode"}>{intl.get("order.sku.barcode")}</Select.Option>
                  <Select.Option key={"sellerUserId"} value={"sellerUserId"}>{intl.get("order.sellerUserId")}ID</Select.Option>
                  {props.weimobApp.id && (
                      <>
                        <Select.Option key={"externalOrderNo"} value={"externalOrderNo"}>{intl.get("order.externalOrderNo")}</Select.Option>
                        {/*<Select.Option key={"externalOrderNoRegexp"} value={"externalOrderNoRegexp"}>{intl.get("order.externalOrderNo") + "-REGEXP"}</Select.Option>*/}
                      </>
                  )}
                  <Select.Option key={"declareOrderNo"} value={"declareOrderNo"}>{intl.get("order.declareOrderNo")}</Select.Option>
                </Select>
                <Divider type="vertical" />
                <Input style={{ width: 250, marginRight: 10 }}
                       value={filter.targetNo}
                       onPressEnter={handleSearchClick}
                       onChange={(e) => onFilterChanged("targetNo", e.target.value)}/>
              </div>
              { /*  검색조건 2열  */ }
              <div style={{marginTop: 10}}>
                <Select style={{ width: 100}}
                  value={filter.managerMarkerColor ? filter.managerMarkerColor : undefined} 
                        placeholder={intl.get("order.managerMarker")}
                        allowClear={true}
                        onClear={() => onFilterChanged('managerMarkerColor', '')}
                        onSelect={(value) => onFilterChanged('managerMarkerColor', value)}>
                  {colorSet.map((color, index) => (
                    color && <Select.Option key={index} value={color}><BookFilled style={{ fontSize: 16, color: color }} /></Select.Option>
                  ))}
                </Select>
                <Divider type="vertical" />
                <Select value={filter.currentStatus ? filter.currentStatus : undefined}
                        allowClear={true}
                        placeholder={intl.get("order.status")}
                        onClear={() => onFilterChanged('currentStatus', '')}
                        onSelect={(value) => onFilterChanged('currentStatus', value)}
                        style={{width: 150}}>
                  {Util.ORDER_STATUSES.map(status =>
                    <Select.Option key={status.key} value={status.key}>
                      {intl.get("order.status." + status.key) ? intl.get("order.status." + status.key) : status.text}
                    </Select.Option>)
                  }
                </Select>
                <Divider type="vertical" />
                <Select value={filter.finishedStatus ? filter.finishedStatus : undefined}
                        allowClear={true}
                        placeholder={intl.get("order.finish.status")}
                        onClear={() => onFilterChanged('finishedStatus', '')}
                        onSelect={(value) => onFilterChanged('finishedStatus', value)}
                        style={{width: 150}}>
                  {Util.ORDER_FINISH_STATUS.map(status =>
                    <Select.Option key={status.key} value={status.key}>
                      {intl.get("order.status." + status.key) ? intl.get("order.status." + status.key) : status.text}
                    </Select.Option>)
                  }
                </Select>
              </div>
            </div>
            <div style={{marginLeft: "auto"}}>
              <div style={{width: 100}}>
                <Button style={{width: 100}} type="primary" block size="default" onClick={handleSearchClick}>{intl.get("common.button.search")}</Button>
              </div>
            </div>
          </div>
          <div className="extra-op-box">
            <Button type="default" size="small" onClick={() => setVisibleDeliveryModal(true)}>
              {intl.get("order.upload.deliveryNo")}</Button>
            <Divider type="vertical" />
            <Button type="default" size="small" onClick={props.downloadExcel}>{intl.get("common.button.download")}</Button>
            {props.weimobApp.id && (
              <>
                <Divider type="vertical" />
                <Button type="default" size="small" onClick={() => setVisibleWeimobOrder(true)}>{intl.get("order.weimob.order")}</Button>
              </>
            )}
            {!props.application.needSendToLogistics && (
              <>
                <Divider type="vertical" />
                <Button type="default" size="small" onClick={downloadDeclareFile}>주문신고엑셀</Button>
              </>
            )}
          </div>
        </div>

        <div className="order-table" style={{marginTop: "10px"}}>
          <Table size={'small'}
                dataSource={orders}
                columns={columns}
                rowKey={'id'}
                title={() => intl.get("common.label.listTotal", {total: pagination.total})}
                pagination={pagination}
                onChange={orderTableChanged}
                // rowSelection={rowSelection}
          />
        </div>
      </Spin>
      { visibleOrderSkuModal && (
        <OrderSkuShowModal
            onOk={() => setVisibleOrderSkuModal(false)}
            onCancel={() => setVisibleOrderSkuModal(false)}
            order={workingSku.order}
            orderSku={workingSku.orderSku}
        />
      )}
      {visibleDeliveryModal && (
          <DeliveryUploadModal order={workingSku.order}
                               onOk={() => {setVisibleDeliveryModal(false); props.searchOrders(props.pagination);}}
                               onCancel={() => setVisibleDeliveryModal(false)}
          />
      )}
      {visiblePayment && (
        <PaymentModal
            order={workingSku.order}
            setOrder={props.setOrder}
            onOk={() => setVisiblePayment(false)}
            onCancel={() => setVisiblePayment(false)}
        />
      )}
      {visibleClearance && (
        <ClearancePopup orderId={workingSku.order.id}
                        application={props.application}
                        setOrder={props.setOrder}
                        handleOk={() => setVisibleClearance(false)}
                        handleCancel={() => setVisibleClearance(false)}
        />
      )}
      {visibleUserModal && (
        <UserShowPopup userId={selectedUserId}
                        handleCancel={() => setVisibleUserModal(false)}
        />
      )}
      {visibleWeimobOrder && (
        <WeimobOrderFetchModal handleOk={() => setVisibleWeimobOrder(false)}
                               handleCancel={() => setVisibleWeimobOrder(false)}/>
      )}
      {visibleDownloadDeclareModal && (
        <DownloadDeclareExcelModal
          selectedOrderIds={selectedOrderIds}
          filter={props.filter}
          handleClose={() => setVisibleDownloadDeclareModal(false)}/>
      )}
      {visibleMarkerModal && (
        <MarkerModal
          handleOk={okMarkerModal}
          handleCancel={() => setVisibleMarkerModal(false)}
          order={workingOrder}
          colorSet={colorSet}
        />
      )}
    </div>
  );
};

const DownloadDeclareExcelModal = (props) => {
  const [option, setOption] = useState("checked");
  const [loading, setLoading] = useState(false);

  const handleOk = () => {
    let params = props.filter;
    if (option === "checked") {
      if (!props.selectedOrderIds || props.selectedOrderIds.length === 0) {
        message.error("다운로드할 주문을 선택하세요.");
        return;
      }
      params = {orderIds: props.selectedOrderIds}
    }

    setLoading(true);
    OrderApi.downloadDownDeclare(params).then(res => {
      setLoading(false);
      const header = res.headers["content-disposition"];
      const filename = header.match(/filename="(.+)"/)[1];
      const contentType = res.headers["content-type"];

      const blob = new Blob([res.data], { contentType });
      saveAs(blob, filename);
      props.handleClose();
    }).catch(err => {
      setLoading(false);
      console.log("download error:", err);
      message.error("다운로드 중 문제가 발생했습나다. 관리자에게 문의하새요.");
    });
  };

  return (
    <Modal title={[<div key={1}><SettingOutlined/> 다운로드</div>]}
           visible={true}
           onOk={handleOk}
           onCancel={() => props.handleClose()}
           // okText="다운로드"
           width="400px"
           footer={[
             <Button key="back" loading={loading} onClick={props.handleClose}>취소</Button>,
             <Button key="submit" type="primary" loading={loading} onClick={handleOk}>다운로드</Button>
           ]}

    >
      <div>
        <Radio.Group onChange={(e) => setOption(e.target.value)} value={option}>
          <Radio value={"checked"}>선택한것만</Radio>
          <Radio value={"all"}>검색결과전체</Radio>
        </Radio.Group>
      </div>
    </Modal>
  )
}

export default OrderList;