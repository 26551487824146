import React, {useEffect, useState} from 'react';

import {
  Table,
  Button,
  Input,
  Divider,
  message,
  Modal,
  Spin
} from 'antd';
import BrandApi from "./BrandApi";
import BrandEditPopup from "./BrandEditPopup";
import intl from "react-intl-universal";

const { TextArea } = Input;

const BrandList = (props) => {
  const [uploading, setUploading] = useState(false);
  const [brands, setBrands] = useState([]);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState({});
  const [brandMultiInputVisible, setBrandMultiInputVisible] = useState(false);
  const [brandsInputText, setBrandsInputText] = useState("");

  useEffect(() => {
    loadBrands()
  }, []);

  const loadBrands = () => {
    BrandApi.getBrands({}).then(res => {
      setBrands(res.data);
    });
  };

  const showEditModal = (id) => {
    const brandsState = [...brands];
    let selBrand = {};
    if (id) {
      selBrand = brandsState.find(brand => brand.id === id);
    }
    setSelectedBrand(selBrand);
    setVisibleEdit(true);
  };

  const editModalOk = () => {
    setVisibleEdit(false);
    loadBrands();
  };

  const deleteBrand = (brand) => {
    if (!window.confirm(intl.get("common.confirm.delete", {name: brand.name}))) {
      return;
    }
    BrandApi.deleteBrand(brand.id).then(res => {
      message.success(intl.get("common.message.deleted"));
      loadBrands()
    });
  };

  const onMultiInputOk = () => {
    const addedBrands = [];
    const brandNames = new Map();
    brandsInputText.split("\n").forEach(line => {
      if (line.trim().length === 0) {
        return;
      }
      const lineTokens = line.split(",");
      const brand = {
        name: lineTokens[0].trim()
      };

      if (brandNames.has(brand.name)) {
        return;
      }
      if (lineTokens.length > 1) {
        brand.imageUrl = lineTokens[1].trim();
      }

      brandNames.set(brand.name, true);
      addedBrands.push(brand);
    });
    if (addedBrands.length === 0) {
      message.error("브랜드를 입력하세요.");
      return;
    }
    setUploading(true);

    BrandApi.submitBrand(addedBrands).then(res => {
      message.success(intl.get("common.message.saved"));
      loadBrands();
    }).finally(() => {
        setUploading(false);
        setBrandMultiInputVisible(false);
    });
  }

  const brandColumns = [{
    title: "ID",
    dataIndex: 'id',
    key: 'id',
  }, {
    title: intl.get("brand.name"),
    dataIndex: 'name',
    key: 'name',
    render: (text, record) => {
      return (
          <div>
            { record.imageUrl && (<img src={record.imageUrl} style={{width: 48, height: 48, marginRight: 10}}/>)}
            <span>{text}</span>
          </div>
      )
    }
  }, {
    title: intl.get("brand.active"),
    dataIndex: 'active',
    key: 'active',
    render: (text, record) => {
      return (
        <div>{record.active ? "Y" : "N"}</div>
      )
    }
  }, {
    title: '',
    dataIndex: '',
    key: 'action',
    render: (text, record) => {
      return(
          <>
            <Button onClick={() => {showEditModal(record.id)}}>{intl.get("common.button.edit")}</Button>
            <Divider type="vertical" />
            <Button onClick={() => {deleteBrand(record);}}>{intl.get("common.button.delete")}</Button>
          </>
      )
    }
  }];

  return (
      <div>
        <div className="page-op-box">
          <div className="filter-box">
            <div style={{marginLeft: "auto"}}>
              <div style={{width: 100}}>
                <Button style={{width: 100}} type="primary" block size="default" onClick={loadBrands}>{intl.get("common.button.search")}</Button>
              </div>
            </div>
          </div>
          <div className="extra-op-box">
            <Button type="default" size="small" onClick={() => showEditModal()}>{intl.get("common.button.add")}</Button>
            <Divider type="vertical"/>
            <Button type="default" size="small" onClick={() => {setBrandMultiInputVisible(true); setBrandsInputText("");}}>{intl.get("common.button.addBulk")}</Button>
          </div>
        </div>
        <div style={{marginTop: "10px"}}>
          <Table size={'small'}
                 rowKey={'id'}
                 dataSource={brands}
                 columns={brandColumns}
                 pagination={false}
                 style={{width: 600}}
          />
        </div>
          <BrandEditPopup
              visible={visibleEdit}
              onOk={editModalOk}
              onCancel={() => setVisibleEdit(false)}
              brand={selectedBrand}
          />
        {brandMultiInputVisible && (
          <Modal  visible={brandMultiInputVisible}
                  title={"브랜드 추가"}
                  onCancel={() => setBrandMultiInputVisible(false)}
                  onOk={onMultiInputOk}
                  width={"700px"}>
            <Spin spinning={uploading}>
              <div style={{display: "flex", padding: 5}}>
                <div style={{width: "40%", color: "gray"}}>
                  이미지가 없으면 다음 형태로 입력<br/>
                  브랜드명1<br/>
                  브랜드명2<br/>
                  브랜드명3
                </div>
                <div style={{width: "60%", color: "gray"}}>
                  이미지가 있으면 없으면 다음형태로 입력<br/>
                  브랜드명1,http://topsdfs.com/brands/brand1.jpg<br/>
                  브랜드명2,http://topsdfs.com/brands/brand1.jpg<br/>
                  브랜드명3,http://topsdfs.com/brands/brand1.jpg
                </div>
              </div>
              <div>
                <TextArea rows={20} value={brandsInputText} onChange={(e) => setBrandsInputText(e.target.value)}/>
              </div>
            </Spin>
          </Modal>
        )}
      </div>
  )
}

export default BrandList;