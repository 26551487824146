import React, {useState, useEffect} from "react";
import ComponentPreview from "./ComponentPreview";
import intl from "react-intl-universal";

import "../index_preivew/Preview.less";
import {DeleteFilled} from "@ant-design/icons";

const EditPagePreview = (props) => {
  const [selectedId, setSelectedId] = useState(0);
  const {pageItems} = props;

  useEffect(() => {
    if (props.selectedPageItemId) {
      setSelectedId(props.selectedPageItemId);
    }
  }, [props.selectedPageItemId]);

  const onItemSelected = (id) => {
    setSelectedId(id);
    props.onPageItemSelected(id);
  };

  const getPageItemView = (item, index) => {
    const selected = selectedId === 0 ? index === 0 : item.id === selectedId;
    return (
      <div key={item.id}>
        <div style={{position: "relative"}}>
          {selected && (
            <div className="floating-button">
              <DeleteFilled onClick={() => props.removePageItem(item.id)}/>
            </div>
          )}
          <div
            className={"preview-slot" + (selected ? " selected" : "")}
            style={{width: selected ? 384 : 375}}
            onClick={() => onItemSelected(item.id)}
          >
            {item.componentId ? (
                <ComponentPreview
                  pageItems={item.settingValues}
                  application={props.application}
                  applicationDesign={props.applicationDesign}
                />
            ) : (
              <div style={{height: 200, display: "flex", alignItems: "center", justifyContent: "center"}}>
                <div style={{textAlign: "center"}}>표시할 컨포넌트를 선택하세요.</div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  };

  return (
    <div>
      <div className="preview-body-wrapper">
        <div className={"preview-body"}>
          <div style={{height: 300, border: "1px solid black", display: "flex", alignItems: "center", justifyContent: "center"}}>
            <div style={{textAlign: "center"}}>{props.title} 내용</div>
          </div>
          {pageItems.map((item, index) => getPageItemView(item, index))}
        </div>
      </div>
    </div>
  )
}

export default EditPagePreview;