import React, {useState, useEffect} from "react";

const NicePayView = (props) => {
  const {payment} = props;

  return (
    <div>
      <h3>지불준비요청</h3>
      <div style={{fontSize: "11px", overflow: "scroll", wordBreak: "break-all", wordWrap: "break-word", height: "150px", width: "500px"}}>
        <div>
          {decodeURIComponent(payment.pgPrepayReq).split("&").map((keyVal, kvIndex) => (<div key={kvIndex}>{keyVal}</div>))}
        </div>
      </div>
      <h3>지불준비요청 회신</h3>
      <div style={{fontSize: "11px", overflow: "scroll", wordBreak: "break-all", wordWrap: "break-word", width: "400px", height: "150px", marginRight: 20}}>
        <pre>{payment.pgPrepayRes}</pre>
      </div>
      <h3>지불처리완료 회신</h3>
      <div style={{fontSize: "11px", overflow: "scroll", wordBreak: "break-all", wordWrap: "break-word", width: "400px", height: "150px", marginRight: 20}}>
        <div>
          {decodeURIComponent(payment.pgCallbackRes).split("&").map((keyVal, kvIndex) => (<div key={kvIndex}>{keyVal}</div>))}
        </div>
      </div>
    </div>
  )
}

export default NicePayView;