import React, {useEffect, useState} from 'react';

import Util from "../../util/Util";
import intl from "react-intl-universal";
import {Button, Divider, Spin, Table} from "antd";

import dayjs from "dayjs";
import CouponEventApi from "./CouponEventApi";
import {useLayoutState} from "../../components/layout/AppLayoutContext";

const initialtPagination = {
  current: 1,
  pageSize: 20,
  total: 0,
}

const initialFilter = {
  active: "",
  type: "",
  name: "",
  startDate: dayjs().subtract(30, "day").format("YYYY-MM-DD"),
  endDate: dayjs().format("YYYY-MM-DD"),
};

const UserCouponList = (props) =>  {
  const layoutState = useLayoutState();
  const [application, setApplication] = useState([]);

  const [pagination, setPagination] = useState(initialtPagination);
  const [filter, setFilter] = useState(initialFilter);
  const [loading, setLoading] = useState(false);
  const [userCoupons, setUserCoupons] = useState([]);
  // const [brands, setBrands] = useState([]);

  useEffect(() => {
    searchUserCoupons(pagination);
  }, []);

  useEffect(() => {
    setApplication(layoutState.application);
  }, [layoutState.application]);

  const searchUserCoupons = (pagination) => {
    setLoading(true);
    const params = getSearchParam(pagination);

    CouponEventApi.getUserCoupons(params).then(res => {
      const pagingResult = res.data;
      const pagination = {
        current: pagingResult.page,
        pageSize: pagingResult.pageSize,
        total: pagingResult.count,
      }
      setUserCoupons(pagingResult.data);
      setPagination(pagination);
    }).finally(() => {
      setLoading(false);
    });
  };

  const handleSearchClick = () => {
    const paginationState = {...pagination};
    paginationState.current = 1;
    setPagination(paginationState);
    searchUserCoupons(paginationState);
  };

  const getSearchParam = (pagination) => {
    const params = {
      pageSize: pagination.pageSize,
      page: pagination.current,
      ...filter
    };
    return params;
  };

  const setFilterState = (filter) => {
    setFilter(JSON.parse(JSON.stringify(filter)));
  };

  const setPaginationState = (pagination) => {
    setPagination(JSON.parse(JSON.stringify(pagination)));
  };

  const columns = [{
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
  }, {
    title: intl.get("coupon.name"),
    dataIndex: 'name',
    key: 'name',
    render: (text, record) => (
      <span>{record.couponEvent.name}</span>
    )
  }, {
    title: intl.get("coupon.type"),
    dataIndex: 'couponEvent',
    key: 'couponEvent',
    render: (text, record) => (
      <span>{intl.get("coupon.type." + record.couponEvent.type)}</span>    )
  }, {
    title: intl.get("coupon.active"),
    dataIndex: 'couponEvent',
    key: 'active',
    align: 'left',
    render: (text, record) => (
      <span>{intl.get("coupon.active." + record.couponEvent.active)}</span>
    )
  }, {
    title: intl.get("coupon.amountAndRate"),
    dataIndex: 'discountAmount',
    key: 'discountAmount',
    align: 'right',
    render: (text, record) => {
      if (record.couponEvent.discountType === "price") {
        return (
          <div>
            <div>{Util.currencyFormat(record.couponEvent.discountAmountPrice.originPrice, application.productCurrency)}</div>
            <div style={{color: "gray", fontSize: 13}}>{Util.currencyFormat(record.couponEvent.discountAmountPrice.secondaryPrice, application.userPayCurrency)}</div>
          </div>
        )
      } else {
        return (<span>{record.couponEvent.discountAmount + "%"}</span>)
      }
    }
  }, {
    title: intl.get("coupon.canRedeem"),
    dataIndex: 'canRedeem',
    key: 'canRedeem',
    align: 'center',
    render: (text, record) => (
      <span>{intl.get("coupon.canRedeem." + text)}</span>
    )
  }, {
    title: intl.get("coupon.redeemStatus"),
    dataIndex: 'redeemStatus',
    key: 'redeemStatus',
    align: 'center',
    render: (text, record) => {
      let statusText = "ERROR";
      if (text === "no") {
        statusText = intl.get("coupon.redeemStatus.no");
      } else if (text === "check") {
        statusText = intl.get("coupon.redeemStatus.check");
      } else if  (text === "yes") {
        statusText = intl.get("coupon.redeemStatus.yes");
      }
      return (<span>{statusText}</span>);
    }
  }, {
    title: intl.get("coupon.redeemAmount"),
    dataIndex: 'redeemAmount',
    key: 'redeemAmount',
    align: 'right',
    render: (text, record) => (
      <div>
        <div>{Util.currencyFormat(record.redeemAmount, application.productCurrency)}</div>
        {record.redeemSettlementAmount && (<div style={{color: "gray", fontSize: 13}}>{Util.currencyFormat(record.redeemSettlementAmount, application.userPayCurrency)}</div>)}
      </div>

    )
  }, {
    title: intl.get("coupon.redeemAt"),
    dataIndex: 'redeemAt',
    key: 'redeemAt',
    align: 'center',
    render: (redeemAt, record) => (
      <span>{redeemAt && redeemAt.indexOf("000") !== 0 ? Util.getLocalTime(redeemAt).substring(0, 10) : "-"}</span>
    )
  }, {
    title: intl.get("common.createdAt"),
    dataIndex: 'createdAt',
    key: 'createdAt',
    align: 'center',
    render: (text, record) => (
      <span>{Util.getLocalTime(text).substring(0, 10)}</span>
    )
  }];

  // NOTICE: path argument를 사용하기 위해서는 Route의 순서가 중요. new -> edit -> show 순서로 작성
  return (
    <div>
      <div className="page-content">
        <Spin spinning={loading}>
          <div className="page-op-box">
            <div className="filter-box">
              {/*<Input style={{ width: 200, marginRight: 10 }} value={filter.name} onChange={changeName} placeholder={"이벤트명"}/>*/}
              <Divider type="vertical" />
              {/*<Select value={filter.active ? filter.active : undefined}*/}
              {/*        allowClear={true}*/}
              {/*        placeholder={"상태"}*/}
              {/*        onClear={() => changeActive('')}*/}
              {/*        onSelect={(value) => changeActive(value)}*/}
              {/*        style={{width: 100}}>*/}
              {/*  <Select.Option key={"true"} value={"true"}>진행중</Select.Option>*/}
              {/*  <Select.Option key={"false"} value={"false"}>미진행</Select.Option>*/}
              {/*</Select>*/}
              <div style={{marginLeft: "auto"}}>
                <div style={{width: 100}}>
                  <Button style={{width: 100}} type="primary" block size="default" onClick={handleSearchClick}>{intl.get("common.button.search")}</Button>
                </div>
              </div>
            </div>
            {/*<div className="extra-op-box">*/}
            {/*  <Link to={`${props.match.path}/new`}>*/}
            {/*    <Button type="default" size="small">{intl.get("common.button.add")}</Button>*/}
            {/*  </Link>*/}
            {/*</div>*/}
          </div>
          <div style={{marginTop: "10px"}}>
            <Table size={'small'}
                   dataSource={userCoupons}
                   columns={columns}
                   rowKey={'id'}
                   title={() => intl.get("common.label.total") + " " + pagination.total}
                   pagination={pagination}
                   // onRow={(record, rowIndex) => {
                   //   return {
                   //     onClick: event => history.push(`/coupon-events/${record.id}`),
                   //   };
                   // }}
            />
          </div>
        </Spin>
      </div>
    </div>
  )
};

export default UserCouponList;