import React, {useEffect, useState} from "react";
import {Button, Spin, Divider, message, Tag, Popconfirm, Table, Alert, Checkbox, Layout, Modal} from "antd";
import intl from "react-intl-universal";
import ProductApi from "./ProductApi";
import ProductFilter from "./ProductFilter";
import {EventBroadcaster, SHOW_ERROR_MESSAGE_EVENT_TOPIC} from "../../event/event.broadcaster";
import Util from "../../util/Util";
import NoImage from "../../asset/images/no_image.png";
import { useLayoutState } from "../../components/layout/AppLayoutContext";
import CategoryApi from "../category/CategoryApi";
import BrandApi from "../brand/BrandApi";
import ProductDetailViewPopup from "./ProductDetailViewPopup";
import SystemSettingApi from "../system_setting/SystemSettingApi";
import {QuestionCircleOutlined} from "@ant-design/icons";
const {Content} = Layout;

const initialPagination = {
  current: 1,
  pageSize: 20,
  total: 0,
};

const initialFilter = {
  nameType: "",
  name: "",
  idType: "",
  idTarget: "",
  published: true,
  brandId: "",
  categoryId: "",
  subCategoryId: "",
  sort: "latest",
  source: "others",
  openToMarket: true,
};

const ProductMarket = (props) => {
  const layoutState = useLayoutState();

  const [filter, setFilter] = useState(initialFilter);
  const [loading, setLoading] = useState(false);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState(0);
  const [visibleProductDetail, setVisibleProductDetail] = useState(false);
  const [products, setProducts] = useState([]);
  const [marketProducts, setMarketProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [pagination, setPagination] = useState(initialPagination);
  const [webmallUrl, setWebmallUrl] = useState("");
  const [published, setPublished] = useState(false);
  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const { application } = layoutState;
  useEffect(() => {
    EventBroadcaster.on(SHOW_ERROR_MESSAGE_EVENT_TOPIC, () => {
      setLoading(false);
    });
    searchProducts(pagination);
    getMarketProductIds();
    loadBrands();
    loadCategories();
    getDefaultWebMall();
  }, []);

  const getDefaultWebMall = () => {
    SystemSettingApi.getPublicSystemSettings().then(res => {
      const webmall = res.data.find(s => s.name === "webmall.url");
      if (webmall) {
        setWebmallUrl(webmall.value);
      }
    });
  };

  const searchProducts = (pagination) => {
    setLoading(true);
    const params = getSearchParam(pagination);

    ProductApi.getProducts(params)
      .then((res) => {
        const pagingResult = res.data;
        const pagination = {
          current: pagingResult.page,
          pageSize: pagingResult.pageSize,
          total: pagingResult.count,
        };
        setProducts(pagingResult.data);
        setPagination(pagination);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getSearchParam = (pagination) => {
    const params = {
      pageSize: pagination.pageSize,
      page: pagination.current,
      ...filter,
    };
    return params;
  };

  const loadCategories = () => {
    CategoryApi.getCategories({}).then((res) => {
      setCategories(res.data);
    });
  };

  const loadBrands = () => {
    BrandApi.getBrands({openToMarket: true}).then((res) => {
      setBrands(res.data);
    });
  };

  const setFilterState = (filter) => {
    setFilter({ ...filter });
  };

  const handleCopyProduct = () => {
    const selectedProductIdsState = [...selectedProductIds];
    if (!selectedProductIdsState || selectedProductIdsState.length === 0) {
      message.error(intl.get("common.message.selectAtLeastOne", {name: intl.get("products.product")}));
      return;
    }
    const selectedProducts = products.filter(p => selectedProductIdsState.includes(p.id)).map(p => {
      return {
        id: p.id,
        published: published,
      }
    });
    setLoading(true);
    ProductApi.marketToMyProduct(selectedProducts).then(res => {
      message.info(intl.get("common.message.saved"));
      setVisibleConfirm(false);
      getMarketProductIds();
    }).finally(() => {
      setLoading(false);
    });
  };

  const handleDeleteMarketProduct = () => {
    const selectedProductIdsState = [...selectedProductIds];
    if (!selectedProductIdsState || selectedProductIdsState.length === 0) {
      message.error(intl.get("common.message.selectAtLeastOne", {name: intl.get("products.product")}));
      return;
    }
    if (!window.confirm(intl.get("common.confirm.delete", {name: intl.get("products.source.productMarket")}))) {
      return;
    }
    setLoading(true);
    ProductApi.deleteMarketProducts(selectedProductIdsState).then(res => {
      message.info(intl.get("common.message.deleted"));
      getMarketProductIds();
      setSelectedProductIds([]);
    }).finally(() => {
      setLoading(false);
    });
  };

  const copyProduct = (e, product) => {
    e.stopPropagation();
    setLoading(true);
    const selectedProducts = [{
      id: product.id,
    }]
    ProductApi.marketToMyProduct(selectedProducts).then(res => {
      message.info(intl.get("common.message.saved"));
      getMarketProductIds();
    }).finally(() => {
      setLoading(false);
    });
  };

  const deleteMarketProduct = (e, product) => {
    e.stopPropagation();
    setLoading(true);
    ProductApi.deleteMarketProducts([product.id]).then(res => {
      message.info(intl.get("common.message.deleted"));
      getMarketProductIds();
    }).finally(() => {
      setLoading(false);
    });
  }

  const getMarketProductIds = () => {
    ProductApi.getMarketProductIds().then(res => {
      setMarketProducts(res.data);
    });
  }

  const isMarketProduct = (productId) => {
    const found = marketProducts.find(cp => cp.marketProductId === productId);
    return  found ? true : false;
  };

  const productTableChanged = (pagination) => {
    searchProducts(pagination);
  };

  const handleSearch = () => {
    const paginationState = {...pagination};
    paginationState.current = 1
    searchProducts(paginationState);
  };

  const onClickCopyProduct = (e, product) => {
    e.stopPropagation();
    setAlertMessage(isMarketProduct(product.id) ? intl.get("products.market.alreadyAdded") : "");
    setSelectedProductIds([product.id]);
    setVisibleConfirm(true);
  }

  const openPreview = (e, productId) => {
    e.stopPropagation();
    window.open(`${webmallUrl}/products/${productId}`, '_blank').focus();
  };

  const columns = [{
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
  }, {
    title: intl.get("products.featuredImage"),
    dataIndex: 'images',
    key: 'images',
    align: 'center',
    render: (text, record) => {
      const image = record.images.length > 0 ? record.images[0] + "?x-oss-process=image/resize,h_48,w_48" : NoImage;
      return  (<span><img src={image} style={{width: "48px", height: "48px"}}/></span>);
    }
  }, {
    title: intl.get("products.name"),
    dataIndex: 'name',
    key: 'name',
    render: (text, record) => {
      return (
        <div>
          <div>{record.name}</div>
          { (record.name !== record.nameKr) && (<div>{record.nameKr}</div>) }
          { record.tags && (
            <div>
              { record.tags.map((t, index) => (<Tag key={index} color="purple">{t.value}</Tag>)) }
            </div>
          )}
          {webmallUrl && (
            <div>
              <Button size="small" onClick={(e) => openPreview(e, record.id)}>{intl.get("products.preview")}</Button>
            </div>
          )}
        </div>
      )
    }
  }, {
    title: intl.get("products.options"),
    key: 'skus',
    dataIndex: 'skus',
    align: 'left',
    render: (text, record) => {
      const barcodes = [];
      record.skus.forEach((sku, index) => {
        if (index >= 5) {
          return;
        }
        let barcodeText = sku.optionText ? sku.optionText + ": " : "" ;
        barcodeText += sku.barCode;
        barcodes.push((<div key={sku.id}>{barcodeText}</div>));
      })
      if (record.skus.length >= 5) {
        barcodes.push((<div key={"etc"}>외  {record.skus.length - 5}개 SKU</div>));
      }
      return (<div style={{fontSize: 11}}>{barcodes}</div>)
    }
  }, {
    title: intl.get("products.taxRate"),
    key: 'taxRate',
    dataIndex: 'skus',
    align: 'left',
    render: (text, record) => {
      const taxRates = [];
      record.skus.forEach((sku, index) => {
        if (!taxRates.includes(sku.taxRate)) {
          taxRates.push(sku.taxRate);
        }
      });
      return taxRates.map((t, index) => {
        return (<div key={index}>{t}%</div>)
      })
    }
  }, {
    title: intl.get("products.salesPrice"),
    dataIndex: 'salesPrice',
    key: 'salesPrice',
    align: 'right',
    render: (text, record) => {
      return (
        <div>
          <div>{Util.currencyFormat(record.productPrices.salesPrice.originPrice, application.productCurrency)}</div>
          {application.productCurrency !== application.userPayCurrency && (
            <div style={{color: "gray",fontSize: 13}}>
              {Util.currencyFormat(record.productPrices.salesPrice.secondaryPrice, application.userPayCurrency)}
            </div>
          )}
        </div>
      )}
  }, {
    title: () => { return (<><div>{intl.get("common.createdAt")}</div><div>{intl.get("common.updatedAt")}</div></>) },
    dataIndex: 'createdAt',
    key: 'createdAt',
    align: 'center',
    render: (text, record) => (
      <>
        <div>{text ? text.substring(0, 10) : ""}</div>
        <div>{record.updatedAt ? record.updatedAt.substring(0, 10) : ""}</div>
      </>
    )
  }, {
    title: intl.get("products.addToMyProduct"),
    dataIndex: 'id',
    key: 'isMarketProduct',
    align: 'center',
    render: (text, record) => (
      <span>{isMarketProduct(record.id) ? intl.get("products.addedToMyProduct") : ""}</span>
    )
  }, {
    title: '',
    dataIndex: 'id',
    key: 'action',
    align: 'center',
    render: (text, record) => {
      return (
        <>
          <Button size="small" onClick={(e) => onClickCopyProduct(e, record)}>{intl.get("products.addToMyProduct")}</Button>
          {isMarketProduct(record.id) && (
            <div style={{marginTop: 5}}>
              <Popconfirm
                title={intl.get("products.confirmRemoveFromMyProduct")}
                onConfirm={(e) => deleteMarketProduct(e, record)}
                onCancel={(e) => e.stopPropagation()}
              >
                  <Button size="small" onClick={(e) =>  e.stopPropagation()}>{intl.get("products.removeFromMyProduct")}</Button>
              </Popconfirm>
            </div>
          )}
        </>
      )
    }
  }];

  const getTableRowClass = (product) => {
    if (isMarketProduct(product.id)) {
      return "market_product_row";
    } else {
      return "";
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedProductIds,
    onChange: (selectedProductIds) => {
      setSelectedProductIds(selectedProductIds);
    },
  };

  return (
    <Spin spinning={loading}>
      <div>
        <Alert message={intl.getHTML("products.productMarket.desc")} type="success" />
      </div>
      <div className="page-op-box">
        <ProductFilter
          filter={filter}
          setFilter={setFilterState}
          handleSearch={handleSearch}
          brands={brands}
          categories={categories}
          hideMarketSelection={true}
          {...props}
        />
        <div className="extra-op-box">
          <Button type="default" size="small" onClick={handleCopyProduct}>{intl.get("products.addToMyProduct")}</Button>
          <Divider type="vertical" />
          <Button type="default" size="small" onClick={handleDeleteMarketProduct}>{intl.get("products.removeFromMyProduct")}</Button>
        </div>
      </div>
      <div style={{marginTop: "10px", cursor: "pointer"}}>
        <Table size={'small'}
               dataSource={products}
               columns={columns}
               rowKey={'id'}
               title={() => intl.get("common.label.listTotal", {total: pagination.total})}
               pagination={pagination}
               onChange={productTableChanged}
               rowSelection={rowSelection}
               rowClassName={(record) => getTableRowClass(record)}
               onRow={(record, rowIndex) => {
                 return {
                   onClick: event => {
                     setSelectedProductId(record.id);
                     setVisibleProductDetail(true);
                   },
                 }
               }}
        />
      </div>
      {visibleProductDetail && (
        <ProductDetailViewPopup
          productId={selectedProductId}
          brands={brands}
          categories={categories}
          application={application}
          webmallUrl={webmallUrl}
          onCancel={() => setVisibleProductDetail(false)}
          onOk={() => setVisibleProductDetail(false)}
        />
      )}
      {visibleConfirm && (
        <Modal
          title={[<div key={1}><QuestionCircleOutlined/> {intl.get("common.button.confirm")}</div>]}
          visible={true}
          onOk={handleCopyProduct}
          onCancel={() => setVisibleConfirm(false)}
          width={500}
          bodyStyle={{margin: 0, padding: 0}}>
          <Layout>
            <Content className="modal-content">
              {alertMessage && (
                <div style={{color: "red"}}>{alertMessage}</div>
              )}
              <div>
                <Checkbox onChange={(e) => setPublished(e.target.checked)}>{intl.get("products.published.help")}</Checkbox>
              </div>
            </Content>
          </Layout>
        </Modal>
      )}
    </Spin>
  );
};

export default ProductMarket;