import Carousel from "react-multi-carousel";
import {PriceView} from "./PriceView";
import {ShoppingCartOutlined} from "@ant-design/icons";
import React from "react";

const ProductSlider2Row = (props) => {
  const {applicationDesign, settingValues} = props;
  const { themeColor } = applicationDesign.themeSetting;

  const responsive = {
    desktop: {
      breakpoint: {
        max: 3000,
        min: 375
      },
      partialVisibilityGutter: 27,
      items: 2,
    },
  };

  const getProductSlots = () => {
    const products = [];
    let slotIndex = 0;
    for (let i = 0; i < props.products.length; i+=2) {
      products[slotIndex] = [props.products[i]];
      if (props.products.length > i) {
        products[slotIndex].push(props.products[i + 1]);
      }
      slotIndex++;
    }
    return products;
  }

  // const backgroundColor = props.settingValues.backgroundColor || "white";
  const backgroundColor = settingValues.backgroundColor || "#ffffff";
  let padding = "16,16,16,16";
  if (settingValues.padding) {
    padding = settingValues.padding;
  }
  const paddingStyle = padding.split(",").map(p => p.trim() + "px").join(" ");

  const style = {
    backgroundColor: backgroundColor,
    padding: paddingStyle,
  };

  if (settingValues.backgroundImage) {
    style.backgroundImage = "url('" + settingValues.backgroundImage + "')";
    style.backgroundSize = "cover";
    style.backgroundRepeat = "no-repeat";
    style.backgroundPosition = "center center";
  }

  return (
    <div className="slider-box2" style={style}>
      <div style={{backgroundColor: backgroundColor}}>
        <Carousel responsive={responsive}
                  autoPlay={false}
                  showDots={false}
                  deviceType={"desktop"}
                  partialVisible={true}
                  draggable={true}
                  removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
        >
          {
            getProductSlots().map(products => {
              return (
                <div key={products[0].id}
                     className="item-box"
                     style={{backgroundColor: backgroundColor}}
                     onDragStart={props.handleDragStart}>
                  {products.map((product, index) => {
                    { return product !== undefined ? (
                      <div key={index} className={"item-inner-box"}
                           style={{borderRadius: props.settingValues.round ? 4 : 0}}>
                        <img style={{width: 148, height: 148, borderTopLeftRadius: 'inherit', borderTopRightRadius: 'inherit'}}
                             src={product.images[0] + "?x-oss-process=image/resize,w_256"}/>
                        <div className={"product-box"}>
                          <div className="u-line-2 product-name">{product.name}</div>
                          <div className={"price-wrapper"}>
                            <PriceView listPrice={product.listPrice}
                                       salesPrice={product.salesPrice}
                                       width={128}
                                       {...props}
                            />
                            {(settingValues.showCart) && (
                              <div style={{marginLeft: "auto"}}>
                                <ShoppingCartOutlined style={{ fontSize: '20px', color: themeColor.colorTable.price }}/>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div key={index} className={"product-box"}></div>
                    )
                    }
                  })}
                </div>
              )
            })
          }
        </Carousel>
      </div>
    </div>
  )
};

export default ProductSlider2Row;