import React, {useEffect, useState, Fragment} from "react";

import {
  Button,
  Form,
  Input,
  Descriptions,
  Table,
  Select,
  Popover, message, InputNumber, Checkbox, Alert
} from "antd";
import {QuestionCircleOutlined} from '@ant-design/icons';
import styled from "styled-components";
import FileListPopup from "../file_manager/FileListPopup";
import NoImage from "../../asset/images/no_image.png";
import intl from "react-intl-universal";
import {EditableCell, EditableRow} from "../../components/editable_table/EditableTable";
import {CustomsCodeUnit, getCustomsCodeText} from "../../util/Code";

const { Search } = Input;

const initSkuPriceRanges = [
  {
    id: 0,
    label: "수량",
    value1: 1,
    value2: 10,
    value3: 20,
    value4: 30,
    value5: 40,
  }, {
    id: 1,
    label: "가격",
    value1: 0,
    value2: 0,
    value3: 0,
    value4: 0,
    value5: 0,
  }, {
    id: 2,
    label: "",
    value1: 0,
    value2: 0,
    value3: 0,
    value4: 0,
    value5: 0,
  }
];

const ProductSkuEditForm = (props) => {
  const [editingSku, setEditingSku] = useState({});
  const [visibleFileModal, setVisibleFileModal] = useState(false);
  const [availableFieldGroups, setAvailableFieldGroups] = useState([]);
  const [help, setHelp] = useState({});
  const [editingPriceRanges, setEditingPriceRanges] = useState([]);
  const [columns, setColumns] = useState([]);
  const [maxSkuPriceId, setMaxSkuPriceId] = useState(5);    //value1, value2, ...의 최대값
  const [usePriceByQuantity, setUsePriceByQuantity] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    const taxRateDesc = (
      <div style={{color: "red"}}>
        {intl.getHTML("products.sku.taxRateDesc")}
      </div>
    )
    const newSkuHelp = (
      <div>
        {intl.getHTML("products.sku.newSkuHelp")}
      </div>
    );

    const updateSkuHelp = (
      <div>
        {intl.getHTML("products.sku.updateSkuHelp")}
      </div>
    )

    setHelp({
      taxRateDesc: taxRateDesc,
      newSkuHelp: newSkuHelp,
      updateSkuHelp: updateSkuHelp,
    });
  }, []);

  useEffect(() => {
    priceRangesToColumns(editingPriceRanges);
    skuPricesRangesToPriceByQuantities(editingPriceRanges);
  }, [editingPriceRanges]);

  useEffect(() => {
    const sku = JSON.parse(JSON.stringify(props.sku));
    if (sku.priceByQuantities?.length > 0) {
      sku.usePriceByQuantity = true;
      setUsePriceByQuantity(true);
      priceByQuantitiesToPriceRanges(sku.priceByQuantities);
    }
    setEditingSku(sku);
    if (props.form) {
      props.form.setFieldsValue(sku);
    } else {
      form.setFieldsValue(sku);
    }
  }, [props.sku.id, props.sku.timestamp]);

  useEffect(() => {
    if (props.skuFieldGroups) {
      if (props.product.cbtType === "none" || props.product.cbtType === "mail") {
        setAvailableFieldGroups(props.skuFieldGroups.filter(g => g.key !== 'customs'));
      } else {
        setAvailableFieldGroups(props.skuFieldGroups);
      }
    }
  }, [props.skuFieldGroups, props.product]);

  const getSkuDataInputFields = (group) => {
    let skuInputFields = props.skuDataInputFields.filter(f => f.group === group);

    const requireDeclare = (props.product.cbtType === "1210" || props.product.cbtType === "9610");

    return skuInputFields.map((field, index) => {
      if (!field.name) {
        return (<div key={index} style={{flex: "0 0 50%"}}/>)
      }

      let required = field.required ? true : false;
      if (requireDeclare && field.name === "taxRate") {
        required = true;
      }

      const fieldLabel= required ? (
          <><span style={{color: "red", marginRight: 2}}>*</span><span>{intl.get("products.sku." + field.name)}</span></>
        ) : (
          <span>{intl.get("products.sku." + field.name)}</span>
        );

      let disabled = props.product.marketProduct;
      if (props.product.eventProduct) {
        disabled = (field.name !== "salesPrice" && field.name !== "salesQuantity");
      } else {
        if (field.name === "salesPrice" || field.name === "listPrice" || field.name === "salesQuantity") {
          disabled = false;
        }
      }

      let fieldComponent;
      if (field.name === "unit") {
        fieldComponent = (
          <Select showSearch
                  disabled={disabled}
                filterOption={(input, option) => {
                  return option.children.indexOf(input) >= 0
                }}
                style={{ width: 200, marginRight: 10 }} >:wq
            {
              CustomsCodeUnit.map(item => {
                return (<Select.Option key={item.id} value={item.id}>{item.value}</Select.Option>)
              })
            }
          </Select>
        );
      } else {
        if (field.type === 'float') {
          if (field.name === "salesPrice") {
            fieldComponent = (<InputNumber disabled={disabled}
                                           style={{width: 200, marginRight: 10}}
                                           min={editingSku.minSalesPrice}
                                           max={editingSku.maxSalesPrice}
                                           step={0.01}/>);
          } else {
            fieldComponent = (<InputNumber disabled={disabled} style={{width: 200, marginRight: 10}} min={0} step={0.01}/>);
          }
        } else if(field.type === 'int') {
          fieldComponent = (<InputNumber disabled={disabled} style={{width: 200, marginRight: 10}} min={0} step={1}/>);
        } else {
          fieldComponent = (<Input disabled={disabled} autoComplete="off" style={{width: 200, marginRight: 10}}/>);
        }
      }

      return (
        <div style={{flex: "0 0 50%"}} key={index}>
          <div style={{display: "flex", alignItems: "center"}}>
            <div style={{textAlign: "right", width: 80, marginRight: 5}}>{fieldLabel}:</div>
            <Form.Item name={field.name}
                       rules={[{ required: required, message: intl.get("common.inputField", {name: intl.get("products.sku." + field.name)})}]}>
              {fieldComponent}
            </Form.Item>
            <Popover content={field.desc} title={intl.get("products.sku." + field.name)}>
              <QuestionCircleOutlined />
            </Popover>
          </div>
        </div>
      )
    });
  }

  // 옵션 이미지 직접 입력 시
  const onSkuImageChanged = (value) => {
    const editingSkuState = {...editingSku};
    editingSkuState.skuImage = value;
    setEditingSku(editingSkuState);
    if (props.skuValueChanged) {
      props.skuValueChanged(editingSkuState);
    }
  };

  // 옵션이미지 선택 시
  const okFileModal = (fileType, filePathList) => {
    if (filePathList.length === 0) {
      message.error(intl.get("order.upload.selectFile"));
      return;
    }
    onSkuImageChanged(filePathList[0]);
    setVisibleFileModal(false);
  };

  const setSkuPriceTableEditingValue = (record, dataIndex, value) => {
    const numValue = Number(value);
    if (isNaN(numValue)) {
      message.error(intl.get("common.message.inputNumber"));
      return;
    }
    record[dataIndex] = numValue;
    const editingPriceRangesState = [...editingPriceRanges];
    editingPriceRangesState[record.id] = record;
    setEditingPriceRanges(editingPriceRangesState);
  };

  const getSortedFieldName = (obj) => {
    const valuesKeys = Object.keys(obj).filter(fieldName => fieldName.startsWith("value"));
    const fieldIds = valuesKeys.map(key => {
      const fieldId = parseInt(key.substring("value".length));
      return fieldId;
    });

    return fieldIds.sort(function(a, b) {
      return a - b;
    }).map(fieldId => {
      return "value" + fieldId;
    });
  };

  //Editable Table 기준으로 입력된 자료구조 skuPricesRanges를 entity 자료구조 형태인 skuPrices 형태로 변환
  const skuPricesRangesToPriceByQuantities = (skuPriceRanges) => {
    if (!skuPriceRanges || skuPriceRanges.length === 0) {
      return;
    }
    const quantityFieldNames = getSortedFieldName(skuPriceRanges[0]);
    const quantities = quantityFieldNames.map(fieldName => {
      return skuPriceRanges[0][fieldName];
    })
    const priceFieldNames = getSortedFieldName(skuPriceRanges[1]);
    const prices = priceFieldNames.map(fieldName => {
      return skuPriceRanges[1][fieldName];
    })

    const priceByQuantities = quantities.map((quantity, index) => {
      return {
        minQuantity: quantities[index],
        maxQuantity: index <= quantities.length - 2 ? quantities[index + 1] : 0,
        salesPrice: prices[index],
      }
    });

    const editingSkuState = {...editingSku};
    editingSkuState.priceByQuantities = priceByQuantities;
    if (props.skuValueChanged) {
      props.skuValueChanged(editingSkuState);
    }
  };

  //entity 자료구조 형태인 skuPrices를 Editable Table를 위한 구조인 skuPricesRanges로 변환
  const priceByQuantitiesToPriceRanges = (priceByQuantities) => {
    const priceRanges = [
      {
        id: 0,
        label: intl.get("products.sku.salesQuantity"),
      }, {
        id: 1,
        label: intl.get("products.sku.price"),
      }, {
        id: 2,
        label: "",
      }
    ];
    priceByQuantities.forEach((price, index) => {
      priceRanges[0]["value" + (index + 1)] = price.minQuantity;
      priceRanges[1]["value" + (index + 1)] = price.salesPrice;
      priceRanges[2]["value" + (index + 1)] = 0;
    });
    setEditingPriceRanges(priceRanges);
    setMaxSkuPriceId(priceByQuantities.length + 1);
  }

  const onUseSkuPriceChange = (e) => {
    setUsePriceByQuantity(e.target.checked);
    const editingSkuState = {...editingSku};
    editingSkuState.usePriceByQuantity = e.target.checked;
    setEditingSku(editingSkuState);
    if (e.target.checked) {
      setEditingPriceRanges(initSkuPriceRanges);
    }
    if (props.skuValueChanged) {
      props.skuValueChanged(editingSkuState);
    }
  };

  const addSkuPrice = () => {
    const editingPriceRangesState = editingPriceRanges.map((sp, index) => {
      let value = 0;
      if (index === 0) {
        // 수량, 기본 값은 최대 수량에서 + 10 한다.
        const valuesFields = getSortedFieldName(sp);
        if (valuesFields.length > 0) {
          value = sp[valuesFields[valuesFields.length - 1]] === 1 ? 10 : sp[valuesFields[valuesFields.length - 1]] + 10;
        }
      }
      const fieldName = "value" + (maxSkuPriceId + 1);
      sp[fieldName] = value;
      return sp;
    });
    setEditingPriceRanges(editingPriceRangesState);
    setMaxSkuPriceId(maxSkuPriceId + 1);
  };

  const deleteSkuPrice = (dataIndex) => {
    if (Object.keys(editingPriceRanges[0]).filter(k => k.startsWith("value")).length === 1) {
      message.error("가격 범위는 최소 1개 이상 필요합니다.");
      return;
    }
    const editingPriceRangesState = editingPriceRanges.map(sp => {
      delete sp[dataIndex];
      return sp;
    });
    setEditingPriceRanges(editingPriceRangesState);
  };

  const priceRangesToColumns = (priceRanges) => {
    if (priceRanges && priceRanges.length > 0) {
      const valueFieldNames = getSortedFieldName(priceRanges[0]);
      const quantities = valueFieldNames.map(fieldName => {
        return priceRanges[0][fieldName];
      })
      const columns = Object.keys(priceRanges[0]).filter(k => k !== "id").map((key, index) => {
        const editable = index !== 0;
        let title = "";
        let valueIndex = index - 1;
        if (valueIndex >= 0 && valueIndex < quantities.length - 1) {
          title = `${(quantities[valueIndex])} >= ${intl.get("products.sku.salesQuantity")} < ${quantities[valueIndex + 1]}`;
        } else if (valueIndex >= quantities.length - 1) {
          title = `${intl.get("products.sku.salesQuantity")} >= ${quantities[quantities.length - 1]}`;
        }

        return {
          dataIndex: key,
          key: key,
          width: 100,
          align: 'right',
          title: title,
          onCell: (record) => ({
            record,
            editable: editable,
            dataIndex: key,
            handleSave: setSkuPriceTableEditingValue,
          }),
        }
      });
      setColumns(columns);
    }
  };

  const skuImage = editingSku.skuImage ? editingSku.skuImage + "?x-oss-process=image/resize,h_48,w_48" : NoImage;
  const disabled = props.product?.marketProduct ? true : false;

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  return (
    <>
      <Form form={props.form ? props.form : form} name="skuForm">
        <Descriptions bordered size={"small"} column={1}>
          <Descriptions.Item label={
            (<><span style={{color: "red", marginRight: 2}}>*</span><span>{intl.get("products.sku.imageUrl")}</span></>)
          }>
            <Search
              disabled={disabled}
              allowClear
              placeholder={intl.get("common.label.upload.help")}
              onChange={(e) => onSkuImageChanged(e.target.value)}
              value={editingSku.skuImage}
              onSearch={() => setVisibleFileModal(true)}
            />
            <img src={skuImage} style={{marginTop: 5, width: 64, height: 64}}/>
          </Descriptions.Item>
          {availableFieldGroups.map(group => (
            <Fragment key={group.key}>
              <Descriptions.Item label={group.label}>
                <div style={{display: "flex", flexWrap: "wrap"}}>
                  {getSkuDataInputFields(group.key)}
                </div>
              </Descriptions.Item>
              {group.key === "price" && (
                <Descriptions.Item
                  key={"wholesale-price"}
                  label={(
                    <div>
                      <Checkbox checked={usePriceByQuantity} onChange={onUseSkuPriceChange}>
                        {intl.get("products.sku.priceByQty")}
                      </Checkbox>
                    </div>
                  )}>
                  {usePriceByQuantity && (
                    <div>
                      <div style={{textAlign: "right", width: "100%"}}>
                        <Button type="primary" size={"small"} onClick={addSkuPrice}>{intl.get("products.sku.addQty")}</Button>
                      </div>
                      <Table size={'small'}
                             // showHeader={false}
                             components={components}
                             rowClassName={() => 'editable-row'}
                             rowKey={'id'}
                             dataSource={editingPriceRanges}
                             columns={columns}
                             pagination={false}
                             onRow={(_, index) => {
                               const ignoreEditing = index === 2;
                               const attr = {
                                 index,
                                 ignoreEditing,
                                 renderCell: (dataIndex, record) => {
                                   if (dataIndex === "label") {
                                     return (<div>{record[dataIndex]}</div>);
                                   } else {
                                     return (<Button onClick={() => deleteSkuPrice(dataIndex)}>{intl.get("common.button.delete")}</Button>);
                                   }
                                 }
                               };
                               return attr;
                             }}
                      />
                      {props.skuPriceErrors?.length > 0 && (
                        <div style={{marginTop: 10}}>
                          {props.skuPriceErrors.map((err, index) => (
                            <Alert key={index} message={err} type="error" showIcon banner />
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </Descriptions.Item>
              )}
            </Fragment>
          ))}
        </Descriptions>
      </Form>
      {visibleFileModal && (
        <FileListPopup id={"skuImage"}
                       fileKind={"product"}
                       visible={true}
                       multipleFile={false}
                       canSelect={true}
                       handleCancel={() => setVisibleFileModal(false)}
                       handleOk={okFileModal}
        />
      )}
    </>
  )
}

const PriceRow = styled.div`
  display: flex;
`;

const PriceCell = styled.div`
  border: 1px solid #CCC; 
  background-color: ${(props) => props.backgroundColor};
  width: 60px;
  text-align: center;
  padding: 2px;
`;

export default ProductSkuEditForm;