import React, {useEffect, useState} from 'react';
import TermsApi from "./TermsApi";
import {Descriptions, Input, Radio, Button, Divider, message, Select} from 'antd';
import intl from "react-intl-universal";
import {Link} from "react-router-dom";
import DefaultTerms from "./DefaultTerms";
import dayjs from "dayjs";
import {adminConfig} from "../../config/admin.config";
import {useLayoutState} from "../../components/layout/AppLayoutContext";
import Cookies from "universal-cookie";

const { TextArea } = Input;
const cookies = new Cookies();

const Terms = (props) => {
  const layoutState = useLayoutState();
  const [termsType, setTermsType] = useState("user");
  const [language, setLanguage] = useState("zh-CN");
  const [terms, setTerms] = useState({
    termsType: "user",
    published: false,
  });

  useEffect(() => {
    let locale = cookies.get('locale');
    if (locale !== "zh-CN" && locale !== "ko-KR") {
      const lang = navigator.language || navigator.userLanguage;
      // en-US, zh-CN, zh, ko
      if (lang.startsWith("zh")) {
        locale = "zh-CN";
      } else {
        locale = "ko-KR";
      }
    }
    setLanguage(locale);
    loadTerms(termsType, locale);
  }, []);

  const loadTerms = (termsType, language) => {
    TermsApi.getTermsByType(termsType, language).then(res => {
      const terms = res.data;
      setTerms(terms);
    }).catch(err => {
      if (err.response && err.response.status === 404) {
        setTerms({
          termsType: termsType,
          editingTerms: "",
          published: false,
        });
      }
    }).finally(() => {
      setTermsType(termsType);
    })
  };

  const onTermsChanged = (e) => {
    const termsState = {...terms};
    termsState.editingTerms = e.target.value;
    setTerms(termsState);
  };

  const onLanguageChanged = (value) => {
    setLanguage(value);
    loadTerms(termsType, value);
  };

  const saveTerms = () => {
    if (!window.confirm(intl.get("common.confirm.save", {name: intl.get("terms.terms")}))) {
      return;
    }

    const termsState = {...terms};
    termsState.language = language;

    TermsApi.saveTerms(termsState).then(res => {
      const savedTerms = res.data;
      message.success(intl.get("common.message.saved"));
      setTerms(savedTerms);
    });
  };

  const publishTerms = () => {
    if (!window.confirm(intl.get("terms.setPublish.message"))) {
      return;
    }
    const termsState = {...terms};

    if (!termsState.editingTerms) {
      message.error(intl.get("common.inputField", {name: intl.get("terms.terms")}));
      return;
    }
    termsState.publishedTerms = termsState.editingTerms;
    termsState.published = true;
    TermsApi.saveTerms(termsState).then(res => {
      const savedTerms = res.data;
      message.success(intl.get("terms.published.message"));
      setTerms(savedTerms);
    });
  };

  const cancelPublishTerms = () => {
    if (!window.confirm(intl.get("terms.setPrivate.message"))) {
      return;
    }

    const termsState = {...terms};
    termsState.published = false;
    TermsApi.saveTerms(termsState).then(res => {
      const savedTerms = res.data;
      message.success(intl.get("terms.private.message"));
      setTerms(savedTerms);
    });
  };

  const loadSample = () => {
    if (!window.confirm(intl.get("terms.sample.confirm1"))) {
      return;
    }
    if (!window.confirm(intl.get("terms.sample.confirm2"))) {
      return;
    }
    let userTerm = "";
    if (termsType === 'user') {
      if (language === "ko-KR") {
        userTerm = DefaultTerms.userTermsKo;
      } else {
        userTerm = DefaultTerms.userTerms;
      }
    } else if (termsType === 'privacy') {
      if (language === "ko-KR") {
        userTerm = DefaultTerms.privacyTermsKo;
      } else {
        userTerm = DefaultTerms.privacyTerms;
      }
    } else {
      message.error("Wrong termsType");
      return;
    }
    const appName = layoutState.application && layoutState.application.id ? layoutState.application.name : adminConfig.siteName;

    userTerm = userTerm.replace(new RegExp("{{merchandiserName}}", 'gi'), appName);
    const currentDate = dayjs().format('YYYY年MM月DD日');
    userTerm = userTerm.replace(new RegExp("{{currentDate}}", "gi"), currentDate);

    const termsState = {...terms};
    termsState.editingTerms = userTerm;
    setTerms(termsState);
  };

  return (
      <div>
        <div style={{marginBottom: 10}}>
          <div style={{display: "flex"}}>
            <div>
              <Select value={language} style={{width: 200}} onChange={onLanguageChanged}>
                <Select.Option value={"zh-CN"}>{intl.get("common.label.language.chinese")}</Select.Option>
                <Select.Option value={"ko-KR"}>{intl.get("common.label.language.korean")}</Select.Option>
              </Select>
            </div>
            <div style={{marginLeft: "auto"}}>
              <Button type={termsType === "user" ? "primary" : ""} onClick={() => loadTerms('user', language)}>{intl.get("terms.termsType.user")}</Button>
              <Divider type="vertical" />
              <Button type={termsType === "privacy" ? "primary" : ""} onClick={() => loadTerms('privacy', language)}>{intl.get("terms.termsType.privacy")}</Button>
              <Divider type="vertical" />
            </div>
          </div>
        </div>
        <Descriptions bordered size={"small"} column={1} layout={"vertical"}
                      extra={<Button size="small" onClick={loadSample}>{intl.get("terms.useSampleTerm")}</Button>}>
          <Descriptions.Item label={intl.get("terms.termsType")}>
            <Radio.Group value={terms.termsType} disabled={true}>
              <Radio value={"user"}>{intl.get("terms.termsType.user")}</Radio>
              <Radio value={"privacy"}>{intl.get("terms.termsType.privacy")}</Radio>
            </Radio.Group>
          </Descriptions.Item>
          <Descriptions.Item label={intl.get("terms.published")}>
            <Radio.Group value={terms.published} disabled={true}>
              <Radio value={true}>{intl.get("terms.published")}</Radio>
              <Radio value={false}>{intl.get("terms.not-published")}</Radio>
            </Radio.Group>
          </Descriptions.Item>
          <Descriptions.Item label={intl.get("terms.terms")}>
            <TextArea value={terms.editingTerms} rows={20} onChange={onTermsChanged}>{terms.editingTerms}</TextArea>
          </Descriptions.Item>
        </Descriptions>
        <div style={{width:"100%", textAlign:"center", marginTop: 20}}>
          <span style={{color: "red"}}>{intl.get("terms.publish.help")}</span>
        </div>
        <div style={{width:"100%", textAlign:"center", marginTop: 20}}>
          <Button type="primary" onClick={saveTerms}>{intl.get("common.button.save")}</Button>
          <Divider type="vertical"/>
          <Button type="primary"  onClick={publishTerms}>{intl.get("terms.published")}</Button>
          <Divider type="vertical"/>
          <Button type="primary"  onClick={cancelPublishTerms}>{intl.get("terms.not-published")}</Button>
        </div>
      </div>
  )
}

export default Terms;