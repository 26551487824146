import React, {useState, useEffect} from "react";
import CompanyApi from "./CompanyApi";
import CompanyForm from "./CompanyForm";

const CompanyEdit = (props) => {
  const [fromList, setFromList] = useState(false);
  const [company, setCompany] = useState({});


  useEffect(() => {
    if (props.match.path === "/company") {
      // 회사 관리자가 상점 관리에서 회사 장보 관리로 들어온 경우
      // edit mode
      loadCompany(0);
      return;
    }

    setFromList(true);

    const companyId = props.match.params.companyId;
    if (companyId) {
      // edit mode
      loadCompany(companyId);
    } else {
      setCompany({});
    }
  }, []);

  const loadCompany = (companyId) => {
    CompanyApi.getCompany(companyId).then(res => {
      setCompany(res.data);
    });
  };

  const setCompanyState = (company) => {
    setCompany(JSON.parse(JSON.stringify(company)));
    if (props.setCompany) {
      props.setCompany(company);
    }
  };

  return (
    <CompanyForm
      showListButton={fromList}
      setCompany={setCompanyState}
      company={company}
      {...props}/>
  )
};

export default CompanyEdit;