import {adminConfig} from "../../config/admin.config";
import axios from "axios";
const API_BASE_URL = adminConfig.apiServer();

export default class SystemSettingApi {
  static getSystemSettings = () => {
    let apiPath = `${API_BASE_URL}/system-settings?projection=webAdmin&`;
    return axios.get(apiPath);
  };

  static getPublicSystemSettings = () => {
    let apiPath = `${API_BASE_URL}/system-settings/public?projection=webAdmin&`;
    return axios.get(apiPath);
  };

  static submitSystemSetting = (systemSetting) => {
    if (systemSetting.added) {
      let apiPath = `${API_BASE_URL}/system-settings?projection=webAdmin`;
      return axios.post(apiPath, systemSetting);
    } else {
      let apiPath = `${API_BASE_URL}/system-settings/${systemSetting.id}?projection=webAdmin`;
      return axios.put(apiPath, systemSetting);
    }
  };

  static deleteSystemSetting = (id) => {
    let apiPath = `${API_BASE_URL}/system-settings/${id}?projection=webAdmin`;
    return axios.delete(apiPath);
  };
}