export default {
  userTermsKo: "준비중입니다.",
  privacyTermsKo: "준비중입니다.",
  userTerms:
      "{{merchandiserName}}商店服务协议\n\n" +
      "最新版本生效日期：{{currentDate}} \n\n" +
      "提示条款 \n" +
      "欢迎您与各{{merchandiserName}}商店经营者详见定义条款）共同签署本《{{merchandiserName}}商店服务协议》（下称“本协议”）并使用{{merchandiserName}}商店服务！\n" +
      "自本协议发布之日起，{{merchandiserName}}商店各处所称“{{merchandiserName}}服务协议”均指本协议。\n" +
      "各服务条款前所列索引关键词仅为帮助您理解该条款表达的主旨之用，不影响或限制本协议条款的含义或解释。为维护您自身权益，建议您仔细阅读各条款具体表述。\n" +
      "【审慎阅读】您在申请注册流程中点击同意本协议之前，应当认真阅读本协议。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。" +
      "免除或者限制责任的条款将以粗体下划线标识，您应重点阅读。如您对协议有任何疑问，可向{{merchandiserName}}商店客服咨询。\n" +
      "【签约动作】当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，即表示您已充分阅读、理解并接受本协议的全部内容，" +
      "并与{{merchandiserName}}达成一致，成为{{merchandiserName}}商店“用户”。阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止注册程序 \n" +
      "\n" +
      "一、 定义 \n" +
      "\n" +
      "{{merchandiserName}}商店：指包括{{merchandiserName}}商店（域名为 eryuan.co）网站及微信小程序等客户端。\n" +
      "{{merchandiserName}}：{{merchandiserName}}商店经营者的单称或合称，包括优码网络科技（威海）有限公司等。\n" +
      "{{merchandiserName}}商店服务：{{merchandiserName}}基于互联网，以包含{{merchandiserName}}商店网站、客户端等在内的各种形态（包括未来技术发展出现的新的服务形态）向您提供的各项服务。 \n" +
      "{{merchandiserName}}商店规则：包括在所有{{merchandiserName}}商店规则频道内已经发布及后续发布的全部规则、解读、公告等内容以及各商店在帮派、论坛、帮助中心内发布的各类规则、实施细则、产品流程说明、公告等。 \n" +
      "\n" +
      "二、 协议范围 \n" +
      "\n" +
      "2.1 签约主体 \n" +
      "\n" +
      "【平等主体】本协议由您与{{merchandiserName}}商店经营者共同缔结，本协议对您与{{merchandiserName}}商店经营者均具有合同效力。 \n" +
      "【主体信息】{{merchandiserName}}商店经营者是指经营{{merchandiserName}}商店的各法律主体，您可随时查看 " +
      "{{merchandiserName}}商店各网站首页底部公示的证照信息以确定与您履约的{{merchandiserName}}主体。本协议项下， " +
      "{{merchandiserName}}商店经营者可能根据{{merchandiserName}}商店的业务调整而发生变更，变更后的{{merchandiserName}}商店经营者与您共同履行本协议并向您提供服务， " +
      "{{merchandiserName}}商店经营者的变更不会影响您本协议项下的权益。{{merchandiserName}}商店经营者还有可能因为提供新的{{merchandiserName}}商店服务而新增， " +
      "如您使用新增的{{merchandiserName}}商店服务的，视为您同意新增的{{merchandiserName}}商店经营者与您共同履行本协议。发生争议时， " +
      "您可根据您具体使用的服务及对您权益产生影响的具体行为对象确定与您履约的主体及争议相对方。 \n" +
      "\n" +
      "2.2 补充协议 \n" +
      "\n" +
      "由于互联网高速发展，您与{{merchandiserName}}签署的本协议列明的条款并不能完整罗列并覆盖您与{{merchandiserName}}所有权利与义务，现有的约定也不能保证完全符合未来发展的需求。因此， " +
      "{{merchandiserName}}商店及后续更新的政策及隐私权、{{merchandiserName}}商店规则均为本协议的补充协议，与本协议不可分割且具有同等法律效力。 " +
      "如您使用{{merchandiserName}}商店服务，视为您同意上述补充协议。 \n" +
      "\n" +
      "三、 账户注册与使用 \n" +
      "\n" +
      "3.1 用户资格 \n" +
      "\n" +
      "您确认，在您开始注册程序使用{{merchandiserName}}商店服务前，您应当具备中华人民共和国法律规定的与您行为相适应的民事行为能力。 " +
      "若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人应依照法律规定承担因此而导致的一切后果。 \n" +
      "此外，您还需确保您不是任何国家、地区或国际组织实施的贸易限制、经济制裁或其他法律法规限制的对象，也未直接或间接为前述对象提供资金、商品或服务，否则您应当停止使用{{merchandiserName}}商店服务， " +
      "同时您理解违反前述要求可能会造成您无法正常注册及使用{{merchandiserName}}商店服务。 \n" +
      "\n" +
      "3.2 账户说明 \n" +
      "\n" +
      "【账户获得】当您按照注册页面提示填写信息、阅读并同意本协议且完成全部注册程序后，您可获得{{merchandiserName}}商店账户并成为{{merchandiserName}}商店用户。 \n" +
      "{{merchandiserName}}商店只允许每位用户使用一个{{merchandiserName}}商店账户。如有证据证明或{{merchandiserName}}根据 " +
      "{{merchandiserName}}商店规则判断您存在不当注册或不当使用多个{{merchandiserName}}商店账户的情形，{{merchandiserName}}商店可采取冻结或关闭账户、 " +
      "取消订单、拒绝提供服务等措施，如给{{merchandiserName}}商店及相关方造成损失的，您还应承担赔偿责任。 \n" +
      "【账户使用】您有权使用您设置或确认的{{merchandiserName}}会员名、邮箱、手机号码（以下简称“账户名称”）及您设置的密码（账户名称及密码合称“账户”）登录{{merchandiserName}}商店。 \n" +
      "由于您的{{merchandiserName}}商店账户关联您的个人信息及{{merchandiserName}}商店商业信息，您的{{merchandiserName}}商店账户仅限您本人使用。 " +
      "未经{{merchandiserName}}商店同意，您直接或间接授权第三方使用您{{merchandiserName}}商店账户或获取您账户项下信息的行为无效。 " +
      "如{{merchandiserName}}根据{{merchandiserName}}商店规则中约定的违约认定程序及标准判断您{{merchandiserName}}商店账户的使用可能危及您的账户安全及/或{{merchandiserName}}商店信息安全的， " +
      "{{merchandiserName}}商店可拒绝提供相应服务或终止本协议。 \n" +
      "【账户转让】由于用户账户关联用户信用信息，仅当有法律明文规定、司法裁定或经{{merchandiserName}}同意， " +
      "并符合{{merchandiserName}}商店规则规定的用户账户转让流程的情况下，您可进行账户的转让。您的账户一经转让，该账户项下权利义务一并转移。除此外，您的账户不得以任何方式转让， " +
      "否则{{merchandiserName}}商店有权追究您的违约责任，且由此产生的责任及后果均由您自行承担。 \n" +
      "【实名认证】作为{{merchandiserName}}商店经营者，为使您更好地使用{{merchandiserName}}商店的各项服务，保障您的账户安全，{{merchandiserName}}可要求您按照相关法律法规规定完成实名认证。 \n" +
      "\n" +
      "3.3 注册信息管理 \n" +
      "\n" +
      "3.3.1 真实合法 \n" +
      "\n" +
      "【信息真实】在使用{{merchandiserName}}商店服务时，您应当按{{merchandiserName}}商店页面的提示准确完整地提供您的信息（包括您的姓名及电子邮件地址、联系电话、联系地址等）， " +
      "以便{{merchandiserName}}或其他用户与您联系。您了解并同意，您有义务保持您提供信息的真实性及有效性。您提供个实名与身份证号码需要一致，此信息仅限适用于海关通关。 \n" +
      "【会员名的合法性】您设置的{{merchandiserName}}会员名不得违反国家法律法规及{{merchandiserName}}商店相关规则关于会员名的管理规定，否则{{merchandiserName}}可回收您的{{merchandiserName}}会员名。 " +
      "{{merchandiserName}}会员名的回收不影响您以邮箱、手机号码登录{{merchandiserName}}商店并使用{{merchandiserName}}商店服务。 \n" +
      "\n" +
      "3.3.2 更新维护 \n" +
      "\n" +
      "您应当及时更新您提供的信息，在法律有明确规定要求{{merchandiserName}}作为商店服务提供者必须对部分用户（如商店卖家和用户等）的信息进行核实的情况下， " +
      "{{merchandiserName}}将依法不时地对您的信息进行检查核实，您应当配合提供最新、真实、完整、有效的信息。 \n" +
      "如{{merchandiserName}}按您最后一次提供的信息与您联系未果、您未按{{merchandiserName}}的要求及时提供信息、您提供的信息存在明显不实或行政司法机关核实您提供的信息无效的， " +
      "您将承担因此对您自身、他人及{{merchandiserName}}造成的全部损失与不利后果。 " +
      "{{merchandiserName}}可向您发出询问或要求整改的通知，并要求您进行重新认证，直至中止、终止对您提供部分或全部{{merchandiserName}}商店服务，{{merchandiserName}}对此不承担责任。 \n" +
      "\n" +
      "3.4 账户安全规范 \n" +
      "\n" +
      "【账户安全保管义务】您的账户为您自行设置并由您保管，{{merchandiserName}}任何时候均不会主动要求您提供您的账户密码。 " +
      "因此，建议您务必保管好您的账户，并确保您在每个上网时段结束时退出登录并以正确步骤离开{{merchandiserName}}商店。 \n" +
      "账户因您主动泄露或因您遭受他人攻击、诈骗等行为导致的损失及后果，{{merchandiserName}}并不承担责任，您应通过司法、行政等救济途径向侵权行为人追偿。 \n" +
      "【账户行为责任自负】除{{merchandiserName}}存在过错外，您应对您账户项下的所有行为结果（包括但不限于在线签署各类协议、发布信息、购买商品及服务及披露信息等）负责。 \n" +
      "【日常维护须知】如发现任何未经授权使用您账户登录{{merchandiserName}}商店或其他可能导致您账户遭窃、遗失的情况， " +
      "建议您立即通知{{merchandiserName}}，并授权{{merchandiserName}}将该信息同步给支付宝及阿里商店。 " +
      "您理解{{merchandiserName}}对您的任何请求采取行动均需要合理时间，且{{merchandiserName}}应您请求而采取的行动可能无法避免或阻止侵害后果的形成或扩大， " +
      "除{{merchandiserName}}存在法定过错外，{{merchandiserName}}不承担责任。 \n" +
      "\n" +
      "四、 {{merchandiserName}}商店服务及规范 \n" +
      "\n" +
      "4.1 商品及/或服务的浏览、收藏、分享 \n" +
      "\n" +
      "在您浏览我们网站或客户端的过程中，{{merchandiserName}}为您提供了信息分类、关键词检索、筛选、收藏及关注等功能， " +
      "以更好地匹配您的需求。您可以对您感兴趣的商品及/或服务进行收藏、添加至购物车，关注您所感兴趣的店铺/品牌等。之用{{merchandiserName}}卖家会分享商品给一般用户， 一般用户会通过卖家分享的商品可以购买商品。 \n" +
      "\n" +
      "4.2商品及/或服务的购买 \n" +
      "\n" +
      "【商品及/或服务的购买】当您在{{merchandiserName}}商店购买商品及/或服务时，请您务必仔细确认所购商品的品名、价格、数量、型号、规格、尺寸或服务的时间、内容、限制性要求等重要事项， " +
      "并在下单时核实您的联系地址、电话、收货人等信息。如您填写的收货人非您本人，则该收货人的行为和意思表示产生的法律后果均由您承担。您购已买的商品无法二次销售。 \n" +
      "您的购买行为应当基于真实的消费需求，不得存在对商品及/或服务实施恶意购买、恶意维权等扰乱{{merchandiserName}}商店正常交易秩序的行为。  " +
      "基于维护{{merchandiserName}}商店交易秩序及交易安全的需要，{{merchandiserName}}发现上述情形时可主动执行关闭相关交易订单等操作。 \n" +
      "\n" +
      "4.3交易争议处理 \n" +
      "\n" +
      "【交易争议处理途径】您在{{merchandiserName}}商店交易过程中发生争议的，您有权选择以下途径解决： \n" +
      "\n" +
      "（一）与{{merchandiserName}}商店自主协商； \n" +
      "（二）请求消费者协会或者其他依法成立的调解组织调解； \n" +
      "（三）向有关行政部门投诉； \n" +
      "（四）根据与争议相对方达成的仲裁协议（如有）提请仲裁机构仲裁； \n" +
      "（五）向人民法院提起诉讼。 \n" +
      "\n" +
      "【商店调处服务】如您依据{{merchandiserName}}商店规则使用{{merchandiserName}}商店的争议调处服务， " +
      "则表示您认可并愿意履行{{merchandiserName}}商店的客服或大众评审员（“调处方”）作为独立的第三方根据其所了解到的争议事实并依据  " +
      "{{merchandiserName}}商店规则所作出的调处决定（包括调整相关订单的交易状态、判定将争议款项的全部或部分支付给交易一方或双方等）。 " +
      "在{{merchandiserName}}商店调处决定作出前，您可选择上述（三）、（四）、（五）途径（下称“其他争议处理途径”）解决争议以中止 " +
      "{{merchandiserName}}商店的争议调处服务。如您对调处决定不满意，您仍有权采取其他争议处理途径解决争议，但通过其他争议处理途径未取得终局决定前，您仍应先履行调处决定。 \n" +
      "\n" +
      "4.4 信息交流 \n" +
      "\n" +
      "为满足用户间分享购物心得等信息交流需求，您可通过{{merchandiserName}}提供的问答、晒单分享、论坛、群组及其他信息发布功能公开发布信息及与其他用户互动， " +
      "信息的形式包括文字、图片、视频、直播内容等，您须确保您所发布的所有信息均符合相关法律法规及本协议的要求。 \n" +
      "\n" +
      "4.5 贸易限制 \n" +
      "\n" +
      "针对通过{{merchandiserName}}商店取得的商品、服务、软件及技术（包括{{merchandiserName}}商店提供的软件及技术），您承诺遵守所有适用的进出口管制、贸易限制与经济制裁相关法律法规。 " +
      "基于维护{{merchandiserName}}商店交易秩序及交易安全的需要，如您违反前述承诺的，{{merchandiserName}}可在发现上述情形时主动执行关闭相关交易订单及作出账户处置等操作。 \n" +
      "\n" +
      "4.6 费用 \n" +
      "\n" +
      "{{merchandiserName}}为{{merchandiserName}}商店向您提供的服务付出了大量的成本，除{{merchandiserName}}商店明示的收费业务外 " +
      "{{merchandiserName}}向您提供的服务目前是免费的。如未来{{merchandiserName}}向您收取合理费用， " +
      "{{merchandiserName}}会采取合理途径并以足够合理的期限提前通过法定程序并以本协议第八条约定的方式通知您，确保您有充分选择的权利。 \n" +
      "\n" +
      "4.7 责任限制 \n" +
      "\n" +
      "【不可抗力及第三方原因】{{merchandiserName}}依照法律规定履行基础保障义务，但对于下述原因导致的合同履行障碍、履行瑕疵、履行延后或履行内容变更等情形，{{merchandiserName}}并不承担相应的违约责任： \n" +
      "\n" +
      "（一）因自然灾害、罢工、暴乱、战争、政府行为、司法行政命令等不可抗力因素； \n" +
      "（二）因电力供应故障、通讯网络故障等公共服务因素或第三人因素； \n" +
      "（三）在{{merchandiserName}}已尽善意管理的情况下，因常规或紧急的设备与系统维护、设备与系统故障、网络信息与数据安全等因素。 \n" +
      "\n" +
      "【海量信息】{{merchandiserName}}仅向您提供{{merchandiserName}}商店服务，您了解{{merchandiserName}}商店上的信息系用户自行发布，且可能存在风险和瑕疵。 " +
      "{{merchandiserName}}将通过依法建立相关检查监控制度尽可能保障您在{{merchandiserName}}商店上的合法权益及良好体验。同时， " +
      "鉴于{{merchandiserName}}商店具备存在海量信息及信息网络环境下信息与实物相分离的特点，{{merchandiserName}}无法逐一审查商品及/或服务的信息， " +
      "无法逐一审查交易所涉及的商品及/或服务的质量、安全以及合法性、真实性、准确性，对此您应谨慎判断。 \n" +
      "【调处决定】您理解并同意，在争议调处服务中，{{merchandiserName}}商店的客服、大众评审员并非专业人士， " +
      "仅能以普通人的认知对用户提交的凭证进行判断。因此，除存在故意或重大过失外，调处方对争议调处决定免责。 \n" +
      "\n" +
      "五、用户信息的保护及授权 \n" +
      "\n" +
      "5.1 个人信息的保护 \n" +
      "\n" +
      "{{merchandiserName}}非常重视用户个人信息（即能够独立或与其他信息结合后识别用户身份的信息）的保护，在您使用{{merchandiserName}}提供的服务时， " +
      "您同意{{merchandiserName}}按照在{{merchandiserName}}商店上公布的隐私权政策收集、存储、使用、披露和保护您的个人信息。 " +
      "{{merchandiserName}}希望通过隐私权政策向您清楚地介绍{{merchandiserName}}对您个人信息的处理方式，因此{{merchandiserName}}建议您完整地阅读隐私权政策，以帮助您更好地保护您的隐私权。 \n" +
      "\n" +
      "5.2非个人信息的保证与授权 \n" +
      "\n" +
      "【信息的发布】您声明并保证，您对您所发布的信息拥有相应、合法的权利。否则，{{merchandiserName}}可对您发布的信息依法或依本协议进行删除或屏蔽。 \n" +
      "【禁止性信息】您应当确保您所发布的信息不包含以下内容： \n" +
      "（一）违反国家法律法规禁止性规定的； \n" +
      "（二）政治宣传、封建迷信、淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的； \n" +
      "（三）欺诈、虚假、不准确或存在误导性的； \n" +
      "（四）侵犯他人知识产权或涉及第三方商业秘密及其他专有权利的； \n" +
      "（五）侮辱、诽谤、恐吓、涉及他人隐私等侵害他人合法权益的； \n" +
      "（六）存在可能破坏、篡改、删除、影响{{merchandiserName}}商店任何系统正常运行或未经授权秘密获取{{merchandiserName}}商店及其他用户的数据、个人资料的病毒、木马、爬虫等恶意软件、程序代码的； \n" +
      "（七）其他违背社会公共利益或公共道德或依据相关{{merchandiserName}}商店协议、规则的规定不适合在{{merchandiserName}}商店上发布的。 \n" +
      "\n" +
      "【授权使用】对于您提供、发布及在使用{{merchandiserName}}商店服务中形成的除个人信息外的文字、图片、视频、音频等非个人信息，均不会因上传、 " +
      "发布等行为发生知识产权、肖像权等权利的转移。除非我们另行说明，在法律允许的范围内，您免费授予{{merchandiserName}}及其关联公司、支付宝公司非排他的、 " +
      "无地域限制的许可使用（包括存储、使用、复制、修订、编辑、发布、展示、翻译、分发上述信息或制作派生作品， " +
      "以已知或日后开发的形式、媒体或技术将上述信息纳入其它作品内等）及可再许可第三方使用的权利，以及可以自身名义对第三方侵权行为取证及提起诉讼的权利。申请体验团商品时， " +
      "{{merchandiserName}}将收取您的承诺，会要求允许{{merchandiserName}}及品牌使用您所参加的体验团活动内容（包括笔记，微博朋友圈等）。 此内容仅用于品牌和{{merchandiserName}}服务的营销与推广。 \n" +
      "\n" +
      "六、 用户的违约及处理 \n" +
      "\n" +
      "6.1 违约认定 \n" +
      "\n" +
      "发生如下情形之一的，视为您违约： \n" +
      "\n" +
      "（一）使用{{merchandiserName}}商店服务时违反有关法律法规规定的； \n" +
      "（二）违反本协议或本协议补充协议（即本协议第2.2条）约定的。 \n" +
      "\n" +
      "为适应电子商务发展和满足海量用户对高效优质服务的需求，您理解并同意，{{merchandiserName}}可在{{merchandiserName}}商店规则中约定违约认定的程序和标准。 " +
      "如：{{merchandiserName}}可依据您的用户数据与海量用户数据的关系来认定您是否构成违约；您有义务对您的数据异常现象进行充分举证和合理解释，否则将被认定为违约。 \n" +
      "\n" +
      "6.2 违约处理措施 \n" +
      "\n" +
      "【信息处理】您在{{merchandiserName}}商店上发布的信息构成违约的，{{merchandiserName}}可根据相应规则立即对相应信息进行删除、屏蔽处理。 \n" +
      "【行为限制】您在{{merchandiserName}}商店上实施的行为，或虽未在{{merchandiserName}}商店上实施但对{{merchandiserName}}商店及其用户产生影响的行为构成违约的， " +
      "{{merchandiserName}}可依据相应规则中止向您提供部分或全部服务。如您的行为构成根本违约的，{{merchandiserName}}可查封您的账户，终止向您提供服务。 \n" +
      "【处理结果公示】{{merchandiserName}}可将对您上述违约行为处理措施信息以及其他经国家行政或司法机关生效法律文书确认的违法信息在{{merchandiserName}}商店上予以公示。 \n" +
      "\n" +
      "6.3 赔偿责任 \n" +
      "\n" +
      "如您的行为使{{merchandiserName}}及其公司遭受损失（包括自身的直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失）， " +
      "您应赔偿{{merchandiserName}}及/或其关联公司的上述全部损失。 \n" +
      "如您的行为使{{merchandiserName}}及/或其关联公司遭受第三人主张权利，{{merchandiserName}}及/或其关联公司可在对第三人承担金钱给付等义务后就全部损失向您追偿。 \n" +
      "\n" +
      "6.4 特别约定 \n" +
      "\n" +
      "【商业贿赂】如您向{{merchandiserName}}及/或其关联公司的雇员或顾问等提供实物、现金、现金等价物、劳务、旅游等价值明显超出正常商务洽谈范畴的利益，则可视为您存在商业贿赂行为。 " +
      "发生上述情形的，{{merchandiserName}}可立即终止与您的所有合作并向您收取违约金及/或赔偿金，该等金额以{{merchandiserName}}因您的贿赂行为而遭受的经济损失和商誉损失作为计算依据。 \n" +
      "\n" +
      "七、 协议的变更 \n" +
      "\n" +
      "{{merchandiserName}}可根据国家法律法规变化及维护交易秩序、保护消费者权益需要，不时修改本协议、补充协议，变更后的协议、补充协议（下称“变更事项”）将通过法定程序并以本协议第八条约定的方式通知您。 " +
      "如您不同意变更事项，您有权于变更事项确定的生效日前联系{{merchandiserName}}反馈意见。如反馈意见得以采纳，{{merchandiserName}}将酌情调整变更事项。 \n" +
      "如您对已生效的变更事项仍不同意的，您应当于变更事项确定的生效之日起停止使用{{merchandiserName}}商店服务，变更事项对您不产生效力；如您在变更事项生效后仍继续使用{{merchandiserName}}商店服务， " +
      "则视为您同意已生效的变更事项。 \n" +
      "\n" +
      "八、 通知 \n" +
      "\n" +
      "8.1有效联系方式 \n" +
      "\n" +
      "您在注册成为{{merchandiserName}}商店用户，并接受{{merchandiserName}}商店服务时，  " +
      "您应该向{{merchandiserName}}提供真实有效的联系方式（包括您的电子邮件地址、联系电话、联系地址等），对于联系方式发生变更的，您有义务及时更新有关信息，并保持可被联系的状态。 \n" +
      "您在注册{{merchandiserName}}商店用户时生成的用于登陆{{merchandiserName}}商店接收站内信、系统消息和阿里旺旺即时信息的会员账号（包括子账号），也作为您的有效联系方式。 \n" +
      "{{merchandiserName}}将向您的上述联系方式的其中之一或其中若干向您送达各类通知，而此类通知的内容可能对您的权利义务产生重大的有利或不利影响，请您务必及时关注。 \n" +
      "您有权通过您注册时填写的手机号码或者电子邮箱获取您感兴趣的商品广告信息、促销优惠等商业性信息；您如果不愿意接收此类信息，您有权通过{{merchandiserName}}提供的相应的退订功能进行退订。 \n" +
      "\n" +
      "8.2 通知的送达 \n" +
      "\n" +
      "{{merchandiserName}}通过上述联系方式向您发出通知，其中以电子的方式发出的书面通知， " +
      "包括但不限于在{{merchandiserName}}商店公告，向您提供的联系电话发送手机短信，向您提供的电子邮件地址发送电子邮件， " +
      "向您的账号发送、系统消息以及站内信信息，在发送成功后即视为送达，按照提供联系地址交邮后的第五个自然日即视为送达。 \n" +
      "对于在{{merchandiserName}}商店上因交易活动引起的任何纠纷，您同意司法机关（包括但不限于人民法院）可以通过手机短信、电子邮件或邮寄方式向您送达法律文书（包括但不限于诉讼文书）。 " +
      "您指定接收法律文书的手机号码、电子邮箱等联系方式为您在{{merchandiserName}}商店注册、更新时提供的手机号码、 " +
      "电子邮箱联系方式以及在注册{{merchandiserName}}用户时生成，司法机关向上述联系方式发出法律文书即视为送达。您指定的邮寄地址为您的法定联系地址或您提供的有效联系地址。 \n" +
      "您同意司法机关可采取以上一种或多种送达方式向您达法律文书，司法机关采取多种方式向您送达法律文书，送达时间以上述送达方式中最先送达的为准。 \n" +
      "您同意上述送达方式适用于各个司法程序阶段。如进入诉讼程序的，包括但不限于一审、二审、再审、执行以及督促程序等。 \n" +
      "你应当保证所提供的联系方式是准确、有效的，并进行实时更新。如果因提供的联系方式不确切，或不及时告知变更后的联系方式，使法律文书无法送达或未及时送达，由您自行承担由此可能产生的法律后果。 \n" +
      "\n" +
      "九、 协议的终止 \n" +
      "\n" +
      "9.1 终止的情形 \n" +
      "\n" +
      "【用户发起的终止】您有权通过以下任一方式终止本协议： \n" +
      "\n" +
      "（一）在满足{{merchandiserName}}商店公示的账户注销条件时您通过网站自助服务注销您的账户的； \n" +
      "（二）变更事项生效前您停止使用并明示不愿接受变更事项的； \n" +
      "（三）您明示不愿继续使用{{merchandiserName}}商店服务，且符合{{merchandiserName}}商店终止条件的。 \n" +
      "\n" +
      "【{{merchandiserName}}发起的终止】出现以下情况时，{{merchandiserName}}可以本协议第八条的所列的方式通知您终止本协议： \n" +
      "（一）您违反本协议约定，{{merchandiserName}}依据违约条款终止本协议的； \n" +
      "（二）您盗用他人账户、发布违禁信息、骗取他人财物、售假、扰乱市场秩序、采取不正当手段谋利等行为，{{merchandiserName}}依据{{merchandiserName}}商店规则对您的账户予以查封的； \n" +
      "（三）除上述情形外，因您多次违反{{merchandiserName}}商店规则相关规定且情节严重，{{merchandiserName}}依据{{merchandiserName}}商店规则对您的账户予以查封的； \n" +
      "（四）您的账户被{{merchandiserName}}依据本协议回收的； \n" +
      "（五）其它应当终止服务的情况。 \n" +
      "\n" +
      "9.2 协议终止后的处理 \n" +
      "【用户信息披露】本协议终止后，除法律有明确规定外，{{merchandiserName}}无义务向您或您指定的第三方披露您账户中的任何信息。 " +
      "【{{merchandiserName}}权利】本协议终止后，{{merchandiserName}}仍享有下列权利： \n" +
      "（一）继续保存您留存于{{merchandiserName}}商店的本协议第五条所列的各类信息； \n" +
      "（二）对于您过往的违约行为，{{merchandiserName}}仍可依据本协议向您追究违约责任。 \n" +
      "【交易处理】本协议终止后，对于您在本协议存续期间产生的交易订单，{{merchandiserName}}可通知交易相对方并根据交易相对方的意愿决定是否关闭该等交易订单； " +
      "如交易相对方要求继续履行的，则您应当就该等交易订单继续履行本协议及交易订单的约定，并承担因此产生的任何损失或增加的任何费用。 \n" +
      "\n" +
      "十、 法律适用、管辖与其他 \n" +
      "\n" +
      "【法律适用】本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律；如法律无相关规定的，参照商业惯例及/或行业惯例。 \n" +
      "【管辖】您因使用{{merchandiserName}}商店服务所产生及与{{merchandiserName}}商店服务有关的争议， " +
      "由{{merchandiserName}}与您协商解决。协商不成时，任何一方均可向被告所在地有管辖权的人民法院提起诉讼。 \n" +
      "【可分性】本协议任一条款被视为废止、无效或不可执行，该条应视为可分的且并不影响本协议其余条款的有效性及可执行性。\n",

  privacyTerms:
      "隐私权政策 \n\n" +
      "最近更新日期：{{currentDate}} \n\n" +
      "提示条款 \n" +
      "您的信任对我们非常重要，我们深知个人信息对您的重要性，我们将按法律法规要求，采取相应安全保护措施，尽力保护您的个人信息安全可控。鉴于此，{{merchandiserName}}商店服务提供者（或简称“我们”）制定本《隐私权政策》（下称“本政策 /本隐私权政策”）并提醒您： \n" +
      "本政策适用于{{merchandiserName}}商店提供的所有产品和服务。如我们及关联公司（范围详见定义部分）的产品或服务中使用了{{merchandiserName}}商店提供的产品或服务（例如使用{{merchandiserName}}商店账户登录）但未设独立隐私权政策的，则本政策同样适用于该部分产品或服务。 \n" +
      "需要特别说明的是，本政策不适用于其他第三方向您提供的服务，也不适用于{{merchandiserName}}商店中已另行独立设置隐私权政策的产品或服务。例如{{merchandiserName}}商店上的商品介绍员依托{{merchandiserName}}商店向您提供服务时，您向卖家提供的个人信息不适用本政策。 \n" +
      "在使用{{merchandiserName}}商店各项产品或服务前，请您务必仔细阅读并透彻理解本政策，您应重点阅读，在确认充分理解并同意后再开始使用。如对本政策内容有任何疑问、意见或建议，您可通过{{merchandiserName}}商店提供的各种联系方式与我们联系。 \n" +
      "\n" +
      "第一部分 定义 \n" +
      "{{merchandiserName}}商店：指{{merchandiserName}}商店及{{merchandiserName}}客户端包括微信小程序，网站，安卓/苹果手机应用。 \n" +
      "{{merchandiserName}}商店服务提供者：指{{merchandiserName}}商店的互联网信息及软件技术服务提供者优码网络科技（威海）有限公司。 \n" +
      "个人信息：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。 \n" +
      "个人敏感信息：指包括身份证件号码、个人生物识别信息、银行账号、财产信息、行踪轨迹、交易信息、14岁以下（含）儿童信息等的个人信息（我们将在本隐私权政策中对具体个人敏感信息以粗体进行显著标识）。 \n" +
      "个人信息删除：指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。 \n" +
      "儿童：指不满十四周岁的未成年人。 \n" +
      "\n" +
      "第二部分  隐私权政策 \n" +
      "\n" +
      "本隐私权政策部分将帮助您了解以下内容： \n" +
      "一、我们如何收集和使用您的信息 \n" +
      "二、我们如何使用Cookie和同类技术 \n" +
      "三、我们如何共享、转让、公开披露您的信息 \n" +
      "四、我们如何保护您的信息 \n" +
      "五、您如何管理您的信息 \n" +
      "六、我们如何处理未成年人的信息 \n" +
      "七、您的信息如何在全球范围转移 \n" +
      "八、本隐私权政策如何更新 \n" +
      "九、如何联系我们 \n" +
      "\n" +
      "一、 我们如何收集和使用您的信息 \n" +
      "\n" +
      "\n" +
      "在您使用我们的产品及/或服务时，我们需要/可能需要收集和使用的您的个人信息包括如下两种： \n" +
      "1、为实现向您提供我们产品及/或服务的基本功能，您须授权我们收集、使用的必要的信息。如您拒绝提供相应信息，您将无法正常使用我们的产品及/或服务； \n" +
      "2、为实现向您提供我们产品及/或服务的附加功能，您可选择授权我们收集、使用的信息。如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，但并不会影响您正常使用我们产品及/或服务的基本功能。 \n" +
      "您理解并同意： \n" +
      "1、我们致力于打造多样的产品和服务以满足您的需求。因我们向您提供的产品和服务种类众多，且不同用户选择使用的具体产品/服务范围存在差异，相应的，基本/附加功能及收集使用的个人信息类型、范围等会有所区别，请以具体的产品/服务功能为准； \n" +
      "2、为给您带来更好的产品和服务体验，我们在持续努力改进我们的技术，随之我们可能会不时推出新的或优化后的功能，可能需要收集、使用新的个人信息或变更个人信息使用目的或方式。对此，我们将通过更新本政策、弹窗、页面提示等方式另行向您说明对应信息的收集目的、范围及使用方式，并为您提供自主选择同意的方式，且在征得您明示同意后收集、使用。在此过程中，如果您有任何疑问、意见或建议的，您可通过{{merchandiserName}}商店提供的各种联系方式与我们联系，我们会尽快为您作出解答。 \n" +
      "\n" +
      "我们会为实现本政策下述的各项功能，收集和使用您的个人信息： \n" +
      "成为我们的会员 \n" +
      "1、基础会员服务 \n" +
      "我们通过{{merchandiserName}}商店账户为您提供基础会员服务。为注册成为会员并使用我们的会员服务，您需要提供手机号码、允许使用您的微信信息用于创建{{merchandiserName}}商店账户。如果您仅需使用浏览、搜索服务，您不需要注册成为我们的会员及提供上述信息。 \n" +
      "\n" +
      "我们会根据您的会员账户使用情况及商店设置的会员权限，提供功能规则确定您当前的会员权限，并为您提供相应会员权限所对应的基本权益。 \n" +
      "   \n" +
      "2、附加会员服务 \n" +
      "\n" +
      "经销商权益：如果您申请经销商，则需要提供真实姓名、微信ID、银行信息、身份证号、手机号，且昵称、头像等非注册必须的个人资料，我们为您提供结算服务及识别身份。其中，为保证交易辨识度，您的昵称、头像将公开显示。 \n" +
      "实人认证：您根据认证要求提供相应的身份信息（身份证）、以完成实人认证。  \n" +
      "授权登录：我们可能经您同意后向第三方共享您的账户信息（头像、昵称及其他页面提示的信息），使您可以便捷地实现第三方账户的注册或登录。此外，我们可能会根据您的授权从第三方处获取您的第三方账户信息，并与您的{{merchandiserName}}商店账户进行绑定，使您可通过第三方账户直接登录、使用我们的产品及/或服务。我们将在您授权同意的范围内使用您的相关信息。 \n" +
      "3、账户信息展示：如果您已拥有{{merchandiserName}}商店账户，我们可能会在{{merchandiserName}}商店服务中显示您的上述个人信息，以及您在{{merchandiserName}}商店上或与{{merchandiserName}}商店账户相关联的产品和服务中执行的操作（您可通过{{merchandiserName}}商店账户在我们提供的{{merchandiserName}}商店/手机{{merchandiserName}}首页、“我的”链接入口或其他产品/服务入口使用我们及/或关联公司提供的产品或服务），包括通过{{merchandiserName}}商店账户集中展示您的个人资料、优惠权益、交易订单。我们会尊重您对{{merchandiserName}}商店服务和{{merchandiserName}}商店账户设置所做的选择。 \n" +
      "\n" +
      "供商品或服务信息展示 \n" +
      "在您使用我们服务过程中，为识别账号异常状态、了解产品适配性、向您提供更契合您需求的页面展示和搜索结果，我们可能会自动收集您的使用情况并存储为网络日志信息，包括： \n" +
      "设备信息：我们会根据您在软件安装及/或使用中的具体操作，接收并记录您所使用的设备相关信息（包括设备型号、操作系统版本、设备设置、唯一设备标识符、设备环境等软硬件特征信息）、设备所在位置相关信息（包括您授权的GPS位置以及WLAN接入点、蓝牙和基站等传感器信息）。 \n" +
      "服务日志信息：当您使用我们的网站或客户端提供的产品或服务时，我们会自动收集您对我们服务的详细使用情况，作为服务日志保存，包括浏览、点击查看、搜索查询、收藏、添加至购物车、交易、售后、关注分享信息、发布信息，以及IP地址、浏览器类型、电信运营商、使用语言、访问日期和时间。 \n" +
      "请注意，单独的设备信息、服务日志信息是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将这类信息做匿名化、去标识化处理。 \n" +
      "为向您提供更便捷、更符合您个性化需求的信息展示、搜索及推送服务，我们会根据您的设备信息和服务日志信息，提取您的偏好特征，并基于特征标签产出间接人群画像，用于展示、推送信息和可能的商业广告。 \n" +
      "如果您不想接受我们给您发送的商业广告，您可通过短信提示回复退订或我们提供的其他方式进行退订或关闭。在您使用我们提供的站内搜索服务时，我们也同时提供了不针对您个人特征的选项。 \n" +
      "此外，我们也会为了不断改进和优化上述的功能来使用您的上述信息。 \n" +
      "\n" +
      "供收藏、加购、关注与分享功能 \n" +
      "在您浏览我们网站或客户端的过程中，您可以选择对感兴趣的商品及/或服务进行收藏、添加至购物车、与您感兴趣的商家/品牌, 通过我们提供的功能组件向其他第三方分享信息。在您使用上述功能的过程中，我们会收集包括您的收藏及添加购物车的记录、关注关系、分享历史在内的服务日志信息用于实现上述功能及其他我们明确告知的目的。 \n" +
      "\n" +
      "成下单及订单管理 \n" +
      "当您在我们的产品及/或服务中订购具体商品及/或服务时，我们会通过系统为您生成购买该商品及/或服务的订单。在下单过程中,您需至少提供您的收货人姓名、收货地址、收货人联系电话，身份证号码（仅限适用于通关申报, 以未成年人身份证不能通关申报），同时该订单中会载明您所购买的商品及/或服务信息、具体订单号、订单创建时间、您应支付的金额，我们收集这些信息是为了帮助您顺利完成交易、保障您的交易安全、查询订单信息、提供客服与售后服务及其他我们明确告知的目的。 \n" +
      "您可以通过{{merchandiserName}}商店为其他人订购商品及/或服务，您需要提供该实际订购人的前述个人信息，若其中涉及儿童个人信息的，您需在提供前征得对应儿童监护人的同意。 \n" +
      "为便于您了解查询订单信息并对订单信息进行管理，我们会收集您在使用我们服务过程中产生的订单信息用于向您展示及便于您对订单进行管理。  \n" +
      "您可额外填写/选择包括其他联系电话、收货时间在内的更多附加信息以确保商品或服务的准确送达。 \n" +
      "\n" +
      "完成支付  \n" +
      "为完成订单支付，您需要提供微信账户并选择付款方式，我们会将您的{{merchandiserName}}商店账户会员名、对应的微信支付账户会员名、订单支付相关信息及其他反洗钱法律要求的必要信息与微信公司共享。微信支付为您提供支付服务的，微信支付信息，必要信息与您选择的相应金融机构与海关共享。 \n" +
      "您可以请求其他人为您付款，那么您需要提供代付人的微信账户及/或手机号码。 \n" +
      "为使我们及时获悉并确认您的支付进度及状态，为您提供售后与争议解决服务，您同意我们可自您所选择的交易对象、微信支付公司或您选择的其他金融机构处收集与支付进度相关信息。 \n" +
      "\n" +
      "您完成商品或服务的交付 \n" +
      "为保证您购买的商品及/或服务能够顺利、安全、准确送达，我们会向为{{merchandiserName}}商店提供物流信息系统和技术服务的圆通快递有限公司等物流商店披露订单相关配送信息，并由其根据商品及/或服务提供主体的指定向相应的物流配送主体同步相关配送信息。您知晓并同意相应物流配送主体不可避免地获知及使用您的配送信息，用于完成交付目的。 \n" +
      "为使我们及时获悉并确认交付进度及状态，向您提供售后与争议解决服务，您同意我们可自物流相关服务主体处收集与交付进度相关信息。 \n" +
      "\n" +
      "争议处理 \n" +
      "当您与我们联系或提出售中售后、争议纠纷处理申请时，为了保障您的账户及系统安全，我们需要您提供必要的个人信息以核验您的会员身份。 \n" +
      "为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会保存您与我们的通信/通话记录及相关内容（包括账号信息、订单信息、您为了证明相关事实提供的其他信息，或您留下的联系方式信息），如果您针对具体订单进行咨询、投诉或提供建议的，我们会使用您的账号信息和订单信息。 \n" +
      "为了提供服务及改进服务质量的合理需要，我们还可能使用的您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息。 \n" +
      "您提供评论、问答等信息公开发布功能 \n" +
      "您可通过我们为您提供的评论、问答、论坛、晒单分享、直播及其他信息发布功能公开发布信息，包括作为会员可发布图文/视频/直播内容、发表评价及问答内容，以及作为卖家发布商品及/或服务相关信息、店铺相关信息。 \n" +
      "我们可能会根据您的会员类型及网络日志信息判断您是否可享受对应功能权限（例如只有曾购买过某商品的会员才可通过问大家对其他会员针对该商品的提问做出回答）。 \n" +
      "请注意，您公开发布的信息中可能会涉及您或他人的个人信息甚至个人敏感信息，如您在评价时选择上传包含个人信息的图片。请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。若您公开发布的信息中涉及儿童个人信息的，您需在发布前征得对应儿童监护人的同意。 \n" +
      "\n" +
      "您提供安全保障 \n" +
      "为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或{{merchandiserName}}商店相关协议规则的情况，我们可能使用或整合您的会员信息、交易信息、设备信息、服务日志信息以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。 \n" +
      "\n" +
      "您提供其他附加服务 \n" +
      "为向您提供更便捷、更优质、个性化的产品及/或服务，努力提升您的体验，我们在向您提供的以下附加服务中可能会收集和使用您的个人信息。如果您不提供这些信息，不会影响您使用{{merchandiserName}}商店的浏览、搜索、但您可能无法获得这些附加服务给您带来的用户体验。这些附加服务包括： \n" +
      "1、基于位置信息的个性化推荐服务：我们会在您开启位置权限后访问获取您的位置信息，根据您的位置信息为您提供更契合您需求的页面展示、产品及/或服务，比如向您推荐附近的优惠信息。 \n" +
      "2、基于相机/摄像头的附加服务：您可在开启相机/摄像头权限后使用该功能进行扫码用于登录、购物、领取优惠信息，拍摄照片或视频用于评价、分享，以及特定场景下经您授权的人脸识别等功能。当您使用该附加功能进行人脸识别时我们会收集您的面部特征，且严格在经您授权同意的范围内使用，未来如我们拟使用您的面部信息为您提供其他产品及/或服务功能的，我们会再次与您确认。 \n" +
      "3、基于相册（图片库/视频库）的图片/视频访问及上传的附加服务：您可在开启相册权限后使用该功能上传您的照片/图片/视频，以实现更换头像、发表评论/分享、拍照购物或与客服沟通提供证明等功能。我们可能会通过您所上传的照片/图片来识别您需要购买的商品或服务，或使用包含您所上传照片或图片的评论信息。 \n" +
      "4、基于麦克风的语音技术相关附加服务：您可在开启麦克风权限后使用麦克风实现语音购物功能，或与客服联系或与客服机器人实现语音交互，在这些功能中我们会收集您的录音内容以识别您的购物需求，或响应您的客服及争议处理等需求。请您知晓，即使您已同意开启麦克风权限，我们也仅会在您主动点击客户端内麦克风图标或录制视频时通过麦克风获取语音信息。 \n" +
      "5、基于通讯录信息的附加服务：我们将在您开启通讯录权限后收集您的通讯录信息使您在购物时可以更便利地取用您通讯录内的联系人信息，无需再手动输入，包括您可以直接为通讯录中的联系人手机号进行充值；此外，为提升您在使用我们产品及/或服务过程中的社交互动分享乐趣，与您认识的人分享购物体验，在经您同意的前提下，我们也可能对您联系人的姓名和电话号码进行加密收集，帮助您判断您的通讯录联系人是否同为我们的会员进而在{{merchandiserName}}商店为你们的交流建立联系，同时，会员可选择开启或关闭好友隐私权限决定自己是否可被其他会员通过手机号码搜索、联系。 \n" +
      "\n" +
      "您理解并同意，上述附加服务可能需要您在您的设备中开启您的位置信息 （地理位置）、摄像头（相机）、相册（图片库）、麦克风（语音）、通讯录及/或日历的访问权限，以实现这些权限所涉及信息的收集和使用。您可在您的设备设置或我们客户端“我的{{merchandiserName}}-设置-隐私—系统权限”中逐项查看上述权限的状态，并可自行决定这些权限随时的开启或关闭。请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。 \n" +
      "\n" +
      "1、若你提供的信息中含有其他用户的个人信息，在向{{merchandiserName}}商店提供这些个人信息之前，您需确保您已经取得合法的授权。若其中涉及儿童个人信息的，您需在发布前取得对应儿童监护人的同意，前述情形下监护人有权通过本政策第九条的途径联系我们，要求更正或删除涉及儿童个人信息的内容。 \n" +
      "2、若我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，或者我们主动从第三方处获取您的个人信息，均会事先获得您的同意。 \n" +
      "若我们从第三方处间接获取您的信息的，我们会在收集前明确以书面形式要求该第三方在已依法取得您同意后收集个人信息，并向您告知共享的信息内容，且涉及敏感信息的在提供给我们使用前需经过您的明确确认，要求第三方对个人信息来源的合法性和合规性作出承诺，如第三方有违反行为的，我们会明确要求对方承担相应法律责任； \n" +
      "3、征得授权同意的例外 \n" +
      "您充分理解并同意，我们在以下情况下收集、使用您的个人信息无需您的授权同意，且我们可能不会响应您提出的更正/修改、删除、注销、撤回同意、索取信息的请求： \n" +
      "全、国防安全有关的； \n" +
      "全、公共卫生、重大公共利益有关的； \n" +
      "查、起诉、审判和判决执行等司法或行政执法有关的； \n" +
      "您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的； \n" +
      "社会公众公开的个人信息； \n" +
      "开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。 \n" +
      "签订和履行相关协议或其他书面文件所必需的； \n" +
      "所提供的产品及/或服务的安全稳定运行所必需的，例如发现、处置产品及/或服务的故障； \n" +
      "新闻报道所必需的； \n" +
      "究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的； \n" +
      "规规定的其他情形。 \n" +
      "请知悉，根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，或我们可能会对收集的信息进行去标识化地研究、统计分析和预测，用于改善{{merchandiserName}}商店的内容和布局，为商业决策提供产品或服务支撑，以及改进我们的产品和服务（包括使用匿名数据进行机器学习或模型算法训练），则此类处理后数据的使用无需另行向您通知并征得您的同意。 \n" +
      "4、如我们停止运营{{merchandiserName}}商店产品或服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对我们所持有的与已关停业务相关的个人信息进行删除或匿名化处理。涉及儿童个人信息的，我们会并将停止运营的通知及时告知儿童监护人。 \n" +
      "\n" +
      "\n" +
      "二、我们如何使用Cookie和同类技术 \n" +
      "\n" +
      "Cookie\n" +
      "为确保网站正常运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，我们会在您的计算机或移动设备上存储Cookie、Flash Cookie，或浏览器（或关联应用程序）提供的其他通常包含标识符、站点名称以及一些号码和字符的本地存储（统称“Cookie”）。借助于 Cookie，网站能够存储您的偏好或购物车内的商品等数据。 \n" +
      "如果您的浏览器或浏览器附加服务允许，您可修改对Cookie的接受程度或拒绝我们的Cookie。有关详情，请参见 AboutCookies.org。但如果您这么做，在某些情况下可能会影响您安全访问我们的网站，且可能需要在每一次访问我们的网站时更改用户设置。 \n" +
      "\n" +
      "Cookie同类技术 \n" +
      "\n" +
      "除 Cookie外，我们还会在网站上使用网站信标、像素标签、ETag等其他同类技术。 \n" +
      "例如，我们向您发送的电子邮件可能含有链接至我们网站内容的地址链接，如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好，以便于我们主动改善客户服务体验。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。 \n" +
      "ETag（实体标签）是在互联网浏览器与互联网服务器之间背后传送的HTTP协议标头，可代替Cookie。ETag可以帮助我们避免不必要的服务器负载，提高服务效率，节省资源、能源，同时，我们可能通过ETag来记录您的身份，以便我们可以更深入地了解和改善我们的产品或服务。大多数浏览器均为用户提供了清除浏览器缓存数据的功能，您可以在浏览器设置功能中进行相应的数据清除操作。但请注意，如果停用ETag，您可能无法享受相对更佳的产品或服务体验。 \n" +
      "\n" +
      "\n" +
      "三、我们如何共享、转让、公开披露您的信息 \n" +
      "\n" +
      "们不会与{{merchandiserName}}商店服务提供者以外的公司、组织和个人共享您的个人信息，但以下情况除外： \n" +
      "1、在法定情形下的共享：我们可能会根据法律法规规定、诉讼、争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。 \n" +
      "2、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。 \n" +
      "3、在您主动选择情况下共享：您通过{{merchandiserName}}商店购买商品或服务，我们会根据您的选择，将您的订单信息中与交易有关的必要信息共享给相关商品或服务的提供者，以实现您的交易及售后服务需求。 \n" +
      "4、与关联公司间共享：为便于我们基于{{merchandiserName}}商店账户向您提供产品和服务，推荐您可能感兴趣的信息，识别会员账号异常，保护{{merchandiserName}}商店关联公司或其他用户或公众的人身财产安全免遭侵害，您的个人信息可能会与我们的关联公司和/或其指定的服务提供商共享。我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束，如果我们共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。 \n" +
      "5、与授权合作伙伴共享：我们可能委托授权合作伙伴为您提供某些服务或代表我们履行职能，我们仅会出于本隐私权政策声明的合法、正当、必要、特定、明确的目的共享您的信息，授权合作伙伴只能接触到其履行职责所需信息，且不得将此信息用于其他任何目的。对于涉及儿童个人信息的，我们不允许合作伙伴进行转委托。 \n" +
      "目前，我们的授权合作伙伴包括以下类型： \n" +
      "析服务类的授权合作伙伴。除非得到您的许可，否则我们不会将您的个人身份信息与提供广告、分析服务的合作伙伴共享。我们会委托这些合作伙伴处理与广告覆盖面和有效性相关的信息，但不会提供您的个人身份信息，或者我们将这些信息进行去标识化处理，以便它不会识别您个人。这类合作伙伴可能将上述信息与他们合法获取的其他数据相结合，以执行我们委托的广告服务或决策建议。 \n" +
      "服务提供商和其他合作伙伴。我们将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括受我们委托提供的技术基础设施服务、分析我们服务的使用方式、衡量广告和服务的有效性、提供客户服务、支付便利或进行学术研究和调查。 \n" +
      "我们会对授权合作伙伴获取有关信息的应用程序接口（API）、软件工具开发包（SDK）进行严格的安全检测，并与授权合作伙伴约定严格的数据保护措施，令其按照我们的委托目的、服务说明、本隐私权政策以及其他任何相关的保密和安全措施来处理个人信息。 \n" +
      "\n" +
      "我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外： \n" +
      "1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息； \n" +
      "2、在{{merchandiserName}}商店服务提供者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。 \n" +
      "\n" +
      "我们仅会在以下情况下，公开披露您的个人信息： \n" +
      "1、获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息； \n" +
      "2、如果我们确定您出现违反法律法规或严重违反{{merchandiserName}}商店相关协议及规则的情况，或为保护{{merchandiserName}}商店用户或公众的人身财产安全免遭侵害，我们可能依据法律法规或征得您同意的情况下披露关于您的个人信息，包括相关违规行为以及{{merchandiserName}}商店已对您采取的措施。例如，若您因出售假冒商品而严重违反{{merchandiserName}}规则，我们可能会公开披露您的店铺认证主体信息与违规情况。 \n" +
      "\n" +
      "共享、转让、公开披露个人信息时事先征得授权同意的例外 \n" +
      "以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意： \n" +
      "1、与国家安全、国防安全有关的； \n" +
      "2、与公共安全、公共卫生、重大公共利益有关的； \n" +
      "3、与犯罪侦查、起诉、审判和判决执行等司法或行政执法有关的； \n" +
      "4、出于维护您或其他个人的生命、财产等重大合法权益但又很难得到本人同意的； \n" +
      "5、您自行向社会公众公开的个人信息； \n" +
      "6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开等渠道。 \n" +
      "请知悉，根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，则此类处理后数据的共享、转让、公开披露无需另行向您通知并征得您的同意。 \n" +
      "\n" +
      "\n" +
      "四、我们如何保护您的信息 \n" +
      "（一）我们已采取符合业界标准、合理可行的安全防护措施保护您的信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，在您的浏览器与服务器之间交换数据时受 SSL协议加密保护；我们同时对{{merchandiserName}}商店网站提供HTTPS协议安全浏览方式；我们会使用加密技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息；以及我们会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。 \n" +
      "\n" +
      "（三）我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非法律有强制的存留要求，例如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。而我们判断前述期限的标准包括： \n" +
      "1、完成与您相关的交易目的、维护相应交易及业务记录、应对您可能的查询或投诉； \n" +
      "2、保证我们为您提供服务的安全和质量； \n" +
      "3、您是否同意更长的留存期间； \n" +
      "4、是否存在保留期限的其他特别约定。 \n" +
      "在您的个人信息超出保留期间后，我们会根据适用法律的要求删除您的个人信息，或使其匿名化处理。 \n" +
      "非绝对安全的环境，使用{{merchandiserName}}商店服务时，我们强烈建议您不要使用非{{merchandiserName}}商店推荐的通信方式发送您的信息。您可以通过我们的服务建立联系和相互分享。当您通过我们的服务创建交流、交易或分享时，您可以自主选择沟通、交易或分享的对象，作为能够看到您的交易内容、联络方式、交流信息或分享内容等相关信息的第三方。 \n" +
      "在使用{{merchandiserName}}商店服务进行网上交易时，您不可避免地要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或联系地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息尤其是您的账户或密码发生泄露，请您立即联络{{merchandiserName}}商店客服，以便我们根据您的申请采取相应措施。 \n" +
      "请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息。请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。 \n" +
      "请使用复杂密码，协助我们保证您的账号安全。我们将尽力保障您发送给我们的任何信息的安全性。如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。 \n" +
      "定期更新并公开安全风险、个人信息安全影响评估报告等有关内容，您可通过{{merchandiserName}}商店公告方式获得。 \n" +
      "生个人信息安全事件后，我们将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。 \n" +
      "定儿童个人信息安全事件应急预案，定期组织内部相关人员进行应急响应培训和应急演练，使其掌握岗位职责和应急处置策略和规程。在不幸发生儿童个人信息安全事件后，我们将按照法律法规的要求，及时向儿童及其监护人告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、儿童及其监护人可自主防范和降低风险的建议、对儿童及其监护人的补救措施等。我们将及时将事件相关情况以APP推送通知、发送邮件/短消息等方式告知儿童及其监护人。难以逐一告知时，我们会采取合理、有效的方式发布相关警示信息。同时，我们还将按照监管部门要求，主动上报儿童个人信息安全事件的处置情况。若儿童及其监护人的合法权益受损，我们将依法承担相应的法律责任。 \n" +
      "同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。 \n" +
      "\n" +
      "\n" +
      "五、您如何管理您的信息 \n" +
      "\n" +
      "您可以通过以下方式访问及管理您的信息： \n" +
      "\n" +
      "正和补充您的信息 \n" +
      "您有权查询、更正或补充您的信息。您可以通过以下方式自行进行： \n" +
      "1、登录{{merchandiserName}}微信小程序，进入“我的{{merchandiserName}}”，点击个人账户设置后查询、更正个人资料及个人账户相关信息； \n" +
      "\n" +
      "信息 \n" +
      "\n" +
      "在以下情形中，您可以向我们提出删除个人信息的请求： \n" +
      "1、如果我们处理个人信息的行为违反法律法规； \n" +
      "2、如果我们收集、使用您的个人信息，却未征得您的明确同意； \n" +
      "3、如果我们处理个人信息的行为严重违反了与您的约定； \n" +
      "4、如果您不再使用我们的产品或服务，或您主动注销了账号； \n" +
      "5、如果我们永久不再为您提供产品或服务。 \n" +
      "若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，并要求其及时删除（除非法律法规另有规定，或这些主体已独立获得您的授权）。 \n" +
      "当您或我们协助您删除相关信息后，因为适用的法律和安全技术，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并将其与任何进一步处理隔离，直到备份可以清除或实现匿名。 \n" +
      "\n" +
      "权同意的范围 \n" +
      "每个业务功能需要一些基本的个人信息才能得以完成（见本隐私权政策“第一部分”）。除此之外，您可以在“我的{{merchandiserName}}-账号管理”中设置或与{{merchandiserName}}商店客服联系或改变您的智能移动设备的设置等方式给予或收回您的授权同意（例如，您可以在手机{{merchandiserName}}APP“我的{{merchandiserName}}-设置-隐私”中收回您“通过手机号码找到我”的用户同意授权）。 \n" +
      "当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。 \n" +
      "\n" +
      "账户 \n" +
      "您可以通过公司的联络方式联系我们申请注销您的账户： \n" +
      "在您主动注销账户之后，我们将停止为您提供产品或服务，根据适用法律的要求删除您的个人信息，或使其匿名化处理。 \n" +
      "\n" +
      "系统自动决策 \n" +
      "在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将在不侵害{{merchandiserName}}商店商业秘密或其他用户权益、社会公共利益的前提下提供申诉方法。 \n" +
      "\n" +
      "上述请求 \n" +
      "为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。 \n" +
      "我们将在15天内做出答复。如您不满意，还可以通过{{merchandiserName}}商店客服发起投诉。 \n" +
      "对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将酌情收取一定费用。对于与您的身份不直接关联的信息、无端重复信息，或者需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者不切实际的请求，我们可能会予以拒绝。 \n" +
      "在以下情形中，按照法律法规要求，我们将无法响应您的请求： \n" +
      "1、与国家安全、国防安全有关的； \n" +
      "2、与公共安全、公共卫生、重大公共利益有关的； \n" +
      "3、与犯罪侦查、起诉、审判和执行判决等有关的； \n" +
      "4、有充分证据表明个人信息主体存在主观恶意或滥用权利的； \n" +
      "5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的； \n" +
      "6、涉及商业秘密的。 \n" +
      "\n" +
      "\n" +
      "六、我们如何处理未成年人的信息 \n" +
      "在电子商务活动中我们推定您具有相应的民事行为能力。如您为未成年人，我们要求您请您的父母或其他监护人仔细阅读本隐私权政策，并在征得您的父母或其他监护人同意的前提下使用我们的服务或向我们提供信息。 \n" +
      "如果我们识别出您是儿童用户的，我们将通知您的监护人并要求您的监护人同意儿童个人信息收集使用规则。对于经父母或其他监护人同意使用我们的产品或服务而收集儿童个人信息的情况，我们只会在法律法规允许、父母或其他监护人明确同意或者保护儿童所必要的情况下使用、共享、转让或披露此信息。 \n" +
      "若您是儿童的父母或其他监护人，请您关注您监护的儿童是否是在取得您的授权同意之后使用我们的服务的。如您对您所监护的儿童的个人信息有疑问，请通过第九节中的联系方式与我们联系。 \n" +
      "\n" +
      "\n" +
      "七、您的信息如何在全球范围转移 \n" +
      "我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内，以下情形除外： \n" +
      "1、适用的法律有明确规定； \n" +
      "2、获得您的明确授权； \n" +
      "3、您通过互联网进行跨境交易等个人主动行为。 \n" +
      "针对以上情形，我们会确保依据本隐私权政策对您的个人信息提供足够的保护。 \n" +
      "\n" +
      "\n" +
      "八、本隐私权政策如何更新 \n" +
      "我们的隐私权政策可能变更。 \n" +
      "未经您明确同意，我们不会限制您按照本隐私权政策所应享有的权利。 \n" +
      "对于重大变更，我们还会提供更为显著的通知（包括我们会通过{{merchandiserName}}商店公示的方式进行通知甚至向您提供弹窗提示）。 \n" +
      "本政策所指的重大变更包括但不限于： \n" +
      "1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等； \n" +
      "2、我们在控制权等方面发生重大变化。如并购重组等引起的信息控制者变更等； \n" +
      "3、个人信息共享、转让或公开披露的主要对象发生变化； \n" +
      "4、您参与个人信息处理方面的权利及其行使方式发生重大变化； \n" +
      "5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化； \n" +
      "6、个人信息安全影响评估报告表明存在高风险。 \n" +
      "\n" +
      "\n" +
      "九、如何联系我们 \n" +
      "您可以通过以下方式与我们联系，我们将在15天内回复您的请求： \n" +
      "1、如对本政策内容、儿童个人信息有任何疑问、意见或建议，您可通过{{merchandiserName}}商店客服与我们联系； \n" +
      "2、如发现个人信息可能被泄露，您可以通过{{merchandiserName}}商店客服投诉举报； \n" +
      "\n" +
      "如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案。"
};
