import {adminConfig} from "../../config/admin.config";
import axios from "axios";
const API_BASE_URL = adminConfig.apiServer();

export default class FaqApi {
  static getFaq = (id) => {
    let apiPath = `${API_BASE_URL}/faqs/${id}?projection=webAdmin&`;
    return axios.get(apiPath);
  };

  static getFaqs = (options) => {
    let apiPath = `${API_BASE_URL}/faqs?projection=webAdmin&`;

    if (options) {
      apiPath += Object.keys(options).map(key => {
        return key + "=" + options[key]
      }).join("&");
    }

    return axios.get(apiPath);
  };

  static submitFaq = (faq) => {
    if (faq.id) {
      let apiPath = `${API_BASE_URL}/faqs/${faq.id}?projection=webAdmin`;
      return axios.put(apiPath, faq);
    } else {
      let apiPath = `${API_BASE_URL}/faqs?projection=webAdmin`;
      return axios.post(apiPath, faq);
    }
  };

  static deleteFaq = (id) => {
    let apiPath = `${API_BASE_URL}/faqs/${id}?projection=webAdmin`;
    return axios.delete(apiPath);
  };
}