import React, {useEffect, useState} from "react";
import {Spin, Table, Button, message, Modal, Checkbox} from "antd";
import ClearanceApi from "./ClearanceApi";
import Util from "../../../util/Util";
import intl from "react-intl-universal";
import OrderDeclareDetail from "./OrderDeclareDetail";

const CreateOrderDeclare = (props) => {
  const [updating, setUpdating] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [orderDeclares, setOrderDeclares] = useState([]);
  const [selectedOrderDeclare, setSelectedOrderDeclare] = useState({});
  const [visibleDetailModal, setVisibleDetailModal] = useState(false);
  const [resetDiscountAmount, setResetDiscountAmount] = useState(false);

  useEffect(() => {
    loadOrderDeclares(props.order.id);
  }, [props.order.id]);

  const loadOrderDeclares = (orderId) => {
    ClearanceApi.getOrderDeclares(orderId).then(res => {
      const orderDeclares = res.data;
      const notFailedDeclares = orderDeclares.filter(od => od.status !== "failed");
      let disabled = true;
      if (props.order.currentStatus === "paid" && notFailedDeclares.length === 0) {
        disabled = false;
      }

      setDisabled(disabled);
      setOrderDeclares(orderDeclares);
      if (orderDeclares.length > 0) {
        setSelectedOrderDeclare(orderDeclares[orderDeclares.length - 1]);
      }
    })
  };

  const onSelectOrderDeclare = (orderDeclare, event) => {
    event.stopPropagation();
    setSelectedOrderDeclare(orderDeclare);
    setVisibleDetailModal(true);
  };

  const deleteOrderDeclare = (id, event) => {
    event.stopPropagation();
    if(!window.confirm(intl.get("common.confirm.delete", {name: intl.get("order.clearance.declareInfo")}))) {
      return;
    }
    ClearanceApi.deleteOrderDeclare(id).then(res => {
      message.info(intl.get("common.message.deleted"));
      loadOrderDeclares(props.order.id);
    }).catch(e => {
      console.log("deleteOrderDeclare err:", e);
    }).finally(() => {
      setUpdating(false);
    });
  };

  const callCreateOrderDeclare = () => {
    if(!window.confirm(intl.get("order.clearance.createDeclareInfo.confirm"))) {
      return;
    }
    setUpdating(true);
    ClearanceApi.createOrderDeclare(props.order.id, {resetDiscountAmount: resetDiscountAmount}).then(res => {
      message.info(intl.get("common.message.success"));
      loadOrderDeclares(props.order.id);
    }).catch(e => {
      console.log("sendPaymentDeclare err:", e);
    }).finally(() => {
      setUpdating(false);
    });
  };

  const columns = [{
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  }, {
    title: intl.get("order.clearance.table.date"),
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (text, record) => {
      return (<div>{Util.getChinaLocalTime(record.createdAt)}</div>);
    }
  }, {
    title: intl.get("order.clearance.declare.price"),
    dataIndex: 'salesAmount',
    key: 'salesAmount',
    render: (text, record) => {
      return (
        <div style={{width: 300, fontSize: 11}}>
          <div style={{display: "flex", alignItems: "center", gap: 10}}>
            <div style={{width: 80, textAlign: "right"}}>{intl.get("order.declareOrder.salesAmount")}:</div>
            <div style={{width: 100, textAlign: "right"}}>{Util.currencyFormat(record.salesAmount, "CNY")}</div>
          </div>
          <div style={{display: "flex", alignItems: "center", gap: 10}}>
            <div style={{width: 80, textAlign: "right"}}>{intl.get("order.discountAmount")}:</div>
            <div style={{width: 100, textAlign: "right"}}>{record.discountAmount != 0 ? "-" : ""}{Util.currencyFormat(record.discountAmount, "CNY")}</div>
          </div>
          <div style={{display: "flex", alignItems: "center", gap: 10}}>
            <div style={{width: 80, textAlign: "right"}}>{intl.get("order.deliveryFee")}:</div>
            <div style={{width: 100, textAlign: "right"}}>{Util.currencyFormat(record.deliveryFee, "CNY")}</div>
          </div>
          <div style={{display: "flex", alignItems: "center", gap: 10}}>
            <div style={{width: 80, textAlign: "right"}}>{intl.get("order.taxTotal")}:</div>
            <div style={{width: 100, textAlign: "right"}}>{Util.currencyFormat(record.taxTotal, "CNY")}</div>
          </div>
          <div style={{display: "flex", alignItems: "center", gap: 10}}>
            <div style={{width: 80, textAlign: "right", fontSize: 14, fontWeight: "bold"}}>{intl.get("order.paymentAmount")}:</div>
            <div style={{width: 100, textAlign: "right", fontSize: 14, fontWeight: "bold"}}>{Util.currencyFormat(record.paymentAmount, "CNY")}</div>
          </div>
        </div>
      );
    }
  }, {
    title: intl.get("order.clearance.table.status"),
    dataIndex: 'status',
    key: 'status',
    render: (text, record) =>(
      <div>{Util.getOrderDeclareStatus(text)}</div>
    )
  }, {
    title: intl.get("order.clearance.table.errorMessage"),
    dataIndex: 'errorMessage',
    key: 'errorMessage',
    render: text => {
      return (<div style={{display: "flex", alignItems: "center", fontSize: 11, width: 200, height: 40, overflow: "auto"}}>{text}</div>)
    }
  }, {
    title: '',
    dataIndex: 'id',
    key: 'button',
    render: (text, record) => (
      <Button size="small" onClick={(event) => deleteOrderDeclare(record.id, event)}>{intl.get("common.button.delete")}</Button>
    )
  }];

  return (
    <Spin spinning={updating}>
      <div>
        <div style={{ display: "flex", marginBottom: 10}}>
          <div>
            <div>{intl.get("order.clearance.createDeclareInfo.help")}</div>
            <div>{intl.get("order.clearance.resetDiscountAmount.help")}</div>
          </div>
          <div style={{marginLeft: "auto"}}>
            <Checkbox checked={resetDiscountAmount} onChange={(e) => setResetDiscountAmount(e.target.checked)}>{intl.get("order.clearance.resetDiscountAmount")}</Checkbox>
            <Button type="primary" size="small" disabled={disabled} onClick={callCreateOrderDeclare}>{intl.get("order.clearance.createDeclareInfo")}</Button>
          </div>
        </div>
        <div style={{cursor: "pointer"}}>
          <Table size={'small'}
                 dataSource={orderDeclares}
                 columns={columns}
                 rowKey={'id'}
                 pagination={false}
                 onRow={(record, rowIndex) => {
                   return {
                     onClick: event => {
                       onSelectOrderDeclare(record, event);
                     },
                   }
                 }}
          />
        </div>
      </div>

      {visibleDetailModal && (
        <Modal title={intl.get("order.clearance.declareInfo")}
               visible={true}
               onOk={() => setVisibleDetailModal(false)}
               onCancel={() => setVisibleDetailModal(false)}
               width="70%"
               cancelButtonProps={{ style: { display: 'none' } }}>
          <div>
            <OrderDeclareDetail order={props.order} orderDeclare={selectedOrderDeclare}/>
          </div>
        </Modal>
      )}
    </Spin>
  );
};

export default CreateOrderDeclare;