import React, { useEffect, useState } from 'react';
import LiveApi from "./LiveApi";
import {Button, Divider, Input, Space, Spin, Table} from "antd";
import intl from "react-intl-universal";
import Util from "../../util/Util";

const LiveRoomList = (props) => {
  const [liveRooms, setLiveRooms] = useState([]);
  const [filter, setFilter] = useState({});
  const [nomoreData, setNomoreData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 20,
  });

  // 처음에는 로딩을 하지 않기 때문에 테이블을 나타내지 않는다.
  // 한번이라도 검색 버튼을 클릭하면 이 값은 true로 변경된다.
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
  }, []);

  const prev = () => {
    if (pagination.current === 1) {
      return;
    }
    const paginationState = {...pagination};
    paginationState.current = pagination.current - 1;
    setPagination(paginationState);
    searchLiveRooms(paginationState);
  };

  const next = () => {
    if (nomoreData) {
      return;
    }
    const paginationState = {...pagination};
    paginationState.current = pagination.current + 1;
    setPagination(paginationState);
    searchLiveRooms(paginationState);
  };

  const getLiveRooms = () => {
    const paginationState = {...pagination};
    paginationState.current = 1;
    setPagination(paginationState);
    searchLiveRooms(paginationState);
  };

  const searchLiveRooms = (pagination) => {
    setLoading(true);
    LiveApi.getLiveRooms(getSearchParam(pagination)).then(res => {
      setLiveRooms(res.data);
      setNomoreData(res.data.length === 0);
      setLoaded(true);
    }).finally(() => {
      setLoading(false);
    });
  };

  const getSearchParam = (pagination) => {
    const params = {
      pageSize: pagination.pageSize,
      page: pagination.current,
      ...filter
    };
    return params;
  };

  const columns = [{
    title: 'ID',
    dataIndex: 'roomid',
    key: 'roomid',
  }, {
    title: intl.get("live.room.image"),
    dataIndex: 'share_img',
    key: 'share_img',
    render: (text, record) => (
      <span><img src={text} style={{width: 100, height: 100}} /></span>
    )
  }, {
    title: intl.get("live.room.name"),
    dataIndex: 'name',
    key: 'name'
  }, {
    title: intl.get("live.room.anchor_name"),
    dataIndex: 'anchor_name',
    key: 'anchor_name',
  }, {
    title: intl.get("live.room.time"),
    dataIndex: 'start_time',
    key: 'start_time',
    render: (text, record) => (
      <span>{Util.unixTimeToStr(record.start_time)} ~ {Util.unixTimeToStr(record.end_time)}</span>
    )
  }, {
    title: intl.get("live.room.goods"),
    dataIndex: 'goods',
    key: 'goods',
    render: (text, record) => {
      if (record.goods.length == 0) {
        return (<span></span>)
      }
      const good = record.goods[0];
      return (
        <div>
          <div><img src={good.cover_img} style={{width: 100, height: 100}} /></div>
          <div>{good.name}</div>
          <div>{good.price}</div>
        </div>
      )
    }
  }, {
    title: intl.get("live.room.liveStatus"),
    dataIndex: 'live_status',
    key: 'live_status',
    render: (text, record) => (
      <span>{intl.get("live.room.liveStatus." + text)}</span>
    )
  }];

  if (!props.application?.useLive) {
    return (
      <>
        <div>
          {intl.get("live.cannotUseLive")}
        </div>
      </>
    )
  }

  return (
    <Spin spinning={loading}>
      <div className="page-op-box">
        <div className="filter-box">
          <div style={{marginLeft: "auto"}}>
            <div style={{width: 100}}>
              <Button style={{width: 100}} type="primary" block size="default" onClick={getLiveRooms}>{intl.get("common.button.search")}</Button>
            </div>
          </div>
        </div>
      </div>
      <div style={{marginTop: "10px"}}>
        {loaded && (
          <Table size={'small'}
                 dataSource={liveRooms}
                 columns={columns}
                 rowKey={'roomid'}
                 title={() => "Page:" + pagination.current}
                 pagination={false}
                 // onRow={(record, rowIndex) => {
                 //   return {
                 //     onClick: event => props.onClickUser(record),
                 //   };
                 // }}
          />
        )}
      </div>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 10}}>
        { loaded && (
          <>
            <Button type="primary" onClick={prev}>{intl.get("common.button.before")}</Button>
            <Divider type={"vertical"}/>
            <Button type="primary" onClick={next}>{intl.get("common.button.next")}</Button>
          </>
        )}
      </div>
    </Spin>
  )
};

export default LiveRoomList;