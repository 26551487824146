import React, {useEffect} from "react";
import {Switch} from "react-router-dom";
import Home from "../Home";
import Dashboard from "../dashboard/Dashboard";
import PageLoadingRoute from "../../components/router/PageLoadingRoute";
import CompanyEdit from "../company/CompanyEdit";
import Company from "../company/Company";
import Application from "../application/Application";
import Product from "../product/Product";
import Brand from "../brand/Brand";
import Category from "../category/Category";
import User from "../user/User";
import Order from "../order/Order";
import Faq from "../faq/Faq";
import Terms from "../terms/Terms";
import ApplicationDesignEdit from "../application/ApplicationDesignEdit";
import ApplicationPage from "../app_page/ApplicationPage";
import CompanyUserManager from "../company/CompanyUserManager";
import CouponEvent from "../coupons/CouponEvent";
import ProductShareList from "../product/ProductShareList";
import FileManager from "../file_manager/FileManager";
import LogisticsCompany from "../logistics_company/LogisticsCompany";
import ApplicationLogisticsCompany from "../application_logistics_company/ApplicationLogisticsCompany";
import CompanyRegister from "../company/CompanyRegister";
import UserCouponList from "../coupons/UserCouponList";
import SystemSetting from "../system_setting/SystemSetting";
import ProductMarket from "../product/ProductMarket";
import Question from "../question/Question";
import NoticeList from "../notice/NoticeList";

import NoticeManager from "../notice/NoticeManager";
import Guide from "../notice/Guide";
import OrderSettlement from "../settlement/OrderSettlement";
import SellerSettlement from "../settlement/SellerSettlement";
import Reply from "../reply/Reply";
import Live from "../live/Live";
import BatchJob from "../admin/BatchJob";
import UploadOrder from "../order/upload/UploadOrder";
import UploadedOrderList from "../order/upload/UploadedOrderList";
import ApplicationEdit from "../application/ApplicationEdit";
import SupplierProductList from "../supplier/SupplierProductList";
import SupplierOrderList from "../supplier/SupplierOrderList";
import CustomsUploadList from "../customs/CustomsUploadList";
import ShopComponent from "../shop_component/ShopComponent";

const PageRouter = () => {
  return (
      <>
        <Switch>
          <PageLoadingRoute exact path="/" component={Home}/>
          <PageLoadingRoute path="/company" component={CompanyEdit}/>
          <PageLoadingRoute path="/company-users" component={CompanyUserManager}/>
          <PageLoadingRoute path="/company-applications" component={Application}/>
          <PageLoadingRoute path="/products" component={Product}/>
          <PageLoadingRoute path="/product-market" component={ProductMarket}/>
          <PageLoadingRoute path="/product-shares"  component={ProductShareList}/>
          <PageLoadingRoute path="/brands" component={Brand}/>
          <PageLoadingRoute path="/categories" component={Category}/>
          <PageLoadingRoute path="/users" component={User}/>
          <PageLoadingRoute path="/companies" component={Company}/>
          <PageLoadingRoute path="/applications" component={Application}/>
          <PageLoadingRoute path="/app-design" component={ApplicationDesignEdit}/>
          <PageLoadingRoute path="/app-pages" component={ApplicationPage}/>
          <PageLoadingRoute path="/app-setting" component={ApplicationEdit}/>
          <PageLoadingRoute path="/orders" component={Order}/>
          <PageLoadingRoute path="/order-upload" component={UploadOrder}/>
          <PageLoadingRoute path="/faqs" component={Faq}/>
          <PageLoadingRoute path="/terms" component={Terms}/>
          <PageLoadingRoute path="/coupon-events" component={CouponEvent}/>
          <PageLoadingRoute path="/user-coupons" component={UserCouponList}/>
          {/*<PageLoadingRoute path="/coupon-redeems" component={CouponRedeemList}/>*/}
          {/*<PageLoadingRoute path="/not-registered-product" component={NotRegisteredProduct} />*/}
          {/*<PageLoadingRoute path="/registered-product" component={RegisteredProduct} />*/}
          <PageLoadingRoute path="/lives" component={Live} />
          <PageLoadingRoute path="/file-manager" component={FileManager} />
          <PageLoadingRoute path="/logistics-companies" component={LogisticsCompany}/>
          <PageLoadingRoute path="/application-logistics-companies" component={ApplicationLogisticsCompany} />
          <PageLoadingRoute path="/company-setting" component={CompanyRegister}/>
          <PageLoadingRoute path="/system-settings" component={SystemSetting}/>
          <PageLoadingRoute path="/questions" component={Question}/>
          <PageLoadingRoute path="/notices" component={NoticeList}/>
          <PageLoadingRoute path="/notice-manager" component={NoticeManager}/>
          <PageLoadingRoute path="/guide" component={Guide}/>
          <PageLoadingRoute path="/settlement-order" component={OrderSettlement}/>
          <PageLoadingRoute path="/settlement-seller" component={SellerSettlement}/>
          <PageLoadingRoute path="/guide" component={Guide}/>
          <PageLoadingRoute path="/replies" component={Reply}/>
          <PageLoadingRoute path="/batch-job"  component={BatchJob}/>
          <PageLoadingRoute path="/upload-logistics-order"  component={UploadOrder}/>
          <PageLoadingRoute path="/logistics-orders"  component={UploadedOrderList}/>
          <PageLoadingRoute path="/supplier-products"  component={SupplierProductList}/>
          <PageLoadingRoute path="/supplier-orders"  component={SupplierOrderList}/>
          <PageLoadingRoute path="/customs" component={CustomsUploadList}/>
          <PageLoadingRoute path="/components" component={ShopComponent}/>
        </Switch>
      </>
  )
};

export default PageRouter;
