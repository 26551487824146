import React, {useEffect, useState} from "react";
import {Descriptions, Input, InputNumber} from "antd";
import intl from "react-intl-universal";

const LineSetting = (props) => {
  const [settingValues, setSettingValues] = useState({});

  useEffect(() => {
    setSettingValues(JSON.parse(JSON.stringify(props.settingValues)));
  }, [props.settingValues]);

  const onValueChanged = (fieldName, value) => {
    const settingValuesState = {...settingValues};
    settingValuesState[fieldName] = value;
    setSettingValues(settingValuesState);
    props.applySetting(props.id, settingValuesState);
  };

  return (
      <div>
        <Descriptions title={intl.get("page_setting.edit.line.setting")}
                      bordered layout="vertical" size={"small"} column={1}>
          <Descriptions.Item label={intl.get("page_setting.edit.line.color")}>
            <span><input type="color" value={settingValues.backgroundColor ? settingValues.backgroundColor : "#ffffff"} onChange={e => onValueChanged("backgroundColor", e.target.value)} /></span>
            <span style={{marginLeft: 10}}>{settingValues.backgroundColor}</span>
            {/*<Input value={settingValues.backgroundColor} onChange={(e) => onValueChanged("backgroundColor", e.target.value)} />*/}
          </Descriptions.Item>
          <Descriptions.Item label={intl.get("page_setting.edit.line.height")}>
            <InputNumber value={settingValues.height} min={1} onChange={(value) => onValueChanged("height", value)} />
          </Descriptions.Item>
        </Descriptions>
      </div>
  )
};

export default LineSetting;